import { Help } from '@mui/icons-material';
import { Slide, Stack, Tooltip, Typography, Zoom } from '@mui/material';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

export const DatapackHelpTooltip = () => {
  return (
    <Slide direction='down' in={true} style={{ transitionDelay: '3000ms' }}>
      <Tooltip
        title={
          <Stack spacing={1.5}>
            <Typography variant='body2'>
              Datapacks capture monitor parameter data at a high frequency
              around a certain fault.
            </Typography>

            <Typography variant='body2' fontWeight={600}>
              {'The occurrence of the fault is represented by the '}
              <span style={{ color: PowerToolDarkTheme.dataPurple200 }}>
                sample no -0.5.
              </span>
            </Typography>
          </Stack>
        }
      >
        <Help />
      </Tooltip>
    </Slide>
  );
};
