import { ArrowDropDown, InfoOutlined, Settings } from '@mui/icons-material';
import {
  Button,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  Menu,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';

import { STR_MAP, STR_PLOT, STR_LAYOUT } from 'common/constants';
import { useAppSelector } from 'common/hooks';
import {
  selectUser,
  selectApplicationContext,
  selectTimeSettings,
} from 'common/stores/globalSlice';
import {
  selectSatelliteView,
  selectScatterPlotView,
} from 'common/stores/userSettingSlice';
import { getApplicationRuntime } from 'common/util/utils';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

import { saveUserSettings } from './api';
import { LayoutListMenu } from './components/LayoutListMenu';
import { TimezoneList } from './components/TimezoneListMenu';
import { useFetchSettings } from './hooks';
import { UserSetting, MapSettingConfig, PlotSettingConfig } from './types';

export const UserSettings = () => {
  // const userSettings = useAppSelector(selectUserSettings);
  const globalSatelliteView = useAppSelector(selectSatelliteView);
  const globalScatterPlotView = useAppSelector(selectScatterPlotView);
  const user = useAppSelector(selectUser);
  const applicationName = useAppSelector(selectApplicationContext);
  const timeSettings = useAppSelector(selectTimeSettings);
  const runtime = getApplicationRuntime();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [satelliteView, setSatelliteView] = useState<boolean>(false);
  const [scatterPlot, setScatterPlot] = useState<boolean>(false);
  const [defaultLayout, setDefaultLayout] = useState<UserSetting | undefined>(
    undefined
  );

  const { settings } = useFetchSettings();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setSatelliteView(globalSatelliteView);
  }, [globalSatelliteView]);

  useEffect(() => {
    setScatterPlot(globalScatterPlotView);
  }, [globalScatterPlotView]);

  const saveSettings = () => {
    const mapSetting: MapSettingConfig = {
      satelliteView: satelliteView,
      threeDView: false,
    };
    const satelliteSetting: UserSetting = {
      userId: user?.userName,
      setting: STR_MAP,
      customName: '',
      config: JSON.stringify(mapSetting),
      application: applicationName,
      isDefault: 'N',
    };

    const plotSetting: PlotSettingConfig = {
      displayLines: !scatterPlot,
    };

    const scatterPlotSetting: UserSetting = {
      userId: user?.userName,
      setting: STR_PLOT,
      customName: '',
      config: JSON.stringify(plotSetting),
      application: applicationName,
      isDefault: 'N',
    };

    const allUserSettings: UserSetting[] = [];

    // if we already have settings for either map or plot, we should
    // update the configObjid with the new ID
    if (settings) {
      for (const userSetting of settings) {
        if (userSetting.setting === STR_MAP) {
          satelliteSetting.configObjid = userSetting.configObjid;
        }

        if (userSetting.setting === STR_PLOT) {
          scatterPlotSetting.configObjid = userSetting.configObjid;
        }

        if (
          defaultLayout &&
          userSetting.setting === STR_LAYOUT &&
          userSetting.isDefault === 'Y' &&
          userSetting.configObjid !== defaultLayout.configObjid
        ) {
          const changeDefaultLayout = { ...userSetting };
          changeDefaultLayout.isDefault = 'N';
          changeDefaultLayout.userId = user?.userName;
          changeDefaultLayout.application = applicationName;
          allUserSettings.push(changeDefaultLayout);
        }
      }
    }

    allUserSettings.push(satelliteSetting);
    allUserSettings.push(scatterPlotSetting);
    if (defaultLayout) {
      allUserSettings.push(defaultLayout);
    }

    if (allUserSettings.length > 0) {
      saveUserSettings(allUserSettings)
        .then((response) => {
          enqueueSnackbar('Successfully updated default settings', {
            variant: 'success',
          });
        })
        .catch((e) => {
          enqueueSnackbar('Error updating settings', { variant: 'error' });
        });
    }
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSwitchChange = (event, name: string) => {
    if (name === 'satellite') {
      setSatelliteView(!satelliteView);
    }
    if (name === 'plot') {
      setScatterPlot(!scatterPlot);
    }
  };

  return (
    <React.Fragment>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <IconButton
          size='medium'
          onClick={handleMenu}
          sx={{
            '&.MuiButtonBase-root-MuiIconButton-root': {
              padding: 0,
            },
            borderRadius: '4px',
            padding: 0,
            paddingLeft: '5px',
          }}
        >
          <div>
            <Settings />
            <ArrowDropDown />
          </div>
        </IconButton>

        <div>
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            disableScrollLock={true}
            PaperProps={{
              elevation: 3,
              style: {
                width: '250px',
                backgroundColor: PowerToolDarkTheme.main,
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                mx: 2,
                alignItems: 'flex-start',
              }}
            >
              <FormGroup
                sx={{
                  ml: 1,
                  display: 'flex',
                  gap: '5px',
                  width: '100%',
                  alignItems: 'flex-start',
                }}
              >
                <Divider sx={{ mt: 1, mb: 1 }} textAlign='left' flexItem>
                  <Typography variant='button'>Preferences</Typography>
                </Divider>

                <Stack direction={'row'} gap={1} alignItems='center'>
                  <Stack direction={'row'} gap={1} alignItems='center'>
                    <Typography variant='button'>Timezone:</Typography>
                    <Typography>{timeSettings.zone}</Typography>
                    <Tooltip title='This can be configured inside OMD'>
                      <InfoOutlined
                        fontSize='small'
                        sx={{ color: PowerToolDarkTheme.statusGray500 }}
                      />
                    </Tooltip>
                  </Stack>
                </Stack>

                {runtime === 'local' || runtime === 'dev' ? (
                  <TimezoneList />
                ) : (
                  <></>
                )}

                <FormControlLabel
                  sx={{
                    '&.MuiFormControlLabel-root': {
                      margin: 0,
                    },
                  }}
                  control={
                    <Switch
                      size='small'
                      checked={satelliteView}
                      onChange={(event) =>
                        handleSwitchChange(event, 'satellite')
                      }
                    />
                  }
                  label='Satellite view'
                />

                {applicationName !== 'train_analysis' ? (
                  <FormControlLabel
                    sx={{
                      '&.MuiFormControlLabel-root': {
                        margin: 0,
                      },
                    }}
                    control={
                      <Switch
                        size='small'
                        checked={scatterPlot}
                        onChange={(event) => handleSwitchChange(event, 'plot')}
                      />
                    }
                    label='Scatter Plot'
                  />
                ) : (
                  <></>
                )}

                <Divider sx={{ mt: 1 }} textAlign='left' flexItem>
                  <Typography variant='button'>Layouts</Typography>
                </Divider>

                <LayoutListMenu
                  onChange={(userSetting) => setDefaultLayout(userSetting)}
                />
              </FormGroup>

              <Button
                size='small'
                onClick={() => saveSettings()}
                variant='contained'
                color='success'
                sx={{
                  alignSelf: 'flex-end',
                  whiteSpace: 'nowrap',
                  mt: 2,
                }}
              >
                Make Default
              </Button>
            </Box>
          </Menu>
        </div>
      </div>
    </React.Fragment>
  );
};
