import { useCallback, useMemo } from 'react';
import { batch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { defaultUser } from 'auth/config';
import { useOktaUserInfo } from 'auth/hooks';

import { useFetchAsset, useFetchUserInfo } from 'common/api/hooks';
import { DayRangeChoice, DayRangeChoices } from 'common/cards/Data/types';
import { baseTimeSettings } from 'common/config/config';
import { useAppDispatch } from 'common/hooks';
import { setCustomer, setTimeSettings } from 'common/stores/globalSlice';
import { setService } from 'common/stores/ingestedDataFooterSlice';
import {
  DateRange,
  PowerToolApplicationContext,
  PowerToolApplicationContexts,
  User,
} from 'common/types/types';
import { SecurityUtilityService } from 'common/util/security-utility';
import { getApplicationRuntime, isAppDeployed } from 'common/util/utils';

import { useFetchCase } from 'power-tool/api/hooks';

import { useFetchTATProperties } from 'train-tool/hooks/apiHooks';

const useDecryptURL = () => {
  const [urlParams] = useSearchParams();

  const deployed = isAppDeployed();
  const security = new SecurityUtilityService();
  const override = urlParams.get('isDecryptedUrl') === 'true';

  const decrypt = useCallback(
    (param: string | number | null | undefined) => {
      if (deployed && param && !override) {
        return security.decrypt(param.toString());
      }

      return param;
    },
    [deployed, security, override]
  );

  return { decrypt };
};

export const useProcessAsset = (
  vehicleObjid: string | null,
  caseId: string | null
) => {
  const { decrypt } = useDecryptURL();

  vehicleObjid = decrypt(vehicleObjid);
  caseId = decrypt(caseId);

  return useFetchAsset(vehicleObjid, caseId);
};

export const useProcessCase = (caseId: string | null) => {
  const { decrypt } = useDecryptURL();

  caseId = decrypt(caseId);

  return useFetchCase(caseId);
};

export const useProcessRule = (rule: string | null) => {
  const { decrypt } = useDecryptURL();

  const ruleId = useMemo(() => {
    if (rule) {
      const ruleId = parseInt(rule);
      if (ruleId > 0 && !isNaN(ruleId)) {
        return decrypt(ruleId);
      }
    }
  }, [rule]);

  return { ruleId };
};

export const useProcessUser = (
  userId: string | undefined | null,
  role: string | null | undefined
) => {
  const dispatch = useAppDispatch();

  const { userInfo } = useOktaUserInfo();
  const { userName } = useFetchUserInfo(userId);

  const oktaUserId =
    userInfo?.ssoidORusername?.toString() ?? userInfo?.SM_USER.toString();

  const { userProperties } = useFetchTATProperties(oktaUserId);

  const user = useMemo((): User | undefined => {
    // If Okta is enabled, we don't consider
    // getting user information from URL only from Okta
    if (process.env.REACT_APP_IS_CLOUDFRONT === 'true') {
      if (userProperties && oktaUserId) {
        const oktaUser: User = {
          strEmail: userInfo?.email,
          userName: oktaUserId,
          eoaUserName: oktaUserId,
          prefTimeZoneDesc: 'EST',
          currRole: 'DD_Map_Alert_IPA',
        };

        // we set some settings up front
        // that usually come from omd
        // we need to make this a single service
        batch(() => {
          dispatch(
            setTimeSettings({
              ...baseTimeSettings,
              zone:
                userProperties.timeZone === 'CST'
                  ? 'US/Central'
                  : userProperties.timeZone ?? oktaUser.prefTimeZoneDesc,
            })
          );
          if (userProperties.enabled) {
            dispatch(setCustomer(userProperties.customer));
          }
        });

        return oktaUser;
      }
    } else {
      // if we get a userId in the URL, make sure the EOA user info
      // has been fetched from the database, and then dispatch
      if (userId !== null && userName) {
        return {
          ...defaultUser,
          userName: userId ?? 'anonymous',
          eoaUserName: userName,
          currRole: role ?? 'IT Read Only',
        };
      }

      // if we haven't been opened as a child window
      // and there isn't a user ID in the URL
      // then dispatch as an anonymous user
      else if (userId === null && !window.opener) {
        return defaultUser;
      }
    }
  }, [userName, userProperties, oktaUserId, userId, role]);

  return { user };
};

export const useGetApplicationContext = (context: string | null) => {
  const application = useMemo((): PowerToolApplicationContext | undefined => {
    if (context) {
      if (
        PowerToolApplicationContexts.includes(
          context as PowerToolApplicationContext
        )
      ) {
        return context as PowerToolApplicationContext;
      } else if (
        context === 'asset_research_alpha_not_intended_for_final_users'
      ) {
        return 'asset_research';
      }
    }

    return 'power_tool';
  }, [context]);

  return { application };
};

export const useProcessCustomer = (customerId: string | null) => {
  const customer = useMemo(() => {
    if (customerId) {
      return customerId;
    }
  }, [customerId]);

  return { customer };
};

export const useProcessTrainId = (train: string | null) => {
  const trainId = useMemo(() => {
    if (train) {
      return train;
    }
  }, [train]);

  return { trainId };
};

export const useProcessRuleFamily = (family: string | null) => {
  const ruleFamily = useMemo(() => {
    if (family) {
      return family;
    }
  }, [family]);

  return { ruleFamily };
};

export const useProcessDatapackParameters = (
  fault: string | null,
  templateNo: string | null
) => {
  const faultObjid = useMemo(() => {
    return fault;
  }, [fault]);

  const template = useMemo(() => {
    return templateNo;
  }, [templateNo]);

  return { faultObjid, template };
};

export const useProcessVirtualTesterParameters = (
  id: string | null,
  info: string | null
) => {
  const { decrypt } = useDecryptURL();

  const virtualId = useMemo(() => {
    if (id) {
      return decrypt(id);
    }
  }, [id]);

  const virtualInfo = useMemo(() => {
    if (info) {
      return decrypt(JSON.parse(info));
    }
  }, [info]);

  return { virtualId, virtualInfo };
};

export const useProcessDevParameters = (
  debug: string | null,
  superUser: string | null
) => {
  const debugMode = useMemo(() => {
    if (debug === 'false') {
      return false;
    } else if (getApplicationRuntime() === 'local' || debug === 'true') {
      return true;
    }

    return false;
  }, [debug]);

  const isSuperUser = useMemo(() => {
    if (getApplicationRuntime() === 'prod') {
      return false;
    }

    if (superUser === 'true') {
      return true;
    }

    return false;
  }, [superUser]);

  return { debugMode, isSuperUser };
};

export const useProcessDatascreenParameters = (
  from: string | null,
  to: string | null,
  range: string | null
) => {
  const dateRange = useMemo((): DateRange | undefined => {
    if (from && to) {
      return {
        to: new Date(to),
        from: new Date(from),
      };
    }
  }, [from, to]);

  const dayRange = useMemo((): DayRangeChoice | undefined => {
    if (range && DayRangeChoices.includes(range as DayRangeChoice)) {
      return range as DayRangeChoice;
    }
  }, []);

  return { dateRange, dayRange };
};

export const useProcessRuleTesterParameters = (
  info: string | null,
  clearingLogic: string | null
) => {
  const { decrypt } = useDecryptURL();

  const ruleInfo = useMemo(() => {
    if (info) {
      return decrypt(info);
    }
  }, [info]);

  const isClearingLogic = useMemo(() => {
    if (clearingLogic) {
      return decrypt(clearingLogic) === 'true';
    }
  }, [clearingLogic]);

  return { ruleInfo, isClearingLogic };
};
