import { ICellRendererParams } from 'ag-grid-community';

import { TextChip } from 'common/components/TextChip';
import { TimelineEventType } from 'common/features/timeline/types';
import { invertColor } from 'common/util/color';

import { TimelineEventToPropsMap } from 'loco-history/config';
import { AnyTimelineEvent } from 'loco-history/types';

/**
 *
 * @param {any} props implicit destructured props
 * @param {TimelineEventType} props.type type of event to label
 * @returns {JSX.Element} colored chip label component labeling the timeline event type
 */
export const TimelineEventTypeChip = ({
  type,
}: {
  type?: TimelineEventType;
}) => {
  if (type) {
    return (
      <TextChip
        color={invertColor(
          TimelineEventToPropsMap[type].style.backgroundColor,
          true
        )}
        backgroundColor={TimelineEventToPropsMap[type].style.backgroundColor}
        label={TimelineEventToPropsMap[type].label}
        textProps={{
          fontWeight: 'normal',
          fontSize: '12px',
          paddingTop: '1px',
          paddingBottom: '1px',
        }}
      />
    );
  } else {
    return <TextChip label={''} />;
  }
};

/**
 *
 * @param {ICellRendererParams<AnyTimelineEvent>} params ag-grid cell renderer params
 * @returns {JSX.Element} ag-grid event chip label cell renderer
 */
export const TimelineEventTypeChipCell = (
  params: ICellRendererParams<AnyTimelineEvent>
) => {
  return <TimelineEventTypeChip type={params.data?.type} />;
};
