import { ICellRendererParams } from 'ag-grid-community';

import {
  useFixedFormattedTime,
  useFixedFormattedTimeWithZone,
} from 'common/hooks';

export const FixedTimeZoneCellWithTZ = (props: ICellRendererParams) => {
  const fixed = useFixedFormattedTimeWithZone(
    props.valueFormatted ?? props.value
  );

  return <span>{fixed}</span>;
};

export const FixedTimeZoneCell = (props: ICellRendererParams) => {
  const fixed = useFixedFormattedTime(props.valueFormatted ?? props.value);

  return <span>{fixed}</span>;
};
