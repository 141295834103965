import { GridApi, GridOptions } from 'ag-grid-community';
import { AgGridReactProps } from 'ag-grid-react';
import {
  AgGridReact,
  AgGridReact as AgGridReactType,
} from 'ag-grid-react/lib/agGridReact';
import React from 'react';

import StyledAgGridCustom from 'common/components/ag-grid/AgGridCustom.styled';
import { useAppSelector } from 'common/hooks';
import { selectTheme } from 'common/stores/globalSlice';

GridApi.prototype.showColumnMenuAfterButtonClick = function (
  colKey,
  buttonElement
) {
  // use grid column so works with pivot mode
  // @ts-ignore
  const column = this.columnModel.getGridColumn(colKey);
  // @ts-ignore
  this.menuFactory.showMenuAfterButtonClick(
    column,
    buttonElement,
    'columnMenu',
    'filterMenuTab'
  );
};

export const AgGridCustomdataScreen = React.forwardRef<
  AgGridReactType,
  AgGridReactProps
>(<T,>(props: AgGridReactProps<T>, ref) => {
  const theme = useAppSelector(selectTheme);

  const customGridOptions: GridOptions = {
    // column settings
    suppressMovableColumns: true,
    suppressMenuHide: true,
    defaultColDef: {
      resizable: true,
      filterParams: {
        defaultToNothingSelected: true,
      },
    },

    // row settings
    rowSelection: 'single',
    suppressRowHoverHighlight: true,

    // cell settings
    enableCellTextSelection: true,

    ...props.gridOptions,
  };

  const getRowNodeId = (data: any) => {
    return data.data.OBJID;
  };

  return (
    <StyledAgGridCustom>
      <div
        className={'ag-theme-alpine-dark'}
        style={{ height: '100%', width: '100%' }}
      >
        <AgGridReact
          className='power-tool-grid'
          ref={ref}
          getRowId={getRowNodeId}
          rowData={props.rowData}
          key={theme}
          gridOptions={customGridOptions}
          columnDefs={props.columnDefs}
          components={props.components}
        />
      </div>
    </StyledAgGridCustom>
  );
});
