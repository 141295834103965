import { BeatLoader } from 'react-spinners';
import { useTheme } from 'styled-components';

export const LoadingSpinnerOverlayLoading = () => {
  return <LoadingSpinner />;
};

export const EmptyOverlayLoading = () => {
  return <div></div>;
};

export const LoadingSpinner = () => {
  const theme = useTheme();
  return (
    <span>
      <BeatLoader size={15} color={theme.mainAccentText} />
    </span>
  );
};
