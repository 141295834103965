import axios, { Canceler } from 'axios';

import { RxPlot } from './types';

let cancelRxPlot: Canceler | undefined;

export const getRxPlots = (
  vehicleObjid: number,
  rxIds: number[]
): Promise<RxPlot[]> => {
  if (cancelRxPlot) {
    cancelRxPlot();
  }
  return axios
    .post<RxPlot[]>(
      `/rxplot?vehicleObjid=${vehicleObjid}&rxIds=${rxIds.join(',')}`,
      {},
      {
        cancelToken: new axios.CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancelRxPlot = c;
        }),
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      if (e?.message === 'canceled') {
        return [];
      }
      console.log('error calling endpoint for RX plots');
      throw e;
    });
};

export const getSASPlot = (
  ruleId: number,
  caseId: string,
  vehicleObjid: number
): Promise<string> => {
  return axios
    .get('/sasPlot/' + ruleId + '/' + caseId + '/' + vehicleObjid)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log('error fetching getRuleFiringDetails');
      throw e;
    });
};
