import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { useAppSelector } from 'common/hooks';
import { selectAssetDetails, selectRule } from 'common/stores/globalSlice';

import {
  getCase,
  getCases,
  getConfigOfInterest,
  getFiredFaults,
  getHealth,
  getParametersFromRule,
  getRunInfo,
  getRuns,
  getRxMetics,
  getToolOutput,
} from 'power-tool/api';
import { selectCase } from 'power-tool/stores/casesSlice';
import { selectRun } from 'power-tool/stores/runSlice';
import { selectToolOutput } from 'power-tool/stores/toolOutputSlice';

/**
 *
 */
export const useFetchRxMetrics = () => {
  const toolOutput = useAppSelector(selectToolOutput);

  const enabled = useMemo(() => {
    return toolOutput && toolOutput.length > 0;
  }, [toolOutput]);

  const hook = useQuery(
    ['rx_metrics', toolOutput],
    () => getRxMetics(toolOutput.map((t) => t.rxId)),
    { enabled }
  );

  return {
    rxMetrics: hook.data,
    rxMetricStatus: hook.status,
    ...hook,
  };
};

export const useFetchCases = () => {
  const asset = useAppSelector(selectAssetDetails);

  const enabled = useMemo(() => {
    return asset ? true : false;
  }, [asset]);

  const hook = useQuery(
    ['cases', asset],
    () => getCases(asset?.vehicleObjid ?? ''),
    { enabled }
  );

  return {
    cases: hook.data,
    casesStatus: hook.status,
    ...hook,
  };
};

export const useFetchCase = (caseId: string | undefined | null) => {
  const enabled = useMemo(() => {
    return caseId ? true : false;
  }, [caseId]);

  const hook = useQuery(['case', caseId], () => getCase(caseId!), { enabled });

  return {
    caseDetails: hook.data,
    caseStatus: hook.status,
    ...hook,
  };
};

export const useFetchToolOutput = () => {
  const selectedCase = useAppSelector(selectCase);
  const run = useAppSelector(selectRun);

  const enabled = useMemo(() => {
    return selectedCase !== undefined || run !== undefined ? true : false;
  }, [selectedCase, run]);

  const hook = useQuery(
    ['tool_output', selectedCase, run],
    () => getToolOutput(selectedCase?.caseId, run?.objid),
    { enabled }
  );

  return {
    toolOutput: hook.data,
    toolOutputStatus: hook.status,
    ...hook,
  };
};

export const useFetchFiredFaults = () => {
  const selectedCase = useAppSelector(selectCase);
  const run = useAppSelector(selectRun);
  const rule = useAppSelector(selectRule);

  const enabled = useMemo(() => {
    return rule > 0 && (selectedCase || run) ? true : false;
  }, [selectedCase, run, rule]);

  const hook = useQuery(
    ['fired_faults', selectedCase, run, rule],
    () => getFiredFaults(rule, selectedCase?.caseId, run?.objid),
    { enabled }
  );

  return {
    firedFaults: hook.data,
    firedFaultStatus: hook.status,
    ...hook,
  };
};

export const useFetchRuns = () => {
  const asset = useAppSelector(selectAssetDetails);
  const selectedCase = useAppSelector(selectCase);

  const enabled = useMemo(() => {
    return asset || selectedCase ? true : false;
  }, [selectedCase, asset]);

  const hook = useQuery(
    ['runs', selectedCase, asset],
    () => getRuns(asset?.vehicleObjid, selectedCase?.caseId),
    { enabled }
  );

  return {
    runs: hook.data,
    runsStatus: hook.status,
    ...hook,
  };
};

export const useFetchFiredParameters = () => {
  const rule = useAppSelector(selectRule);

  const enabled = useMemo(() => {
    return rule > 0;
  }, [rule]);

  const hook = useQuery(
    ['fired_parameters', rule],
    () => getParametersFromRule(rule),
    { enabled }
  );

  return {
    firedParameters: hook.data,
    firedParameterStatus: hook.status,
    ...hook,
  };
};

export const useFetchAssetCardInfo = () => {
  const asset = useAppSelector(selectAssetDetails);

  const enabled = useMemo(() => {
    return asset !== undefined;
  }, [asset]);

  const configHook = useQuery(
    ['config_items', { asset }],
    () => getConfigOfInterest(asset?.vehicleObjid),
    { enabled }
  );

  const healthHook = useQuery(
    ['health_info', { asset }],
    () => getHealth(asset?.vehicleObjid),
    { enabled }
  );

  const runHook = useQuery(
    ['last_run', { asset }],
    () => getRunInfo(asset?.vehicleObjid),
    { enabled }
  );

  return {
    configItems: configHook.data,
    healthInfo: healthHook.data,
    lastRun: runHook.data,
  };
};
