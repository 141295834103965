export const dataCardTimestampFormat = 'LL-dd-yy TT';

// these columns' timezone depends on the controller source ID of the unit
// this is defined in Asset.unitTimeZone
export const controllerZonedTimeColumns = ['OCCUR_DATE', 'FAULT_RESET_DATE'];

export const fixedTimeColumns = ['CMU_TIME', 'OFFBOARD_LOAD_DATE'];

export const zonedTimeColumns = ['NATIVE_TIME'];

// columns in the grid that contain timestamps
export const timeColumns = [
  ...controllerZonedTimeColumns,
  ...fixedTimeColumns,
  ...zonedTimeColumns,
];

// "Notch 8 reference check" eligible
export const outboundModels = [
  'ET44AC',
  'DCCCA',
  'ACCCA',
  'AC44CCA',
  'ES44ACVS',
  'D89CCA',
  'HSP46',
];

// columns that have excel aka "set" style filters
export const setFilterColumns = [
  'RECORD_TYPE',
  'FAULT_CODE',
  'FAULT_CLASSIFICATION',
  'FAULT_CODE',
  'TOOL_RUN_INDICATOR',
  'LOCATION',
  'FAULT_DESC',
  'MODE_CALL',
  'RAD_FAN',
  'MP_0102_N_0_0',
  'NOTCH',
  'SUB_ID',
];

// columns that are not plottable
export const excludedFromPlot = [
  'LOCATION',
  'FAULT_RESET_DATE',
  'RECORD_TYPE',
  'FAULT_CODE',
  'SUB_ID',
  'FAULT_DESC',
  'CMU_TIME',
  'TOOL_RUN_INDICATOR',
  'TEMPF',
  'PRECIPIN',
  'WINDSPEEDMPH',
  'ICON',
  'WEATHER',
  'FAULT_CLASSIFICATION',
];

// columns that are part of the response but that are not rendered
export const allExclude = [
  'objid',
  'uid',
  'proxi',
  'fired',
  'creation_date',
  'occur_time',
  'last_updated_date',
  'location_desc',
  'snowin',
  'winddir',
  'winddirdeg',
  'windgustmph',
  'fault_reset_time',
  'critical_flag',
  'notes',
  'operator_message',
  'tool_ind_tip',
  'additional_info',
  'elevft',
  'name',
  'state',
  'country',
  'fault_origin',
  'diagnostic_weight',
  'controller_source_id',
];
