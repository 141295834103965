import { Warning } from '@mui/icons-material';
import { Stack, Divider } from '@mui/material';
import React from 'react';

import { StripHTMLWrapper } from 'common/components/StripHTMLWrapper';
import { TextChip } from 'common/components/TextChip';
import { StyledLink } from 'common/components/general/StyledLink';
import { UnderConstruction } from 'common/components/general/UnderConstruction';
import { UrgencyColorMap } from 'common/config/config';
import { TimelineEventType } from 'common/features/timeline/types';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

import {
  BodyHeader,
  MainBody,
  BodyLongText,
} from 'loco-history/cards/Timeline/components/event-tooltip/EventTooltipContentComponents';
import { FaultEventTooltipContent } from 'loco-history/cards/Timeline/components/event-tooltip/FaultEventTooltipContent';
import {
  IncidentEvent,
  FaultEvent,
  MaterialUsageEvent,
  ShopEvent,
  RXEvent,
  CallEvent,
  DefectEvent,
  AnyTimelineEvent,
  WriteInEvent,
  ProgramEvent,
} from 'loco-history/types';

/**
 *
 * @param {any} props implicit destructured props
 * @param {JSX.Element} props.header header component
 * @param {JSX.Element} props.body main body component
 * @param {JSX.Element} props.footer footer component
 * @param {JSX.Element[]} props.long long text component(s)
 * @returns {JSX.Element} layout wrapper component for the entire event tooltip body
 */
export const LayoutWrapper = ({
  header,
  body,
  footer,
  long,
}: {
  header: JSX.Element;
  body: JSX.Element;
  footer?: JSX.Element;
  long?: JSX.Element[];
}) => {
  return (
    <React.Fragment>
      <Stack direction={'column'} spacing={1}>
        {header}

        <Divider />

        {body}

        {long}

        <Divider />

        {footer}
      </Stack>
    </React.Fragment>
  );
};

/**
 *
 * @param {any} props implicit destructured props
 * @param {AnyTimelineEvent} props.event event to display tooltip content for
 * @param {TimelineEventType} props.type type of event ti display tooltip content for
 * @param {(content: string) => void | undefined} props.onContentClick callback function for when tooltip content is clicked
 * @returns {JSX.Element} event tooltip body content
 */
export const EventTooltipContent = ({
  event,
  type,
  onContentClick,
}: {
  event: AnyTimelineEvent;
  type: TimelineEventType;
  onContentClick?: (content: string) => void;
}) => {
  switch (type) {
    case 'write-in':
      event = event as WriteInEvent;
      return <UnderConstruction />;

    case 'shop':
      event = event as ShopEvent;
      return (
        <LayoutWrapper
          header={
            <BodyHeader title={event.shop} clipboardLabel={event.workorder} />
          }
          body={
            <MainBody
              onContentClick={onContentClick}
              left={[
                {
                  label: 'shop',
                  value: event.shop,
                },
                {
                  label: 'workorder',
                  value: event.workorder,
                },
                {
                  label: 'repeater',
                  value: event.repeater,
                },
                {
                  label: 'reason',
                  value: event.inshopReason,
                },
              ]}
              right={[
                {
                  label: 'inshop',
                  value: event.inshopDate,
                  options: { formatTime: true },
                },
                {
                  label: 'outshop',
                  value: event.outshopDate,
                  options: { formatTime: true },
                },
                {
                  label: 'workorder close',
                  value: event.workorderCloseDate,
                  options: { formatTime: true },
                },
              ]}
            />
          }
          long={[
            <BodyLongText
              onContentClick={onContentClick}
              key={'comments'}
              item={{
                label: 'comments',
                value: event.comments,
              }}
            />,
            <BodyLongText
              onContentClick={onContentClick}
              key={'notes'}
              item={{
                label: 'notes',
                value: event.notes,
              }}
            />,
          ]}
        />
      );

    case 'incident':
      event = event as IncidentEvent;
      return (
        <LayoutWrapper
          header={<BodyHeader title={event.custIncidentType} />}
          body={
            <MainBody
              onContentClick={onContentClick}
              left={[
                {
                  label: 'code',
                  value: event.custIncidentType,
                },
                {
                  label: 'status',
                  value:
                    event.status === 'Open' ? (
                      <React.Fragment>
                        <Warning
                          sx={{ color: PowerToolDarkTheme.statusOrange500 }}
                        />{' '}
                        {event.status}
                      </React.Fragment>
                    ) : (
                      event.status
                    ),
                },
                {
                  label: 'critical',
                  value: event.critical,
                },
              ]}
              right={[
                {
                  label: 'opened',
                  value: event.timestamp,
                  options: { formatTime: true },
                },
                {
                  label: 'closed',
                  value: event.closeOutDate,
                  options: { formatTime: true },
                },
              ]}
            />
          }
          long={[
            <BodyLongText
              onContentClick={onContentClick}
              key={'code'}
              item={{
                label: 'code description',
                value: event.incidentCodeDescription,
              }}
            />,
            <BodyLongText
              onContentClick={onContentClick}
              key={'open'}
              item={{
                label: 'open notes',
                value: event.incidentOpenDesc,
              }}
            />,
            <BodyLongText
              onContentClick={onContentClick}
              key={'close'}
              item={{
                label: 'close notes',
                value: event.closeOutComments,
              }}
            />,
          ]}
        />
      );

    case 'fault':
      event = event as FaultEvent;
      return (
        <FaultEventTooltipContent
          event={event}
          onContentClick={onContentClick}
        />
      );

    case 'material':
      event = event as MaterialUsageEvent;
      return (
        <LayoutWrapper
          header={
            <BodyHeader
              title={event.partNo}
              clipboardLabel={event.workorderNumber}
            />
          }
          body={
            <MainBody
              onContentClick={onContentClick}
              left={[
                {
                  label: 'workorder',
                  value: event.workorderNumber,
                },
                {
                  label: 'part no.',
                  value: event.partNo,
                },
                {
                  label: 'position',
                  value: event.positionApplied,
                },
                {
                  label: 'price',
                  value: event.price,
                },
              ]}
              right={[
                {
                  label: 'quantity',
                  value: event.quantity,
                },
                {
                  label: 'shop',
                  value: event.shop,
                },
                {
                  label: 'usage date',
                  value: event.usageDate,
                  options: { formatTime: true },
                },
              ]}
            />
          }
          long={[
            <BodyLongText
              onContentClick={onContentClick}
              key={event.id}
              item={{
                label: 'removal reason',
                value: event.removalReason,
              }}
            />,
            <BodyLongText
              onContentClick={onContentClick}
              key={event.id}
              item={{
                label: 'description',
                value: event.description,
              }}
            />,
          ]}
        />
      );

    case 'rx':
      event = event as RXEvent;
      return (
        <LayoutWrapper
          header={
            <BodyHeader title={event.title} clipboardLabel={event.rxCaseId} />
          }
          body={
            <MainBody
              onContentClick={onContentClick}
              left={[
                {
                  label: 'rx case id',
                  value: event.rxCaseId,
                },
                {
                  label: 'urgency',
                  value: (
                    <TextChip
                      backgroundColor={UrgencyColorMap[event.urgency]}
                      label={event.urgency}
                    />
                  ),
                },
                {
                  label: 'est repair time',
                  value: event.estRepairTime,
                },
              ]}
              right={[
                {
                  label: 'view rx',
                  value: (
                    <StyledLink target='_blank' href={event.pdfLink}>
                      Download PDF
                    </StyledLink>
                  ),
                },
                {
                  label: 'opened',
                  value: event.rxOpenDate,
                  options: { formatTime: true },
                },
                {
                  label: 'closed',
                  value: event.rxCloseDate,
                  options: { formatTime: true },
                },
              ]}
            />
          }
          long={[
            <BodyLongText
              onContentClick={onContentClick}
              key={event.id}
              item={{
                label: 'line zero notes',
                value: event.lineZeroNotes ? (
                  <StripHTMLWrapper data={event.lineZeroNotes} />
                ) : undefined,
              }}
            />,
          ]}
        />
      );

    case 'call':
      event = event as CallEvent;
      return (
        <LayoutWrapper
          header={<BodyHeader title={event.location} />}
          body={
            <MainBody
              onContentClick={onContentClick}
              left={[
                {
                  label: 'issue type',
                  value: event.issueType,
                },
                {
                  label: 'call type',
                  value: event.callType,
                },
                {
                  label: 'agent',
                  value: event.agentName,
                },
              ]}
              right={[
                {
                  label: 'created',
                  value: event.creationDate,
                  options: { formatTime: true },
                },
                {
                  label: 'started',
                  value: event.callStartedOn,
                  options: { formatTime: true },
                },
                {
                  label: 'ended',
                  value: event.callEndedOn,
                  options: { formatTime: true },
                },
              ]}
            />
          }
          long={[
            <BodyLongText
              key={event.id}
              onContentClick={onContentClick}
              item={{
                label: 'notes/description',
                value: event.notesDesc,
              }}
            />,
          ]}
        />
      );

    case 'defect':
      event = event as DefectEvent;
      return (
        <LayoutWrapper
          header={
            <BodyHeader
              title={event.customerIncidentId}
              clipboardLabel={event.woNumber}
            />
          }
          body={
            <MainBody
              onContentClick={onContentClick}
              left={[
                {
                  label: 'repaired',
                  value: event.repairedFlag,
                },
                {
                  label: 'service type code',
                  value: event.serviceTypeCode,
                },
                {
                  label: 'b2b defect status',
                  value: event.b2bDefectStatus,
                },
                {
                  label: 'fmi flag',
                  value: event.fmiFlag,
                },
              ]}
              right={[
                {
                  label: 'created',
                  value: event.creationDate,
                  options: { formatTime: true },
                },
                {
                  label: 'last updated',
                  value: event.lastUpdateDate,
                  options: { formatTime: true },
                },
                {
                  label: 'service sheet status code',
                  value: event.serviceSheetStatusCode,
                },
              ]}
            />
          }
          long={[
            <BodyLongText
              onContentClick={onContentClick}
              key={`event.id${1}`}
              item={{
                label: 'defect comments',
                value: event.defectComments ? (
                  <StripHTMLWrapper data={event.defectComments} />
                ) : undefined,
              }}
            />,
            <BodyLongText
              onContentClick={onContentClick}
              key={`event.id${2}`}
              item={{
                label: 'repair comments',
                value: event.repairComments ? (
                  <StripHTMLWrapper data={event.repairComments} />
                ) : undefined,
              }}
            />,
            <BodyLongText
              onContentClick={onContentClick}
              key={`event.id${1}`}
              item={{
                label: 'service sheet comments',
                value: event.serviceSheetComments ? (
                  <StripHTMLWrapper data={event.serviceSheetComments} />
                ) : undefined,
              }}
            />,
          ]}
        />
      );

    case 'program':
      event = event as ProgramEvent;
      return (
        <LayoutWrapper
          header={
            <BodyHeader
              title={event.program}
              clipboardLabel={event.workorder}
            />
          }
          body={
            <MainBody
              onContentClick={onContentClick}
              left={[
                {
                  label: 'org',
                  value: event.org,
                },
                {
                  label: 'software code',
                  value: event.profileName,
                },
              ]}
              right={[
                {
                  label: 'profile status',
                  value: event.profileStatus,
                },
                {
                  label: 'completed on',
                  value: event.completionDate,
                  options: { formatTime: true },
                },
              ]}
            />
          }
          long={[
            <BodyLongText
              onContentClick={onContentClick}
              key={`event.id${1}`}
              item={{
                label: 'title',
                value: event.title,
              }}
            />,
            <BodyLongText
              onContentClick={onContentClick}
              key={`event.id${2}`}
              item={{
                label: 'description',
                value: event.description,
              }}
            />,
            <BodyLongText
              onContentClick={onContentClick}
              key={`event.id${3}`}
              item={{
                label: 'profile name',
                value: event.profileName,
              }}
            />,
          ]}
        />
      );

    default:
      return <></>;
  }
};
