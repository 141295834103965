import { END, eventChannel } from 'redux-saga';

// saga + server sent events are a bit confusing
// this link *kinda* explains what we do
// https://mohit5.medium.com/server-sent-events-with-typescript-and-redux-sagas-b90443128e13
// other background: https://redux-saga.js.org/docs/advanced/Channels/
// but in summary, we need this function to
//  emit events async for us to use in the handler
export const subscribeToDataStream = (eventSource: EventSource) => {
  const subscriber = (emitter) => {
    eventSource.onmessage = (event) => {
      // the incoming message is JSON
      const message = JSON.parse(event.data);
      emitter(message);

      // we end the stream once we get columns
      if (message.columns) {
        emitter(END);
      }
    };

    eventSource.onerror = (event) => {
      emitter({ error: event });
      eventSource.close();
      emitter(END);
    };

    // a cleanup function to close and end the stream
    return () => {
      eventSource.close();
      emitter(END);
    };
  };

  // return the event channel handler
  return eventChannel(subscriber);
};
