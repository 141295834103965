import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { dataCardTimestampFormat } from 'common/cards/Data/const';
import {
  selectTimeSettings,
  selectAssetDetails,
  selectApplicationContext,
  selectUser,
} from 'common/stores/globalSlice';

import { selectCase } from 'power-tool/stores/casesSlice';
import { selectRun } from 'power-tool/stores/runSlice';

import { subscribeToPowerToolEvents } from './api';
import { AppDispatch, RootState } from './store';
import { DateFormattingOptions, PowerToolEvent } from './types/types';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useUserFormattedTime = (
  timestampISO?: string,
  options?: DateFormattingOptions
) => {
  const timePreference = useAppSelector(selectTimeSettings);

  return useMemo(() => {
    if (timestampISO) {
      // get a DT object
      const timestamp = DateTime.fromISO(timestampISO, {
        zone: timePreference.zone,
      });

      if (timestamp.isValid) {
        if (options?.pretty) {
          return timestamp.toFormat(timePreference.dateTimeFormat12h);
        } else if (options?.dateOnly) {
          return timestamp.toFormat(timePreference.dateFormat);
        } else {
          return timestamp.toFormat(timePreference.dateTimeFormat);
        }
      }

      // otherwise we'll say its undefined
      return undefined;
    }
  }, [timestampISO, options, timePreference]);
};

export const useFixedFormattedTime = (timestampISO?: string) => {
  const timePreference = useAppSelector(selectTimeSettings);

  return useMemo(() => {
    if (timestampISO) {
      const timestamp = DateTime.fromISO(timestampISO, {
        setZone: true,
        locale: 'en-US',
      });

      if (timestamp.isValid) {
        return timestamp.toFormat(timePreference.dateTimeFormat, {
          locale: 'en-US',
        });
      }

      return undefined;
    }
  }, [timestampISO, timePreference]);
};

export const useFixedFormattedTimeWithZone = (timestampISO?: string) => {
  const timePreference = useAppSelector(selectTimeSettings);

  return useMemo(() => {
    if (timestampISO) {
      const timestamp = DateTime.fromISO(timestampISO, {
        setZone: true,
        locale: 'en-US',
      });

      if (timestamp.isValid) {
        return timestamp.toFormat('LL-dd-yy TTT', { locale: 'en-US' });
      }

      return undefined;
    }
  }, [timestampISO, timePreference]);
};

export const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>();

  // as soon as value changes
  // update the ref to it
  useEffect(() => {
    ref.current = value;
  }, [value]);

  // however, this return happens
  // prior to useEffect triggering (event loop)
  return ref.current;
};

export const useAssetFormattedTime = (timestampISO?: string) => {
  const timePreference = useAppSelector(selectTimeSettings);
  const asset = useAppSelector(selectAssetDetails);

  return useMemo(() => {
    if (timestampISO) {
      const timestamp = DateTime.fromISO(timestampISO, {
        zone: asset?.unitTimeZone ?? timePreference.zone,
        locale: 'en-US',
      });

      if (timestamp.isValid) {
        return timestamp.toFormat(dataCardTimestampFormat, { locale: 'en-US' });
      }

      return undefined;
    }
  }, [timestampISO, timePreference]);
};

// checks to see what 'level' the application is at
export const useIsAssetLevelHook = () => {
  const selectedCase = useAppSelector(selectCase);
  const run = useAppSelector(selectRun);

  // we are at the asset level in our application when:
  //   1. a case is not selected AND
  //   2. a run is not selected
  const isAssetLevel = React.useCallback(() => {
    return (
      (selectedCase === undefined || selectedCase.caseId === '') &&
      (run === undefined || run.objid === undefined || run.objid <= 0)
    );
  }, [selectedCase, run]);

  return { isAssetLevel };
};

// we define a custom hook to be used in components
// checks to see what 'level' the application is at
export const useIsCaseLevelHook = () => {
  const selectedCase = useAppSelector(selectCase);
  const run = useAppSelector(selectRun);

  // we are at the case level in our application when:
  //  1. a case is selected AND
  //  2. a run is not selected
  const isCaseLevel = React.useCallback(() => {
    return (
      selectedCase !== undefined &&
      selectedCase.caseId !== '' &&
      (run === undefined || run.objid === undefined || run.objid <= 0)
    );
  }, [selectedCase, run]);

  return { isCaseLevel };
};

// purpose of this hook is to monitor the case and asset values in our store
// if either of them change, we re-subscribe to the event stream from the backend
// this allows components to simply select the event rather than having to
// do the monitoring logic
export const useEventSelector = () => {
  const [event, setEvent] = useState<PowerToolEvent>();

  const selectedCase = useAppSelector(selectCase);
  const asset = useAppSelector(selectAssetDetails);
  const applicationContext = useAppSelector(selectApplicationContext);
  const user = useAppSelector(selectUser);

  useEffect(() => {
    // we only sub to events in power tool application context (or by default)
    if (
      asset &&
      user &&
      (applicationContext === undefined || applicationContext === 'power_tool')
    ) {
      subscribeToPowerToolEvents(
        user,
        selectedCase?.caseId,
        asset.vehicleObjid,
        (event) => {
          // console.log(event);

          // backend sends pings once a minute to keep us alive
          // but we dont want the rest of the application to know about this event
          if (event.event !== 'KEEP_ALIVE_PING') {
            // console.log(event)
            setEvent(event);
          }
        }
      );
    }
  }, [selectedCase, asset, applicationContext]);

  return event;
};

export const useRefState = <T>(value: T) => {
  const [state] = useState<T>(value);
  const ref = useRef<T>(state);

  useEffect(() => {
    ref.current = state;
  }, [state]);

  return { state, ref };
};
