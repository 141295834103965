import { ICellRendererParams } from 'ag-grid-community';

import { LazyTooltip } from 'common/components/general/LazyTooltip';

export const WindSpeedCell = (params: ICellRendererParams) => {
  const data = params.data;
  const speed = data ? data.WINDSPEEDMPH : '';
  const direction = data ? data.WINDDIR : '';
  const gust = data ? data.WINDGUSTMPH : '';
  return (
    <LazyTooltip title={`${direction} gusting to ${gust} MPH`}>
      <div>{speed}</div>
    </LazyTooltip>
  );
};
