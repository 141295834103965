import { AddSharp, Download } from '@mui/icons-material';
import { Box, Divider, Fab, IconButton } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useCallback, useMemo, useRef } from 'react';
import { v4 as uuid } from 'uuid';

import { ContentLabel } from 'common/components/general/ContentLabel';
import { useAppDispatch, useAppSelector } from 'common/hooks';
import {
  selectApplicationContext,
  selectAssetDetails,
} from 'common/stores/globalSlice';
import {
  addTimelineEvent,
  changeTimelineMode,
  selectTimelineEvents,
  selectTimelineModeToggle,
} from 'common/stores/timelineSlice';
import { captureHTMLElement } from 'common/util/utils';

import { WriteInEvent } from 'loco-history/types';

import { EventsWrapper } from './components/events/EventsWrapper';
import { LocomotiveStory } from './components/story/LocomotiveStory';
import { EventTimeline } from './components/timeline/EventTimeline';

const transition = 'all 0.35s ease-in-out 0.25s';

/**
 * @returns {JSX.Element} single large card wrapper that includes timeline, events, and loco story
 */
export const HistoryCard = () => {
  const dispatch = useAppDispatch();
  const application = useAppSelector(selectApplicationContext);
  const asset = useAppSelector(selectAssetDetails);
  const storyEvents = useAppSelector(selectTimelineEvents);
  const modeToggleState = useAppSelector(selectTimelineModeToggle);

  const ref = useRef<HTMLDivElement | null>(null);

  const filename = useMemo(() => {
    return `Locomotive Story ${asset?.vehicleHdr ?? ''}-${
      asset?.roadNumber ?? ''
    } ${DateTime.now().toLocaleString(DateTime.DATE_MED)}`;
  }, [asset]);

  const disabled = useMemo(() => {
    return storyEvents.length === 0;
  }, [storyEvents]);

  const onAddStoryItem = useCallback(() => {
    const event: WriteInEvent = {
      timestamp: new Date().toISOString(),
      type: 'write-in',
      id: uuid(),
      someProp: '',
    };

    dispatch(
      addTimelineEvent({
        id: event.id,
        timestamp: event.timestamp,
        type: event.type,
        event: event,
        storyProps: {
          text: 'Click to edit...',
          title: 'Write-In',
        },
      })
    );
  }, [dispatch]);

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {application === 'springboard' ? (
        <></>
      ) : (
        <React.Fragment>
          <ContentLabel label='Timeline' />
          <EventTimeline />

          <Divider sx={{ m: 0.5 }} />
        </React.Fragment>
      )}

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          height: '100%',
        }}
      >
        <div
          id='event-transition-wrapper'
          onTransitionEnd={(event) => {
            if (
              event.currentTarget.id === 'event-transition-wrapper' &&
              (event.target as HTMLElement).id === 'event-transition-wrapper'
            ) {
              dispatch(changeTimelineMode(modeToggleState));
            }
          }}
          style={{
            transition,
            flex: modeToggleState === 'build' ? '0 0 30%' : '0 0 70%',
          }}
        >
          <EventsWrapper />
        </div>

        <div
          style={{
            transition,
            flex: modeToggleState === 'build' ? '0 0 65%' : '0 0 30%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box display='flex' justifyContent={'space-between'} sx={{ px: 2 }}>
            <div style={{ width: '30px' }}></div>
            <div style={{ position: 'relative' }}>
              <ContentLabel label='Story' />
              <Fab
                size='small'
                color='primary'
                onClick={onAddStoryItem}
                sx={{
                  minHeight: 'unset',
                  height: '28px',
                  width: '28px',
                  position: 'absolute',
                  color: 'white',
                  left: '60px',
                  top: '5px',
                }}
              >
                <AddSharp fontSize='small' style={{ marginLeft: '1px' }} />
              </Fab>
            </div>

            <IconButton
              onClick={() =>
                captureHTMLElement({
                  element: ref.current,
                  filename: filename,
                  fixedWidth: 1200,
                })
              }
              color='primary'
              disabled={disabled}
            >
              <Download />
            </IconButton>
          </Box>
          <LocomotiveStory ref={ref} />
        </div>
      </div>
    </div>
  );
};
