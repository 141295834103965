import { ArrowRightAlt } from '@mui/icons-material';
import { Tooltip, Paper } from '@mui/material';
import { useMemo, CSSProperties } from 'react';

import { useAppSelector } from 'common/hooks';
import { selectCustomer } from 'common/stores/globalSlice';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

import { ActiveAxlesComponent } from 'train-tool/cards/Status/components/ActiveAxlesComponent';
import ClosedSwitch from 'train-tool/cards/Status/components/icons/ClosedSwitch';
import { HighAdhesionIcon } from 'train-tool/cards/Status/components/icons/HighAdhesionIcon';
import { ShutterIcon } from 'train-tool/cards/Status/components/icons/ShutterIcon';
import { SteerableIcon } from 'train-tool/cards/Status/components/icons/SteerableIcon';
import { UnivalveIcon } from 'train-tool/cards/Status/components/icons/UnivalveIcon';
import { TrainAsset } from 'train-tool/types';

/**
 *
 * @param {any} props implicit component props
 * @param {TrainAsset?} props.asset asset to display details for
 * @returns {JSX.Element} component wrapper for all the additional icons and statues we display below a loco icon. in the case of a null props.asset, a label is generated instead.
 */
export const LocoHeaderDetails = (props: { asset?: TrainAsset }) => {
  const customer = useAppSelector(selectCustomer);

  const textProps = useMemo(() => {
    return {
      fontSize: '11px',
      lineHeight: 1,
    } as CSSProperties;
  }, []);

  const modelValue = useMemo(() => {
    if (customer === 'CSX' && props.asset?.vehicleModel === 'D89CCA') {
      return 'D8-EFI-CCA';
    }

    return props.asset?.vehicleModel ?? '-';
  }, [customer, props.asset?.vehicleModel]);

  const modelLabel = useMemo(() => {
    if (props.asset) {
      return (
        <div
          style={{
            ...textProps,
            fontWeight: 600,
          }}
        >
          {modelValue}
        </div>
      );
    } else {
      return (
        <div
          style={{
            ...textProps,
            fontWeight: 600,
            color: PowerToolDarkTheme.neutralDark500,
          }}
        >
          {'Model'}
        </div>
      );
    }
  }, [props.asset, textProps, modelValue]);

  const hpLabel = useMemo(() => {
    if (props.asset) {
      return (
        <div
          style={{
            ...textProps,
            fontWeight: 500,
          }}
        >
          <span>
            {props.asset.lastN8HorsePower && props.asset.vehicleHdr === customer
              ? `${props.asset.lastN8HorsePower} `
              : '-'}
          </span>
          <span style={{ fontWeight: 300 }}>
            {props.asset.lastN8HorsePower && props.asset.vehicleHdr === customer
              ? `HP`
              : ''}
          </span>
        </div>
      );
    } else {
      return (
        <div
          style={{
            ...textProps,
            fontWeight: '600',
            color: PowerToolDarkTheme.neutralDark500,
          }}
        >
          Last N8 HP
        </div>
      );
    }
  }, [props.asset, customer, textProps]);

  const activeAxles = useMemo(() => {
    if (props.asset) {
      return (
        <ActiveAxlesComponent
          axleConfig={props.asset.axleConfig}
          activeAxles={
            !props.asset.foreign
              ? props.asset.recentActiveAxleStatus
              : undefined
          }
        />
      );
    } else {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            color: PowerToolDarkTheme.neutralDark500,
          }}
        >
          <div style={{ ...textProps, fontWeight: 600 }}>Axles 1</div>
          <ArrowRightAlt style={{ fontSize: '16px' }} />
          <div style={{ ...textProps, fontWeight: 600 }}>6</div>
        </div>
      );
    }
  }, [props.asset, textProps]);

  const specialEquipment = useMemo(() => {
    if (props.asset) {
      // High Adhesion trucks
      if (props.asset.truckConfig === '0') {
        return (
          <Tooltip title={'HIAD Trucks'}>
            <span>
              <HighAdhesionIcon
                width={60}
                height={36}
                fill={PowerToolDarkTheme.neutralDark700}
              />
            </span>
          </Tooltip>
        );
      }

      // Steerable
      else if (props.asset.truckConfig === '1') {
        return (
          <Tooltip title={'Steerable Trucks'}>
            <span>
              <SteerableIcon
                width={60}
                height={36}
                fill={PowerToolDarkTheme.neutralDark700}
              />
            </span>
          </Tooltip>
        );
      }

      // TIM Switch
      else if (props.asset.timSwitch === true) {
        return (
          <Tooltip title={'TIM Switch Equipped'}>
            <span>
              <ClosedSwitch fill={PowerToolDarkTheme.neutralDark500} />
            </span>
          </Tooltip>
        );
      }

      // Univalve Cooling
      else if (props.asset.coolingType === 'univalve') {
        return (
          <Tooltip title={'Univalve Equipped'}>
            <span>
              <UnivalveIcon fill={PowerToolDarkTheme.neutralDark700} />
            </span>
          </Tooltip>
        );
      }

      // Shutter Equipped
      else if (props.asset.coolingType === 'shutter') {
        return (
          <Tooltip title={'Shutter Equipped'}>
            <span>
              <ShutterIcon fill={PowerToolDarkTheme.neutralDark700} />
            </span>
          </Tooltip>
        );
      }

      // No Special Icon
      else {
        return <ClosedSwitch fill='transparent' />;
      }
    } else {
      return (
        <div
          style={{
            ...textProps,
            fontWeight: '600',
            color: PowerToolDarkTheme.neutralDark500,
          }}
        >
          Special Equipment
        </div>
      );
    }
  }, [props.asset, textProps]);

  return (
    <Paper
      variant='outlined'
      sx={{
        width: '126px',
        backgroundImage:
          'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
        backgroundColor: '#172839',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        mt: 1,
        gap: '1px',
        py: 0.25,
      }}
    >
      {modelLabel}
      {hpLabel}
      {activeAxles}
      {specialEquipment}
    </Paper>
  );
};
