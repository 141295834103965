import { SxProps } from '@mui/material';
import { useEffect, useState } from 'react';

import { useFetchAssets } from 'common/api/hooks';
import {
  PowerToolSearch,
  SearchOption,
} from 'common/features/search/PowerToolSearch';
import { useAppDispatch, useAppSelector } from 'common/hooks';
import { selectApplicationContext } from 'common/stores/globalSlice';
import { Asset } from 'common/types/types';

import { setAssetAction } from 'power-tool/saga/actionSlice';

import { setTrainSearchItem } from 'train-tool/stores/trainSlice';

export type AssetSearchProps = {
  inputSx?: SxProps;
};
/**
 *
 * @param {AssetSearchProps} props sx props for the MUI search component
 * @returns {JSX.Element} MUI search component containing assets
 */
export const AssetSearch = (props: AssetSearchProps) => {
  const dispatch = useAppDispatch();
  const application = useAppSelector(selectApplicationContext);

  const [searchResults, setSearchResults] = useState<SearchOption[]>([]);

  const { assets, assetsStatus } = useFetchAssets();

  useEffect(() => {
    if (assets) {
      setSearchResults(
        assets.map((asset) => {
          return {
            label: `${asset.vehicleHdr}-${asset.roadNumber}`,
            value: asset,
            type: 'asset',
          };
        })
      );
    }
  }, [assets]);

  return (
    <PowerToolSearch
      placeholder={
        assetsStatus === 'loading' ? 'Loading...' : `Search for an asset`
      }
      inputSx={props.inputSx}
      optionList={searchResults}
      noOptionsText={assetsStatus === 'loading' ? 'Loading...' : undefined}
      onSelect={(selectedValue) => {
        dispatch(setAssetAction(selectedValue.value as Asset));
        if (application === 'asset_view') {
          dispatch(
            setTrainSearchItem({
              item: selectedValue.value as Asset,
              type: 'asset',
            })
          );
        }
      }}
    />
  );
};
