import { Chip, Stack, TextField } from '@mui/material';
import { GridApi, GridOptions, RowNode } from 'ag-grid-community';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { AgGridCustom } from 'common/components/ag-grid/DensePowerToolGrid';
import { CustomColDef } from 'common/components/ag-grid/types';
import { useAppSelector } from 'common/hooks';
import { selectAssetDetails } from 'common/stores/globalSlice';

import { getVehicleBomConfig } from 'power-tool/api';
import { VehicleConfig } from 'power-tool/types';

import { ConfigTooltipCell } from './ConfigTooltipCell';

export function ConfigCard() {
  const gridApi = useRef<GridApi>();

  const vehicleInfo = useAppSelector(selectAssetDetails);
  const [bomConfigs, setBomConfigs] = useState<VehicleConfig[]>([]);
  const [activeFilters, setActiveFilters] = useState<string>('');

  const activeFilterRef = useRef(activeFilters);

  // when our filter changes, let the grid know
  useEffect(() => {
    activeFilterRef.current = activeFilters;
    gridApi.current?.onFilterChanged();
  }, [activeFilters]);

  useEffect(() => {
    if (vehicleInfo && vehicleInfo.vehicleObjid > 0) {
      getVehicleBomConfig(vehicleInfo.vehicleObjid)
        .then((response) => {
          if (response) {
            setBomConfigs(response);
          }
        })
        .catch((err) =>
          console.log('error calling bom config for vehicle', err)
        );
    }
  }, [vehicleInfo]);

  const columns: CustomColDef[] = useMemo(
    () => [
      {
        field: 'CONFIG_ITEM',
        headerName: 'Item',
        cellRenderer: ConfigTooltipCell,
      },
      {
        field: 'CURRENT_VERSION',
        headerName: 'Value',
        width: 80,
      },
    ],
    []
  );

  const gridOptions: GridOptions = {
    onFirstDataRendered: (params) => {
      params.api.sizeColumnsToFit();
      gridApi.current = params.api;
    },

    // our filter chips act as an external filter
    isExternalFilterPresent: () => activeFilterRef.current !== '',
    doesExternalFilterPass: (node: RowNode) => {
      return node.data.CONFIG_ITEM.includes(activeFilterRef.current);
    },
    defaultColDef: {
      resizable: true,
      suppressMenu: true,
      filter: 'agSetColumnFilter',
    },
    // filter
    suppressRowClickSelection: true,
    headerHeight: 24,
  };

  const filters: string[] = ['CFG', 'PTC', 'PNL', 'Service'];

  const FilterChip = (props: { filter: string }) => {
    const [filter, setFilter] = useState<string>(props.filter);
    useEffect(() => {
      setFilter(props.filter);
    }, [props.filter]);

    return (
      <Chip
        label={filter}
        size='small'
        variant={activeFilters.includes(filter) ? 'filled' : 'outlined'}
        color='primary'
        sx={{
          fontSize: '10px',
          paddingTop: '0px',
          paddingBottom: '0px',
          borderRadius: '16px',
          '.MuiChip-label': {
            paddingLeft: '3px',
            paddingRight: '3px',
          },
        }}
        onClick={(event) => {
          if (activeFilters === filter) {
            setActiveFilters('');
          } else {
            setActiveFilters(filter);
          }
        }}
      />
    );
  };

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Stack
        spacing={0.75}
        direction={'row'}
        style={{
          margin: '4px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <TextField
          label='Search...'
          variant='outlined'
          size='small'
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            gridApi.current?.setQuickFilter(event.target.value)
          }
          inputProps={{
            style: { fontSize: 12 },
          }}
          InputLabelProps={{
            style: { fontSize: 12 },
          }}
          sx={{
            mt: '3px',
          }}
          fullWidth
        />
        {filters.map((filter) => (
          <FilterChip key={filter} filter={filter} />
        ))}
      </Stack>
      <AgGridCustom data={bomConfigs} columns={columns} options={gridOptions} />
    </div>
  );
}

export default ConfigCard;
