import { AlertColor, Alert } from '@mui/material';

/**
 *
 * @param {any} props implicit component props
 * @param {AlertColor} props.severity MUI severity
 * @param {string} props.message text content of the alert
 * @returns {JSX.Element} generic MUI outlined text alert component
 */
export const GenericAlertComponent = (props: {
  severity: AlertColor;
  message: string;
}) => {
  return (
    <Alert
      variant='outlined'
      severity={props.severity}
      sx={{
        width: '100%',
        '.MuiAlert-message': { p: 0 },
        '.MuiAlert-icon': { p: 0 },
        '&.MuiPaper-root': { p: '4px', alignItems: 'center' },
      }}
    >
      {props.message}
    </Alert>
  );
};
