import { ColDef, GridOptions } from 'ag-grid-community';
import chroma from 'chroma-js';
import { useMemo } from 'react';

import { PowerToolGrid } from 'common/components/ag-grid/PowerToolGrid';
import { HTMLDecodedCell } from 'common/components/ag-grid/cell-renderers/HTMLDecodedCell';
import { ZonedTimeCell } from 'common/components/ag-grid/cell-renderers/ZonedTimeCell';
import { useAppSelector } from 'common/hooks';
import { selectRule } from 'common/stores/globalSlice';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

import { RuleHistory } from 'power-tool/cards/RuleOutput/types';
import { selectRuleDetails } from 'power-tool/stores/ruleOutputSlice';

export const RuleHistoryGrid = () => {
  const rule = useAppSelector(selectRule);
  const ruleDetails = useAppSelector(selectRuleDetails);
  const data = ruleDetails?.ruleHistory.map((row) => {
    let newDateString;
    //dates in the history are not in ISO format. switching to ISO safely before passing it to the renderer
    try {
      newDateString = new Date(row.createdDate).toISOString();
    } catch (e) {
      newDateString = undefined;
    }
    return { ...row, createdDate: newDateString };
  });

  const columns: ColDef[] = useMemo((): ColDef[] => {
    if (ruleDetails?.ruleHistory) {
      return [
        {
          field: 'createdDate',
          headerName: 'Created',
          filter: 'agDateColumnFilter',
          cellRenderer: ZonedTimeCell,
          suppressSizeToFit: true,
          width: 125,
        },
        {
          field: 'createdBY',
          headerName: 'Author',
          width: 110,
          suppressSizeToFit: true,
        },
        {
          field: 'ruleID',
          headerName: 'ID',
          width: 70,
          suppressSizeToFit: true,
        },
        {
          field: 'revisionHistory',
          headerName: 'Revision Notes',
          width: 548,
          cellRenderer: HTMLDecodedCell,
          wrapText: true,
          autoHeight: true,
        },
      ];
    }
    return [];
  }, [ruleDetails]);

  const gridOptions: GridOptions<RuleHistory> = {
    onFirstDataRendered: (params) => {
      params.api.sizeColumnsToFit();
    },
    getRowStyle: (params) => {
      if (params.data?.ruleID === rule.toString()) {
        return {
          backgroundColor: chroma(PowerToolDarkTheme.statusGreen500)
            .alpha(0.35)
            .hex(),
          fontWeight: 'bold',
        };
      }
    },
  };

  return (
    <div style={{ minWidth: 800, height: 500, padding: 10, paddingTop: 0 }}>
      <PowerToolGrid
        defaultColDef={{
          filter: 'agSetColumnFilter',
          menuTabs: ['filterMenuTab', 'generalMenuTab'],
          sortable: true,
          resizable: true,
          wrapHeaderText: true,
          autoHeight: true,
        }}
        headerHeight={25}
        columns={columns}
        data={data}
        gridOptions={gridOptions}
      />
    </div>
  );
};
