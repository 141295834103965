import { ICellRendererParams } from 'ag-grid-community';

import { useAssetFormattedTime, useAppSelector } from 'common/hooks';
import { selectApplicationContext } from 'common/stores/globalSlice';

import { ZonedTimeCell } from './ZonedTimeCell';

export const AssetTimeZoneCell = (props: ICellRendererParams) => {
  const timestamp = useAssetFormattedTime(props.valueFormatted ?? props.value);
  const application = useAppSelector(selectApplicationContext);

  switch (application) {
    // if we are using TAT data screen, we display the times
    // formatted to the customer's preferred TZ
    case 'train_analysis':
      return ZonedTimeCell(props);

    // otherwise, we return the time relative to the
    //  unit's main controller source
    case 'power_tool':
    case 'virtual_tester':
    default:
      return <span>{timestamp}</span>;
  }
};
