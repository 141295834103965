import {
  Add,
  CloseOutlined,
  Fullscreen,
  OpenInFull,
  Remove,
} from '@mui/icons-material';
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogContent,
  DialogTitle,
  ImageListItem,
  Paper,
  Zoom,
} from '@mui/material';
import chroma from 'chroma-js';
import React, { useCallback, useEffect, useState } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

export type AnomPlotProps = {
  src: string;
  label: string;
};

type ControlProps = {
  action?: () => void;
  content?: JSX.Element;
  sx?: React.CSSProperties;
};
const ControlButton = (props: ControlProps) => {
  return (
    <Button
      style={{
        height: '24px',
        width: '24px',
        // minWidth: 'unset',
        borderColor: PowerToolDarkTheme.neutralDark500,
        zIndex: 1,
      }}
      sx={{
        color: PowerToolDarkTheme.primary,
        backgroundColor: PowerToolDarkTheme.frosted,
        '&:hover': {
          backgroundColor: chroma(PowerToolDarkTheme.frosted)
            .brighten(0.5)
            .hex(),
        },
        // padding: 0,
        ...props.sx,
      }}
      onClick={props.action}
    >
      {props.content ?? <></>}
    </Button>
  );
};

export const AnomPlot = (props: AnomPlotProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const onClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    setIsExpanded(false);
  };

  const previewRenderer = useCallback(() => {
    return (
      <Paper
        elevation={12}
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          maxHeight: '100%',
          maxWidth: '100%',
          backgroundColor: '#12121200',
          overflow: 'hidden',
        }}
      >
        <img
          style={{
            width: 'auto',
            height: 'auto',
            maxWidth: 'inherit',
            maxHeight: 'inherit',
          }}
          src={props.src}
          alt={props.label}
          loading='lazy'
        />
      </Paper>
    );
  }, [props.label, props.src]);

  return (
    <div
      style={{
        height: '100%',
        overflow: 'auto',
        marginRight: '13px',
        display: 'flex',
        justifyContent: 'center',
        justifyItems: 'center',
      }}
    >
      <Zoom in={true}>
        <ImageListItem
          key={props.src}
          sx={{
            pt: 0,
            lineHeight: 1.5,
            maxHeight: 'calc(100% - 8px)',
            width: '100%',
            minHeight: undefined,
            maxWidth: 'calc(100% - 8px)',
            display: 'flex',
            justifyContent: 'center',
            justifyItems: 'center',
          }}
        >
          <Button
            onClick={() => setIsExpanded(true)}
            size='small'
            variant='outlined'
            style={{
              position: 'absolute',
              right: '10px',
              top: '5px',
              minWidth: 0,
            }}
          >
            <OpenInFull fontSize='small' />
          </Button>

          {previewRenderer()}

          <AnomView img={props} isOpen={isExpanded} onClose={onClose} />
        </ImageListItem>
      </Zoom>
    </div>
  );
};

export type AnomViewProps = {
  img: AnomPlotProps;
  isOpen: boolean;
  onClose: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void;
};

export const AnomView = (props: AnomViewProps) => {
  const [open, setOpen] = useState<boolean>(props.isOpen);

  useEffect(() => {
    setOpen(props.isOpen);
  }, [props.isOpen]);

  const onClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    setOpen(false);
    props.onClose(event, reason);
  };

  return (
    <Dialog
      PaperProps={{
        style: {
          backgroundColor: PowerToolDarkTheme.main,
          backgroundImage: 'unset',
          width: 'auto',
          maxWidth: 'unset',
        },
      }}
      open={open}
      disableScrollLock={true}
      onClose={onClose}
    >
      <DialogTitle>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            sx={{
              alignSelf: 'flex-start',
              minWidth: 'unset',
              padding: '4px',
            }}
            onClick={() => onClose({}, 'backdropClick')}
          >
            <CloseOutlined />
          </Button>
        </div>
      </DialogTitle>

      <DialogContent style={{ overflow: 'hidden' }}>
        <TransformWrapper>
          {({ zoomIn, zoomOut, resetTransform }) => (
            <React.Fragment>
              <ButtonGroup sx={{ float: 'right', paddingBottom: '3px' }}>
                <ControlButton
                  action={() => {
                    zoomIn();
                  }}
                  content={<Add fontSize='small' />}
                />
                <ControlButton
                  action={() => {
                    zoomOut();
                  }}
                  content={<Remove fontSize='small' />}
                />
                <ControlButton
                  action={() => {
                    resetTransform();
                  }}
                  content={<Fullscreen fontSize='small' />}
                />
              </ButtonGroup>
              <TransformComponent
                wrapperClass='anomPlotTransformWrapper'
                contentStyle={{ justifyContent: 'center' }}
              >
                <img
                  src={props.img.src}
                  alt={props.img.label}
                  style={{ maxWidth: '100%', maxHeight: '80vh' }}
                />
              </TransformComponent>
            </React.Fragment>
          )}
        </TransformWrapper>
      </DialogContent>
    </Dialog>
  );
};
