import { History, Train } from '@mui/icons-material';
import { IconButton, Menu } from '@mui/material';
import React, { useState, useEffect } from 'react';

import { ClipboardLabel } from 'common/components/card/CardHeaderLabels';
import { useAppDispatch, useAppSelector } from 'common/hooks';
import { selectApplicationContext } from 'common/stores/globalSlice';
import { Asset } from 'common/types/types';
import { getRoadNumber } from 'common/util/utils';

import {
  selectTrainSearchItem,
  setTrainSearchItem,
} from 'train-tool/stores/trainSlice';
import { TrainSearchItem } from 'train-tool/types';

/**
 * @returns {JSX.Element} FAB component that tracks previous train and asset searches, and allows users to select previously searched items. CURRENTLY DISABLED FOR PRODUCTION
 */
export const SearchHistory = () => {
  const dispatch = useAppDispatch();

  const application = useAppSelector(selectApplicationContext);
  const searchItem = useAppSelector(selectTrainSearchItem);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [items, setItems] = useState<Record<string, JSX.Element>>({});

  const open = Boolean(anchorEl);

  /**
   * @description effectively opens the history menu
   * @param {React.MouseEvent<HTMLButtonElement>} event mouse click event
   */
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * @description closes the history menu
   */
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (searchItem) {
      const id =
        searchItem.type === 'asset'
          ? getRoadNumber(searchItem.item as Asset)
          : (searchItem.item as TrainSearchItem).trainId;
      const onClick = (event: React.MouseEvent<HTMLDivElement>) => {
        dispatch(setTrainSearchItem(searchItem));
      };

      setItems((old) => ({
        ...old,
        [id]: (
          <ClipboardLabel
            key={id}
            onClick={onClick}
            role='button'
            clipboardValue={id}
            sxProps={{ marginTop: 0, marginBottom: '8px', width: '180px' }}
            value={
              <React.Fragment>
                <Train fontSize='small' />
                {id}
              </React.Fragment>
            }
          />
        ),
      }));
    }
  }, [searchItem, dispatch]);

  if (
    application === 'train_analysis' ||
    application === 'asset_research' ||
    application === 'asset_view'
  ) {
    return (
      <React.Fragment>
        <IconButton
          onClick={handleClick}
          sx={{ ml: 1 }}
          disabled={Object.values(items).length === 0}
        >
          <History />
        </IconButton>
        <Menu
          PaperProps={{
            sx: {
              backgroundColor: 'transparent',
              mb: 5,
              backdropFilter: 'blur(5px)',
            },
          }}
          MenuListProps={{
            sx: {
              padding: '8px',
              paddingBottom: 0,
            },
          }}
          id='basic-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          disableScrollLock
        >
          {Object.values(items)}
        </Menu>
      </React.Fragment>
    );
  }

  return <></>;
};
