import { Leaderboard, MenuSharp, Timeline } from '@mui/icons-material';
import { Stack, Zoom } from '@mui/material';
import { IHeaderParams } from 'ag-grid-community';
import { useRef, useCallback, useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';

import { DatapackPlotValue } from 'common/features/datapack/types';
import { useAppDispatch, useAppSelector } from 'common/hooks';
import {
  plotDatapackParameter,
  removeDatapackParameter,
  selectPlottedDatapackParameters,
} from 'common/stores/datpacksSlice';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

const HoverText = styled.div`
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const HoverIcon = styled(MenuSharp)`
  :hover {
    color: #2196f3;
  }
`;

export const DatapackGridHeader = (props: IHeaderParams) => {
  const [visibility, setVisibility] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const column: string =
    props.column.getColDef().headerName ?? props.column.getColId();

  const plottedParameters: Record<string, any[]> | undefined = useAppSelector(
    selectPlottedDatapackParameters
  );
  const refButton = useRef<HTMLDivElement | null>(null);

  const plotted = useMemo(() => {
    return Object.keys(plottedParameters).includes(column);
  }, [column, plottedParameters]);

  const onMenuClicked = useCallback(() => {
    if (refButton.current) {
      props.showColumnMenu(refButton.current);
    }
  }, []);

  const menu = useMemo(() => {
    if (props.enableMenu) {
      return (
        <div
          ref={refButton}
          className='customHeaderMenuButton'
          onClick={onMenuClicked}
        >
          <HoverIcon
            style={{ visibility: visibility ? 'visible' : 'hidden' }}
            fontSize='small'
          />
        </div>
      );
    }
    return <></>;
  }, [props.enableMenu, visibility]);

  const onHeaderClick = useCallback(() => {
    if (plotted) {
      dispatch(removeDatapackParameter(column));
    } else {
      const data: DatapackPlotValue[] = [];
      props.api.forEachNodeAfterFilter((node) => {
        data.push({
          sampleNo: parseFloat(node.data['SAMPLE_NO']),
          timestamp: node.data['INCIDENT_TIMESTAMP'],
          value: parseFloat(node.data[props.column.getColId()]),
        });
      });

      dispatch(plotDatapackParameter({ column, data }));
    }
  }, [column, plotted, dispatch]);

  return (
    <div
      onMouseEnter={() => setVisibility(true)}
      onMouseLeave={() => setVisibility(false)}
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flex: '1 0 0',
        alignItems: 'center',
      }}
    >
      <HoverText onClick={onHeaderClick}>{props.displayName}</HoverText>
      <Stack>
        {plotted ? (
          <Zoom in={plotted}>
            <Timeline
              fontSize='small'
              sx={{ color: PowerToolDarkTheme.voltBlue400 }}
            />
          </Zoom>
        ) : (
          <></>
        )}
        {menu}
      </Stack>
    </div>
  );
};
