// https://stackoverflow.com/questions/31626231/custom-error-class-in-typescript
// https://github.com/Microsoft/TypeScript-wiki/blob/main/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
// https://axios-http.com/docs/handling_errors

export class PowerToolError extends Error {
  public traceId: string | undefined;

  constructor(m: string, traceId: string | undefined = undefined) {
    super(m);

    this.traceId = traceId;

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, PowerToolError.prototype);
  }
}
