import { Tooltip } from '@mui/material';

import { useUserFormattedTime } from 'common/hooks';

export const ConfigTooltipCell = (params) => {
  return (
    <Tooltip
      placement='left'
      enterDelay={1}
      title={
        <div>
          <div>{`Updated by ${params.data.LAST_UPDATED_BY}`}</div>
          <div>{`${useUserFormattedTime(params.data.LAST_UPDATED_DATE)}`}</div>
        </div>
      }
    >
      <div>{params.value}</div>
    </Tooltip>
  );
};
