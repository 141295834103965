import { Fade, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';

import { LoadingSpinner } from 'common/components/LoadingComponents';

import { TrainAndAssetSearch } from 'train-tool/features/TrainAndAssetSearch';
import { useFetchTrain } from 'train-tool/hooks/apiHooks';

import { SwitchesAndControlsComponent } from './components/SwitchesAndControlsComponent';

/**
 * @returns {JSX.Element} card wrapper component that contains either a landing screen with for no data, or all the monitor parameter information for a given train
 */
export const TrainStatusCard = () => {
  const { train, trainStatus, isPlaceholderData } = useFetchTrain();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    // if we are loaded but train ID is null, we got nothing back from DB
    if (
      train &&
      train.locomotives.length === 0 &&
      isPlaceholderData === false
    ) {
      enqueueSnackbar(
        "Looks like we can't find anything on the train you searched. Try a different search",
        { variant: 'info' }
      );
    } else if (trainStatus === 'error') {
      enqueueSnackbar(
        'Whoops! Looks like we ran into a problem. Try a different search.',
        { variant: 'warning' }
      );
    }
  }, [train, trainStatus, isPlaceholderData, enqueueSnackbar]);

  if (train && train.locomotives.length > 0 && !isPlaceholderData) {
    return (
      <Fade in={true} style={{ transitionDelay: '500ms' }} timeout={1000}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <SwitchesAndControlsComponent />
        </div>
      </Fade>
    );
  } else if (train?.trainId === null && isPlaceholderData) {
    return (
      <Fade in={true} style={{ transitionDelay: '500ms' }} timeout={1000}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <LoadingSpinner />
        </div>
      </Fade>
    );
  } else {
    return (
      <Fade in={true} style={{ transitionDelay: '500ms' }} timeout={1000}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant='h2' sx={{ fontSize: '28px', mb: 3 }}>
            Start your train analysis here
          </Typography>
          <TrainAndAssetSearch inputSx={{ minWidth: '300px' }} />
        </div>
      </Fade>
    );
  }
};

export default TrainStatusCard;
