import { BugReport } from '@mui/icons-material';
import {
  TextField,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  IconButton,
  Stack,
  Checkbox,
  FormControlLabel,
  FormControl,
  Fade,
} from '@mui/material';
import {
  ColDef,
  RowSelectedEvent,
  GridApi,
  FirstDataRenderedEvent,
  GridReadyEvent,
  ColumnApi,
  RowNode,
  IDetailCellRendererParams,
  GetQuickFilterTextParams,
  GridColumnsChangedEvent,
} from 'ag-grid-community';
import React, { useState, useMemo, useCallback, useEffect } from 'react';

import { LoadingSpinnerOverlayLoading } from 'common/components/LoadingComponents';
import { MuiFloatingFilterComponent } from 'common/components/ag-grid/MUIFloatingFilter';
import { PowerToolGrid } from 'common/components/ag-grid/PowerToolGrid';
import { TimelineEvent } from 'common/features/timeline/types';
import { useAppDispatch, useAppSelector } from 'common/hooks';
import { selectAssetDetails } from 'common/stores/globalSlice';
import {
  addTimelineEvent,
  removeTimelineEvent,
  selectSearchText,
  selectTimelineEvents,
  selectTimelineMode,
  updateSearchText,
} from 'common/stores/timelineSlice';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

import { HighlightCellRenderer } from 'loco-history/cards/Timeline/components/events/grids/renderers/HighlightCellRenderer';
import { createTimelineEvent } from 'loco-history/cards/Timeline/components/events/utils';
import { EventDetailCell } from 'loco-history/components/EventDetailComponents';
import { AnyTimelineEvent } from 'loco-history/types';

/**
 *
 * @template T
 * @param {any} props implicit destructured props
 * @param {ColDef[]} props.columns ag-grid column definitions for data (T)
 * @param {T[]} props.data data to go into the grid
 * @param {JSX.Element | undefined} props.headerComponent additional component to render above the grid
 * @param {(props: IDetailCellRendererParams<T>) => JSX.Element | undefined} props.detailRenderer cell renderer function for the detail view (row expand)
 * @param {(event: React.ChangeEvent<HTMLInputElement>) => void} props.onFilterSelected callback for when a filter type is selected
 * @param {(node: RowNode<T>) => boolean | undefined} props.doesExternalFilterPass callback to see if a specific event passes its external filter
 * @param {(event: GridReadyEvent<T>) => void | undefined} props.onGridReady callback for when the grid is loaded and ready
 * @returns {JSX.Element} generic events grid; parents of this component will provide type
 */
export const EventsGrid = <T extends AnyTimelineEvent>({
  columns,
  data,
  headerComponent,
  detailRenderer,
  onFilterSelected,
  doesExternalFilterPass,
  onGridReady,
}: {
  columns: ColDef<T>[];
  data?: T[];
  headerComponent?: JSX.Element;
  detailRenderer?: (
    props: IDetailCellRendererParams<T>
  ) => JSX.Element | undefined;
  onFilterSelected?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  doesExternalFilterPass?: (node: RowNode<T>) => boolean;
  onGridReady?: (event: GridReadyEvent<T>) => void;
}) => {
  const dispatch = useAppDispatch();

  const asset = useAppSelector(selectAssetDetails);
  const mode = useAppSelector(selectTimelineMode);
  const searchText = useAppSelector(selectSearchText);
  const events = useAppSelector(selectTimelineEvents).map(
    (event: TimelineEvent) => event.id
  );

  const [api, setApi] = useState<GridApi<T>>();
  const [columnApi, setColumnApi] = useState<ColumnApi>();
  const [error, showError] = useState<boolean>(false);

  const [filterSelected, setFilterSelected] = useState<boolean>(false);

  const defaultColDef = useMemo((): ColDef => {
    return {
      sortable: true,
      resizable: true,
      floatingFilter: true,
      suppressSizeToFit: true,
      headerCheckboxSelectionFilteredOnly: true,

      filter: 'agSetColumnFilter',
      cellRenderer: HighlightCellRenderer,
      floatingFilterComponent: MuiFloatingFilterComponent,
    };
  }, []);

  const defaultColumns = useMemo((): ColDef[] => {
    /**
     * @param {GetQuickFilterTextParams<T, unknown>} params ag-grid quick filter parameters for the object
     * @returns {string} stringified value for the generic object
     */
    const getQuickFilterText = (
      params: GetQuickFilterTextParams<T, unknown>
    ) => {
      return Object.values(params.data).join(' ');
    };

    return [
      {
        headerName: '',
        width: 0,
        hide: true,
        getQuickFilterText,
      },
    ];
  }, []);

  const onShowSelections = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFilterSelected(event.target.checked);

      if (onFilterSelected) {
        onFilterSelected(event);
      }
    },
    [onFilterSelected]
  );

  const onFirstDataRendered = useCallback(
    (event: FirstDataRenderedEvent<T>) => {
      event.api.sizeColumnsToFit();
      event.api.forEachNode((node: RowNode<T>) => {
        if (node.data?.id) {
          node.setSelected(events.includes(node.data.id), false, true);
        }
      });
    },
    [events]
  );

  const onGridReadyWrapper = useCallback(
    (event: GridReadyEvent<T>) => {
      setApi(event.api);
      setColumnApi(event.columnApi);
      event.api.sizeColumnsToFit();

      if (searchText) {
        event.api.setQuickFilter(searchText);
      }

      if (onGridReady) {
        onGridReady(event);
      }
    },
    [onGridReady, searchText]
  );

  const doesExternalFilterPassWrapper = useCallback(
    (row: RowNode<T>): boolean => {
      // checkboxes
      if (filterSelected) {
        return row.isSelected() ?? false;
      } else if (doesExternalFilterPass) {
        return doesExternalFilterPass(row);
      }

      return true;
    },
    [filterSelected, doesExternalFilterPass]
  );

  const onDebugClick = useCallback(() => {
    columnApi?.autoSizeAllColumns();
    // columnApi?.getAllGridColumns().forEach((col) => {
    //   console.log(col.getColId());
    //   console.log(col.getActualWidth());
    // });
  }, [columnApi]);

  const onRowSelectedWrapper = useCallback(
    (event: RowSelectedEvent<T>) => {
      if (event.data) {
        if (event.node.isSelected()) {
          dispatch(addTimelineEvent(createTimelineEvent(event.data)));
        } else {
          dispatch(removeTimelineEvent(event.data.id));
        }
      }
    },
    [dispatch]
  );

  const onSearchTextUpdate = useCallback(
    (text: string) => {
      api?.setQuickFilter(text);
      dispatch(updateSearchText(text));
      api?.refreshCells();
    },
    [api, dispatch]
  );

  useEffect(() => {
    api?.onFilterChanged();
  }, [api, filterSelected]);

  // reset search on asset change
  useEffect(() => {
    if (asset?.vehicleObjid) {
      onSearchTextUpdate('');
    }
  }, [onSearchTextUpdate, asset]);

  return (
    <React.Fragment>
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignContent: 'center',
            marginBottom: '10px',
            justifyContent: 'space-between',
          }}
        >
          <Stack
            id='event-grid-filter-controls'
            direction='row'
            alignSelf={'center'}
            display={'flex'}
            flex={'1 0 0'}
          >
            <TextField
              id='event-search'
              fullWidth
              value={searchText}
              onChange={(event) => onSearchTextUpdate(event.target.value)}
              maxRows={1}
              inputProps={{ style: { fontSize: '12px' } }}
              sx={{
                alignSelf: 'flex-start',
                my: 0.5,
                mx: 1,
                maxWidth: '300px',
              }}
              style={{ alignSelf: 'flex-start' }}
              placeholder='Search...'
              size='small'
            />

            <Fade in={mode === 'research'}>
              <FormControl
                sx={{ justifyContent: 'center' }}
                id='event-selection-filter'
              >
                <FormControlLabel
                  sx={{ m: 0, whiteSpace: 'nowrap', color: 'white' }}
                  label='Show Selections'
                  control={
                    <Checkbox
                      size='small'
                      value={filterSelected}
                      onChange={onShowSelections}
                    />
                  }
                />
              </FormControl>
            </Fade>
          </Stack>

          <Stack
            id='event-grid-custom-controls'
            direction={'row'}
            flex={'2 0 0'}
            justifyContent={'center'}
            maxWidth={mode === 'build' ? 0 : undefined}
          >
            {headerComponent}
          </Stack>

          <Stack
            id='event-grid-display-controls'
            direction={'row'}
            spacing={1}
            justifyContent='center'
            alignItems={'center'}
          >
            <IconButton
              sx={{ alignSelf: 'center', ml: 1 }}
              color='secondary'
              onClick={onDebugClick}
            >
              <BugReport />
            </IconButton>
          </Stack>
        </div>
        <PowerToolGrid<T>
          columns={[...columns, ...defaultColumns]}
          data={data}
          defaultColDef={defaultColDef}
          masterDetail={true}
          floatingFiltersHeight={35}
          suppressRowClickSelection={true}
          rowSelection='multiple'
          cacheQuickFilter={true}
          isExternalFilterPresent={() => true}
          getRowId={(params) => params.data.id}
          animateRows={true}
          detailCellRenderer={detailRenderer ?? EventDetailCell}
          doesExternalFilterPass={doesExternalFilterPassWrapper}
          onGridReady={onGridReadyWrapper}
          onRowSelected={onRowSelectedWrapper}
          onFirstDataRendered={onFirstDataRendered}
          loadingOverlayComponent={LoadingSpinnerOverlayLoading}
          headerHeight={35}
          // sideBar={'columns'}
          onGridColumnsChanged={(event: GridColumnsChangedEvent<T>) => {
            event.api.sizeColumnsToFit();
          }}
          detailRowAutoHeight={true}
        />
      </div>

      <Dialog
        open={error}
        PaperProps={{
          style: {
            backgroundColor: PowerToolDarkTheme.main,
            backgroundImage: 'unset',
          },
        }}
      >
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          Woah! Only 100 events are allowed on the timeline.
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            color='primary'
            size='small'
            onClick={() => showError(false)}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
