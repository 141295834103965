import { Button } from '@mui/material';

import { useAppDispatch, useAppSelector } from 'common/hooks';
import {
  selectFilterActive,
  setClearAllFilters,
} from 'common/stores/ingestedDataFooterSlice';

/**
 *
 */
export const ClearAllFilterComponent = () => {
  const dispatch = useAppDispatch();
  const areFiltersActive = useAppSelector(selectFilterActive);

  return (
    <Button
      size='small'
      sx={{
        mt: '2px',
        mb: '2px',
        fontWeight: 'unset',
        height: '25px',
        whiteSpace: 'nowrap',
        minWidth: '100px',
        width: '100px',
        marginLeft: '5px',
      }}
      variant={areFiltersActive ? 'contained' : 'outlined'}
      onClick={() => dispatch(setClearAllFilters(true))}
    >
      Clear Filters
    </Button>
  );
};
