import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MapMarkerData } from 'common/cards/Map/types';
import { RootState } from 'common/store';

export type MapState = {
  resizeFlag: boolean;
  markerData: MapMarkerData[];
  focusedFault?: number | string | undefined;
};

const initialState: MapState = {
  resizeFlag: false,
  markerData: [],
};

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setMarkerData: (state, action: PayloadAction<MapMarkerData[]>) => {
      state.markerData = action.payload;
    },

    addMarkerData: (state, action: PayloadAction<MapMarkerData[]>) => {
      state.markerData = [...action.payload, ...state.markerData];
    },
    setMapResize: (state) => {
      state.resizeFlag = !state.resizeFlag;
    },
    setFocusedFault: (
      state,
      action: PayloadAction<number | string | undefined>
    ) => {
      state.focusedFault = action.payload;
    },
  },
});

export const { setMapResize, setMarkerData, addMarkerData, setFocusedFault } =
  mapSlice.actions;
export const getMapFlag = (state: RootState) => state.map.resizeFlag;
export const getMarkerData = (state: RootState) => state.map.markerData;
export const getFocusedFault = (state: RootState) => state.map.focusedFault;
export default mapSlice.reducer;
