import { Paper, Zoom } from '@mui/material';
import chroma from 'chroma-js';
import React from 'react';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

import { EOTComponent } from 'train-tool/cards/Summary/components/EOTComponent';
import { LocoPositionLabelComponent } from 'train-tool/cards/Summary/components/LocoPositionLabelComponent';
import { LocoDisplay } from 'train-tool/components/LocoDisplay';
import { TrainAsset } from 'train-tool/types';

/**
 *
 * @param {any} props implicit component properties
 * @param {TrainAsset[]} props.locos locomotives in a single consist
 * @param {boolean} props.eot true if end of train group otherwise false
 * @returns {JSX.Element} component representing a single consist in a train
 */
export const ConsistGroupComponent = (props: {
  locos: TrainAsset[];
  eot?: boolean;
}) => {
  const style = props.locos.find((asset) => asset.displayProps.style)
    ?.displayProps.style;
  return (
    <Zoom
      in={props.locos.length > 0}
      timeout={500}
      style={
        {
          // transitionDelay: `${props.transitionDelay ?? 0}ms`
        }
      }
    >
      <div
        id='loco-display-row-wrapper'
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1px',
        }}
      >
        <div
          id='loco-display-position-row-wrapper'
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingInline: '5px',
          }}
        >
          {props.eot === true ? (
            <LocoPositionLabelComponent position={'EOT'} type={'default'} />
          ) : (
            props.locos.map((loco, index) => (
              <LocoPositionLabelComponent
                position={loco.position}
                type={loco.displayProps.type}
                key={index}
              />
            ))
          )}
        </div>
        <Paper
          variant='outlined'
          sx={{
            paddingInline: '5px',
            backgroundImage:
              'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
            backgroundColor: '#172839',
            borderColor:
              style === 'warning'
                ? chroma(PowerToolDarkTheme.statusYellow500).alpha(0.75).hex()
                : chroma(PowerToolDarkTheme.statusGray500).alpha(0.25).hex(),
          }}
        >
          <div
            id='loco-group-row'
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              borderRadius: '4px',
              paddingBlock: '4px',
            }}
          >
            {props.eot ? (
              <EOTComponent loco={props.locos[0]} />
            ) : (
              props.locos.map((loco, index) => (
                <React.Fragment key={index}>
                  <LocoDisplay loco={loco} displayMode='positional' />
                </React.Fragment>
              ))
            )}
          </div>
        </Paper>
      </div>
    </Zoom>
  );
};
