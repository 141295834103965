import { IDateComp } from 'ag-grid-community';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import 'flatpickr/dist/themes/dark.css';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';

// https://javascript.plainenglish.io/how-to-create-a-datetime-filter-in-ag-grid-react-e2e1ba2fc80
// https://www.ag-grid.com/react-data-grid/component-date/

export const GridDateTimeFilter = forwardRef((props: IDateComp, ref) => {
  const [date, setDate] = useState(null);
  const [picker, setPicker] = useState(null);
  const refFlatPickr = useRef(null);
  const refInput = useRef(null);

  //*********************************************************************************
  //          LINKING THE UI, THE STATE AND AG-GRID
  //*********************************************************************************

  const onDateChanged = (selectedDates) => {
    setDate(selectedDates[0]);
    //@ts-ignore
    props.onDateChanged();
  };

  // Instantiate Flatpicker on first render with useEffect:
  useEffect(() => {
    // Set the picker instance to state with the picker ref:
    setPicker(
      // @ts-ignore
      flatpickr(refFlatPickr.current, {
        // Pass in our onDateChanged function to Flatpickr:
        onChange: onDateChanged,
        // Format the date however you would like:
        dateFormat: 'm-d-Y H:i:s',
        wrap: true,
        // Enable time selection:
        enableTime: true,
        enableSeconds: true,
      })
    );
  }, []);

  useEffect(() => {
    if (picker) {
      //@ts-ignore
      picker.calendarContainer.classList.add('ag-custom-component-popup');
    }
  }, [picker]);

  useEffect(() => {
    //Callback after the state is set. This is where we tell ag-grid that the date has changed so
    //it will proceed with the filtering and we can then expect AG Grid to call us back to getDate
    if (picker) {
      //@ts-ignore
      picker.setDate(date);
    }
  }, [date, picker]);

  useImperativeHandle(ref, () => ({
    //*********************************************************************************
    //          METHODS REQUIRED BY AG-GRID
    //*********************************************************************************
    getDate() {
      //ag-grid will call us here when in need to check what the current date value is hold by this
      //component.
      return date;
    },

    setDate(date) {
      //ag-grid will call us here when it needs this component to update the date that it holds.
      setDate(date);
    },

    //*********************************************************************************
    //          AG-GRID OPTIONAL METHODS
    //*********************************************************************************

    setInputPlaceholder(placeholder) {
      if (refInput.current) {
        //@ts-ignore
        refInput.current.setAttribute('placeholder', placeholder);
      }
    },

    setInputAriaLabel(placeholder) {
      if (refInput.current) {
        //@ts-ignore
        refInput.current.setAttribute('aria-label', placeholder);
      }
    },
  }));

  // inlining styles to make simpler the component
  return (
    <StyledDatePicker
      className='ag-input-wrapper custom-date-filter'
      role='presentation'
      ref={refFlatPickr}
    >
      <input
        type='text'
        ref={refInput}
        data-input
        style={{ width: '100%' }}
        placeholder='MM-DD-YYYY HH:MI:SS'
      />
    </StyledDatePicker>
  );
});

const StyledDatePicker = styled.div`
  .flatpickr-calendar {
    background-color: green;
  }
`;
