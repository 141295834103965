import { CloseOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  Modal,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTime } from 'luxon';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { batch } from 'react-redux';

import { DateSourceChoice, DateSourceChoices } from 'common/cards/Data/types';
import {
  useAppDispatch,
  useAppSelector,
  useIsCaseLevelHook,
} from 'common/hooks';
import { selectApplicationContext } from 'common/stores/globalSlice';
import {
  // setShouldFetchNewData,
  setShowFiredFaults,
  setShowFiredParameters,
} from 'common/stores/ingestedDataFooterSlice';
import { dayTimeSpan } from 'common/util/time';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

import { selectCase } from 'power-tool/stores/casesSlice';

import { FromCaseCreationSwitch } from './DateRangeModalComponents';
import {
  selectDateSource,
  selectDayRange,
  setDateSource,
  setDayRange,
  setDateRange,
} from './dateRangeSlice';

const boxStyle = {
  position: 'absolute' as const,
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 650,
  bgcolor: PowerToolDarkTheme.main,
  border: `1px solid ${PowerToolDarkTheme.mainAccent}`,
  borderRadius: '3px',
  boxShadow: 24,
  pb: 4,
  pt: 1,
};

const toggleButtonStyle = {
  paddingTop: '0px',
  paddingBottom: '0px',
  paddingLeft: '7px',
  paddingRight: '7px',
  fontSize: '11px',
  '&.Mui-selected': {
    backgroundColor: PowerToolDarkTheme.voltBlue500,
    color: 'white',
  },
};

type DateRangeModelProps = {
  open: boolean;
  onClose: () => void;
};

/**
 *
 * @param props
 */
export const DateRangeModal = (props: DateRangeModelProps) => {
  const dispatch = useAppDispatch();
  const application = useAppSelector(selectApplicationContext);
  const selectedCase = useAppSelector(selectCase);
  const selectedDateSource = useAppSelector(selectDateSource);
  const _selectedDayRange = useAppSelector(selectDayRange);
  const selectedDayRange = useRef(_selectedDayRange);

  // data state
  const [fromDateRange, setFromDateRange] = useState<Date | null>(null);
  const [toDateRange, setToDateRange] = useState<Date | null>(new Date());
  const [fromCaseCreation, setFromCaseCreation] = useState<boolean>(false);
  const [lookbackType, setLookbackType] =
    useState<DateSourceChoice>('occur_date');

  // validity state
  const [isValidRange, setIsValidRange] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>();

  // window state
  const [open, setOpen] = useState<boolean>(props.open);

  useEffect(() => {
    selectedDayRange.current = _selectedDayRange;
  }, [_selectedDayRange]);

  useEffect(() => {
    setLookbackType(selectedDateSource);
  }, [selectedDateSource]);

  const handleFromCaseCreationChange = useCallback(() => {
    if (selectedCase && fromCaseCreation) {
      // get the case creation time
      const caseCreationTime = DateTime.fromISO(
        selectedCase.creationDate
      ).toJSDate();

      if (selectedDayRange.current !== 'range') {
        // set the from date to (caseCreationTime - selectedDayRange)
        const fromDateRange = new Date(caseCreationTime.getTime());
        setFromDateRange(
          new Date(
            fromDateRange.setDate(
              fromDateRange.getDate() - parseInt(selectedDayRange.current)
            )
          )
        );
      }
      // set the to date to case creation time
      setToDateRange(caseCreationTime);
    } else {
      setFromDateRange(null);
      setToDateRange(new Date());
    }
  }, [selectedCase, fromCaseCreation]);

  useEffect(() => {
    handleFromCaseCreationChange();
  }, [selectedCase, handleFromCaseCreationChange]);

  // update our window state when the props (parent) changes the state
  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  // when either of the date ranges change, valid date our input
  // controls the error message
  useEffect(() => {
    // validate start date
    if (fromDateRange === null) {
      setErrorMessage('Select a From Date');
    }

    // validate end date
    else if (toDateRange === null) {
      setErrorMessage('Select a To Date');
    }

    // check to ensure that the start date is not after the end date
    // since we only let the user choose time,
    else if (
      fromDateRange.getTime() > toDateRange.getTime() &&
      dayTimeSpan(fromDateRange, toDateRange) < -1
    ) {
      setErrorMessage('Start date should less than or equal to the end date.');
    }

    // otherwise check to see if the user has selected more than 90 days of data
    else {
      // calculate the time in days between the two selections
      const timeSpan = dayTimeSpan(fromDateRange, toDateRange);

      if (timeSpan > 90) {
        setErrorMessage(
          `Date range cannot be greater than 90 days (currently selected ${timeSpan} days).`
        );
      } else if (application === 'rule_tester' && timeSpan > 3) {
        setErrorMessage(
          `Date range cannot be greater than 3 days (currently selected ${timeSpan} days).`
        );
      }

      // otherwise the form is valid -> no error message
      else {
        setErrorMessage(null);
      }
    }
  }, [fromDateRange, toDateRange, application]);

  // when the error message changes, we should check to see if the button should be enabled
  // if the message is null, there is no error, and the button should be enabled
  useEffect(() => {
    setIsValidRange(errorMessage === null);
  }, [errorMessage]);

  // when closing the modal, callback to our parent end set our state
  /**
   *
   */
  const handleClose = () => {
    setOpen(false);
    props.onClose();
  };

  // handle when the form is valid to fetch a range of ingested data
  /**
   *
   * @param event
   */
  const handleGoClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (fromDateRange && toDateRange && lookbackType) {
      batch(() => {
        dispatch(setDayRange('range'));
        dispatch(setDateSource(lookbackType));
        dispatch(
          setDateRange({
            to: toDateRange,
            from: fromDateRange,
          })
        );
      });

      handleClose();
    }
  };

  /**
   *
   * @param event
   * @param checked
   */
  const onCaseCreationChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setFromCaseCreation(checked);

    // if the toggle is being set to true and we are on the case level
    if (checked) {
      handleFromCaseCreationChange();
    }
  };

  /**
   *
   * @param event
   * @param value
   */
  const handleDateSourceChange = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: (typeof DateSourceChoices)[number]
  ) => {
    // enforce value set (cannot unselect)
    if (value !== null) {
      setLookbackType(value);
    }
  };

  // render
  return (
    <Modal open={open} onClose={handleClose} disableScrollLock={true}>
      <Box
        sx={boxStyle}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <Button
          sx={{
            mr: 1,
            alignSelf: 'flex-end',
            minWidth: 'unset',
            padding: '4px',
          }}
          onClick={handleClose}
        >
          <CloseOutlined />
        </Button>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            alignSelf: 'center',
          }}
        >
          <div
            style={{
              alignSelf: 'center',
            }}
          >
            <ToggleButtonGroup
              size='small'
              value={lookbackType}
              exclusive={true}
              onChange={handleDateSourceChange}
              sx={{
                alignSelf: 'center',
                marginBottom: '20px',
              }}
            >
              {DateSourceChoices.map((choice) => (
                <ToggleButton
                  color='primary'
                  value={choice}
                  key={choice}
                  sx={toggleButtonStyle}
                >
                  {choice.replaceAll('_', ' ')}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </div>

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div
              style={{
                display: 'flex',
                gap: '10px',
              }}
            >
              <DatePicker
                minDate={new Date(2010, 0, 1)}
                maxDate={new Date()}
                label='From Date'
                value={fromDateRange}
                onChange={(newValue) => setFromDateRange(newValue)}
                renderInput={(params) => <TextField size='small' {...params} />}
              />

              <div>
                <DatePicker
                  minDate={new Date(2010, 0, 1)}
                  maxDate={new Date()}
                  label='To Date'
                  value={toDateRange}
                  onChange={(newValue) => setToDateRange(newValue)}
                  renderInput={(params) => (
                    <TextField size='small' {...params} />
                  )}
                />

                <FromCaseCreationSwitch
                  checked={fromCaseCreation}
                  handleChange={onCaseCreationChange}
                />
              </div>
            </div>
          </LocalizationProvider>

          <div
            style={{
              marginLeft: '10px',
              fontWeight: 400,
              fontSize: '13px',
              color: PowerToolDarkTheme.statusRed500,
            }}
          >
            {errorMessage}
          </div>

          <Button
            disabled={!isValidRange}
            variant='contained'
            onClick={handleGoClick}
            fullWidth={false}
            style={{
              alignSelf: 'flex-end',
            }}
          >
            {'Go'}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};
