import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';
import { useCallback, useState } from 'react';

import { TransferList } from 'common/features/plot/AxisTransferList';
import { AxisConfiguration } from 'common/features/plot/types';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

export const ConfigureAxisModal = ({
  open,
  configs,
  onClose,
  onUpdate,
}: {
  open: boolean;
  configs: AxisConfiguration[];
  onClose: (event: React.MouseEvent) => void;
  onUpdate: (configs: AxisConfiguration[]) => void;
}) => {
  const [pendingConfigs, setPendingConfigs] =
    useState<AxisConfiguration[]>(configs);

  const onConfirm = useCallback(
    (event: React.MouseEvent) => {
      onUpdate(pendingConfigs);
      onClose(event);
    },
    [pendingConfigs]
  );

  return (
    <Dialog
      fullWidth
      maxWidth={'lg'}
      open={open}
      onClose={onClose}
      PaperProps={{
        elevation: 1,
        sx: {
          backgroundColor: PowerToolDarkTheme.neutralDark100,
        },
      }}
    >
      <TransferList
        sx={{ py: 2 }}
        configs={configs}
        onUpdate={(newConfigs) => setPendingConfigs(newConfigs)}
      />
      <DialogActions>
        <Button size='small' onClick={onClose} variant='outlined'>
          Back
        </Button>
        <Button
          size='small'
          onClick={onConfirm}
          color='success'
          variant='contained'
          autoFocus
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
