import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import styled, { useTheme } from 'styled-components';
import { v4 as uuid } from 'uuid';

import { OutboundMacroResult } from 'common/cards/Data/types';

interface outboundParams extends ICellRendererParams {
  outboundData: OutboundMacroResult;
}

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 800,
  },
});

export const OutboundCell = (params: outboundParams) => {
  const theme = useTheme();
  const cellValue = params.valueFormatted
    ? params.valueFormatted
    : params.value;
  const field: OutboundMacroResult = params.outboundData;

  //if macro returned null. no tooltip
  if (field.value === null) {
    return <div>{cellValue !== undefined && <span>{cellValue}</span>}</div>;
  } else {
    //return with tooltip
    return (
      <CustomWidthTooltip
        placement='bottom'
        arrow
        title={
          <div style={{ textAlign: 'center' }}>
            {/* splits string up into substrings. MP values are wrapped in #, logical operators wrapped in & and logical operators with new lines wrapped in %  */}
            {field.tooltip.split(/([&%#][\s\S]*?[&%#])/g).map((token) => {
              //if the substring is an MP value wrapped in #, color blue
              if (token.includes('#')) {
                return (
                  <span style={{ color: theme.dataTeal300 }} key={uuid()}>
                    {token.replaceAll('#', '')}
                  </span>
                );
              }
              //if the substring is a logical operator wrapped in %, we put it on its' own line and color green. some equations are long enough to justify this
              if (token.includes('%')) {
                return (
                  <>
                    <br />
                    <span style={{ color: theme.dataGreen300 }} key={uuid()}>
                      {token.replaceAll('%', '')}
                    </span>
                    <br />
                  </>
                );
              }
              //if the substring is a logical operator wrapped in &, color green
              if (token.includes('&')) {
                return (
                  <span style={{ color: theme.dataGreen300 }} key={uuid()}>
                    {token.replaceAll('&', '')}
                  </span>
                );
              }

              return token;
            })}
          </div>
        }
      >
        <div>{cellValue !== undefined && <span>{cellValue}</span>}</div>
      </CustomWidthTooltip>
    );
  }
};
