// https://github.com/okta/okta-react/blob/master/samples/routing/react-router-dom-v6/src/config.ts
import { AuthConfig } from 'auth/types';

import { User } from 'common/types/types';
import { isAppDeployed } from 'common/util/utils';

export const defaultUser: User = {
  userName: 'anonymous',
  eoaUserName: 'anonymous',
  prefTimeZoneDesc: 'US/Eastern',
  currRole: 'IT Read Only',
};

const clientId = isAppDeployed()
  ? process.env.REACT_APP_OKTA_CLIENT_ID
  : '0oa1j7pc8h60gieKR0h8';
export const oktaDomain = isAppDeployed()
  ? process.env.REACT_APP_OKTA_URL
  : 'wabtec.oktapreview.com';
const appPath = isAppDeployed() ? process.env.PUBLIC_URL : '';

const config: AuthConfig = {
  oidc: {
    clientId: clientId,
    issuer: `https://${oktaDomain}/oauth2/default`,
    redirectUri: `${window.location.origin}${appPath}/login/callback`,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
  },
};

export default config;
