//this is a comment
import axios, { Canceler } from 'axios';

import { VirtualDetails } from './types';

let cancelVirtual: Canceler | undefined;

/**
 * returns the virtual details for a given virtual ID
 *
 * @param {string} virtualId id of the virtual
 * @returns {Promise<VirtualDetails>} promise that contains the virtual details
 */
export const getVirtualDetails = (
  virtualId: string
): Promise<VirtualDetails> => {
  if (cancelVirtual) {
    cancelVirtual();
    cancelVirtual = undefined;
  }

  return axios
    .get<VirtualDetails>('proxy/getVirtualDetails/' + virtualId, {
      cancelToken: new axios.CancelToken(function executor(c) {
        cancelVirtual = c;
      }),
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
};
