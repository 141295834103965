import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'common/hooks';
import { selectApplicationContext } from 'common/stores/globalSlice';
import { getApplicationRuntime } from 'common/util/utils';

import { setDataSource } from 'train-tool/stores/trainSlice';
import { TrainDataSource } from 'train-tool/types';

/**
 * @returns {JSX.Element} toggle button group only enabled
 */
export const DataSourceSelector = () => {
  const dispatch = useAppDispatch();

  const applicationRuntime = getApplicationRuntime();
  const applicationContext = useAppSelector(selectApplicationContext);

  const [source, setSource] = useState<TrainDataSource>('TO');

  useEffect(() => {
    dispatch(setDataSource(source));
  }, [source, dispatch]);

  if (
    applicationRuntime === 'local' &&
    applicationContext === 'train_analysis'
  ) {
    return (
      <ToggleButtonGroup
        exclusive
        size='small'
        value={source}
        onChange={(
          _: React.MouseEvent<HTMLElement>,
          source: TrainDataSource
        ) => {
          if (source) setSource(source);
        }}
      >
        <ToggleButton value='TO'>TO</ToggleButton>

        <ToggleButton value='DART'>PTC</ToggleButton>

        <ToggleButton value='B2B'>B2B</ToggleButton>
      </ToggleButtonGroup>
    );
  }

  return <></>;
};
