import { Box, Tabs, Tab, styled, Typography, Stack } from '@mui/material';
import React from 'react';

import { DragComponent } from 'common/components/card/DragHandle';

import { StyledCardHeader } from './Card.styled';

export type SingleCardProps = {
  title: JSX.Element;
  content: JSX.Element;
};
export type CardComponentProps = {
  title: string;
  content: JSX.Element;
};

export type TabbedCardProps = {
  componentMap: CardComponentProps[];
  leftHeaderComponent?: JSX.Element;
  centerHeaderComponent?: JSX.Element;
  rightHeaderComponent?: JSX.Element;
};

export const SingleCard = (props: SingleCardProps) => {
  return (
    <div className='card-wrapper'>
      <StyledCardHeader
        className='header'
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ flex: '1 1 0' }}>{props.title}</div>
        <div style={{ flex: '0 1 0' }}>
          <DragComponent />
        </div>
        <div style={{ flex: '1 1 0' }} />
      </StyledCardHeader>
      <div className='content'>{props.content}</div>
    </div>
  );
};
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`tabpanel-${index}`}
      style={{ height: '100%', overflow: 'hidden' }}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            height: '100%',
            marginBottom: '10px',
            overflow: 'auto',
          }}
        >
          {/* Added component={'div'} to solve warning  */}
          <Typography component={'div'} sx={{ height: '100%' }}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

export const CustomizedTabs = styled(Tabs)(
  ({ theme }) => `
  min-height: 25px;
  padding-bottom: 4px;
  .MuiButtonBase-root {
    padding: 4px;
    min-height: 25px;
  }
`
);

export const TabbedCard = (props: TabbedCardProps) => {
  const [value, setValue] = React.useState(0);

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
      }}
    >
      <StyledCardHeader
        className='header'
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Stack
          direction={'row'}
          justifyContent='flex-start'
          style={{ flex: '1 1 0' }}
          spacing={1}
        >
          <CustomizedTabs
            sx={{
              '.MuiTabs-scroller': {
                alignSelf: 'flex-start',
              },
            }}
            value={value}
            onChange={(event: React.SyntheticEvent, newValue: number) => {
              setValue(newValue);
            }}
          >
            {props.componentMap.map((component, idx) => (
              <Tab label={component.title} key={idx} id={`tab-${idx}`} />
            ))}
          </CustomizedTabs>
          <Stack paddingTop={'3px'}>{props.leftHeaderComponent}</Stack>
        </Stack>
        <Stack
          style={{
            flex: '0 1 0',
            display: 'grid',
            justifyItems: 'center',
            minWidth: 'fit-content',
          }}
        >
          <DragComponent />
          {props.centerHeaderComponent}
        </Stack>
        <Stack
          sx={{ flex: '1 1 0', alignItems: 'flex-end', pt: '3px', pr: '3px' }}
        >
          <div>{props.rightHeaderComponent}</div>
        </Stack>
      </StyledCardHeader>

      {props.componentMap.map((component, idx) => (
        <TabPanel key={idx} value={value} index={idx}>
          {/* <Suspense fallback={<CardSuspenseLoader />}> */}
          {component.content}
          {/* </Suspense> */}
        </TabPanel>
      ))}
    </div>
  );
};
