import { useAppSelector } from 'common/hooks';
import { selectRowCount } from 'common/stores/ingestedDataFooterSlice';

export const RowCountComponent = () => {
  const rowCount = useAppSelector(selectRowCount);
  if (rowCount) {
    return (
      <span
        style={{
          whiteSpace: 'nowrap',
          marginRight: '15px',
        }}
      >
        <span style={{ fontSize: '13px', fontWeight: 'bold' }}>{rowCount}</span>
        <span style={{ fontSize: '13px' }}> records</span>
      </span>
    );
  } else {
    return <div />;
  }
};
