import SearchIcon from '@mui/icons-material/Search';
import {
  alpha,
  Autocomplete,
  CreateFilterOptionsConfig,
  InputBase,
  Popper,
  PopperProps,
  styled,
  SxProps,
} from '@mui/material';
import fuzzysort from 'fuzzysort';
import { useEffect, useState } from 'react';

import { Asset } from 'common/types/types';

import { ToolOutputData } from 'power-tool/types';

import { TrainSearchItem } from 'train-tool/types';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  paddingLeft: '12px',
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '0ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

export type PowerToolSearchProps<SearchOption> = {
  inputSx?: SxProps;
  searchStyles?: React.CSSProperties;
  iconWrapperStyles?: React.CSSProperties;
  placeholder?: string;

  //text to appear when no options are available.
  noOptionsText?: string;

  //list of options, if defined, Search will use this as the optionlist instead of calling onLoad for the list
  optionList?: SearchOption[];
  filterOptions?: CreateFilterOptionsConfig<SearchOption>;

  //https://stackoverflow.com/questions/35318442/how-to-pass-parameter-to-a-promise-function
  //Promises passed into functions execute immediately. wrap it in a function and it's all good

  //onLoad is a promise that returns a list of options
  onLoad?: () => Promise<SearchOption[]>;
  //onSelect is a callback for the search to do when an option is selected
  onSelect?: (selectedItem: SearchOption) => any;
  //if defined, groups results with a banner
  groupBy?: (option: SearchOption) => string;
  //filter function to be called on every item of the list
  filter?: (option: SearchOption) => boolean;
};

export type SearchOption = {
  label: string;
  value: Asset | TrainSearchItem | ToolOutputData;
  type: 'asset' | 'train' | 'Custom' | 'Approved';
};

/**
 *
 * @param props
 */
export function PowerToolSearch(props: PowerToolSearchProps<SearchOption>) {
  //the value currently typed by the user
  const [inputValue, setInputValue] = useState<string>('');
  //the selected value
  const [selectedValue, setSelectedValue] = useState<SearchOption | null>(null);
  //whether the search results are open
  const [open, setOpen] = useState(false);
  //option list for dropdown
  const [options, setOptions] = useState<SearchOption[]>([]);
  //whether to show the loading text when no options are visible
  const [loading, setLoading] = useState<boolean>(false);
  //pixel length of the longest option.label. used to adjust the width of the option list
  const [longestTitle, setLongestTitle] = useState<number>(150);

  useEffect(() => {
    if (props.optionList) {
      setOptions(props.optionList);
    }
    //if there is an on load, set loading state and call it
    else if (props.onLoad) {
      setLoading(true);
      props
        .onLoad()
        .then((optionList) => {
          //then set the results of the onLoad function as our optionlist
          setOptions(optionList);
          //set loading to false, showing the results
        })
        .finally(() => setLoading(false));
    }
  }, [props.optionList, props.onLoad]);

  useEffect(() => {
    if (selectedValue && props.onSelect) {
      props.onSelect(selectedValue);
      setSelectedValue(null);
    }
  }, [selectedValue, props.onSelect]);

  useEffect(() => {
    //ensure the filter results appear only when a value is typed in
    //clearOnBlur empties input when search loses focus. triggering this
    setOpen(inputValue.length > 0);
  }, [inputValue]);

  useEffect(() => {
    //this measures the length of the optionlist titles for the popover
    const fragment: DocumentFragment = document.createDocumentFragment();
    const canvas: HTMLCanvasElement = document.createElement('canvas');
    fragment.appendChild(canvas);
    // the calculation is applied to the container of elements. this is extra padding to accommodate
    const extraPixels = 70;
    // get the canvas element, set the font that we use in the filter
    const context = canvas.getContext('2d') as CanvasRenderingContext2D;
    context.font =
      '16px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif';
    let minSize = 150;

    options.forEach((option) => {
      if (context.measureText(option.label).width > minSize) {
        minSize = context.measureText(option.label).width;
      }
    });
    setLongestTitle(minSize + extraPixels);
  }, [options]);

  return (
    <Autocomplete
      //removes dropdown like behavior. also allows values not defined within options to be selected
      //freeSolo //disabling this for now because freeSolo disables isOptionEqualToValue

      //fills remainder of input field when highlighted
      autoComplete
      //highlights the first option to allow autocomplete
      autoHighlight
      //selects all text in the input field when user focuses the element.
      selectOnFocus
      //removes text when element loses focus - except when an option is selected
      clearOnBlur
      //removes the dropdown icon. makes it look like a freesolo without losing control
      blurOnSelect
      forcePopupIcon={false}
      noOptionsText={props.noOptionsText}
      style={{
        paddingRight: '5px',
        paddingLeft: '5px',
      }}
      ListboxProps={{
        style: {
          backgroundColor: '#172839',
          width: longestTitle,
          minWidth: props?.inputSx?.['minWidth'],
          fontSize: '16px',
        },
      }}
      PopperComponent={(popperProps: PopperProps) => {
        return (
          <Popper
            sx={props.inputSx}
            {...popperProps}
            style={{ width: longestTitle }}
            placement='bottom-start'
          />
        );
      }}
      open={open}
      value={selectedValue}
      loading={loading}
      options={props.filter ? options.filter(props.filter) : options}
      // https://github.com/farzher/fuzzysort
      // https://mui.com/material-ui/react-autocomplete/#advanced
      filterOptions={(options, { inputValue }) => {
        // limit any given result set to 100 elements
        // we could virtualize this list in future
        // if the 100 element limit is a problem
        return fuzzysort
          .go(inputValue, options, { limit: 100, keys: ['label'] })
          .map((result) => result.obj);
      }}
      groupBy={props.groupBy ? props.groupBy : undefined}
      onChange={(event: any, newValue: SearchOption | null) => {
        setSelectedValue(newValue);
      }}
      onInputChange={(event: any, newInput: string) => {
        setInputValue(newInput);
      }}
      renderInput={(params) => (
        <Search style={props?.searchStyles}>
          <SearchIconWrapper style={props?.iconWrapperStyles}>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            data-testid='SearchInputBase'
            ref={params.InputProps.ref}
            inputProps={params.inputProps}
            sx={props?.inputSx}
            placeholder={props?.placeholder ?? 'Loading...'}
          />
        </Search>
      )}
    />
  );
}
