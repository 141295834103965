import { Stack } from '@mui/material';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

/**
 * @returns {JSX.Element} does nothing currently. intent is to link to relevant related data for a given alarm
 */
export const ViewRelatedDataComponent = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: '5px',
        justifyContent: 'flex-end',
        marginRight: '3px',
      }}
    >
      <Stack
        direction={'row'}
        alignItems='center'
        spacing={1}
        sx={{
          color: PowerToolDarkTheme.neutralDark700,
          cursor: 'pointer',
          ':hover': {
            textDecoration: 'underline',
          },
        }}
      ></Stack>
    </div>
  );
};
