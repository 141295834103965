import { Warning } from '@mui/icons-material';
import { Stack } from '@mui/system';
import { ICellRendererParams } from 'ag-grid-community';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

import { IncidentEvent } from 'loco-history/types';

/**
 *
 * @param {ICellRendererParams<IncidentEvent>} params ag-grid cell renderer params
 * @returns {JSX.Element} ag-grid cell renderer that puts an icon next to open incidents
 */
export const IncidentStatusRenderer = (
  params: ICellRendererParams<IncidentEvent>
) => {
  const value = (params.value as string).toLowerCase();

  if (value.includes('open')) {
    return (
      <Stack direction={'row'} spacing={0.5}>
        <div>{params.value}</div>
        <Warning sx={{ color: PowerToolDarkTheme.statusOrange500 }} />
      </Stack>
    );
  }

  return <span>{params.value}</span>;
};
