import {
  Add,
  Remove,
  CropFree,
  Public,
  Pentagon,
  WrongLocation,
} from '@mui/icons-material';
import { ButtonGroup, Button } from '@mui/material';
import chroma from 'chroma-js';
import { FaRoad } from 'react-icons/fa';
import { GiRailRoad } from 'react-icons/gi';

import { MapControlButtonProps, MapControlProps } from 'common/cards/Map/types';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

export const MapControlButtonGroup = (props: MapControlButtonProps) => {
  const iconStyle = {
    height: '14px',
    width: '14px',
    // https://github.com/google/material-design-icons/issues/648#issuecomment-335735429
    transform: 'rotate(0.03deg)',
  };

  return (
    <div
      style={{
        right: 0,
        zIndex: 1,
        position: 'absolute',
        marginRight: '10px',
        marginTop: '10px',
        flexDirection: 'column',
        display: 'flex',
      }}
    >
      <ButtonGroup
        orientation='vertical'
        sx={{
          color: PowerToolDarkTheme.frosted,
        }}
      >
        {/* we render a dummy button that the compass overlays on */}
        <MapControlButton />

        {/* Render the rest of the controls */}
        <MapControlButton
          action={props.onZoomIn}
          content={<Add style={iconStyle} />}
        />
        <MapControlButton
          action={props.onZoomOut}
          content={<Remove style={iconStyle} />}
        />
        <MapControlButton
          action={props.onReset}
          content={<CropFree style={iconStyle} />}
        />
        <MapControlButton
          action={props.onPerspectiveChange}
          content={
            <span style={{ fontSize: '12px' }}>{props.currentPerspective}</span>
          }
        />
        <MapControlButton
          sx={{ backgroundColor: PowerToolDarkTheme.frosted }}
          action={props.onMarkerClear}
          content={<WrongLocation style={iconStyle} />}
        />
      </ButtonGroup>

      <ButtonGroup
        orientation='vertical'
        sx={{
          color: PowerToolDarkTheme.frosted,
        }}
        style={{ marginTop: '5px' }}
      >
        <MapControlButton
          action={props.onMapChange}
          content={
            !props.currentPerspective ? (
              <Public style={iconStyle} />
            ) : (
              <FaRoad style={iconStyle} />
            )
          }
        />
        <MapControlButton
          sx={{
            backgroundColor: props.railState
              ? chroma(PowerToolDarkTheme.frosted).brighten(0.9).hex()
              : PowerToolDarkTheme.frosted,
          }}
          action={props.onRailToggle}
          content={<GiRailRoad style={iconStyle} />}
        />
        <MapControlButton
          sx={{
            backgroundColor: props.fenceState
              ? chroma(PowerToolDarkTheme.frosted).brighten(0.9).hex()
              : PowerToolDarkTheme.frosted,
          }}
          action={props.onFenceToggle}
          content={<Pentagon style={iconStyle} />}
        />
      </ButtonGroup>
    </div>
  );
};

const MapControlButton = (props: MapControlProps) => {
  return (
    <Button
      style={{
        height: '24px',
        width: '24px',
        minWidth: 'unset',
        borderColor: PowerToolDarkTheme.neutralDark500,
      }}
      sx={{
        color: PowerToolDarkTheme.primary,
        backgroundColor: PowerToolDarkTheme.frosted,
        '&:hover': {
          backgroundColor: chroma(PowerToolDarkTheme.frosted)
            .brighten(0.5)
            .hex(),
        },
        padding: 0,
        ...props.sx,
      }}
      onClick={props.action}
    >
      {props.content ?? <></>}
    </Button>
  );
};
