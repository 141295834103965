import { Stack } from '@mui/system';
import { ICellRendererParams } from 'ag-grid-community';

import { TextChip } from 'common/components/TextChip';
import { UrgencyColorMap } from 'common/config/config';
import { RX } from 'common/types/types';

/**
 *
 * @param {ICellRendererParams<RX>} props ag-grid cell renderer params
 * @returns {JSX.Element} ag-grid cell renderer that renders an RX title and the urgency in a colored badge
 */
export const RxTitleUrgencyRenderer = (props: ICellRendererParams<RX>) => {
  return (
    <Stack
      direction={'row'}
      spacing={0.25}
      justifyContent={'space-between'}
      display={'flex'}
      minWidth={'100px'}
    >
      <div>{props.value}</div>
      {props.data?.urgency ? (
        <TextChip
          style={{ paddingTop: '1px', paddingBottom: '1px' }}
          label={props.data.urgency}
          backgroundColor={UrgencyColorMap[props.data.urgency]}
          color={'white'}
        />
      ) : (
        <></>
      )}
    </Stack>
  );
};
