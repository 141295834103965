import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useState, useEffect } from 'react';
import { MdDateRange } from 'react-icons/md';
import { batch } from 'react-redux';

import { DayRangeChoice, DayRangeChoices } from 'common/cards/Data/types';
import {
  useAppDispatch,
  useIsAssetLevelHook,
  useAppSelector,
} from 'common/hooks';
import { selectApplicationContext } from 'common/stores/globalSlice';
import {
  setShowFiredFaults,
  setShowFiredParameters,
} from 'common/stores/ingestedDataFooterSlice';
import { isRunSelected } from 'common/util/utils';

import { selectRun } from 'power-tool/stores/runSlice';

import { DateRangeModal } from './DateRangeModal';
import { selectDayRange, setDateRange, setDayRange } from './dateRangeSlice';

/**
 *
 */
export const LookbackRangeComponent = () => {
  const dispatch = useAppDispatch();

  const { isAssetLevel } = useIsAssetLevelHook();

  const applicationContext = useAppSelector(selectApplicationContext);
  const dayRange = useAppSelector(selectDayRange);
  const run = useAppSelector(selectRun);

  const [showDayRange, setShowDayRange] = useState<boolean>(true);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [availableChoices, setAvailableChoices] = useState<DayRangeChoice[]>();

  useEffect(() => {
    isRunSelected(run) ? setShowDayRange(false) : setShowDayRange(true);
  }, [run]);

  useEffect(() => {
    if (applicationContext === 'train_analysis') {
      setAvailableChoices(
        DayRangeChoices.filter((choice) => choice !== 'range')
      );
    } else if (applicationContext === 'rule_tester') {
      setAvailableChoices(['range']);
    } else {
      setAvailableChoices(DayRangeChoices.map((c) => c));
    }
  }, [applicationContext]);

  /**
   *
   * @param event
   * @param newDayRange
   */
  const handleDateRangeChange = (
    event: React.MouseEvent<HTMLElement>,
    newDayRange: (typeof DayRangeChoices)[number] | null
  ) => {
    // if the user selected the range button, we open the modal
    // or if they are just pressing the range button again (value didn't change)
    if (
      newDayRange === 'range' ||
      (newDayRange === null && dayRange === 'range')
    ) {
      setOpenModal(true);
    }

    // otherwise, if we are not at the asset level go fetch new data immediately for the new range
    // if we are at the asset level, the click of the go button fires the fetch event
    // also ensure they arent 'unselecting' a button
    else if (newDayRange !== null) {
      batch(() => {
        dispatch(setDayRange(newDayRange));
        dispatch(setDateRange(undefined));
      });

      // if we are not at the asset level
      // then the event should fetch new data and clear the fired toggles
      // OR
      // if we are in TAT mode, we don't have a service selection
      // so always fetch data
      if (
        !isAssetLevel() ||
        applicationContext === 'train_analysis' ||
        applicationContext === 'virtual_tester'
      ) {
        // dispatch(setShouldFetchNewData(true));
        dispatch(setShowFiredFaults(false));
        dispatch(setShowFiredParameters(false));
      }
    }
  };

  // render

  if (!showDayRange) {
    return <div />;
  }

  return (
    <div
      className='ingestedDataDayRange'
      style={{
        display: 'flex',
        marginLeft: '5px',
      }}
    >
      <ToggleButtonGroup
        exclusive
        color='secondary'
        size='small'
        value={dayRange}
        onChange={handleDateRangeChange}
      >
        {availableChoices?.map((day) => {
          return (
            <ToggleButton color='primary' value={day} key={day}>
              {day === 'range' ? <MdDateRange /> : day}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>

      <DateRangeModal onClose={() => setOpenModal(false)} open={openModal} />
    </div>
  );
};
