import {
  Avatar,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from '@mui/material';
import { useState } from 'react';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

import {
  SelectComponentProps,
  BadgedSelectComponentProps,
} from 'power-tool/tool-dispo/types';

export function CaseActionSelectComponent(props: SelectComponentProps) {
  const [value, setValue] = useState<string>(props.value ?? '');

  const onChange = (event: SelectChangeEvent<string>) => {
    setValue(event.target.value);
    if (props.onChange) {
      props.onChange(event);
    }
  };

  return (
    <FormControl
      error={value === ''}
      size='small'
      sx={{
        minWidth: '200px',
        '& .Mui-error': {
          color: `${PowerToolDarkTheme.statusYellow300} !important`,
        },
        '& .Mui-error .MuiOutlinedInput-notchedOutline': {
          borderColor: `${PowerToolDarkTheme.statusYellow300} !important`,
        },
      }}
      {...props.formProps}
    >
      <InputLabel id={`${props.id}-label`} {...props.labelProps}>
        {props.label}
      </InputLabel>
      <Select
        error={value === ''}
        size='small'
        value={value}
        label={props.label}
        labelId={`${props.id}-label`}
        autoWidth={true}
        fullWidth
        onChange={onChange}
        {...props.selectProps}
      >
        {/* <MenuItem value="">
            <em>None</em>
          </MenuItem> */}
        {props.items.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.component ? item.component : item.description}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export function BadgedSelectComponent(props: BadgedSelectComponentProps) {
  return (
    <CaseActionSelectComponent
      {...props}
      selectProps={{
        sx: {
          height: '35px',
          '&.MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select':
            {
              paddingBlock: '5px',
            },
        },
      }}
      items={props.items.map((item) => {
        return {
          ...item,
          component: (
            <Stack direction='row' spacing={1}>
              <Chip
                label={item.description}
                sx={{
                  py: 0,
                  '&.MuiChip-label': {
                    fontSize: '0.6964285714285714rem',
                  },
                  '&.MuiChip-root': {
                    height: '24px',
                  },
                  '&.MuiChip-root .MuiChip-avatar': {
                    height: '18px',
                    width: '18px',
                  },
                }}
                avatar={
                  <Avatar
                    sx={{
                      backgroundColor: item.color,
                    }}
                  >
                    {item.badge}
                  </Avatar>
                }
              />
            </Stack>
          ),
        };
      })}
    />
  );
}
