import axios, { Canceler } from 'axios';

import { PowerToolError } from 'common/types/error';
import { Asset } from 'common/types/types';

import { CaseUrgencyChoice, ToolOutputData } from 'power-tool/types';

import {
  CaseActionChoice,
  CaseActionPayload,
  CurrentCaseOwnerResponse,
  CustomizeRxDetails,
  PowerToolResponse,
  SearchResultRxResponse,
  ToolIdChoice,
  ToolOutputDropdowns,
  ToolOutputVerification,
  VizxAttachments,
  GetLookupsByNameResponse,
} from './types';

let cancelRxDetails: Canceler, cancelVizxAttachments: Canceler | undefined;

export const getLookupsByName = (
  choice:
    | 'WORKORDER_CUSTOMERS'
    | 'GPOC_ADVISORY_RX'
    | 'INSHOP_REASON_FOR_WORKSCOPE_ENHANCEMENT'
) => {
  return axios
    .post<GetLookupsByNameResponse[]>('/query/lookup_by_name', {
      cacheable: false,
      parameters: {
        1: choice,
      },
    })
    .then((response) => response.data);
};

export const getRxById = (rxId: string) => {
  return axios
    .post<CustomizeRxDetails[]>('/query/rx_details', {
      parameters: {
        1: rxId,
      },
    })
    .then((response) => response.data);
};

export const getCurrentCaseOwner = (
  caseId?: string
): Promise<CurrentCaseOwnerResponse> => {
  return axios
    .post<CurrentCaseOwnerResponse>('/query/case_owner_login_name', {
      parameters: {
        1: caseId,
      },
      cacheable: false,
    })
    .then((response) => response.data[0])
    .catch((e) => {
      console.log('error calling endpoint for current case owner');
      throw e;
    });
};

export const getRxs = (
  vehicleObjid: number,
  solutionType: 'Custom' | 'Standard'
): Promise<ToolOutputData[]> => {
  return axios
    .get<SearchResultRxResponse[]>(
      `/proxy/rxSearch/${vehicleObjid}/${solutionType}`,
      {
        cancelToken: new axios.CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancelRxDetails = c;
        }),
      }
    )
    .then((response) => {
      return response.data.map((item) => {
        return {
          toolId: 'JDPAD' as ToolIdChoice,
          ruleId: 0,
          rxTitle: item.solutionDetail.name,
          rxUrgency: item.solutionDetail.urgRepair as CaseUrgencyChoice,
          lastUpdatedDate: item.solutionDetail.creationDate,
          actions: ['deliver' as CaseActionChoice],
          rxId: Number(item.solutionDetail.id),
          rxRepairTime: item.solutionDetail.estmTimeRepair,
          arListObjid: 0,
          isFlowchartOnly:
            item.solutionDetail.taskType === 'Flowchart' ? 'Y' : 'N',
        };
      });
    })
    .catch((e) => {
      console.log("error calling API endpoint for Rx's", e);
      // throw e;
      return [];
    });
};

export const verifyToolOutput = (
  vehicleObjid: number,
  caseId: string,
  user: string
) => {
  return axios
    .get<ToolOutputVerification>(
      `/tooloutput/verify/${vehicleObjid}/${caseId}/${user}`
    )
    .then((response) => response.data)
    .catch((e) => {
      console.log('error calling endpoint tool output verification');
      throw e;
    });
};

export const getToolOutputDropdowns = (
  vehicleObjid: number,
  caseId: string
) => {
  return axios
    .get<ToolOutputDropdowns>(`/tooloutput/dropdowns/${vehicleObjid}/${caseId}`)
    .then((response) => response.data)
    .catch((e) => {
      console.log('error calling endpoint tool output dropdowns');
      throw e;
    });
};

export const getVizxAttachments = (
  assetDetails: Asset
): Promise<VizxAttachments[]> => {
  if (cancelVizxAttachments) {
    cancelVizxAttachments();
    cancelVizxAttachments = undefined;
  }
  return axios
    .get(
      '/proxy/vizxAttachments/' +
        assetDetails.vehicleHdr +
        '/' +
        assetDetails.roadNumber,
      {
        cancelToken: new axios.CancelToken(function executor(c) {
          cancelVizxAttachments = c;
        }),
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log('error fetching rule details');
      throw e;
    });
};

export const submitToolOutput = (
  payload: CaseActionPayload
): Promise<PowerToolResponse<string>> => {
  return axios
    .post<string>('/tooloutput/submit', payload)
    .then((response) => {
      return {
        response: response.data,
        traceId: response.headers['TRACE-ID'],
      };
    })
    .catch((error) => {
      throw new PowerToolError(error, error.response.headers['TRACE-ID']);
    })
    .catch((error) => {
      throw error;
    });
};

export const submitReclose = (caseId: string, user: string) => {
  return axios
    .get<string>(`/tooloutput/reclose?caseId=${caseId}&userId=${user}`)
    .then((response) => response.data)
    .catch((err) => {
      console.log('error submitting reclose', err);
      throw err;
    });
};
