import { all, takeLatest } from 'redux-saga/effects';

import {
  refreshToolDispoAction,
  setToolDispoErrorAction,
  openToolDispoAction,
  addRxAction,
} from 'power-tool/stores/caseActionSlice';

import {
  addRxActionHandler,
  caseActionErrorMessageHandler,
  openToolDispoActionHandler,
  toolDispoReloadHandler,
} from './caseActionHandlers';

function* watchToolDispoReloadAction() {
  yield takeLatest(refreshToolDispoAction.type, toolDispoReloadHandler);
}

function* watchCaseActionErrorMessageAction() {
  yield takeLatest(setToolDispoErrorAction.type, caseActionErrorMessageHandler);
}

function* watchOpenToolDispoAction() {
  yield takeLatest(openToolDispoAction.type, openToolDispoActionHandler);
}

function* watchAddRxAction() {
  yield takeLatest(addRxAction.type, addRxActionHandler);
}

export default function* caseActionSaga() {
  yield all([
    watchToolDispoReloadAction(),
    watchCaseActionErrorMessageAction(),
    watchOpenToolDispoAction(),
    watchAddRxAction(),
  ]);
}
