import { RX } from 'common/types/types';

import {
  ActiveFault,
  DPAlarmDetails,
  TrainSenseOutput,
} from 'train-tool/types';

export type DPAlarm = {
  label: string;
  description?: string;
  value: string;
};

export type AlertTypeDisplayProps = {
  label: string;
  color: string;
  icon: JSX.Element;
  priority: number;
};

export type AnalysisCardAlert = {
  message: string;
  type: AnalysisCardAlertType;
  source?: RX | ActiveFault | DPAlarmDetails | TrainSenseOutput;
};

export type AnyAnalysisCardAlert =
  | RX
  | ActiveFault
  | DPAlarmDetails
  | TrainSenseOutput;

export const AnalysisCardAlertTypes = [
  'alarm',
  'rx',
  'fault',
  'sense',
  'incident',
  // 'defect',
] as const;
export type AnalysisCardAlertType = typeof AnalysisCardAlertTypes[number];
