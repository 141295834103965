import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';
import styled from 'styled-components';

import { LazyTooltip } from 'common/components/general/LazyTooltip';
import { StyledLink } from 'common/components/general/StyledLink';
import { useAppSelector } from 'common/hooks';
import {
  selectAssetDetails,
  selectApplicationContext,
} from 'common/stores/globalSlice';
import { Asset } from 'common/types/types';
import { openTrxViz } from 'common/util/trex-viz-utils';
import { stripEncodedHTML } from 'common/util/utils';

const StyledAdditionalInfo = styled.div`
  a {
    cursor: pointer;
    color: ${(props) => props.theme.voltBlue300};
    :hover {
      color: ${(props) => props.theme.voltBlue400};
    }
    font-weight: bold;
  }
`;

// given a data grid row, return a FAM link to the caller
// code copied from OMD
// link to the caller
export const getFAMLink = (data: any): string => {
  const faultOrigin = data.FAULT_ORIGIN;
  const faultCode = data.FAULT_CODE;

  if (!faultOrigin || !faultCode) {
    console.log(
      `Could not get FAM link for fault origin: ${faultOrigin}. Fault code: ${faultCode}`
    );
    return '';
  }

  if (faultOrigin === 'AC6000') {
    return `https://eservices.wabtec.com/eservices/fam/ac6000_fam.jsp?mode=&incident_number=${faultCode}`;
  } else if (faultOrigin === 'EGU') {
    return `https://eservices.wabtec.com/eservices/fam/egu_fam.jsp?mode=&fault_code_EGU=${faultCode}`;
  } else if (faultOrigin === 'ACCCA' || faultOrigin === 'DCCCA') {
    return `https://eservices.wabtec.com/eservices/fam/evo_fam.jsp?mode=&incident_number=${faultCode}`;
  } else if (faultOrigin === 'BSS') {
    return `https://eservices.wabtec.com/eservices/fam/brightstar_fam_ge.jsp?mode=&model=Brightstar-GE&fault_code=${faultCode}`;
  } else if (faultOrigin === 'ECU') {
    return `https://eservices.wabtec.com/eservices/fam/eec_u6_ecu_fam.jsp?mode=&incident_number=${faultCode}`;
  } else {
    return `https://eservices.wabtec.com/eservices/fam/otherlocomotives_fam.jsp?mode=&fault_code=${faultCode}`;
  }
};

// sometimes, we recieve an encoded string. When this string is rendered, it reveals raw HTML tags
// i.e.: &#x3c;&#x2f;p&#x3e;&#xa;&#xa;&#x3c might render into <p>Some text here</p>
// so we need to render the decoded string again to render the HTML tags correctly
const DoubleRenderHTML = (props: any) => {
  // create temp element and assign the text, this is the first step of decoding
  const tempElement = document.createElement('span');
  tempElement.innerHTML = props.text;

  // now render the tags from the decoded strings into actual HTML elements
  return <span dangerouslySetInnerHTML={{ __html: tempElement.innerText }} />;
};

const getTrexVizLink = (vehicleInfo: Asset) => {
  return (
    <StyledLink
      target='popup'
      onClick={(event) =>
        window.open(
          openTrxViz({
            assetNumber: vehicleInfo.roadNumber,
            customerId: vehicleInfo.vehicleHdr,
            eoaEnabled: 'true',
            groupName: vehicleInfo.vehicleHdr,
            timestamp: '',
            showGraph: true,
            showLimit: true,
          }),
          'TRexAdvisor',
          'width=1500,height=1000'
        )
      }
    >
      T-Rx Viz
    </StyledLink>
  );
};

const CriticalFlagComponent = (props) => {
  if (props.flag) {
    return (
      <div>
        <span style={{ fontWeight: 'bold' }}>Critical Flag: </span>
        <span>{props.flag}</span>
      </div>
    );
  }

  return <div />;
};

const OperatorMessageComponent = (props) => {
  if (props.message) {
    return (
      <div>
        <span style={{ fontWeight: 'bold' }}>Operator Message: </span>
        <span>{props.message}</span>
      </div>
    );
  }

  return <div />;
};

const TRexVizComponent = (props) => {
  const vehicleInfo = useAppSelector(selectAssetDetails);

  if (props.faultCode && props.faultCode === 'TRX0004' && vehicleInfo) {
    return getTrexVizLink(vehicleInfo);
  }

  return <div />;
};

const NotesComponent = (props) => {
  if (props.notes) {
    return (
      <div>
        <span style={{ fontWeight: 'bold' }}>Notes: </span>
        {stripEncodedHTML(`${props.notes}`)}
      </div>
    );
  }

  return <div />;
};

const AdditionalInfoComponent = (props) => {
  if (props.additionalInfo) {
    return (
      <div>
        <span style={{ fontWeight: 'bold' }}>Additional Info: </span>
        <StyledAdditionalInfo>
          <DoubleRenderHTML text={props.additionalInfo} />
        </StyledAdditionalInfo>
      </div>
    );
  }
  return <div />;
};

const DiagnosticWeightComponent = (props) => {
  if (props.diagnosticWeight) {
    return (
      <div>
        <span style={{ fontWeight: 'bold' }}>Fault Level: </span>
        <span>{props.diagnosticWeight}</span>
      </div>
    );
  }
  return <div />;
};

export function FaultCodeCell(params: ICellRendererParams) {
  const applicationContext = useAppSelector(selectApplicationContext);

  if (applicationContext === 'train_analysis') {
    return (
      <LazyTooltip
        placement='bottom'
        color='primary'
        title={
          <div style={{ fontSize: '12px', fontWeight: 'normal' }}>
            <div>
              <StyledLink target='_blank' href={getFAMLink(params.data)}>
                Fault Analysis Manual
              </StyledLink>{' '}
            </div>
          </div>
        }
      >
        <div>{params.value}</div>
      </LazyTooltip>
    );
  }

  return (
    <LazyTooltip
      placement='bottom-start'
      color='primary'
      title={
        <div style={{ fontSize: '12px', fontWeight: 'normal' }}>
          <div>
            <CriticalFlagComponent flag={params.data.CRITICAL_FLAG} />
          </div>
          <div>
            <DiagnosticWeightComponent
              diagnosticWeight={params.data.DIAGNOSTIC_WEIGHT}
            />
          </div>
          <div>
            <OperatorMessageComponent message={params.data.OPERATOR_MESSAGE} />
          </div>
          <div>
            <NotesComponent notes={params.data.NOTES} />
          </div>
          <div>
            <StyledLink target='_blank' href={getFAMLink(params.data)}>
              Fault Analysis Manual
            </StyledLink>{' '}
          </div>
          <div>
            <TRexVizComponent faultCode={params.data.FAULT_CODE} />
          </div>
          <div>
            <AdditionalInfoComponent
              additionalInfo={params.data.ADDITIONAL_INFO}
            />
          </div>
        </div>
      }
    >
      <div>{params.value}</div>
    </LazyTooltip>
  );
}
