import axios from 'axios';

import { getBaseURL } from 'common/api/config';

import { Asset, PowerToolEvent, User } from './types/types';

let powerToolEvents: EventSource;

export const timeoutPromise = (
  timeout: number,
  randomError?: boolean
): Promise<void> => {
  const isError = Math.floor(Math.random() * 2) === 0;

  return new Promise((resolve, reject) => {
    if (isError && randomError) {
      setTimeout(() => reject(), timeout);
    } else {
      setTimeout(() => resolve(), timeout);
    }
  });
};

export const subscribeToPowerToolEvents = (
  user: User,
  caseId: string | undefined,
  vehicleObjid: number,
  handler: (message: PowerToolEvent) => any
) => {
  if (vehicleObjid > 0) {
    let query = `/event/subscribe?userId=${user.userName}&vehicleObjid=${vehicleObjid}`;

    // pass case ID and vehicle objid if both present else send only one
    if (caseId) {
      query += `&caseId=${caseId}`;
    }

    // if we already have an active event stream, close it before starting a new one
    if (powerToolEvents) {
      powerToolEvents.close();
    }

    // start up our event stream
    powerToolEvents = new EventSource(getBaseURL() + query);
    powerToolEvents.onmessage = (event: MessageEvent<string>) => {
      handler(JSON.parse(event.data));
    };
  }
};

export const getModelsInFamilies = (families: string[]): Promise<string[]> => {
  return axios
    .post<{ MODEL_NAME: string }[]>(`/query/models_in_families`, {
      parameters: {
        1: families,
      },
      cacheable: true,
    })
    .then((response) => response.data.map((item) => item.MODEL_NAME))
    .catch((err) => {
      console.log('err getting models for familes: ' + families, err);
      throw err;
    });
};

export const getAsset = (
  vehicleObjid?: string | number | null,
  caseId?: string | null
): Promise<Asset[]> => {
  const query = caseId ? 'vehicle_info_for_case' : 'vehicle_info';
  const parameter = caseId ? caseId : vehicleObjid;

  return axios
    .post<Asset[]>(`/query/${query}`, {
      parameters: {
        1: parameter,
      },
      cacheable: false,
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log('error calling query endpoint for vehicle info data');
      throw e;
    });
};

export const getEOAUserLoginName = (
  userId?: string | null
): Promise<string> => {
  if (userId) {
    return axios
      .post<string[]>('/query/eoa_login_name', {
        parameters: {
          1: userId,
        },
        cacheable: false,
      })
      .then((response) => {
        return response.data?.[0]?.['LOGIN_NAME'] ?? null;
      })
      .catch((e) => {
        console.log('error calling endpoint to get eoa login name');
        throw e;
      });
  } else {
    return Promise.resolve('');
  }
};

export const getAssetsForCustomer = (customer: string) => {
  return axios
    .post<Asset[]>('/query/assets_for_customer', {
      parameters: {
        1: customer,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log('error calling query endpoint for asset list for family');
      throw e;
    });
};

export const getAssets = () => {
  return axios
    .post<Asset[]>('/query/assets', {
      cacheable: true,
      parameters: {},
    })
    .then((response) => response.data);
};

/**
 * returns a list of assets for a given rule family
 *
 * @param {string} family rule family to filter assets down to
 * @returns {Promise<Asset[]>} promise that contains the asset list
 */
export const getAssetsForRuleFamily = (family: string): Promise<Asset[]> => {
  return axios
    .post<Asset[]>('/query/assets_for_family', {
      parameters: {
        1: [family],
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      // console.log('error calling query endpoint for asset list for family');
      throw e;
    });
};
