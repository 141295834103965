import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import {
  selectDateRange,
  selectDayRange,
} from 'common/cards/Data/date-range/dateRangeSlice';
import {
  getDataPackCountForVehicle,
  getDatapackData,
  getFaultById,
} from 'common/features/datapack/api';
import { FetchDatapackInputs } from 'common/features/datapack/types';
import { useAppSelector } from 'common/hooks';
import {
  selectDatapackFaultObjid,
  selectDatapackTemplate,
} from 'common/stores/datpacksSlice';
import {
  selectApplicationContext,
  selectAssetDetails,
} from 'common/stores/globalSlice';

/**
 *
 * @returns {boolean} true if datapacks are enabled for app, false otherwise
 */
export const useFetchDatapacksForFaults = () => {
  const application = useAppSelector(selectApplicationContext);
  const lookback = useAppSelector(selectDayRange);
  const dateRange = useAppSelector(selectDateRange);
  const vehicle = useAppSelector(selectAssetDetails);

  const datapackInputs: FetchDatapackInputs | undefined = useMemo(() => {
    if (vehicle?.vehicleObjid) {
      return {
        dayRange: lookback,
        vehicleObjid: vehicle.vehicleObjid,
        selectedDateRange: dateRange,
      };
    }
  }, [vehicle?.vehicleObjid, dateRange, lookback]);

  const enabled = useMemo(() => {
    if (application === 'train_analysis') {
      return false;
    }

    return (
      //if vehicle exists
      datapackInputs &&
      datapackInputs.vehicleObjid !== 0 &&
      //and if dateRange is set to a nonrange OR if a valid range is set
      (datapackInputs.dayRange !== 'range' ||
        (datapackInputs.selectedDateRange !== undefined &&
          datapackInputs.selectedDateRange?.from !== undefined &&
          datapackInputs.selectedDateRange.to !== undefined))
    );
  }, [datapackInputs, application]);

  const hook = useQuery(
    [
      'datapack_count_for_vehicle',
      datapackInputs?.vehicleObjid,
      datapackInputs?.dayRange,
      datapackInputs?.selectedDateRange,
    ],
    () => {
      if (datapackInputs && datapackInputs.vehicleObjid !== 0) {
        if (
          datapackInputs?.dayRange === 'range' &&
          datapackInputs.selectedDateRange !== undefined &&
          datapackInputs.selectedDateRange?.from !== undefined &&
          datapackInputs.selectedDateRange.to !== undefined
        ) {
          return getDataPackCountForVehicle({
            vehicleObjid: datapackInputs!.vehicleObjid,
            from: datapackInputs.selectedDateRange!.from?.toISOString(),
            to: datapackInputs.selectedDateRange!.to?.toISOString(),
          });
        } else
          return getDataPackCountForVehicle({
            vehicleObjid: datapackInputs!.vehicleObjid,
            dateRange: datapackInputs?.dayRange,
          });
      } else {
        return [];
      }
    },
    { enabled }
  );

  //simplifying the data
  const mappedDatapacks = useMemo(() => {
    //we search based on fault id so we are mapping the sizes to that
    const returnMap = new Map<string, number>();
    if (hook.data) {
      hook.data?.forEach((data) => {
        //casting the objid to a string
        returnMap.set(`${data.faultObjid}`, data.template);
      });
    }
    return returnMap;
  }, [hook.data]);

  return {
    datapack2FaultMap: mappedDatapacks,
    datapackStatus: hook.status,
    ...hook,
  };
};

export const useFetchDatapack = () => {
  const asset = useAppSelector(selectAssetDetails);
  const fault = useAppSelector(selectDatapackFaultObjid);
  const template = useAppSelector(selectDatapackTemplate);

  const enabled = useMemo(() => {
    return (
      asset !== undefined &&
      asset.vehicleObjid !== undefined &&
      fault !== undefined &&
      template !== undefined
    );
  }, [asset, fault]);

  const hook = useQuery(
    ['datapack_data', asset, fault, template],
    () => {
      return getDatapackData(`${asset!.vehicleObjid!}`, fault!, template!);
    },
    { enabled }
  );

  return {
    datapack: hook.data,
    datapackStatus: hook.status,
    ...hook,
  };
};

export const useFetchFaultById = () => {
  const fault = useAppSelector(selectDatapackFaultObjid);

  const enabled = useMemo(() => {
    return fault !== undefined;
  }, [fault]);

  const hook = useQuery(['fault_by_id', fault], () => getFaultById(fault!), {
    enabled,
  });

  return {
    fault: hook.data,
    faultStatus: hook.status,
    ...hook,
  };
};
