import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { batch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import {
  setDateRange,
  setDayRange,
} from 'common/cards/Data/date-range/dateRangeSlice';
import { useAppDispatch } from 'common/hooks';
import {
  useGetApplicationContext,
  useProcessAsset,
  useProcessCase,
  useProcessCustomer,
  useProcessDatapackParameters,
  useProcessDatascreenParameters,
  useProcessDevParameters,
  useProcessRule,
  useProcessRuleFamily,
  useProcessRuleTesterParameters,
  useProcessTrainId,
  useProcessUser,
  useProcessVirtualTesterParameters,
} from 'common/providers/hooks';
import {
  setDatapackFaultObjid,
  setDatapackTemplate,
} from 'common/stores/datpacksSlice';
import {
  changeRule,
  changeUser,
  setApplicationContext,
  setAsset,
  setCustomer,
  setDebugMode,
  setRuleFamily,
  setSuperUser,
} from 'common/stores/globalSlice';

import { setSelectedCase } from 'power-tool/stores/casesSlice';

import { setTrainSearchItem } from 'train-tool/stores/trainSlice';

import {
  setVirtualData,
  setVirtualId,
} from 'virtual-tester/stores/virtualSlice';

import {
  setIsClearingLogic,
  setRuleInfo,
} from 'rule-tester/stores/ruleTesterSlice';

/**
 *
 * @param {JSX.Element} children components to provide URL params to
 * @returns {JSX.Element[]} returns the children passed into the component
 *
 * This components only job is to provide
 * parsed URL parameters to the application
 */
export const URLProvider = ({ children }) => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const { enqueueSnackbar } = useSnackbar();

  // common
  const { ruleFamily } = useProcessRuleFamily(searchParams.get('ruleFamily'));
  const { user } = useProcessUser(
    searchParams.get('userId'),
    searchParams.get('role')
  );
  const { application } = useGetApplicationContext(
    searchParams.get('application_context')
  );

  // power tool
  const { asset } = useProcessAsset(
    searchParams.get('vehicleObjid'),
    searchParams.get('caseId')
  );
  const { caseDetails } = useProcessCase(searchParams.get('caseId'));
  const { ruleId } = useProcessRule(searchParams.get('ruleId'));

  // train tool
  const { customer } = useProcessCustomer(searchParams.get('customer'));
  const { trainId } = useProcessTrainId(searchParams.get('trainId'));

  // asset research tool

  // standalone datascreen
  const { dateRange, dayRange } = useProcessDatascreenParameters(
    searchParams.get('from'),
    searchParams.get('to'),
    searchParams.get('dayRange')
  );

  // virtual tester
  const { virtualId, virtualInfo } = useProcessVirtualTesterParameters(
    searchParams.get('virtualId'),
    searchParams.get('virtualInfo')
  );

  // rule tester
  const { ruleInfo, isClearingLogic } = useProcessRuleTesterParameters(
    searchParams.get('ruleInfo'),
    searchParams.get('isClearingLogic')
  );

  // datapacks
  const { faultObjid, template } = useProcessDatapackParameters(
    searchParams.get('faultObjid'),
    searchParams.get('template')
  );

  // debug & dev parameters
  const { debugMode, isSuperUser } = useProcessDevParameters(
    searchParams.get('debug'),
    searchParams.get('superUser')
  );

  // ========================================================
  // =========== STATE UPDATES FOR URL PARAMETERS ===========
  // ========================================================

  const caseId = searchParams.get('caseId');

  // default asset / case setting
  // TAT uses special logic
  useEffect(() => {
    if (application !== 'train_analysis') {
      // make sure asset details have been loaded
      if (asset) {
        // if there was a case ID in the URL
        if (caseId !== null && caseDetails) {
          const selectedCase = caseDetails[0];
          batch(() => {
            // oil and estp cases have special lookbacks
            // beware: this logic is duplicated in cases card
            if (selectedCase.caseType === 'Oil Anomaly') {
              dispatch(setDayRange('90'));
            } else if (selectedCase.caseType === 'ESTP Problem') {
              dispatch(setDayRange('30'));
            }

            dispatch(setAsset(asset[0]));
            dispatch(setSelectedCase(selectedCase));
          });
        }

        // otherwise dispatch asset
        // if we aren't waiting on case details
        else if (caseId === null) {
          dispatch(setAsset(asset[0]));
        }
      }
    }
  }, [asset, caseDetails, application]);

  useEffect(() => {
    if (ruleId) {
      dispatch(changeRule(ruleId));
    }
  }, [ruleId]);

  useEffect(() => {
    if (user) {
      dispatch(changeUser(user));
    }
  }, [user]);

  useEffect(() => {
    if (application) {
      dispatch(setApplicationContext(application));
    }
  }, [application]);

  // === TAT ===

  useEffect(() => {
    if (customer) {
      dispatch(setCustomer(customer));
    }
  }, [customer]);

  useEffect(() => {
    // if TAT
    if (application === 'train_analysis') {
      // if both asset and train are defined, error
      if (
        searchParams.get('vehicleObjid') !== null &&
        searchParams.get('trainId') !== null
      ) {
        enqueueSnackbar(
          'Invalid search criteria. Try typing your search instead.',
          { variant: 'warning', preventDuplicate: true }
        );
      } else if (trainId) {
        dispatch(
          setTrainSearchItem({
            type: 'train',
            item: { trainId },
          })
        );
      } else if (asset && asset.length > 0) {
        dispatch(
          setTrainSearchItem({
            type: 'asset',
            item: asset[0],
          })
        );
      }
    }
  }, [trainId, asset, application]);

  useEffect(() => {
    if (ruleFamily) {
      dispatch(setRuleFamily(ruleFamily));
    }
  }, [ruleFamily]);

  // === Datapacks ===

  useEffect(() => {
    if (template && faultObjid) {
      batch(() => {
        dispatch(setDatapackFaultObjid(faultObjid));
        dispatch(setDatapackTemplate(template));
      });
    }
  }, [template, faultObjid]);

  // === Virtual Tester ===

  useEffect(() => {
    if (virtualId) {
      dispatch(setVirtualId(virtualId));
    }

    if (virtualInfo) {
      dispatch(setVirtualData(virtualInfo));
    }
  }, [virtualId, virtualInfo]);

  useEffect(() => {
    if (debugMode !== undefined) {
      dispatch(setDebugMode(debugMode));
    }

    if (isSuperUser !== undefined) {
      dispatch(setSuperUser(isSuperUser));
    }
  }, [debugMode, isSuperUser]);

  useEffect(() => {
    if (dayRange && dateRange) {
      batch(() => {
        dispatch(setDayRange(dayRange));
        dispatch(setDateRange(dateRange));
      });
    }
  }, [dayRange, dateRange]);

  // === Rule Tester ===

  useEffect(() => {
    if (ruleInfo) {
      dispatch(setRuleInfo(ruleInfo));
    }

    if (isClearingLogic !== undefined) {
      dispatch(setIsClearingLogic(isClearingLogic));
    }
  }, [ruleInfo, isClearingLogic]);

  return children;
};
