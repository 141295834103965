import { all, call, put } from 'redux-saga/effects';

import {
  getRunsForVehicle as getRunsForVehicleApi,
  getToolOutputForCase as getToolOutputForCaseApi,
  getRunsFromCase,
  getToolOutputFromRun,
} from 'power-tool/api';
import {
  setCurrentCaseOwner,
  setInWorkQueue,
} from 'power-tool/stores/caseActionSlice';
import { setRuns } from 'power-tool/stores/runSlice';
import { setToolOutputData } from 'power-tool/stores/toolOutputSlice';
import { getCurrentCaseOwner } from 'power-tool/tool-dispo/api';
import { CurrentCaseOwnerResponse } from 'power-tool/tool-dispo/types';
import { Run, ToolOutputData } from 'power-tool/types';

import { setToolOutputAction } from './actionSlice';

export function* getRunsForCase(caseId: string) {
  try {
    const runsFromCase: Run[] = yield call(getRunsFromCase, caseId);
    yield put(setRuns(runsFromCase));
  } catch (err) {
    yield put(setRuns([]));
  }
}

export function* setCurrentCaseOwnerHelper(caseId: string) {
  try {
    const currentCaseOwner: CurrentCaseOwnerResponse = yield call(
      getCurrentCaseOwner,
      caseId
    );

    if (currentCaseOwner) {
      // case 'ownership' is defined as owning the case in OMD
      //  and having the case in your work queue
      //   ** this logic is legacy and left over from OMD design
      yield all([
        put(setCurrentCaseOwner(currentCaseOwner.LOGIN_NAME)),
        put(setInWorkQueue(currentCaseOwner.IN_WORK_QUEUE)),
      ]);
    }
  } catch (err) {
    console.log('error in saga - set current case owner helper', err);
    yield all([put(setCurrentCaseOwner(undefined)), put(setInWorkQueue('Y'))]);
  }
}

export function* getRunsForVehicle(vehicleObjid: number) {
  try {
    const runsFromVehicle: Run[] = yield call(
      getRunsForVehicleApi,
      vehicleObjid
    );
    yield put(setRuns(runsFromVehicle));
  } catch (err) {
    yield put(setRuns([]));
  }
}

export function* getToolOutputForCase(caseId: string) {
  try {
    const toolOutputForCase: ToolOutputData[] = yield call(
      getToolOutputForCaseApi,
      caseId
    );
    yield put(setToolOutputAction(toolOutputForCase));
  } catch (err) {
    yield put(setToolOutputData([]));
  }
}

export function* getToolOutputForRun(runId: number) {
  try {
    const toolOutputForRun: ToolOutputData[] = yield call(
      getToolOutputFromRun,
      runId
    );
    yield put(setToolOutputAction(toolOutputForRun));
  } catch (err) {
    yield put(setToolOutputData([]));
  }
}
