import { Tab, tabsClasses } from '@mui/material';
import {
  ChartRef,
  CreateRangeChartParams,
  GridApi,
  SeriesChartType,
} from 'ag-grid-community';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import {
  // selectColumnPositionMap,
  // selectDataCardLoading,
  selectGridApi,
} from 'common/cards/Data/store';
import { CustomizedTabs } from 'common/components/card/Card';
import { selectIngestedDataStatus } from 'common/features/ingested-data/store';
import { useAppDispatch, useAppSelector } from 'common/hooks';
import { selectAssetDetails } from 'common/stores/globalSlice';
import { selectScatterPlotView } from 'common/stores/userSettingSlice';
import { Asset } from 'common/types/types';

import {
  getChartModels,
  getSelectedPlot,
  getHasRxPlots,
  changePlot,
  getRxPlots,
} from 'power-tool/stores/plotSlice';

type PlotListProps = {
  createdPlots: Object;
  cannedPlots: Object;
  selectedPlotTitle: string;
  userPlots: string[];
  selectedUserPlot: number;
  setFocusedView: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedPlotTitle: React.Dispatch<React.SetStateAction<string>>;
  anomalyPlotData: string | undefined;
};

// we only want to default to RX plot on the very first load
// of this component and not any re-renders
let firstLoad = true;
/**
 *
 * @param props
 */
export const PlotListComponent = (props: PlotListProps) => {
  const dispatch = useAppDispatch();
  const chartModels = useAppSelector(getChartModels);
  const api = useAppSelector(selectGridApi);
  const selectedPlotIndex = useAppSelector(getSelectedPlot);
  const vehicleInfo = useAppSelector(selectAssetDetails);
  const rxPlots = useAppSelector(getRxPlots);
  const dataCardStatus = useAppSelector(selectIngestedDataStatus);
  // const isDataCardLoading = useAppSelector(selectDataCardLoading);
  const hasRxPlots = useAppSelector(getHasRxPlots);
  // const columnPositionMap = useAppSelector(selectColumnPositionMap);
  const scatterPlotView = useAppSelector(selectScatterPlotView);

  const [plotList, setPlotList] = useState<string[]>([]);
  const [createdPlots, setCreatedPlots] = useState(props.createdPlots);
  const [cannedPlots, setCannedPlots] = useState(props.cannedPlots);

  useEffect(() => {
    setCreatedPlots(props.createdPlots);
  }, [props.createdPlots]);

  useEffect(() => {
    setCannedPlots(props.cannedPlots);
  }, [props.cannedPlots]);

  // when either cannedPlot list changes (it shouldn't)
  // or the RX plot list changes, update our list of tabs we display
  useEffect(() => {
    setPlotList([
      ...rxPlots.map((plot) => plot.title),
      ...Object.keys(cannedPlots),
    ]);
  }, [cannedPlots, rxPlots]);

  // when the grid loads, check to see if we have an RX plot
  // we only want to fire this event when:
  //   1. We have a plot list defined
  //   2. The data card has loaded completely
  //   3. This is the first time for ANY render that we are doing this
  //   4. The RX plots request has completed, and we know for sure if we have RX plots or not
  useEffect(() => {
    // this kinda stinks but
    // sometimes there is no asset loaded when the application starts (virtual tester, train tool)
    // so we need to make sure we create a user plot once the grid is loaded, but only once
    if (
      Object.keys(createdPlots).length === 0 &&
      plotList.includes('user plot') &&
      dataCardStatus === 'complete' &&
      hasRxPlots === undefined
    ) {
      setTimeout(() => {
        changePlotTab('user plot');
      }, 0);
    } else if (
      plotList.length > 0 &&
      dataCardStatus === 'complete' &&
      firstLoad &&
      hasRxPlots !== undefined
    ) {
      // we only do this once for any render
      firstLoad = false;

      //if we have an anomaly plot, default to that.
      if (plotList.includes('anomaly plot')) {
        setTimeout(() => {
          changePlotTab('anomaly plot');
        }, 0);
      }
      // if we have an RX plot, default to that
      // note hasRxPlots is an optional boolean, hence the '=== true'
      else if (hasRxPlots === true) {
        // does not work without the setTimeout callback of 0ms
        // not sure why lots of ag-grid plotting functions behave like this
        setTimeout(() => {
          const rxPlotTitle = rxPlots[0].title;
          changePlotTab(rxPlotTitle);
        }, 0);
      } else {
        // does not work without the setTimeout callback of 0ms
        // not sure why lots of ag-grid plotting functions behave like this
        setTimeout(() => {
          changePlotTab('user plot');
        }, 0);
      }
    }

    // otherwise if we are not on the first load and we have plots and rx plots
    // and the rx plots have changed, select the rx plot
    else if (!firstLoad && plotList.length > 0) {
      setTimeout(() => {
        if (plotList.includes('anomaly plot')) {
          changePlotTab('anomaly plot');
        } else if (hasRxPlots) {
          const rxPlotTitle = rxPlots[0].title;
          changePlotTab(rxPlotTitle);
        }
        // else {
        //   changePlotTab('user plot');
        // }
      }, 0);
    }

    // we get a warning that we need to include props and changePlotTab
    // but we really only want this to run on load
    // and in certain specific situations
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCardStatus, rxPlots, plotList, hasRxPlots, dispatch]);

  /**
   *
   * @param event
   * @param value
   */
  const handleChange = (event: React.SyntheticEvent, value: string) => {
    changePlotTab(value);
  };

  /**
   *
   * @param plotTitle
   */
  const changePlotTab = (plotTitle: string) => {
    if (api && vehicleInfo) {
      // when we change plots, undo the focused view
      // otherwise, when plotting there would be no columns available
      // and the chart would result in empty
      props.setFocusedView(false);

      // select the correct plot in the tab list
      props.setSelectedPlotTitle(plotTitle);

      if (plotTitle === 'anomaly plot') {
        //anomoly's aren't rendered like other plots(>0), this omits it from general renders
        dispatch(changePlot(-1));
      } else {
        // if the user is navigating back to user plots, handle this separately
        if (plotTitle === 'user plot' && props.userPlots.length > 0) {
          const focusedPlot = props.userPlots[props.selectedUserPlot];
          dispatch(changePlot(chartModels.indexOf(focusedPlot)));
        }

        // if we are navigating to user plot and no user plot exists yet, create a blank one
        else if (plotTitle === 'user plot' && props.userPlots.length === 0) {
          setTimeout(
            () =>
              createChart('Custom Plot', [], api, scatterPlotView, vehicleInfo),
            0
          );
        } else if (plotTitle !== 'user plot') {
          // else if we have already created this plot, just switch to it
          if (Object.keys(createdPlots).includes(plotTitle)) {
            dispatch(changePlot(createdPlots[plotTitle]));
          }
          // otherwise, create a new plot and focus it
          else {
            // track our plots position so we can sort a small list later
            const positionMap: Record<string, number> = {};

            // parameters we will sort
            let parameters: string[];

            // parameters we will plot
            // let orderedParameters: string[];

            let seriesChartTypes: SeriesChartType[] = [];

            // ===== RX PLOT CREATION =====
            if (rxPlots.map((plot) => plot.title).includes(plotTitle)) {
              // filter down to our plot that the user selected
              const rxPlot = rxPlots.find((plot) => plot.title === plotTitle)!;

              // get the parameters we need to plot
              parameters = rxPlot.parameters.map((mp) => mp.parameterName);

              // and group RX plots by axis
              seriesChartTypes = rxPlot.parameters.map((mp) => ({
                chartType: 'line',
                colId: mp.parameterName,
                secondaryAxis: mp.axis !== 'L',
              }));
            }

            // ===== CANNED PLOT CREATION =====
            else {
              // get the parameters we need to plot
              parameters = [
                ...cannedPlots[plotTitle][vehicleInfo.vehicleModel].primary,
                ...cannedPlots[plotTitle][vehicleInfo.vehicleModel].secondary,
              ];

              // seriesChartTypes = cannedPlots[plotTitle][
              //   vehicleInfo.vehicleModel
              // ].secondary.map((mp) => ({
              //   chartType: 'line',
              //   colId: mp,
              //   secondaryAxis: true,
              // }));
            }

            // create a map with the correct position of these parameters
            // parameters.forEach(
            //   (mp) => (positionMap[mp] = columnPositionMap[mp])
            // );

            // sort the parameters based on position
            // orderedParameters = Object.keys(positionMap).sort(
            //   (a, b) => positionMap[a] - positionMap[b]
            // );

            // console.log(parameters);

            // create the chart and track it
            setTimeout(
              () =>
                createChart(
                  plotTitle,
                  parameters,
                  api,
                  scatterPlotView,
                  vehicleInfo,
                  seriesChartTypes
                ),
              0
            );
            createdPlots[plotTitle] = chartModels.length;
          }
        }
      }
    }
  };

  // when our selected plot changes, see if
  // the user selected a canned plot that has already been generated
  // if so, select the tab that corresponds with the plot
  useEffect(() => {
    for (const [plot, index] of Object.entries(createdPlots)) {
      if (index === selectedPlotIndex) {
        props.setSelectedPlotTitle(plot);
        return;
      }
    }

    // user plot, or some other plot than isnt in our canned list
    if (props.userPlots.length > 0 && selectedPlotIndex >= 0) {
      props.setSelectedPlotTitle('user plot');
    }

    // we get a warning that we need to include props and createdPlots
    // we really don't want this to fire any other time than when the selectedPlotIndex changes
    // if this fired more than that, it would have major side-effects on plot selection/rendering
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlotIndex]);

  return (
    <React.Fragment>
      <CustomizedTabs
        variant='scrollable'
        onChange={handleChange}
        value={props.selectedPlotTitle}
        scrollButtons
        sx={{
          [`& .${tabsClasses.scrollButtons}`]: {
            '&.Mui-disabled': { opacity: 0.3 },
          },
        }}
      >
        {plotList.map((plot) => {
          return (
            <Tab
              style={{
                whiteSpace: 'nowrap',
                fontSize: '12px',
              }}
              label={plot.length < 30 ? plot : `${plot.slice(0, 30)}...`}
              value={plot}
              key={plot}
            />
          );
        })}
      </CustomizedTabs>
    </React.Fragment>
  );
};

// create a chart with ag-grid API

/**
 *
 * @param title title of the plot to create
 * @param columns columns to chart, should be contained within the api being passed in
 * @param api GridApi of ag-grid to create the chart
 * @param scatterPlotView
 * @param vehicleInfo
 * @param seriesChartTypes
 * @returns chartRef of the chart that was created, otherwise undefined if a chart was not created
 */
export const createChart = (
  title: string,
  columns: string[],
  api: GridApi | undefined,
  scatterPlotView: boolean,
  vehicleInfo: Asset,
  seriesChartTypes?: SeriesChartType[]
): ChartRef | undefined => {
  // console.log(columns);
  const titleText = `${vehicleInfo.vehicleHdr} ${
    vehicleInfo.roadNumber
  } ${_.startCase(title)}`;

  const chart: CreateRangeChartParams = {
    cellRange: {
      rowStartIndex: 0,
      rowEndIndex: null,
      columns: [...columns, 'OCCUR_DATE'],
    },
    chartThemeOverrides: {
      common: {
        title: {
          text: titleText,
        },
        axes: {
          number: {
            title: {
              fontWeight: 'normal',
              fontFamily: 'Roboto',
              enabled: seriesChartTypes && seriesChartTypes.length > 0,
            },
          },
        },
      },
      line: {
        title: {
          text: titleText,
        },
        series: {
          strokeWidth: scatterPlotView ? 0 : 1,
        },
        axes: {
          number: {
            title: {
              fontWeight: 'normal',
              fontFamily: 'Roboto',
              enabled: seriesChartTypes && seriesChartTypes.length > 0,
            },
          },
        },
      },
    },
    seriesChartTypes: seriesChartTypes,
    chartType:
      seriesChartTypes && seriesChartTypes.length > 0 ? 'customCombo' : 'line',
  };

  return api?.createRangeChart(chart);
};
