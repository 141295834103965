import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ToolOutputResults } from 'common/features/search/types';
import { RootState } from 'common/store';

export type SearchState = {
  ToolOutputResults?: ToolOutputResults[];
};

const initialState: SearchState = {};

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setToolOutputResults: (
      state,
      action: PayloadAction<ToolOutputResults[] | undefined>
    ) => {
      state.ToolOutputResults = action.payload;
    },
  },
});

export const { setToolOutputResults } = searchSlice.actions;

export const selectToolOutputResults = (state: RootState) =>
  state.search.ToolOutputResults;
export default searchSlice.reducer;
