import { HelpOutlineOutlined } from '@mui/icons-material';
import { Divider, Tooltip, Typography } from '@mui/material';
import React from 'react';

import { LegendItemProps } from 'common/cards/Map/types';
import { useAppSelector } from 'common/hooks';
import { selectApplicationContext } from 'common/stores/globalSlice';

import { MapMarkerIcon } from './MapIcon';

export const MapLegend = () => {
  return (
    <div
      style={{
        left: 0,
        zIndex: 1,
        position: 'absolute',
        marginLeft: '10px',
        marginTop: '10px',
        flexDirection: 'column',
        display: 'flex',
      }}
    >
      <Tooltip
        placement='bottom'
        title={
          <div>
            <MapMarkerLegendSection />

            <Typography sx={{ ml: 1 }} variant='button'>
              Rails
            </Typography>
            <RailLegendItem label='Main' color='#bce7f5' />
            <RailLegendItem label='Industrial' color='#e4a350' />
            <RailLegendItem label='Tunnels' color='#f34135' />
            <RailLegendItem label='Bridges' color='#81df9b' />
            <RailLegendItem label='Branch/Other' color='#3397c7' />

            <Typography sx={{ ml: 1 }} variant='button'>
              Geozones
            </Typography>
            <GeozoneLegendItem label='Shops' color='#3397c7' />
            <GeozoneLegendItem label='Service Tracks' color='#e4a350' />
            <GeozoneLegendItem label='Tunnels' color='#f34135' />
            <GeozoneLegendItem label='Bridges' color='#81df9b' />
          </div>
        }
      >
        <HelpOutlineOutlined />
      </Tooltip>
    </div>
  );
};

const RailLegendItem = (props: LegendItemProps) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: '40px',
        justifyContent: 'space-between',
        marginLeft: '3px',
      }}
    >
      <span>{props.label}</span>
      <hr
        style={{
          width: '25px',
          height: '2px',
          backgroundColor: props.color,
          border: 'none',
          borderRadius: '1px',
          alignSelf: 'center',
          margin: 0,
        }}
      />
    </div>
  );
};

const GeozoneLegendItem = (props: LegendItemProps) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: '40px',
        justifyContent: 'space-between',
        marginLeft: '3px',
        marginBottom: '2px',
      }}
    >
      <span>{props.label}</span>
      <div
        style={{
          width: '15px',
          height: '15px',
          backgroundColor: props.color,
          border: 'none',
          borderRadius: '2px',
          opacity: 0.5,
          alignSelf: 'center',
          margin: 0,
        }}
      />
    </div>
  );
};

const MapMarkerLegendSection = () => {
  const applicationContext = useAppSelector(selectApplicationContext);

  if (applicationContext === 'train_analysis') {
    return <></>;
  }

  return (
    <React.Fragment>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
        }}
      >
        <Typography variant='overline' sx={{ fontWeight: 'bold' }}>
          Past
        </Typography>
        <Typography variant='overline' sx={{ fontWeight: 'bold' }}>
          Present
        </Typography>
      </div>
      <div
        style={{ display: 'flex', flexDirection: 'row', marginBottom: '4px' }}
      >
        {[1, 2, 3, 4, 5, 6].map((_, index, arr) => (
          <MapMarkerIcon
          // key={index}
          // fontSize='small'
          // strokeWidth={0.5}
          // stroke='darkgrey'
          // style={{
          //   color: chroma.mix(PowerToolDarkTheme.voltBlue200, PowerToolDarkTheme.voltBlue700, index / arr.length, 'hsl').css('hsl')
          // }}
          />
        ))}

        <MapMarkerIcon
        // fontSize='small'
        // strokeWidth={0.5}
        // stroke='darkgrey'
        // style={{color: PowerToolDarkTheme.statusGreen500 }}
        />
      </div>

      <Divider sx={{ my: 1 }} orientation='horizontal' />
    </React.Fragment>
  );
};
