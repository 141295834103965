import { Switch, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'common/hooks';
import {
  selectApplicationContext,
  selectCustomer,
} from 'common/stores/globalSlice';
import {
  selectHealthCheckFilterState,
  setHealthCheckFilter,
  toggleHealthCheckFilter,
} from 'common/stores/ingestedDataFooterSlice';

/**
 * @returns {JSX.Element} switch component that toggles health checks on or off from the data grid
 */
export const HealthCheckToggle = () => {
  const dispatch = useAppDispatch();
  const application = useAppSelector(selectApplicationContext);
  const customer = useAppSelector(selectCustomer);
  const healthCheckFilterState = useAppSelector(selectHealthCheckFilterState);

  useEffect(() => {
    if (customer === 'CSX') {
      dispatch(setHealthCheckFilter(false));
    }
  }, [customer, dispatch]);

  if (application === 'train_analysis') {
    return (
      <Stack direction={'row'} ml={1} spacing={0.5} alignItems={'center'}>
        <Typography variant='button'>Detail</Typography>
        <Switch
          key={customer}
          size='small'
          checked={healthCheckFilterState}
          onChange={() => dispatch(toggleHealthCheckFilter())}
        />
        <Typography variant='button'>Summary</Typography>
      </Stack>
    );
  }

  return <></>;
};
