import { Stack, Typography, Divider } from '@mui/material';

import { useAppSelector } from 'common/hooks';
import { stripEncodedHTML } from 'common/util/utils';

import { selectRuleDetails } from 'power-tool/stores/ruleOutputSlice';

export const RuleInfo = () => {
  const ruleDetails = useAppSelector(selectRuleDetails);
  return (
    <Stack
      direction={'column'}
      style={{
        minHeight: 75,
        padding: 10,
        marginBottom: 10,
        fontSize: 12,
        rowGap: 4,
        lineHeight: 20,
        whiteSpace: 'nowrap',
        overflowX: 'auto',
        overflowY: 'hidden',
        width: '100%',
      }}
    >
      <RuleCardItem
        key={'Title'}
        label={'Title:'}
        info={
          ruleDetails && ruleDetails.ruleTitle !== ''
            ? stripEncodedHTML(ruleDetails.ruleTitle)
            : 'N/A'
        }
      />
      <RuleCardItem
        key={'Subsystem'}
        label={'Subsystem:'}
        info={
          ruleDetails && ruleDetails.subSystem !== ''
            ? stripEncodedHTML(ruleDetails.subSystem)
            : 'N/A'
        }
      />
      <RuleCardItem
        key={'Description'}
        label={'Description:'}
        info={
          ruleDetails && ruleDetails.ruleDescription !== '' ? (
            <span style={{ whiteSpace: 'pre-wrap' }}>
              {stripEncodedHTML(`${ruleDetails.ruleDescription}`)}
            </span>
          ) : (
            'N/A'
          )
        }
      />
    </Stack>
  );
};

export const RuleCardItem = (props: {
  label: string;
  info: JSX.Element | string;
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Stack spacing={1} direction='row'>
        <Typography variant='button' style={{ lineHeight: 1.45 }}>
          {props.label}
        </Typography>
        <Typography variant='body2'>{props.info}</Typography>
      </Stack>

      <Divider orientation='horizontal' flexItem />
    </div>
  );
};
