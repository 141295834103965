import { Typography, Divider } from '@mui/material';
import React from 'react';

import { useRowProps } from './hooks';

/**
 *
 * @param {any} props implicit component props
 * @param {string} props.value label to display
 * @param {string?} props.subText additional text to display next to the label
 * @param {boolean?} props.divider flag to render the bottom divider or not
 * @returns {JSX.Element} text component label for a parameter in the train status table
 */
export const ParameterLabel = (props: {
  value: string;
  subText?: string;
  divider?: boolean;
}) => {
  const rowProps = useRowProps();

  return (
    <React.Fragment>
      <div
        style={{
          ...rowProps,
        }}
      >
        <Typography
          variant='button'
          sx={{
            paddingLeft: '10px',
            whiteSpace: 'nowrap',
            paddingRight: '10px',
            fontSize: '11px',
          }}
        >
          {props.value}
        </Typography>

        {props.subText ? (
          <Typography
            variant='button'
            sx={{ whiteSpace: 'nowrap', fontSize: '9px', fontWeight: 400 }}
          >
            {props.subText}
          </Typography>
        ) : (
          <></>
        )}
      </div>
      {props.divider ? <Divider /> : <></>}
    </React.Fragment>
  );
};
