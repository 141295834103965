import { ICellRendererParams } from 'ag-grid-community';

import { LazyTooltip } from 'common/components/general/LazyTooltip';

export function ToolRunIndicatorCell(params: ICellRendererParams) {
  if (
    params.data.TOOL_IND_TIP !== undefined &&
    params.data.TOOL_IND_TIP &&
    params.value
  ) {
    return (
      <LazyTooltip title={params.data.TOOL_IND_TIP}>
        <span>{params.value}</span>
      </LazyTooltip>
    );
  }

  return params.value ? params.value : null;
}
