import { Tooltip } from '@mui/material';
import { DateTime, Duration } from 'luxon';
import React from 'react';

import { useUserFormattedTime } from 'common/hooks';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

/**
 *
 * @param {any} props implicit component properties
 * @param {string?} props.timestamp timestamp for the record this component represents
 * @param {'FLT' | 'HC'} props.type type of record that this component represents
 * @returns {JSX.Element} time display for the train details table that indicates how long ago from now the record was recorded in our system: ;
 */
export const TimeDeltaComponent = (props: {
  timestamp?: string;
  type: 'FLT' | 'HC';
}) => {
  const formattedTime = useUserFormattedTime(props.timestamp);

  /**
   *
   * @param {Duration} delta time duration between now and when the record was recorded
   * @returns {string} text string that loosely describes how long ago in minutes the record happened
   */
  const getDeltaString = (delta: Duration) => {
    if (delta.hours > 0) {
      return `Over an hour ago`;
    } else if (delta.minutes === 0) {
      return 'Now';
    } else if (delta.minutes >= 0) {
      return `${delta.minutes} min${delta.minutes !== 1 ? 's' : ''} ago`;
    }

    return `Last 4 hours`;
  };

  /**
   *
   * @param {Duration} delta time duration between now and when the record was recorded
   * @returns {string} color to indicate how fresh the data is; green recent, yellow stale, red old)
   */
  const getDeltaColor = (delta: Duration): string => {
    if (delta.hours > 0 || delta.minutes >= 30) {
      return PowerToolDarkTheme.statusRed600;
    } else if (delta.minutes < 30 && delta.minutes > 15) {
      return PowerToolDarkTheme.statusYellow600;
    } else if (delta.minutes >= 0) {
      return PowerToolDarkTheme.statusGreen600;
    } else {
      return PowerToolDarkTheme.primary;
    }
  };

  if (props.timestamp) {
    const then = DateTime.fromISO(props.timestamp);
    const now = DateTime.fromISO(DateTime.now().toISO());

    const delta = now.diff(then, ['days', 'hours', 'minutes', 'seconds']);

    return (
      <React.Fragment>
        {/* {icon} */}

        <Tooltip title={formattedTime ?? props.timestamp}>
          <div
            style={{
              maxWidth: '125px',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              justifyContent: 'center',
              alignItems: 'center',
              fontWeight: 600,
              color: getDeltaColor(delta),
            }}
          >
            {getDeltaString(delta)}
          </div>
        </Tooltip>
      </React.Fragment>
    );
  } else {
    return (
      <span style={{ color: PowerToolDarkTheme.statusGray500 }}>No data</span>
    );
  }
};
