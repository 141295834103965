import { Typography } from '@mui/material';

export const ContentLabel = ({ label }: { label: string }) => {
  return (
    <Typography
      alignSelf={'center'}
      variant='h2'
      sx={{ fontSize: '22px', mt: 1 }}
    >
      {label}
    </Typography>
  );
};
