import { ColDef } from 'ag-grid-community';
import { useMemo } from 'react';

import { useFetchFaultEvents } from 'loco-history/api/hooks';
import { createEventTimeColDef } from 'loco-history/cards/Timeline/components/events/utils';
import { FaultEvent } from 'loco-history/types';

import { EventsGrid } from './EventsGrid';

/**
 * @returns {JSX.Element} ag-grid wrapper for locomotive fault events
 */
export const FaultHistory = () => {
  const { faultEvents } = useFetchFaultEvents();

  const columns = useMemo(
    (): ColDef[] => [
      {
        field: 'faultCode',
        headerName: 'Fault Code',
        cellRenderer: 'agGroupCellRenderer',
        headerCheckboxSelection: true,
        checkboxSelection: true,
        width: 140,
      },
      {
        field: 'subId',
        headerName: 'Sub ID',
        width: 80,
      },
      {
        field: 'faultDesc',
        headerName: 'Fault Description',
        suppressSizeToFit: false,
      },
      createEventTimeColDef({
        field: 'occurDate',
        headerName: 'Occur Date',
        sort: true,
      }),
    ],
    []
  );

  return <EventsGrid<FaultEvent> data={faultEvents} columns={columns} />;
};
