import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

import { RootState } from 'common/store';

import { CaseDetails } from 'power-tool/types';

export type CasesState = {
  caseTypeOptions?: any;
  selectedCase?: CaseDetails | undefined;
};

// these types of cases we dont allow users to select
export const noSelectCaseTypes = ['Inbound Review', 'Manual Shutdown'];

const initialState: CasesState = {};

export const casesSlice = createSlice({
  name: 'cases',
  initialState,
  reducers: {
    setCaseTypeOptions: (state, action: PayloadAction<any>) => {
      state.caseTypeOptions = action.payload;
    },
    setSelectedCase: (
      state,
      action: PayloadAction<CaseDetails | undefined>
    ) => {
      // in general redux does not update state changes
      // if we try and update the same value in the store
      // however this does not work for objects by default
      if (!_.isEqual(state.selectedCase, action.payload)) {
        state.selectedCase = action.payload;
      }
    },
  },
});

export const { setCaseTypeOptions, setSelectedCase } = casesSlice.actions;

export const selectCaseTypeOptions = (state: RootState) =>
  state.cases.caseTypeOptions;
export const selectCase = (state: RootState) => state.cases.selectedCase;

export default casesSlice.reducer;
