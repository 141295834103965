import { useCallback, useEffect, useState } from 'react';

import {
  PowerToolSearch,
  SearchOption,
} from 'common/features/search/PowerToolSearch';
import { useAppDispatch, useAppSelector } from 'common/hooks';
import { selectAssetDetails } from 'common/stores/globalSlice';
import { selectToolOutputResults } from 'common/stores/searchSlice';

import {
  addRxAction,
  selectIsAddRxEnabled,
  selectToolOutputVerification,
  setIsAddRxEnabled,
} from 'power-tool/stores/caseActionSlice';
import { selectCase } from 'power-tool/stores/casesSlice';
import { ToolOutputData } from 'power-tool/types';

export function RxSearch() {
  const dispatch = useAppDispatch();

  const verification = useAppSelector(selectToolOutputVerification);
  const assetDetails = useAppSelector(selectAssetDetails);
  const optionList = useAppSelector(selectToolOutputResults);
  const selectedCase = useAppSelector(selectCase);
  const isAddRxEnabled = useAppSelector(selectIsAddRxEnabled);

  const [typeFilter, setTypeFilter] = useState<'Custom' | 'Approved'>(
    'Approved'
  );

  useEffect(() => {
    // we enable Add Rx if
    const enabled: boolean =
      // we have a valid vehicle
      (assetDetails !== undefined &&
        assetDetails.vehicleObjid >= 0 &&
        // no active RX exists
        !verification?.activeRxExists &&
        // and our selected case is not an inbound review or outbound review
        selectedCase &&
        !['Inbound Review', 'Outbound Review'].includes(
          selectedCase.caseType
        )) ??
      false;

    dispatch(setIsAddRxEnabled(enabled));
  }, [assetDetails, verification, selectedCase]);

  const onSelect = useCallback(
    (selectedValue: SearchOption) => {
      dispatch(addRxAction(selectedValue.value as ToolOutputData));
    },
    [dispatch]
  );

  if (isAddRxEnabled) {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <PowerToolSearch
          inputSx={{ minWidth: '400px' }}
          searchStyles={{
            marginLeft: 0,
            marginTop: 4,
            display: 'inline-block',
          }}
          placeholder={'Add Rx...'}
          filter={(option) => {
            return option.type === typeFilter;
          }}
          optionList={optionList}
          onSelect={onSelect}
        />
      </div>
    );
  }

  return <></>;
}
