export const basePlots = {
  'user plot': {},
};

export const cannedPlots = {
  ...basePlots,
  temperatures: {
    AC44CCA: {
      primary: [
        'MP_1525_N_0_0',
        'MP_1526_N_0_0',
        'MP_1510_N_0_0',
        'MP_1507_N_0_0',
        'MP_1503_N_0_0',
        'MP_1500_N_0_0',
        'MP_1505_N_0_0',
      ],
      secondary: ['MP_0110_N_0_0', 'MP_1530_N_0_0'],
    },
    'AC6K-CCA': {
      primary: [
        'MP_1525_N_0_0',
        'MP_1526_N_0_0',
        'MP_1505_N_0_0',
        'MP_1510_N_0_0',
        'MP_1500_N_0_0',
        'MP_1503_N_0_0',
        'MP_1507_N_0_0',
      ],
      secondary: ['MP_0110_N_0_0'],
    },
    ACCCA: {
      primary: [
        'MP_1503_N_0_0',
        'MP_1526_N_0_0',
        'MP_1525_N_0_0',
        'MP_1505_N_0_0',
        'MP_1510_N_0_0',
        'MP_1500_N_0_0',
        'MP_1507_N_0_0',
      ],
      secondary: ['MP_0110_N_0_0', 'MP_1530_N_0_0'],
    },
    ES44ACVS: {
      primary: [
        'MP_1500_N_0_0',
        'MP_1503_N_0_0',
        'MP_1507_N_0_0',
        'MP_1510_N_0_0',
        'MP_1525_N_0_0',
        'MP_1526_N_0_0',
      ],
      secondary: ['MP_0110_N_0_0', 'MP_1530_N_0_0'],
    },
    ES44LNG: {
      primary: [
        'MP_21119_N_0_32',
        'PRETURBINE_TEMP_LT_RAW_21235',
        'MP_21143_N_0_32',
        'MP_21152_N_0_32',
        'MP_21149_N_0_32',
        'MP_21100_N_0_32',

        'MP_21140_N_0_32',
        'PRETURBINE_TEMP_RT_RAW_21234',
      ],
      secondary: ['MP_0110_N_0_0', 'MP_1530_N_0_0'],
    },
    ET44AC: {
      primary: [
        'MP_21152_N_0_32',
        'MP_21119_N_0_32',
        'MP_21140_N_0_32',
        'MP_21143_N_0_32',
        'MP_21149_N_0_32',
        'MP_21100_N_0_32',
      ],
      secondary: ['MP_0110_N_0_0'],
    },
    HSP46: {
      primary: [
        'MP_1500_N_0_0',
        'MP_1507_N_0_0',
        'MP_1510_N_0_0',
        'MP_1526_N_0_0',
        'MP_1525_N_0_0',
      ],
      secondary: ['MP_0110_N_0_0', 'MP_1530_N_0_0'],
    },
    PH37ACM: {
      primary: [
        'MP_1526_N_0_0',
        'MP_1525_N_0_0',
        'MP_1510_N_0_0',
        'MP_1507_N_0_0',
        'MP_1500_N_0_0',
        'MP_1503_N_0_0',
      ],
      secondary: ['MP_0110_N_0_0', 'MP_1530_N_0_0'],
    },
    SHUNTER: {
      primary: [
        'PRETURBINE_TEMP_LT_RAW_21235',
        'MP_21119_N_0_32',
        'MP_21143_N_0_32',
        'MP_21149_N_0_32',

        'PRETURBINE_TEMP_RT_RAW_21234',
      ],
      secondary: ['MP_0110_N_0_0'],
    },
    C20EMP: {
      primary: [
        'MP_1526_N_0_0',
        'MP_1525_N_0_0',

        'MP_1510_N_0_0',
        'MP_1507_N_0_0',
        'MP_1503_N_0_0',
        'MP_1500_N_0_0',
        'MP_1505_N_0_0',
      ],
      secondary: ['MP_0110_N_0_0', 'MP_1530_N_0_0'],
    },
    D89CCA: {
      primary: [
        'MP_1525_N_0_0',
        'MP_1526_N_0_0',

        'MP_1510_N_0_0',
        'MP_1505_N_0_0',
        'MP_1507_N_0_0',
        'MP_1503_N_0_0',
        'MP_1500_N_0_0',
      ],
      secondary: ['MP_0110_N_0_0', 'MP_1530_N_0_0'],
    },
    DCCCA: {
      primary: [
        'MP_1500_N_0_0',
        'MP_1503_N_0_0',
        'MP_1507_N_0_0',
        'MP_1505_N_0_0',
        'MP_1510_N_0_0',

        'MP_1525_N_0_0',
        'MP_1526_N_0_0',
      ],
      secondary: ['MP_0110_N_0_0', 'MP_1530_N_0_0'],
    },
    AC44CW: {
      primary: ['WATER_TEMP', 'OIL_TEMP', 'MP_1001_N_0_0', 'MP_1131_N_0_0'],
      secondary: [],
    },
    D9: {
      primary: [
        'WATER_TEMP',
        'OIL_TEMP',
        'MP_1036_N_0_0',
        'MP_1038_N_0_0',
        'MP_1001_N_0_0',
        'MP_3125_N_0_0',
      ],
      secondary: [],
    },
    D8: {
      primary: [
        'WATER_TEMP',
        'OIL_TEMP',
        'MP_1036_N_0_0',
        'MP_1038_N_0_0',
        'MP_1001_N_0_0',
        'MP_3125_N_0_0',
      ],
      secondary: [],
    },
  },
  pressures: {
    AC44CCA: {
      primary: [
        'MP_1511_N_0_0',
        'MP_1531_N_0_0',
        'MP_1501_N_0_0',
        'MP_1506_N_0_0',
      ],
      secondary: ['MP_0111_N_0_0', 'MP_1535_N_0_0'],
    },
    'AC6K-CCA': {
      primary: [
        'MP_1531_N_0_0',

        'MP_1511_N_0_0',
        'MP_1501_N_0_0',
        'MP_1506_N_0_0',
      ],
      secondary: ['MP_0111_N_0_0', 'MP_1535_N_0_0'],
    },
    ACCCA: {
      primary: [
        'MP_1531_N_0_0',

        'MP_1501_N_0_0',
        'MP_1511_N_0_0',
        'MP_1506_N_0_0',
      ],
      secondary: ['MP_0111_N_0_0', 'MP_1535_N_0_0'],
    },
    ES44ACVS: {
      primary: [
        'MP_1511_N_0_0',
        'MP_1531_N_0_0',
        'MP_1506_N_0_0',
        'MP_1501_N_0_0',
      ],
      secondary: ['MP_0111_N_0_0', 'MP_1535_N_0_0'],
    },
    ES44LNG: {
      primary: [
        'MP_21103_N_0_32',
        'MP_21199_N_0_32',
        'MP_21146_N_0_32',
        'MP_21181_N_0_32',
        'MP_21155_N_0_32',
        'MP_21122_N_0_32',
      ],
      secondary: [],
    },
    ET44AC: {
      primary: [
        'MP_21155_N_0_32',
        'MP_21181_N_0_32',
        'MP_21146_N_0_32',
        'MP_21122_N_0_32',
        'MP_21103_N_0_32',
        'MP_21199_N_0_32',
      ],
      secondary: [],
    },
    HSP46: {
      primary: [
        'MP_1506_N_0_0',
        'MP_1501_N_0_0',

        'MP_1511_N_0_0',
        'MP_1531_N_0_0',
      ],
      secondary: ['MP_0111_N_0_0', 'MP_1535_N_0_0'],
    },
    PH37ACM: {
      primary: [
        'MP_1506_N_0_0',

        'MP_1501_N_0_0',
        'MP_1531_N_0_0',
        'MP_1511_N_0_0',
      ],
      secondary: ['MP_0111_N_0_0', 'MP_1535_N_0_0'],
    },
    SHUNTER: {
      primary: [
        'MP_21155_N_0_32',
        'MP_21199_N_0_32',
        'MP_21181_N_0_32',
        'MP_21122_N_0_32',
      ],
      secondary: [],
    },
    C20EMP: {
      primary: [
        'MP_1506_N_0_0',
        'MP_1501_N_0_0',
        'MP_1511_N_0_0',

        'MP_1531_N_0_0',
      ],
      secondary: ['MP_0111_N_0_0', 'MP_1535_N_0_0'],
    },
    D89CCA: {
      primary: [
        'MP_1501_N_0_0',
        'MP_1506_N_0_0',
        'MP_1511_N_0_0',

        'MP_1531_N_0_0',
      ],
      secondary: ['MP_0111_N_0_0', 'MP_1535_N_0_0'],
    },
    DCCCA: {
      primary: [
        'MP_1531_N_0_0',

        'MP_1511_N_0_0',
        'MP_1501_N_0_0',
        'MP_1506_N_0_0',
      ],
      secondary: ['MP_0111_N_0_0', 'MP_1535_N_0_0'],
    },
    AC44CW: {
      primary: ['MP_1410_N_0_0'],
      secondary: [],
    },
    D9: {
      primary: ['MP_1039_N_0_0'],
      secondary: [],
    },
    D8: {
      primary: ['MP_1039_N_0_0'],
      secondary: [],
    },
  },
};
