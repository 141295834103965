import { GridApi, GridOptions, RowSelectedEvent } from 'ag-grid-community';
import { useState, useEffect, useRef, useCallback } from 'react';

import { selectFaultCodes } from 'common/cards/Data/store';
import { EmptyOverlayLoading } from 'common/components/LoadingComponents';
import { AgGridCustom } from 'common/components/ag-grid/DensePowerToolGrid';
import { CustomColDef } from 'common/components/ag-grid/types';
import { useAppDispatch, useAppSelector } from 'common/hooks';
import {
  selectRule,
  changeRule,
  changeLastSelectedRuleFromAppRules,
  setDeselectedType,
} from 'common/stores/globalSlice';

import { getAppRulesForFault, getApplicableRulesFromRun } from 'power-tool/api';
import { ruleSelectedAction } from 'power-tool/saga/actionSlice';
import { selectCase } from 'power-tool/stores/casesSlice';
import { selectRun, selectAllRuns } from 'power-tool/stores/runSlice';
import { ApplicableRule } from 'power-tool/types';

export default function ApplicableRulesCard() {
  const dispatch = useAppDispatch();

  const run = useAppSelector(selectRun);
  const selectedRule = useAppSelector(selectRule);
  const selectedFaultCodes = useAppSelector(selectFaultCodes);
  const allRuns = useAppSelector(selectAllRuns);
  const noResultMessage = useRef(false);
  const caseId = useRef(useAppSelector(selectCase));

  const [gridApi, setGridApi] = useState<GridApi>();
  const [applicableRules, setApplicableRules] = useState<ApplicableRule[]>([]);

  useEffect(() => {
    if (caseId || (run && run.objid > 0)) {
      noResultMessage.current = true;
    }

    // we only want this to run once on render, and it needs to check
    // the selection of caseId and runId
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // function to select a rule on the grid once the grid has
  // been rendered
  const selectRuleOnGrid = useCallback(
    (api: GridApi) => {
      if (selectedRule && selectedRule > 0) {
        api.forEachNode((node) => {
          if (node.data.ruleId === selectedRule) {
            node.setSelected(true);
          }
        });
      }
    },
    [selectedRule]
  );

  const isCaseOrRunSelected = useCallback(() => {
    return caseId.current || (run && run.objid > 0);
  }, [caseId, run]);

  // on first render
  useEffect(() => {
    noResultMessage.current = true;
  }, [isCaseOrRunSelected]);

  // when our gridApi changes or our selected rule changes
  // select the rule on the grid that was selected in redux
  useEffect(() => {
    if (gridApi) {
      if (gridApi.getSelectedNodes() && gridApi.getSelectedNodes().length < 1) {
        selectRuleOnGrid(gridApi);
      } else if (selectedRule === 0) {
        gridApi.deselectAll();
      }
    }
  }, [gridApi, selectedRule, selectRuleOnGrid]);

  useEffect(() => {
    let selectedRun = 0;
    if (run && run.objid > 0) {
      selectedRun = run.objid;
    } else if (caseId.current && allRuns.length > 0) {
      selectedRun = allRuns[0].objid;
    }

    if (selectedFaultCodes.length > 0 && selectedRun > 0) {
      gridApi?.showLoadingOverlay();
      getAppRulesForFault(selectedFaultCodes[0], selectedRun)
        .then((response) => {
          setApplicableRules(response);
          gridApi?.hideOverlay();
          if (response.length === 0) {
            noResultMessage.current = true;
            gridApi?.showNoRowsOverlay();
          }
        })
        .catch((err) => {
          console.error('error setting applicable rules for run', err);
          setApplicableRules([]);
          gridApi?.hideOverlay();
          gridApi?.showNoRowsOverlay();
        });
    } else if (selectedRun > 0) {
      gridApi?.showLoadingOverlay();
      getApplicableRulesFromRun(selectedRun)
        .then((response) => {
          setApplicableRules(response);
          gridApi?.hideOverlay();
          if (response.length === 0) {
            noResultMessage.current = true;
            gridApi?.showNoRowsOverlay();
          }
        })
        .catch((err) => {
          console.error('error setting applicable rules for run', err);
          setApplicableRules([]);
          gridApi?.hideOverlay();
          gridApi?.showNoRowsOverlay();
        });
    } else {
      setApplicableRules([]);
      noResultMessage.current = false;
      gridApi?.showNoRowsOverlay();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [run, selectedFaultCodes, allRuns]);

  const columns: CustomColDef[] = [
    {
      field: 'ruleId',
      headerName: 'Rule ID',
      width: 100,
      filter: 'agSetColumnFilter',
    },
    {
      field: 'partiallyFired',
      headerName: 'Fires',
      width: 50,
      filter: 'agSetColumnFilter',
    },
    {
      field: 'ruleTitle',
      headerName: 'Rule',
      width: 400,
      filter: 'agSetColumnFilter',
    },
    {
      field: 'ruleType',
      headerName: 'Rule Type',
      width: 100,
      filter: 'agSetColumnFilter',
    },
  ];

  const ApplicableRulesNoRowsOverlay = () => {
    let noRowText = 'Select a Case or Run to see the Associated Rules';
    if (noResultMessage.current) {
      noRowText = 'No Rules to Display';
    }
    return <span>{noRowText}</span>;
  };

  const gridOptions: GridOptions = {
    onFirstDataRendered: (params) => {
      params.api.sizeColumnsToFit();
      setGridApi(params.api);
      // when data is rendered, there should be a run already selected
      selectRuleOnGrid(params.api);
    },

    onRowSelected: (event: RowSelectedEvent) => {
      if (event.node.isSelected()) {
        dispatch(changeRule(event.node.data.ruleId));
        dispatch(changeLastSelectedRuleFromAppRules(true));
        dispatch(ruleSelectedAction());
      } else if (
        !event.node.isSelected() &&
        event.api.getSelectedNodes().length === 0
      ) {
        dispatch(setDeselectedType('rule'));
      }
    },

    // renderers
    frameworkComponents: {
      customLoadingOverlay: EmptyOverlayLoading,
      customNoRowsOverlay: ApplicableRulesNoRowsOverlay,
    },
    noRowsOverlayComponent: 'customNoRowsOverlay',
    headerHeight: 24,
  };

  return (
    <AgGridCustom
      data={applicableRules}
      columns={columns}
      options={gridOptions}
    />
  );
}
