import { Stack } from '@mui/material';
import { ColDef } from 'ag-grid-community';

import { TextChip } from 'common/components/TextChip';
import { ZonedDateCell } from 'common/components/ag-grid/cell-renderers/ZonedDateCell';
import { ZonedTimeCell } from 'common/components/ag-grid/cell-renderers/ZonedTimeCell';
import { UrgencyColorMap } from 'common/config/config';
import { TimelineEvent } from 'common/features/timeline/types';

import { EventTooltipBody } from 'loco-history/cards/Timeline/components/event-tooltip/EventTooltipBody';
import { TimelineEventToPropsMap } from 'loco-history/config';
import {
  AnyTimelineEvent,
  CallEvent,
  DefectEvent,
  FaultEvent,
  IncidentEvent,
  MaterialUsageEvent,
  ProgramEvent,
  RXEvent,
  ShopEvent,
} from 'loco-history/types';

/**
 *
 * @param {AnyTimelineEvent} event event from grid/api to be turned into a timeline event for story and/or timeline
 * @returns {TimelineEvent} timeline event that is able to be dispached to the story and timeline
 */
export const createTimelineEvent = (event: AnyTimelineEvent): TimelineEvent => {
  const props = TimelineEventToPropsMap[event.type];

  const baseEvent: TimelineEvent = {
    id: event.id,
    timestamp: event.timestamp,
    type: event.type,
    tooltip: <EventTooltipBody event={event} type={event.type} />,
    event: event,
  };

  switch (event.type) {
    case 'call':
      const call = event as CallEvent;
      return {
        ...baseEvent,
        storyProps: {
          title: props.label,
          text: call.notesDesc,
        },
      };

    // case 'case':
    //   const caseEvent = event as CaseEvent;
    //   return {
    //     ...baseEvent,
    //     storyProps: {
    //       title: props.label,
    //       text: caseEvent.caseTitle,
    //     },
    //   };

    case 'defect':
      const defect = event as DefectEvent;
      return {
        ...baseEvent,
        storyProps: {
          title: props.label,
          text: defect.defectComments,
        },
      };

    case 'fault':
      const fault = event as FaultEvent;
      return {
        ...baseEvent,
        storyProps: {
          title: props.label,
          text: `${fault.faultCode} ${fault.faultDesc}`,
        },
      };

    case 'incident':
      const incident = event as IncidentEvent;
      return {
        ...baseEvent,
        storyProps: {
          title: props.label,
          text: incident.incidentOpenDesc,
        },
      };

    case 'material':
      const material = event as MaterialUsageEvent;
      return {
        ...baseEvent,
        storyProps: {
          title: props.label,
          text: material.description,
        },
      };

    case 'rx':
      const rx = event as RXEvent;
      return {
        ...baseEvent,
        storyProps: {
          title: (
            <Stack
              direction={'row'}
              spacing={1}
              alignItems={'center'}
              justifyContent='flex-end'
            >
              <div>{props.label}</div>
              <TextChip
                style={{ fontSize: '14px' }}
                backgroundColor={UrgencyColorMap[rx.urgency]}
                label={rx.urgency}
              />
            </Stack>
          ),
          text: rx.title,
        },
      };

    case 'shop':
      const shop = event as ShopEvent;
      return {
        ...baseEvent,
        storyProps: {
          title: props.label,
          text: `${shop.inshopReason} ${shop.shop}`,
        },
      };

    case 'program':
      const program = event as ProgramEvent;
      return {
        ...baseEvent,
        storyProps: {
          title: props.label,
          text: program.program,
        },
      };

    default:
      return {
        ...baseEvent,
      };
  }
};

/**
 *
 * @param {string} field object field to create column for
 * @param {string} headerName label for the column
 * @param {boolean | undefined} dateOnly render date only or full timestamp
 * @returns {ColDef} common ag-grid column definition for timestamps in the loco events grid
 */

/**
 *
 * @param {any} props implicit destructured props
 * @param {string} props.field object field to create column for
 * @param {string} props.headerName label for the column
 * @param {boolean | undefined} props.dateOnly render date only or full timestamp
 * @param {boolean | undefined} props.sort if true, desc sort is applied to this column
 * @returns {ColDef} common ag-grid column definition for timestamps in the loco events grid
 */
export const createEventTimeColDef = ({
  field,
  headerName,
  dateOnly,
  sort,
}: {
  field: string;
  headerName: string;
  dateOnly?: boolean;
  sort?: boolean;
}): ColDef => {
  return {
    cellRenderer: dateOnly ? ZonedDateCell : ZonedTimeCell,
    filter: 'agDateColumnFilter',
    // floatingFilterComponent: DateTimePickerFilter,
    width: dateOnly ? 100 : 150,
    sort: sort === true ? 'desc' : undefined,
    field,
    headerName,
  };
};
