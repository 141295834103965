import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useMemo } from 'react';

import { useAppSelector } from 'common/hooks';
import { selectAssetDetails } from 'common/stores/globalSlice';

import {
  getCallEvents,
  getCaseEvents,
  getIncidentEvents,
  getFaultEvents,
  getMaterialUsage,
  getRxEvents,
  getShopEvents,
  getDefectEvents,
  getProgramEvents,
} from 'loco-history/api';
import {
  AnyTimelineEvent,
  CallEvent,
  CaseEvent,
  DefectEvent,
  FaultEvent,
  IncidentEvent,
  MaterialUsageEvent,
  ProgramEvent,
  RXEvent,
  ShopEvent,
} from 'loco-history/types';

/**
 * @returns {AnyTimelineEvent[]} array of all locomotive events in the application
 */
export const useFetchAllLocoEvents = () => {
  const { materialUsage } = useFetchMaterialUsage();
  const { shopEvents } = useFetchShopEvents();
  const { faultEvents } = useFetchFaultEvents();
  const { incidentEvents } = useFetchIncidentEvents();
  const { rxEvents } = useFetchRXEvents();
  const { callEvents } = useFetchCallEvents();
  const { defectEvents } = useFetchDefectEvents();
  const { programEvents } = useFetchProgramEvents();

  const allEvents: AnyTimelineEvent[] = [
    ...(materialUsage ?? []),
    ...(shopEvents ?? []),
    ...(faultEvents ?? []),
    ...(incidentEvents ?? []),
    ...(rxEvents ?? []),
    ...(callEvents ?? []),
    ...(defectEvents ?? []),
    ...(programEvents ?? []),
  ];

  return { allEvents };
};

/**
 * @returns {UseQueryResult<MaterialUsageEvent[]>} hook state wrapper for material usage events
 */
export const useFetchMaterialUsage = () => {
  const asset = useAppSelector(selectAssetDetails);

  const enabled = useMemo(() => {
    return asset !== undefined && asset.vehicleObjid > 0;
  }, [asset]);

  const hook = useQuery(
    ['material_usage', { asset }],
    () => getMaterialUsage(asset),
    { enabled }
  );

  return {
    materialUsage: hook.data,
    materialUsageStatus: hook.status,
    ...hook,
  };
};

/**
 * @returns {UseQueryResult<ShopEvent[]>} hook state wrapper for shop events
 */
export const useFetchShopEvents = () => {
  const asset = useAppSelector(selectAssetDetails);

  const enabled = useMemo(() => {
    return asset !== undefined && asset.vehicleObjid > 0;
  }, [asset]);

  const hook = useQuery(
    ['shop_events', { asset }],
    () => getShopEvents(asset),
    { enabled }
  );

  return {
    shopEvents: hook.data,
    shopEventsStatus: hook.status,
    ...hook,
  };
};

/**
 * @returns {UseQueryResult<FaultEvent[]>} hook state wrapper for fault events
 */
export const useFetchFaultEvents = () => {
  const asset = useAppSelector(selectAssetDetails);

  const enabled = useMemo(() => {
    return asset !== undefined && asset.vehicleObjid > 0;
  }, [asset]);

  const hook = useQuery(
    ['fault_events', { asset }],
    () => getFaultEvents(asset),
    { enabled }
  );

  return {
    faultEvents: hook.data,
    faultStatus: hook.status,
    ...hook,
  };
};

/**
 * @returns {UseQueryResult<IncidentEvent[]>} hook state wrapper for customer b2b incidents
 */
export const useFetchIncidentEvents = () => {
  const asset = useAppSelector(selectAssetDetails);

  const enabled = useMemo(() => {
    return asset !== undefined && asset.vehicleObjid > 0;
  }, [asset]);

  const hook = useQuery(
    ['incident_events', { asset }],
    () => getIncidentEvents(asset),
    { enabled }
  );

  return {
    incidentEvents: hook.data,
    incidentStatus: hook.status,
    ...hook,
  };
};

/**
 * @returns {UseQueryResult<CaseEvent[]>} hook state wrapper for case events
 */
export const useFetchCaseEvents = () => {
  const asset = useAppSelector(selectAssetDetails);

  const enabled = useMemo(() => {
    return asset !== undefined && asset.vehicleObjid > 0;
  }, [asset]);

  const hook = useQuery(
    ['case_events', { asset }],
    () => getCaseEvents(asset),
    { enabled }
  );

  return {
    caseEvents: hook.data,
    caseStatus: hook.status,
    ...hook,
  };
};

/**
 * @returns {UseQueryResult<RXEvent[]>} hook state wrapper for rx events
 */
export const useFetchRXEvents = () => {
  const asset = useAppSelector(selectAssetDetails);

  const enabled = useMemo(() => {
    return asset !== undefined && asset.vehicleObjid > 0;
  }, [asset]);

  const hook = useQuery(['rx_events', { asset }], () => getRxEvents(asset), {
    enabled,
  });

  return {
    rxEvents: hook.data,
    rxStatus: hook.status,
    ...hook,
  };
};

/**
 * @returns {UseQueryResult<CallEvent[]>} hook state wrapper for call log events
 */
export const useFetchCallEvents = () => {
  const asset = useAppSelector(selectAssetDetails);

  const enabled = useMemo(() => {
    return asset !== undefined && asset.vehicleObjid > 0;
  }, [asset]);

  const hook = useQuery(
    ['call_events', { asset }],
    () => getCallEvents(asset),
    {
      enabled,
    }
  );

  return {
    callEvents: hook.data,
    callStatus: hook.status,
    ...hook,
  };
};

/**
 * @returns {UseQueryResult<DefectEvent[]>} hook state wrapper for defect events
 */
export const useFetchDefectEvents = () => {
  const asset = useAppSelector(selectAssetDetails);

  const enabled = useMemo(() => {
    return asset !== undefined && asset.vehicleObjid > 0;
  }, [asset]);

  const hook = useQuery(
    ['defect_events', { asset }],
    () => getDefectEvents(asset),
    {
      enabled,
    }
  );

  return {
    defectEvents: hook.data,
    defectStatus: hook.status,
    ...hook,
  };
};

/**
 * @returns {UseQueryResult<ProgramEvent[]>} hook state wrapper for program or FMI events
 */
export const useFetchProgramEvents = () => {
  const asset = useAppSelector(selectAssetDetails);

  const enabled = useMemo(() => {
    return asset !== undefined && asset.vehicleObjid > 0;
  }, [asset]);

  const hook = useQuery(
    ['program_events', asset?.vehicleObjid],
    () => getProgramEvents(asset),
    { enabled }
  );

  return {
    programEvents: hook.data,
    programStatus: hook.status,
    ...hook,
  };
};
