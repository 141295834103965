import { GridApi, GridOptions, RowSelectedEvent } from 'ag-grid-community';
import { useEffect, useMemo, useRef, useState } from 'react';

import { AgGridCustom } from 'common/components/ag-grid/DensePowerToolGrid';
import { ZonedTimeCell } from 'common/components/ag-grid/cell-renderers/ZonedTimeCell';
import { CustomColDef } from 'common/components/ag-grid/types';
import { useAppDispatch, useAppSelector } from 'common/hooks';
import { selectRule, setDeselectedType } from 'common/stores/globalSlice';

import { useFetchRuns } from 'power-tool/api/hooks';
import {
  RunActionInput,
  FiredFaultInput,
  runAction,
} from 'power-tool/saga/actionSlice';
import { selectCase } from 'power-tool/stores/casesSlice';
import { selectRun } from 'power-tool/stores/runSlice';
import { Run } from 'power-tool/types';

/**
 *
 */
export default function RunsCard() {
  const dispatch = useAppDispatch();

  const { runs } = useFetchRuns();

  const selectedCase = useAppSelector(selectCase);
  const run = useAppSelector(selectRun);
  const selectedRunRef = useRef<Run | undefined>(run);

  const [gridApi, setGridApi] = useState<GridApi>();
  const ruleId = useAppSelector(selectRule);
  // sometimes, the grid can load prior to the run being set
  // here we check that if the gridApi is set and the run is set
  // but if there are no selections, we select the run
  useEffect(() => {
    if (gridApi) {
      if (!run || run.objid <= 0 || Object.keys(run).length === 0) {
        gridApi.deselectAll();
      }
    }
  }, [gridApi, run]);

  const columns = useMemo<CustomColDef[]>(() => {
    if (selectedCase) {
      return [
        {
          field: 'startedOn',
          headerName: 'Started On',
          width: 100,
          cellRenderer: ZonedTimeCell,
          filter: 'agDateColumnFilter',
        },
        {
          field: 'diagServiceId',
          headerName: 'Service',
          width: 50,
          filter: 'agSetColumnFilter',
        },
        {
          field: 'minOccurDate',
          headerName: 'First Fault',
          width: 100,
          cellRenderer: ZonedTimeCell,
          filter: 'agDateColumnFilter',
        },
        {
          field: 'maxOccurDate',
          headerName: 'Last Fault',
          width: 100,
          cellRenderer: ZonedTimeCell,
          filter: 'agDateColumnFilter',
        },
        {
          field: 'runSeconds',
          headerName: 'Duration',
          width: 50,
          filter: 'agNumberColumnFilter',
        },
      ];
    } else {
      return [
        {
          field: 'startedOn',
          headerName: 'Started On',
          width: 100,
          cellRenderer: ZonedTimeCell,
          filter: 'agDateColumnFilter',
        },
        {
          field: 'diagServiceId',
          headerName: 'Service',
          width: 75,
          filter: 'agSetColumnFilter',
        },
        {
          field: 'caseId',
          headerName: 'Case ID',
          width: 50,
          filter: 'agSetColumnFilter',
        },
        {
          field: 'minOccurDate',
          headerName: 'First Fault',
          width: 100,
          cellRenderer: ZonedTimeCell,
          filter: 'agDateColumnFilter',
        },
        {
          field: 'maxOccurDate',
          headerName: 'Last Fault',
          width: 100,
          cellRenderer: ZonedTimeCell,
          filter: 'agDateColumnFilter',
        },
        {
          field: 'runSeconds',
          headerName: 'Duration',
          width: 50,
          filter: 'agNumberColumnFilter',
        },
      ];
    }
  }, [selectedCase]);

  /**
   *
   * @param run
   */
  const initiateRunAction = (run: Run) => {
    if (run && run.objid > 0) {
      const runActionInput: RunActionInput = {
        run: run,
      };
      if (ruleId) {
        const firedFaultInput: FiredFaultInput = {
          id: run.objid,
          ruleId: ruleId,
          type: 'runrul',
        };
        runActionInput.firedFaultInput = firedFaultInput;
      }
      dispatch(runAction(runActionInput));
    }
  };

  /**
   *
   * @param api
   */
  const selectRunOnGrid = (api: GridApi) => {
    if (run && run.objid > 0) {
      api.forEachNode((node) => {
        if (node.data.objid === run.objid) {
          node.setSelected(true);
        }
      });
    }
  };

  const gridOptions: GridOptions = {
    /**
     *
     * @param params
     */
    onFirstDataRendered: (params) => {
      params.api.sizeColumnsToFit();
      setGridApi(params.api);
      // when data is rendered, there should be a run already selected
      selectRunOnGrid(params.api);
    },

    /**
     *
     * @param params
     */
    onComponentStateChanged: (params) => {
      params.api.sizeColumnsToFit();
    },

    /**
     *
     * @param event
     */
    onRowSelected: (event: RowSelectedEvent) => {
      const selectedRun: Run = event.node.data;

      if (
        event.node.isSelected() &&
        selectedRunRef.current?.objid !== selectedRun.objid
      ) {
        initiateRunAction(selectedRun);
      } else if (
        !event.node.isSelected() &&
        event.api.getSelectedNodes().length === 0
      ) {
        dispatch(setDeselectedType('run'));
      }
    },

    overlayNoRowsTemplate: 'Select a Case to view the Runs',
    headerHeight: 24,
  };

  return <AgGridCustom data={runs} columns={columns} options={gridOptions} />;
}
