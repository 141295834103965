import { AccessTimeFilled } from '@mui/icons-material';
import { Typography } from '@mui/material';
import React from 'react';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

import { useFetchTrain } from 'train-tool/hooks/apiHooks';

import { TimeDeltaComponent } from './TimeDeltaComponent';
import { useDisplayProps, useGetLatestRecord } from './hooks';

/**
 * @returns {JSX.Element} label component for the time delta component. displayed in the same column as parameter labels, and same row as the time delta component
 */
export const FaultTimingsLeadColumn = () => {
  const { leadColumnProps } = useDisplayProps();

  return (
    <div
      style={{
        ...leadColumnProps,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
      }}
    >
      <Typography
        variant='h5'
        sx={{
          whiteSpace: 'nowrap',
          fontSize: '14px',
        }}
      >
        <AccessTimeFilled
          fontSize='small'
          sx={{ mr: 1, color: PowerToolDarkTheme.statusGray500 }}
        />
      </Typography>
    </div>
  );
};

/**
 *
 * @param {any} props implicit component props
 * @param {'HC' | 'FLT'} props.type toggle to change if we are rendering HC records or snapshot records
 * @returns {JSX.Element} wrapper component representing the time delta between now and the fault records for each loco on the train
 */
export const FaultTimingsComponent = (props: { type: 'HC' | 'FLT' }) => {
  const { train } = useFetchTrain();
  const { columnProps } = useDisplayProps();
  const { getLatestRecord } = useGetLatestRecord();

  return (
    <React.Fragment>
      {train?.locomotives?.map((loco) => {
        return (
          <div
            key={loco.vehicleObjid ?? loco.roadNumber}
            style={{
              ...columnProps,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                fontSize: '12px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <TimeDeltaComponent
                type={
                  props.type === 'FLT'
                    ? getLatestRecord(loco)?.recordType ?? 'FLT'
                    : 'HC'
                }
                timestamp={
                  props.type === 'FLT'
                    ? getLatestRecord(loco)?.occurDate
                    : loco.healthCheckData?.occurDate
                }
              />
            </div>
          </div>
        );
      }) ?? <></>}
    </React.Fragment>
  );
};
