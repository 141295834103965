import { IHeaderParams } from 'ag-grid-community';
import { useMemo } from 'react';
import {
  FaLocationArrow,
  FaTools,
  FaCloud,
  FaTemperatureHigh,
  FaWind,
  FaCloudRain,
} from 'react-icons/fa';

import { controllerZonedTimeColumns } from 'common/cards/Data/const';
import { useAppSelector } from 'common/hooks';
import {
  selectApplicationContext,
  selectAssetDetails,
  selectTimeSettings,
} from 'common/stores/globalSlice';

export const useColumnHeaderRenderer = (props: IHeaderParams) => {
  const application = useAppSelector(selectApplicationContext);
  const asset = useAppSelector(selectAssetDetails);
  const timeSettings = useAppSelector(selectTimeSettings);

  const columnHeader = useMemo(() => {
    // if its a column that is zoned depending on the units controller source id
    if (controllerZonedTimeColumns.includes(props.column.getColId())) {
      if (application === 'train_analysis') {
        const tzString =
          timeSettings.zone === 'Canada/Eastern' ? 'EST' : timeSettings.zone;

        return `${props.displayName} (${tzString})`;
      } else {
        // make sure we have a tz defined - we default to GMT otherwise
        if (asset?.unitTimeZone) {
          const tzString =
            asset.unitTimeZone === 'Canada/Eastern'
              ? 'EST'
              : asset.unitTimeZone;

          return `${props.displayName} (${tzString})`;
        } else {
          console.log('could not detect asset timezone - default to GMT');
          return `${props.displayName} (GMT)`;
        }
      }
    } else {
      switch (props.column.getColId()) {
        case 'PROXIMITY_LABEL':
          return <FaLocationArrow />;
        case 'TOOL_RUN_INDICATOR':
          return <FaTools />;
        case 'ICON':
        case 'WEATHER':
          return <FaCloud style={{ fontSize: '15px' }} />;
        case 'TEMPF':
          return <FaTemperatureHigh style={{ fontSize: '15px' }} />;
        case 'WINDSPEEDMPH':
          return <FaWind style={{ fontSize: '15px' }} />;
        case 'PRECIPIN':
          return <FaCloudRain style={{ fontSize: '15px' }} />;
      }
    }

    return props.displayName;
  }, [props.displayName, asset, application, timeSettings]);

  return { columnHeader };
};
