import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'common/store';

import {
  VirtualDetails,
  VirtualJson,
  VirtualState,
} from 'virtual-tester/types';

const initialState: VirtualState = {
  virtualJson: [],
};

export const virtualSlice = createSlice({
  name: 'virtual',
  initialState,
  reducers: {
    /**
     *
     * @param {VirtualState} state current virtual tester state
     * @param {PayloadAction<VirtualDetails | undefined>} action new virtual details to set
     */
    setVirtualData: (
      state,
      action: PayloadAction<VirtualDetails | undefined>
    ) => {
      state.info = action.payload;
    },
    /**
     *
     * @param {VirtualState} state current virtual tester state
     * @param {PayloadAction<VirtualJson[]>} action new virtual JSON from URL to set
     */
    setVirtualJson: (state, action: PayloadAction<VirtualJson[]>) => {
      state.virtualJson = action.payload;
    },
    /**
     *
     * @param {VirtualState} state current virtual tester state
     * @param {PayloadAction<string>} action new virtual tester ID from URL to set
     */
    setVirtualId: (state, action: PayloadAction<string>) => {
      state.virtualId = action.payload;
    },
    /**
     *
     * @param {VirtualState} state current virtual tester state
     * @param {PayloadAction<string>} action virtual equation from JSON object to set
     */
    setVirtualEquation: (state, action: PayloadAction<string>) => {
      state.virtualEquation = action.payload;
    },
  },
});

export const {
  setVirtualData,
  setVirtualId,
  setVirtualJson,
  setVirtualEquation,
} = virtualSlice.actions;
/**
 *
 * @param {VirtualState} state current virtual tester state
 * @returns {VirtualDetails?} all properties around a virtual equation
 */
export const selectVirtualData = (state: RootState) => state.virtual.info;
/**
 *
 * @param {VirtualState} state current virtual tester state
 * @returns {string?} current virtual ID
 */
export const selectVirtualId = (state: RootState) => state.virtual.virtualId;
/**
 *
 * @param {VirtualState} state current virtual tester state
 * @returns {string?} current virtual equation
 */
export const selectVirtualEquation = (state: RootState) =>
  state.virtual.virtualEquation;
/**
 *
 * @param {VirtualState} state current virtual tester state
 * @returns {VirtualJson[]} JSON representation of the virtual passed in from URL parameter
 */
export const selectVirtualJson = (state: RootState) =>
  state.virtual.virtualJson;
export default virtualSlice.reducer;
