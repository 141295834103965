import _ from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import { LoadingSpinner } from 'common/components/LoadingComponents';
import { StyledCard } from 'common/components/card/Card.styled';
import { DebugComponent } from 'common/components/debug/DebugComponent';
import { StyledReactGridLayout } from 'common/components/layout/Layout.styled';
import {
  defaultTrainAnalysisCards,
  defaultVirtualTesterCards,
  defaultCards,
  layoutRowHeight,
  layoutColumnScale,
  datapackCards,
  defaultLocoHistoryCards,
  defaultRuleTesterCards,
  standaloneDatascreenCards,
  defaultSpringboardCards,
  defaultTokenizerCards,
  defaultSocketCards,
} from 'common/config/defaultLayouts';
import { STR_MAP, STR_PLOT } from 'common/constants';
import { useFetchSettings } from 'common/features/settings/hooks';
import {
  MapSettingConfig,
  PlotSettingConfig,
} from 'common/features/settings/types';
import { useAppDispatch, useAppSelector } from 'common/hooks';
import {
  selectApplicationContext,
  selectUser,
} from 'common/stores/globalSlice';
import { setMapResize } from 'common/stores/mapSlice';
import {
  selectActiveLayout,
  setSatelliteView,
  setScatterPlotView,
  setCurrentLayout,
} from 'common/stores/userSettingSlice';
import { Card } from 'common/types/types';

/**
 *
 */
export const GridLayout = () => {
  const dispatch = useAppDispatch();

  const applicationContext = useAppSelector(selectApplicationContext);
  const selectedLayout = useAppSelector(selectActiveLayout);
  const user = useAppSelector(selectUser);

  const [cards, setCards] = useState<Card[]>();

  const layout = useRef({});

  const { settings, settingsStatus } = useFetchSettings();

  useEffect(() => {
    // we only load the default cards if we are an anonymous user
    // otherwise we wait for user setting default cards
    if (applicationContext && user?.userName === 'anonymous') {
      setCards(getDefaultCards());
    }
  }, [applicationContext, user]);

  useEffect(() => {
    setCards(getDefaultCards());
  }, [settingsStatus]);

  /**
   *
   */
  const getDefaultCards = (): Card[] => {
    let cards: Card[] = [];
    if (applicationContext) {
      switch (applicationContext) {
        case 'train_analysis':
          cards = defaultTrainAnalysisCards;
          break;
        case 'virtual_tester':
          cards = defaultVirtualTesterCards;
          break;
        case 'datapack_viewer':
          cards = datapackCards;
          break;
        case 'datascreen':
          cards = standaloneDatascreenCards;
          break;
        case 'asset_research':
          cards = defaultLocoHistoryCards;
          break;
        case 'rule_tester':
          cards = defaultRuleTesterCards;
          break;
        case 'springboard':
          cards = defaultSpringboardCards;
          break;
        case 'tokenizer':
          cards = defaultTokenizerCards;
          break;
        case 'sockets':
          cards = defaultSocketCards;
          break;
        case 'power_tool':
        default:
          cards = defaultCards;
      }
    }

    return cards;
  };

  useEffect(() => {
    if (settings) {
      for (const userSetting of settings) {
        if (userSetting.setting === STR_MAP) {
          if (userSetting.config && userSetting.config !== '') {
            const mapSettingConfig: MapSettingConfig = JSON.parse(
              userSetting.config
            );
            dispatch(setSatelliteView(mapSettingConfig.satelliteView));
          }
        }
        if (userSetting.setting === STR_PLOT) {
          if (userSetting.config && userSetting.config !== '') {
            const plotSettingConfig: PlotSettingConfig = JSON.parse(
              userSetting.config
            );
            dispatch(
              setScatterPlotView(
                plotSettingConfig.displayLines === undefined
                  ? false
                  : !plotSettingConfig.displayLines
              )
            );
          }
        }
      }
    }
  }, [settings, dispatch]);

  // on first load, check to see if we have a layout saved
  // and if we do, dispatch this is out cards that we use
  useEffect(() => {
    if (selectedLayout) {
      // get the layout from local storage
      const newLayout: {} = JSON.parse(selectedLayout?.config || '{}');
      const defaultCards = getDefaultCards();
      if (_.isEmpty(newLayout)) {
        setCards(defaultCards);
      } else {
        const newCards: Card[] = [];
        defaultCards.forEach((value) => {
          const item = _.cloneDeep(value);
          item.layout = newLayout[value.layout.i];
          newCards.push(item);
        });
        if (newCards.length > 0) {
          setCards(newCards);
        }
      }
    }
  }, [selectedLayout]);

  // when the grid first renders, or when users drag and resize cards
  // this method is called. here we store the current layout in a state
  // variable, used when the save layout button is clicked
  /**
   *
   * @param newLayout
   */
  const onLayoutChange = (newLayout: any) => {
    dispatch(setMapResize()); //adjusts map whenever layout changes. -> to be replaced with general solution

    const obj = {};
    for (let i = 0; i < newLayout.length; i++) {
      obj[newLayout[i].i] = newLayout[i];
    }

    // assign the new layout to our layout reference
    layout.current = obj;
    dispatch(setCurrentLayout(layout.current));

    // 're-render' the cards with the new configured sizes
    return cards?.map((value) => {
      return (
        <div key={value.layout.i} className={`card ${value.layout.i}`}>
          {value.layout.i}
        </div>
      );
    });
  };

  const drawCards = useMemo(() => {
    return cards?.map((item) => {
      return (
        <StyledCard
          key={item.layout.i}
          className={`card ${item.layout.i}`}
          data-grid={{
            x: item.layout.x,
            y: item.layout.y,
            w: item.layout.w,
            h: item.layout.h,
          }}
        >
          {item.component}
        </StyledCard>
      );
    });
  }, [cards]);

  if (cards) {
    return (
      <div>
        <DebugComponent />
        <StyledReactGridLayout
          className='dynamic-grid'
          layouts={{
            lg: _.map(cards, (value) => {
              return value.layout;
            }),
          }}
          width={1200}
          autoSize={true}
          preventCollision={false}
          measureBeforeMount={false}
          onLayoutChange={onLayoutChange}
          margin={[5, 5]}
          compactType={'vertical'}
          breakpoints={{ lg: 1200 }}
          draggableHandle={'.draggableHandle'}
          rowHeight={layoutRowHeight}
          cols={{ lg: 12 * layoutColumnScale }}
        >
          {drawCards}
        </StyledReactGridLayout>
      </div>
    );
  } else {
    return (
      <div
        style={{
          width: '100%',
          height: '95vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <LoadingSpinner />
      </div>
    );
  }
};
