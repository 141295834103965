import { Divider, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';

import { DateTimeWrapper } from 'common/components/DateTimeWrapper';
import { TextChip } from 'common/components/TextChip';

import { CustomerIncident } from 'train-tool/types';

/**
 *
 * @param {any} props implicit destructured props
 * @param {CustomerIncident[]} props.defects all customer defects for a given locomotive
 * @returns {JSX.Element} expanded detail view for customer defects. we show all defects in a single view
 */
export const CustomerIncidentDetailView = ({
  defects,
}: {
  defects?: CustomerIncident[];
}) => {
  if (defects === undefined || defects.length === 0) {
    return <></>;
  }

  return (
    <React.Fragment>
      {defects.map((defect, index) => (
        <Stack key={defect.incidentOpenDesc}>
          <Stack direction={'row'} alignItems='center' spacing={1}>
            <TextChip label={defect.custIncidentType} />
            <Typography variant='button'>
              {defect.incidentCodeDescription}
            </Typography>
            <Typography variant='body2'>
              <DateTimeWrapper
                date={defect.incidentDate}
                options={{ dateOnly: true }}
              />
            </Typography>
          </Stack>

          <Divider sx={{ mt: 0.25, mb: 0.5 }} />

          <Stack sx={{ mb: index === defects.length - 1 ? 0 : 3, px: 1 }}>
            {defect.incidentOpenDesc}
          </Stack>
        </Stack>
      ))}
    </React.Fragment>
  );
};
