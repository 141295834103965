import { useQuery } from '@tanstack/react-query';
import { useMemo, useEffect } from 'react';

import { getBaseURL } from 'common/api/config';
import {
  selectDateSource,
  selectDayRange,
  selectDateRange,
} from 'common/cards/Data/date-range/dateRangeSlice';
import { fetchIngestedDataAction } from 'common/features/ingested-data/actions';
import { getIngestedFiredFaultData } from 'common/features/ingested-data/api';
import { useFetchDatascreenProperties } from 'common/features/ingested-data/api/hooks';
import { useAppDispatch, useAppSelector } from 'common/hooks';
import {
  selectApplicationContext,
  selectAssetDetails,
  selectUser,
} from 'common/stores/globalSlice';
import {
  selectHardFetchNewData,
  selectService,
} from 'common/stores/ingestedDataFooterSlice';

import { selectCase } from 'power-tool/stores/casesSlice';
import { selectRun } from 'power-tool/stores/runSlice';

import { selectVirtualEquation } from 'virtual-tester/stores/virtualSlice';

export const useFetchIngestedData = () => {
  const dispatch = useAppDispatch();

  const application = useAppSelector(selectApplicationContext);

  const vehicle = useAppSelector(selectAssetDetails);
  const selectedCase = useAppSelector(selectCase);
  const run = useAppSelector(selectRun);

  const { properties } = useFetchDatascreenProperties();
  const lookback = useAppSelector(selectDayRange);
  const dateSource = useAppSelector(selectDateSource);
  const dateRange = useAppSelector(selectDateRange);
  const hardFetch = useAppSelector(selectHardFetchNewData);
  const service = useAppSelector(selectService);
  const user = useAppSelector(selectUser);

  const virtualEquation = useAppSelector(selectVirtualEquation);

  // depending on the application, the way we fetch data might change
  const dataURL = useMemo(() => {
    if (application === 'virtual_tester') {
      return 'virtual/fetchAndCalculate';
    }

    return 'ingested-data';
  }, [application]);

  // behind the scenes there are 'three' selection levels in the app
  // asset, case, and run levels. depending on the selection,
  // the data screen is slightly different
  // we determine the selection level of the application for the backend here
  const selectionLevel = useMemo(() => {
    if (vehicle) {
      if (run?.objid) {
        return `runId=${run.objid}`;
      } else if (selectedCase?.caseId) {
        return `caseId=${selectedCase.caseId}`;
      } else {
        return `vehicleObjid=${vehicle.vehicleObjid}`;
      }
    }
  }, [vehicle?.vehicleObjid, selectedCase?.caseId, run?.objid]);

  // depending on the level and certain UI components
  // the user can grab more or less fault data from the DB
  const lookbackLogic = useMemo(() => {
    if (run?.objid) {
      return '';
    } else if (dateRange) {
      return `from=${dateRange.from.toISOString()}&to=${dateRange.to.toISOString()}&occurDate=${
        dateSource === 'occur_date'
      }`;
    } else {
      return `daysLookBack=${lookback}`;
    }
  }, [run?.objid, lookback, dateSource, dateRange]);

  const permissionProperties = useMemo(() => {
    if (properties) {
      return `diagnosticWeight=${properties.diagnosticsWeight}&isInternal=${properties.defaultLevel3}`;
    }
  }, [properties]);

  const screenType = useMemo(() => {
    if (service) {
      return `screenType=${service.screenType}`;
    }
  }, [service]);

  const equation = useMemo(() => {
    if (virtualEquation) {
      return `equationTxt=${encodeURIComponent(virtualEquation)}`;
    }
  }, [virtualEquation]);

  // when the listed dependencies change, we regenerate the URL
  // *** Main trigger for re-fetching ingested data ***
  const url = useMemo(() => {
    if (selectionLevel && screenType && permissionProperties) {
      switch (application) {
        case 'virtual_tester':
          if (equation) {
            return `${dataURL}?${selectionLevel}&${lookbackLogic}&${permissionProperties}&${screenType}&${equation}`;
          }
          break;

        default:
          return `${dataURL}?${selectionLevel}&${lookbackLogic}&${permissionProperties}&${screenType}`;
      }
    }

    return undefined;
  }, [
    application,
    selectionLevel,
    lookbackLogic,
    permissionProperties,
    screenType,
    equation,
  ]);

  // when 'hard fetch' is set to true, and URL is also defined, we go get new data
  // with a special flag included (handled in saga)
  useEffect(() => {
    if (url && hardFetch) {
      dispatch(fetchIngestedDataAction({ url, hardFetch }));
    }
  }, [url, hardFetch]);

  // as a side effect to the URL changing, we go fetch new data
  useEffect(() => {
    if (url) {
      dispatch(fetchIngestedDataAction({ url }));
    }

    return undefined;
  }, [url]);
};

export const useFetchIngestedFiredFaultData = () => {
  const selectedCase = useAppSelector(selectCase);

  const enabled = useMemo(() => {
    return selectedCase !== undefined && selectedCase.caseObjid > 0;
  }, [selectedCase?.caseObjid]);

  const hook = useQuery(
    ['ingested_fired_faults', selectedCase?.caseId],
    () => getIngestedFiredFaultData(selectedCase?.caseId),
    { enabled }
  );

  return {
    firedFaultData: hook.data,
    firedFaultDataStatus: hook.status,
    ...hook,
  };
};
