import { ICellRendererParams } from 'ag-grid-community';
import { escapeRegExp } from 'lodash';
import React from 'react';

import { useAppSelector } from 'common/hooks';
import { selectSearchText } from 'common/stores/timelineSlice';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

import { AnyTimelineEvent } from 'loco-history/types';

/**
 *
 * @param {ICellRendererParams<AnyTimelineEvent>} params ag-grid cell renderer parameters
 * @returns {JSX.Element} ag-grid cell component that highlights text as it is found in the quick filter component
 */
export const HighlightCellRenderer = (
  params: ICellRendererParams<AnyTimelineEvent>
) => {
  const searchText = useAppSelector(selectSearchText);
  const value = params.value as string;

  if (searchText) {
    const regex = new RegExp(`(${escapeRegExp(searchText)})`, 'i');
    return (
      <React.Fragment>
        {value.split(regex).map((item, index) => {
          if (item.toLowerCase() === searchText.toLowerCase()) {
            return (
              <span
                key={index}
                style={{
                  backgroundColor: PowerToolDarkTheme.statusYellow500,
                  color: 'black',
                }}
              >
                {item}
              </span>
            );
          }
          return <span key={index}>{item}</span>;
        })}
      </React.Fragment>
    );
  }

  return <span>{params.value}</span>;
};
