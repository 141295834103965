import CryptoJS from 'crypto-js';

type TrexVizParams = {
  customerId: string;
  groupName: string;
  assetNumber: string;
  timestamp: string;
  showLimit: boolean;
  showGraph: boolean;
  eoaEnabled: string;
};

const uuid = () => {
  let dt = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // tslint:disable-next-line: no-bitwise
    const r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
};

function getNextAlphabet(alphabet) {
  const char = alphabet.charCodeAt(0);
  return String.fromCharCode(char + 3);
}

function generateSessionKey() {
  let sessionKey = '';
  const uid = uuid();
  const alphabets = uid.split('');
  alphabets.forEach((item, i) => {
    if (i % 2 !== 0) {
      sessionKey += getNextAlphabet(alphabets[i - 1]);
    } else {
      sessionKey += item;
    }
  });
  sessionKey = encrypt(sessionKey);
  return sessionKey;
}

function getPrivateKey() {
  return CryptoJS.enc.Utf8.parse('$Trx-S!e@c#r$e%t');
}

function encrypt(value) {
  const obj = {
    keySize: 128 / 8,
    iv: getPrivateKey(),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  };
  return CryptoJS.AES.encrypt(
    CryptoJS.enc.Utf8.parse(value),
    getPrivateKey(),
    obj
  )
    .toString()
    .replace(/\//g, '****');
}

export function openTrxViz(params: TrexVizParams): string {
  const url =
    'https://rmd.corp.wabtec.com/trex/login/' +
    encodeURIComponent(encrypt(params.customerId)) +
    '/' +
    encodeURIComponent(encrypt(JSON.stringify(params))) +
    '/' +
    encodeURIComponent(generateSessionKey());

  return url;
}
