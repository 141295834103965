import { ICellRendererParams } from 'ag-grid-community';

import { LazyTooltip } from 'common/components/general/LazyTooltip';

export const WeatherIconCell = (params: ICellRendererParams) => {
  const data = params.data;
  const icon = data ? data.ICON : '';
  const desc = data ? data.WEATHER : '';

  if (icon) {
    return (
      <LazyTooltip title={desc}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <img
            src={require(`common/assets/weather/${icon}`)}
            alt={icon.replaceAll('.png', '')}
            height={'18px'}
            width={'18px'}
            style={{
              marginRight: '2px',
            }}
          />
        </div>
      </LazyTooltip>
    );
  } else {
    return <div />;
  }
};
