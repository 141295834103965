import { CloseOutlined } from '@mui/icons-material';
import { Button, Dialog, Paper } from '@mui/material';
import { useEffect, useState } from 'react';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

import { RuleHistoryGrid } from 'power-tool/cards/RuleOutput/RuleHistoryGrid';
import { RuleInfo } from 'power-tool/cards/RuleOutput/RuleInfo';
import { RuleHistoryModalProps } from 'power-tool/cards/RuleOutput/types';

export const RuleHistoryModal = (props: RuleHistoryModalProps) => {
  return (
    <Dialog
      maxWidth={'md'}
      open={props.open}
      disableScrollLock={true}
      onClose={props.onClose}
    >
      <Paper
        elevation={0}
        sx={{
          backgroundColor: PowerToolDarkTheme.main,
          py: 1,
          px: 2,
          overflow: 'auto',
        }}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <Button
          sx={{
            minWidth: 'unset',
            padding: '4px',
            mr: 1,
            alignSelf: 'flex-end',
          }}
          onClick={props.onClose}
        >
          <CloseOutlined />
        </Button>
        <RuleInfo />
        <RuleHistoryGrid />
      </Paper>
    </Dialog>
  );
};
