import styled from 'styled-components';

export const StyledPowerToolGrid = styled.div`
  height: 100%;

  .ag-header-cell {
    padding-left: 5px;
    padding-right: 5px;
  }

  .ag-cell-wrapper {
    overflow: hidden;
  }

  .ag-theme-alpine-dark {
    --ag-background-color: '#1e2f41';
    --ag-odd-row-background-color: #203b57;
    --ag-header-background-color: '#1e2f41';
  }
`;
