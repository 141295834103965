import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'common/store';

import { ToolOutputData } from 'power-tool/types';

export interface ToolOutputState {
  toolOutput: Array<ToolOutputData>;
}

const initialState: ToolOutputState = {
  toolOutput: [],
};

export const toolOutputSlice = createSlice({
  name: 'toolOutput',
  initialState,
  reducers: {
    setToolOutputData: (state, action: PayloadAction<ToolOutputData[]>) => {
      state.toolOutput = action.payload;
    },
  },
});

export const { setToolOutputData } = toolOutputSlice.actions;

export const selectToolOutput = (state: RootState) =>
  state.toolOutput.toolOutput;

export default toolOutputSlice.reducer;
