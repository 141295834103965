import {
  Paper,
  Typography,
  Divider,
  Fade,
  LinearProgress,
} from '@mui/material';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

import { LocoDisplay } from 'train-tool/components/LocoDisplay';
import { TATCloseoutButton } from 'train-tool/features/closeout/CloseoutButton';
import { useFetchTrain } from 'train-tool/hooks/apiHooks';

import {
  FaultTimingsComponent,
  FaultTimingsLeadColumn,
} from './table-components/FaultTimingsComponent';
import { HealthCheckDataSection } from './table-components/HealthCheckSection';
import { LeadColumnComponent } from './table-components/LeadColumnComponent';
import { LocoHeaderDetails } from './table-components/LocoHeaderComponent';
import { SnapshotDataSection } from './table-components/SnapshotSection';
import { useDisplayProps } from './table-components/hooks';

const fltControlItems = [
  'Notch',
  'Loco Mode',
  'Speed',
  'Dir Call',
  'Engine Run Switch (TL16)',
  'Generator Field Switch (TL6)',
  'DP Link Status',
  'BP/BC Pressure',
  'Lead/Trail',
];

const hcControlItems = [
  'Engine Control Switch',
  'DP Mode',
  'Automatic Brake Handle',
  'Fuel Level',
  'Air Flow',
  'Reverser Handle',
  'Independent Brake',
];

/**
 * @returns {JSX.Element} wrapper component that contains all monitor parameter information for the selected train
 */
export const SwitchesAndControlsComponent = () => {
  const { train, fetchStatus } = useFetchTrain();
  const { leadColumnProps, columnProps } = useDisplayProps();

  if (train && train.locomotives.length > 0) {
    return (
      <Fade
        in={train.locomotives.length > 0}
        style={{ transitionDelay: '0ms' }}
        timeout={250}
      >
        <div>
          <Fade in={fetchStatus !== 'idle'}>
            <LinearProgress />
          </Fade>
          <Paper
            id='train-status-view-paper'
            style={{
              position: 'relative',
              marginTop: '20px',
              backgroundColor: '#172839',
              color: 'white',
              margin: '10px',
              overflow: 'auto',
              height: '100%',
            }}
          >
            <TATCloseoutButton />
            <div
              id='train-status-view-wrapper'
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginLeft: '10px',
              }}
            >
              <div
                id='train-status-view'
                style={{
                  flexBasis: '100%',
                }}
              >
                {/* this element's sizing should match #train-status-view-table */}
                <Paper
                  id='train-status-view-header'
                  sx={{ boxShadow: 0 }}
                  style={{
                    margin: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    top: 0,
                    position: 'sticky',
                    backgroundColor: '#172839',
                    paddingBottom: '5px',
                    paddingTop: '15px',
                    marginTop: 0,
                    zIndex: 1,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <div
                      style={{
                        ...leadColumnProps,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'flex-end',
                      }}
                    >
                      {train.locomotives.length <= 0 ? (
                        <></>
                      ) : (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Typography
                            variant='h5'
                            sx={{
                              whiteSpace: 'nowrap',
                              fontSize: '18px',
                              mt: 1,
                            }}
                          >
                            {'Switches & Controls'}
                          </Typography>
                          <LocoHeaderDetails />
                        </div>
                      )}
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-around',
                        flexGrow: 1,
                        gap: '20px',
                      }}
                    >
                      {train?.locomotives.map((loco) => {
                        return (
                          <div
                            key={loco.vehicleObjid ?? loco.roadNumber}
                            style={{
                              ...columnProps,
                            }}
                          >
                            <LocoDisplay
                              loco={loco}
                              displayMode='header'
                              additionalDisplayComponent={
                                <LocoHeaderDetails asset={loco} />
                              }
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <Divider orientation='horizontal' sx={{ mt: 1 }} />
                </Paper>

                <div id='train-status-view-body'>
                  <Paper
                    id='train-status-view-timings-hc'
                    elevation={2}
                    style={{
                      backgroundColor: PowerToolDarkTheme.neutralDark100,
                      margin: '15px',
                      marginBottom: 0,
                    }}
                  >
                    <div
                      style={{
                        margin: '10px',
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      <FaultTimingsLeadColumn />

                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-around',
                          flexGrow: 1,
                          gap: '20px',
                        }}
                      >
                        <FaultTimingsComponent type='HC' />
                      </div>
                    </div>
                  </Paper>

                  {/* this element's sizing should match #train-status-view-header */}
                  <div
                    id='train-status-view-table-hc'
                    style={{
                      margin: '15px',
                      marginTop: 0,
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <LeadColumnComponent labels={hcControlItems} />

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-around',
                        flexGrow: 1,
                        gap: '20px',
                      }}
                    >
                      <HealthCheckDataSection />
                    </div>
                  </div>

                  <Paper
                    id='train-status-view-timings-flt'
                    elevation={2}
                    style={{
                      backgroundColor: PowerToolDarkTheme.neutralDark100,
                      margin: '15px',
                      marginBottom: 0,
                    }}
                  >
                    <div
                      style={{
                        margin: '10px',
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      <FaultTimingsLeadColumn />

                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-around',
                          flexGrow: 1,
                          gap: '20px',
                        }}
                      >
                        <FaultTimingsComponent type='FLT' />
                      </div>
                    </div>
                  </Paper>

                  {/* this element's sizing should match #train-status-view-header */}
                  <div
                    id='train-status-view-table-flt'
                    style={{
                      margin: '15px',
                      marginTop: 0,
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <LeadColumnComponent labels={fltControlItems} />

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-around',
                        flexGrow: 1,
                        gap: '20px',
                      }}
                    >
                      <SnapshotDataSection />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Paper>
        </div>
      </Fade>
    );
  } else {
    return <></>;
  }
};
