import { Layout } from 'react-grid-layout';

import {
  CaseUrgencyChoice,
  ConfigItems,
  HealthInfoData,
  RunInfo,
} from 'power-tool/types';

export const PowerToolApplicationContexts = [
  'power_tool',
  'train_analysis',
  'virtual_tester',
  'datascreen',
  'asset_view',
  'loco_history',
  'datapack_viewer',
  'asset_research',
  'rule_tester',
  'springboard',
  'tokenizer',
  'sockets',
] as const;
export type PowerToolApplicationContext =
  (typeof PowerToolApplicationContexts)[number];

export const HealthRisks = ['High', 'Med', 'Low', 'Very Low', 'No Comm'];
export type HealthRisk = (typeof HealthRisks)[number];

export const PowerToolEventTypeValues = [
  'CASE_OWNERSHIP_CHANGE',
  'KEEP_ALIVE_PING',
  'CASE_DISPATCHED',
  'RX_CUSTOMIZED',
] as const;
export type PowerToolEventChoice = (typeof PowerToolEventTypeValues)[number];

export type ResizeHandle = 's' | 'w' | 'e' | 'n' | 'sw' | 'nw' | 'se' | 'ne';

export type Card = {
  layout: Layout;
  component: JSX.Element;
};

export type PowerToolUrlParams = {
  caseId: string;
  ruleId: string;
  isDecryptedUrl: string;
  vehicleObjid: string;
  userId?: string;
  superUser?: string;
  virtualId?: string;
  virtualInfo?: string;
  application_context?: PowerToolApplicationContext;
  customer?: string;
  tz?: string;
  faultObjid?: string;
  template?: string;
  ruleInfo?: string;
  isClearingLogic?: string;
  debug?: string;
  ruleFamily?: string;
  from?: string;
  to?: string;
  dayRange?: string;
};

export type UserDateTimeConfig = {
  // https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
  zone: string;

  //  == Application Formats ==
  // https://moment.github.io/luxon/#/formatting?id=table-of-tokens

  // most timestamps follow this format e.g.: 01-22-21 18:19:58
  dateTimeFormat: 'LL-dd-yy TT';

  // date only
  dateFormat: 'LL-dd-yy';

  // 'nicer' 12 hour formatting e.g.: 01/22/21 4:19 PM
  dateTimeFormat12h: 'D t';
};

export type User = {
  userName: string | 'anonymous'; // 503129916
  eoaUserName: string; // Jlahut
  firstName?: string; // John
  lastName?: string; // Lahut
  cmPrivilege?: boolean; // case management
  userType?: string; // Employee
  strEmail?: string; // john.lahut@wabtec.com
  preferenceLanguage?: string; // 'en', 'es', etc.
  currRole?: 'Power Tool' | string; // We use the role to enable the action button
  customerId?: string;
  prefTimeZoneDesc: string;
};

export type PowerToolEvent = {
  event: PowerToolEventChoice;
  userId: string;
  caseId?: string;
  rxId?: string;
};

export type Asset = {
  roadNumber: string;
  vehicleHdr: string;
  customerOrgId: string;
  vehicleObjid: number;
  vehicleModel?: string;
  fleet: string;
  healthInfo?: HealthInfoData;
  configItems?: ConfigItems[];
  runs?: RunInfo;
  controller?: string;
  overhaulStartDate?: string;
  overhaulCompleteDate?: string;
  shipDate?: string;
  unitTimeZone?: string;
};

export type RX = {
  asset: string;
  rxCaseId: string;
  urgency: CaseUrgencyChoice;
  estRepairTime: string;
  rxOpenDate: string;
  title: string;
  status: string;
  pdfLink: string;
  lineZeroNotes?: string;
  rxCloseDate?: string;
};

export type Health = {
  healthDate: Date;
  health: number;
  risk: HealthRisk;
  healthDelta: number;
  latitude: number;
  longitude: number;
  incidentCodes: string;
  incidentDescriptions: string;
  performanceChecks?: string;
};

export type DateFormattingOptions = {
  dateOnly?: boolean;
  pretty?: boolean;
};

export type DateRange = {
  to: Date;
  from: Date;
};
