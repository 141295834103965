import { Switch } from '@mui/material';
import { useEffect } from 'react';

import { useAppSelector, useAppDispatch } from 'common/hooks';
import { selectRule } from 'common/stores/globalSlice';
import {
  selectShowFiredFaults,
  selectShowFiredParameters,
  setShowFiredFaults,
  setShowFiredParameters,
} from 'common/stores/ingestedDataFooterSlice';
import { isRunSelected } from 'common/util/utils';

import { selectCase } from 'power-tool/stores/casesSlice';
import { selectRun } from 'power-tool/stores/runSlice';

/**
 *
 */
export const ShowFiredComponent = () => {
  const dispatch = useAppDispatch();

  const selectedCase = useAppSelector(selectCase);
  const run = useAppSelector(selectRun);
  const rule = useAppSelector(selectRule);
  const showFiredFaults = useAppSelector(selectShowFiredFaults);
  const showFiredParameters = useAppSelector(selectShowFiredParameters);

  useEffect(() => {
    if (rule <= 0) {
      if (showFiredFaults === true) dispatch(setShowFiredFaults(false));
      if (showFiredParameters === true) {
        dispatch(setShowFiredParameters(false));
      }
    }

    // we do not want to include showFiredFaults and showFiredParameters in the deps
    // we only want to clear the showFired toggles when the rule changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rule, dispatch]);

  if ((isRunSelected(run) || selectedCase) && rule > 0) {
    return (
      <div
        style={{
          display: 'flex',
          flexFlow: 'row wrap',
          marginRight: '25px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexWrap: 'nowrap',
            alignItems: 'center',
          }}
        >
          <Switch
            size='small'
            checked={showFiredFaults === undefined ? false : showFiredFaults}
            onChange={(event) =>
              dispatch(setShowFiredFaults(event.target.checked))
            }
          />
          <span style={{ fontSize: '12px', whiteSpace: 'nowrap' }}>
            Fired Faults
          </span>
        </div>

        <div
          style={{
            display: 'flex',
            flexWrap: 'nowrap',
            alignItems: 'center',
          }}
        >
          <Switch
            size='small'
            checked={
              showFiredParameters === undefined ? false : showFiredParameters
            }
            onChange={(event) =>
              dispatch(setShowFiredParameters(event.target.checked))
            }
          />
          <span style={{ fontSize: '12px', whiteSpace: 'nowrap' }}>
            Fired MPs
          </span>
        </div>
      </div>
    );
  }
  return <div />;
};
