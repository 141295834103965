import { NoteAltOutlined } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';

import { useAppSelector } from 'common/hooks';
import { selectAssetDetails } from 'common/stores/globalSlice';
import { openOMDRx } from 'common/util/utils';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

/**
 *
 * @param params
 */
export function RuleIdCell(params: ICellRendererParams) {
  return params.data.ruleId !== '' ? (
    <React.Fragment>
      <span>Rule - {params.data.ruleId}</span>
    </React.Fragment>
  ) : (
    params.data.toolId
  );
}
