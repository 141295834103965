import { Divider } from '@mui/material';
import { Sparklines, SparklinesLine } from 'react-sparklines';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

import { LocoStatusDetailDisplayProps } from 'train-tool/types';

import { TextItem } from './TextItemComponent';
import { useRowProps } from './hooks';

/**
 *
 * @param {any} props implicit component properties
 * @param {number[]?} props.values values over time to display as sparkline. assumed to be already stored relative to time
 * @param {LocoStatusDetailDisplayProps?} props.displayProps any additional display properties for the given locomotive
 * @param {boolean?} props.divider flag to render the bottom divider or not
 * @param {string?} props.backgroundColor background color of the text item
 * @returns {JSX.Element} sparkline component used in the train status table. displays a value over time in the background, and a instantaneous value in the cell
 */
export const SparkLineItem = (props: {
  values?: number[];
  displayProps?: LocoStatusDetailDisplayProps;
  divider?: boolean;
  backgroundColor?: string;
}) => {
  const rowProps = useRowProps();

  if (props.displayProps?.value || (props.values && props.values.length > 0)) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: props.backgroundColor,
        }}
      >
        <div
          style={{
            ...rowProps,
            position: 'relative',
            zIndex: 0,
          }}
        >
          <Sparklines data={props.values} height={20}>
            <SparklinesLine
              color={
                props.displayProps?.format === 'warning'
                  ? PowerToolDarkTheme.statusRed600
                  : PowerToolDarkTheme.voltBlue400
              }
            />
          </Sparklines>

          {props.displayProps ? (
            <div
              style={{
                marginLeft: '6px',
                position: 'absolute',
                fontSize: '11px',
              }}
            >
              {props.displayProps.value}
            </div>
          ) : (
            <></>
          )}
        </div>
        {props.divider === false ? <></> : <Divider />}
      </div>
    );
  } else {
    return (
      <TextItem
        divider={props.divider}
        backgroundColor={props.backgroundColor}
      />
    );
  }
};
