import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ColumnApi, GridApi } from 'ag-grid-community';

import { OutboundMacroResults } from 'common/cards/Data/types';
import { IngestedData } from 'common/features/ingested-data/types';
// import { IngestedData, IngestedDataColumn } from 'common/cards/Data/types';
import { RootState } from 'common/store';

export type IngestedDataGridState = {
  // data: IngestedData[]; // all ingested data for the case
  fault?: IngestedData; // selected fault from tool output card
  // firedParameters: string[]; // associated parameters with selected fault from tool output card
  firedFaults: string[];
  selectedFaultCodes: string[];
  firedFltAndParamForRuleLoaded: boolean;
  gridApi?: GridApi;
  columnApi?: ColumnApi;
  // dataCardLoading: boolean;
  // columnPositionMap: Record<string, number>;
  // columns: IngestedDataColumn[];
  outboundResults?: Record<string, OutboundMacroResults>;
};

const initialState: IngestedDataGridState = {
  // data: [],
  // firedParameters: [],
  firedFaults: [],
  selectedFaultCodes: [],
  firedFltAndParamForRuleLoaded: false,
  // dataCardLoading: false,
  // columnPositionMap: {},
  // columns: [],
};

export const ingestedDataGridSlice = createSlice({
  name: 'ingestedDataGrid',
  initialState,
  reducers: {
    // setIngestedData: (state, action: PayloadAction<IngestedData[]>) => {
    //   state.data = action.payload;
    //   // console.log('set ingested data: ', state.data)
    // },
    // setColumns: (state, action: PayloadAction<IngestedDataColumn[]>) => {
    //   state.columns = action.payload;
    //   // console.log('set ingested data: ', state.data)
    // },
    setFault: (state, action: PayloadAction<IngestedData>) => {
      state.fault = action.payload;
      // console.log('set fault: ', state.fault);
    },
    // setFiredParameters: (state, action: PayloadAction<string[]>) => {
    //   state.firedParameters = action.payload;
    //   // console.log('set parameters: ', state.parameters);
    // },
    setFiredFaults: (state, action: PayloadAction<string[]>) => {
      state.firedFaults = action.payload;
    },
    setSelectedFaultCodes: (state, action: PayloadAction<string[]>) => {
      state.selectedFaultCodes = action.payload;
    },
    setFiredFltAndParamForRuleLoaded: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.firedFltAndParamForRuleLoaded = action.payload;
    },
    setGridApi: (state, action: PayloadAction<GridApi | undefined>) => {
      state.gridApi = action.payload;
    },
    setColumnApi: (state, action: PayloadAction<ColumnApi | undefined>) => {
      state.columnApi = action.payload;
    },
    // setDataCardLoading: (state, action: PayloadAction<boolean>) => {
    //   state.dataCardLoading = action.payload;
    // },
    // setColumnPositionMap: (
    //   state,
    //   action: PayloadAction<Record<string, number>>
    // ) => {
    //   state.columnPositionMap = action.payload;
    // },
    addOutboundResults: (
      state,
      action: PayloadAction<Record<string, OutboundMacroResults>>
    ) => {
      state.outboundResults = {
        ...(state.outboundResults ?? {}),
        ...action.payload,
      };
    },
    clearOutboundResults: (state, action: PayloadAction<void>) => {
      state.outboundResults = undefined;
    },
  },
});

export const {
  // setIngestedData,
  setFault,
  // setFiredParameters,
  setFiredFaults,
  setSelectedFaultCodes,
  setFiredFltAndParamForRuleLoaded,
  setGridApi,
  setColumnApi,
  // setDataCardLoading,
  // setColumnPositionMap,
  // setColumns: setIngestedDataColumns,
  addOutboundResults,
  clearOutboundResults,
} = ingestedDataGridSlice.actions;

// export const selectIngestedData = (state: RootState) => state.ingestedData.data;
export const selectFault = (state: RootState) => state.ingestedDataGrid.fault;
// export const selectFiredParameters = (state: RootState) =>
//   state.ingestedDataGrid.firedParameters;
export const selectFiredFaults = (state: RootState) =>
  state.ingestedDataGrid.firedFaults;
export const selectFaultCodes = (state: RootState) =>
  state.ingestedDataGrid.selectedFaultCodes;
export const selectFiredFltAndParamForRuleLoaded = (state: RootState) =>
  state.ingestedDataGrid.firedFltAndParamForRuleLoaded;
export const selectGridApi = (state: RootState) =>
  state.ingestedDataGrid.gridApi;
export const selectColumnApi = (state: RootState) =>
  state.ingestedDataGrid.columnApi;
export const selectOutboundResults = (state: RootState) =>
  state.ingestedDataGrid.outboundResults;
// export const selectDataCardLoading = (state: RootState) =>
//   state.ingestedDataGrid.dataCardLoading;
// export const selectColumnPositionMap = (state: RootState) =>
//   state.ingestedDataGrid.columnPositionMap;
// export const selectColumns = (state: RootState) => state.ingestedData.columns;
export default ingestedDataGridSlice.reducer;
