/**
 *
 * @param {React.SVGProps<SVGSVGElement>} props additional SVG display props
 * @returns {SVGElement} icon for a closed circuit switch
 */
function ClosedIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={props.width ?? 48}
      height={props.height ?? 24}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.18 16.558a4.153 4.153 0 003.143-1.443l6.303-2.966a4.307 4.307 0 00-.002.13c0 2.363 1.88 4.28 4.198 4.28 1.98 0 3.64-1.399 4.083-3.28H46.5a.5.5 0 000-1H35.02C35.02 9.916 33.14 8 30.822 8c-1.862 0-3.441 1.236-3.99 2.948L21.17 13.61a4.35 4.35 0 00.207-1.332C21.377 9.916 19.497 8 17.18 8c-2.318 0-4.197 1.916-4.197 4.28H1.5a.5.5 0 000 1h11.597c.442 1.88 2.102 3.278 4.082 3.278z'
        fill={props.fill ?? '#000'}
      />
      <path
        d='M20.323 15.115l-.213-.453-.095.045-.07.08.378.328zm6.303-2.966l.5.015.024-.814-.736.347.212.452zm8.279 1.13v-.5h-.396l-.091.386.487.114zm.115-1h-.5v.5h.5v-.5zm-8.188-1.331l.213.452.196-.093.067-.207-.476-.152zM21.17 13.61l-.476-.152-.352 1.095 1.04-.49-.212-.453zm-8.188-1.332v.5h.5v-.5h-.5zm.115 1l.487-.114-.09-.386h-.397v.5zm6.849 1.508a3.654 3.654 0 01-2.767 1.271v1a4.654 4.654 0 003.522-1.615l-.755-.656zm6.468-3.09l-6.304 2.965.426.905 6.303-2.965-.425-.905zm.71.582l.002-.115-1-.03a4.951 4.951 0 00-.001.145h1zm3.698 3.78c-2.033 0-3.697-1.684-3.697-3.78h-1c0 2.63 2.094 4.78 4.697 4.78v-1zm3.596-2.894c-.391 1.666-1.859 2.893-3.596 2.893v1c2.224 0 4.077-1.569 4.57-3.664l-.974-.23zm.487.614H46.5v-1H34.905v1zm11.595 0a1 1 0 001-1h-1v1zm1-1a1 1 0 00-1-1v1h1zm-1-1H35.02v1H46.5v-1zm-11.98.5h1-1zM30.822 8.5c2.034 0 3.698 1.683 3.698 3.78h1c0-2.631-2.094-4.78-4.698-4.78v1zm-3.514 2.6c.486-1.515 1.88-2.6 3.514-2.6v-1c-2.09 0-3.854 1.388-4.466 3.295l.952.305zm-5.925 2.964l5.662-2.664-.426-.905-5.662 2.664.426.905zm-.506-1.785c0 .413-.064.81-.183 1.18l.952.305a4.85 4.85 0 00.231-1.485h-1zM17.18 8.5c2.033 0 3.697 1.683 3.697 3.78h1c0-2.631-2.094-4.78-4.697-4.78v1zm-3.698 3.78c0-2.097 1.664-3.78 3.698-3.78v-1c-2.604 0-4.698 2.149-4.698 4.78h1zm0 0h-1 1zm-.5-.5H1.5v1h11.482v-1zm-11.482 0a1 1 0 00-1 1h1v-1zm-1 1a1 1 0 001 1v-1h-1zm1 1h11.597v-1H1.5v1zm15.68 2.278c-1.737 0-3.205-1.227-3.596-2.893l-.974.229c.493 2.095 2.346 3.664 4.57 3.664v-1z'
        fill={props.fill ?? '#000'}
      />
    </svg>
  );
}

export default ClosedIcon;
