import { Paper } from '@mui/material';
import React from 'react';

import { useFetchTrain } from 'train-tool/hooks/apiHooks';

import { TextItem } from './TextItemComponent';
import { useDisplayProps, useGetLatestRecord } from './hooks';

/**
 * @returns {JSX.Element} wrapper component for the snapshot data section of the train status table
 */
export const SnapshotDataSection = () => {
  const { train } = useFetchTrain();
  const { columnProps, evenColor, oddColor } = useDisplayProps();
  const { getLatestRecord } = useGetLatestRecord();

  return (
    <React.Fragment>
      {train?.locomotives?.map((loco) => {
        // we use snapshot if we don't have a health check or it is more recent
        const item = getLatestRecord(loco);

        return (
          <div
            key={loco.vehicleObjid ?? loco.roadNumber}
            style={{
              ...columnProps,
            }}
          >
            <Paper
              style={{
                overflow: 'hidden',
                backgroundColor: 'rgba(57, 71, 85, 0.50)',
              }}
            >
              <TextItem
                displayProps={item?.notch}
                backgroundColor={evenColor}
              />
              <TextItem
                displayProps={item?.locoMode}
                backgroundColor={oddColor}
              />
              <TextItem
                displayProps={item?.locoSpeed}
                backgroundColor={evenColor}
              />
              <TextItem
                displayProps={item?.dirCall}
                backgroundColor={oddColor}
              />

              <TextItem
                displayProps={item?.engineRunSwitch}
                showStatusIndicator={false}
                backgroundColor={evenColor}
              />
              <TextItem
                displayProps={item?.generatorFieldSwitch}
                showStatusIndicator={false}
                backgroundColor={oddColor}
              />
              <TextItem
                displayProps={item?.dpLinkStatus}
                showStatusIndicator={false}
                backgroundColor={evenColor}
              />
              <TextItem displayProps={item?.bpBc} backgroundColor={oddColor} />
              <TextItem
                displayProps={item?.leadTrail}
                divider={false}
                backgroundColor={evenColor}
              />
            </Paper>
          </div>
        );
      }) ?? <></>}
    </React.Fragment>
  );
};
