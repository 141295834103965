import { Paper } from '@mui/material';
import { useMemo } from 'react';
import { v4 as uuid } from 'uuid';

import { useFetchTrain } from 'train-tool/hooks/apiHooks';
import { TrainAsset } from 'train-tool/types';

import { ConsistGroupComponent } from './ConsistGroupComponent';

/**
 * @param {any} props implicit destructured props
 * @param {boolean | undefined} props.hideBackground toggle to hide background color for this component
 * @returns {JSX.Element} positional component representing train makeup. fills with dummy data on cases where there is no train found or initial load.
 */
export const TrainSummaryComponent = ({
  hideBackground,
}: {
  hideBackground?: boolean;
}) => {
  const { train, isPlaceholderData } = useFetchTrain();

  const dummyAsset = useMemo(() => {
    const dummyAsset: TrainAsset = {
      roadNumber: '',
      vehicleHdr: '',
      customerOrgId: '',
      vehicleObjid: 0,
      vehicleModel: '',
      fleet: '',
      position: 1,
      foreign: true,
      displayProps: {
        type: 'default',
        displayGroup: 1,
        lineType: 'none',
      },
    };
    return dummyAsset;
  }, []);

  const LocoGroups = useMemo(() => {
    const groups: JSX.Element[] = [];

    let currentDisplayGroup = 1;
    let currentGroup: TrainAsset[] = [];

    // if we are looking at a dummy train
    if (train?.trainId === null || isPlaceholderData) {
      [1, 2, 3].forEach((position) => {
        currentGroup.push({
          ...dummyAsset,
          position,
        });
      });
    }

    // otherwise we got some actual train data
    else {
      train?.locomotives.forEach((loco) => {
        if (loco.displayProps.displayGroup > currentDisplayGroup) {
          groups.push(
            <ConsistGroupComponent key={uuid()} locos={[...currentGroup]} />
          );
          currentGroup = [];
        }

        currentGroup.push({ ...loco });
        currentDisplayGroup = loco.displayProps.displayGroup;
      });
    }

    groups.push(
      <ConsistGroupComponent key={uuid()} locos={[...currentGroup]} />
    );

    // show EOT sensor component when we have a train
    // with more than one locomotive, and when the
    // data is defined
    if (
      train?.locomotives &&
      train.locomotives.length > 0 &&
      train.locomotives[0].healthCheckData?.eotBP
    ) {
      groups.push(
        <ConsistGroupComponent
          eot={true}
          key={uuid()}
          locos={[train.locomotives[0]]}
        />
      );
    }

    return groups;
  }, [train, dummyAsset, isPlaceholderData]);

  return (
    <Paper
      elevation={hideBackground ? 0 : 1}
      id='loco-status-view-paper'
      style={{
        margin: '10px',
        backgroundColor: hideBackground ? 'transparent' : '#172839',
        color: 'white',
        overflow: 'auto',
        height: '100%',
      }}
    >
      <div
        id='loco-status-view-wrapper'
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginLeft: '10px',
          marginRight: '10px',
          paddingTop: '5px',
          height: '100%',
        }}
      >
        <div
          id='loco-group-wrapper'
          style={{
            flexBasis: '35%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'stretch',
          }}
        >
          <div
            id='loco-group-display'
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              flexBasis: '100%',
              gap: '4px',
              margin: 'auto',
            }}
          >
            {LocoGroups}
          </div>
        </div>
      </div>
    </Paper>
  );
};
