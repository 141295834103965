import chroma from 'chroma-js';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

/**
 *
 * @param props
 * @param props.value
 */
export const GradientBadgeComponent = (props: { value: number }) => {
  /**
   *
   * @param value
   */
  const getPercentGradient = (value: number | undefined) => {
    return (
      chroma
        .scale([
          PowerToolDarkTheme.statusRed700,
          PowerToolDarkTheme.statusOrange700,
          PowerToolDarkTheme.statusYellow600,
          PowerToolDarkTheme.statusGreen500,
        ])
        // @ts-ignore
        .nodata(PowerToolDarkTheme.statusGray600)
        .domain([100, 75, 35, 0])(value)
    );
  };

  if (props.value) {
    return (
      <div style={{ width: '100%' }}>
        <span
          style={{
            color: 'white',
            fontWeight: 'bold',
            marginLeft: '2px',
            backgroundColor: getPercentGradient(
              props.value.toString() === '' ? undefined : props.value
            ).hex(),
            padding: '2px 10px',
            borderRadius: '12px',
          }}
        >
          {!props.value ? ' - ' : `${props.value}%`}
        </span>
      </div>
    );
  }

  return <></>;
};
