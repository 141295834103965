import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { useAppSelector } from 'common/hooks';
import { selectAssetDetails } from 'common/stores/globalSlice';

import { selectCase } from 'power-tool/stores/casesSlice';
import { ToolOutputData } from 'power-tool/types';

import { getLookupsByName, getRxById, getCurrentCaseOwner } from './api';

export const useFetchCustomRx = (rxId?: string) => {
  const enabled = useMemo(() => {
    return rxId !== undefined;
  }, [rxId]);

  const hook = useQuery(['fetch_custom_rx', rxId], () => getRxById(rxId!), {
    enabled,
  });

  return {
    customRx: hook.data,
    customRxStatus: hook.status,
    ...hook,
  };
};

export const useFetchWorkOrderCustomers = () => {
  const caseDetails = useAppSelector(selectCase);
  const enabled = useMemo(() => {
    return (
      caseDetails !== undefined && caseDetails.caseType === 'Inbound Review'
    );
  }, [caseDetails]);

  const hook = useQuery(
    ['workorder_customers', 'WORKORDER_CUSTOMERS'],
    () => getLookupsByName('WORKORDER_CUSTOMERS'),
    { enabled }
  );
  return {
    customers: hook.data,
    customersStatus: hook.status,
    ...hook,
  };
};
export const useFetchAdvisoryRX = () => {
  const caseDetails = useAppSelector(selectCase);
  const enabled = useMemo(() => {
    return (
      caseDetails !== undefined && caseDetails.caseType === 'Inbound Review'
    );
  }, [caseDetails]);

  const hook = useQuery(
    ['advisory_rx', 'GPOC_ADVISORY_RX'],
    () => getLookupsByName('GPOC_ADVISORY_RX'),
    { enabled }
  );
  return {
    rxId: hook.data,
    rxIdStatus: hook.status,
    ...hook,
  };
};

export const useFetchMandatoryTitles = () => {
  const caseDetails = useAppSelector(selectCase);
  const enabled = useMemo(() => {
    return caseDetails !== undefined;
  }, [caseDetails]);

  const hook = useQuery(
    ['mandatory_advisory_rx_lookup', 'INSHOP_REASON_FOR_WORKSCOPE_ENHANCEMENT'],
    () => getLookupsByName('INSHOP_REASON_FOR_WORKSCOPE_ENHANCEMENT'),
    { enabled }
  );
  return {
    mandatoryTitles: hook.data,
    mandatoryTitlesStatus: hook.status,
    ...hook,
  };
};

export const useFetchAdvisoryRx = () => {
  const asset = useAppSelector(selectAssetDetails);
  const caseDetails = useAppSelector(selectCase);
  const { rxId } = useFetchAdvisoryRX();
  const { customers } = useFetchWorkOrderCustomers();

  const enabled = useMemo(() => {
    //if all required fields are set
    return (
      asset?.customerOrgId !== undefined &&
      caseDetails !== undefined &&
      rxId !== undefined &&
      rxId.length > 0 &&
      rxId[0] !== undefined &&
      customers !== undefined &&
      customers.length > 0 &&
      //the case is inbound review
      caseDetails.caseType === 'Inbound Review' &&
      //and the customer is applicable
      customers.find(
        (customer) => customer.lookValue === asset.customerOrgId
      ) !== undefined
    );
  }, [asset, caseDetails, rxId, customers]);

  const hook = useQuery(
    ['fetch_advisory_rx', rxId?.[0]?.lookValue ?? ''],
    () => {
      return getRxById(rxId![0].lookValue!);
    },
    {
      enabled,
    }
  );

  const returnVal: ToolOutputData | undefined = useMemo(() => {
    //format the rx
    return hook.data?.length !== undefined && hook.data.length > 0
      ? {
          toolId: 'JDPAD',
          ruleId: 0,
          rxTitle: hook.data[0].rxTitle,
          lastUpdatedDate: '',
          rxId: parseInt(hook.data[0].rxId),
          rxRepairTime: hook.data[0].rxRepairTime,
          arListObjid: 0,
          isFlowchartOnly: hook.data[0].isFlowchartOnly,
          //only close and deliver
          actions: ['close', 'deliver'],
        }
      : undefined;
  }, [hook.data]);

  return {
    advisoryRx: returnVal,
    advisoryRXtatus: hook.status,
    ...hook,
  };
};

export const useFetchCurrentCaseOwner = () => {
  const selectedCase = useAppSelector(selectCase);

  const enabled = useMemo(() => {
    return selectedCase?.caseId !== undefined;
  }, [selectedCase?.caseId]);

  const hook = useQuery(
    ['case_owner', { selectedCase }],
    () => getCurrentCaseOwner(selectedCase?.caseId),
    { enabled }
  );

  return {
    owner: hook.data?.LOGIN_NAME,
    queueStatus: hook.data?.IN_WORK_QUEUE,
    ...hook,
  };
};
