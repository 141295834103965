// when we are deployed on servers, we use nginx as our
// reverse proxy / http server, and backend requests are routed to /api/forensics
// locally for development we hit the backend directly without nginx
import { useOktaAuth } from '@okta/okta-react';
import axios from 'axios';
import { useEffect } from 'react';

import { store } from 'common/store';
import { isAppDeployed } from 'common/util/utils';

// so we use the backend's base URL
export const getBaseURL = () => {
  const state = store.getState();
  const applicationContext = state.global.applicationContext;
  const isCloudfront = process.env.REACT_APP_IS_CLOUDFRONT === 'true';

  // *** This code really needs to be cleaned up
  //     and fixed when we fully change over
  //     our deployment
  if (isAppDeployed()) {
    if (applicationContext === 'train_analysis') {
      if (isCloudfront) {
        return `${process.env.REACT_APP_GATEWAY_URL}/powertool/api/traintool`;
      }
      return '/powertool/api/traintool';
    }

    if (isCloudfront) {
      return `${process.env.REACT_APP_GATEWAY_URL}/powertool/api/forensics`;
    }
    return '/powertool/api/forensics';
  } else {
    if (applicationContext === 'train_analysis') {
      return 'http://localhost:8081/api/v1';
    }

    return 'http://localhost:8080/api/v1';
  }
};

// we need to setup this as an interceptor so we can check the
// base URL parameters each request
// otherwise, the URL is only set on load before the application_context is set
axios.interceptors.request.use((config) => {
  config.baseURL = getBaseURL();
  return config;
});

export const OktaTokenInterceptor = ({ children }) => {
  const { oktaAuth } = useOktaAuth();

  useEffect(() => {
    const interceptor = axios.interceptors.request.use((config) => {
      config.headers = {
        Authorization: `${oktaAuth?.getAccessToken()}`,
      };
      return config;
    });

    return () => axios.interceptors.response.eject(interceptor);
  }, [oktaAuth, axios]);

  return children;
};
