import { all, takeLatest } from 'redux-saga/effects';

import { fetchIngestedDataAction } from 'common/features/ingested-data/actions';
import { fetchIngestedDataHandler } from 'common/features/ingested-data/handlers';

function* watchIngestedData() {
  yield takeLatest(fetchIngestedDataAction.type, fetchIngestedDataHandler);
}

export default function* ingestedDataSaga() {
  yield all([watchIngestedData()]);
}
