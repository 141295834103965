import {
  ClickAwayListener,
  TooltipComponentsPropsOverrides,
} from '@mui/material';
import chroma from 'chroma-js';

import { BigTooltip } from 'common/components/general/BigTooltip';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

/**
 *
 * @param {any} props implicit destructured props
 * @param {JSX.Element | string} props.title title component or string for the tooltip
 * @param {JSX.Element} props.content component within the tooltip
 * @param {boolean} props.open tooltip open or closed
 * @param {TooltipComponentsPropsOverrides | undefined} props.tooltipProps MUI tooltip property overrides
 * @param {(event: MouseEvent | TouchEvent) => void} props.onClickAway click away handler function
 * @returns {JSX.Element} tooltip wrapper element for a locomotive event
 */
export const EventTooltip = ({
  title,
  content,
  open,
  tooltipProps,
  onClickAway,
}: {
  title: JSX.Element | string;
  content: JSX.Element;
  open: boolean;
  tooltipProps?: TooltipComponentsPropsOverrides;
  onClickAway: (event: MouseEvent | TouchEvent) => void;
}) => {
  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <div>
        <BigTooltip
          arrow
          placement='top'
          open={open}
          title={title}
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: chroma(PowerToolDarkTheme.neutralLight900)
                  .alpha(0.7)
                  .hex(),
                backdropFilter: 'blur(5px)',
              },
            },
          }}
          {...tooltipProps}
        >
          {content}
        </BigTooltip>
      </div>
    </ClickAwayListener>
  );
};
