export const getTemplateLabel = (
  template: string | number | undefined
): string => {
  template = template?.toString();
  switch (template) {
    case '0':
      return 'Common';
    case '1':
      return 'Traction (no HIC)';
    case '2':
      return 'Traction (with HIC)';
    case '3':
      return 'Engine and Cooling';
    case '4':
      return 'AUX';
    case '5':
      return 'Train Control';
    case '6':
      return 'Health';
    case '7':
      return 'Compressed Air';
    case '8':
      return 'Equipment Ventilation';
    case '9':
      return 'ARC Net';
    case '10':
      return 'Software/Ethernet Infrastructure';
    case '11':
      return 'Trip Optimizer / LCCM';
    case '12':
      return 'Traction Long';
    case '13':
      return 'Tender Controls LNG';
    case '14':
      return 'DHMS / Combo Health';
    case '15':
      return 'Crank';
  }

  return 'Unknown';
};
