import { AccordionDetails, Grow, SelectChangeEvent } from '@mui/material';
import { useState, useEffect } from 'react';

import { useAppSelector } from 'common/hooks';

import { selectToolOutputDropdowns } from 'power-tool/stores/caseActionSlice';
import {
  AdditionalActionProps,
  CaseActionChoice,
  ToolOutputItemState,
} from 'power-tool/tool-dispo/types';

import { CaseActionSelectComponent } from './SelectComponent';

export const CloseComponent = (props: AdditionalActionProps) => {
  const dropdownValues = useAppSelector(selectToolOutputDropdowns);

  const [action, setAction] = useState<CaseActionChoice | null>(props.action);
  const [item, setItem] = useState<ToolOutputItemState>(
    props.currentToolOutput
  );

  useEffect(() => {
    setItem(props.currentToolOutput);
  }, [props.currentToolOutput]);

  useEffect(() => {
    setAction(props.action);
  }, [props.action]);

  const onChange = (event: SelectChangeEvent<string>) => {
    props.onUpdate({
      ...item,
      closeReason: event.target.value,
    });
  };

  if (action === 'close') {
    return (
      <Grow in={action === 'close'}>
        <AccordionDetails
          sx={{ ml: 2, display: 'flex', justifyContent: 'flex-end' }}
        >
          <CaseActionSelectComponent
            selectProps={{
              error: item.closeReason === '',
            }}
            onChange={onChange}
            value={item.closeReason}
            id='close-reason'
            label='Close Reason'
            items={Object.entries(dropdownValues!.closeOptions).map(
              ([key, value]) => ({
                value: key,
                description: value.description,
              })
            )}
          />
        </AccordionDetails>
      </Grow>
    );
  } else {
    return <></>;
  }
};
