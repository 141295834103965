import { TextChip } from 'common/components/TextChip';
import { UrgencyColorMap } from 'common/config/config';
import { RX } from 'common/types/types';

import {
  AnalysisCardAlertType,
  AnyAnalysisCardAlert,
} from 'train-tool/cards/Analysis/types';

/**
 *
 * @param {any} props implicit component props
 * @param {AnalysisCardAlertType} props.type source type of the alert
 * @param {string} props.message title to display
 * @param {AnyAnalysisCardAlert} props.source source object of the alert
 * @returns {JSX.Element} generic title component wrapper for any given alert
 */
export const AlertTitleComponent = (props: {
  type: AnalysisCardAlertType;
  message: string;
  source?: AnyAnalysisCardAlert;
}) => {
  switch (props.type) {
    case 'rx':
      const rx = props?.source as RX;

      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {props.message}
          <TextChip
            label={rx.urgency}
            backgroundColor={UrgencyColorMap[rx.urgency]}
            style={{
              marginLeft: '4px',
              fontSize: '10px',
              display: 'flex',
            }}
          />
        </div>
      );

    default:
      return (
        <div
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {props.message}
        </div>
      );
  }
};
