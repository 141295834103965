import { BookmarkAdded, BookmarkAddOutlined } from '@mui/icons-material';
import { Checkbox, Paper, Stack, Tooltip, Typography } from '@mui/material';
import chroma from 'chroma-js';
import React, { useMemo } from 'react';

import { TimelineEventType } from 'common/features/timeline/types';
import { useAppDispatch, useAppSelector } from 'common/hooks';
import {
  addTimelineEvent,
  removeTimelineEvent,
  selectTimelineEvents,
} from 'common/stores/timelineSlice';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

import { createTimelineEvent } from 'loco-history/cards/Timeline/components/events/utils';
import { TimelineEventTypeChip } from 'loco-history/components/TimelineEventTypeChip';
import { AnyTimelineEvent } from 'loco-history/types';

import { EventTooltipContent } from './EventTooltipContent';

/**
 *
 * @param {any} props implicit destructured props
 * @param {AnyTimelineEvent} props.event loco event that this tooltip represents
 * @param {TimelineEventType} props.type type of loco event
 * @param {(content: string) => void | undefined} props.onContentClick callback for when content within the tooltip is clicked
 * @returns {JSX.Element} body component of a locomotive event tooltip
 */
export const EventTooltipBody = ({
  event,
  type,
  onContentClick,
}: {
  event: AnyTimelineEvent;
  type: TimelineEventType;
  onContentClick?: (content: string) => void;
}) => {
  const dispatch = useAppDispatch();

  const timelineEvents = useAppSelector(selectTimelineEvents);

  const checked = useMemo(() => {
    return timelineEvents.map((e) => e.id).includes(event.id);
  }, [timelineEvents, event]);

  /**
   *
   * @param {React.ChangeEvent<HTMLInputElement>} e checkbox change event - handles when timeline events are added/removed via checkbox icon
   */
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      dispatch(addTimelineEvent(createTimelineEvent(event)));
    } else if (!e.target.checked) {
      dispatch(removeTimelineEvent(event.id));
    }
  };

  return (
    <div
      style={{
        fontSize: '13px',
        display: 'flex',
        minWidth: '500px',
        flexDirection: 'column',
        gap: 10,
      }}
    >
      {/* Header */}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ flex: '0 0 100px' }}>
          <TimelineEventTypeChip type={type} />
        </div>

        <div>
          <Typography variant='subtitle1' sx={{ fontWeight: 600 }}>
            {'Event Details'}
          </Typography>
        </div>

        <div
          style={{
            flex: '0 0 100px',
            justifyContent: 'flex-end',
            display: 'flex',
          }}
        >
          <Tooltip
            title={<Typography variant='body2'>{'Add to Story'}</Typography>}
            placement='top'
          >
            <Checkbox
              color='success'
              checked={checked}
              onChange={onChange}
              checkedIcon={<BookmarkAdded />}
              icon={<BookmarkAddOutlined />}
            />
          </Tooltip>
        </div>
      </div>

      <Paper
        variant='outlined'
        sx={{
          backgroundColor: chroma(PowerToolDarkTheme.neutralLight800)
            .alpha(0.75)
            .hex(),
          p: 1,
        }}
      >
        <Stack>
          <EventTooltipContent
            event={event}
            type={type}
            onContentClick={onContentClick}
          />
        </Stack>
      </Paper>
    </div>
  );
};
