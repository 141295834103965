import { ColumnApi, GridApi } from 'ag-grid-community';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo } from 'react';
import { batch } from 'react-redux';

import { useFetchEvoModels } from 'common/api/hooks';
import {
  useGetGPSColumns,
  useIsValidGPSLocation,
} from 'common/cards/Data/hooks';
import { selectFiredFaults, setFiredFaults } from 'common/cards/Data/store';
import { QuickFilterChoices } from 'common/cards/Data/types';
import {
  applyFilter,
  applyHCFilter,
  applySetFilter,
  destroyFilter,
} from 'common/cards/Data/utils';
import { MapMarkerData } from 'common/cards/Map/types';
import { useFetchIngestedFiredFaultData } from 'common/features/ingested-data/hooks';
import {
  selectIngestedDataFooter,
  selectIngestedDataStatus,
} from 'common/features/ingested-data/store';
import { IngestedData } from 'common/features/ingested-data/types';
import { useAppDispatch, useAppSelector } from 'common/hooks';
import {
  selectApplicationContext,
  selectAssetDetails,
} from 'common/stores/globalSlice';
import {
  selectClearAllFilters,
  selectHealthCheckFilterState,
  selectQuickFilters,
  selectShowFiredFaults,
  selectShowFiredParameters,
  selectedQuickNavigation,
  setClearAllFilters,
  setQuickFilters,
  setSelectedQuickNavigation,
} from 'common/stores/ingestedDataFooterSlice';
import { setMarkerData } from 'common/stores/mapSlice';

import {
  useFetchFiredFaults,
  useFetchFiredParameters,
} from 'power-tool/api/hooks';

// handles quick filter states
// IMPORTANT: ONLY HANDLES INTERNAL FILTERS
//            EXTERNAL FILTERS ARE HANDLED IN doesExternalFilterPass callback
export const useQuickFilterHandler = (api: GridApi | undefined) => {
  const asset = useAppSelector(selectAssetDetails);
  const quickFilters = useAppSelector(selectQuickFilters).map(
    (filter) => filter.type
  );
  const { evoModels } = useFetchEvoModels();

  const evo = useMemo(() => {
    return evoModels?.includes(asset?.vehicleModel ?? '');
  }, [evoModels, asset]);

  useEffect(() => {
    if (quickFilters) {
      QuickFilterChoices.forEach((filter) => {
        const existing = Object.keys(api?.getFilterModel() ?? {});
        const active = quickFilters.includes(filter);
        let column: string;
        let threshold: number;

        if (filter === 'notch_8') {
          column = evo ? 'MP_0102_N_0_0' : 'NOTCH';

          // if the quick filter is active, apply it
          if (active) {
            applySetFilter(column, [8.0], api);
          }

          // otherwise make sure that there isn't
          // a user applied filter on that column
          else if (!existing.includes(column)) {
            destroyFilter(column, api);
          }
        }

        if (filter === 'engine_running') {
          column = evo ? 'MP_1005_N_0_0' : 'ENGINE_SPEED';

          // if the quick filter is active, apply it
          if (active) {
            applyFilter(column, 'number', 'greaterThanOrEqual', 300.0, api);
          }

          // otherwise make sure that there isn't
          // a user applied filter on that column
          else if (!existing.includes(column)) {
            destroyFilter(column, api);
          }
        }

        if (filter === 'full_load') {
          column = evo ? 'MP_1000_N_0_0' : 'HP';
          threshold = evo ? 4000.0 : 3900.0;

          // if the quick filter is active, apply it
          if (active) {
            applyFilter(column, 'number', 'greaterThanOrEqual', threshold, api);
          }

          // otherwise make sure that there isn't
          // a user applied filter on that column
          else if (!existing.includes(column)) {
            destroyFilter(column, api);
          }
        }
      });

      // trigger filter refresh once processing is done
      // as a side effect, also triggers any external filters
      api?.onFilterChanged();
    }
  }, [api, quickFilters, evo]);
};

// handles putting fired faults on the map automatically
// we say 'fired faults' but really its faults with valid locations
// that we have already collected weather for
export const useFaultMapHandler = (data: IngestedData[] | undefined) => {
  const dispatch = useAppDispatch();

  const application = useAppSelector(selectApplicationContext);
  const status = useAppSelector(selectIngestedDataStatus);

  const { gpsColumns } = useGetGPSColumns();
  const { isValidLocation } = useIsValidGPSLocation();

  return useEffect(() => {
    // for every app thats not TAT
    if (application !== 'train_analysis') {
      // if data is done loading
      if (status === 'complete' && gpsColumns) {
        const set = new Set<MapMarkerData>();

        // get the unique valid locations from the dataset with weather data
        data
          ?.filter((row) => isValidLocation(row))
          .filter((row) => row.WEATHER !== undefined)
          .forEach((row) =>
            set.add({
              long: row[gpsColumns.longitude],
              lat: row[gpsColumns.latitude],
              date: row.OCCUR_DATE,
              id: row.OBJID,
              weather: row.WEATHER,
              //there are a few states that are numbers, safety parse
              state: String(row.STATE),
              name: row.NAME,
              tempF: row.TEMPF,
              windSpeedMPH: row.WINDSPEEDMPH,
              precipIN: row.PRECIPIN,
              elevFT: row.ELEVFT,
              type: 'auto',
            })
          );

        // then dispatch them to map card
        dispatch(setMarkerData(Array.from(set)));
      } else if (status === 'fetching') {
        dispatch(setMarkerData([]));
      }
    }
  }, [application, status, gpsColumns, data]);
};

export const useClearFilterHandler = (
  api: GridApi<IngestedData> | undefined
) => {
  const dispatch = useAppDispatch();

  const clearAllFilters = useAppSelector(selectClearAllFilters);

  useEffect(() => {
    if (clearAllFilters) {
      const reapplyHC =
        api?.getFilterModel().RECORD_TYPE &&
        api?.getFilterModel().RECORD_TYPE.values.indexOf('HC') < 0;

      api?.setFilterModel(null);
      if (reapplyHC) {
        applyHCFilter(api);
      }

      batch(() => {
        dispatch(setClearAllFilters(false));
        dispatch(setQuickFilters([]));
      });
    }
  }, [clearAllFilters, api, dispatch]);
};

export const useQuickNavHandler = (api?: GridApi, colApi?: ColumnApi) => {
  const dispatch = useAppDispatch();

  const quickNav = useAppSelector(selectedQuickNavigation);
  // quick nav button handler
  useEffect(() => {
    if (quickNav) {
      const column = colApi
        ?.getAllDisplayedColumns()
        ?.find(
          (col) => col.getParent().getColGroupDef()?.headerName === quickNav
        );

      if (column) {
        api?.ensureColumnVisible(column?.getColId(), 'start');
      }

      dispatch(setSelectedQuickNavigation(undefined));
    }
  }, [quickNav, api, colApi]);
};

export const useShowNoFiredFaultsHandler = (api?: GridApi) => {
  const toggle = useAppSelector(selectShowFiredFaults);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (
      toggle &&
      api?.getDisplayedRowCount() === 0 &&
      Object.keys(api?.getFilterModel()).length > 0
    ) {
      enqueueSnackbar('Filters cleared to show fired faults.', {
        variant: 'info',
      });
      api?.setFilterModel(null);
      api?.onFilterChanged();
    }
  }, [toggle]);
};

export const useColumnVisibilityHandler = (
  api?: GridApi,
  colApi?: ColumnApi
) => {
  const { firedFaultData } = useFetchIngestedFiredFaultData();
  const { firedParameters } = useFetchFiredParameters();

  const footer = useAppSelector(selectIngestedDataFooter);
  const firedParameterToggle = useAppSelector(selectShowFiredParameters);
  const status = useAppSelector(selectIngestedDataStatus);

  // when we get fired fault data, ensure the columns with fired faults are shown
  useEffect(() => {
    if (firedFaultData?.columns) {
      colApi?.setColumnsVisible(
        firedFaultData.columns?.nonEmptyColumns ?? [],
        true
      );
    }
  }, [colApi, firedFaultData]);

  useEffect(() => {
    // fired parameters hide all non-fired columns
    // except columns that are pinned - we leave those alone
    if (firedParameterToggle) {
      const columnHeaders = colApi
        ?.getAllGridColumns()
        .filter(
          (col) =>
            !firedParameters?.includes(col.getColDef().headerName ?? '') &&
            !col.isPinned()
        )
        .map((col) => col.getColId() ?? '');
      colApi?.setColumnsVisible(columnHeaders ?? [], false);
    }

    // when we get the footer, ensure columns with no data are hidden
    else if (api && status !== 'fetching' && api.getDisplayedRowCount() > 0) {
      colApi?.setColumnsVisible(footer?.nonEmptyColumns ?? [], true);
      colApi?.setColumnsVisible(footer?.emptyColumns ?? [], false);
    }
  }, [api, colApi, firedParameterToggle, firedParameters, status]);
};

export const useFiredDataHandler = (api?: GridApi) => {
  const dispatch = useAppDispatch();
  const firedFaultToggle = useAppSelector(selectShowFiredFaults);

  // firedFaults represents the 'final' fired faults for the given rule + case
  const { firedFaults } = useFetchFiredFaults();

  // current fired faults represents the current state of firings
  // i.e. if a simple rule is selected, this will have the partial
  // firings for that simrul.
  const currentFiredFaults = useAppSelector(selectFiredFaults);

  // trigger filter when these values change
  useEffect(() => {
    api?.onFilterChanged();
  }, [api, firedFaultToggle]);

  // dispatch to redux when fired faults changes
  // TODO: dispatch shouldn't be needed, fired fault hook can be used
  useEffect(() => {
    if (firedFaults) {
      dispatch(setFiredFaults(firedFaults));
    }
  }, [firedFaults]);

  // whenever fired faults change, we redraw the rows
  // and reapply the fired fault toggle if applicable
  useEffect(() => {
    api?.redrawRows();
    api?.onFilterChanged();
  }, [currentFiredFaults]);
};

export const useHealthCheckFilterHandler = (api?: GridApi) => {
  const application = useAppSelector(selectApplicationContext);
  const state = useAppSelector(selectHealthCheckFilterState);
  const status = useAppSelector(selectIngestedDataStatus);

  // we apply HC filter automatically when:
  //    we are in non-TAT and data is streaming back or
  //    we are in TAT and the filter state is true
  useEffect(() => {
    if (status === 'streaming') {
      if (application === 'train_analysis' && state) {
        applyHCFilter(api);
      } else if (application !== 'train_analysis') {
        applyHCFilter(api);
      }
    } else if (application === 'train_analysis') {
      state ? applyHCFilter(api) : destroyFilter('RECORD_TYPE', api);
    }
  }, [api, application, status, state]);
};
