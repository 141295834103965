import {
  FormControl,
  FormControlLabel,
  Checkbox,
  Fade,
  CircularProgress,
  Box,
} from '@mui/material';
import {
  ColDef,
  GetQuickFilterTextParams,
  GridApi,
  GridReadyEvent,
  RowNode,
  ValueGetterParams,
} from 'ag-grid-community';
import chroma from 'chroma-js';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  EventDisplayProps,
  TimelineEventType,
} from 'common/features/timeline/types';
import { useAppDispatch, useAppSelector } from 'common/hooks';
import {
  addSelectedTimelineEventType,
  removeSelectedTimelineEventType,
  selectSelectedTimelineEventTypes,
  selectTimelineMode,
} from 'common/stores/timelineSlice';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

import {
  useFetchCallEvents,
  useFetchDefectEvents,
  useFetchFaultEvents,
  useFetchIncidentEvents,
  useFetchMaterialUsage,
  useFetchProgramEvents,
  useFetchRXEvents,
  useFetchShopEvents,
} from 'loco-history/api/hooks';
import { EventsGrid } from 'loco-history/cards/Timeline/components/events/grids/EventsGrid';
import { createEventTimeColDef } from 'loco-history/cards/Timeline/components/events/utils';
import { TimelineEventTypeChipCell } from 'loco-history/components/TimelineEventTypeChip';
import { TimelineEventToPropsMap } from 'loco-history/config';
import {
  AnyTimelineEvent,
  CallEvent,
  DefectEvent,
  FaultEvent,
  IncidentEvent,
  MaterialUsageEvent,
  ProgramEvent,
  RXEvent,
  ShopEvent, // GenericEvent
} from 'loco-history/types';

/**
 * @returns {JSX.Element} events grid representing the superset of all loco history events
 */
export const AllEvents = () => {
  const dispatch = useAppDispatch();
  const selectedEventTypes = useAppSelector(selectSelectedTimelineEventTypes);
  const mode = useAppSelector(selectTimelineMode);

  const { materialUsage, materialUsageStatus } = useFetchMaterialUsage();
  const { shopEvents, shopEventsStatus } = useFetchShopEvents();
  const { faultEvents, faultStatus } = useFetchFaultEvents();
  const { incidentEvents, incidentStatus } = useFetchIncidentEvents();
  const { rxEvents, rxStatus } = useFetchRXEvents();
  const { callEvents, callStatus } = useFetchCallEvents();
  const { defectEvents, defectStatus } = useFetchDefectEvents();
  const { programEvents, programStatus } = useFetchProgramEvents();

  const [api, setApi] = useState<GridApi>();
  const [filterOnlySelected, setFilterOnlySelected] = useState<boolean>(false);

  const gridRef = useRef(api);

  const columns = useMemo((): ColDef[] => {
    /**
     *
     * @param {GetQuickFilterTextParams<AnyTimelineEvent, any>} params ag-grid value getter parameters
     * @returns {string} description value for given timeline event
     */
    const descriptionValueGetter = (
      params: ValueGetterParams<AnyTimelineEvent>
    ): string | undefined => {
      if (params.data?.type) {
        switch (params.data.type) {
          case 'call':
            return (params.data as CallEvent).callLogId;

          // case 'case':
          //   return (params.data as CaseEvent).caseId;

          case 'defect':
            return (params.data as DefectEvent).woNumber;

          case 'fault':
            return (params.data as FaultEvent).faultCode;

          case 'incident':
            return (params.data as IncidentEvent).incidentCodeDescription;

          case 'material':
            return (params.data as MaterialUsageEvent).description;

          case 'rx':
            return (params.data as RXEvent).rxCaseId;

          case 'shop':
            return (params.data as ShopEvent).workorder;

          case 'program':
            return (params.data as ProgramEvent).program;

          default:
            undefined;
        }
      } else {
        return '';
      }
    };

    /**
     *
     * @param {GetQuickFilterTextParams<AnyTimelineEvent, any>} params ag-grid value getter parameters
     * @returns {string} details value for given timeline event
     */
    const detailsValueGetter = (
      params: ValueGetterParams<AnyTimelineEvent>
    ): string | undefined => {
      if (params.data?.type) {
        switch (params.data.type) {
          case 'call':
            return (params.data as CallEvent).notesDesc;

          // case 'case':
          //   return (params.data as CaseEvent).caseTitle;

          case 'defect':
            return (params.data as DefectEvent).defectComments;

          case 'fault':
            return (params.data as FaultEvent).faultDesc;

          case 'incident':
            return (params.data as IncidentEvent).incidentOpenDesc;

          case 'material':
            return (params.data as MaterialUsageEvent).removalReason;

          case 'rx':
            return (params.data as RXEvent).title;

          case 'shop':
            return (params.data as ShopEvent).shop;

          case 'program':
            return (params.data as ProgramEvent).title;

          default:
            undefined;
        }
      } else {
        return '';
      }
    };

    /**
     *
     * @param {GetQuickFilterTextParams<AnyTimelineEvent, any>} params ag-grid value getter parameters
     * @returns {string} date value for given timeline event
     */
    const dateValueGetter = (
      params: ValueGetterParams<AnyTimelineEvent>
    ): string | undefined => {
      if (params.data?.type) {
        switch (params.data.type) {
          case 'call':
            return (params.data as CallEvent).creationDate;

          // case 'case':
          //   return (params.data as CaseEvent).creationDate;

          case 'defect':
            return (params.data as DefectEvent).creationDate;

          case 'fault':
            return (params.data as FaultEvent).occurDate;

          case 'incident':
            return (params.data as IncidentEvent).incidentDate;

          case 'material':
            return (params.data as MaterialUsageEvent).usageDate;

          case 'rx':
            return (params.data as RXEvent).rxOpenDate;

          case 'shop':
            return (params.data as ShopEvent).inshopDate;

          case 'program':
            return (params.data as ProgramEvent).completionDate;

          default:
            undefined;
        }
      } else {
        return '';
      }
    };

    if (mode === 'build') {
      return [
        {
          field: 'type',
          headerName: 'Type',
          cellRenderer: 'agGroupCellRenderer',
          checkboxSelection: true,
          headerCheckboxSelection: true,
          width: 145,
          cellRendererParams: {
            innerRenderer: TimelineEventTypeChipCell,
          },
        },
        {
          field: 'description',
          headerName: 'Event',
          width: 250,
          valueGetter: descriptionValueGetter,
          suppressSizeToFit: false,
        },
        {
          field: 'timestamp',
          hide: true,
          sort: 'desc',
        },
      ];
    }

    return [
      {
        field: 'type',
        headerName: 'Type',
        cellRenderer: 'agGroupCellRenderer',
        checkboxSelection: true,
        headerCheckboxSelection: true,
        width: 145,
        cellRendererParams: {
          innerRenderer: TimelineEventTypeChipCell,
        },
      },
      {
        field: 'description',
        headerName: 'Event',
        width: 250,
        valueGetter: descriptionValueGetter,
      },
      {
        field: 'details',
        headerName: 'Description',
        suppressSizeToFit: false,
        valueGetter: detailsValueGetter,
      },
      {
        ...createEventTimeColDef({
          field: 'date',
          headerName: 'Date',
          sort: true,
        }),
        valueGetter: dateValueGetter,
      },
    ];
  }, [mode]);

  const onGridReady = useCallback((event: GridReadyEvent<AnyTimelineEvent>) => {
    setApi(event.api);
  }, []);

  useEffect(() => {
    gridRef.current = api;
  }, [api]);

  // when our checkboxes change, we also want the filters to update
  useEffect(() => {
    gridRef.current?.onFilterChanged();
  }, [selectedEventTypes, filterOnlySelected]);

  // external filter logic
  const doesExternalFilterPass = useCallback(
    (row: RowNode<AnyTimelineEvent>): boolean => {
      if (mode === 'build') {
        return row.isSelected() ?? false;
      }
      // checkboxes
      if (row.data?.type) {
        return selectedEventTypes.includes(row.data.type);
      }

      return true;
    },
    [selectedEventTypes, mode]
  );

  const onExternalFilterChanged = useCallback(
    (type: TimelineEventType, event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        dispatch(addSelectedTimelineEventType(type));
      } else {
        dispatch(removeSelectedTimelineEventType(type));
      }
    },
    [dispatch]
  );

  const onFilterSelected = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFilterOnlySelected(event.target.checked);
    },
    []
  );

  const getEventFetchStatus = useCallback(
    (type: TimelineEventType) => {
      switch (type) {
        case 'call':
          return callStatus;

        case 'defect':
          return defectStatus;

        case 'fault':
          return faultStatus;

        case 'incident':
          return incidentStatus;

        case 'material':
          return materialUsageStatus;

        case 'rx':
          return rxStatus;

        case 'shop':
          return shopEventsStatus;

        case 'program':
          return programStatus;

        default:
          undefined;
      }
    },
    [
      callStatus,
      defectStatus,
      faultStatus,
      incidentStatus,
      materialUsageStatus,
      rxStatus,
      shopEventsStatus,
      programStatus,
    ]
  );

  const selectionComponent = useMemo((): JSX.Element => {
    return (
      <Fade in={mode === 'research'}>
        <FormControl sx={{ flexDirection: 'row' }}>
          {Object.entries(TimelineEventToPropsMap)
            .filter(([, props]) => props.hideFromGrid !== true)
            .map(([t, props]: [string, EventDisplayProps]) => {
              const type = t as TimelineEventType;
              const status = getEventFetchStatus(type);

              return (
                <FormControlLabel
                  disabled={status === 'loading'}
                  key={type}
                  label={props.label}
                  sx={{ color: 'white' }}
                  control={
                    status !== 'loading' ? (
                      <Checkbox
                        disabled={filterOnlySelected}
                        onChange={(event) =>
                          onExternalFilterChanged(type, event)
                        }
                        checked={selectedEventTypes.includes(type)}
                        size='small'
                        sx={{
                          color: props.style.backgroundColor,
                          '&.Mui-checked': {
                            color: chroma(
                              props.style.backgroundColor as string
                            ).hex(),
                          },
                          '&.Mui-disabled': {
                            color: chroma(PowerToolDarkTheme.statusGray400)
                              .alpha(0.5)
                              .hex(),
                          },
                        }}
                      />
                    ) : (
                      <Box sx={{ display: 'flex', mr: 0.5 }}>
                        <CircularProgress
                          size={'1rem'}
                          style={{
                            color: props.style.backgroundColor,
                          }}
                        />
                      </Box>
                    )
                  }
                />
              );
            })}
        </FormControl>
      </Fade>
    );
  }, [
    filterOnlySelected,
    selectedEventTypes,
    mode,
    onExternalFilterChanged,
    getEventFetchStatus,
  ]);

  const data = useMemo(() => {
    const allEvents: AnyTimelineEvent[] = [
      ...(materialUsage ?? []),
      ...(shopEvents ?? []),
      ...(faultEvents ?? []),
      ...(incidentEvents ?? []),
      ...(rxEvents ?? []),
      ...(callEvents ?? []),
      ...(defectEvents ?? []),
      ...(programEvents ?? []),
    ];

    return allEvents;
  }, [
    callEvents,
    defectEvents,
    faultEvents,
    incidentEvents,
    materialUsage,
    programEvents,
    rxEvents,
    shopEvents,
  ]);

  return (
    <EventsGrid<AnyTimelineEvent>
      data={data}
      columns={columns}
      headerComponent={selectionComponent}
      onFilterSelected={onFilterSelected}
      onGridReady={onGridReady}
      doesExternalFilterPass={doesExternalFilterPass}
    />
  );
};
