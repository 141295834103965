import { Circle, Help } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import chroma from 'chroma-js';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

import { ActiveAxle } from 'train-tool/types';

export type ActiveAxleProps = {
  axleConfig?: ('1' | '0')[] | undefined;
  activeAxles?: ActiveAxle[];
};

/**
 *
 * @param {any} props implicit destructured props
 * @param {'1' | '0'} props.axleConfig if the axle is enabled or disabled
 * @param {ActiveAxle?} props.axleStatus if the axle is online or offline
 * @returns {JSX.Element} returns a green circle if the axle is online, red if offline, and gray if disabled/not applicable
 */
const ActiveAxleIcon = ({
  axleConfig,
  axleStatus,
}: {
  axleConfig: '1' | '0';
  axleStatus?: ActiveAxle;
}) => {
  /**
   *
   * @param {'1' | '0'} availableAxle if the axle is applicable
   * @param {ActiveAxle?} activeAxle if the axle is online
   * @returns {string} color representing the axle status
   */
  const getColor = (availableAxle: '1' | '0', activeAxle?: ActiveAxle) => {
    // if the given axle is not part of the config (i.e. 4 axle loco)
    // then we make it gray regardless of the axle status
    if (availableAxle === '0' && activeAxle) {
      return PowerToolDarkTheme.statusGray500;
    }

    // if the given axle is not defined, we'll hide it
    else if (activeAxle === undefined) {
      return 'transparent';
    }

    // otherwise, if the axle is online we turn it green.
    // otherwise, it's offline and red
    return activeAxle.status === 'online'
      ? PowerToolDarkTheme.statusGreen500
      : PowerToolDarkTheme.statusRed500;
  };

  // axle status is unknown, we return a ? when a tooltip
  if (axleStatus?.status === 'unknown') {
    return (
      <Tooltip title={axleStatus.tooltip ?? ''}>
        <Help
          sx={{
            fontSize: '13px',
            color: chroma(PowerToolDarkTheme.statusGray500).alpha(0.4).hex(),
          }}
        />
      </Tooltip>
    );
  } else {
    return (
      <Circle
        sx={{
          fontSize: '10px',
          color: getColor(axleConfig, axleStatus),
        }}
      />
    );
  }
};

/**
 *
 * @param {ActiveAxleProps} props display properties for a given locomotive
 * @returns {JSX.Element} display component of up to 6 axles representing if they are online, offline, not available, or unknown
 */
export const ActiveAxlesComponent = (props: ActiveAxleProps) => {
  if (props.axleConfig) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: 5,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {props.axleConfig.map((_, index) => (
          <ActiveAxleIcon
            key={index}
            axleConfig={props.axleConfig ? props.axleConfig[index] : '1'}
            axleStatus={
              props.activeAxles ? props.activeAxles[index] : undefined
            }
          />
        ))}
      </div>
    );
  }

  return <Circle sx={{ fontSize: '10px', color: 'transparent' }} />;
};
