import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type IngestedDataAction = {
  url: string;
  hardFetch?: boolean;
};

export const slice = createSlice({
  name: 'ingestedDataActions',
  initialState: {},
  reducers: {
    fetchIngestedDataAction: (
      _state,
      _action: PayloadAction<IngestedDataAction>
    ) => {},
  },
});

export const { fetchIngestedDataAction } = slice.actions;
export default slice.reducer;
