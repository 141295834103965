import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import {
  getDatascreenProperties,
  getServiceSelectionOptions,
} from 'common/features/ingested-data/api';
import { useAppSelector } from 'common/hooks';
import { selectUser } from 'common/stores/globalSlice';
import { getApplicationRuntime } from 'common/util/utils';

export const useFetchDatascreenServices = () => {
  const user = useAppSelector(selectUser);

  const enabled = useMemo(() => {
    return user?.currRole !== undefined || getApplicationRuntime() === 'local';
  }, [user?.currRole]);

  const hook = useQuery(
    ['datascreen_services', { user }],
    () => getServiceSelectionOptions(user?.currRole ?? 'Power Tool'),
    { enabled }
  );

  return {
    services: hook.data,
    servicesStatus: hook.status,
    ...hook,
  };
};

export const useFetchDatascreenProperties = () => {
  const user = useAppSelector(selectUser);

  const enabled = useMemo(() => {
    return user?.currRole !== undefined;
  }, [user?.currRole]);

  const hook = useQuery(
    ['datascreen_properties', { user }],
    () => getDatascreenProperties(user?.currRole ?? 'Power Tool'),
    { enabled }
  );

  return {
    properties: hook.data,
    propertiesStatus: hook.status,
    ...hook,
  };
};
