import axios from 'axios';

import {
  DatapackColumns,
  Fault,
  FaultToDatapackSize,
} from 'common/features/datapack/types';

/**
 * retrive faults for a given vehicle that contain datapacks and their size
 * uses dateRange, from, to just like ingested data
 *
 * @param props object
 * @param props.vehicleObjid given vehicle
 * @param props.dateRange lookback
 * @param props.from range start
 * @param props.to range end
 * @returns {Promise<FaultToDatapackSize[]>} promise of an object array containing faults and their respective datapack size
 */
export const getDataPackCountForVehicle = (props: {
  vehicleObjid: number;
  dateRange?: string;
  from?: string;
  to?: string;
}): Promise<FaultToDatapackSize[]> => {
  let url = `datapacks?vehicleObjid=${props.vehicleObjid}`;
  if (props.dateRange !== undefined) {
    url += `&daysLookBack=${props.dateRange}`;
  }
  if (props.from !== undefined && props.to !== undefined) {
    url += `&from=${props.from}&to=${props.to}`;
  }

  return axios
    .get<FaultToDatapackSize[]>(url)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log('error calling query endpoint for datapack');
      throw e;
    });
};

export const getDatapackData = (
  asset: string,
  fault: string,
  template: string
) => {
  return axios
    .get<{ columns: DatapackColumns[]; data: any[] }>(
      `datapack?vehicleObjid=${asset}&faultObjid=${fault}&template=${template}`
    )
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log('error calling query endpoint for datapack');
      throw e;
    });
};

export const getFaultById = (faultObjid: string | number) => {
  return axios
    .post<Fault[]>('/query/fault_by_id', {
      parameters: {
        1: faultObjid,
      },
    })
    .then((response) => response.data.find((x) => x));
};
