import {
  RumActionEventDomainContext,
  RumErrorEventDomainContext,
  RumEvent,
  RumFetchResourceEventDomainContext,
  RumLongTaskEventDomainContext,
  RumOtherResourceEventDomainContext,
  RumViewEventDomainContext,
  RumXhrResourceEventDomainContext,
} from '@datadog/browser-rum';

import { store } from 'common/store';

/**
 * we customize the metrics that we send to datadog through this function
 *
 * @param {RumEvent} event datadog event being recorded (resource, view, session, etc.)
 * @param {any} context contains the actual data around the event (response, headers, status, etc..)
 */
export const beforeSend = (
  event: RumEvent,
  context:
    | RumViewEventDomainContext
    | RumActionEventDomainContext
    | RumFetchResourceEventDomainContext
    | RumXhrResourceEventDomainContext
    | RumOtherResourceEventDomainContext
    | RumErrorEventDomainContext
    | RumLongTaskEventDomainContext
) => {
  const globalState = store.getState().global;
  const user = `${globalState.user?.firstName ?? ''} ${
    globalState.user?.lastName ?? ''
  }`;

  event.context = {
    ...event.context,
    customer: globalState.customer,
    user: user === '' || undefined ? 'anonymous' : user,
    applicationContext: globalState.applicationContext ?? 'unknown',
    role: globalState.user?.currRole,
  };

  if (event.type === 'resource') {
    if (event.resource.type === 'xhr') {
      event.context = {
        ...event.context,
        response: event.resource.url.includes('ingested-data')
          ? ''
          : (context as RumXhrResourceEventDomainContext).xhr.response,
        responseHeaders: (
          context as RumXhrResourceEventDomainContext
        ).xhr.getAllResponseHeaders(),
      };
    } else if (event.resource.type === 'fetch') {
      event.context = {
        ...event.context,
        response: event.resource.url.includes('ingested-data')
          ? ''
          : (context as RumFetchResourceEventDomainContext).response?.body,
        responseHeaders: (context as RumFetchResourceEventDomainContext)
          .response?.headers,
      };
    }
  }
};
