import { ColDef } from 'ag-grid-community';
import { useMemo } from 'react';

import { useFetchMaterialUsage } from 'loco-history/api/hooks';
import { createEventTimeColDef } from 'loco-history/cards/Timeline/components/events/utils';
import { MaterialUsageEvent } from 'loco-history/types';

import { EventsGrid } from './EventsGrid';

/**
 * @returns {JSX.Element} ag-grid wrapper for locomotive material usage events
 */
export const MaterialUsageEvents = () => {
  const { materialUsage } = useFetchMaterialUsage();

  const columns = useMemo((): ColDef[] => {
    return [
      {
        field: 'workorderNumber',
        headerName: 'Workorder',
        cellRenderer: 'agGroupCellRenderer',
        headerCheckboxSelection: true,
        checkboxSelection: true,
        width: 190,
      },
      {
        field: 'partNo',
        headerName: 'Part No.',
        width: 140,
      },
      {
        field: 'description',
        headerName: 'Part Description',
        suppressSizeToFit: false,
      },
      {
        field: 'removalReason',
        headerName: 'Removal Reason',
        width: 150,
      },
      {
        field: 'quantity',
        headerName: 'Quantity',
        width: 100,
      },
      {
        field: 'positionApplied',
        headerName: 'Position',
        width: 140,
      },
      createEventTimeColDef({
        field: 'usageDate',
        headerName: 'Usage Date',
        sort: true,
      }),
    ];
  }, []);

  return (
    <EventsGrid<MaterialUsageEvent> columns={columns} data={materialUsage} />
  );
};
