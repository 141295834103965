import { ICellRendererParams } from 'ag-grid-community';

import { StripHTMLWrapper } from 'common/components/StripHTMLWrapper';

/**
 *
 * @param {ICellRendererParams} props ag-grid cell renderer props
 * @returns {JSX.Element} generic ag-grid cell renderer to strip HTML markup from a cell
 */
export const StrippedHTMLCellRenderer = (props: ICellRendererParams) => {
  return <StripHTMLWrapper data={props.value as string} />;
};
