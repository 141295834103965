import { PayloadAction } from '@reduxjs/toolkit';
import { all, put, select, call, delay } from 'redux-saga/effects';
import { v4 as uuid } from 'uuid';

import { selectAssetDetails } from 'common/stores/globalSlice';
import { setHardFetchNewData } from 'common/stores/ingestedDataFooterSlice';
// import { setShouldFetchNewData } from 'common/stores/ingestedDataFooterSlice';
import { PowerToolError } from 'common/types/error';
import { User, Asset } from 'common/types/types';
import { getOMDHost } from 'common/util/utils';

import { getToolOutputForCase, getCases } from 'power-tool/api';
import {
  setErrorModalOpen,
  setError,
  setToolOutputVerification,
  setToolOutputDropdowns,
  setVizxAttachments,
  setIsToolDispoLoading,
  setIsOMDServicesLoaded,
  resetForm,
  setToolDispoProcessingState,
  setCurrentCaseOwner,
  setInWorkQueue,
  getCaseActionState,
  setSearchItem,
  updateItem,
} from 'power-tool/stores/caseActionSlice';
import {
  selectCase, // , setCases
} from 'power-tool/stores/casesSlice';
import { setToolOutputData } from 'power-tool/stores/toolOutputSlice';
import {
  getCurrentCaseOwner,
  getToolOutputDropdowns,
  getVizxAttachments,
  verifyToolOutput,
} from 'power-tool/tool-dispo/api';
import {
  ToolOutputDropdowns,
  CaseActionState,
  ToolOutputItemState,
  ToolOutputVerification,
  DeliveryAttachmentProps,
  VizxAttachments,
} from 'power-tool/tool-dispo/types';
import { CaseDetails, ToolOutputData } from 'power-tool/types';

export function* caseActionErrorMessageHandler(
  action: PayloadAction<PowerToolError>
) {
  console.log(`error: ${action.payload.traceId}`);

  yield all([put(setErrorModalOpen(true)), put(setError(action.payload))]);
}

export function* toolDispoReloadHandler() {
  // we (unfortunately) need to give time for OMD's APIs to write to the DB
  yield delay(3000);

  // when tool dispo is submitted, first get the asset and refresh cases
  const selectedCase = yield select(selectCase);
  const asset = yield select(selectAssetDetails);

  // then refresh tool output, and fetch new ingested data, reset the form
  const [toolOutput, cases, caseOwner] = yield all([
    call(getToolOutputForCase, selectedCase.caseId),
    call(getCases, asset.vehicleObjid),
    call(getCurrentCaseOwner, selectedCase.caseId),
    put(setHardFetchNewData(true)),
  ]);

  yield resetToolOutputForm(toolOutput);

  yield all([
    // put(setCases(cases)),
    put(setToolOutputData(toolOutput)),

    // 'ownership' is defined by owning the case AND
    //   having the case in your work queue
    put(setCurrentCaseOwner(caseOwner.LOGIN_NAME)),
    put(setInWorkQueue(caseOwner.IN_WORK_QUEUE)),
  ]);

  // finally refresh OMD
  if (window.opener) {
    const postmessageHost = getOMDHost();

    window.opener.postMessage(
      {
        type: 'powertool-refreshAsset',
        assetDetail:
          asset.customerOrgId + '-' + asset.vehicleHdr + '-' + asset.roadNumber,
      },
      postmessageHost
    );
  }

  // and we are done
  yield put(setToolDispoProcessingState('ready'));
}

export function* openToolDispoActionHandler(action: PayloadAction<User>) {
  yield all([
    put(setIsToolDispoLoading(true)),
    put(setIsOMDServicesLoaded('init')),
  ]);

  let vizxAttachments: DeliveryAttachmentProps[] = [];
  const user = action.payload;
  const [vehicle, selectedCase]: [Asset, CaseDetails] = yield all([
    select(selectAssetDetails),
    select(selectCase),
  ]);

  try {
    // go get everything we need for the modal
    const [verification, dropdowns, attachments]: [
      ToolOutputVerification,
      ToolOutputDropdowns,
      VizxAttachments[]
    ] = yield all([
      call(
        verifyToolOutput,
        vehicle.vehicleObjid,
        selectedCase.caseId,
        user.userName
      ),
      call(getToolOutputDropdowns, vehicle.vehicleObjid, selectedCase.caseId),
      call(getVizxAttachments, vehicle),
    ]);

    vizxAttachments = attachments.map((doc) => ({
      src: 'data:image/png;base64,' + doc.docData,
      label: doc.docTitle,
      type: 'vizxPlot',
      isAttached: false,
      id: doc.docTitle,
    }));

    // store it into redux
    yield all([
      put(setToolOutputVerification(verification)),
      put(setToolOutputDropdowns(dropdowns)),
      put(setVizxAttachments(vizxAttachments)),
    ]);

    // and finally "complete" the connection
    yield put(setIsOMDServicesLoaded('complete'));
  } catch (e) {
    console.log('error calling (proxy) OMD services', e);
    yield all([
      // put(setCaseActionDisabled(true)),
      // put(setCaseActionDisableReason(`Error contacting OMD services.`)),
      put(setIsOMDServicesLoaded('error')),
    ]);
  }
}

export function* resetToolOutputForm(toolOutput: ToolOutputData[]) {
  // we reset the form whenever tooloutput is set
  yield put(
    resetForm(
      toolOutput.map((item) => {
        return {
          id: uuid(),
          isExpanded: false,
          availableAttachments: [],
          deliveryTarget: 'new',
          ...item,
          itemType: 'ToolOutput',
        };
      })
    )
  );
}

export function* addRxActionHandler(action: PayloadAction<ToolOutputData>) {
  const currentState: CaseActionState = yield select(getCaseActionState);
  const allItems = currentState.items;

  // the added rx is expanded and selected for current delivery by default
  const addedRx: ToolOutputItemState = {
    ...action.payload,
    itemType: 'Search',
    id: uuid(),
    isExpanded: true,
    availableAttachments: [],
    deliveryTarget: 'current',
    ruleId: action.payload.rxId,
    selectedAction: 'deliver',
  };

  // we need to flip all the current delivery targets to new
  // because the added RX always defects to current
  const otherItems: ToolOutputItemState[] =
    allItems?.map((item) => ({ ...item, deliveryTarget: 'new' })) ?? [];

  // dispatch all at once
  yield all([
    put(setSearchItem(addedRx)),
    ...otherItems.map((item) => put(updateItem(item))),
  ]);
}
