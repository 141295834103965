import { CustomerIncident } from 'train-tool/types';

/**
 *
 * @param {any} props implicit destructured props
 * @param {CustomerIncident[]} props.defects  list of customer defects to display titles for
 * @returns {JSX.Element} title for customer defects. if there are more than three defects, we will display a truncated version
 */
export const IncidentTitleComponent = ({
  incidents,
}: {
  incidents?: CustomerIncident[];
}) => {
  let title: string;

  if (incidents === undefined || incidents.length === 0) {
    return <></>;
  }

  if (incidents.length > 3) {
    title =
      incidents
        .slice(0, 3)
        .map((incident: CustomerIncident) => incident.custIncidentType)
        .join(', ') + ` and ${incidents.length - 3} more`;
  } else {
    title = incidents.map((incident) => incident.custIncidentType).join(', ');
  }

  return <span>{title}</span>;
};
