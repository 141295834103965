import { Check } from '@mui/icons-material';
import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemProps,
  ListItemText,
  Typography,
  Zoom,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

import {
  ListComponentProps,
  ListItemComponentProps,
} from 'power-tool/tool-dispo/types';

export const ListComponent = (
  props: ListComponentProps<typeof ListComponent>
) => {
  const [target, setTarget] = useState<string>();

  return (
    <nav
      style={{
        width: '100%',
      }}
    >
      <Typography variant='subtitle2'>{props.label}</Typography>
      <List
        dense
        disablePadding
        component='div'
        sx={{
          maxHeight: 175,
          overflow: 'auto',
          ml: 1,
          mt: 1,
          width: '100%',
          borderRadius: '3px',
          backgroundColor: PowerToolDarkTheme.main,
        }}
        {...props.listProps}
      >
        {props.items.map((item) => (
          <ListItemComponent
            key={item.value}
            selected={target ? target === item.id : item.selected === true}
            value={item.value}
            description={item.description}
            prefixComponent={item.prefixComponent}
            onClick={
              item.value === 'disabled'
                ? () => {}
                : () => {
                    setTarget(item.id);
                    if (props.handleSelect) {
                      props.handleSelect(item);
                    }
                  }
            }
          />
        ))}
        <Divider />
      </List>
    </nav>
  );
};

const ListItemComponent = <C extends React.ElementType>(
  props: ListItemProps<C, { component?: C }> & ListItemComponentProps
) => {
  const [selected, setSelected] = useState<boolean | undefined>(props.selected);

  useEffect(() => {
    setSelected(props.selected);
  }, [props.selected]);

  // todo: make this a common component
  if (selected) {
    return (
      <React.Fragment>
        <Divider />
        <ListItem key={props.value} disablePadding {...props}>
          <ListItemButton
            disabled={props.value === 'disabled'}
            onClick={props.value !== 'disabled' ? props.onClick : () => {}}
            sx={{
              backgroundColor: 'rgba(160, 132, 132, 0.185)',
            }}
          >
            <ListItemIcon>
              <Zoom in={true}>
                <Check color='success' />
              </Zoom>
            </ListItemIcon>
            <ListItemText>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '10px',
                }}
              >
                {props.prefixComponent}
                {props.description}
              </div>
            </ListItemText>
          </ListItemButton>
        </ListItem>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Divider />
      <ListItem key={props.value} disablePadding {...props}>
        <ListItemButton
          disabled={props.value === 'disabled'}
          onClick={props.value !== 'disabled' ? props.onClick : () => {}}
        >
          <ListItemText inset>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '10px',
              }}
            >
              {props.prefixComponent}
              {props.description}
            </div>
          </ListItemText>
        </ListItemButton>
      </ListItem>
    </React.Fragment>
  );
};
