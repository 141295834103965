import { Typography } from '@mui/material';
import { Stack } from '@mui/system';

export const UnderConstruction = () => {
  return (
    <Stack direction={'row'} spacing={2}>
      <Typography>🚧 Under construction 🚧</Typography>
    </Stack>
  );
};
