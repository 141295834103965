import CryptoJS from 'crypto-js';

export class SecurityUtilityService {
  getPrivateKey() {
    return CryptoJS.enc.Utf8.parse(
      process.env.REACT_APP_DECRYPT_PRIVATE_KEY
        ? process.env.REACT_APP_DECRYPT_PRIVATE_KEY
        : ''
    );
  }

  encrypt(value: string): string {
    return CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(value),
      this.getPrivateKey(),
      {
        keySize: 128 / 8,
        iv: this.getPrivateKey(),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    )
      .toString()
      .replace(/\//g, '****');
  }

  decrypt(textToDecrypt: string) {
    textToDecrypt = textToDecrypt.replace(/\*\*\*\*/g, '/');
    return CryptoJS.AES.decrypt(textToDecrypt, this.getPrivateKey(), {
      keySize: 128 / 8,
      iv: this.getPrivateKey(),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }).toString(CryptoJS.enc.Utf8);
  }
}
