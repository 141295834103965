import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CellRangeParams } from 'ag-grid-community';

import { RootState } from 'common/store';

import { RxPlot } from 'power-tool/cards/Plot/types';

export type PlotSlice = {
  chartModels: string[];
  selectedPlotIndex: number;
  plottedCellRange?: CellRangeParams;
  rxPlots: RxPlot[];
  anomalyPlot: string;
  isUserPlotFocused: boolean;
  hasRxPlots?: boolean;
};

const initState: PlotSlice = {
  selectedPlotIndex: 0,
  chartModels: [],
  rxPlots: [],
  anomalyPlot: '',
  isUserPlotFocused: false,
};

export const plotSlice = createSlice({
  name: 'plot',
  initialState: initState,
  reducers: {
    changePlot: (state, action: PayloadAction<number>) => {
      state.selectedPlotIndex = action.payload;
    },
    addChartModel: (state, action: PayloadAction<string>) => {
      state.chartModels = [...state.chartModels, action.payload];
    },
    setChartModels: (state, action: PayloadAction<string[]>) => {
      state.chartModels = action.payload;
    },
    setPlottedCellRange: (
      state,
      action: PayloadAction<CellRangeParams | undefined>
    ) => {
      state.plottedCellRange = action.payload;
    },
    setRxPlots: (state, action: PayloadAction<RxPlot[]>) => {
      state.rxPlots = action.payload;
      state.hasRxPlots = action.payload.length > 0;
    },
    setIsUserPlotFocused: (state, action: PayloadAction<boolean>) => {
      state.isUserPlotFocused = action.payload;
    },
  },
});

export const {
  changePlot,
  addChartModel,
  setChartModels,
  setPlottedCellRange,
  setRxPlots,
  setIsUserPlotFocused,
} = plotSlice.actions;
export const getSelectedPlot = (state: RootState) =>
  state.plot.selectedPlotIndex;
export const getChartModels = (state: RootState) => state.plot.chartModels;
export const getPlottedCellRange = (state: RootState) =>
  state.plot.plottedCellRange;
export const getRxPlots = (state: RootState) => state.plot.rxPlots;
export const getIsUserPlotFocused = (state: RootState) =>
  state.plot.isUserPlotFocused;
export const getHasRxPlots = (state: RootState) => state.plot.hasRxPlots;
export default plotSlice.reducer;
