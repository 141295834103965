import { Add, Remove } from '@mui/icons-material';
import { Stack, SvgIcon, Typography } from '@mui/material';
import { IHeaderGroupParams } from 'ag-grid-community';
import { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

const StyledHoverIcon = styled(SvgIcon)`
  :hover {
    color: ${PowerToolDarkTheme.voltBlue300};
    cursor: pointer;
  }
`;

// https://www.ag-grid.com/react-data-grid/component-header/#example-header-group-cells
export const DataGridHeaderGroup = (props: IHeaderGroupParams) => {
  const [expandState, setExpandState] = useState<'expanded' | 'collapsed'>(
    'collapsed'
  );

  const expandOrCollapse = () => {
    let currentState = props.columnGroup.getProvidedColumnGroup().isExpanded();
    props.setExpanded(!currentState);
  };

  const syncExpandButtons = () => {
    setExpandState(
      props.columnGroup.getProvidedColumnGroup().isExpanded()
        ? 'expanded'
        : 'collapsed'
    );
  };

  useEffect(() => {
    props.columnGroup
      .getProvidedColumnGroup()
      .addEventListener('expandedChanged', syncExpandButtons);
    syncExpandButtons();

    return () => {
      props.columnGroup
        .getProvidedColumnGroup()
        .removeEventListener('expandedChanged', syncExpandButtons);
    };
  }, []);

  const ExpandOrCollapseIcon = useCallback(
    ({ children }) => {
      return (
        <StyledHoverIcon onClick={expandOrCollapse}>{children}</StyledHoverIcon>
      );
    },
    [expandOrCollapse]
  );

  return (
    <Stack direction={'row'}>
      <Typography variant='button'>{props.displayName}</Typography>
      {props.columnGroup.isExpandable() ? (
        expandState === 'collapsed' ? (
          <ExpandOrCollapseIcon>
            <Add />
          </ExpandOrCollapseIcon>
        ) : (
          <ExpandOrCollapseIcon>
            <Remove />
          </ExpandOrCollapseIcon>
        )
      ) : (
        <></>
      )}
    </Stack>
  );
};
