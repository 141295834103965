import { AddLocationAltOutlined, QueryStats } from '@mui/icons-material';
import {
  CellClassParams,
  CellClickedEvent,
  CellStyle,
  ColumnEverythingChangedEvent,
  FilterChangedEvent,
  GetContextMenuItems,
  GetContextMenuItemsParams,
  GetMainMenuItemsParams,
  ICellRendererParams,
  ProcessCellForExportParams,
  RowClassParams,
  RowNode,
  RowStyle,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import chroma from 'chroma-js';
import { useCallback, useEffect, useRef } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { useStore } from 'react-redux';

import { controllerZonedTimeColumns } from 'common/cards/Data/const';
import {
  useDropPinsAndFetchWeather,
  useIsValidGPSLocation,
  useIsValidOutbound,
  useProcessOutbound,
} from 'common/cards/Data/hooks';
import {
  selectFiredFaults,
  selectOutboundResults,
  setSelectedFaultCodes,
} from 'common/cards/Data/store';
import { OutboundMacroResult } from 'common/cards/Data/types';
import {
  applyHCFilter,
  getSelectedFaultsOnGrid,
} from 'common/cards/Data/utils';
import { OutboundCell } from 'common/components/ag-grid/cell-renderers/OutboundCell';
import { useFetchDatapacksForFaults } from 'common/features/datapack/hooks';
import { useFetchIngestedFiredFaultData } from 'common/features/ingested-data/hooks';
import { IngestedData } from 'common/features/ingested-data/types';
import { useAppDispatch, useAppSelector } from 'common/hooks';
import { RootState } from 'common/store';
import {
  selectApplicationContext,
  selectAssetDetails,
} from 'common/stores/globalSlice';
import {
  selectQuickFilters, // selectQuickFilter,
  selectShowFiredFaults,
  setFilterActive,
  setRowCount,
} from 'common/stores/ingestedDataFooterSlice';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

import { useFetchFiredParameters } from 'power-tool/api/hooks';

// For more information on the design pattern for React Hooks, Redux, and ag-grid, see:
// https://ag-grid.zendesk.com/hc/en-us/articles/360018810938?input_string=stale+hook+state
// https://github.com/AhmedAGadir/ag-grid-react-stale-closure-fixes/blob/main/ag-grid-react-redux-stale-closure-usestore-fix/src/App.js
const useGridCallbacks = () => {
  const store = useStore<RootState>();

  const dispatch = useAppDispatch();

  // helper hooks
  const { isValidLocation } = useIsValidGPSLocation();
  const { isValidOutbound } = useIsValidOutbound();
  const { dropPinsAndFetchWeather } = useDropPinsAndFetchWeather();
  const { processOutbound } = useProcessOutbound();

  // query hooks + refs
  const { firedFaultData } = useFetchIngestedFiredFaultData();
  const firedFaultDataRef = useRef(firedFaultData);
  useEffect(() => {
    firedFaultDataRef.current = firedFaultData;
  }, [firedFaultData]);

  const { datapack2FaultMap } = useFetchDatapacksForFaults();
  const datapack2FaultRef = useRef(datapack2FaultMap);
  useEffect(() => {
    datapack2FaultRef.current = datapack2FaultMap;
  }, [datapack2FaultMap]);

  const { firedParameters } = useFetchFiredParameters();

  // redux state variables
  const application = useAppSelector(selectApplicationContext);
  const firedFaults = useAppSelector(selectFiredFaults);
  const firedFaultToggle = useAppSelector(selectShowFiredFaults);
  const quickFilters = useAppSelector(selectQuickFilters);
  const outboundResults = useAppSelector(selectOutboundResults);
  const asset = useAppSelector(selectAssetDetails);

  // ====== ag-grid callbacks =======
  const getRowStyle = useCallback(
    (params: RowClassParams<IngestedData>): RowStyle | undefined => {
      const firedFaults = store.getState().ingestedDataGrid.firedFaults;
      if (params.data) {
        if (firedFaults?.includes(params.data?.OBJID)) {
          return {
            background: PowerToolDarkTheme.grayHighlight,
            fontWeight:
              params.data.FAULT_CLASSIFICATION === 'Y' ? 'bold' : 'inherit',
          };
        }
      }

      // return;
    },
    [firedFaults]
  );

  const doesExternalFilterPass = useCallback(
    (params: RowNode<IngestedData>) => {
      const firedFaults = store.getState().ingestedDataGrid.firedFaults;
      const toggle = store.getState().ingestedDataFooter.showFiredFaults;
      const quickFilters = store
        .getState()
        .ingestedDataFooter.quickFilters.map((filter) => filter.type);

      if (toggle && params.data?.OBJID) {
        return firedFaults.includes(params.data.OBJID);
      }

      if (params.data?.inDayRange === false) {
        return false;
      }

      if (quickFilters && params.data) {
        const states: boolean[] = [];

        if (quickFilters.includes('steady_state')) {
          states.push(
            Math.abs(
              params.data['MP_1005_N_0_0'] - params.data['MP_1006_N_0_0']
            ) < 5
          );
        }

        if (quickFilters.includes('has_datapack')) {
          states.push(
            Array.from(datapack2FaultRef.current.keys()).includes(
              params.data.OBJID
            )
          );
        }

        return states.every((pass) => pass);
      }

      return true;
    },
    [
      firedFaults,
      firedFaultToggle,
      firedFaultData,
      quickFilters,
      datapack2FaultMap,
    ]
  );

  // define individual cell styles
  // IMPORTANT: order of return implies precedence of style applied
  const cellStyles = useCallback(
    (params: CellClassParams<IngestedData>): CellStyle | undefined => {
      const outboundResults = store.getState().ingestedDataGrid.outboundResults;

      if (params.value !== undefined) {
        switch (params.column.getColId()) {
          case 'PRECIPIN':
            return {
              backgroundColor: chroma
                .scale([
                  PowerToolDarkTheme.main,
                  PowerToolDarkTheme.precipGradientHi,
                ])
                .domain([0, 0.75])(parseInt(params.value))
                .hex(),
            };
          case 'TEMPF':
            return {
              backgroundColor: chroma
                .scale([
                  PowerToolDarkTheme.tempGradientLo,
                  PowerToolDarkTheme.main,
                  PowerToolDarkTheme.main,
                  PowerToolDarkTheme.tempGradientHi,
                ])
                .domain([-10, 45, 65, 100])(parseInt(params.value))
                .hex(),
            };
          case 'WINDSPEEDMPH':
            return {
              backgroundColor: chroma
                .scale([
                  PowerToolDarkTheme.main,
                  PowerToolDarkTheme.windGradientHi,
                ])
                .domain([0, 55])(parseInt(params.value))
                .hex(),
            };

          // active fault style
          // not sure case/switch right design pattern here?
          case 'FAULT_CODE':
          case 'SUB_ID':
          case 'FAULT_DESC':
            const active =
              params.data?.FAULT_RESET_DATE === undefined &&
              params.data?.RECORD_TYPE !== 'HC';

            return {
              color: active ? PowerToolDarkTheme.statusYellow300 : 'inherit',
              cursor:
                params.column.getColId() === 'FAULT_CODE'
                  ? 'pointer'
                  : 'default',
            };
        }

        // outbound result cell style processing
        if (params.data?.OBJID && outboundResults) {
          const objid = params.data.OBJID;

          // if theres results for this fault
          // and theres result for this fault and column
          if (
            outboundResults[objid] !== undefined &&
            outboundResults[objid][params.column.getColId()] !== undefined
          ) {
            const field: OutboundMacroResult =
              outboundResults[objid][params.column.getColId()];

            // get the colors the backend is sending us
            if (
              field.value !== null &&
              field.colors !== null &&
              field.colorDomain !== null
            ) {
              // generate a chroma scale and get the value on the scale
              const backgroundColor = chroma
                .scale(field.colors)
                .domain(field.colorDomain)(field.value)
                .hex();

              return { backgroundColor };
            }
          }
        }
      }

      // if these cells are part of virtual tester
      if (
        ['virtual results', 'focused_params'].includes(
          params.column
            .getParent()
            .getColGroupDef()
            ?.headerName?.toLowerCase() ?? ''
        )
      ) {
        // if its the result, make it a different color
        if (params.column.getColId() === 'VIRTUAL_RESULT') {
          return { backgroundColor: PowerToolDarkTheme.voltBlue700 };
        }

        // otherwise virtual tester columns have this color
        return { backgroundColor: PowerToolDarkTheme.voltBlue800 };
      }

      // fired parameter **column** background color
      if (
        params.data &&
        firedParameters?.includes(params.column.getColDef().headerName ?? '')
      ) {
        return {
          backgroundColor: PowerToolDarkTheme.grayHighlight,
        };
      }
    },
    [firedParameters, outboundResults]
  );

  // get the menu that appears when you right click on grid
  // slightly confusing logic because we enable multiple selection in the grid.
  // although this is called on a per-row basis, we need to check each time against
  // the other selected rows. this allows us to show the correct options
  // even if all of the selected rows do not meet the 'valid' conditions
  const getContextMenuItems = useCallback(
    (params: GetContextMenuItemsParams<IngestedData>) => {
      let showDropPin: boolean = false;
      let showOutbound: boolean = false;

      // check for range selections
      getSelectedFaultsOnGrid(params.api).forEach((fault) => {
        if (isValidLocation(fault)) {
          showDropPin = true;
        }

        if (isValidOutbound(fault)) {
          showOutbound = true;
        }
      });

      // ag-grid menu items are poorly typed - either undefined or string[]
      // but in reality it is an mixed array - strings and objects
      let menu = params.defaultItems as any[];

      if (
        showDropPin &&
        ['train_analysis', 'power_tool'].includes(application ?? '')
      ) {
        menu = [
          ...(menu ?? []),
          'separator',
          {
            name: 'Show on Map',
            tooltip: "Show fault's location on map and get weather.",
            action: () => {
              // fetch weather here
              dropPinsAndFetchWeather(params);
            },
            // icon needs regular HTML
            icon: renderToStaticMarkup(
              <AddLocationAltOutlined
                fontSize='small'
                style={{ fill: PowerToolDarkTheme.secondary }}
              />
            ),
          },
        ];
      }

      if (showOutbound && application === 'power_tool') {
        menu = [
          ...(menu ?? []),
          'separator',
          {
            name: 'Notch 8 Reference Check',
            tooltip: 'Process row and highlight results',
            icon: renderToStaticMarkup(
              <QueryStats
                fontSize='small'
                style={{ fill: PowerToolDarkTheme.secondary }}
              />
            ),
            action: () => {
              processOutbound(params);
            },
          },
        ];
      }

      return menu;
    },
    [
      application,
      isValidLocation,
      isValidOutbound,
      dropPinsAndFetchWeather,
      processOutbound,
    ]
  );

  const cellRendererSelector = useCallback(
    (params: ICellRendererParams<IngestedData>) => {
      const outboundResults = store.getState().ingestedDataGrid.outboundResults;
      const objid = params.data?.OBJID;
      const column = params.column?.getColId();

      if (outboundResults && column && objid) {
        if (
          outboundResults[objid] !== undefined &&
          outboundResults[objid][column]
        ) {
          const result: OutboundMacroResult = outboundResults[objid][column];
          return {
            component: OutboundCell,
            params: {
              outboundData: result,
              params,
            },
          };
        }
      }

      return undefined;
    },
    [outboundResults]
  );

  const getMainMenuItems = useCallback(
    (params: GetMainMenuItemsParams<IngestedData>) => {
      const sortAction = (sort: 'asc' | 'desc' | null | undefined) => {
        return params.columnApi.applyColumnState({
          state: [{ colId: params.column.getId(), sort }],
          defaultState: { sort: null },
        });
      };

      return [
        ...params.defaultItems,
        'separator',
        {
          name: 'Sort Ascending',
          checked: params.column.isSortAscending(),
          action: () => sortAction('asc'),
        },
        {
          name: 'Sort Descending',
          checked: params.column.isSortDescending(),
          action: () => sortAction('desc'),
        },
        {
          name: 'Disable Sort',
          checked: params.column.isSortNone(),
          action: () => sortAction(null),
        },
      ];
    },
    []
  );

  const onCellClicked = useCallback(
    (params: CellClickedEvent<IngestedData>) => {
      if (params.column.getColId() === 'FAULT_CODE') {
        const selectedCellValue = params.value;
        const uniqueData: string[] = [];
        uniqueData.push(selectedCellValue);
        dispatch(setSelectedFaultCodes(uniqueData));
      }
    },
    []
  );

  const getValueGetter = useCallback((column: string) => {
    // for any timestamp column, return a javascript Date() object
    if (controllerZonedTimeColumns.includes(column)) {
      return (params: ValueGetterParams<IngestedData>) => {
        if (params.data) {
          if (
            params.data[column] !== undefined &&
            params.data[column] !== null
          ) {
            return new Date(params.data[column]);
          }
        } else {
          return null;
        }
      };
    }

    // otherwise, we have no value getter
    return undefined;
  }, []);

  const getValueFormatter = useCallback(
    (column: string) => {
      // const

      if (controllerZonedTimeColumns.includes(column)) {
        return (params: ValueFormatterParams) => {
          if (params.data[column] !== undefined) {
            return (params.value as Date).toISOString();
          }
          return '';
        };
      }

      return undefined;
    },
    [asset]
  );

  const onColumnEverythingChanged = useCallback(
    (params: ColumnEverythingChangedEvent) => {
      // applyHCFilter(params.api);
    },
    []
  );

  const onFilterChanged = useCallback(
    (params: FilterChangedEvent<IngestedData>) => {
      dispatch(setRowCount(params.api.getDisplayedRowCount()));

      const activeFilters = Object.keys(params.api.getFilterModel()).filter(
        (column) => column !== 'RECORD_TYPE'
      );
      dispatch(setFilterActive(activeFilters.length > 0));
    },
    []
  );

  /**
   * @param {ProcessCellForExportParams} params parameters given to format a cell for excel
   */
  const processCellCallback = useCallback(
    (params: ProcessCellForExportParams) => {
      const colDef = params.column.getColDef();
      const valueFormatter = colDef.valueFormatter;
      //if there is value data and valueFormatter is not a string
      if (params.node && valueFormatter && typeof valueFormatter !== 'string') {
        const valueFormatterParams: ValueFormatterParams = {
          ...params,
          data: params.node.data,
          node: params.node,
          colDef: params.column.getColDef(),
        };
        //return formatted value
        return valueFormatter(valueFormatterParams);
      }
      //fault codes get auto formatted to dates sometimes
      if (params.column.getColId() === 'FAULT_CODE') {
        return `${params.value}\t`;
      }
      //return default
      return params.value;
    },
    []
  );

  return {
    getRowStyle,
    getContextMenuItems,
    getMainMenuItems,
    getValueGetter,
    getValueFormatter,

    onCellClicked,
    onColumnEverythingChanged,
    onFilterChanged,

    doesExternalFilterPass,
    cellStyles,
    cellRendererSelector,
    processCellCallback,
  };
};

export default useGridCallbacks;
