import { Alert, Collapse } from '@mui/material';
import { useEffect, useState } from 'react';

import { ToolDispoWarningProps } from 'power-tool/tool-dispo/types';

// Generally displayed as an RX config mismatch warning
export const ToolDispoWarning = (props: ToolDispoWarningProps) => {
  const [open, setOpen] = useState<boolean>(props.open);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const onClose = () => {
    setOpen(!false);

    if (props.closeHandler) {
      props.closeHandler();
    }
  };

  return (
    <Collapse in={open} {...props.transitionprops}>
      <Alert
        sx={{ color: 'white' }}
        onClose={props.allowclose === true ? onClose : undefined}
        variant='filled'
        {...props}
      >
        {props.message}
      </Alert>
    </Collapse>
  );
};
