import {
  Check,
  CloseOutlined,
  EditOutlined,
  KeyboardArrowDown,
  SaveOutlined,
} from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  ListItemIcon,
  MenuItem,
  TextField,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';

import { PowerToolDropdownMenu } from 'common/components/DropdownMenu';
import { STR_LAYOUT, STR_POWER_TOOL } from 'common/constants';
import { saveUserSettings } from 'common/features/settings/api';
import { useFetchSettings } from 'common/features/settings/hooks';
import { UserSetting } from 'common/features/settings/types';
import { useAppDispatch, useAppSelector } from 'common/hooks';
import {
  selectUser,
  selectApplicationContext,
} from 'common/stores/globalSlice';
import {
  selectLayout,
  setCurrentLayoutSetting,
} from 'common/stores/userSettingSlice';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

// these are the default layout settings and names we use
// these will "fill in" for the user if they do not have three layouts saved
const defaultLayoutSettingsName: UserSetting[] = [
  {
    config: '',
    setting: STR_LAYOUT,
    customName: 'Layout 1',
    isDefault: 'N',
  },
  {
    config: '',
    setting: STR_LAYOUT,
    customName: 'Layout 2',
    isDefault: 'N',
  },
  {
    config: '',
    setting: STR_LAYOUT,
    customName: 'Layout 3',
    isDefault: 'N',
  },
];

export const LayoutListMenu = (props: {
  onChange: (layout: UserSetting | undefined) => void;
}) => {
  const dispatch = useAppDispatch();

  const user = useAppSelector(selectUser);
  const currentLayout = useAppSelector(selectLayout);
  const applicationName = useAppSelector(selectApplicationContext);
  const [selectedLayout, setSelectedLayout] = useState<string>('');
  const [layoutSettings, setLayoutSettings] = useState<UserSetting[]>([]);
  const [renameLayoutModalOpen, setRenameLayoutModalOpen] =
    useState<boolean>(false);
  const [oldLayoutName, setOldLayoutName] = useState<string>('');
  const [currentEditedLayoutName, setCurrentEditedLayoutName] =
    useState<string>('');
  const [layoutAnchor, setLayoutAnchor] = React.useState<null | HTMLElement>(
    null
  );

  const { settings } = useFetchSettings();
  const { enqueueSnackbar } = useSnackbar();

  const open = Boolean(layoutAnchor);

  // when userSettings are loaded
  useEffect(() => {
    if (settings) {
      // get all the layouts, then get the default layout
      const userLayouts: UserSetting[] = settings.filter(
        (setting) => setting.setting === STR_LAYOUT
      );
      let defaultLayout: UserSetting | undefined = userLayouts.find(
        (setting) =>
          setting.isDefault === 'Y' && setting.customName !== undefined
      );

      if (!defaultLayout) {
        defaultLayout = userLayouts.length > 0 ? userLayouts[0] : undefined;
        if (userLayouts.length <= 0) {
          defaultLayout = defaultLayoutSettingsName[0];
        }
      }
      // set the selected layout
      setSelectedLayout(defaultLayout?.customName ?? '');

      dispatch(setCurrentLayoutSetting(defaultLayout));

      // here we check to see how many layouts the user has saved
      // if none, we use the default settings
      // if less than three, we use whatever they have saved, and then fill in the rest
      // otherwise, they have three, and we use all the user settings
      if (userLayouts.length <= 0) {
        setLayoutSettings(defaultLayoutSettingsName);
      } else if (userLayouts.length > 0 && userLayouts.length < 3) {
        const userLayoutNames = userLayouts.map((layout) => layout.customName);

        // we guard against users saving layouts without changing the name
        // otherwise, our default names could clash with the saved names in the DB
        const modifiedLayout: UserSetting[] = defaultLayoutSettingsName.filter(
          (layout) => {
            if (userLayoutNames.indexOf(layout.customName) < 0) {
              return layout;
            }
          }
        );
        setLayoutSettings(
          userLayouts.concat(modifiedLayout.slice(userLayouts.length - 3))
        );
      } else if (userLayouts.length >= 3) {
        setLayoutSettings(userLayouts.slice(0, 3));
      }
    }
  }, [settings, dispatch]);

  // when the layout dropdown is closed,
  // we trigger the change layout event
  const onCloseLayoutDropdown = (params: any) => {
    const text: string = params.target.textContent ?? '';
    if (text !== '') {
      setSelectedLayout(text);
      changeSelectedLayout(text);
    }

    setLayoutAnchor(null);
  };

  const changeSelectedLayout = (newSelectedLayout: string) => {
    let currentLayoutSetting = {
      ...layoutSettings.find(
        (setting) => setting.customName === newSelectedLayout
      ),
    } as UserSetting;

    // we set the current selected layout to isDefault = 'Y'
    // when users change layouts. this is because when they save,
    // whatever layout is selected will be stored as default
    if (currentLayoutSetting) {
      if (currentLayoutSetting.configObjid) {
        currentLayoutSetting.isDefault = 'Y';
        currentLayoutSetting.userId = user?.userName;
        currentLayoutSetting.application = applicationName;
      } else {
        currentLayoutSetting = {
          isDefault: 'Y',
          userId: user?.userName,
          application: applicationName,

          setting: STR_LAYOUT,
          config: '',
          customName: currentLayoutSetting.customName,
        };
      }

      props.onChange(currentLayoutSetting);
      dispatch(setCurrentLayoutSetting(currentLayoutSetting));
    }
  };

  // preps the current layout to save
  const saveLayout = (layout: UserSetting) => {
    if (layout) {
      const currentLayoutSetting: UserSetting = {
        userId: user?.userName,
        setting: STR_LAYOUT,
        customName: layout.customName,
        config: JSON.stringify(currentLayout),
        application: applicationName,
        isDefault: selectedLayout === layout.customName ? 'Y' : 'N',
      };

      if (layout.configObjid) {
        currentLayoutSetting.configObjid = layout.configObjid;
      }

      createOrUpdateLayout('save', currentLayoutSetting);
    }
  };

  // handles renaming the layout and saving a different layout
  const createOrUpdateLayout = (
    action: 'save' | 'edit',
    userSetting: UserSetting
  ) => {
    const userSettings: UserSetting[] = [userSetting];

    saveUserSettings(userSettings)
      .then((response) => {
        if (action === 'edit') {
          setRenameLayoutModalOpen(false);
        }

        if (response.length > 0) {
          // updateUserSettings(action, response[0]);
          const updatedLayoutSetting = response[0];
          let newlyEditedLayoutName = '';
          const updatedLayouts = layoutSettings.map((updatedLayout) => {
            const layout = { ...updatedLayout };

            if (action === 'save') {
              if (layout.customName === updatedLayoutSetting.customName) {
                layout.config = updatedLayoutSetting.config;
                layout.configObjid = updatedLayoutSetting.configObjid;
              }
            } else if (action === 'edit') {
              if (layout.customName === oldLayoutName) {
                layout.configObjid = updatedLayoutSetting.configObjid;
                if (layout.customName === selectedLayout) {
                  newlyEditedLayoutName = updatedLayoutSetting.customName ?? '';
                }
                layout.customName = updatedLayoutSetting.customName;
              }
            }

            return layout;
          });

          if (updatedLayouts.length > 0) {
            setLayoutSettings(updatedLayouts);
          }

          if (updatedLayoutSetting) {
            updatedLayoutSetting.isDefault = 'Y';
            updatedLayoutSetting.userId = user?.userName;
            updatedLayoutSetting.application = STR_POWER_TOOL;
          }
          if (action === 'save') {
            setSelectedLayout(updatedLayoutSetting.customName ?? '');
            props.onChange(updatedLayoutSetting);
            dispatch(setCurrentLayoutSetting(updatedLayoutSetting));
          }

          if (action === 'edit' && newlyEditedLayoutName) {
            setSelectedLayout(newlyEditedLayoutName);
            props.onChange(updatedLayoutSetting);
            dispatch(setCurrentLayoutSetting(updatedLayoutSetting));
          }

          if (action === 'edit') {
            enqueueSnackbar(`Renamed as "${userSetting.customName}"`, {
              variant: 'info',
            });
          } else {
            enqueueSnackbar(
              `Layout "${userSetting.customName}" saved successfully`,
              { variant: 'success' }
            );
          }
        }
      })
      .catch((e) => {
        if (action === 'edit') {
          setRenameLayoutModalOpen(false);
        }
        enqueueSnackbar(`Error saving "${userSetting.customName}"`, {
          variant: 'error',
        });
      });
  };

  const onOpenLayoutDropdown = (event: React.MouseEvent<HTMLElement>) => {
    setLayoutAnchor(event.currentTarget);
  };

  const onRenameLayout = (currentLayoutName: string | undefined) => {
    setCurrentEditedLayoutName(currentLayoutName ? currentLayoutName : '');
    setOldLayoutName(currentLayoutName ? currentLayoutName : '');
    setRenameLayoutModalOpen(true);
  };

  // when we close the rename modal, update the setting's name
  const onRenameModalClose = (
    event: {},
    reason: 'backdropClick' | 'escapeKeyDown'
  ) => {
    const sameLayout = layoutSettings.find(
      (layout) => layout.customName === currentEditedLayoutName
    );
    if (sameLayout) {
      setRenameLayoutModalOpen(false);
      enqueueSnackbar(
        `Already layout with name "${currentEditedLayoutName}" is present. Please save with different name`,
        { variant: 'error' }
      );
      return;
    }
    const currentLayoutSetting = {
      ...layoutSettings.find((layout) => layout.customName === oldLayoutName),
    } as UserSetting;

    if (currentLayoutSetting) {
      currentLayoutSetting.userId = user?.userName;
      currentLayoutSetting.application = applicationName;
      currentLayoutSetting.customName = currentEditedLayoutName;
      createOrUpdateLayout('edit', currentLayoutSetting);
    }
  };

  return (
    <React.Fragment>
      <Button
        variant='contained'
        size='small'
        disableElevation
        onClick={onOpenLayoutDropdown}
        endIcon={<KeyboardArrowDown />}
      >
        {selectedLayout}
      </Button>

      <PowerToolDropdownMenu
        anchorEl={layoutAnchor}
        open={open}
        onClose={onCloseLayoutDropdown}
      >
        {layoutSettings.map((layout) => (
          <MenuItem
            key={layout.customName}
            onClick={onCloseLayoutDropdown}
            disableRipple
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <ListItemIcon>
                {selectedLayout === layout.customName ? (
                  <Check
                    fontSize='medium'
                    style={{ color: PowerToolDarkTheme.statusGreen500 }}
                    sx={{ mr: 0.5, ml: 0, pl: 0 }}
                  />
                ) : (
                  <></>
                )}
              </ListItemIcon>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {layout.customName}
              </div>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                }}
              >
                <IconButton
                  onClick={() => onRenameLayout(layout.customName)}
                  size='small'
                  sx={{
                    '&.MuiSvgIcon-root': {
                      paddingRight: 0,
                    },
                  }}
                >
                  <EditOutlined
                    fontSize='small'
                    style={{
                      color: PowerToolDarkTheme.statusGray600,
                      paddingRight: 0,
                    }}
                  />
                </IconButton>

                <IconButton
                  onClick={() => saveLayout(layout)}
                  size='small'
                  sx={{
                    '&.MuiSvgIcon-root': {
                      paddingRight: 0,
                    },
                  }}
                >
                  <SaveOutlined
                    fontSize='small'
                    style={{
                      color: PowerToolDarkTheme.statusGreen300,
                      paddingRight: 0,
                    }}
                  />
                </IconButton>
              </div>
            </div>
          </MenuItem>
        ))}
      </PowerToolDropdownMenu>

      <Dialog
        PaperProps={{
          style: {
            backgroundColor: '#172839',
            backgroundImage: 'unset',
          },
        }}
        open={renameLayoutModalOpen}
        fullWidth={true}
        maxWidth={'xs'}
      >
        <DialogTitle>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '15px',
            }}
          >
            <div>Rename Layout</div>
            <Button
              sx={{
                mr: 1,
                alignSelf: 'flex-end',
                minWidth: 'unset',
                padding: '4px',
              }}
              onClick={(event) => setRenameLayoutModalOpen(false)}
            >
              <CloseOutlined />
            </Button>
          </div>
        </DialogTitle>

        <DialogContent dividers>
          <TextField
            label={'Enter new name...'}
            value={currentEditedLayoutName}
            size='small'
            fullWidth
            // length validation
            helperText={`Limit: ${currentEditedLayoutName.length}/25`}
            onChange={(event) =>
              setCurrentEditedLayoutName(
                event.target.value.length <= 25
                  ? event.target.value
                  : currentEditedLayoutName
              )
            }
          />
        </DialogContent>

        <DialogActions>
          <Button
            variant='outlined'
            color='primary'
            onClick={(event) => setRenameLayoutModalOpen(false)}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            color='success'
            onClick={(event) => onRenameModalClose({}, 'backdropClick')}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
