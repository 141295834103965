import { Summarize } from '@mui/icons-material';
import { Fab, Zoom } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';

import { CloseoutModal } from 'train-tool/features/closeout/CloseoutModal';
import { useFetchTrain } from 'train-tool/hooks/apiHooks';

/**
 * @returns {JSX.Element} FAB to show TAT closeout modal
 */
export const TATCloseoutButton = () => {
  const { train, fetchStatus } = useFetchTrain();
  const { enqueueSnackbar } = useSnackbar();

  const [disabled, setDisabled] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  // when fetch status and/or train change
  // re-enable button
  useEffect(() => {
    if (fetchStatus === 'idle' && train?.exists && train.lookupId) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [train, fetchStatus]);

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const onError = useCallback(() => {
    enqueueSnackbar('Closeout failed to save', {
      variant: 'error',
    });
  }, [enqueueSnackbar]);

  // on submit, show notification
  // and call close handler
  const onSubmitted = useCallback(() => {
    setDisabled(true);
    onClose();
    enqueueSnackbar('Mechanical closeout successfully submitted.', {
      variant: 'success',
    });
  }, [onClose, enqueueSnackbar]);

  return (
    <React.Fragment>
      <Zoom in={true}>
        <Fab
          disabled={disabled}
          size='small'
          color='success'
          onClick={() => setOpen((current) => !current)}
          sx={{
            position: 'absolute',
            bottom: 10,
            right: 10,
          }}
        >
          <Summarize
            sx={{
              color: 'white',
            }}
          />
        </Fab>
      </Zoom>
      <CloseoutModal
        open={open}
        onSubmitted={onSubmitted}
        onClose={onClose}
        onError={onError}
      />
    </React.Fragment>
  );
};
