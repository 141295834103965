import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'common/store';

import { Run } from 'power-tool/types';

export type RunState = {
  selectedRun?: Run;
  runs: Run[];
  runRemovedFromFilter: boolean;
  latestRun?: number;
};

const initialState: RunState = {
  runs: [],
  runRemovedFromFilter: false,
};

export const runSlice = createSlice({
  name: 'runs',
  initialState,
  reducers: {
    setRun: (state, action: PayloadAction<Run | undefined>) => {
      state.selectedRun = action.payload;
    },
    setRuns: (state, action: PayloadAction<Run[]>) => {
      state.runs = action.payload;
    },
    setRunRemovedFromFilter: (state, action: PayloadAction<boolean>) => {
      state.runRemovedFromFilter = action.payload;
    },
    setLatestRun: (state, action: PayloadAction<number>) => {
      state.latestRun = action.payload;
    },
  },
});

export const { setRun, setRuns, setRunRemovedFromFilter, setLatestRun } =
  runSlice.actions;
export const selectRun = (state: RootState) => state.runs.selectedRun;
export const selectAllRuns = (state: RootState) => state.runs.runs;
export const selectRunRemovedFromFilter = (state: RootState) =>
  state.runs.runRemovedFromFilter;
export const selectLatestRun = (state: RootState) => state.runs.latestRun;
export default runSlice.reducer;
