import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Asset } from 'common/types/types';

import { Run, ToolOutputData } from 'power-tool/types';

export type AssetCardInput = {
  vehicleObjId: number;
};

export type FiredFaultInput = {
  id: number | string;
  ruleId: number;
  type: string;
};

export type RunActionInput = {
  run: Run;
  firedFaultInput?: FiredFaultInput;
};

// the application will dispatch these actions to 'trigger' a saga
// instead of directly dispatching values to the store,
// we dispatch these action types that are then observed
// in watchers.ts

export const sagaActionSlice = createSlice({
  name: 'sagaActions',
  initialState: {},
  reducers: {
    caseAction: (state, action: PayloadAction<string>) => {},
    runChangeAction: (state, action: PayloadAction<number>) => {},
    getFiredFaults: (state, action: PayloadAction<FiredFaultInput>) => {},
    getFiredFaultsAndFiredParameters: (
      state,
      action: PayloadAction<FiredFaultInput>
    ) => {},
    runAction: (state, action: PayloadAction<RunActionInput>) => {},
    ruleSelectedAction: () => {},
    initializeAction: (
      state,
      action: PayloadAction<{ caseId?: string; vehicleObjid?: string }>
    ) => {},
    assetCardAction: (state, action: PayloadAction<AssetCardInput>) => {},
    getAssetRuns: (state, action: PayloadAction<number>) => {},
    setToolOutputAction: (state, action: PayloadAction<ToolOutputData[]>) => {},
    setAssetAction: (state, action: PayloadAction<Asset | undefined>) => {},
  },
});

export const {
  caseAction,
  runChangeAction,
  getFiredFaults,
  getFiredFaultsAndFiredParameters,
  runAction,
  ruleSelectedAction,
  initializeAction,
  assetCardAction,
  getAssetRuns,
  setToolOutputAction,
  setAssetAction,
} = sagaActionSlice.actions;

export default sagaActionSlice.reducer;
