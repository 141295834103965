import styled from 'styled-components';

export const StyledLink = styled.a`
  cursor: pointer;
  color: ${(props) => props.theme.voltBlue300};
  :hover {
    color: ${(props) => props.theme.voltBlue400};
  }
  font-weight: bold;
`;
