import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from '@mui/material';
import { GridApi, GridOptions, RowSelectedEvent } from 'ag-grid-community';
import chroma from 'chroma-js';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  selectGridApi, // setFiredParameters,
  setFiredFaults,
  setFiredFltAndParamForRuleLoaded,
} from 'common/cards/Data/store';
import { AgGridCustom } from 'common/components/ag-grid/DensePowerToolGrid';
import { ZonedTimeCell } from 'common/components/ag-grid/cell-renderers/ZonedTimeCell';
import { CustomColDef } from 'common/components/ag-grid/types';
import { useAppDispatch, useAppSelector } from 'common/hooks';
import {
  selectRule,
  selectLastSelectedRuleFromAppRules,
  selectAssetDetails,
  selectUser,
  changeRule,
  changeLastSelectedRuleFromAppRules,
  setDeselectedType,
} from 'common/stores/globalSlice';

import { darkTheme, PowerToolDarkTheme } from 'theme/PowerToolThemes';

import { getMaxRunId } from 'power-tool/api';
import { useFetchRxMetrics, useFetchToolOutput } from 'power-tool/api/hooks';
import { getRxPlots } from 'power-tool/cards/Plot/api';
import { useFetchRxPlots } from 'power-tool/cards/Plot/hooks';
import { RuleIdCell } from 'power-tool/cards/ToolOutput/components/RuleIdCell';
import { StyledToolOutput } from 'power-tool/cards/ToolOutput/components/ToolOutputCard.styled';
import {
  RecommendationCell,
  NTFCell,
  ReissueCell,
} from 'power-tool/cards/ToolOutput/components/ToolOutputCells';
import {
  ruleSelectedAction,
  setToolOutputAction,
} from 'power-tool/saga/actionSlice';
import {
  selectCaseActionDisabled,
  selectCaseActionDisableReason,
  selectIsToolDispoLoading,
  selectIsOMDServicesLoaded,
  selectVizxAttachments,
  getCaseActionState,
  setIsToolDispoLoading,
  updateItem,
  openToolDispoAction,
} from 'power-tool/stores/caseActionSlice';
import { selectCase } from 'power-tool/stores/casesSlice';
import { setRxPlots } from 'power-tool/stores/plotSlice';
import { selectRun, setLatestRun } from 'power-tool/stores/runSlice';
import {
  selectToolOutput,
  setToolOutputData,
} from 'power-tool/stores/toolOutputSlice';
import { ToolOutputModal } from 'power-tool/tool-dispo/components/ToolOutputModal';
import {
  DeliveryAttachmentProps,
  ToolOutputItemState,
} from 'power-tool/tool-dispo/types';

/**
 *
 */
export function ToolOutputCard() {
  const { rxPlots } = useFetchRxPlots();

  // redux store
  const dispatch = useAppDispatch();
  const selectedCase = useAppSelector(selectCase);
  const toolOutputData = useAppSelector(selectToolOutput);
  const selectedRule = useAppSelector(selectRule);
  const isLastSelectedRuleFromAppRules = useAppSelector(
    selectLastSelectedRuleFromAppRules
  );
  const vehicleInfo = useAppSelector(selectAssetDetails);
  const isCaseActionDisabled = useAppSelector(selectCaseActionDisabled);
  const actionDisabledReason = useAppSelector(selectCaseActionDisableReason);
  const user = useAppSelector(selectUser);

  // state for Tool Output card
  const [gridApi, setGridApi] = useState<GridApi>();
  const [toolDispoOpen, setToolDispoOpen] = useState<boolean>(false);
  const [errorOpen, setErrorOpen] = useState<boolean>(false);

  const isToolDispoLoading = useAppSelector(selectIsToolDispoLoading);
  const isOMDServicesLoaded = useAppSelector(selectIsOMDServicesLoaded);
  const vizxAttachments = useAppSelector(selectVizxAttachments);
  const state = useAppSelector(getCaseActionState);
  const selectedGridApi = useAppSelector(selectGridApi);
  // ref hooks
  const ruleIdRef = useRef(selectedRule);
  const run = useRef(useAppSelector(selectRun));
  const toolOutputRef = useRef(toolOutputData);

  const { rxMetrics } = useFetchRxMetrics();
  const { toolOutput } = useFetchToolOutput();

  // ====== useCallback Hooks ======

  const selectRuleOnGrid = useCallback(
    (api: GridApi) => {
      const currentRuleId = ruleIdRef.current;
      let ruleMatch = true;
      if (currentRuleId && currentRuleId > 0) {
        ruleMatch = false;
        api.forEachNode((node) => {
          if (node.data.ruleId === currentRuleId) {
            node.setSelected(true);
            ruleMatch = true;
          }
        });
      }

      if (!ruleMatch && !isLastSelectedRuleFromAppRules) {
        dispatch(changeRule(0));
        // dispatch(setFiredParameters([]));
        dispatch(setFiredFaults([]));
        dispatch(setFiredFltAndParamForRuleLoaded(true));
      }
    },
    [dispatch, isLastSelectedRuleFromAppRules]
  );

  // ====== Use Effect Hooks ======

  // when our selected rule changes, we want to update our ref
  //
  useEffect(() => {
    ruleIdRef.current = selectedRule;
    if (!isLastSelectedRuleFromAppRules) {
      dispatch(ruleSelectedAction());

      if (selectedRule > 0 && selectedCase) {
        getMaxRunId(selectedCase.caseId, selectedRule)
          .then((response) => dispatch(setLatestRun(response[0]['runId'])))
          .catch((err) => console.log(err));
      }
    }
  }, [selectedRule, selectedCase, dispatch]);

  // when our tooloutput changes (because of a case select, not a run select)
  // aggregate the RXs and go get the RX Plot information, and dispatch it
  useEffect(() => {
    if (rxPlots !== undefined) {
      dispatch(setRxPlots(rxPlots));
    } else {
      dispatch(setRxPlots([]));
    }
  }, [rxPlots, dispatch]);

  useEffect(() => {
    if (toolDispoOpen === true && isCaseActionDisabled === true) {
      onModalClose({}, 'escapeKeyDown');
    }
  }, [isCaseActionDisabled]);

  useEffect(() => {
    toolOutputRef.current = toolOutputData;
  }, [toolOutputData]);

  useEffect(() => {
    dispatch(setToolOutputAction(toolOutput ?? []));
  }, [toolOutput]);

  useEffect(() => {
    if (rxMetrics) {
      dispatch(
        setToolOutputData(
          toolOutputRef.current.map((item) => ({
            ...item,
            ntfPct: rxMetrics?.find(
              (metric) => metric.rxId === item.rxId.toString()
            )?.ntfPct,
            reissuePct: rxMetrics?.find(
              (metric) => metric.rxId === item.rxId.toString()
            )?.reissuePct,
          }))
        )
      );
    }
  }, [rxMetrics]);

  /**
   *
   * @param reason
   */
  const onModalClose = (
    event: {},
    reason: 'backdropClick' | 'escapeKeyDown'
  ) => {
    setToolDispoOpen(false);
  };

  const columns: CustomColDef[] = [
    {
      field: 'ruleId',
      headerName: 'Tool',
      width: 95,
      cellRenderer: 'ruleId',
      suppressSizeToFit: true,
      filter: 'agSetColumnFilter',
    },
    {
      field: 'rxTitle',
      headerName: 'Recommendation',
      cellRenderer: 'rxTitle',
      width: 400,
      filter: 'agSetColumnFilter',
    },
    {
      field: 'lastUpdatedDate',
      headerName: 'Fired On',
      width: 100,
      suppressSizeToFit: true,
      filter: 'agSetColumnFilter',
      cellRenderer: ZonedTimeCell,
    },
    {
      field: 'ntfRate',
      headerName: 'NTF',
      width: 60,
      cellRenderer: 'ntfRate',
      suppressSizeToFit: true,
      filter: 'agSetColumnFilter',
    },
    {
      field: 'reissueRate',
      headerName: 'Repeat',
      cellRenderer: 'reissueRate',
      width: 60,
      suppressSizeToFit: true,
      filter: 'agSetColumnFilter',
    },
  ];

  const gridOptions: GridOptions = {
    /**
     *
     * @param params
     */
    onFirstDataRendered: (params) => {
      params.api.sizeColumnsToFit();
      setGridApi(params.api);
    },

    /**
     *
     * @param event
     */
    onRowSelected: (event: RowSelectedEvent) => {
      if (event.node.isSelected()) {
        dispatch(changeLastSelectedRuleFromAppRules(false));
        dispatch(changeRule(event.node.data.ruleId));
        event.api['deselectedCase'] = false;
      } else if (
        !event.node.isSelected() &&
        event.api.getSelectedNodes().length === 0
      ) {
        event.api['deselectedCase'] = true;
        dispatch(setDeselectedType('rule'));
      }
    },

    /**
     *
     * @param params
     */
    onComponentStateChanged: (params) => {
      if (
        params.api.getDisplayedRowCount() === 1 &&
        params.api.getDisplayedRowAtIndex(0)?.data.ruleId > 0
      ) {
        params.api.getDisplayedRowAtIndex(0)?.setSelected(true);
      } else {
        selectRuleOnGrid(params.api);
      }
    },
    frameworkComponents: {
      rxTitle: RecommendationCell,
      ruleId: RuleIdCell,
      ntfRate: NTFCell,
      reissueRate: ReissueCell,
    },
    overlayNoRowsTemplate: 'Select a Case or Run to view Tools',
    headerHeight: 24,
  };

  useEffect(() => {
    if (isToolDispoLoading) {
      // when OMD services are complete, we are ready to update
      // our list of attachments and open the modal
      if (isOMDServicesLoaded === 'complete') {
        refreshAttachmentsAndOpen();
      }

      // otherwise if we have an error on OMD side, we cant open the modal
      // and we need to show an error dialog
      else if (isOMDServicesLoaded === 'error') {
        setErrorOpen(true);
        dispatch(setIsToolDispoLoading(false));
      }
    }
  }, [isOMDServicesLoaded]);

  //method to update all type of attachments to redux
  /**
   *
   */
  const refreshAttachmentsAndOpen = () => {
    let userPlots: DeliveryAttachmentProps[] | undefined = [];

    const allCharts: string[] = [];
    const unchangedCharts: string[] = [];

    if (selectedGridApi && selectedGridApi?.getChartModels()) {
      // we set our current attachments to our plots
      userPlots = selectedGridApi
        .getChartModels()
        ?.filter(
          // that have a parameter included other than 'occur_date'
          // eslint-disable-next-line no-self-compare
          (model) =>
            model.cellRange.columns?.filter((column) => column !== 'OCCUR_DATE')
              .length ?? 0 > 0
        )
        .map(
          // and map each plot to an attachment prop object
          // if any step returns undefined, return our current list
          (model) => {
            allCharts.push(model.chartId);
            return {
              src:
                selectedGridApi?.getChartImageDataURL({
                  chartId: model.chartId,
                  fileFormat: 'image/png',
                }) ?? model.chartId,
              label: model.chartOptions.line?.title?.text ?? model.chartId,
              type: 'plot',
              chartId: model.chartId,
              isAttached: false,
              id: model.chartId,
            };
          }
        );
    }

    if (
      (userPlots && userPlots.length > 0) ||
      (vizxAttachments && vizxAttachments.length > 0)
    ) {
      state.items?.forEach((item) => {
        const newAttachment: DeliveryAttachmentProps[] = [];
        const existingAttachments: DeliveryAttachmentProps[] = [
          ...item.availableAttachments,
        ];
        // get our existing vizx attachments, need to preserve state
        const attachedVizxAttachmentLabels = existingAttachments
          .filter(
            (attachment) =>
              attachment.type === 'vizxPlot' && attachment.isAttached
          )
          .map((attachment) => attachment.label);

        // loop through vizx attachments and add it to the start.
        // So it will be shown always at first
        if (vizxAttachments && vizxAttachments.length > 0) {
          vizxAttachments.forEach((vizxAttachment, index) => {
            //check for existing vizxattachment is selected or not. if selected, clone object set it as true
            if (
              attachedVizxAttachmentLabels.indexOf(vizxAttachment.label) >= 0
            ) {
              const newVizxAttachment = { ...vizxAttachment };
              newVizxAttachment.isAttached = true;
              newAttachment.push(newVizxAttachment);
            } else {
              newAttachment.push(vizxAttachment);
            }
          });
        }
        if (existingAttachments && existingAttachments.length > 0) {
          existingAttachments.forEach((oldAttachment, index) => {
            // if the existing attachment is a power tool plot and
            // that plot is still in our list of charts

            if (
              oldAttachment.type === 'plot' &&
              oldAttachment.chartId &&
              allCharts.indexOf(oldAttachment.chartId) >= 0
            ) {
              // keep the existing chart
              unchangedCharts.push(oldAttachment.chartId);
              // and add it to our list of attachments
              newAttachment.push(oldAttachment);
            }
            // for non plot attachment types, just add it to our list
            else if (
              oldAttachment.type !== 'plot' &&
              oldAttachment.type !== 'vizxPlot'
            ) {
              newAttachment.push(oldAttachment);
            }
          });
        }

        // now, for all new plots add them to the end of the list
        if (userPlots && userPlots.length > 0) {
          userPlots.forEach((userPlot, index) => {
            if (
              userPlot.chartId &&
              unchangedCharts.indexOf(userPlot.chartId) < 0
            ) {
              newAttachment.push(userPlot);
            }
          });
        }

        // and finally update our item
        const updatedItem: ToolOutputItemState = {
          ...item,
          availableAttachments: newAttachment,
        };
        dispatch(updateItem(updatedItem));
      });
    }

    //open modal after all attachments updated to redux
    dispatch(setIsToolDispoLoading(false));
    setToolDispoOpen(true);
  };

  /**
   *
   */
  const ActionButton = () => {
    return (
      <LoadingButton
        loading={isToolDispoLoading}
        onClick={() => dispatch(openToolDispoAction(user!))}
        disabled={isCaseActionDisabled}
        variant='contained'
        size='small'
        sx={{
          position: 'absolute',
          bottom: 0,
          right: 0,
          component: 'div',
          m: 1,
          bgcolor: darkTheme.palette.success.main,
          color: 'white',
          // some trickery to get tooltips working on a disabled button
          '&:hover:not(.Mui-disabled)': {
            bgcolor: chroma.hex(darkTheme.palette.success.main).darken().hex(),
          },
          '&.Mui-disabled': {
            pointerEvents: 'auto',
          },
        }}
      >
        action
      </LoadingButton>
    );
  };
  const tooltipButton = useMemo(() => {
    return !isCaseActionDisabled ? (
      <ActionButton />
    ) : (
      <Tooltip
        title={
          <span style={{ fontSize: '12px' }}>{actionDisabledReason ?? ''}</span>
        }
      >
        <span
          style={{
            position: 'absolute',
            right: 0,
          }}
        >
          <ActionButton />
        </span>
      </Tooltip>
    );
  }, [ActionButton, actionDisabledReason, isCaseActionDisabled]);

  return (
    <StyledToolOutput>
      <AgGridCustom
        data={toolOutputData}
        columns={columns}
        options={gridOptions}
        // height={'100%'} />
        height={'calc(100% - 45px)'}
      />

      {tooltipButton}

      <ToolOutputModal open={toolDispoOpen} onClose={onModalClose} />

      <Dialog
        PaperProps={{
          style: {
            backgroundColor: PowerToolDarkTheme.main,
            backgroundImage: 'unset',
          },
        }}
        fullWidth={true}
        open={errorOpen}
        maxWidth={'sm'}
      >
        <DialogTitle>Error</DialogTitle>

        <DialogContent>{'Error contacting OMD services.'}</DialogContent>

        <DialogActions>
          <Button
            variant='contained'
            color='primary'
            sx={{ color: 'white' }}
            onClick={() => setErrorOpen(false)}
          >
            ok
          </Button>
        </DialogActions>
      </Dialog>
    </StyledToolOutput>
  );
}

export default ToolOutputCard;
