import { Paper } from '@mui/material';
import chroma from 'chroma-js';

import { useFetchTrain } from 'train-tool/hooks/apiHooks';

import { ParameterLabel } from './ParameterLabelComponent';
import { useDisplayProps } from './hooks';

/**
 *
 * @param {any} props implicit component props
 * @param {string[]} props.labels labels to display within the component
 * @returns {JSX.Element} first column in the train status table containing parameter labels
 */
export const LeadColumnComponent = (props: { labels: string[] }) => {
  const { train } = useFetchTrain();
  const { leadColumnProps, labelColumnProps, evenColor, oddColor } =
    useDisplayProps();

  return (
    <div
      style={{
        ...leadColumnProps,
      }}
    >
      {train === undefined ? (
        <></>
      ) : (
        <Paper
          style={{
            ...labelColumnProps,
          }}
        >
          {props.labels.map((item, index) => {
            return (
              <div
                key={index}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundColor:
                    index % 2 === 0
                      ? chroma(evenColor).brighten(1).hex()
                      : chroma(oddColor).brighten(1).hex(),
                }}
              >
                <ParameterLabel
                  subText={
                    item.toLowerCase() === 'fuel level'
                      ? 'Last 2 days'
                      : undefined
                  }
                  value={item}
                  divider={index !== props.labels.length - 1}
                />
              </div>
            );
          })}
        </Paper>
      )}
    </div>
  );
};
