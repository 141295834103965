import { ColDef } from 'ag-grid-community';
import { useMemo } from 'react';

import { useFetchIncidentEvents } from 'loco-history/api/hooks';
import { IncidentStatusRenderer } from 'loco-history/cards/Timeline/components/events/grids/renderers/IncidentStatusRenderer';
import { createEventTimeColDef } from 'loco-history/cards/Timeline/components/events/utils';
import { IncidentEvent } from 'loco-history/types';

import { EventsGrid } from './EventsGrid';

/**
 * @returns {JSX.Element} ag-grid wrapper for locomotive incident events
 */
export const IncidentEvents = () => {
  const { incidentEvents } = useFetchIncidentEvents();

  const columns = useMemo(
    (): ColDef[] => [
      {
        field: 'custIncidentType',
        headerName: 'Incident',
        cellRenderer: 'agGroupCellRenderer',
        headerCheckboxSelection: true,
        checkboxSelection: true,
        width: 125,
      },
      {
        field: 'status',
        headerName: 'Status',
        width: 80,
        cellRenderer: IncidentStatusRenderer,
      },
      {
        field: 'incidentCodeDescription',
        headerName: 'Code Description',
        width: 150,
      },
      {
        field: 'incidentOpenDesc',
        headerName: 'Open Description',
        suppressSizeToFit: false,
      },
      createEventTimeColDef({
        field: 'incidentDate',
        headerName: 'Open Date',
      }),
      createEventTimeColDef({
        field: 'closeOutDate',
        headerName: 'Close Date',
      }),
    ],
    []
  );

  return <EventsGrid<IncidentEvent> data={incidentEvents} columns={columns} />;
};
