import { Stack, Divider, Typography } from '@mui/material';
import React from 'react';

import { useUserFormattedTime } from 'common/hooks';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

import { ViewRelatedDataComponent } from 'train-tool/cards/Analysis/components/ViewRelatedDataComponent';
import { DPAlarmMap } from 'train-tool/cards/Analysis/config';
import { DPAlarmDetails } from 'train-tool/types';

/**
 *
 * @param {any} props implicit destructured props
 * @param {DPAlarmDetails} props.alarm DP alarm object details
 * @returns {JSX.Element} expanded detail view for a DP alarm
 */
export const AlarmDetailView = ({ alarm }: { alarm: DPAlarmDetails }) => {
  return (
    <React.Fragment>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems='center'
      >
        <Stack
          direction={'row'}
          alignItems='center'
          spacing={1}
          sx={{ ml: 2, color: PowerToolDarkTheme.neutralDark800 }}
          divider={<Divider orientation='vertical' flexItem />}
        >
          <Typography variant='button'>{'Date:'}</Typography>
          <Typography variant='body2' sx={{ ml: 1 }}>
            {useUserFormattedTime(alarm.occurDate)}
          </Typography>
        </Stack>

        <ViewRelatedDataComponent />
      </Stack>

      <Stack
        direction={'row'}
        alignItems='center'
        spacing={3}
        sx={{ ml: 1 }}
        divider={<Divider orientation='vertical' flexItem />}
      >
        <Stack direction={'row'} alignItems='center' spacing={1}>
          <Typography variant='button'>{'Details: '}</Typography>
          <Typography variant='body2'>
            {DPAlarmMap[alarm.alarm].description ??
              'No additional details available'}
          </Typography>
        </Stack>
      </Stack>
    </React.Fragment>
  );
};
