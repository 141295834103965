import { Chip, Collapse, TextField, Typography } from '@mui/material';
import chroma from 'chroma-js';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { LoadingSpinner } from 'common/components/LoadingComponents';
import { useAppSelector } from 'common/hooks';
import { selectCustomer } from 'common/stores/globalSlice';
import { getRoadNumber } from 'common/util/utils';

import { useFetchTrain } from 'train-tool/hooks/apiHooks';

import { AlertOutput } from './components/AlertOutputComponent';
import { AlertTitleComponent } from './components/AlertTitleComponent';
import { AlertDetailViewComponent } from './components/DetailViewComponent';
import { GenericAlertComponent } from './components/GenericAlertComponent';
import { IncidentTitleComponent } from './components/IncidentTitleComponent';
import { TrainAlertComponent } from './components/TrainAlertComponent';
import { CustomerIncidentDetailView } from './components/detail-views/IncidentDetailView';
import { AnalysisCardCategoryMap, DPAlarmMap } from './config';
import { AnalysisCardAlertType, AnalysisCardAlertTypes } from './types';

/**
 * @returns {JSX.Element} wrapper card component for the analysis card. contains output for defects, rxs, faults, sense, and others
 */
export const AnalysisCard = () => {
  const customer = useAppSelector(selectCustomer);

  const { train, isPlaceholderData } = useFetchTrain();

  const [selectedCategories, setSelectedCategories] = useState<string[]>(
    AnalysisCardAlertTypes.map((i) => i)
  );
  const [searchString, setSearchString] = useState<string>('');

  const [animationTimer, setAnimationTimer] = useState<NodeJS.Timer>();
  const [animationCountdown, setAnimationCountdown] = useState<number>();

  const carouselText = [
    'Active RXs',
    'Active Faults',
    'DP Alarms',
    'Train Sense',
    'Open Defects',
  ];

  useEffect(() => {
    // we re-set the timer when we are looking at placeholder data
    // or when the returned train is null
    // however these can both happen (i.e. we search for a train that returns nothing)
    // so we need to ensure the timer is undefined prior to setting again, otherwise there will be more than 1
    if (
      (isPlaceholderData || train?.trainId === null) &&
      animationTimer === undefined
    ) {
      setAnimationTimer(
        setInterval(
          () => setAnimationCountdown((timer) => (timer ? timer + 1 : 1)),
          2000
        )
      );
    }

    // we want to clear the interval while content is rendered to prevent re-renders on this interval
    else if (train?.trainId !== null) {
      clearInterval(animationTimer);
      setAnimationTimer(undefined);
    }

    // we don't want animationTimer in this thing - re-render on each tick
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaceholderData, train?.trainId]);

  useEffect(() => {
    if (animationCountdown && animationCountdown > carouselText.length - 1) {
      setAnimationCountdown(0);
    }
  }, [animationCountdown, carouselText.length]);

  const filtered = useCallback(
    (type: AnalysisCardAlertType, ...tests: string[]) => {
      if (searchString) {
        return [type, ...tests].some((str) =>
          str.toLowerCase().includes(searchString.toLowerCase())
        );
      } else {
        return selectedCategories.includes(type);
      }
    },
    [searchString, selectedCategories]
  );

  const availableCategories = useMemo(() => {
    // Only CSX gets all alert types
    if (customer === 'CSX') {
      return AnalysisCardAlertTypes;
    }

    // other customers dont get defects
    else {
      return AnalysisCardAlertTypes.filter((type) => type !== 'incident');
    }
  }, [customer]);

  const alerts = useMemo(() => {
    const trainAlerts: JSX.Element[] = [];

    train?.locomotives.forEach((loco) => {
      const roadNo = getRoadNumber(loco);
      const key = loco.vehicleObjid ?? loco.roadNumber;

      const rxs =
        train.activeRXs
          ?.filter((rx) => rx.asset === roadNo)
          .filter((rx) =>
            filtered('rx', rx.title, rx.rxCaseId, rx.status, rx.urgency)
          ) ?? [];

      const sense =
        train.sense
          ?.filter((sense) => sense.asset === roadNo)
          .filter((sense) => filtered('sense', sense.message, sense.title)) ??
        [];

      const faults =
        train.activeFaults
          ?.filter((fault) => fault.asset === roadNo)
          .filter((fault) =>
            filtered(
              'fault',
              fault.faultCode,
              fault.faultDesc,
              fault.operatorMessage
            )
          ) ?? [];

      const defects =
        train.openDefects
          ?.filter((defect) => defect.asset === roadNo)
          .filter((defect) =>
            filtered(
              'incident',
              defect.custIncidentType,
              defect.incidentCodeDescription,
              defect.incidentOpenDesc
            )
          ) ?? [];

      const alarms =
        train.locomotives
          .map((loco) => loco.alarms?.filter((alarm) => alarm.asset === roadNo))
          .flat()
          .filter((alarm) => alarm !== undefined)
          .filter((alarm) => filtered('alarm', alarm?.alarm ?? '')) ?? [];

      if (loco.foreign) {
        trainAlerts.push(
          <AlertOutput
            key={key}
            label={roadNo}
            alerts={[
              <GenericAlertComponent
                key={key}
                message='No information available'
                severity='warning'
              />,
            ]}
          />
        );
      }

      const locoAlerts: JSX.Element[] = [];

      if (loco.health?.risk === 'No Comm') {
        locoAlerts.push(
          <GenericAlertComponent
            key={key}
            message='No locomotive communication'
            severity='info'
          />
        );
      }

      locoAlerts.push(
        ...rxs.map((rx) => (
          <TrainAlertComponent
            key={rx.rxCaseId}
            type='rx'
            alertTypeProps={AnalysisCardCategoryMap['rx']}
            detailView={<AlertDetailViewComponent type='rx' source={rx} />}
            title={
              <AlertTitleComponent message={rx.title} type='rx' source={rx} />
            }
          />
        ))
      );

      locoAlerts.push(
        ...faults.map((fault) => (
          <TrainAlertComponent
            key={fault.occurDate.toString()}
            type='fault'
            alertTypeProps={AnalysisCardCategoryMap.fault}
            detailView={
              <AlertDetailViewComponent type='fault' source={fault} />
            }
            title={
              <AlertTitleComponent
                message={`${fault.faultCode}: ${fault.faultDesc}`}
                type='fault'
                source={fault}
              />
            }
          />
        ))
      );

      locoAlerts.push(
        ...alarms.map((alarm) => (
          <TrainAlertComponent
            key={alarm?.alarm}
            type='alarm'
            alertTypeProps={AnalysisCardCategoryMap.alarm}
            detailView={
              <AlertDetailViewComponent type='alarm' source={alarm} />
            }
            title={
              <AlertTitleComponent
                message={DPAlarmMap[alarm?.alarm ?? ''].label}
                type='alarm'
                source={alarm}
              />
            }
          />
        ))
      );

      locoAlerts.push(
        ...sense.map((sense) => (
          <TrainAlertComponent
            key={sense.title}
            type='sense'
            alertTypeProps={AnalysisCardCategoryMap.sense}
            detailView={
              <AlertDetailViewComponent type='sense' source={sense} />
            }
            title={
              <AlertTitleComponent
                message={sense.title}
                type='alarm'
                source={sense}
              />
            }
          />
        ))
      );

      // we only show defects for CSX
      if (defects && defects.length > 0 && customer === 'CSX') {
        locoAlerts.push(
          <TrainAlertComponent
            key={loco.vehicleHdr}
            type='incident'
            alertTypeProps={AnalysisCardCategoryMap.incident}
            title={<IncidentTitleComponent incidents={defects} />}
            detailView={<CustomerIncidentDetailView defects={defects} />}
          />
        );
      }

      // if we have alerts, show them
      if (locoAlerts.length > 0) {
        trainAlerts.push(
          <AlertOutput key={key} label={roadNo} alerts={locoAlerts} />
        );
      }

      // if we have a foreign power unit
      // show a special no info warning
      else if (loco.foreign) {
        trainAlerts.push(
          <AlertOutput
            key={key}
            label={roadNo}
            alerts={[
              <GenericAlertComponent
                key={key}
                message='No information available'
                severity='warning'
              />,
            ]}
          />
        );
      }

      // otherwise, we're all good
      else {
        trainAlerts.push(
          <AlertOutput
            key={key}
            label={roadNo}
            alerts={[
              <GenericAlertComponent
                key={key}
                message='No active issues'
                severity='success'
              />,
            ]}
          />
        );
      }
    });

    return trainAlerts;
  }, [train, customer, filtered]);

  // we make sure we have a train and we are not looking at the placeholder data
  if (train && train.locomotives.length > 0 && !isPlaceholderData) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '7px',
          padding: '5px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              gap: '7px',
            }}
          >
            {availableCategories.map((category: AnalysisCardAlertType) => (
              <Chip
                key={category}
                sx={{
                  borderColor: chroma(AnalysisCardCategoryMap[category].color)
                    .alpha(0.5)
                    .hex(),
                  '&.MuiChip-filled': {
                    border: `1px solid ${chroma(
                      AnalysisCardCategoryMap[category].color
                    )
                      .alpha(0.5)
                      .hex()}`,
                    backgroundColor: chroma(
                      AnalysisCardCategoryMap[category].color
                    )
                      .alpha(0.5)
                      .hex(),
                  },
                  '&:hover': {
                    backgroundColor: chroma(
                      AnalysisCardCategoryMap[category].color
                    )
                      .brighten()
                      .alpha(0.5)
                      .hex(),
                  },
                }}
                size='small'
                label={
                  <Typography
                    variant='button'
                    sx={{
                      fontSize: '12px',
                    }}
                  >
                    {AnalysisCardCategoryMap[category].label}
                  </Typography>
                }
                onClick={() =>
                  selectedCategories.includes(category)
                    ? setSelectedCategories(
                        selectedCategories.filter((x) => x !== category)
                      )
                    : setSelectedCategories([...selectedCategories, category])
                }
                variant={
                  selectedCategories.includes(category) ? 'filled' : 'outlined'
                }
              />
            ))}
          </div>

          <TextField
            sx={{
              '& .MuiFilledInput-root': { borderRadius: '4px' },
              '& .MuiFilledInput-underline:before': { border: 'none' },
              '& .MuiFilledInput-underline:hover:before': { border: 'none' },
            }}
            variant='filled'
            placeholder='Search...'
            size='small'
            value={searchString}
            onChange={(event) => setSearchString(event.target.value)}
            hiddenLabel
          />
        </div>
        {alerts}
      </div>
    );
  }

  // otherwise we are looking at placeholder data, but we don't have a train ID we know we are loading
  else if (train?.trainId === null && isPlaceholderData) {
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <LoadingSpinner />
      </div>
    );
  }

  // else, we are either on a dummy placeholder train (initial view)
  // or no results came back (null train ID), so display boilerplate text
  else {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'row',
          height: '100%',
        }}
      >
        <Typography variant='h2' sx={{ fontSize: '22px' }}>
          Search a train or asset to view
        </Typography>

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {carouselText.map((label, index) => (
            <Collapse
              key={index}
              in={animationCountdown === index}
              orientation={'vertical'}
              timeout={1000}
            >
              <Typography
                variant='h2'
                sx={{ fontSize: '18px', ml: 1, fontWeight: 500 }}
              >
                {label}
              </Typography>
            </Collapse>
          ))}
        </div>
      </div>
    );
  }
};

export default AnalysisCard;
