import { ThemeProvider } from '@mui/material';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { SnackbarProvider } from 'notistack';
import { useEffect } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import { OktaTokenInterceptor } from 'common/api/config';
import SnackbarCloseButton from 'common/components/SnackbarCloseButton';
import { TopBar } from 'common/components/app-bar/AppBar';
import { GridLayout } from 'common/components/layout/GridLayout';
import { useAppSelector } from 'common/hooks';
import { PostMessageProvider } from 'common/providers/PostMessageProvider';
import { URLProvider } from 'common/providers/URLProvider';
import {
  selectTheme,
  selectApplicationContext,
} from 'common/stores/globalSlice';

import {
  lightTheme,
  darkTheme,
  PowerToolLightTheme,
  PowerToolDarkTheme,
} from 'theme/PowerToolThemes';

import { CaseActionMonitor } from 'power-tool/tool-dispo/CaseActionMonitor';

import { GlobalStyles } from './theme/GlobalStyles';

/**
 * @returns {JSX.Element} main application wrapper. all non-store providers are defined here. communication with OMD is also located here
 */
export const PowerTool = () => {
  const theme = useAppSelector(selectTheme);
  const applicationContext = useAppSelector(selectApplicationContext);

  // updating the document title (tab title)
  // depending on the application context
  useEffect(() => {
    switch (applicationContext) {
      case 'train_analysis':
        document.title = 'Wabtec - TAT';
        break;

      case 'asset_research':
        document.title = 'Wabtec - Asset Research';
        break;

      case 'virtual_tester':
        document.title = 'Wabtec - Virtual Tester';
        break;

      case 'asset_view':
        document.title = 'Wabtec - Power Tool (Asset)';
        break;

      case 'datapack_viewer':
        document.title = 'Wabtec - Datapack Viewer';
        break;

      case 'datascreen':
        document.title = 'Wabtec - Datascreen';
        break;

      case 'rule_tester':
        document.title = 'Wabtec - Rule Tester';
        break;

      case 'springboard':
        document.title = 'Wabtec - Loco Events';
        break;

      case 'tokenizer':
        document.title = 'Wabtec - Token Info';
        break;

      case 'sockets':
        document.title = 'Wabtec - WS Testbench';
        break;

      default:
        break;
    }
  }, [applicationContext]);

  return (
    <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
      <StyledThemeProvider
        theme={theme === 'light' ? PowerToolLightTheme : PowerToolDarkTheme}
      >
        <SnackbarProvider
          anchorOrigin={{
            horizontal: 'center',
            vertical: 'top',
          }}
          action={(snackbarKey) => (
            <SnackbarCloseButton snackbarKey={snackbarKey} />
          )}
          maxSnack={3}
          autoHideDuration={3000}
        >
          <PostMessageProvider>
            <URLProvider>
              <OktaTokenInterceptor>
                <GlobalStyles />

                <TopBar />

                <CaseActionMonitor />

                <GridLayout />

                <ReactQueryDevtools initialIsOpen={true} />
              </OktaTokenInterceptor>
            </URLProvider>
          </PostMessageProvider>
        </SnackbarProvider>
      </StyledThemeProvider>
    </ThemeProvider>
  );
};
