import {
  KeyboardArrowDown,
  SsidChart,
  AutoGraph,
  LinearScale,
  Menu as MenuIcon,
  KeyboardArrowUp,
  ScatterPlot,
  InsertCommentOutlined,
  Download,
} from '@mui/icons-material';
import {
  SxProps,
  Button,
  Menu,
  MenuItem,
  Chip,
  Divider,
  MenuProps,
  styled,
} from '@mui/material';
import React, { useState } from 'react';

import { ConfigureAxisModal } from 'common/features/plot/ConfigureAxisModal';
import { AxisConfiguration } from 'common/features/plot/types';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

const StyledMenu = styled((props: MenuProps) => (
  <Menu elevation={1} {...props} />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: PowerToolDarkTheme.neutralDark100,
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
  },
}));

const PlotMenuToggleChip = ({
  state,
  labels,
  changeColor,
}: {
  state: boolean;
  labels?: string[];
  changeColor?: boolean;
}) => {
  return (
    <Chip
      size='small'
      variant='outlined'
      label={labels ? (state ? labels[0] : labels[1]) : state ? 'On' : 'Off'}
      sx={{
        ml: 1.5,
        fontSize: 10,
        '.MuiChip-label': {
          color:
            changeColor !== false
              ? state
                ? PowerToolDarkTheme.statusGreen500
                : PowerToolDarkTheme.statusYellow500
              : PowerToolDarkTheme.statusGreen500,
        },
      }}
    />
  );
};

export const PlotMenu = ({
  continuousOptions,
  downsamplingOptions,
  axisConfigOptions,
  showLinesOptions,
  showAnnotationsOptions,
  exportOptions,
}: {
  continuousOptions: {
    onContinuousClick: (event: React.MouseEvent<HTMLElement>) => void;
    state: boolean;
  };
  downsamplingOptions: {
    onDownsamplingClick: (event: React.MouseEvent<HTMLElement>) => void;
    state: boolean;
    show: boolean;
  };
  axisConfigOptions: {
    onAxisConfigUpdate: (configs: AxisConfiguration[]) => void;
    state: AxisConfiguration[];
  };
  showLinesOptions: {
    onShowLinesChange: (event: React.MouseEvent<HTMLElement>) => void;
    state: boolean;
  };
  showAnnotationsOptions: {
    onShowAnnotationsChange: (event: React.MouseEvent<HTMLElement>) => void;
    state: boolean;
    show: boolean;
  };
  exportOptions: {
    onExportClick: (event: React.MouseEvent<HTMLElement>) => void;
    show: boolean;
  };
}) => {
  const [configureOpen, setConfigureOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const iconProps: SxProps = {
    mr: 1.5,
    // fontSize: 16
  };

  const itemProps: SxProps = {
    fontSize: 14,
  };

  return (
    <>
      <Button
        size='small'
        variant='contained'
        onClick={handleClick}
        endIcon={
          open ? (
            <KeyboardArrowUp fontSize='small' />
          ) : (
            <KeyboardArrowDown fontSize='small' />
          )
        }
      >
        <MenuIcon fontSize='small' />
      </Button>
      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem sx={itemProps} onClick={continuousOptions.onContinuousClick}>
          <LinearScale sx={iconProps} />
          Mode
          <PlotMenuToggleChip
            state={continuousOptions.state}
            labels={['Continuous', 'Time']}
            changeColor={false}
          />
        </MenuItem>

        <MenuItem sx={itemProps} onClick={() => setConfigureOpen(true)}>
          <SsidChart sx={iconProps} />
          Configure Axes
        </MenuItem>

        <MenuItem sx={itemProps} onClick={showLinesOptions.onShowLinesChange}>
          <ScatterPlot sx={iconProps} />
          Lines
          <PlotMenuToggleChip state={showLinesOptions.state} />
        </MenuItem>

        <MenuItem
          sx={itemProps}
          onClick={showAnnotationsOptions.onShowAnnotationsChange}
        >
          <InsertCommentOutlined sx={iconProps} />
          Annotations
          <PlotMenuToggleChip state={showAnnotationsOptions.state} />
        </MenuItem>

        <Divider />

        {downsamplingOptions.show ? (
          <MenuItem
            sx={itemProps}
            onClick={downsamplingOptions.onDownsamplingClick}
          >
            <AutoGraph sx={iconProps} />
            Downsampling
            <PlotMenuToggleChip state={downsamplingOptions.state} />
          </MenuItem>
        ) : (
          <></>
        )}

        {exportOptions.show ? (
          <React.Fragment>
            <Divider />
            <MenuItem sx={itemProps} onClick={exportOptions.onExportClick}>
              <Download sx={iconProps} />
              Export
            </MenuItem>
          </React.Fragment>
        ) : (
          <></>
        )}
      </StyledMenu>
      <Divider />
      <ConfigureAxisModal
        open={configureOpen}
        onClose={() => setConfigureOpen(false)}
        configs={axisConfigOptions.state}
        onUpdate={axisConfigOptions.onAxisConfigUpdate}
      />
    </>
  );
};
