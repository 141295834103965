import { Paper } from '@mui/material';
import React from 'react';

import { useFetchTrain } from 'train-tool/hooks/apiHooks';

import { SparkLineItem } from './SparkLineComponent';
import { TextItem } from './TextItemComponent';
import { useDisplayProps } from './hooks';

/**
 * @returns {JSX.Element} wrapper component for the health check data section of the train status table
 */
export const HealthCheckDataSection = () => {
  const { train } = useFetchTrain();
  const { columnProps, evenColor, oddColor } = useDisplayProps();

  return (
    <React.Fragment>
      {train?.locomotives?.map((loco) => {
        const hc = loco.healthCheckData;

        return (
          <div
            key={loco.vehicleObjid}
            style={{
              ...columnProps,
            }}
          >
            <Paper
              style={{
                backgroundColor: 'rgba(57, 71, 85, 0.50)',
              }}
            >
              <TextItem
                displayProps={hc?.engineControlSwitch}
                backgroundColor={evenColor}
              />
              <TextItem displayProps={hc?.dpMode} backgroundColor={oddColor} />
              <TextItem
                displayProps={hc?.automaticBrakeHandle}
                backgroundColor={evenColor}
              />
              <SparkLineItem
                values={loco?.fuelLevel?.map((item) => item.fuelLevel)}
                displayProps={hc?.fuelLevel}
                backgroundColor={oddColor}
              />
              <TextItem
                displayProps={hc?.airFlow}
                backgroundColor={evenColor}
              />
              <TextItem
                displayProps={hc?.reverserHandle}
                backgroundColor={oddColor}
              />
              <TextItem
                displayProps={hc?.independentBrake}
                divider={false}
                backgroundColor={evenColor}
              />
            </Paper>
          </div>
        );
      }) ?? <></>}
    </React.Fragment>
  );
};
