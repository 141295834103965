/**
 *
 * @param {React.SVGProps<SVGSVGElement>} props additional SVG display props
 * @returns {SVGElement} icon for representing if a locomotive has rad fan shutter cooling
 */
export const ShutterIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width ?? 48}
      height={props.height ?? 24}
      // fill="none"
      viewBox='0 0 503.609 503.609'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g>
        <g>
          <path d='M0,51.726v408.548h512V51.726H0z M481.583,429.857H30.417V82.143h451.166V429.857z' />
        </g>
      </g>
      <g>
        <g>
          <path
            d='M188.279,118.419c-75.862,0-137.58,61.719-137.58,137.581s61.719,137.581,137.58,137.581
			c75.863,0,137.581-61.719,137.581-137.581S264.141,118.419,188.279,118.419z M188.279,363.164
			c-59.09,0-107.163-48.073-107.163-107.164s48.073-107.164,107.163-107.164c59.091,0,107.164,48.073,107.164,107.164
			S247.369,363.164,188.279,363.164z'
          />
        </g>
      </g>
      <g>
        <g>
          <rect x='348.967' y='139.393' width='109.502' height='30.417' />
        </g>
      </g>
      <g>
        <g>
          <rect x='348.967' y='190.098' width='109.502' height='30.417' />
        </g>
      </g>
      <g>
        <g>
          <rect x='348.967' y='240.793' width='109.502' height='30.417' />
        </g>
      </g>
      <g>
        <g>
          <rect x='348.967' y='291.489' width='109.502' height='30.417' />
        </g>
      </g>
      <g>
        <g>
          <rect x='348.967' y='342.194' width='109.502' height='30.417' />
        </g>
      </g>
      <g>
        <g>
          <path
            d='M276.887,269.698c-3.172-11.837-10.763-21.731-21.376-27.858c-7.006-4.045-16.965-7.233-27.232-8.932
			c3.661-9.74,5.88-19.958,5.88-28.05c0-25.298-20.581-45.879-45.879-45.879c-25.298,0-45.879,20.581-45.879,45.879
			c0,8.091,2.218,18.309,5.881,28.05c-10.266,1.699-20.225,4.887-27.231,8.933c-10.614,6.127-18.205,16.021-21.376,27.858
			c-3.173,11.837-1.544,24.201,4.584,34.814c6.127,10.614,16.021,18.205,27.858,21.376c3.95,1.06,7.961,1.584,11.947,1.584
			c7.95,0,15.796-2.086,22.866-6.167c7.006-4.045,14.746-11.075,21.351-19.116c6.605,8.041,14.345,15.072,21.351,19.116
			c7.07,4.082,14.915,6.168,22.866,6.167c3.985,0,7.998-0.524,11.949-1.584c11.837-3.172,21.731-10.764,27.858-21.376
			C278.431,293.899,280.059,281.534,276.887,269.698z M151.719,294.963c-3.576,2.064-7.744,2.615-11.733,1.544
			c-3.99-1.069-7.323-3.628-9.389-7.204c-2.065-3.576-2.613-7.744-1.544-11.734c1.069-3.99,3.627-7.323,7.204-9.389
			c2.458-1.418,6.358-2.894,10.961-4.05c1.803,9.208,6.617,17.384,13.473,23.381C157.394,290.912,154.173,293.547,151.719,294.963z
			 M199.645,257.116c-0.009,0.096-0.026,0.191-0.039,0.286c-0.033,0.276-0.072,0.549-0.126,0.818
			c-0.017,0.09-0.041,0.177-0.06,0.267c-0.063,0.281-0.132,0.559-0.214,0.83c-0.018,0.062-0.04,0.124-0.059,0.185
			c-0.099,0.308-0.209,0.613-0.334,0.909c-0.009,0.021-0.017,0.042-0.026,0.063c-1.151,2.695-3.308,4.859-5.997,6.021
			c-0.061,0.025-0.123,0.051-0.184,0.076c-0.243,0.1-0.492,0.19-0.743,0.273c-0.13,0.043-0.261,0.084-0.392,0.123
			c-0.184,0.054-0.37,0.099-0.557,0.143c-0.187,0.044-0.374,0.087-0.565,0.123c-0.674,0.125-1.367,0.199-2.076,0.199
			c-0.709,0-1.399-0.074-2.073-0.198c-0.194-0.035-0.383-0.079-0.573-0.124c-0.183-0.044-0.366-0.088-0.545-0.141
			c-0.136-0.04-0.27-0.082-0.404-0.126c-0.246-0.082-0.491-0.169-0.73-0.268c-0.066-0.026-0.132-0.054-0.198-0.081
			c-2.685-1.162-4.84-3.324-5.991-6.015c-0.01-0.023-0.019-0.046-0.029-0.069c-0.124-0.295-0.232-0.597-0.332-0.903
			c-0.02-0.063-0.042-0.126-0.061-0.189c-0.082-0.272-0.151-0.549-0.214-0.828c-0.019-0.089-0.043-0.177-0.061-0.268
			c-0.053-0.269-0.091-0.541-0.125-0.816c-0.011-0.096-0.029-0.192-0.039-0.289c-0.036-0.367-0.057-0.738-0.057-1.113
			c0-0.374,0.021-0.742,0.056-1.107c0.009-0.097,0.026-0.194,0.039-0.291c0.033-0.271,0.071-0.539,0.123-0.805
			c0.019-0.101,0.045-0.201,0.067-0.301c0.058-0.26,0.123-0.517,0.199-0.77c0.028-0.095,0.06-0.189,0.09-0.283
			c0.498-1.516,1.308-2.888,2.349-4.051c0.16-0.178,0.318-0.358,0.488-0.525c0.135-0.133,0.28-0.257,0.421-0.382
			c0.151-0.135,0.302-0.271,0.46-0.397c0.153-0.123,0.311-0.237,0.47-0.352c0.16-0.116,0.322-0.229,0.488-0.337
			c0.162-0.104,0.326-0.204,0.494-0.3c0.177-0.102,0.358-0.199,0.541-0.292c0.164-0.083,0.329-0.164,0.497-0.239
			c0.202-0.09,0.408-0.17,0.613-0.248c0.159-0.06,0.316-0.123,0.479-0.175c0.24-0.079,0.477-0.141,0.713-0.202
			c0.146-0.037,0.291-0.081,0.439-0.114c0.272-0.059,0.549-0.099,0.825-0.138c0.122-0.017,0.24-0.043,0.363-0.056
			c0.404-0.043,0.81-0.067,1.221-0.067c0.411,0,0.817,0.024,1.221,0.067c0.125,0.013,0.244,0.04,0.368,0.057
			c0.275,0.039,0.549,0.079,0.82,0.137c0.151,0.032,0.298,0.077,0.447,0.116c0.233,0.061,0.466,0.122,0.696,0.197
			c0.165,0.054,0.328,0.118,0.489,0.18c0.203,0.077,0.404,0.155,0.601,0.243c0.172,0.077,0.342,0.16,0.51,0.245
			c0.177,0.09,0.353,0.183,0.525,0.283c0.172,0.099,0.343,0.202,0.509,0.309c0.159,0.103,0.314,0.212,0.468,0.323
			c0.165,0.119,0.331,0.238,0.489,0.366c0.148,0.12,0.29,0.247,0.433,0.375c0.15,0.134,0.303,0.265,0.446,0.406
			c0.151,0.149,0.292,0.31,0.435,0.468c1.068,1.179,1.897,2.574,2.402,4.12c0.027,0.083,0.055,0.166,0.08,0.25
			c0.079,0.263,0.146,0.529,0.206,0.799c0.02,0.093,0.044,0.185,0.062,0.279c0.053,0.27,0.092,0.544,0.126,0.82
			c0.011,0.093,0.028,0.185,0.036,0.28c0.035,0.365,0.056,0.735,0.056,1.109C199.702,256.378,199.681,256.749,199.645,257.116z
			 M201.767,216.379c-0.302-0.102-0.608-0.194-0.913-0.29c-0.08-0.025-0.161-0.052-0.241-0.077c-0.588-0.18-1.18-0.351-1.776-0.506
			c-0.048-0.012-0.095-0.026-0.143-0.04c-0.649-0.166-1.301-0.318-1.959-0.453c-0.074-0.015-0.148-0.026-0.222-0.042
			c-0.55-0.109-1.103-0.209-1.659-0.297c-0.209-0.033-0.42-0.061-0.63-0.091c-0.434-0.062-0.869-0.119-1.306-0.166
			c-0.233-0.025-0.466-0.051-0.701-0.072c-0.446-0.042-0.893-0.075-1.341-0.102c-0.209-0.013-0.417-0.028-0.626-0.039
			c-0.655-0.03-1.312-0.05-1.971-0.05s-1.316,0.019-1.971,0.05c-0.21,0.01-0.418,0.025-0.627,0.039
			c-0.448,0.027-0.894,0.061-1.34,0.102c-0.235,0.021-0.468,0.047-0.703,0.072c-0.436,0.049-0.87,0.104-1.302,0.166
			c-0.212,0.03-0.424,0.059-0.634,0.092c-0.552,0.087-1.101,0.186-1.647,0.295c-0.078,0.015-0.157,0.027-0.234,0.044
			c-0.656,0.135-1.307,0.286-1.954,0.452c-0.052,0.013-0.102,0.028-0.155,0.043c-0.593,0.154-1.181,0.322-1.765,0.503
			c-0.082,0.025-0.163,0.052-0.245,0.078c-0.304,0.095-0.609,0.187-0.911,0.289c-1.3-4.565-1.974-8.682-1.974-11.521
			c-0.002-8.526,6.934-15.462,15.46-15.462c8.526,0,15.462,6.936,15.462,15.462C203.741,207.695,203.066,211.814,201.767,216.379z
			 M245.961,289.303c-2.065,3.576-5.399,6.135-9.389,7.204c-3.991,1.07-8.157,0.52-11.733-1.544
			c-2.454-1.416-5.675-4.052-8.972-7.451c6.855-5.996,11.67-14.173,13.472-23.381c4.602,1.157,8.505,2.632,10.962,4.051
			c3.577,2.065,6.136,5.4,7.204,9.389C248.575,281.56,248.026,285.726,245.961,289.303z'
          />
        </g>
      </g>
    </svg>
  );
};
