// for use when rendering many tooltips
import { TooltipProps, Tooltip } from '@mui/material';
import { useState } from 'react';

// https://github.com/mui/material-ui/issues/27057#issuecomment-984722974
export const LazyTooltip = ({ children, ...rest }: TooltipProps) => {
  const [renderTooltip, setRenderTooltip] = useState(false);

  return (
    <div
      onMouseEnter={() => !renderTooltip && setRenderTooltip(true)}
      className='display-contents'
    >
      {!renderTooltip && children}
      {renderTooltip && <Tooltip {...rest}>{children}</Tooltip>}
    </div>
  );
};
