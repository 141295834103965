import axios from 'axios';
import { v4 as uuid } from 'uuid';

import { Asset } from 'common/types/types';

import {
  CallEvent,
  CaseEvent,
  IncidentEvent,
  FaultEvent,
  MaterialUsageEvent,
  RXEvent,
  ShopEvent,
  DefectEvent,
  ProgramEvent,
} from 'loco-history/types';

/**
 *
 * @param {Asset} asset asset to get material usage for
 * @returns {Promise<MaterialUsageEvent[]>} list of material usage events for a given asset
 */
export const getMaterialUsage = (
  asset?: Asset
): Promise<MaterialUsageEvent[]> => {
  return axios
    .post<MaterialUsageEvent[]>('/query/material_usage', {
      cacheable: true,
      parameters: {
        1: asset?.vehicleHdr,
        2: asset?.roadNumber,
      },
    })
    .then((response) =>
      response.data.map((event) => ({
        ...event,
        id: uuid(),
        timestamp: event.usageDate,
        type: 'material',
      }))
    );
};

/**
 *
 * @param {Asset} asset asset to get shop events for
 * @returns {Promise<ShopEvent[]>} list of shoppings for a given asset
 */
export const getShopEvents = (asset?: Asset): Promise<ShopEvent[]> => {
  return axios
    .post<ShopEvent[]>('/query/shoppings', {
      cacheable: true,
      parameters: {
        1: asset?.vehicleObjid,
      },
    })
    .then((response) =>
      response.data.map((event) => ({
        ...event,
        id: uuid(),
        timestamp: event.inshopDate,
        type: 'shop',
      }))
    );
};

/**
 *
 * @param {Asset} asset asset to get fault events for
 * @returns {Promise<FaultEvent[]>} list of faults for a given asset
 */
export const getFaultEvents = (asset?: Asset): Promise<FaultEvent[]> => {
  return axios
    .post<FaultEvent[]>('/query/fault_history', {
      dataSource: 'DWH',
      cacheable: true,
      parameters: {
        1: asset?.vehicleObjid,
      },
    })
    .then((response) =>
      response.data.map((event) => ({
        ...event,
        id: uuid(),
        timestamp: event.occurDate,
        type: 'fault',
      }))
    );
};

/**
 *
 * @param {Asset} asset asset to get customer incidents events for
 * @returns {Promise<IncidentEvent[]>} list of customer b2b incidents for a given asset
 */
export const getIncidentEvents = (asset?: Asset): Promise<IncidentEvent[]> => {
  return axios
    .post<IncidentEvent[]>('/query/incidents', {
      cacheable: true,
      parameters: {
        1: asset?.vehicleObjid,
      },
    })
    .then((response) =>
      response.data.map((event) => ({
        ...event,
        id: uuid(),
        timestamp: event.incidentDate,
        type: 'incident',
      }))
    );
};

/**
 *
 * @param {Asset} asset asset to get cases for
 * @returns {Promise<CaseEvent[]>} list of cases for a given asset
 */
export const getCaseEvents = (asset?: Asset): Promise<CaseEvent[]> => {
  return axios
    .post<CaseEvent[]>('/query/cases_for_vehicle', {
      parameters: {
        1: asset?.vehicleObjid,
        2: 720,
      },
    })
    .then((response) =>
      response.data.map((event) => ({
        ...event,
        id: uuid(),
        timestamp: event.creationDate,
        // type: 'case',
      }))
    );
};

/**
 *
 * @param {Asset} asset asset to get rxs for
 * @returns {Promise<RXEvent[]>} list of rxs for a given asset
 */
export const getRxEvents = (asset?: Asset): Promise<RXEvent[]> => {
  return axios
    .post<RXEvent[]>('/query/rxs', {
      parameters: {
        1: asset?.vehicleObjid,
      },
    })
    .then((response) =>
      response.data.map((event) => ({
        ...event,
        id: uuid(),
        timestamp: event.rxOpenDate,
        type: 'rx',
      }))
    );
};

/**
 *
 * @param {Asset} asset asset to get call logs for
 * @returns {Promise<CallEvent[]>} list of call logs for a given asset
 */
export const getCallEvents = (asset?: Asset): Promise<CallEvent[]> => {
  return axios
    .post<CallEvent[]>('/query/calls', {
      parameters: {
        1: asset?.vehicleObjid,
      },
    })
    .then((response) =>
      response.data.map((event) => ({
        ...event,
        id: uuid(),
        timestamp: event.creationDate,
        type: 'call',
      }))
    );
};

/**
 *
 * @param {Asset} asset asset to get eservice defects for
 * @returns {Promise<DefectEvent[]>} list of eservice defects for a given asset
 */
export const getDefectEvents = (asset?: Asset): Promise<DefectEvent[]> => {
  return axios
    .post<DefectEvent[]>('/query/defects', {
      parameters: {
        1: asset?.vehicleObjid,
        2: asset?.vehicleObjid,
      },
    })
    .then((response) =>
      response.data.map((event) => ({
        ...event,
        id: uuid(),
        timestamp: event.lastUpdateDate,
        type: 'defect',
      }))
    );
};

/**
 *
 * @param {Asset} asset asset to get eservice programs for
 * @returns {Promise<ProgramEvent[]>} list of eservice FMIs/programs for a given asset
 */
export const getProgramEvents = (asset?: Asset): Promise<ProgramEvent[]> => {
  return axios
    .post<ProgramEvent[]>('/query/programs', {
      parameters: {
        1: asset?.vehicleObjid,
      },
    })
    .then((response) =>
      response.data.map((event) => ({
        ...event,
        id: uuid(),
        timestamp: event.completionDate,
        type: 'program',
      }))
    );
};
