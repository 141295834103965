import { ICellRendererParams } from 'ag-grid-community';
import { useMemo } from 'react';
import { HiOutlineChevronDoubleRight } from 'react-icons/hi';

import { DatapackViewerIcon } from 'common/components/ag-grid/DatapackViewerIcon';
import { LazyTooltip } from 'common/components/general/LazyTooltip';
import { useFetchDatapacksForFaults } from 'common/features/datapack/hooks';

export function FaultDescriptionCell(params: ICellRendererParams) {
  const { datapack2FaultMap } = useFetchDatapacksForFaults();

  const hasDatapack = useMemo(() => {
    return (
      datapack2FaultMap !== undefined &&
      datapack2FaultMap.get(params.data.OBJID) !== undefined
    );
  }, [datapack2FaultMap]);

  const DatapackIcon = useMemo(() => {
    if (hasDatapack) {
      return (
        <DatapackViewerIcon
          faultObjId={params.data.OBJID}
          template={datapack2FaultMap.get(params.data.OBJID)}
        />
      );
    }

    return <></>;
  }, [datapack2FaultMap]);

  if (params.data.RECORD_TYPE !== 'HC' && params.data.OPERATOR_MESSAGE) {
    if (
      !params.data.OPERATOR_MESSAGE.toLowerCase().includes('ready') &&
      !params.data.FAULT_RESET_DATE
    ) {
      return (
        <LazyTooltip
          title={
            <div style={{ fontSize: '12px' }}>
              Active fault with restriction
            </div>
          }
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              whiteSpace: 'nowrap',
              width: 'max-content',
            }}
          >
            <HiOutlineChevronDoubleRight
              data-testid='HiOutlineChevronDoubleRight'
              style={{ fontWeight: 1000, fontSize: 13, paddingRight: '3px' }}
            />
            {DatapackIcon}
            {`  ${params.value}`}
          </div>
        </LazyTooltip>
      );
    }
  }

  return params.value ? (
    <div style={{ width: '100%' }}>
      {DatapackIcon}
      <span>{params.value}</span>
    </div>
  ) : (
    <></>
  );
}
