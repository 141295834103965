import { Switch } from '@mui/material';
import { useState, useEffect } from 'react';

import { useIsCaseLevelHook } from 'common/hooks';

export type FromCaseCreationProps = {
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  checked: boolean;
};
export const FromCaseCreationSwitch = (props: FromCaseCreationProps) => {
  const { isCaseLevel } = useIsCaseLevelHook();
  const [showCaseRange, setShowCaseRange] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(props.checked);

  useEffect(() => {
    setChecked(props.checked);
  }, [props.checked]);
  useEffect(() => {
    setShowCaseRange(isCaseLevel());
  }, [isCaseLevel]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    props.handleChange(event, checked);
  };

  if (showCaseRange) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginRight: '20px',
          marginTop: '2px',
        }}
      >
        <Switch size='small' checked={checked} onChange={handleChange} />
        <span style={{ fontSize: '12px', whiteSpace: 'nowrap' }}>
          From Case Creation
        </span>
      </div>
    );
  }
  return <div />;
};

export const DatePicker = () => {};
