import { Chip } from '@mui/material';
import { useCallback, useMemo } from 'react';

import { useAppDispatch } from 'common/hooks';
import { getRoadNumber } from 'common/util/utils';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

import { setAssetAction } from 'power-tool/saga/actionSlice';

import { TrainAsset } from 'train-tool/types';

import { BadgedTrainIcon, HealthScoreBadgeValue } from './BadgedTrainIcon';

/**
 *
 * @param {any} props implicit destructured props
 * @param {TrainAsset} props.loco locomotive to create the display component for
 * @param {'positional' | 'header' | 'placeholder'} props.displayMode mode that controls what type of display is shown
 * @param {JSX.Element?} props.additionalDisplayComponent any additional component(s) that should be added to the display
 * @returns {JSX.Element} locomotive display component representing: position, status, health score, and other information
 */
export const LocoDisplay = ({
  loco,
  displayMode,
  additionalDisplayComponent,
}: {
  loco: TrainAsset;
  displayMode: 'positional' | 'header' | 'placeholder';
  additionalDisplayComponent?: JSX.Element;
}) => {
  const dispatch = useAppDispatch();

  const selectLoco = useCallback(() => {
    dispatch(setAssetAction({ ...loco }));
  }, [loco, dispatch]);

  const badgeColor = useMemo(() => {
    if (displayMode === 'placeholder' || loco.foreign) {
      return 'inherit';
    }

    switch (loco.health?.risk) {
      case 'Very Low':
        return PowerToolDarkTheme.statusGreen500;

      case 'Low':
        return PowerToolDarkTheme.statusYellow500;

      case 'Med':
        return PowerToolDarkTheme.statusOrange500;

      case 'High':
        return PowerToolDarkTheme.statusRed600;

      case 'No Comm':
        return '#2979ff';

      case 'unknown':
      default:
        return PowerToolDarkTheme.statusGray500;
    }
  }, [loco.health, displayMode, loco.foreign]);

  const isDisabled = useMemo(() => {
    return loco.foreign || displayMode === 'placeholder';
  }, [loco.foreign, displayMode]);

  const label = useMemo(() => {
    return (
      <Chip
        onClick={isDisabled ? undefined : selectLoco}
        sx={{
          cursor: isDisabled ? 'not-allowed' : 'pointer',
          opacity: isDisabled ? 0.4 : 1,
          mt: 1,
          fontSize: '11px',
          width: '75px',
        }}
        size='small'
        label={
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontWeight: 500,
              fontSize: '10px',
            }}
          >
            {getRoadNumber(loco)}
          </div>
        }
      />
    );
  }, [loco, isDisabled, selectLoco]);

  const badgeVariant = useMemo(() => {
    if (loco.health?.health === undefined || loco.health.health < 0) {
      return 'dot';
    } else if (displayMode === 'header') {
      return 'standard';
    }

    return 'dot';
  }, [loco.health, displayMode]);

  const locoLineLength = '28px';
  const locoLineHeight = '3px';

  return (
    <div
      id='loco-display-group'
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        id='loco-drawing-wrapper'
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {/* leading loco line */}
          <div
            id='loco-leading-line'
            style={{
              width: locoLineLength,
              height: locoLineHeight,
              backgroundColor: PowerToolDarkTheme.dataYellow600,
              borderTopRightRadius: '2px',
              borderBottomRightRadius: '2px',
              opacity:
                displayMode === 'positional' &&
                (loco.displayProps.lineType === 'leading' ||
                  loco.displayProps.lineType === 'both')
                  ? 1
                  : 0,
            }}
          />
        </div>

        {/* badge (color dot) */}
        <BadgedTrainIcon
          leadRemote={loco.displayProps.type === 'lead-remote'}
          iconTooltip={loco.foreign ? <span>Not Available</span> : undefined}
          size={'small'}
          value={<HealthScoreBadgeValue asset={loco} />}
          disabled={isDisabled}
          badgeColor={badgeColor}
          onClick={selectLoco}
          variant={badgeVariant}
          color={
            loco.displayProps.type === 'lead'
              ? PowerToolDarkTheme.voltBlue300
              : undefined
          }
        />

        {/* trailing loco line */}
        <div
          id='loco-trailing-line'
          style={{
            width: locoLineLength,
            height: locoLineHeight,
            backgroundColor: PowerToolDarkTheme.dataYellow600,
            borderTopLeftRadius: '2px',
            borderBottomLeftRadius: '2px',
            opacity:
              displayMode === 'positional' &&
              (loco.displayProps.lineType === 'trailing' ||
                loco.displayProps.lineType === 'both')
                ? 1
                : 0,
          }}
        />
      </div>

      {label}
      {additionalDisplayComponent}
    </div>
  );
};
