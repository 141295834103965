import { Paper, Typography, Divider } from '@mui/material';

/**
 *
 * @param {any} props implicit component props
 * @param {string} props.label display label; generally road number
 * @param {JSX.Element[]} props.alerts alerts to display
 * @returns {JSX.Element} wrapper component for an arbitrary number or type of alerts
 */
export const AlertOutput = (props: {
  label: string;
  alerts: JSX.Element[];
}) => {
  return (
    <Paper
      sx={{
        p: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
        overflow: 'auto',
        alignItems: 'flex-start',
        backgroundColor: '#172839',
      }}
    >
      <Typography sx={{ ml: 2 }} variant={'button'}>
        {props.label}
      </Typography>

      <Divider />

      {props.alerts}
    </Paper>
  );
};
