import {
  PictureAsPdfTwoTone,
  TextSnippet,
  OpenInFull,
  CloseOutlined,
  DataObject,
} from '@mui/icons-material';
import {
  CardContent,
  Box,
  ImageList,
  Zoom,
  ImageListItem,
  IconButton,
  ImageListItemBar,
  Dialog,
  DialogTitle,
  DialogContent,
  Fade,
  Card,
  Checkbox,
  Button,
} from '@mui/material';
import { useState, useEffect, useCallback } from 'react';
import {
  SiMicrosoftpowerpoint,
  SiMicrosoftexcel,
  SiMicrosoftword,
} from 'react-icons/si';

import { file2Base64 } from 'common/util/utils';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

import {
  AttachmentPreviewListProps,
  DeliveryAttachmentProps,
  AttachmentPreviewProps,
  PreviewListItemProps,
  AttachButtonProps,
  ViewPlotProps,
} from 'power-tool/tool-dispo/types';

import { UploadFileComponent } from './UploadFileComponent';

export const AttachmentPreviewList = (props: AttachmentPreviewListProps) => {
  const [attachments, setAttachments] = useState<DeliveryAttachmentProps[]>(
    props.attachments
  );

  useEffect(() => {
    setAttachments(props.attachments);
  }, [props.attachments]);

  useEffect(() => {
    if (props.onUpdate && attachments) {
      props.onUpdate(attachments);
    }
  }, [attachments]);

  const onFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    // if the file picker successfully gave us a file, get it
    const file =
      event.target.files && event.target.files.length > 0
        ? event.target.files[0]
        : undefined;

    // make sure we got a file, and if we do create a temporary URL for the file
    // so that we can preview the image without uploading it
    // we need to turn the file into a base64 string, because thats how the backend expects all attachments
    if (file) {
      file2Base64(file).then((base64) => {
        setAttachments([
          ...(attachments ?? []),
          {
            label: file.name,
            src: base64,
            isAttached: true,
            id: (attachments.length + 1).toString(),
          },
        ]);
      });
    }
  };

  const errorHandler = (reason: 'size-limit' | 'unknown' | 'bad-type') => {
    if (props.onError) {
      props.onError(reason);
    }
  };

  const onFileAttach = (
    attachment: DeliveryAttachmentProps,
    attached: boolean
  ) => {
    setAttachments(
      attachments.map((a) => {
        if (a.id === attachment.id) {
          return {
            ...a,
            isAttached: attached,
          };
        }

        return a;
      })
    );
  };

  return (
    <div>
      <UploadFileComponent
        fabProps={{
          hidden: attachments.length > 0,
        }}
        errorHandler={errorHandler}
        handlefileupload={onFileUpload}
      />
      <Fade in={attachments.length > 0}>
        <Card
          hidden={attachments.length <= 0}
          variant='outlined'
          sx={{ backgroundColor: PowerToolDarkTheme.main }}
        >
          <CardContent
            style={{
              display: 'flex',
              flexDirection: 'column',
              paddingBottom: 0,
            }}
          >
            <Box
              sx={{
                height: '125px',
                overflowY: 'auto',
              }}
            >
              <ImageList cols={4} gap={16} sx={{ mt: '2px', mb: 0, mx: 2 }}>
                {attachments.map((img, idx) => (
                  <AttachmentPreview
                    img={img}
                    key={idx}
                    onAttach={onFileAttach}
                  />
                ))}
              </ImageList>

              <UploadFileComponent
                fabProps={{
                  hidden: attachments.length <= 0,
                }}
                errorHandler={errorHandler}
                handlefileupload={onFileUpload}
              />
            </Box>
          </CardContent>
        </Card>
      </Fade>
    </div>
  );
};

const AttachmentPreview = (props: AttachmentPreviewProps) => {
  if (props.img.src.includes('.pdf') || props.img.label.includes('.pdf')) {
    return (
      <PreviewListItem
        onAttach={props.onAttach}
        icon='pdf'
        attachmentProps={props.img}
      />
    );
  } else if (
    props.img.src.includes('.xls') ||
    props.img.label.includes('.xls')
  ) {
    return (
      <PreviewListItem
        onAttach={props.onAttach}
        icon='xlsx'
        attachmentProps={props.img}
      />
    );
  } else if (
    props.img.src.includes('.ppt') ||
    props.img.label.includes('.ppt')
  ) {
    return (
      <PreviewListItem
        onAttach={props.onAttach}
        icon='pptx'
        attachmentProps={props.img}
      />
    );
  } else if (
    props.img.src.includes('.txt') ||
    props.img.label.includes('.txt')
  ) {
    return (
      <PreviewListItem
        onAttach={props.onAttach}
        icon='txt'
        attachmentProps={props.img}
      />
    );
  } else if (
    props.img.src.includes('.doc') ||
    props.img.label.includes('.doc')
  ) {
    return (
      <PreviewListItem
        onAttach={props.onAttach}
        icon='docx'
        attachmentProps={props.img}
      />
    );
  } else if (
    props.img.src.includes('.drs') ||
    props.img.label.includes('.drs')
  ) {
    return (
      <PreviewListItem
        onAttach={props.onAttach}
        icon='drs'
        attachmentProps={props.img}
      />
    );
  }

  return (
    <PreviewListItem attachmentProps={props.img} onAttach={props.onAttach} />
  );
};

const PreviewListItem = (props: PreviewListItemProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const onClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    setIsExpanded(false);
  };

  const previewRenderer = useCallback(() => {
    const style = {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '64px',
    };

    switch (props.icon) {
      case 'pdf':
        return <PictureAsPdfTwoTone sx={style} />;

      case 'pptx':
        return <SiMicrosoftpowerpoint style={style} />;

      case 'txt':
        return <TextSnippet sx={style} />;

      case 'xlsx':
        return <SiMicrosoftexcel style={style} />;

      case 'docx':
        return <SiMicrosoftword style={style} />;

      case 'drs':
        return <DataObject style={style} />;

      case undefined:
        return (
          <img
            style={{ maxHeight: '100px' }}
            src={props.attachmentProps.src}
            alt={props.attachmentProps.label}
            loading='lazy'
          />
        );
    }
  }, [props.icon, props.attachmentProps.label, props.attachmentProps.src]);

  return (
    <Zoom in={true}>
      <ImageListItem
        key={props.attachmentProps.src}
        sx={{
          pt: 0,
          lineHeight: 1.5,
          maxHeight: '100px',
          width: '100%',
          minHeight: '100px',
        }}
      >
        <IconButton
          onClick={() => setIsExpanded(true)}
          size='small'
          style={{
            position: 'absolute',
            right: 0,
            color: PowerToolDarkTheme.secondary,
          }}
        >
          <OpenInFull fontSize='small' />
        </IconButton>

        {previewRenderer()}

        <ImageListItemBar
          title={props.attachmentProps.label.split('.')[0]}
          actionIcon={
            <AttachPlotButton
              onAttach={props.onAttach}
              {...props.attachmentProps}
            />
          }
        />

        <ViewPlot
          img={props.attachmentProps}
          isOpen={isExpanded}
          onClose={onClose}
        />
      </ImageListItem>
    </Zoom>
  );
};

const AttachPlotButton = (props: AttachButtonProps) => {
  const [isAttached, setAttached] = useState<boolean>(props.isAttached);

  useEffect(() => {
    setAttached(props.isAttached);
  }, [props.isAttached]);

  return (
    <Checkbox
      color='info'
      checked={isAttached}
      onChange={(event, checked) => props.onAttach(props, checked)}
    />
  );
};

export const ViewPlot = (props: ViewPlotProps) => {
  const [open, setOpen] = useState<boolean>(props.isOpen);

  useEffect(() => {
    setOpen(props.isOpen);
  }, [props.isOpen]);

  const onClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    setOpen(false);
    props.onClose(event, reason);
  };

  return (
    <Dialog
      PaperProps={{
        style: {
          backgroundColor: PowerToolDarkTheme.main,
          backgroundImage: 'unset',
          width: 'auto',
          maxWidth: 'unset',
        },
      }}
      open={open}
      onClose={onClose}
    >
      <DialogTitle>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            sx={{
              alignSelf: 'flex-start',
              minWidth: 'unset',
              padding: '4px',
            }}
            onClick={() => onClose({}, 'backdropClick')}
          >
            <CloseOutlined />
          </Button>
        </div>
      </DialogTitle>

      <DialogContent>
        <Zoom in={true} style={{ transitionDelay: '100ms' }}>
          <img src={props.img.src} alt={props.img.label} loading='lazy' />
        </Zoom>
      </DialogContent>
    </Dialog>
  );
};
