import {
  Error,
  Factory,
  Handyman,
  LocalPharmacy,
  LocalPhone,
  Report,
  BuildCircle,
  RateReview,
  Terminal,
} from '@mui/icons-material';

import {
  TimelineEventType,
  EventDisplayProps,
} from 'common/features/timeline/types';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

export const TimelineEventToPropsMap: Record<
  TimelineEventType,
  EventDisplayProps
> = {
  // case: {
  //   label: 'Case',
  //   icon: <MedicalServices fontSize='small' />,
  //   style: { backgroundColor: PowerToolDarkTheme.statusRed700 },
  // },
  incident: {
    label: 'Incident',
    icon: <Report fontSize='small' />,
    style: { backgroundColor: PowerToolDarkTheme.dataGreen500 },
  },
  material: {
    label: 'Material',
    icon: <Handyman fontSize='small' />,
    style: { backgroundColor: PowerToolDarkTheme.dataOrange500 },
  },
  call: {
    label: 'Call',
    icon: <LocalPhone fontSize='small' />,
    style: { backgroundColor: PowerToolDarkTheme.statusGray200 },
  },
  fault: {
    label: 'Fault',
    icon: <Error fontSize='small' />,
    style: { backgroundColor: PowerToolDarkTheme.dataTeal400 },
  },
  rx: {
    label: 'RX',
    icon: <LocalPharmacy fontSize='small' />,
    style: { backgroundColor: PowerToolDarkTheme.dataPurple500 },
  },
  shop: {
    label: 'Shop',
    icon: <Factory fontSize='small' />,
    style: { backgroundColor: PowerToolDarkTheme.dataPink500 },
  },
  defect: {
    label: 'Defect',
    icon: <BuildCircle fontSize='small' />,
    style: { backgroundColor: PowerToolDarkTheme.statusYellow500 },
  },
  program: {
    label: 'FMI',
    icon: <Terminal fontSize='small' />,
    style: { backgroundColor: PowerToolDarkTheme.statusRed300 },
  },
  'write-in': {
    label: 'Write-In',
    icon: <RateReview fontSize='small' />,
    style: { backgroundColor: PowerToolDarkTheme.statusGreen500 },
    hideFromGrid: true,
  },
};
