import { IDetailCellRendererParams } from 'ag-grid-community';
import { CSSProperties, useMemo } from 'react';

import { TextChip } from 'common/components/TextChip';

export const CaseConditionComponent = (props: {
  condition: string;
  style?: CSSProperties;
}) => {
  const chip = useMemo(() => {
    switch (props.condition) {
      case 'Closed':
        return <TextChip label='Closed' style={props.style} />;

      case 'Open':
      case 'Pending':
      case 'Open-Dispatch':
      case 'Open-Pending-Dispatch':
      case '':
      default:
        return <TextChip label='Open' style={props.style} />;
    }
  }, [props.condition, props.style]);

  return (
    // <Tooltip title={props.value}>
    chip
    // </Tooltip>
  );
};

export const CaseConditionRenderer = (props: IDetailCellRendererParams) => {
  return (
    <CaseConditionComponent condition={props.value} style={{ width: '100%' }} />
  );
};
