import { Stack, Divider, Typography } from '@mui/material';
import React from 'react';

import { getFAMLink } from 'common/components/ag-grid/cell-renderers/FaultCodeCell';
import { StyledLink } from 'common/components/general/StyledLink';
import { useUserFormattedTime } from 'common/hooks';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

import { ViewRelatedDataComponent } from 'train-tool/cards/Analysis/components/ViewRelatedDataComponent';
import { ActiveFault } from 'train-tool/types';

/**
 *
 * @param {any} props implicit destructured props
 * @param {ActiveFault} props.fault active fault details
 * @returns {JSX.Element} expanded detail view for an active fault
 */
export const FaultDetailView = ({ fault }: { fault: ActiveFault }) => {
  return (
    <React.Fragment>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems='center'
      >
        <Stack
          direction={'row'}
          alignItems='center'
          spacing={1}
          sx={{ ml: 2, color: PowerToolDarkTheme.neutralDark800 }}
          divider={<Divider orientation='vertical' flexItem />}
        >
          <React.Fragment>
            <Typography variant='button'>{'Date:'}</Typography>
            <Typography variant='body2' sx={{ ml: 1 }}>
              {useUserFormattedTime(fault.occurDate)}
            </Typography>
          </React.Fragment>

          <Typography variant='button'>
            <StyledLink
              target='_blank'
              href={getFAMLink({
                FAULT_ORIGIN: 'ACCCA',
                FAULT_CODE: fault.faultCode,
              })}
            >
              Fault Manual
            </StyledLink>
          </Typography>
        </Stack>
        <ViewRelatedDataComponent />
      </Stack>

      <Stack direction={'column'} sx={{ ml: 1 }}>
        <Stack direction={'row'} alignItems='center' spacing={1}>
          <Typography variant='button'>{'Message: '}</Typography>

          {/* <Error fontSize={'small'} sx={{color: PowerToolDarkTheme.statusOrange400}}/> */}
          <Typography variant='body2'>{fault.operatorMessage}</Typography>
        </Stack>
      </Stack>
    </React.Fragment>
  );
};
