import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DayRangeChoices, DateSourceChoices } from 'common/cards/Data/types';
import { RootState } from 'common/store';
import { DateRange } from 'common/types/types';

export type DateRangeSlice = {
  dayRange: (typeof DayRangeChoices)[number];
  selectedDateRange?: DateRange;
  dateSource: (typeof DateSourceChoices)[number];
};

const initialState: DateRangeSlice = {
  dayRange: '7',
  dateSource: 'occur_date',
};

export const dateRangeSlice = createSlice({
  name: 'dateRangeSlice',
  initialState,
  reducers: {
    setDateRange: (state, action: PayloadAction<DateRange | undefined>) => {
      state.selectedDateRange = action.payload;
    },
    setDayRange: (
      state,
      action: PayloadAction<(typeof DayRangeChoices)[number]>
    ) => {
      state.dayRange = action.payload;
    },
    setDateSource: (
      state,
      action: PayloadAction<(typeof DateSourceChoices)[number]>
    ) => {
      state.dateSource = action.payload;
    },
  },
});

export const { setDayRange, setDateRange, setDateSource } =
  dateRangeSlice.actions;

export const selectDayRange = (state: RootState) => state.dateRange.dayRange;
export const selectDateRange = (state: RootState) =>
  state.dateRange.selectedDateRange;
export const selectDateSource = (state: RootState) =>
  state.dateRange.dateSource;
export default dateRangeSlice.reducer;
