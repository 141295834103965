import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  IngestedData,
  IngestedDataColumn,
  IngestedDataFooter,
  IngestedDataStatus,
} from 'common/features/ingested-data/types';
import { RootState } from 'common/store';

export type IngestedDataState = {
  data?: IngestedData[];
  columns?: IngestedDataColumn[];
  status: IngestedDataStatus;
  footer?: IngestedDataFooter;
};

const initialState: IngestedDataState = {
  status: 'empty',
};

export const ingestedDataSlice = createSlice({
  name: 'ingestedData',
  initialState,
  reducers: {
    addData: (state, action: PayloadAction<IngestedData[]>) => {
      state.data = [...(state.data ?? []), ...action.payload];
    },
    setData: (state, action: PayloadAction<IngestedData[]>) => {
      state.data = action.payload;
    },
    setColumns: (state, action: PayloadAction<IngestedDataColumn[]>) => {
      state.columns = action.payload;
    },
    setStatus: (state, action: PayloadAction<IngestedDataStatus>) => {
      state.status = action.payload;
    },
    setFooter: (state, action: PayloadAction<IngestedDataFooter>) => {
      state.footer = action.payload;
    },
  },
});

export const {
  addData: addIngestedData,
  setData: setIngestedData,
  setColumns: setIngestedDataColumns,
  setStatus: setIngestedDataStatus,
  setFooter: setIngestedDataFooter,
} = ingestedDataSlice.actions;

export const selectIngestedData = (state: RootState) => state.ingestedData.data;
export const selectIngestedDataColumns = (state: RootState) =>
  state.ingestedData.columns;
export const selectIngestedDataStatus = (state: RootState) =>
  state.ingestedData.status;
export const selectIngestedDataFooter = (state: RootState) =>
  state.ingestedData.footer;
export default ingestedDataSlice.reducer;
