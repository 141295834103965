/**
 *
 * @param {React.SVGProps<SVGSVGElement>} props additional SVG display props
 * @returns {SVGElement} icon for CN customer
 */
export const CNLogo = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width ?? 96}
      height={props.height ?? 48}
      clipRule='evenodd'
      fillRule='evenodd'
      viewBox='0 0 560 400'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='m241.081 148.918h-116.757c-13.344 0-24.324 10.981-24.324 24.325v68.108c0 13.344 10.98 24.324 24.324 24.324h136.217c13.343 0 24.324-10.98 24.324-24.324v-68.109c0-13.344 10.98-24.325 24.324-24.325 6.904 0 13.491 2.939 18.102 8.077l90.283 100.605c4.611 5.138 11.198 8.077 18.102 8.077 13.344 0 24.324-10.98 24.324-24.324v-.001-107.027'
        fill='none'
        stroke='white'
        strokeWidth='29.19'
      />
    </svg>
  );
};
