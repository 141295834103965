import '@aerisweather/javascript-sdk/dist/styles/styles.css';
import { datadogRum } from '@datadog/browser-rum';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import React from 'react';
import { createRoot, Root } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { store } from 'common/store';
import { beforeSend } from 'common/util/rum';
import { getApplicationRuntime } from 'common/util/utils';

import 'theme/css/card.css';
import 'theme/css/global.css';
import 'theme/css/roboto.css';

import { App } from './App';

const env = getApplicationRuntime();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  },
});

if (env === 'prod') {
  datadogRum.init({
    applicationId: '6faa4947-5e07-4c82-b597-8dd4b278c8c1',
    clientToken: 'pub657d26f5cb54a291275252b510b6b72d',
    site: 'datadoghq.com',
    service: 'power-tool-frontend-suite',
    env: env,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sampleRate: 100,
    premiumSampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
    beforeSend: beforeSend,
  });

  datadogRum.startSessionReplayRecording();
}

const mainContainer: Element | null = document.getElementById('root');
if (!mainContainer) throw new Error('Failed to find the root element');
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
const root: Root = createRoot(mainContainer);
// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Provider store={store}>
          <App />
        </Provider>
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>
);
