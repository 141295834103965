import { useSnackbar } from 'notistack';
import { useEffect, useRef } from 'react';
import { batch } from 'react-redux';

import {
  useAppDispatch,
  useAppSelector,
  useEventSelector,
  useIsAssetLevelHook,
} from 'common/hooks';
import {
  selectUser,
  selectAssetDetails,
  selectSuperUser,
} from 'common/stores/globalSlice';
import { setToolOutputResults } from 'common/stores/searchSlice';
import { isRunSelected } from 'common/util/utils';

import {
  selectCaseActionDisabled,
  selectCurrentCaseOwner,
  selectInWorkQueue,
  setCaseActionDisabled,
  setCaseActionDisableReason,
  setCurrentCaseOwner,
  setInWorkQueue,
  setRxCustomized,
} from 'power-tool/stores/caseActionSlice';
import { selectCase } from 'power-tool/stores/casesSlice';
import { selectRun } from 'power-tool/stores/runSlice';
import { selectToolOutput } from 'power-tool/stores/toolOutputSlice';
import { useFetchCurrentCaseOwner } from 'power-tool/tool-dispo/hooks';

import { getRxs } from './api';

export const CaseActionMonitor = () => {
  const dispatch = useAppDispatch();

  const { owner, queueStatus } = useFetchCurrentCaseOwner();

  const toolOutputData = useAppSelector(selectToolOutput);
  const user = useAppSelector(selectUser);
  const selectedCase = useAppSelector(selectCase);
  const isCaseActionDisabled = useAppSelector(selectCaseActionDisabled);
  const assetDetails = useAppSelector(selectAssetDetails);
  const run = useAppSelector(selectRun);
  const superuser = useAppSelector(selectSuperUser);
  const inWorkQueue = useAppSelector(selectInWorkQueue);
  const currentCaseOwner = useAppSelector(selectCurrentCaseOwner);

  const { isAssetLevel } = useIsAssetLevelHook();

  const userRef = useRef(user);
  const previousButtonState = useRef(isCaseActionDisabled);
  const caseRef = useRef(selectedCase);

  // event monitor
  const currentEvent = useEventSelector();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    userRef.current = user;
  }, [user]);

  useEffect(() => {
    previousButtonState.current = isCaseActionDisabled;
  }, [isCaseActionDisabled]);

  useEffect(() => {
    if (owner && queueStatus) {
      batch(() => {
        dispatch(setCurrentCaseOwner(owner));
        dispatch(setInWorkQueue(queueStatus));
      });
    }
  }, [owner, queueStatus]);

  // go get a list of searchable RXs for add rx feature
  useEffect(() => {
    //if the action is enabled and the assetdetails are defined
    //calls and stores the results
    if (!isCaseActionDisabled && assetDetails) {
      Promise.all([getRxs(assetDetails.vehicleObjid, 'Standard')]).then(
        (responses) => {
          const results = [
            ...responses[0].map((result) => {
              return {
                key: result.rxId,
                label: result.rxTitle,
                value: result,
                type: 'Approved' as const,
              };
            }),
          ];
          dispatch(setToolOutputResults(results));
        }
      );
    }
  }, [isCaseActionDisabled, assetDetails, dispatch]);

  useEffect(() => {
    caseRef.current = selectedCase;
  }, [selectedCase]);

  // here we have a big use effect hook that checks all of the
  // conditions regardless of what changes to see if the button should
  // be enabled / disabled
  useEffect(() => {
    // if user doesn't have case management priv, disable
    if (user?.cmPrivilege !== true && !superuser) {
      // console.log('-- disable: no case management priv -- ')
      dispatch(setCaseActionDisabled(true));
      dispatch(
        setCaseActionDisableReason(
          'You do not have the correct permissions to take case actions.'
        )
      );
      return;
    }

    if (user?.currRole !== 'Power Tool' && !superuser) {
      // console.log('-- disable: no Power Tool role -- ')
      dispatch(setCaseActionDisabled(true));
      dispatch(
        setCaseActionDisableReason(
          "You do not have the correct role. Launch with the 'Power Tool' role selected in OMD."
        )
      );
      return;
    }

    // if we are on asset level, disable
    if (isAssetLevel()) {
      // console.log('-- disable: on asset level -- ')
      dispatch(setCaseActionDisabled(true));
      dispatch(
        setCaseActionDisableReason(
          'Case actions are only available at the case level. Select a case to enable.'
        )
      );
      return;
    }

    // if the user has a run selected, return
    if (isRunSelected(run)) {
      // console.log('-- disable: run selected -- ')
      dispatch(setCaseActionDisabled(true));
      dispatch(
        setCaseActionDisableReason(
          'Case actions are only available at the case level. Unselect the run at the top of the screen to enable.'
        )
      );
      return;
    }

    // if the user of the application is not the current case owner, we disable the button
    if (
      currentCaseOwner === undefined ||
      user === undefined ||
      currentCaseOwner !== user.eoaUserName
    ) {
      // console.log('-- disable: current case owner != user.id -- ')
      dispatch(setCaseActionDisabled(true));
      dispatch(
        setCaseActionDisableReason(
          `You (${user?.eoaUserName}) do not own the case. Accept the case from OMD to enable case actions.`
        )
      );
      return;
    }

    // if the user of the application is not the current case owner, we disable the button
    if (inWorkQueue === 'Y') {
      // console.log('-- disable: current case owner != user.id -- ')
      dispatch(setCaseActionDisabled(true));
      dispatch(
        setCaseActionDisableReason(
          `The case is not in your work queue. Accept the case from OMD.`
        )
      );
      return;
    }

    // if (toolOutputData.length <= 0) {
    //   // console.log('-- disable: no output from tools -- ')
    //   dispatch(setCaseActionDisabled(true));
    //   dispatch(setCaseActionDisableReason('No output from tools.'));
    //   return;
    // }

    // if all other tests have passed, we are safe to enable tool output
    dispatch(setCaseActionDisabled(false));
  }, [
    toolOutputData,
    user,
    run,
    selectedCase,
    currentCaseOwner,
    superuser,
    inWorkQueue,
    isAssetLevel,
    dispatch,
  ]);

  // this hook strictly worries about when incoming events related to case ownership comes in
  useEffect(() => {
    if (currentEvent) {
      if (currentEvent.event === 'CASE_OWNERSHIP_CHANGE') {
        if (
          currentEvent.caseId === caseRef.current?.caseId &&
          userRef.current?.eoaUserName === currentEvent.userId &&
          previousButtonState.current === true
        ) {
          enqueueSnackbar('Case accepted', { variant: 'success' });
        } else if (
          previousButtonState.current === false &&
          currentEvent.userId !== userRef.current?.eoaUserName
        ) {
          // console.log('-- yank -- ')
          enqueueSnackbar(`${currentEvent.userId} took ownership of the case`, {
            variant: 'info',
          });
        }

        batch(() => {
          dispatch(setCurrentCaseOwner(currentEvent.userId));
          dispatch(setInWorkQueue('N'));
        });
      } else if (currentEvent.event === 'CASE_DISPATCHED') {
        dispatch(setInWorkQueue('Y'));
        enqueueSnackbar(`Case moved into work queue`, { variant: 'info' });
      } else if (currentEvent.event === 'RX_CUSTOMIZED') {
        dispatch(setRxCustomized(currentEvent));
      }
    }
  }, [currentEvent, dispatch, enqueueSnackbar]);

  return <></>;
};
