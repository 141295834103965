import { Typography } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';

import { useTimelineStyles } from 'common/features/timeline/hooks/style';
import { TimelineEvent } from 'common/features/timeline/types';
import { useUserFormattedTime } from 'common/hooks';

import { EventTooltip } from 'loco-history/cards/Timeline/components/event-tooltip/EventTooltip';
import { TimelineEventToPropsMap } from 'loco-history/config';

export const Tick = ({
  props,
  event,
  icons,
}: {
  props: any;
  event?: TimelineEvent;
  icons: boolean;
}) => {
  const timestamp = useUserFormattedTime(event?.timestamp);

  const { trackStyle } = useTimelineStyles();

  const [detailsOpen, setDetailsOpen] = useState<boolean>(false);

  const markHeight = trackStyle.height;

  const onClickAway = useCallback((event: MouseEvent | TouchEvent) => {
    setDetailsOpen(false);
  }, []);

  const tooltipBody = useMemo(() => {
    return event?.tooltip ?? <Typography>{timestamp}</Typography>;
  }, [timestamp, event]);

  return (
    <EventTooltip
      title={tooltipBody}
      onClickAway={onClickAway}
      open={detailsOpen}
      content={
        <div
          onClick={() => setDetailsOpen(true)}
          {...props}
          style={{
            ...props.style,
            // @ts-ignore
            zIndex: trackStyle.zIndex + 1,
            height: trackStyle.height,
            top: 0,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          {icons && event?.type ? (
            <div
              style={{
                color:
                  TimelineEventToPropsMap[event.type].style.backgroundColor,
              }}
            >
              {TimelineEventToPropsMap[event.type].icon}
            </div>
          ) : (
            <div
              style={{
                height: markHeight,
                width: '5px',
                borderRadius: '2px',
                backgroundColor: event?.type
                  ? TimelineEventToPropsMap[event.type].style.backgroundColor
                  : 'gray',
              }}
            ></div>
          )}
        </div>
      }
    />
  );
};

export const TimeTick = ({ props }: { props: any }) => {
  const { trackStyle } = useTimelineStyles();

  const [detailsOpen, setDetailsOpen] = useState<boolean>(false);

  const labelHeight = '35px';
  const markHeight = trackStyle.height;

  // const onClickAway = useCallback((event: MouseEvent | TouchEvent) => {
  //   setDetailsOpen(false)
  // }, [])

  // const tooltipBody = useMemo(() => {
  //   return event?.tooltip ?? <Typography>{timestamp}</Typography>
  // }, [timestamp, event])

  return (
    // <ClickAwayListener onClickAway={onClickAway}>
    //   <div>
    //     <ColoredTooltip arrow
    //       // someOtherProps={{}}
    //       placement='top'
    //       open={detailsOpen}
    //       title={tooltipBody}
    //       sx={{
    //         // backgroundColor: PowerToolDarkTheme.statusGray700,
    //       }}>
    <div
      onClick={() => setDetailsOpen(true)}
      {...props}
      style={{
        ...props.style,
        // @ts-ignore
        zIndex: trackStyle.zIndex + 1,
        height: trackStyle.height,
        top: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
      }}
    >
      <div
        style={{
          height: markHeight,
          width: '1px',
          backgroundColor: 'gray',
        }}
      ></div>
    </div>
    //     </ColoredTooltip>
    //   </div>
    // </ClickAwayListener>
  );
};
