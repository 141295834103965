import axios, { Canceler } from 'axios';

import { RuleDetails } from 'power-tool/cards/RuleOutput/types';

let cancelRuleDetails: Canceler | undefined,
  cancelRuleFiringDetails: Canceler | undefined;

export const getRuleDetails = (ruleId: number): Promise<RuleDetails> => {
  if (cancelRuleDetails) {
    cancelRuleDetails();
    cancelRuleDetails = undefined;
  }
  return axios
    .get('/proxy/omd/ruleinfo/' + ruleId, {
      cancelToken: new axios.CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancelRuleDetails = c;
      }),
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      if (e?.message === 'canceled') {
        return;
      }
      console.log('error fetching rule details');
      throw e;
    });
};

export const getRuleFiringDetails = (
  runId: number,
  ruleId: number
): Promise<any> => {
  if (cancelRuleFiringDetails) {
    cancelRuleFiringDetails();
    cancelRuleFiringDetails = undefined;
  }
  return axios
    .get('/rulefiring/' + runId + '/' + ruleId, {
      cancelToken: new axios.CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancelRuleFiringDetails = c;
      }),
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      if (e?.message === 'canceled') {
        return;
      }
      console.log('error fetching getRuleFiringDetails');
      throw e;
    });
};
