import { TextField } from '@mui/material';
import { IFloatingFilterParams, TextFilterModel } from 'ag-grid-community';
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

export interface CustomParams extends IFloatingFilterParams {
  suppressFilterButton: boolean;
  color: string;
}

// https://www.ag-grid.com/react-data-grid/component-floating-filter/#example-custom-floating-filter
export const MuiFloatingFilterComponent = forwardRef(
  (props: CustomParams, ref) => {
    const [currentValue, setCurrentValue] = useState<string | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    // expose AG Grid Filter Lifecycle callbacks
    useImperativeHandle(ref, () => {
      return {
        onParentModelChanged(parentModel: TextFilterModel) {
          // When the filter is empty we will receive a null value here
          if (!parentModel) {
            inputRef.current!.value = '';
            setCurrentValue(null);
          } else {
            inputRef.current!.value = parentModel.filter + '';
            setCurrentValue(parentModel.filter!);
          }
        },
      };
    });

    const onInputBoxChanged = (input: any) => {
      if (input.target.value === '') {
        // Remove the filter
        props.parentFilterInstance((instance) => {
          instance.onFloatingFilterChanged(null, null);
        });
        return;
      }

      setCurrentValue(input.target.value);
      props.parentFilterInstance((instance) => {
        instance.onFloatingFilterChanged('contains', input.target.value);
      });
    };

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <TextField
          fullWidth
          inputRef={inputRef}
          size={'small'}
          variant={'standard'}
          placeholder={'Filter...'}
          sx={{
            fontSize: '10px',
            height: '20px',
          }}
          inputProps={{
            style: { height: 'unset', fontSize: 'unset', fontWeight: 'unset' },
          }}
          InputProps={{
            sx: { fontSize: '12px' },
          }}
          onInput={onInputBoxChanged}
        />
      </div>
    );
  }
);
