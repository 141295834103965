import { Select, MenuItem } from '@mui/material';
import { useEffect, useMemo } from 'react';

import { useFetchDatascreenServices } from 'common/features/ingested-data/api/hooks';
import {
  useAppDispatch,
  useAppSelector,
  useIsAssetLevelHook,
} from 'common/hooks';
import { selectApplicationContext } from 'common/stores/globalSlice';
import {
  selectService,
  setService,
} from 'common/stores/ingestedDataFooterSlice';

export const ServiceSelectionComponent = () => {
  const dispatch = useAppDispatch();

  const applicationContext = useAppSelector(selectApplicationContext);

  const { isAssetLevel } = useIsAssetLevelHook();
  const { services } = useFetchDatascreenServices();

  const service = useAppSelector(selectService);

  const visible = useMemo(() => {
    if (
      applicationContext !== 'train_analysis' &&
      applicationContext !== 'virtual_tester'
    ) {
      return true;
    }

    return false;
  }, [applicationContext]);

  // when services is populated, dispatch the default if its not already selected
  useEffect(() => {
    if (services && service === undefined) {
      if (applicationContext === 'train_analysis') {
        dispatch(
          setService({
            screenType: 'VEHICLE_FAULTS_CUSTOMER',
            customerViewTypes: [],
            defaultView: true,
            displayName: 'Vehicle Faults',
          })
        );
      } else if (applicationContext === 'virtual_tester') {
        dispatch(
          setService({
            screenType: 'VEHICLE_FAULTS_GPOC',
            customerViewTypes: [],
            defaultView: true,
            displayName: 'Vehicle Faults',
          })
        );
      } else {
        dispatch(setService(services.find((service) => service.defaultView)));
      }
    }
  }, [services, service]);

  if (visible && services && isAssetLevel()) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginLeft: '25px',
        }}
      >
        {visible ? (
          <Select
            key={service?.screenType}
            size='small'
            variant='standard'
            MenuProps={{
              disableScrollLock: true,
            }}
            sx={{
              fontSize: '13px',
              minWidth: 120,
              marginRight: '5px',
            }}
            value={service?.screenType}
            onChange={(event) =>
              dispatch(
                setService(
                  services.find(
                    (service) => service.screenType === event.target.value
                  )
                )
              )
            }
          >
            {services.map((service) => {
              return (
                <MenuItem
                  key={service.screenType}
                  value={service.screenType}
                  sx={{ fontSize: '13px' }}
                >
                  {service.displayName}
                </MenuItem>
              );
            })}
          </Select>
        ) : (
          <></>
        )}
      </div>
    );
  }

  return <></>;
};
