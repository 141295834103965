import { Fullscreen, Add, Remove, HighlightOff } from '@mui/icons-material';
import { Button, ButtonGroup, styled } from '@mui/material';
import chroma from 'chroma-js';
import 'itools-rule-editor/css/editor.css';
import 'itools-rule-editor/css/editorNodes.css';
import { ruleEditor } from 'itools-rule-editor/ruleEditor';
import EOA from 'itools-rule-editor/utils';
import ModuleView from 'itools-rule-editor/views/module';
import $ from 'jquery';
import { DateTime } from 'luxon';
import React, { createRef, useEffect, useState } from 'react';
import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchRef,
} from 'react-zoom-pan-pinch';

import {
  setFiredFaults,
  setFiredFltAndParamForRuleLoaded,
} from 'common/cards/Data/store';
import { useAppSelector, useAppDispatch } from 'common/hooks';
import { store } from 'common/store';
import { selectRule } from 'common/stores/globalSlice';
import { stripEncodedHTML } from 'common/util/utils';

import { StyledH4 } from 'theme/GlobalStyles';
import { PowerToolDarkTheme } from 'theme/PowerToolThemes';
import 'theme/css/ruleCard.css';
import 'theme/css/ruleEditor_bootstrap.css';

import { RuleHistoryModal } from 'power-tool/cards/RuleOutput/RuleHistoryModal';
import { getFiredFaults, FiredFaultInput } from 'power-tool/saga/actionSlice';
import { selectCase } from 'power-tool/stores/casesSlice';
import {
  setRuleCardTitle,
  selectRuleCardTitle,
  setRuleDetails,
} from 'power-tool/stores/ruleOutputSlice';
import { selectRun, selectLatestRun } from 'power-tool/stores/runSlice';

import { getRuleDetails, getRuleFiringDetails } from './api';

let caseId: any;
let runId: any;
let ruleId: number;
let selectedRunId: any;

const StyledSpan = styled('span')`
  color: ${PowerToolDarkTheme.voltBlue200};
  font-style: italic;
  padding-left: 10px;
  cursor: pointer;
  :hover {
    color: ${PowerToolDarkTheme.voltBlue500} !important;
  }
`;

export default function RuleOutputCard() {
  ruleId = useAppSelector(selectRule);
  caseId = useAppSelector(selectCase)?.caseId;
  runId = useAppSelector(selectRun)?.objid;
  const latestRun = useAppSelector(selectLatestRun);
  const dispatch = useAppDispatch();
  const [initialScale] = useState<number>(1);
  const transformWrapperRef: React.Ref<ReactZoomPanPinchRef> = createRef();
  const selectedCase = useAppSelector(selectCase);
  const [warningMessage, setWarningMessage] = useState<string>('');

  useEffect(() => {
    // Update the document title using the browser API
    //let ruleFiredDetails = JSON.parse('{"simpleRules":["378983","378984"],"complexRules":["378994","378995","378996","378997","378998"]}');
    transformWrapperRef.current?.resetTransform();
    selectedRunId = 0;
    const scrollDivRef: HTMLCollectionOf<Element> =
      document.getElementsByClassName('react-transform-wrapper');
    if (scrollDivRef.length > 0) {
      scrollDivRef[0].scroll(0, 0);
    }
    if (ruleId > 0) {
      if (runId && runId > 0) {
        selectedRunId = runId;
        callRuleAndRunDetails(ruleId, runId);
      } else if (caseId) {
        selectedRunId = latestRun;
        if (latestRun && latestRun > 0) {
          callRuleAndRunDetails(ruleId, latestRun);
        }
      } else {
        setWarningMessage('');
        callRuleDetails(ruleId);
      }
    } else {
      setWarningMessage('');
      new ruleEditor('', '');
      dispatch(setRuleCardTitle(''));
    }
    // eslint-disable-next-line
  }, [ruleId, runId, caseId, latestRun]);

  const callRuleAndRunDetails = (rule: any, run: any) => {
    Promise.all([getRuleDetails(rule), getRuleFiringDetails(run, rule)])
      .then((responses) => {
        if (responses.length > 0) {
          const firingDetails = responses[1];
          if (
            selectedCase &&
            firingDetails &&
            firingDetails.complexRules?.length == 0 &&
            firingDetails.simpleRules?.length == 0
          ) {
            const currentDate: any = new Date();
            const caseCreationTime = DateTime.fromISO(
              selectedCase.creationDate
            ).toJSDate();
            const caseCreationDate: any = new Date(caseCreationTime.getTime());
            const difference =
              (currentDate - caseCreationDate) / (1000 * 3600 * 24);
            if (difference > 60) {
              setWarningMessage(
                'Forensic data is not available for cases older than 60 days.'
              );
            } else {
              setWarningMessage('');
            }
          } else {
            setWarningMessage('');
          }
          if (responses[0]) {
            dispatch(setRuleDetails(responses[0]));
            dispatch(
              setRuleCardTitle('Rule: ' + rule + ' - ' + responses[0].ruleTitle)
            );
            new ruleEditor(responses[0], firingDetails);
          }
        }
      })
      .catch((e) => {
        console.log('err in set rule editor', e);
        new ruleEditor('', '');
        dispatch(setRuleCardTitle(''));
      });
  };

  const callRuleDetails = (rule: any) => {
    getRuleDetails(rule)
      .then((response) => {
        if (response) {
          dispatch(
            setRuleCardTitle('Rule: ' + rule + ' - ' + response.ruleTitle)
          );
          new ruleEditor(response, '');
        } else {
          new ruleEditor('', '');
          dispatch(setRuleCardTitle(''));
        }
        dispatch(setRuleDetails(response));
      })
      .catch((e) => {
        console.log('err in set rule editor', e);
        new ruleEditor('', '');
        dispatch(setRuleCardTitle(''));
      });
  };

  return (
    <TransformWrapper
      initialScale={initialScale}
      wheel={{ disabled: true }}
      pinch={{ disabled: true }}
      centerZoomedOut={false}
      centerOnInit={false}
      panning={{ disabled: true }}
      doubleClick={{ disabled: true }}
      minScale={-2}
      limitToBounds={false}
      ref={transformWrapperRef}
    >
      {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
        <React.Fragment>
          <span className='ruleCardWarningMsg' style={{ marginTop: '5px' }}>
            {warningMessage}
          </span>
          <span className='zoomOptions' style={{ marginTop: '8px' }}>
            <ButtonGroup size='small' style={{ marginRight: '8px' }}>
              <RuleControlButton
                action={() => {
                  $('#ruleEditor .module.selected').removeClass('selected');
                  store.dispatch(
                    getFiredFaults({
                      id: caseId,
                      ruleId: ruleId,
                      type: 'caserul',
                    })
                  );
                }}
                content={<HighlightOff fontSize='small' />}
              />
            </ButtonGroup>
            <ButtonGroup>
              <RuleControlButton
                action={() => {
                  zoomIn(0.2);
                  const panning =
                    transformWrapperRef.current?.instance.props.panning;
                  if (panning) panning['disabled'] = false;
                }}
                content={<Add fontSize='small' />}
              />
              <RuleControlButton
                action={() => {
                  zoomOut(0.2);
                  const panning =
                    transformWrapperRef.current?.instance.props.panning;
                  if (panning) panning['disabled'] = false;
                }}
                content={<Remove fontSize='small' />}
              />
              <RuleControlButton
                action={() => {
                  resetTransform();
                  const panning =
                    transformWrapperRef.current?.instance.props.panning;
                  if (panning) panning['disabled'] = true;
                }}
                content={<Fullscreen fontSize='small' />}
              />
            </ButtonGroup>
          </span>

          <div className='tab-pane module-body active' id='editor-container'>
            <section id='editor'>
              <div id='canvasContainer' className='canvasContainer'>
                <TransformComponent>
                  <div className='canvas panFullSize' id='ruleEditor'></div>
                </TransformComponent>
              </div>
            </section>
          </div>
        </React.Fragment>
      )}
    </TransformWrapper>
  );
}

ModuleView.prototype.selected = function (e: any) {
  $('#ruleEditor .module.selected').removeClass('selected');
  $(this.el).addClass('selected');
  const selectedModule = EOA.Collections.Modules.get(
    e.currentTarget.dataset.cid
  );
  if (
    selectedModule.get('business').isFired &&
    selectedRunId &&
    selectedRunId > 0
  ) {
    const firedFaultInput: FiredFaultInput = {
      id: selectedRunId,
      ruleId: 0,
      type: '',
    };
    if (selectedModule.get('business').simId) {
      firedFaultInput.ruleId = selectedModule.get('business').simId;
      firedFaultInput.type = 'simrul';
    } else if (selectedModule.get('business').cmpId) {
      firedFaultInput.ruleId = selectedModule.get('business').cmpId;
      firedFaultInput.type = 'cmprul';
    } else if (selectedModule.get('business').rorId) {
      firedFaultInput.ruleId = selectedModule.get('business').rorId;
      firedFaultInput.type = 'cmprul';
    } else if (selectedModule.get('business')) {
      if (selectedRunId && selectedRunId > 0) {
        firedFaultInput.ruleId = ruleId;
        firedFaultInput.type = 'runrul';
      } else if (caseId) {
        firedFaultInput.id = caseId;
        firedFaultInput.ruleId = ruleId;
        firedFaultInput.type = 'caserul';
      }
    }
    store.dispatch(getFiredFaults(firedFaultInput));
  } else {
    const data = [];
    store.dispatch(setFiredFaults(data));
    store.dispatch(setFiredFltAndParamForRuleLoaded(true));
  }
};

export const RuleOutputTitle = () => {
  const ruleTitle = useAppSelector(selectRuleCardTitle);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  return (
    <div
      onMouseDown={(event) => {
        event.stopPropagation();
      }}
      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
    >
      <StyledH4>
        {ruleTitle !== '' ? stripEncodedHTML(ruleTitle) : 'Rule Output'}
      </StyledH4>
      {ruleTitle !== '' && (
        <StyledSpan onClick={() => setModalOpen(true)}>View History</StyledSpan>
      )}
      <RuleHistoryModal open={modalOpen} onClose={() => setModalOpen(false)} />
    </div>
  );
};

export type ruleControlProps = {
  action?: () => void;
  content?: JSX.Element;
  sx?: React.CSSProperties;
};
const RuleControlButton = (props: ruleControlProps) => {
  return (
    <Button
      style={{
        height: '24px',
        width: '24px',
        // minWidth: 'unset',
        borderColor: PowerToolDarkTheme.neutralDark500,
        zIndex: 1,
      }}
      sx={{
        color: PowerToolDarkTheme.primary,
        backgroundColor: PowerToolDarkTheme.frosted,
        '&:hover': {
          backgroundColor: chroma(PowerToolDarkTheme.frosted)
            .brighten(0.5)
            .hex(),
        },
        // padding: 0,
        ...props.sx,
      }}
      onClick={props.action}
    >
      {props.content ?? <></>}
    </Button>
  );
};
