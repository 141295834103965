import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
  PreloadedState,
} from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import ingestedDataGridReducer from 'common/cards/Data/store';
import ingestedDataActions from 'common/features/ingested-data/actions';
import ingestedDataReducer from 'common/features/ingested-data/store';
import ingestedDataSaga from 'common/features/ingested-data/watchers';

import sagaActionReducer from 'power-tool/saga/actionSlice';
import rootSaga from 'power-tool/saga/watchers';
import caseActionReducer from 'power-tool/stores/caseActionSlice';
import casesReducer from 'power-tool/stores/casesSlice';
import plotReducer from 'power-tool/stores/plotSlice';
import ruleOutputReducer from 'power-tool/stores/ruleOutputSlice';
import runReducer from 'power-tool/stores/runSlice';
import toolOutputReducer from 'power-tool/stores/toolOutputSlice';
import caseActionSaga from 'power-tool/tool-dispo/saga/caseActionWatchers';

import trainReducer from 'train-tool/stores/trainSlice';

import virtualReducer from 'virtual-tester/stores/virtualSlice';

import ruleTesterReducer from 'rule-tester/stores/ruleTesterSlice';

import dateRangeReducer from './cards/Data/date-range/dateRangeSlice';
import datapacksReducer from './stores/datpacksSlice';
import globalReducer from './stores/globalSlice';
import ingestedDataFooterReducer from './stores/ingestedDataFooterSlice';
import MapReducer from './stores/mapSlice';
import searchReducer from './stores/searchSlice';
import timelineSlice from './stores/timelineSlice';
import userSettingReducer from './stores/userSettingSlice';

const sagaMiddleware = createSagaMiddleware();

export const rootReducer = combineReducers({
  toolOutput: toolOutputReducer,
  global: globalReducer,
  ingestedDataGrid: ingestedDataGridReducer,
  ingestedData: ingestedDataReducer,
  runs: runReducer,
  ruleOutput: ruleOutputReducer,
  cases: casesReducer,
  plot: plotReducer,
  map: MapReducer,
  ingestedDataFooter: ingestedDataFooterReducer,
  sagaActions: sagaActionReducer,
  dateRange: dateRangeReducer,
  caseAction: caseActionReducer,
  userSetting: userSettingReducer,
  trainAnalysis: trainReducer,
  search: searchReducer,
  virtual: virtualReducer,
  ruleTester: ruleTesterReducer,
  timeline: timelineSlice,
  datapacks: datapacksReducer,
  ingestedDataActions: ingestedDataActions,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: [
    ...getDefaultMiddleware({ thunk: false, serializableCheck: false }),
    sagaMiddleware,
  ],
});

sagaMiddleware.run(rootSaga);
sagaMiddleware.run(caseActionSaga);
sagaMiddleware.run(ingestedDataSaga);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppStore = typeof store;
