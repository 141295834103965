import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';

import { useFetchAssetsForCustomer } from 'common/api/hooks';
import { useAppSelector } from 'common/hooks';
import { selectCustomer, selectUser } from 'common/stores/globalSlice';
import { Asset } from 'common/types/types';

import {
  getTATUserProperties,
  getTrain,
  getTrainsForCustomer,
} from 'train-tool/api';
import {
  selectTrainSearchItem,
  selectDataSource,
} from 'train-tool/stores/trainSlice';
import {
  TrainResponse,
  TrainSearchItem,
  TrainAndAssetResults,
  TATUserProperties,
} from 'train-tool/types';

/**
 * @description hook that fetches a TrainResponse.
 *              this hook managers the selected datasource and selected customer, and will refetch accordingly
 *              if there is no data returned this hook will return dummy data in the form of placeholder data, and effectively is never in a loading state
 * @returns {UseQueryResult<TrainResponse>} hook state wrapper for a TrainResponse
 */
export const useFetchTrain = () => {
  const customer = useAppSelector(selectCustomer);
  const searchItem = useAppSelector(selectTrainSearchItem);
  const dataSource = useAppSelector(selectDataSource);
  const user = useAppSelector(selectUser);

  const enabled = useMemo(() => {
    if (customer === undefined || customer === '') {
      return false;
    }

    if (searchItem !== undefined) {
      return true;
    }

    return false;
  }, [customer, searchItem]);

  const dummyTrain = useMemo(() => {
    const train: TrainResponse = {
      trainId: null,
      eventTime: '',
      origin: '',
      destination: '',
      weight: 0,
      totalLength: 0,
      loadedCars: 0,
      emptyCars: 0,
      totalAxles: 0,
      tpob: 0,
      maxSpeed: 0,
      maxTractiveEffort: 0,
      targetHpt: 0,
      locomotives: [],
      exists: true,
    };

    return train;
  }, []);

  /**
   * @returns {TrainResponse} dummy train with ID 'dummy' if search item is undefined (null response or user has not searched for anything)
   */
  const placeholderData = (): TrainResponse => {
    if (searchItem === undefined) {
      return {
        ...dummyTrain,
        trainId: 'dummy',
      };
    }
    return dummyTrain;
  };

  const hook = useQuery(
    ['train_details', customer, dataSource, searchItem],
    () => {
      if (searchItem?.type === 'train') {
        const train = searchItem.item as TrainSearchItem;
        return getTrain({
          customer: customer ?? '',
          dataSource: dataSource ?? 'TO',
          trainId: train.trainId,
          userId: user?.userName,
        });
      } else if (searchItem?.type === 'asset') {
        const asset = searchItem.item as Asset;

        return getTrain({
          customer: customer ?? '',
          dataSource: dataSource ?? 'TO',
          vehicleHeader: asset.vehicleHdr,
          roadNumber: asset.roadNumber,
          userId: user?.userName,
        });
      }
    },
    { enabled, placeholderData }
  );

  return {
    train: hook.data,
    trainStatus: hook.status,
    ...hook,
  };
};

/**
 * @returns {UseQueryResult<TrainSearchItem>} hook state wrapper for all trains given a customer
 */
export const useFetchTrainsForCustomer = () => {
  const customer = useAppSelector(selectCustomer);
  const dataSource = useAppSelector(selectDataSource);

  const enabled = useMemo(() => {
    return customer !== undefined && dataSource !== undefined;
  }, [customer, dataSource]);

  const hook = useQuery(
    ['trains_for_customer', { customer, dataSource }],
    () => getTrainsForCustomer(customer ?? '', dataSource ?? 'TO'),
    { enabled }
  );

  return {
    trains: hook.data,
    trainsStatus: hook.status,
    ...hook,
  };
};

/**
 * @description combines useFetchAssetsForCustomer and useFetchTrainsForCustomer into a single managed state variable
 * @returns {TrainAndAssetResults[]} combined asset and train search list
 */
export const useFetchTrainsAndAssets = () => {
  const [searchResults, setSearchResults] = useState<TrainAndAssetResults[]>();

  const { assets } = useFetchAssetsForCustomer();
  const { trains } = useFetchTrainsForCustomer();

  useEffect(() => {
    if (assets && trains) {
      setSearchResults([
        ...assets.map((asset) => ({
          label: `${asset.vehicleHdr}-${asset.roadNumber}`,
          value: asset,
          type: 'asset' as const,
        })),
        ...trains.map((train) => ({
          label: train.trainId,
          value: train,
          type: 'train' as const,
        })),
      ]);
    }
  }, [assets, trains]);

  return { searchResults };
};

/**
 *
 * @param {string | undefined} userId okta user name / gets_user_usr user_id / employee number
 * @returns {UseQueryResult<TATUserProperties>} query hook contains TAT user properties
 */
export const useFetchTATProperties = (userId?: string) => {
  const enabled = useMemo(() => {
    return userId !== undefined;
  }, [userId]);

  const hook = useQuery(
    ['tat_user_properties', userId],
    () => getTATUserProperties(userId),
    { enabled }
  );

  return {
    userProperties:
      hook.data && hook.data.length > 0
        ? {
            ...hook.data[0],
            enabled: hook.data[0].enabled === 'true',
          }
        : undefined,
    ...hook,
  };
};
