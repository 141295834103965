import { Cable, Send } from '@mui/icons-material';
import {
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

export const WebSocketProvider = ({ children }) => {
  return children;
};

const Label = ({ label, value }) => {
  return (
    <Stack direction={'row'} spacing={2} alignItems={'center'}>
      <Typography variant='button' width={'100px'}>{`${label}:`}</Typography>
      <Typography variant='body2' color={'white'}>
        {value}
      </Typography>
    </Stack>
  );
};

export const SocketDemoCard = () => {
  const [socket, setSocket] = useState<string>(
    'wss://6ka6ojfwtd.execute-api.us-east-1.amazonaws.com/powertool-prod'
  );
  const [url, setUrl] = useState<string>(socket);
  const [message, setMessage] = useState<string>('Hello from client!');
  const [messages, setMessages] = useState<MessageEvent[]>([]);

  const { sendMessage, lastMessage, readyState } = useWebSocket(url, {
    onOpen: (event) => console.log('socket opened'),
    onError: (event) => console.log('socket error'),
  });

  const statuses = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  };

  useEffect(() => {
    if (lastMessage) {
      setMessages((existing) => [...existing, lastMessage]);
    }
  }, [lastMessage]);

  return (
    <Stack direction={'column'} spacing={2} alignItems={'flex-start'} m={2}>
      <Stack
        direction={'row'}
        justifyContent={'center'}
        alignItems={'center'}
        spacing={2}
        flex={'1 0 0'}
      >
        <TextField
          size='small'
          value={socket}
          onChange={(event) => setSocket(event.target.value)}
          sx={{ minWidth: '750px' }}
        />
        <Button
          variant='contained'
          color='success'
          endIcon={<Cable />}
          sx={{ color: 'white' }}
          onClick={() => setUrl(socket)}
        >
          Connect
        </Button>
      </Stack>
      <Label label={'State'} value={statuses[readyState]} />
      <Label label={'Last Message'} value={lastMessage?.data} />
      <Stack
        direction={'row'}
        justifyContent={'center'}
        alignItems={'center'}
        spacing={2}
      >
        <TextField
          size='small'
          value={message}
          onChange={(event) => setMessage(event.target.value)}
          sx={{ minWidth: '750px' }}
        />
        <Button
          variant='contained'
          color='success'
          endIcon={<Send />}
          sx={{ color: 'white' }}
          onClick={() => sendMessage(message)}
        >
          Send Message
        </Button>
      </Stack>
      <Paper
        sx={{ backgroundColor: PowerToolDarkTheme.main, minWidth: '500px' }}
      >
        <TableContainer>
          <Table size='small'>
            <TableHead>
              <TableRow>
                <TableCell>Timestamp</TableCell>
                <TableCell>Message</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {messages.map((message) => {
                return (
                  <TableRow key={message.timeStamp}>
                    <TableCell>{message.timeStamp}</TableCell>
                    <TableCell>{message.data}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Stack>
  );
};
