import { createTheme } from '@mui/material/styles';
import { DefaultTheme } from 'styled-components';

import WabtecLogoLight from 'common/assets/Wabtec-Corporation-Logo.svg';
import WabtecLogoDark from 'common/assets/White-Wabtec-Corporation-Logo.svg';

declare module 'styled-components' {
  export type ThemeTypes = 'dark' | 'light';
  export interface DefaultTheme {
    primary: string;
    inversePrimary: string;
    secondary: string;
    tertiary: string;
    main: string;
    inverseMain: string;
    underlay: string;
    shadow: string;
    inverseShadow: string;
    background: string;
    frosted: string;
    logo: string;

    torqblue: string;

    // accent colors (generally on opposite brightness spectrums for light/dark theme)
    purpleAccent: string;
    yellowAccent: string;
    redAccent: string;
    greenAccent: string;
    mainAccent: string;

    mainAccentText: string;

    // highlight colors (opposite brightness spectrum of accent colors)
    redHighlight: string;
    grayHighlight: string;

    // define the bounds for weather gradients, and allow chroma to do the rest
    // precipGradientLo: string;
    precipGradientHi: string;
    tempGradientLo: string;
    tempGradientHi: string;
    windGradientHi: string;

    // parameter grouping colors
    // basicParameterGroup: string;
    // engineParameterGroup: string;
    // propulsionParameterGroup: string;
    // auxParameterGroup: string;
    // virtualParameterGroup: string;
    // endfieldParameterGroup: string;
    // oilParameterGroup: string;
    // standardEDPParameterGroup: string;
    // restrictionsParameterGroup: string;
    // basicParameterGroupHover: string;
    // engineParameterGroupHover: string;
    // propulsionParameterGroupHover: string;
    // auxParameterGroupHover: string;
    // virtualParameterGroupHover: string;
    // endfieldParameterGroupHover: string;
    // oilParameterGroupHover: string;

    parameterGroupEven: string;
    parameterGroupOdd: string;

    parameterGroup1: string;
    parameterGroup2: string;
    parameterGroup3: string;
    parameterGroup4: string;
    parameterGroup5: string;
    parameterGroup6: string;
    parameterGroup7: string;
    parameterGroup8: string;

    filterActive: string;

    // neutrals
    neutralDark900: string;
    neutralDark800: string;
    neutralDark700: string;
    neutralDark600: string;
    neutralDark500: string;
    neutralDark400: string;
    neutralDark300: string;
    neutralDark200: string;
    neutralDark100: string;

    neutralLight900: string;
    neutralLight800: string;
    neutralLight700: string;
    neutralLight600: string;
    neutralLight500: string;
    neutralLight400: string;
    neutralLight300: string;
    neutralLight200: string;
    neutralLight100: string;

    // frosted colors
    statusGreenFrosted: string;
    statusYellowFrosted: string;
    statusOrangeFrosted: string;
    statusRedFrosted: string;
    statusGrayFrosted: string;
    dataTealFrosted: string;
    dataPurpleFrosted: string;

    /**
     * These colors below shouldn't really be used directly. they are stored for a reference to the themes
     * There are sitations where it makes sense to use these. However, use accent colors wherever possible as they
     * will change with the theme.
     */
    statusGreen900: string;
    statusGreen800: string;
    statusGreen700: string;
    statusGreen600: string;
    statusGreen500: string;
    statusGreen400: string;
    statusGreen300: string;
    statusGreen200: string;
    statusGreen100: string;

    dataGreen900: string;
    dataGreen800: string;
    dataGreen700: string;
    dataGreen600: string;
    dataGreen500: string;
    dataGreen400: string;
    dataGreen300: string;
    dataGreen200: string;
    dataGreen100: string;

    voltBlue900: string;
    voltBlue800: string;
    voltBlue700: string;
    voltBlue600: string;
    voltBlue500: string;
    voltBlue400: string;
    voltBlue300: string;
    voltBlue200: string;
    voltBlue100: string;

    dataTeal900: string;
    dataTeal800: string;
    dataTeal700: string;
    dataTeal600: string;
    dataTeal500: string;
    dataTeal400: string;
    dataTeal300: string;
    dataTeal200: string;
    dataTeal100: string;

    statusYellow900: string;
    statusYellow800: string;
    statusYellow700: string;
    statusYellow600: string;
    statusYellow500: string;
    statusYellow400: string;
    statusYellow300: string;
    statusYellow200: string;
    statusYellow100: string;

    dataYellow900: string;
    dataYellow800: string;
    dataYellow700: string;
    dataYellow600: string;
    dataYellow500: string;
    dataYellow400: string;
    dataYellow300: string;
    dataYellow200: string;
    dataYellow100: string;

    statusOrange900: string;
    statusOrange800: string;
    statusOrange700: string;
    statusOrange600: string;
    statusOrange500: string;
    statusOrange400: string;
    statusOrange300: string;
    statusOrange200: string;
    statusOrange100: string;

    dataOrange900: string;
    dataOrange800: string;
    dataOrange700: string;
    dataOrange600: string;
    dataOrange500: string;
    dataOrange400: string;
    dataOrange300: string;
    dataOrange200: string;
    dataOrange100: string;

    dataPink900: string;
    dataPink800: string;
    dataPink700: string;
    dataPink600: string;
    dataPink500: string;
    dataPink400: string;
    dataPink300: string;
    dataPink200: string;
    dataPink100: string;

    statusRed900: string;
    statusRed800: string;
    statusRed700: string;
    statusRed600: string;
    statusRed500: string;
    statusRed400: string;
    statusRed300: string;
    statusRed200: string;
    statusRed100: string;

    statusGray900: string;
    statusGray800: string;
    statusGray700: string;
    statusGray600: string;
    statusGray500: string;
    statusGray400: string;
    statusGray300: string;
    statusGray200: string;
    statusGray100: string;

    dataPurple900: string;
    dataPurple800: string;
    dataPurple700: string;
    dataPurple600: string;
    dataPurple500: string;
    dataPurple400: string;
    dataPurple300: string;
    dataPurple200: string;
    dataPurple100: string;
  }
}

const BaseTheme = {
  torqblue: '#508be4',

  statusGreenFrosted: 'rgba(152, 190, 72, 0.6)',
  statusYellowFrosted: 'rgba(254, 209, 51, 0.6)',
  statusOrangeFrosted: 'rgba(255, 162, 97, 0.6)',
  statusRedFrosted: 'rgba(245, 104, 94, 0.6)',
  statusGrayFrosted: 'rgba(189, 189, 189, 0.6)',
  dataTealFrosted: 'rgba(115, 214, 233, 0.6)',
  dataPurpleFrosted: 'rgba(167, 115, 233, 0.6)',

  statusGreen900: '#304738',
  statusGreen800: '#436130',
  statusGreen700: '#587A2A',
  statusGreen600: '#6B9422',
  statusGreen500: '#7FAE1B',
  statusGreen400: '#98BE48',
  statusGreen300: '#B2CE76',
  statusGreen200: '#CBDEA3',
  statusGreen100: '#E5EED1',

  dataGreen900: '#2A504D',
  dataGreen800: '#37725A',
  dataGreen700: '#469368',
  dataGreen600: '#53B575',
  dataGreen500: '#61D883',
  dataGreen400: '#80DF9B',
  dataGreen300: '#A0E7B4',
  dataGreen200: '#BFEFCD',
  dataGreen100: '#DFF7E6',

  voltBlue900: '#173E58',
  voltBlue800: '#114E70',
  voltBlue700: '#0C5D89',
  voltBlue600: '#066DA1',
  voltBlue500: '#007DBA',
  voltBlue400: '#3397C7',
  voltBlue300: '#66B1D5',
  voltBlue200: '#99CBE3',
  voltBlue100: '#CCE5F1',

  dataTeal900: '#274D60',
  dataTeal800: '#316D81',
  dataTeal700: '#3C8CA2',
  dataTeal600: '#46ACC3',
  dataTeal500: '#50CCE4',
  dataTeal400: '#73D6E9',
  dataTeal300: '#96E0EE',
  dataTeal200: '#B9EAF4',
  dataTeal100: '#DCF4F9',

  statusYellow900: '#494C33',
  statusYellow800: '#766B26',
  statusYellow700: '#A4881A',
  statusYellow600: '#D1A70D',
  statusYellow500: '#FEC600',
  statusYellow400: '#FED133',
  statusYellow300: '#FEDC66',
  statusYellow200: '#FEE899',
  statusYellow100: '#FEF3CC',

  dataYellow900: '#444D43',
  dataYellow800: '#6C6D46',
  dataYellow700: '#948B4A',
  dataYellow600: '#BCAB4D',
  dataYellow500: '#E4CB50',
  dataYellow400: '#E9D573',
  dataYellow300: '#EEDF96',
  dataYellow200: '#F4EAB9',
  dataYellow100: '#F9F4DC',

  statusOrange900: '#4A403E',
  statusOrange800: '#77533D',
  statusOrange700: '#A5653C',
  statusOrange600: '#D2783B',
  statusOrange500: '#FF8B3A',
  statusOrange400: '#FFA261',
  statusOrange300: '#FFB988',
  statusOrange200: '#FFD0B0',
  statusOrange100: '#FFE7D7',

  dataOrange900: '#444543',
  dataOrange800: '#6C5D46',
  dataOrange700: '#94734A',
  dataOrange600: '#BC8B4D',
  dataOrange500: '#E4A350',
  dataOrange400: '#E9B573',
  dataOrange300: '#EEC796',
  dataOrange200: '#F4DAB9',
  dataOrange100: '#F9ECDC',

  dataPink900: '#483E5F',
  dataPink800: '#744E7C',
  dataPink700: '#A15D9B',
  dataPink600: '#CD6DB9',
  dataPink500: '#F97DD8',
  dataPink400: '#FA97DF',
  dataPink300: '#FBB1E7',
  dataPink200: '#FCCBEF',
  dataPink100: '#FDE5F7',

  statusRed900: '#47323D',
  statusRed800: '#72363B',
  statusRed700: '#9D3A3A',
  statusRed600: '#C83E38',
  statusRed500: '#F34336',
  statusRed400: '#F5685E',
  statusRed300: '#F78E86',
  statusRed200: '#FAB3AE',
  statusRed100: '#FCD9D6',

  statusGray900: '#394755',
  statusGray800: '#56616B',
  statusGray700: '#737981',
  statusGray600: '#909397',
  statusGray500: '#ADADAD',
  statusGray400: '#BDBDBD',
  statusGray300: '#CDCDCD',
  statusGray200: '#DEDEDE',
  statusGray100: '#EEEEEE',

  dataPurple900: '#343560',
  dataPurple800: '#4B3C81',
  dataPurple700: '#6342A2',
  dataPurple600: '#7A49C3',
  dataPurple500: '#9150E4',
  dataPurple400: '#A773E9',
  dataPurple300: '#BD96EE',
  dataPurple200: '#D3B9F4',
  dataPurple100: '#E9DCF9',

  neutralLight900: '#324252',
  neutralLight800: '#485664',
  neutralLight700: '#53606E',
  neutralLight600: '#747D8A',
  neutralLight500: '#8A929D',
  neutralLight400: '#A1A7B0',
  neutralLight300: '#B8BDC4',
  neutralLight200: '#D0D3D7',
  neutralLight100: '#eef0f1',

  neutralDark900: '#E7E9EB',
  neutralDark800: '#CFD3D6',
  neutralDark700: '#C4C8CC',
  neutralDark600: '#A1A7B0',
  neutralDark500: '#8B929A',
  neutralDark400: '#727982',
  neutralDark300: '#525e6b',
  neutralDark200: '#25313E',
  neutralDark100: '#0F1924',

  // parameterGroup1: '#0E2D43',
  // parameterGroup1: '#103651',
  // parameterGroup2: '#144162',
  // parameterGroup3: '#1A5683',
  // parameterGroup4: '#1A759F',
  // parameterGroup5: '#34A0A4',
  // parameterGroup6: '#55B49C',
  // parameterGroup7: '#76C893',
  // parameterGroup8: '#A2DB9C',

  // parameterGroup1: '#25313E',
  // parameterGroup2: '#3E5368',
  // parameterGroup3: '#47323D',
  // parameterGroup4: '#6D4C5D',
  // parameterGroup5: '#343560',
  // parameterGroup6: '#5A489E',
  // parameterGroup7: '#37725A',
  // parameterGroup8: '#4E9A7C',

  parameterGroup1: '#0f3147',
  parameterGroup2: '#0a2131',
  parameterGroup3: '#143F5E',
  parameterGroup4: '#0F3249',
  parameterGroup5: '#1E4965',
  parameterGroup6: '#103249',
  parameterGroup7: '#1C435F',
  parameterGroup8: '#123852',
};

export const PowerToolLightTheme: DefaultTheme = {
  ...BaseTheme,
  primary: '#000000',
  inversePrimary: '#FFFFFF',
  secondary: '#57657A',
  tertiary: '#c7d4dd',
  main: '#FFFFFF',
  inverseMain: '#172839',
  underlay: 'rgba (0,0,0,.15)',
  shadow: 'rgba(29,46,64,.10)',
  inverseShadow: 'rgba(0, 0, 0, 0.308)',
  background: 'linear-gradient(#F8FAFC, #E7ECF4)',
  frosted: 'rgba(199, 212, 221, 0.65)',
  logo: `${WabtecLogoLight}`,

  redAccent: BaseTheme.statusRed100,
  purpleAccent: BaseTheme.dataPurple100,
  yellowAccent: BaseTheme.dataYellow200,
  greenAccent: BaseTheme.dataGreen200,
  mainAccent: BaseTheme.neutralLight100,
  mainAccentText: BaseTheme.voltBlue800,

  redHighlight: BaseTheme.statusRed500,
  grayHighlight: BaseTheme.neutralLight200,

  // precipGradientLo:
  precipGradientHi: BaseTheme.dataTeal400,
  tempGradientLo: BaseTheme.voltBlue400,
  tempGradientHi: BaseTheme.statusRed300,
  windGradientHi: BaseTheme.dataGreen400,

  filterActive: BaseTheme.voltBlue700,

  parameterGroupEven: '',
  parameterGroupOdd: '',
};

export const PowerToolDarkTheme: DefaultTheme = {
  ...BaseTheme,
  primary: '#b9bfc4',
  inversePrimary: '#000000',
  secondary: '#A8B5BF',
  tertiary: '#66757F',
  main: '#132d48',
  mainAccent: '#173951',
  inverseMain: '#000000',
  underlay: 'rgba(0,0,0,.5)',
  shadow: 'rgba(0, 0, 0, 0.308)',
  inverseShadow: 'rgba(29,46,64,.10)',
  background: 'linear-gradient(#1A2633, #121A23)',
  frosted: 'rgba(102, 117, 127, 0.65)',
  logo: `${WabtecLogoDark}`,

  redAccent: BaseTheme.statusRed900,
  purpleAccent: BaseTheme.dataPurple900,
  yellowAccent: BaseTheme.dataYellow800,
  greenAccent: BaseTheme.dataGreen800,
  mainAccentText: BaseTheme.voltBlue100,

  redHighlight: BaseTheme.statusRed400,
  grayHighlight: '#515c68',

  precipGradientHi: BaseTheme.dataTeal700,
  tempGradientLo: BaseTheme.voltBlue400,
  tempGradientHi: BaseTheme.statusRed800,
  windGradientHi: BaseTheme.dataGreen600,

  filterActive: BaseTheme.voltBlue300,

  parameterGroupEven: '#172839',
  parameterGroupOdd: '#0d1824',
};

const BaseMUITheme = createTheme({
  typography: {
    fontFamily: 'Roboto',
    fontSize: 13, // in rem
  },
});

export const darkTheme = createTheme({
  ...PowerToolDarkTheme,
  ...BaseMUITheme,
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: PowerToolDarkTheme.main,
        },
      },
    },
    MuiAccordionSummary: {
      defaultProps: {
        sx: {
          width: '100%',
        },
      },
      styleOverrides: {
        root: {
          width: '100%',
        },
        content: {
          '&.Mui-expanded': {
            marginBottom: '0px',
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        sizeSmall: {
          paddingTop: '2px',
          paddingBottom: '2px',
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          '&[class*="variantSuccess"]': {
            backgroundColor: PowerToolDarkTheme.dataGreen500,
          },
        },
      },
    },
  },
  palette: {
    mode: 'dark',
    grey: {
      '800': PowerToolDarkTheme.primary,
      '900': PowerToolDarkTheme.primary,
    },
    primary: {
      main: PowerToolDarkTheme.voltBlue300,
    },
    warning: {
      main: PowerToolDarkTheme.statusOrange500,
    },
    success: {
      main: PowerToolDarkTheme.statusGreen500,
      contrastText: 'FFFFFF',
    },
    secondary: {
      main: PowerToolDarkTheme.secondary,
    },
    info: {
      main: '#0085ff',
    },
  },
});

export const lightTheme = createTheme({
  ...PowerToolLightTheme,
  ...BaseMUITheme,
  palette: {
    mode: 'light',
    primary: {
      main: PowerToolLightTheme.voltBlue500,
    },
  },
});
