import { BugReport } from '@mui/icons-material';
import { IconButton } from '@mui/material';

import { useAppDispatch } from 'common/hooks';
import { setDataGridDebug } from 'common/stores/globalSlice';
import { getApplicationRuntime } from 'common/util/utils';

export const DataGridDebugButton = () => {
  const dispatch = useAppDispatch();

  const runtime = getApplicationRuntime();

  if (runtime === 'prod') {
    return <></>;
  }

  return (
    <IconButton size='small' onClick={() => dispatch(setDataGridDebug(true))}>
      <BugReport fontSize='small' color='secondary' />
    </IconButton>
  );
};
