import PostAddIcon from '@mui/icons-material/PostAdd';
import { Tooltip, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

import { getTemplateLabel } from 'common/features/datapack/utils';
import { useAppSelector } from 'common/hooks';
import { selectAssetDetails } from 'common/stores/globalSlice';
import { SecurityUtilityService } from 'common/util/security-utility';
import { isAppDeployed } from 'common/util/utils';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

const security = new SecurityUtilityService();

const StyledPostAddIcon = styled(PostAddIcon)`
  :hover {
    color: ${PowerToolDarkTheme.dataPurple200} !important;
  }
`;
export const DatapackViewerIcon = (props: {
  faultObjId: string;
  template?: string | number;
}) => {
  const assetDetails = useAppSelector(selectAssetDetails);

  const openDataPack = (event: React.MouseEvent<SVGSVGElement>) => {
    event.stopPropagation();
    if (
      assetDetails?.vehicleObjid &&
      props.faultObjId !== '' &&
      props.template !== undefined
    ) {
      const url = `?application_context=datapack_viewer&vehicleObjid=${assetDetails.vehicleObjid}&faultObjid=${props.faultObjId}&template=${props.template}&isDecryptedUrl=true`;

      window.open(
        url,
        `datapack_viewer`,
        'toolbar=no, location=no, statusbar=no, menubar=no, width=1920, height=1080 noopener'
      );
    }
  };

  return (
    <Tooltip
      title={
        <Typography variant='body2' sx={{ fontSize: 12 }}>
          <strong>View Datapack: </strong>
          {getTemplateLabel(props.template)}
        </Typography>
      }
      placement='bottom-start'
      arrow
    >
      <StyledPostAddIcon
        data-testid='HasDatapackIcon'
        style={{
          fontWeight: 1000,
          fontSize: 20,
          paddingRight: '3px',
          cursor: 'pointer',
          color: PowerToolDarkTheme.dataPurple400,
        }}
        onClick={openDataPack}
      />
    </Tooltip>
  );
};
