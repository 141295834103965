import { Collapse, SelectChangeEvent } from '@mui/material';
import React, { useState } from 'react';

import { useAppDispatch, useAppSelector } from 'common/hooks';

import {
  getCaseActionState,
  selectToolOutputDropdowns,
  selectToolOutputVerification,
  updateItem,
} from 'power-tool/stores/caseActionSlice';
import {
  ToolOutputItemState,
  CaseActionChoice,
} from 'power-tool/tool-dispo/types';

import { CaseActionSelectComponent } from './SelectComponent';
import {
  ToolOutputAccordion,
  ToolOutputAccordionHeader,
} from './ToolOutputAccordion';

export const ToolOutputModalItems = () => {
  const dispatch = useAppDispatch();
  const allItems = useAppSelector(getCaseActionState).items;
  const dropdownValues = useAppSelector(selectToolOutputDropdowns);
  const verification = useAppSelector(selectToolOutputVerification);

  const ruleItems = allItems?.filter(
    (item) => item.toolId !== 'Fault' && item.itemType === 'ToolOutput'
  );
  const advisoryItems = allItems?.filter(
    (item) => item.toolId !== 'Fault' && item.itemType === 'advisory'
  );
  const faultItems = allItems?.filter(
    (item) => item.toolId === 'Fault' && item.itemType === 'ToolOutput'
  );
  const searchItems = allItems?.filter((item) => item.itemType === 'Search');

  // since all the close reason for faults will be the same,
  // as a default on render we select whatever the first fault has as a close reason
  const [closeReason, setCloseReason] = useState<string>(
    faultItems && faultItems.length > 0 ? faultItems[0].closeReason ?? '' : ''
  );

  const onUpdate = (updatedItem: ToolOutputItemState) => {
    // console.log(`--- base update: ${updatedItem.id} ---`)
    // console.log(updatedItem)
    dispatch(updateItem(updatedItem));
  };

  const handleAction = (
    event: React.MouseEvent<HTMLElement>,
    newAction: CaseActionChoice | null,
    item: ToolOutputItemState
  ) => {
    // if the item is a critical fault, we only expand on select of merge
    // and we allow users to unselect an action
    if (item.toolId === 'Fault') {
      dispatch(
        updateItem({
          ...item,
          isExpanded: newAction === 'append',
          selectedAction: newAction,
        })
      );
    }

    // otherwise, we are on a CBR or JDPAD tool output, and once an action is selected it cannot be unselected
    // and the expand icon should always be available once selected

    // the accordion should be expanded when:
    //    the selected case action changed (not null) OR
    //    or if we are selecting the same action again (newAction is null AND our current selected action is defined)
    else if (item !== null) {
      let deliveryTarget = item.deliveryTarget;
      // if the user is selecting delivery,
      if (newAction === 'deliver') {
        // get the other tool output that are selected for delivery
        const deliveryTargets = allItems?.filter(
          (item) => item.selectedAction === 'deliver'
        );

        // and if we are the only output currently selected for delivery, mark it as current
        if (
          !deliveryTargets ||
          (deliveryTargets.length < 1 && verification?.activeRxExists !== true)
        ) {
          deliveryTarget = 'current';
        }

        // otherwise, we already have others as marked for delivery
        // (lets assume at this point the 'current' setting is correct)
        // so we can set the target as new
        else if (
          deliveryTargets.length >= 1 ||
          verification?.activeRxExists === true
        ) {
          deliveryTarget = 'new';
        }
      }
      const selectedAction = newAction ?? item?.selectedAction;
      const isExpanded =
        newAction !== null ||
        (item?.selectedAction !== undefined && newAction === null);

      dispatch(
        updateItem({
          ...item,
          isExpanded: isExpanded,
          selectedAction: selectedAction,
          deliveryTarget: deliveryTarget,
        })
      );
    }
  };

  // only for critical faults
  const onCloseReasonChange = (event: SelectChangeEvent<string>) => {
    setCloseReason(event.target.value);

    faultItems?.forEach((item) =>
      dispatch(
        updateItem({
          ...item,
          closeReason: event.target.value,
        })
      )
    );
  };

  return (
    <React.Fragment>
      <Collapse in={searchItems !== undefined && searchItems.length > 0}>
        <div>
          <ToolOutputAccordionHeader
            label='Add Rx'
            spacing={3}
            SearchItem={true}
          />
          {searchItems?.map((searchItem) => (
            <ToolOutputAccordion
              key={searchItem.id}
              item={searchItem}
              actions={['deliver']}
              enableOMDLink={true}
              label={undefined}
              expandDisabled={!searchItem.selectedAction}
              onActionSelect={handleAction}
              onUpdate={onUpdate}
            />
          ))}
        </div>
      </Collapse>

      <Collapse
        in={
          (ruleItems !== undefined && ruleItems.length > 0) ||
          (advisoryItems !== undefined && advisoryItems.length > 0)
        }
      >
        <div>
          <ToolOutputAccordionHeader label='Rules' spacing={3} />

          {ruleItems?.map((item) => (
            <ToolOutputAccordion
              key={item.id}
              item={item}
              actions={['deliver', 'append', 'merge', 'close']}
              enableOMDLink={true}
              label={item.toolId}
              expandDisabled={!item.selectedAction}
              onActionSelect={handleAction}
              onUpdate={onUpdate}
            />
          ))}
          {advisoryItems?.map((item) => (
            <ToolOutputAccordion
              key={item.id}
              item={item}
              actions={['deliver', 'close']}
              enableOMDLink={true}
              expandDisabled={!item.selectedAction}
              onActionSelect={handleAction}
              onUpdate={onUpdate}
            />
          ))}
        </div>
      </Collapse>
      <Collapse in={faultItems !== undefined && faultItems?.length > 0}>
        <div>
          <ToolOutputAccordionHeader label='Faults' spacing={3}>
            {/* For critical faults, users select a single close reason for all faults */}
            {/* and we only display the dropdown if a fault is selected for closure */}
            {faultItems?.find((item) => item.selectedAction === 'close') ===
            undefined ? (
              <></>
            ) : (
              <CaseActionSelectComponent
                id='close-reason'
                label='Close Reason'
                onChange={onCloseReasonChange}
                value={closeReason}
                items={Object.entries(dropdownValues!.closeOptions).map(
                  ([key, value]) => ({
                    value: key,
                    description: value.description,
                  })
                )}
              />
            )}
          </ToolOutputAccordionHeader>

          {faultItems?.map((item) => (
            <ToolOutputAccordion
              key={item.id}
              item={item}
              actions={['append', 'close']}
              enableOMDLink={false}
              expandDisabled={
                item.selectedAction === 'close' || !item.selectedAction
              }
              onActionSelect={handleAction}
              onUpdate={onUpdate}
            />
          ))}
        </div>
      </Collapse>
    </React.Fragment>
  );
};
