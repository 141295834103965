import { Add } from '@mui/icons-material';
import { Fab } from '@mui/material';
import React from 'react';

import { UploadFileProps } from 'power-tool/tool-dispo/types';

const allowedTypes = ['image/*'];

const allowedExtensions = [
  '.pdf',
  '.doc',
  '.docx',
  '.xls',
  '.xlsx',
  '.ppt',
  '.pptx',
  '.txt',
  '.drs',
];

export const UploadFileComponent = (
  props: UploadFileProps<React.ElementType>
) => {
  const onFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    // if the file picker successfully gave us a file, get it
    const file =
      event.target.files && event.target.files.length > 0
        ? event.target.files[0]
        : undefined;

    // extension will be last item after last dot, and we prepend the dot because we use it downstream
    // @ts-ignore
    const ext = `.${file?.name.split('.').at(-1) ?? ''}`;
    const type = file?.type ?? '';

    // if every allowed type does not include our uploaded type (replace wildcards because we just do an include)
    // and our uploaded type's extension is not in the allowed extension list
    // show an error message
    if (
      allowedTypes.every(
        (allowedType) => !type.includes(allowedType.replace('*', ''))
      ) &&
      !allowedExtensions.includes(ext)
    ) {
      if (props.errorHandler) {
        props.errorHandler('bad-type');
      }
    }

    // file size check - checking greater than 1MB
    else if (file && file.size / 1e6 > 1) {
      if (props.errorHandler) {
        props.errorHandler('size-limit');
      }
    }

    // success
    else {
      props.handlefileupload(event);
    }
  };

  return (
    <Fab
      size='small'
      color='primary'
      sx={{
        position: 'absolute',
        right: '45px',
        bottom: '23px',
        color: 'white',
        ...props.fabProps?.sx,
      }}
      component='label'
      {...props.fabProps}
    >
      <Add />
      <input
        onChange={onFileUpload}
        type='file'
        accept={[...allowedExtensions, ...allowedTypes].join(',')}
        hidden
      />
    </Fab>
  );
};
