import { Paper } from '@mui/material';
import React from 'react';

import { LocoDisplayType } from 'train-tool/types';

/**
 *
 * @param {any} props implicit destructured props
 * @param {LocoDisplayType} props.type locomotive display type
 * @param {number | 'EOT'} props.position position of the locomotive or 'EOT' if end of train sensor
 * @returns {JSX.Element} locomotive position label component used within a consist group
 */
export const LocoPositionLabelComponent = ({
  type,
  position,
}: {
  type: LocoDisplayType;
  position: number | 'EOT';
}) => {
  return (
    <React.Fragment>
      {type === 'lead-remote' ? <div style={{ width: '26px' }} /> : <></>}

      <div
        id='loco-position'
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: position === 'EOT' ? '95px' : '75px',
        }}
      >
        <Paper
          elevation={10}
          sx={{
            py: 0.25,
            px: 0.75,
            fontSize: '9px',
            fontFamily: 'monospace',
            backgroundColor: '#26415c',
            fontWeight: '600',
          }}
        >
          {position}
        </Paper>
      </div>
    </React.Fragment>
  );
};
