import { Button, Divider, Stack, Typography } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';
import { useState } from 'react';

import { useOktaUserInfo } from 'auth/hooks';

import { ClipboardLabel } from 'common/components/card/CardHeaderLabels';

/**
 * @returns {() => void} returns dummy hook to force a component to re-render
 */
const useForceUpdate = () => {
  const [, setValue] = useState(0);
  return () => setValue((x) => x + 1);
};

/**
 *
 * @param {any} props implicit destructured props
 * @param {string} props.label clipboard label
 * @param {string | undefined} props.value content to display
 * @returns {JSX.Element} stacked label component
 */
const LabelStack = ({
  label,
  value,
}: {
  label: string;
  value: string | undefined;
}) => {
  return (
    <Stack direction={'row'} alignItems={'center'} spacing={2}>
      <Typography>{label}</Typography>
      <ClipboardLabel
        role={'label'}
        value={value}
        sxProps={{
          alignSelf: 'flex-start',
          overflow: 'hidden',
          overflowWrap: 'anywhere',
        }}
      />
    </Stack>
  );
};

/**
 * @returns {JSX.Element} token information used for testing
 */
export const TokenInfo = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const { userInfo } = useOktaUserInfo();

  const forceUpdate = useForceUpdate();

  return (
    <Stack
      sx={{
        m: 2,
        color: 'white',
      }}
    >
      {authState?.isAuthenticated ? (
        <Stack alignContent={'flex-start'} spacing={2} divider={<Divider />}>
          <Button
            onClick={forceUpdate}
            variant='contained'
            color='primary'
            sx={{ alignSelf: 'flex-end' }}
          >
            Refresh
          </Button>
          <LabelStack label={'Name'} value={userInfo?.name} />
          <LabelStack label={'Username'} value={userInfo?.preferred_username} />
          <LabelStack
            label={'SSO'}
            value={userInfo?.ssoidORusername.toString()}
          />
          <LabelStack
            label={'Scopes'}
            value={authState.accessToken?.scopes.join(', ')}
          />
          <LabelStack label={'Issuer'} value={oktaAuth.getIssuerOrigin()} />
          <LabelStack
            label={'Token Type'}
            value={authState.accessToken?.tokenType}
          />
          <LabelStack
            label={'Access Token'}
            value={oktaAuth.getAccessToken()}
          />
          <LabelStack label={'ID Token'} value={oktaAuth.getIdToken()} />
        </Stack>
      ) : (
        <Typography variant='h5'>User is not Okta authenticated.</Typography>
      )}
    </Stack>
  );
};
