import {
  SentimentVeryDissatisfied,
  SentimentNeutral,
  SentimentVerySatisfied,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  IconContainerProps,
  Dialog,
  DialogTitle,
  Stack,
  Typography,
  DialogContent,
  TextField,
  DialogActions,
  Rating,
  Button,
} from '@mui/material';
import { useState, useMemo, useCallback } from 'react';

import { TrainCardLabel } from 'common/components/card/CardHeaderLabels';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

import { submitTrainCloseout } from 'train-tool/api';
import { useFetchTrain } from 'train-tool/hooks/apiHooks';

/**
 *
 * @param {any} props implicit destructured props
 * @param {boolean} props.open open/close state of modal
 * @param {() => void} props.onClose  callback to handle how modal should close
 * @param {() => void} props.onSubmitted callback to handle what should happen when data is submitted
 * @returns {JSX.Element} returns modal for TAT users to submit mechanical closeout
 */
export const CloseoutModal = ({
  open,
  onClose,
  onSubmitted,
  onError,
}: {
  open: boolean;
  onClose: () => void;
  onSubmitted: () => void;
  onError: () => void;
}) => {
  const { train } = useFetchTrain();
  const [loading, setLoading] = useState<boolean>(false);
  const [hover, setHover] = useState<number>(-1);

  const [notes, setNotes] = useState<string | null>(null);
  const [sentiment, setSentiment] = useState<number | null>(null);

  // each sentiment is represented by a number
  const sentimentProps: {
    [index: string]: {
      icon: React.ReactElement;
      label: string;
    };
  } = useMemo(() => {
    return {
      1: {
        icon: <SentimentVeryDissatisfied color='error' />,
        label: 'Did not help',
      },
      2: {
        icon: <SentimentNeutral />,
        label: 'Was helpful',
      },
      3: {
        icon: <SentimentVerySatisfied color='success' />,
        label: 'Solved the problem',
      },
    };
  }, []);

  const onSubmit = useCallback(() => {
    setLoading(true);

    if (train?.lookupId && notes) {
      submitTrainCloseout(train.lookupId, notes, sentiment)
        .then(() => {
          // callback to parent
          // reset form state
          setLoading(false);
          onSubmitted();
          setSentiment(null);
          setNotes(null);
        })
        .catch(() => {
          setLoading(false);
          onError();
        });
    } else {
      setLoading(false);
    }
  }, [train, notes, onError, onSubmitted, sentiment]);

  /**
   *
   * @param {IconContainerProps} props mui icon props
   * @returns {JSX.Element} span wrapper for sentiment icons
   */
  const IconContainer = (props: IconContainerProps) => {
    const { value, ...other } = props;
    return <span {...other}>{sentimentProps[value].icon}</span>;
  };

  return (
    <Dialog
      onClose={onClose}
      fullWidth
      maxWidth='md'
      open={open}
      PaperProps={{
        elevation: 0,
        sx: {
          backgroundColor: PowerToolDarkTheme.main,
        },
      }}
      disableScrollLock={true}
      sx={{}}
    >
      <DialogTitle>
        <Stack direction={'row'} spacing={5}>
          <Typography variant='h6'>Mechanical Closeout</Typography>
          <TrainCardLabel role='label' />
        </Stack>
      </DialogTitle>

      <DialogContent>
        <Stack alignItems={'center'}>
          <TextField
            fullWidth
            multiline
            value={notes}
            onChange={(event) => setNotes(event.target.value)}
            rows={2}
            sx={{ mt: 1 }}
            size='small'
            label={'Notes'}
          />
        </Stack>
      </DialogContent>

      <DialogActions>
        <Stack
          justifyContent={'space-between'}
          direction={'row'}
          flex={'1 0 0'}
        >
          <Stack minWidth={'180px'} alignItems={'flex-start'} ml={1}>
            <Rating
              highlightSelectedOnly
              IconContainerComponent={IconContainer}
              value={sentiment}
              onChange={(event, value) => setSentiment(value)}
              onChangeActive={(event, hover) => setHover(hover)}
              max={3}
              sx={{
                '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
                  color: PowerToolDarkTheme.statusGray500,
                },
              }}
            />
            <Typography
              minHeight={'18px'}
              variant='body2'
              color={PowerToolDarkTheme.statusGray300}
            >
              {hover !== -1
                ? sentimentProps[hover].label
                : sentiment
                ? sentimentProps[sentiment].label
                : ''}
            </Typography>
          </Stack>

          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <Button variant='outlined' color='primary' onClick={onClose}>
              back
            </Button>
            <LoadingButton
              onClick={onSubmit}
              disabled={
                !notes || notes.trim().length < 10 || notes.length > 4000
              }
              loading={loading}
              variant='contained'
              color='success'
            >
              submit
            </LoadingButton>
          </Stack>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
