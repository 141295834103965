import { GridOptions } from 'ag-grid-community';
import { AgGridColumn } from 'ag-grid-react';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';

import { LoadingSpinnerOverlayLoading } from 'common/components/LoadingComponents';
import { StyledPowerToolGrid } from 'common/components/ag-grid/PowerToolGrid.styled';
import { useAppSelector } from 'common/hooks';
import { selectTheme } from 'common/stores/globalSlice';

import { AgGridCustomProps, CustomColDef } from './types';

export const PowerToolGrid = <T,>(props: AgGridCustomProps<T>) => {
  const theme = useAppSelector(selectTheme);

  // data items for grid
  const [data, setData] = useState<any[] | undefined>(props.data);
  const [columns, setColumns] = useState<CustomColDef[] | undefined>(
    props.columns
  );

  const gridRef = useRef<AgGridReact>(null);
  const handleKeyDown = (event) => {
    if (
      event.which === 13 &&
      event.target.className &&
      event.target.className.indexOf('ag-input-field-input') > -1
    ) {
      gridRef.current?.api?.hidePopupMenu();
    }
  };
  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  useEffect(() => {
    setColumns(props.columns);
  }, [props.columns]);

  const customGridOptions: GridOptions = {
    // column settings
    suppressMovableColumns: true,
    defaultColDef: {
      resizable: true,
      menuTabs: ['filterMenuTab'],
      filterParams: {
        buttons: ['clear'],
        closeOnApply: true,
        defaultToNothingSelected: true,
      },
    },
    postProcessPopup: function (params) {
      params.ePopup.addEventListener('keydown', handleKeyDown);
    },

    // row settings
    rowSelection: 'single',
    suppressRowHoverHighlight: true,

    // cell settings
    suppressCellFocus: true,
    enableRangeSelection: false,
    enableCellTextSelection: true,
    rowMultiSelectWithClick: true,

    // overlayLoadingTemplate: 'loadingSpinner',
    frameworkComponents: {
      customLoadingOverlay: LoadingSpinnerOverlayLoading,
    },

    //@ts-ignore

    ...props.options,
  };

  const generateGroupedColumns = () => {
    const groupMap: Map<string, CustomColDef[]> = new Map<
      string,
      CustomColDef[]
    >();

    columns?.forEach((column) => {
      let group;
      if (!column.parameterGroup) {
        group = 'Unknown group';
      } else {
        group = column.parameterGroup.toLowerCase();
      }

      if (groupMap.has(group) && groupMap.get(group)) {
        groupMap.get(group)?.push(column);
      } else {
        groupMap.set(group, [column]);
      }
    });

    const component: JSX.Element[] = [];

    groupMap.forEach((value, key) => {
      const classNames: string[] = value[0].headerGroupClass
        ? value[0].headerGroupClass
        : [];

      component.push(
        <AgGridColumn
          key={key}
          headerName={_.startCase(_.toLower(key))}
          headerClass={classNames.map((c) => c.toLowerCase()).join(' ')}
        >
          {value.map((coldef) => (
            <AgGridColumn {...coldef} key={coldef.headerName} />
          ))}
        </AgGridColumn>
      );
    });

    return component;
  };

  const renderColumns = () => {
    if (props.enableGrouping === true) {
      return generateGroupedColumns();
    } else {
      return columns?.map((column) => (
        <AgGridColumn {...column} key={column.headerName} />
      ));
    }
  };

  return (
    <StyledPowerToolGrid>
      {/* Grid Theme Wrapper */}
      <div
        className={
          theme === 'light' ? `ag-theme-alpine` : `ag-theme-alpine-dark`
        }
        style={{ height: props.height ?? '100%', width: '100%' }}
      >
        {/* Render Grid */}
        <AgGridReact
          // height
          {...props}
          defaultColDef={{
            ...props.defaultColDef,
            cellStyle: {
              display: 'flex',
              alignItems: 'center',
              overflow: 'hidden',
              fontSize: '13px',
              paddingLeft: '5px',
              paddingRight: '5px',
            },
          }}
          rowHeight={26}
          ref={gridRef}
          rowData={data}
          key={theme}
          gridOptions={{ ...customGridOptions, ...props.gridOptions }}
          loadingOverlayComponent={'customLoadingOverlay'}
        >
          {renderColumns()}
        </AgGridReact>
      </div>
    </StyledPowerToolGrid>
  );
};
