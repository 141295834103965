import {
  ToggleButtonGroup,
  ToggleButton,
  TextField,
  Divider,
  Fade,
  AccordionDetails,
  SelectChangeEvent,
} from '@mui/material';
import chroma from 'chroma-js';
import { useEffect, useMemo, useState } from 'react';

import { UrgencyColorMap } from 'common/config/config';
import { useAppDispatch, useAppSelector } from 'common/hooks';
import { PowerToolError } from 'common/types/error';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

import {
  selectToolOutputDropdowns,
  getCaseActionState,
  setToolDispoErrorAction,
} from 'power-tool/stores/caseActionSlice';
import {
  DeliverProps,
  ToolOutputItemState,
  DeliveryAttachmentProps,
  DeliveryTargetChoice,
} from 'power-tool/tool-dispo/types';
import { CaseUrgencyChoice } from 'power-tool/types';

import { AttachmentPreviewList } from './AttachmentsComponent';
import {
  BadgedSelectComponent,
  CaseActionSelectComponent,
} from './SelectComponent';

export const DeliverComponent = (props: DeliverProps) => {
  const dispatch = useAppDispatch();

  const dropdownValues = useAppSelector(selectToolOutputDropdowns);
  const allToolOutput = useAppSelector(getCaseActionState).items;

  const [item, setItem] = useState<ToolOutputItemState>(
    props.currentToolOutput
  );
  const [notes, setNotes] = useState<string | undefined>(
    props.currentToolOutput.notes
  );
  const [supportingRxAndFaultsNotes, setSupportingRxAndFaultsNotes] = useState<
    string | undefined
  >(props.currentToolOutput.supportingRxAndFaultsNotes);
  const [relevantHistoryNotes, setRelevantHistoryNotes] = useState<
    string | undefined
  >(props.currentToolOutput.notes);
  const [gpocInstructionNotes, setGPOCInstructionNotes] = useState<
    string | undefined
  >(props.currentToolOutput.notes);
  const [attachments, setAttachments] = useState<
    DeliveryAttachmentProps[] | undefined
  >(props.currentToolOutput.availableAttachments);

  const otherToolOutput = allToolOutput?.filter((i) => i.id !== item.id);

  useEffect(() => {
    setItem(props.currentToolOutput);
  }, [props.currentToolOutput]);

  useEffect(() => {
    if (attachments) {
      props.onUpdate({
        ...item,
        availableAttachments: attachments,
      });
    }
  }, [attachments]);

  /**
   * update function for all 4 possible textbox fields
   *
   * @param {string} notes string to update with
   * @param {'notes' | 'relevantHistory' | 'GPOCInstruction' | 'supportingRx'} type which field to update
   */
  const onBlurUpdater = (
    notes: string,
    type: 'notes' | 'relevantHistory' | 'GPOCInstruction' | 'supportingRx'
  ) => {
    props.onUpdate({
      ...item,
      ...(type === 'notes' && { notes: notes }),
      ...(type === 'supportingRx' && { supportingRxAndFaultsNotes: notes }),
      ...(type === 'relevantHistory' && { relevantHistoryNotes: notes }),
      ...(type === 'GPOCInstruction' && { gpocInstructionNotes: notes }),
    });
  };

  const onDeliveryTargetChange = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: DeliveryTargetChoice | null
  ) => {
    if (value !== null) {
      props.onUpdate({
        ...item,
        deliveryTarget: value,
      });
    }
  };

  const onUrgencyChange = (event: SelectChangeEvent<string>) => {
    props.onUpdate({
      ...item,
      rxUrgency: event.target.value as CaseUrgencyChoice,
    });
  };

  const onTimeToRepairChange = (event: SelectChangeEvent<string>) => {
    props.onUpdate({
      ...item,
      rxRepairTime: event.target.value,
    });
  };

  const onAttachmentError = (reason: 'size-limit' | 'bad-type' | 'unknown') => {
    switch (reason) {
      case 'bad-type':
        dispatch(
          setToolDispoErrorAction(
            new PowerToolError(
              'Unsupported file type. Supported file types are images, MS Office formats, PDFs, and plain text.'
            )
          )
        );
        break;

      case 'size-limit':
        dispatch(
          setToolDispoErrorAction(
            new PowerToolError('Unable to upload file. Max file size is 1MB.')
          )
        );
        break;

      case 'unknown':
      default:
        dispatch(
          setToolDispoErrorAction(
            new PowerToolError('Unable to upload file. Unknown error occurred.')
          )
        );
        break;
    }
  };

  const onAttachmentUpdate = (newAttachments: DeliveryAttachmentProps[]) => {
    if (newAttachments.length > 0) {
      setAttachments(newAttachments);
    }
  };

  const isCurrentTargetAvailable: boolean = useMemo(() => {
    return (
      otherToolOutput
        ?.filter((i) => i.selectedAction === 'deliver')
        ?.find((i) => i.deliveryTarget === 'current') === undefined &&
      props.verification?.activeRxExists !== true
    );
  }, [otherToolOutput, props.verification?.activeRxExists]);

  if (props.action === 'deliver') {
    return (
      <Fade in={props.action === 'deliver'}>
        <AccordionDetails sx={{ display: 'flex', ml: 2 }}>
          <Divider orientation='vertical' flexItem />

          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: 20,
              marginLeft: '20px',
              marginRight: '20px',
              marginTop: '5px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {/* Toggle buttons (left) */}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <ToggleButtonGroup
                  exclusive
                  size='small'
                  value={
                    props.verification?.activeRxExists === true
                      ? 'new'
                      : item.deliveryTarget
                  }
                  onChange={onDeliveryTargetChange}
                >
                  {props.currentToolOutput.itemType !== 'Search' && (
                    <ToggleButton
                      disabled={allToolOutput?.length === 1}
                      value='new'
                      color='success'
                      sx={{
                        '&.Mui-selected': {
                          backgroundColor: PowerToolDarkTheme.statusGreen500,
                          color: 'white',
                        },
                        '&.Mui-selected:hover': {
                          backgroundColor: chroma(
                            PowerToolDarkTheme.statusGreen500
                          )
                            .brighten(0.5)
                            .hex(),
                        },
                      }}
                    >
                      {'new'}
                    </ToggleButton>
                  )}

                  <ToggleButton
                    disabled={!isCurrentTargetAvailable}
                    value='current'
                    sx={{
                      '&.Mui-selected': {
                        backgroundColor: PowerToolDarkTheme.statusGreen500,
                        color: 'white',
                      },
                      '&.Mui-selected:hover': {
                        backgroundColor: chroma(
                          PowerToolDarkTheme.statusGreen500
                        )
                          .brighten(0.5)
                          .hex(),
                      },
                    }}
                  >
                    {'current'}
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>

              {/* Dropdowns (right) */}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 10,
                  marginRight: '20px',
                }}
              >
                <BadgedSelectComponent
                  id='urgency'
                  value={item.rxUrgency}
                  onChange={onUrgencyChange}
                  label='Urgency'
                  items={Object.entries(dropdownValues!.urgencyOfRepair).map(
                    ([key, value]) => ({
                      value: key,
                      description: value.description,
                      badge: key,
                      color: UrgencyColorMap[key],
                    })
                  )}
                  labelProps={{
                    sx: {
                      marginTop: '-2px',
                    },
                  }}
                  selectProps={{
                    error: item?.rxUrgency === undefined,
                    sx: {
                      height: '35px',
                    },
                  }}
                />

                <CaseActionSelectComponent
                  id='time-to-repair'
                  value={item.rxRepairTime}
                  onChange={onTimeToRepairChange}
                  label='Time to Repair'
                  labelProps={{
                    sx: {
                      marginTop: '-2px',
                    },
                  }}
                  selectProps={{
                    sx: {
                      height: '35px',
                    },
                  }}
                  items={Object.entries(dropdownValues!.estRepairTimes).map(
                    ([key, value]) => ({
                      value: key,
                      description: value.description,
                    })
                  )}
                />
              </div>
            </div>
            {props.advisoryRX ? (
              <>
                <TextField
                  label={'Supporting Rx and Faults...'}
                  value={supportingRxAndFaultsNotes}
                  onChange={(event) =>
                    setSupportingRxAndFaultsNotes(event.target.value)
                  }
                  onBlur={(event) =>
                    onBlurUpdater(event.target.value, 'supportingRx')
                  }
                  rows={2}
                  fullWidth
                  multiline
                />
                <TextField
                  label={'Relevant History...'}
                  value={relevantHistoryNotes}
                  onChange={(event) =>
                    setRelevantHistoryNotes(event.target.value)
                  }
                  onBlur={(event) =>
                    onBlurUpdater(event.target.value, 'relevantHistory')
                  }
                  rows={2}
                  fullWidth
                  multiline
                />
                <TextField
                  label={'GPOC Instructions...'}
                  value={gpocInstructionNotes}
                  onChange={(event) =>
                    setGPOCInstructionNotes(event.target.value)
                  }
                  onBlur={(event) =>
                    onBlurUpdater(event.target.value, 'GPOCInstruction')
                  }
                  rows={2}
                  fullWidth
                  multiline
                />
              </>
            ) : (
              <TextField
                label={'Enter Note...'}
                value={notes}
                onChange={(event) => setNotes(event.target.value)}
                onBlur={(event) => onBlurUpdater(event.target.value, 'notes')}
                rows={2}
                fullWidth
                multiline
              />
            )}

            <AttachmentPreviewList
              onError={onAttachmentError}
              onUpdate={onAttachmentUpdate}
              attachments={attachments ?? []}
            />
          </div>
        </AccordionDetails>
      </Fade>
    );
  }

  return <></>;
};
