import {
  Divider,
  Fade,
  Paper,
  Stack,
  styled,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Zoom,
} from '@mui/material';
import chroma from 'chroma-js';
import { useState, useMemo, useEffect } from 'react';

import { ContentLabel } from 'common/components/general/ContentLabel';
import { AssetSearch } from 'common/features/search/AssetSearch';
import { TimelineEventType } from 'common/features/timeline/types';
import { useAppDispatch, useAppSelector } from 'common/hooks';
import { selectAssetDetails } from 'common/stores/globalSlice';
import {
  changeTimelineModeToggle,
  clearTimelineEvents,
  selectTimelineModeToggle,
} from 'common/stores/timelineSlice';
import { invertColor } from 'common/util/color';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

import { CallEvents } from 'loco-history/cards/Timeline/components/events/grids/CallsGrid';
import { DefectEvents } from 'loco-history/cards/Timeline/components/events/grids/DefectsGrid';
import { FaultHistory } from 'loco-history/cards/Timeline/components/events/grids/FaultHistoryGrid';
import { IncidentEvents } from 'loco-history/cards/Timeline/components/events/grids/IncidentsGrid';
import { MaterialUsageEvents } from 'loco-history/cards/Timeline/components/events/grids/MaterialUsageGrid';
import { ProgramEvents } from 'loco-history/cards/Timeline/components/events/grids/ProgramEventsGrid';
import { RxEvents } from 'loco-history/cards/Timeline/components/events/grids/RxEventsGrid';
import { ShopEvents } from 'loco-history/cards/Timeline/components/events/grids/ShopEventsGrid';
import { TimelineEventToPropsMap } from 'loco-history/config';

import { AllEvents } from './grids/AllEventsGrid';

/**
 * @returns {JSX.Element} events grid wrapper with checkboxes and filter component
 */
export const EventsWrapper = () => {
  const dispatch = useAppDispatch();

  // const mode = useAppSelector(selectTimelineMode);
  const modeToggleState = useAppSelector(selectTimelineModeToggle);
  const asset = useAppSelector(selectAssetDetails);

  const [selectedGrid, setSelectedGrid] = useState<TimelineEventType | 'all'>(
    'all'
  );

  const currentGrid = useMemo(() => {
    switch (selectedGrid) {
      case 'material':
        return <MaterialUsageEvents />;

      case 'shop':
        return <ShopEvents />;

      case 'fault':
        return <FaultHistory />;

      case 'all':
        return <AllEvents />;

      case 'incident':
        return <IncidentEvents />;

      case 'rx':
        return <RxEvents />;

      case 'call':
        return <CallEvents />;

      case 'defect':
        return <DefectEvents />;

      case 'program':
        return <ProgramEvents />;

      default:
        return <></>;
    }
  }, [selectedGrid]);

  useEffect(() => {
    setSelectedGrid('all');
  }, [modeToggleState]);

  useEffect(() => {
    if (asset && asset?.vehicleObjid > 0) {
      dispatch(clearTimelineEvents());
    }
  }, [asset, dispatch]);

  return (
    <div
      style={{
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
      }}
    >
      <ContentLabel label='Events' />
      {asset === undefined ? (
        <Fade in={asset === undefined} timeout={1000}>
          <Stack alignItems={'center'} mt={3}>
            <AssetSearch inputSx={{ minWidth: '400px' }} />
          </Stack>
        </Fade>
      ) : (
        <></>
      )}
      <Zoom in={asset !== undefined}>
        <Stack height={'100%'}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              marginBottom: '15px',
              alignItems: 'center',
            }}
          >
            <Stack
              direction={'row'}
              justifyContent='space-between'
              width={'100%'}
            >
              <Stack
                direction={'row'}
                flex='1 0 0'
                alignItems={'center'}
                justifyContent='flex-end'
              >
                <Typography variant='button' whiteSpace={'nowrap'}>
                  story Mode
                </Typography>
                {/* <EditTwoTone fontSize='large' color={modeToggleState === 'build' ? 'primary' : 'secondary'} /> */}
                <Switch
                  size='small'
                  checked={modeToggleState === 'research'}
                  onChange={() =>
                    dispatch(
                      changeTimelineModeToggle(
                        modeToggleState === 'build' ? 'research' : 'build'
                      )
                    )
                  }
                />
                <Typography variant='button' whiteSpace={'nowrap'}>
                  research Mode
                </Typography>
                {/* <BiotechTwoTone fontSize='large' color={modeToggleState === 'research' ? 'primary' : 'secondary'} /> */}
              </Stack>
            </Stack>

            <Paper
              elevation={1}
              sx={{
                mt: 0.5,
                display: 'flex',
                backgroundColor: PowerToolDarkTheme.main,
                // eslint-disable-next-line jsdoc/require-jsdoc
                border: (theme) => `1px solid ${theme.palette.divider}`,
                flexWrap: 'wrap',
                justifySelf: 'center',
              }}
            >
              <InsetToggleButtonGroup value={selectedGrid} size='small'>
                <ToggleButton
                  value={'all'}
                  onClick={() => setSelectedGrid('all')}
                >
                  All
                </ToggleButton>
              </InsetToggleButtonGroup>

              <Divider
                flexItem
                orientation='vertical'
                sx={{ mx: 0.5, my: 1 }}
              />

              <InsetToggleButtonGroup
                value={selectedGrid}
                size='small'
                disabled={modeToggleState === 'build'}
              >
                {Object.entries(TimelineEventToPropsMap)
                  .filter(([, props]) => props.hideFromGrid !== true)
                  .map(([event, props]) => {
                    return (
                      <ToggleButton
                        value={event}
                        key={event}
                        sx={{
                          ':hover': {},
                          '&.Mui-selected': {
                            color: invertColor(
                              props.style.backgroundColor,
                              true
                            ),
                            backgroundColor: props.style.backgroundColor,
                          },
                          '&.Mui-selected:hover': {
                            backgroundColor: props.style.backgroundColor
                              ? chroma(props.style.backgroundColor)
                                  .brighten(0.5)
                                  .hex()
                              : undefined,
                          },
                        }}
                        onClick={() =>
                          setSelectedGrid(event as TimelineEventType)
                        }
                      >
                        {props.label}
                      </ToggleButton>
                    );
                  })}
              </InsetToggleButtonGroup>
            </Paper>
          </div>

          <div
            style={{
              height: '100%',
              width: '100%',
            }}
          >
            {currentGrid}
          </div>
        </Stack>
      </Zoom>
    </div>
  );
};

const InsetToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.5),
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));
