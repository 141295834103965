import { MapMarkerTooltipProps } from 'common/cards/Map/types';
import { useUserFormattedTime } from 'common/hooks';

export const MapMarkerTooltipComponent = (props: MapMarkerTooltipProps) => {
  //default values to make checks easier later
  const na = 'N/A';

  //location formatting to ex. "Seattle, WA"
  const location =
    props.marker?.state !== undefined && props.marker?.name !== undefined
      ? props.marker?.name.charAt(0).toUpperCase() +
        props.marker?.name.slice(1) +
        ', ' +
        props.marker?.state.toUpperCase()
      : na;

  const date = useUserFormattedTime(props.marker?.date);

  if (props.marker) {
    return (
      <div>
        <span>
          <b>Weather: </b>{' '}
          {props.marker.weather !== undefined && props.marker.weather !== ''
            ? props.marker.weather
            : na}{' '}
          <br />
          <b>Temperature: </b>{' '}
          {props.marker.tempF !== undefined ? props.marker.tempF + '°F' : na}{' '}
          <br />
          <b>Location: </b> {location} <br />
          <b>Precipitation: </b>{' '}
          {props.marker.precipIN !== undefined
            ? props.marker.precipIN + 'in'
            : na}{' '}
          <br />
          <b>Wind Speed: </b>{' '}
          {props.marker.windSpeedMPH !== undefined &&
          props.marker.windSpeedMPH !== ''
            ? props.marker.windSpeedMPH + 'mph'
            : na}{' '}
          <br />
          <b>Elevation: </b>{' '}
          {props.marker.elevFT !== undefined ? props.marker.elevFT + 'ft' : na}{' '}
          <br />
          <b>Date: </b> {date ?? na} <br />
        </span>
      </div>
    );
  } else {
    return <div />;
  }
};
