import { CSSProperties } from 'react';

export const TextChip = (props: {
  label: string | JSX.Element;
  style?: CSSProperties;
  backgroundColor?: string;
  color?: string;
  textProps?: CSSProperties;
}) => {
  return (
    <div style={{ ...props.style }}>
      <span
        style={{
          color: props.color ?? 'white',
          fontWeight: 'bold',
          marginLeft: '2px',
          backgroundColor: props.backgroundColor ?? 'rgba(255, 255, 255, 0.16)',
          padding: '2px 10px',
          borderRadius: '12px',
          ...props.textProps,
        }}
      >
        {props.label}
      </span>
    </div>
  );
};
