import {
  AgChartThemeOverrides,
  AgCartesianSeriesTooltipRendererParams,
  ExcelStyle,
  CsvExportParams,
  ExcelExportParams,
  CellClassRules,
  CellClassParams,
} from 'ag-grid-community';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useRef } from 'react';

import useGridCallbacks from 'common/cards/Data/callbacks';
import { dataCardTimestampFormat } from 'common/cards/Data/const';
import { useAppSelector } from 'common/hooks';
import { selectAssetDetails } from 'common/stores/globalSlice';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

// contains all 'static' grid options - i.e. properties
// that are not 'functions'
// these generally should all be memoized callbacks
export const useGridProps = () => {
  // ref updates
  const asset = useAppSelector(selectAssetDetails);
  const assetRef = useRef(asset);
  useEffect(() => {
    assetRef.current = asset;
  }, [asset]);

  // helper hooks & callbacks
  const { processCellCallback } = useGridCallbacks();

  const chartThemeOverrides: AgChartThemeOverrides = useMemo(
    () => ({
      common: {
        background: { fill: PowerToolDarkTheme.main },
        navigator: {
          enabled: true,
          height: 7,
          min: 0.0001,
          max: 1,
          minHandle: {
            fill: PowerToolDarkTheme.main,
            stroke: PowerToolDarkTheme.tertiary,
            strokeWidth: 1,
            gripLineGap: 2,
          },
          maxHandle: {
            fill: PowerToolDarkTheme.main,
            stroke: PowerToolDarkTheme.tertiary,
            strokeWidth: 1,
            gripLineGap: 2,
          },
        },
        title: {
          enabled: true,
          fontFamily: 'Roboto',
          text: 'Custom Plot - Double Click to Edit',
        },
        axes: {
          number: {
            label: {
              fontSize: 10,
              fontFamily: 'Roboto',
            },
          },
          time: {
            label: {
              fontSize: 10,
              fontFamily: 'Roboto',
              rotation: 335,
              formatter: (params) => {
                return DateTime.fromJSDate(params.value).toLocaleString(
                  DateTime.DATE_SHORT
                );
              },
            },
          },
          category: {
            label: {
              fontSize: 10,
              fontFamily: 'Roboto',
              rotation: 335,
            },
            gridStyle: [
              {
                lineDash: undefined,
              },
            ],
          },
        },
        legend: {
          position: 'bottom',
          item: {
            label: {
              fontSize: 12,
              fontFamily: 'Roboto',
            },
            marker: {
              size: 8,
            },
          },
        },
        tooltip: {
          class: 'plot-tooltip',
        },
      },
      line: {
        series: {
          strokeWidth: 1,
          cursor: 'pointer',
          tooltip: {
            renderer: (params: AgCartesianSeriesTooltipRendererParams) => {
              const value = params.xValue,
                tzone = assetRef.current?.unitTimeZone;
              if (value && tzone) {
                // once the value is in the grid, it is stored as a js date
                // try and parse it and convert to the unit's timestamp
                const timestamp = DateTime.fromJSDate(params.xValue, {
                  zone: tzone,
                });

                // if we can parse it, return it as a formatted string
                if (timestamp.isValid) {
                  return `<div class="ag-chart-tooltip-title">
                  ${params.title}
                  </div>
                  <div class="ag-chart-tooltip-content">
                  <div><b>Value</b>: ${params.yValue}</div>
                  <div><b>Date</b>: ${timestamp.toFormat(
                    dataCardTimestampFormat
                  )}</div>
                  </div>
                  `;
                }
              }

              // otherwise, return whatever the grid already has
              return `<div class="ag-chart-tooltip-title">
              ${params.title}
              </div> 
              <div class="ag-chart-tooltip-content">
              <div><b>Value</b>: ${params.yValue}</div>
              <div><b>Date: ${params.xValue}</b></div>
              </div>
              `;
            },
          },
        },
      },
    }),
    [asset]
  );

  const excelStyles = useMemo((): ExcelStyle[] => {
    return [
      {
        id: 'dateTime',
        dataType: 'DateTime',
        numberFormat: {
          format: 'yyy-mm-ddThh:mm:ss',
        },
      },
    ];
  }, []);

  const defaultExportParams = useMemo(():
    | CsvExportParams
    | ExcelExportParams => {
    return {
      skipColumnGroupHeaders: true,
      processCellCallback,
    };
  }, []);

  const cellClassRules = useMemo((): CellClassRules => {
    return {
      'ag-right-aligned-cell': (params: CellClassParams) => {
        return !params.column.isPinned();
      },
    };
  }, []);

  return {
    chartThemeOverrides,
    excelStyles,
    cellClassRules,
    defaultCsvExportParams: defaultExportParams as CsvExportParams,
    defaultExcelExportParams: defaultExportParams as ExcelExportParams,
  };
};
