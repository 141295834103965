import { useTheme } from 'styled-components';

import { CustomerLogo } from 'train-tool/components/CustomerLogoComponent';

export const Logo = () => {
  const theme = useTheme();

  return (
    <div>
      <img
        src={theme.logo}
        alt='Wabtec Logo'
        style={{
          width: '110px',
          marginRight: '10px',
          marginLeft: '5px',
        }}
      />
      <CustomerLogo />
    </div>
  );
};
