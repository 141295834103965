import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';

import {
  TimelineEvent,
  TimelineEventType,
} from 'common/features/timeline/types';
import { RootState } from 'common/store';

export type TimelineState = {
  events: TimelineEvent[];
  // stories: StoryEvent[];
  selectedEventTypes: TimelineEventType[];
  searchText?: string;
  mode: 'research' | 'build';
  modeToggle: 'research' | 'build';

  // we need to 'freeze' now while the user is using the timeline
  // otherwise if we use DateTime.now() everywhere, we run the risk
  // of inconsistent results
  now: DateTime;
};

const initialState: TimelineState = {
  events: [],
  // stories: [],
  now: DateTime.now(),
  mode: 'research',
  modeToggle: 'research',
  selectedEventTypes: [
    // 'case',
    'incident',
    'material',
    'rx',
    'shop',
    'call',
    'defect',
    'program',
  ],
};

export const timelineSlice = createSlice({
  name: 'timeline',
  initialState,
  reducers: {
    addEvent: (state, action: PayloadAction<TimelineEvent>) => {
      if (state.events.map((e) => e.id).includes(action.payload.id)) {
        return;
      }
      state.events = [...state.events, action.payload];
    },
    updateEvent: (state, action: PayloadAction<TimelineEvent>) => {
      state.events = state.events.map((event) => {
        if (event.id === action.payload.id) {
          return action.payload;
        }
        return event;
      });
    },
    removeEvent: (state, action: PayloadAction<string>) => {
      state.events = state.events.filter(
        (event) => event.id !== action.payload
      );
    },
    clearEvents: (state, action: PayloadAction<void>) => {
      state.events = [];
    },
    addSelectedEvent: (state, action: PayloadAction<TimelineEventType>) => {
      if (state.selectedEventTypes.includes(action.payload)) {
        return;
      }
      state.selectedEventTypes = [...state.selectedEventTypes, action.payload];
    },
    removeSelectedEvent: (state, action: PayloadAction<TimelineEventType>) => {
      state.selectedEventTypes = state.selectedEventTypes.filter(
        (e) => e !== action.payload
      );
    },
    updateSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
    },
    changeMode: (state, action: PayloadAction<'build' | 'research'>) => {
      state.mode = action.payload;
    },
    changeModeToggle: (state, action: PayloadAction<'build' | 'research'>) => {
      state.modeToggle = action.payload;
    },
  },
});

export const {
  addEvent: addTimelineEvent,
  updateEvent: updateTimelineEvent,
  removeEvent: removeTimelineEvent,
  clearEvents: clearTimelineEvents,
  addSelectedEvent: addSelectedTimelineEventType,
  removeSelectedEvent: removeSelectedTimelineEventType,
  updateSearchText,
  changeMode: changeTimelineMode,
  changeModeToggle: changeTimelineModeToggle,
} = timelineSlice.actions;

export const selectTimelineEvents = (state: RootState) => state.timeline.events;
export const selectTimelineNow = (state: RootState) => state.timeline.now;
export const selectSelectedTimelineEventTypes = (state: RootState) =>
  state.timeline.selectedEventTypes;
export const selectSearchText = (state: RootState) => state.timeline.searchText;
export const selectTimelineMode = (state: RootState) => state.timeline.mode;
export const selectTimelineModeToggle = (state: RootState) =>
  state.timeline.modeToggle;
export default timelineSlice.reducer;
