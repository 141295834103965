import axios, { Canceler } from 'axios';

import { IngestedData } from 'common/features/ingested-data/types';

import {
  OutboundMacroResponse,
  WeatherForFaultPayload,
  OnDemandWeatherResponse,
} from './types';

let cancelOutbound: Canceler | undefined;

export const getOutboundResults = (
  vehicleObjid: number,
  rows: IngestedData[]
): Promise<OutboundMacroResponse[]> => {
  if (cancelOutbound) {
    cancelOutbound();
    cancelOutbound = undefined;
  }
  return axios
    .post<OutboundMacroResponse[]>(
      `/outbound/verification/${vehicleObjid}`,
      rows,
      {
        cancelToken: new axios.CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancelOutbound = c;
        }),
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log("Error calling API endpoint for outbound's", e);
      return [];
    });
};

export const getWeatherForFaults = (
  props: WeatherForFaultPayload[]
): Promise<OnDemandWeatherResponse[]> => {
  //date expects 'MM/dd/yyyy HH:mm:ss'
  return axios
    .post<OnDemandWeatherResponse[]>('/onDemandWeather', props)
    .then((response) => response.data)
    .catch((e) => {
      console.log(`Error getting weather for faults`);
      throw e;
    });
};
