import { TooltipProps, tooltipClasses } from '@mui/material';
import styled from 'styled-components';

import { LazyTooltip } from './LazyTooltip';

// https://mui.com/material-ui/react-tooltip/#variable-width
export const BigTooltip = styled(({ className, ...props }: TooltipProps) => (
  <LazyTooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: '600px',
  },
});
