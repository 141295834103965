import { Stack, Divider, Typography } from '@mui/material';
import React from 'react';

import { RX } from 'common/types/types';

import {
  AnalysisCardAlertType,
  AnyAnalysisCardAlert,
} from 'train-tool/cards/Analysis/types';
import {
  DPAlarmDetails,
  ActiveFault,
  TrainSenseOutput,
} from 'train-tool/types';

import { AlarmDetailView } from './detail-views/AlarmDetailView';
import { FaultDetailView } from './detail-views/FaultDetailView';
import { RXDetailView } from './detail-views/RXDetailView';

/**
 *
 * @param {any} props implicit component props
 * @param {AnalysisCardAlertType} props.type source type of the alert
 * @param {AnyAnalysisCardAlert} props.source source object of the alert
 * @returns {JSX.Element} generic detail component wrapper for any given alert
 */
export const AlertDetailViewComponent = (props: {
  type: AnalysisCardAlertType;
  source?: AnyAnalysisCardAlert;
}) => {
  switch (props.type) {
    case 'alarm':
      const alarm = props.source as DPAlarmDetails;
      return <AlarmDetailView alarm={alarm} />;

    case 'rx':
      const rx = props.source as RX;
      return <RXDetailView rx={rx} />;

    case 'fault':
      const fault = props.source as ActiveFault;
      return <FaultDetailView fault={fault} />;

    case 'sense':
      const sense = props.source as TrainSenseOutput;
      return (
        <React.Fragment>
          <Stack
            direction={'row'}
            alignItems='center'
            spacing={3}
            sx={{ ml: 1 }}
            divider={<Divider orientation='vertical' flexItem />}
          >
            <Stack direction={'row'} alignItems='flex-start' spacing={1}>
              <Typography variant='body2'>
                {sense.message ?? 'No additional details available'}
              </Typography>
            </Stack>
          </Stack>
        </React.Fragment>
      );

    default:
      return <></>;
  }
};
