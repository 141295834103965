import { Fade, Typography, Collapse, Paper } from '@mui/material';
import chroma from 'chroma-js';
import React, { useState } from 'react';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

import { AnalysisCardCategoryMap } from 'train-tool/cards/Analysis/config';
import {
  AlertTypeDisplayProps,
  AnalysisCardAlertType,
} from 'train-tool/cards/Analysis/types';

const AlertDetailViewRefWrapper = React.forwardRef<
  HTMLDivElement,
  { component?: JSX.Element }
>((props, ref) => {
  return (
    <Paper
      variant='outlined'
      ref={ref}
      style={{ backgroundColor: PowerToolDarkTheme.main }}
      sx={{ py: 2, pb: 2, pt: 0.25 }}
    >
      {props.component}
    </Paper>
  );
});
AlertDetailViewRefWrapper.displayName = 'AlertDetailViewRef';

/**
 *
 * @param {any} props implicit component props
 * @param {AlertTypeDisplayProps} props.alertTypeProps display properties for the given alert
 * @param {string} props.title title of the alert
 * @param {AnalysisCardAlertType} props.type type of the alert
 * @param  {JSX.Element?} props.detailView optional component to display when alert is clicked and expanded
 * @returns {JSX.Element} generic alert wrapper component for a train analysis alert; handles the expanded state for detail view
 */
export const TrainAlertComponent = (props: {
  alertTypeProps: AlertTypeDisplayProps;
  title: JSX.Element | string;
  type: AnalysisCardAlertType;
  detailView?: JSX.Element;
}) => {
  const [isDetailView, setIsDetailView] = useState<boolean>(false);

  return (
    <Fade in={true}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '15px',
          width: '100%',
        }}
      >
        <div
          style={{
            width: '60px',
            color: 'white',
            backgroundColor: `${props.alertTypeProps.color}AA`, // PowerToolDarkTheme.neutralDark800,
            borderRadius: '6px',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            height: '24px',
            flexShrink: 0,
          }}
        >
          <Typography sx={{ fontSize: '11px' }} variant={'button'}>
            {AnalysisCardCategoryMap[props.type].label}
          </Typography>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',

            fontSize: '12px',
            border: `1px solid ${chroma(props.alertTypeProps.color).hex()}`,
            borderRadius: '4px',
            padding: '4px',
            flexGrow: 1,
            maxHeight: '350px',
            overflow: 'auto',
          }}
        >
          <div
            onClick={
              props.detailView !== null
                ? () => setIsDetailView(!isDetailView)
                : undefined
            }
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '12px',
              cursor: props.detailView !== null ? 'pointer' : 'default',
              // position: 'sticky',
              // top: 0,
            }}
          >
            <div
              style={{
                color: props.alertTypeProps.color,
                width: '22px',
                height: '22px',
              }}
            >
              {props.alertTypeProps.icon}
            </div>

            <Typography
              variant='body2'
              style={{
                color: chroma(props.alertTypeProps.color).brighten(2.5).hex(),
              }}
            >
              {props.title}
            </Typography>
          </div>

          <Collapse in={isDetailView} unmountOnExit>
            <AlertDetailViewRefWrapper component={props.detailView} />
          </Collapse>
        </div>
      </div>
    </Fade>
  );
};
