import { SxProps } from '@mui/material';
import React, { useCallback } from 'react';

import {
  SearchOption,
  PowerToolSearch,
} from 'common/features/search/PowerToolSearch';
import { useAppDispatch } from 'common/hooks';
import { Asset } from 'common/types/types';

import { useFetchTrainsAndAssets } from 'train-tool/hooks/apiHooks';
import { setTrainSearchItem } from 'train-tool/stores/trainSlice';
import { TrainSearchItem } from 'train-tool/types';

export type TrainAndAssetSearchProps = {
  inputSx?: SxProps;
};

/**
 *
 * @param {TrainAndAssetSearchProps} props SxProps for MUI component
 * @returns {JSX.Element} customized MUI search element containing trains and assets
 */
export const TrainAndAssetSearch = (props: TrainAndAssetSearchProps) => {
  const dispatch = useAppDispatch();

  const { searchResults } = useFetchTrainsAndAssets();

  const onSelect = useCallback(
    (selectedValue: SearchOption) => {
      switch (selectedValue.type) {
        case 'asset':
          dispatch(
            setTrainSearchItem({
              item: selectedValue.value as Asset,
              type: 'asset',
            })
          );
          break;
        case 'train':
          dispatch(
            setTrainSearchItem({
              item: selectedValue.value as TrainSearchItem,
              type: 'train',
            })
          );
          break;
      }
    },
    [dispatch]
  );

  return (
    <React.Fragment>
      <PowerToolSearch
        optionList={searchResults}
        onSelect={onSelect}
        groupBy={(option) => (option.type === 'asset' ? 'Asset' : 'Train')}
        placeholder={`Search for train or loco`}
        inputSx={props.inputSx}
      />
    </React.Fragment>
  );
};
