import { BugReport, CloseOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { useAppDispatch, useAppSelector } from 'common/hooks';
import { selectUser, selectAssetDetails } from 'common/stores/globalSlice';
import { PowerToolError } from 'common/types/error';
import { getApplicationRuntime } from 'common/util/utils';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

import {
  getCaseActionState,
  selectToolOutputVerification,
  selectToolDispoProcessingState,
  selectIsAddRxEnabled,
  setToolDispoProcessingState,
  refreshToolDispoAction,
  setToolDispoErrorAction,
  setErrorModalOpen,
  selectRxCustomized,
  selectOpenedRx,
  updateItem,
  addItem,
} from 'power-tool/stores/caseActionSlice';
import { selectCase } from 'power-tool/stores/casesSlice';
import { submitToolOutput, submitReclose } from 'power-tool/tool-dispo/api';
import { ToolDispoWarning } from 'power-tool/tool-dispo/components/ToolDispoWarning';
import { RxSearch } from 'power-tool/tool-dispo/features/RxSearch';
import {
  useFetchCustomRx,
  useFetchAdvisoryRx,
  useFetchMandatoryTitles,
} from 'power-tool/tool-dispo/hooks';
import {
  CaseActionPayload,
  CustomizeRxDetails,
  ToolOutputItemState,
  ToolOutputModalProps,
  ValidationList,
} from 'power-tool/tool-dispo/types';
import { notesFormatter } from 'power-tool/tool-dispo/utils';

import { ToolOutputModalItems } from './ToolOutputModalItems';

export const ToolOutputModal = (props: ToolOutputModalProps) => {
  const dispatch = useAppDispatch();

  const state = useAppSelector(getCaseActionState);
  const user = useAppSelector(selectUser);
  const selectedCase = useAppSelector(selectCase);
  const verification = useAppSelector(selectToolOutputVerification);
  const assetDetails = useAppSelector(selectAssetDetails);
  const loadingState = useAppSelector(selectToolDispoProcessingState);
  const isAddRxEnabled = useAppSelector(selectIsAddRxEnabled);
  const rxCustomized = useAppSelector(selectRxCustomized);
  const _selectedRx = useAppSelector(selectOpenedRx);
  const selectedRx = useRef(_selectedRx);

  const { mandatoryTitles } = useFetchMandatoryTitles();
  const { advisoryRx } = useFetchAdvisoryRx();
  const { customRx } = useFetchCustomRx(rxCustomized?.rxId);

  const loading = loadingState === 'processing';

  const errorModalOpen = state.errorModalOpen;
  const errorModalMessage = state.error;

  const [toolOutputModalOpen, setToolOutputModalOpen] = useState<boolean>(
    props.open
  );
  const [recloseModalOpen, setRecloseModalOpen] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setToolOutputModalOpen(props.open);
  }, [props.open]);

  useEffect(() => {
    selectedRx.current = _selectedRx;
  }, [_selectedRx]);

  useEffect(() => {
    if (advisoryRx && verification?.activeRxExists !== true) {
      dispatch(
        addItem({
          id: uuid(),
          isExpanded: false,
          availableAttachments: [],
          deliveryTarget: 'current',
          rxUrgency: 'A',
          itemType: 'advisory',
          ...advisoryRx,
        })
      );
    }
  }, [advisoryRx, verification]);

  const warning = useMemo(() => {
    if (state.showWarnings === false) {
      return undefined;
    }

    if (
      state.items?.find(
        (item) =>
          item.selectedAction === 'close' && item.itemType === 'advisory'
      )
    ) {
      if (
        mandatoryTitles !== undefined &&
        selectedCase?.caseTitle !== undefined &&
        mandatoryTitles?.find(
          (title) =>
            title.lookValue !== undefined &&
            title.lookValueDesc === '1' &&
            selectedCase?.caseTitle.includes(title.lookValue)
        ) !== undefined
      ) {
        return `An Advisory Rx is required`;
      }
    }

    if (verification?.bomMismatchRxList?.includes('Y')) {
      return 'Rx(s) of other Customer(s) / Fleet(s) are returned. Please select the appropriate Rx and proceed.';
    } else if (
      verification?.bomMismatchRxList &&
      verification.bomMismatchRxList.length > 0
    ) {
      return `Rx "${verification.bomMismatchRxList[0]}" does not have a matching config for the asset.`;
    }

    return undefined;
  }, [
    verification,
    state.showWarnings,
    state.items,
    mandatoryTitles,
    selectedCase,
  ]);

  const onClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (!loading) {
      setToolOutputModalOpen(false);
      props.onClose(event, reason);
    }
  };
  useEffect(() => {
    if (loadingState === 'ready') {
      enqueueSnackbar('Tools and Cases cards refreshed', { variant: 'info' });
    }
  }, [loadingState, enqueueSnackbar]);

  useEffect(() => {
    if (customRx && customRx.length > 0 && selectedRx.current) {
      const rx: CustomizeRxDetails = customRx[0];
      const originalRx = selectedRx.current;

      const replacedRx: ToolOutputItemState = {
        ...originalRx,
        isCustomized: true,
        rxId: parseInt(rx.rxId),
        rxTitle: rx.rxTitle,
        rxUrgency: rx.rxUrgency,
        rxRepairTime: rx.rxRepairTime,
        isFlowchartOnly: rx.isFlowchartOnly,
      };

      dispatch(updateItem(replacedRx));

      enqueueSnackbar(`Replaced ${originalRx.rxTitle} with custom RX.`, {
        variant: 'success',
        persist: true,
      });
    }
  }, [customRx, dispatch, enqueueSnackbar]);

  const validityChecks: Set<ValidationList> = useMemo(() => {
    const reasons: Set<ValidationList> = new Set();
    const hasAddedRx =
      state.items?.filter((item) => item.itemType === 'Search').length ?? 0 > 0;

    if (state.items) {
      if (state.items.length <= 0 && verification?.activeRxExists) {
        reasons.add({ reason: 'Nothing to submit', type: 'Error' });
      } else {
        for (const item of state.items) {
          // we dont do any validation for disabled action tool output
          if (
            verification?.disableAllActionsFor?.includes(item.rxId.toString())
          ) {
            continue;
          }

          // if we are currently a CBR tool output, and the other
          //   JDPAD tool output(s) contains the current (CBR) rxId,
          //   we disable the current tool output
          // in other words, if a JDPAD and CBR tool output both point
          // to the same RX, the JDPAD output takes priority
          // so this output will be disabled, and we shouldn't do any validation
          if (
            item.toolId === 'CBR' &&
            state.items
              .filter((output) => output.toolId === 'JDPAD')
              .map((output) => output.rxId)
              .includes(item.rxId)
          ) {
            continue;
          }

          if (item.toolId === 'JDPAD' || item.toolId === 'CBR') {
            // if an action is not selected, we disable
            if (
              item.selectedAction === null ||
              (item.selectedAction === undefined && !hasAddedRx)
            ) {
              reasons.add({
                reason: 'A case action must be selected for JDPAD/CBR output',
                type: 'Error',
              });
              // return true;
            }
          }

          // if an item is selected for close, it must have a close reason selected
          if (item.selectedAction === 'close') {
            if (item.closeReason === undefined || item.closeReason === '') {
              reasons.add({
                reason: 'Close reason not selected',
                type: 'Error',
              });
              // return true;
            }
          }

          // for append, we just need to make sure something is selected
          else if (item.selectedAction === 'append') {
            if (item.appendTarget === undefined || item.appendTarget === '') {
              reasons.add({
                reason: 'Append target not selected',
                type: 'Error',
              });
              // return true;
            }
          }

          // for merge, we also just need to make sure something is selected
          // the validation for which outputs are 'eligible' for merge is
          // handled in the merge component itself
          else if (item.selectedAction === 'merge') {
            if (item.mergeTarget === undefined || item.mergeTarget === '') {
              reasons.add({
                reason: 'Merge target not selected',
                type: 'Error',
              });
              // return true;
            }
          }

          // for deliver, we just make sure mandatory fields are present
          //   urgency, time to repair
          // other validation like only one delivery to current case, attachment validation, etc
          //   are handled in the child components of deliver
          else if (item.selectedAction === 'deliver') {
            if (
              item.rxUrgency === undefined ||
              item.rxUrgency?.toString() === '' ||
              item.rxRepairTime === '' ||
              item.rxRepairTime === undefined
            ) {
              reasons.add({
                reason: 'Urgency or Time to Repair not selected',
                type: 'Error',
              });
              // return true;
            }
          }
        }

        //if an item is a advisory item and being delivered, we ensure all notes fields are entered
        for (const item of state.items) {
          if (
            item.selectedAction === 'deliver' &&
            item.itemType === 'advisory'
          ) {
            if (
              item.supportingRxAndFaultsNotes === undefined ||
              item.supportingRxAndFaultsNotes.length <= 0
            ) {
              reasons.add({
                reason: `Alert: "Supporting Rx and Fault Notes" field is empty`,
                type: 'Warning',
              });
            }
            if (
              item.gpocInstructionNotes === undefined ||
              item.gpocInstructionNotes.length <= 0
            ) {
              reasons.add({
                reason: `Alert: "GPOC Instructions" field is empty`,
                type: 'Warning',
              });
            }
            if (
              item.relevantHistoryNotes === undefined ||
              item.relevantHistoryNotes.length <= 0
            ) {
              reasons.add({
                reason: `Alert: "Relevant History" field is empty`,
                type: 'Warning',
              });
            }
          }
        }

        // then we check to see if we only have critical faults,
        // that at least one action is selected
        const allCriticalFaults =
          state.items.find(
            (item) => item.toolId === 'JDPAD' || item.toolId === 'CBR'
          ) === undefined;

        // if we have all critical faults, and every item has a null type, disable
        if (
          allCriticalFaults &&
          state.items.every((item) => !item.selectedAction)
        ) {
          // we got to the end of all critical faults, and nothing was selected so disable
          reasons.add({
            reason: 'At least one critical fault must handled',
            type: 'Error',
          });
          // return true;
        }

        // at this point, we know we are not all critical faults, so we need to check
        // that for all output where we do not disable actions, that something is selected
        const noSelectionOutput = state.items
          .filter(
            (item) =>
              !verification?.disableAllActionsFor?.includes(
                item.rxId.toString()
              )
          )
          .filter((item) => item.toolId !== 'Fault');

        for (const item of noSelectionOutput) {
          // if we are currently a CBR tool output, and the other
          //   JDPAD tool output(s) contains the current (CBR) rxId,
          //   we disable the current tool output
          // in other words, if a JDPAD and CBR tool output both point
          // to the same RX, the JDPAD output takes priority
          // so this output will be disabled, and we shouldn't do any validation
          if (
            item.toolId === 'CBR' &&
            state.items
              .filter((output) => output.toolId === 'JDPAD')
              .map((output) => output.rxId)
              .includes(item.rxId)
          ) {
            continue;
          }

          if (
            (!item.selectedAction ||
              verification?.disableAllActionsFor?.includes('ALL')) &&
            !hasAddedRx
          ) {
            if (reasons.size <= 0) {
              reasons.add({ reason: 'Nothing to submit', type: 'Error' });
            }
          }
        }

        // or if every item we have is disabled, and the flag didn't return as 'ALL', we disable .. :(
        if (
          state.items.every((item) =>
            verification?.disableAllActionsFor?.includes(item.rxId.toString())
          ) &&
          !hasAddedRx
        ) {
          // this reason doesn't make a lot of sense unless its the only failure
          if (reasons.size <= 0) {
            reasons.add({ reason: 'Nothing to submit', type: 'Error' });
          }
          // return true;
        }

        const deliveries = state.items.filter(
          (item) => item.selectedAction === 'deliver'
        );

        // if all deliveries are marked as new and there is not an active RX, we disable
        if (
          deliveries.length > 0 &&
          deliveries.every((item) => item.deliveryTarget === 'new') &&
          !verification?.activeRxExists
        ) {
          reasons.add({
            reason: 'One output must be delivered to the current case',
            type: 'Error',
          });
        }

        // finally -> valid
      }
    }

    // shouldn't get here, but if no items, disable
    else {
      // this reason doesn't make a lot of sense unless its the only failure
      if (reasons.size <= 0)
        reasons.add({ reason: 'Nothing to submit', type: 'Error' });
      // return true;
    }

    return reasons;
  }, [state.items, verification]);

  const showContinueButton = useMemo(() => {
    //if there is an Error we do not want to allow Users to be able to continue
    return (
      Array.from(validityChecks).find((item) => item.type === 'Warning') !==
      undefined
    );
  }, [validityChecks]);

  const onSubmit = () => {
    dispatch(setToolDispoProcessingState('processing'));

    const deliveries = state.items?.filter(
      (item) => item.selectedAction === 'deliver'
    );
    const merges = state.items?.filter(
      (item) => item.selectedAction === 'merge'
    );
    const appends = state.items?.filter(
      (item) => item.selectedAction === 'append'
    );
    const closures = state.items?.filter(
      (item) => item.selectedAction === 'close'
    );

    const payload: CaseActionPayload = {
      caseId: selectedCase?.caseId,
      userId: user?.userName,
      vehicleId: assetDetails!.vehicleObjid,
      userLanguage: user?.preferenceLanguage ?? 'en',

      // ==== deliver =====
      deliverActions: deliveries?.map((delivery: ToolOutputItemState) => ({
        deliverNewCase: delivery.deliveryTarget === 'new',
        mdscNotes: notesFormatter(
          delivery.notes,
          delivery.supportingRxAndFaultsNotes,
          delivery.relevantHistoryNotes,
          delivery.gpocInstructionNotes
        ),
        urgency: delivery.rxUrgency,
        repairTime: delivery.rxRepairTime,
        rxId: delivery.rxId,
        arListObjid: delivery.arListObjid,
        toolId: delivery.toolId,
        isAddRx: delivery.itemType === 'Search',
        attachments: delivery.availableAttachments
          .filter((attachment) => attachment.isAttached)
          .map((attachment) => ({
            docTitle:
              attachment.type === 'plot'
                ? `${attachment.label}.png`
                : attachment.label,
            docData: attachment.src.split(';base64,')[1],
          })),
      })),

      // ==== merge =====
      mergeActions: merges?.map((merge: ToolOutputItemState) => ({
        mergeTo: merge.mergeTarget!,
        rxId: merge.rxId,
        arListObjid: merge.arListObjid,
        toolId: merge.toolId,
      })),

      // ==== append =====
      appendActions: appends?.map((append: ToolOutputItemState) => ({
        toCaseId: append.appendTarget!,
        rxId: append.rxId,
        toolId: append.toolId,
        arListObjid: append.arListObjid,
      })),

      // ==== close =====
      closeActions: closures?.map((closure: ToolOutputItemState) => ({
        closeReason: closure.closeReason!,
        rxId: closure.rxId,
        toolId: closure.toolId,
        arListObjid: closure.arListObjid,
        itemType: closure.itemType,
      })),
    };

    // console.log(payload);
    // downloadToBrowser(payload);

    // on submit we:
    //  refresh a number of cards
    //  refresh OMD's main case screen
    //  close modal on success
    // timeoutPromise(1000).then(response => {
    submitToolOutput(payload)
      .then((response) => {
        // console.log('success', response);
        onSuccessfulSubmit();
      })
      .catch((err) => {
        onErrorSubmit(err);
      });
  };

  const onSubmitVerify = (event) => {
    if (validityChecks.size > 0) {
      onErrorSubmit(
        new PowerToolError(
          Array.from(validityChecks)
            .map((item) => `• ${item.reason}`)
            .join('\n')
        )
      );
      return;
    } else {
      onSubmit();
    }
  };

  const onReclose = () => {
    if (selectedCase?.caseId && user?.userName) {
      submitReclose(selectedCase.caseId, user.userName)
        .then((response) => {
          // console.log('success', response);
          onSuccessfulSubmit();
        })
        .catch((error) => {
          onErrorSubmit(error);
        });
    }
  };

  const onSuccessfulSubmit = () => {
    dispatch(refreshToolDispoAction());
    onClose({}, 'backdropClick');
  };

  const onErrorSubmit = (err: PowerToolError) => {
    dispatch(setToolDispoErrorAction(err));
    dispatch(setToolDispoProcessingState('error'));
  };

  const onSubmitBugReport = () => {
    const emails = [
      'john.lahut@wabtec.com',
      'hammad.shaikh@wabtec.com',
      'nicholas.roddy@wabtec.com',
      'amuthan.anandhan@Wabtec.com',
    ];

    const subject = `[${getApplicationRuntime()}] Power Tool Error - ${
      errorModalMessage?.traceId
    } (${user?.eoaUserName})`;
    const body = `A Power Tool error occurred for the user ${user?.eoaUserName} trace-id ${errorModalMessage?.traceId}  Error message: ${errorModalMessage?.message}.`;

    window.location.href = `mailto:${emails.join(
      ','
    )}?subject=${subject}&body=${body}`;
  };

  if (!verification || !state.items) {
    return <></>;
  }

  return (
    <Dialog
      PaperProps={{
        style: {
          backgroundColor: PowerToolDarkTheme.main,
          backgroundImage: 'unset',
          border: `1px solid ${PowerToolDarkTheme.voltBlue800}`,
        },
      }}
      open={toolOutputModalOpen}
      onClose={onClose}
      fullWidth={true}
      maxWidth={'lg'}
    >
      {/* Open RF in eServices re-close warning modal (todo: should be generic warning modal (i.e. can continue)) */}
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: PowerToolDarkTheme.main,
            backgroundImage: 'unset',
          },
        }}
        fullWidth={true}
        open={recloseModalOpen}
        maxWidth={'sm'}
      >
        <DialogTitle>Are you sure?</DialogTitle>

        <DialogContent>
          There is an open work order for a road failure present in eServices.
          Do you still want to close the case?
        </DialogContent>

        <DialogActions>
          <Button
            variant='outlined'
            color='primary'
            onClick={() => setRecloseModalOpen(false)}
          >
            go back
          </Button>
          <Button
            variant='outlined'
            color='success'
            onClick={() => {
              onReclose();
              setRecloseModalOpen(false);
            }}
          >
            yes
          </Button>
        </DialogActions>
      </Dialog>

      {/* Generic error modal */}
      <Dialog
        sx={{
          '&.MuiDialog-paperWidthSm': {
            paddingBottom: '0px',
          },
        }}
        PaperProps={{
          style: {
            backgroundColor: PowerToolDarkTheme.main,
            backgroundImage: 'unset',
          },
        }}
        fullWidth={true}
        open={errorModalOpen}
        maxWidth={'sm'}
      >
        <DialogTitle>{showContinueButton ? 'Warning' : 'Error'}</DialogTitle>

        <DialogContent style={{ whiteSpace: 'pre-line' }}>
          {errorModalMessage?.message}
        </DialogContent>

        <DialogActions>
          <div
            style={{
              display: 'flex',
              gap: '10px',
              flexDirection: 'row',
              justifyContent: errorModalMessage?.traceId
                ? 'space-between'
                : 'flex-end',
              marginLeft: '20px',
              width: '100%',
              alignItems: 'center',
              marginRight: '10px',
            }}
          >
            {errorModalMessage?.traceId ? (
              <div>
                <span
                  style={{
                    textTransform: 'uppercase',
                    fontWeight: 500,
                    fontSize: '11px',
                    color: PowerToolDarkTheme.statusGray300,
                  }}
                >
                  report this issue
                </span>
                <div
                  style={{
                    display: 'flex',
                    gap: '5px',
                    flexDirection: 'row',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={onSubmitBugReport}
                >
                  <BugReport
                    fontSize='small'
                    style={{
                      color: PowerToolDarkTheme.statusGreen700,
                      cursor: 'pointer',
                    }}
                  />
                  <span
                    style={{
                      textTransform: 'uppercase',
                      fontSize: '10px',
                      color: PowerToolDarkTheme.statusGray300,
                    }}
                  >
                    {errorModalMessage.traceId}
                  </span>
                </div>
              </div>
            ) : (
              <></>
            )}
            <Button
              variant='contained'
              color='success'
              sx={{ color: 'white' }}
              onClick={() => dispatch(setErrorModalOpen(false))}
            >
              Go Back
            </Button>
            {showContinueButton && (
              <Button
                variant='outlined'
                color='success'
                sx={{ color: 'white' }}
                onClick={() => {
                  dispatch(setErrorModalOpen(false));
                  onSubmit();
                }}
              >
                Deliver
              </Button>
            )}
          </div>
        </DialogActions>
      </Dialog>

      <DialogTitle>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'auto 50px',
          }}
        >
          <ToolDispoWarning
            open={warning !== undefined}
            message={warning}
            severity='warning'
            transitionprops={{
              sx: {
                gridColumnStart: 1,
                justifySelf: 'center',
              },
            }}
          />

          <Button
            disabled={loading}
            sx={{
              gridColumnStart: 2,
              alignSelf: 'baseline',
              justifySelf: 'center',
              minWidth: 'unset',
              padding: '4px',
            }}
            onClick={() => onClose({}, 'backdropClick')}
          >
            <CloseOutlined />
          </Button>
        </div>
      </DialogTitle>

      <DialogContent>
        <div
          style={{
            opacity: loading ? 0.4 : 1.0,
            pointerEvents: loading ? 'none' : 'inherit',
          }}
        >
          <RxSearch />
          <ToolOutputModalItems />

          {/* if the modal will be empty (no items, and no rx search because of active rx, case type) */}
          {/* then display a message */}
          {isAddRxEnabled || state.items.length > 0 ? (
            <></>
          ) : (
            <div
              style={{
                fontSize: '18px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontWeight: '300',
              }}
            >
              No actions available for this asset.
            </div>
          )}
        </div>
      </DialogContent>

      <DialogActions sx={{ mb: 2, mr: 1 }}>
        <Button
          sx={{ color: 'white' }}
          hidden={!verification.enableReClose}
          onClick={() => {
            if (verification?.openFLCount ?? 0 > 0) {
              setRecloseModalOpen(true);
            } else {
              onReclose();
            }
          }}
          variant='contained'
          color='primary'
        >
          re-close
        </Button>

        <LoadingButton
          // disabled={isSubmitDisabled}
          onClick={onSubmitVerify}
          loading={loading}
          variant='contained'
          color='success'
        >
          submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
