export const notesFormatter = (
  notes?: string,
  supportingRxAndFaultsNotes?: string,
  RelevantHistoryNotes?: string,
  GPOCInstructionNotes?: string
) => {
  let notesHTML = '';
  if (notes !== undefined && notes !== '') {
    return notes;
  } else {
    if (
      supportingRxAndFaultsNotes !== undefined &&
      supportingRxAndFaultsNotes !== ''
    ) {
      notesHTML += `<p><u><strong>Supporting Rx And Faults:</strong></u> ${supportingRxAndFaultsNotes}</p>`;
    }
    if (RelevantHistoryNotes !== undefined && RelevantHistoryNotes !== '') {
      notesHTML += `<p><u><strong>Relevant History:</strong></u> ${RelevantHistoryNotes}</p>`;
    }
    if (GPOCInstructionNotes !== undefined && GPOCInstructionNotes !== '') {
      notesHTML += `<p><u><strong>GPOC Instructions:</strong></u> ${GPOCInstructionNotes}</p>`;
    }
  }
  return notesHTML;
};
