import axios from 'axios';

import {
  DatascreenProperties,
  FiredFaultResponse,
  IngestedData,
  IngestedDataFooter,
  ServiceDropdownChoice,
} from 'common/features/ingested-data/types';

export const getIngestedFiredFaultData = (
  caseId?: string
): Promise<FiredFaultResponse> => {
  return axios
    .get<any[]>(`/fired-fault-data?caseId=${caseId}`)
    .then((response) => {
      let columns: IngestedDataFooter = {};
      const faults: IngestedData[] = [];
      response.data.forEach((item) => {
        if (item?.columns !== undefined) {
          columns = item.columns;
        } else {
          faults.push(item);
        }
      });
      return { columns, faults };
    });
};

export const getServiceSelectionOptions = (
  role?: string
): Promise<ServiceDropdownChoice[]> => {
  return axios
    .get<ServiceDropdownChoice[]>(`/datascreen-services?userRole=${role}`)
    .then((response) => response.data);
};

export const getDatascreenProperties = (
  role?: string
): Promise<DatascreenProperties> => {
  return axios
    .get<DatascreenProperties>(`datascreen-properties?userRole=${role}`)
    .then((response) => response.data);
};
