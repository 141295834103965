import { Button, ButtonGroup, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useMemo } from 'react';

import { useOktaUserInfo } from 'auth/hooks';

import { ClipboardLabel } from 'common/components/card/CardHeaderLabels';
import { useAppSelector } from 'common/hooks';
import {
  selectApplicationContext,
  selectAssetDetails,
  selectDebugMode,
  selectRule,
  selectUser,
} from 'common/stores/globalSlice';
import { selectLayout } from 'common/stores/userSettingSlice';
import { getApplicationRuntime } from 'common/util/utils';

import { selectCase } from 'power-tool/stores/casesSlice';
import { selectRun } from 'power-tool/stores/runSlice';

const Item = ({
  label,
  value,
  clipboard,
}: {
  label: string;
  value?: string | number;
  clipboard?: boolean;
}) => {
  if (value) {
    return (
      <Stack direction={'row'} alignItems={'center'} spacing={2}>
        <Typography variant='button' sx={{ fontFamily: 'monospace' }}>
          {label}
        </Typography>
        {clipboard === false ? (
          <Typography variant='button' sx={{ fontFamily: 'monospace' }}>
            {value}
          </Typography>
        ) : (
          <ClipboardLabel
            role='label'
            value={value}
            sxProps={{ alignSelf: 'flex-start', zIndex: 0 }}
          />
        )}
      </Stack>
    );
  }

  return <></>;
};

export const DebugComponent = () => {
  const runtime = getApplicationRuntime();
  const application = useAppSelector(selectApplicationContext);
  const debugMode = useAppSelector(selectDebugMode);
  const asset = useAppSelector(selectAssetDetails);
  const caseDetails = useAppSelector(selectCase);
  const layout = useAppSelector(selectLayout);
  const user = useAppSelector(selectUser);
  const run = useAppSelector(selectRun);
  const rule = useAppSelector(selectRule);

  const { userInfo } = useOktaUserInfo();

  const enabled = useMemo(() => {
    // if debug mode is defined, use as flag
    if (debugMode !== undefined) {
      return debugMode;
    }

    // otherwise always disable in prod, qa, dev
    else if (runtime === 'local') {
      return true;
    }

    return false;
  }, [application, debugMode]);

  const prodUrl = useMemo(() => {
    const location = window.location.toString();
    const protocol = 'https://';
    const host = 'powertool.corp.wabtec.com';

    if (location.includes('isDecryptedUrl')) {
      return location
        .replace(window.location.host, host)
        .replace(window.location.protocol, protocol);
    }

    return location
      .replace(window.location.host, host)
      .replace(window.location.protocol, protocol)
      .concat('&isDecryptedUrl=true');
  }, []);

  const getUrl = (application: string) => {
    const location = window.location.toString();
    const origin = window.location.origin;
    const path = window.location.pathname;
    const vehicle = asset !== undefined && asset.vehicleObjid > 0;

    if (location.includes('isDecryptedUrl')) {
      return (
        origin +
        path +
        `?application_context=${application}` +
        (vehicle ? `&vehicleObjid=${asset.vehicleObjid}` : '')
      );
    }

    return (
      origin +
      path +
      `?application_context=${application}` +
      (vehicle ? `&vehicleObjid=${asset.vehicleObjid}` : '') +
      '&isDecryptedUrl=true'
    );
  };

  if (enabled) {
    return (
      <Stack direction={'row'} spacing={5}>
        <Stack spacing={1}>
          <Stack direction={'row'} alignItems={'center'} spacing={2}>
            <Item label='application' value={application} clipboard={false} />
          </Stack>

          <Stack direction={'row'} alignItems={'center'} spacing={2}>
            <Item label='vehicle objid' value={asset?.vehicleObjid} />
            <Item
              label='vehicle'
              value={`${asset?.vehicleHdr}-${asset?.roadNumber}`}
            />
          </Stack>

          <Stack direction={'row'} alignItems={'center'} spacing={2}>
            <Item label='case id' value={caseDetails?.caseId} />
            <Item label='case objid' value={caseDetails?.caseObjid} />
          </Stack>

          <Stack direction={'row'} alignItems={'center'} spacing={2}>
            <Item label='rule objid' value={rule} />
            <Item label='run objid' value={run?.objid} />
          </Stack>

          <Stack direction={'row'} alignItems={'center'} spacing={2}>
            <Item label='user' value={user?.userName} />
            <Item label='role' value={user?.currRole} />
            <Item label='okta user' value={userInfo?.name} />
            <Item label='okta email' value={userInfo?.email} />
          </Stack>
        </Stack>

        <Stack justifyContent={'center'}>
          <ButtonGroup size='small'>
            <Button color='info' href={getUrl('power_tool')} target='_blank'>
              Open in PT
            </Button>
            <Button
              color='info'
              role={'link'}
              href={getUrl('train_analysis')}
              target='_blank'
            >
              Open in TAT
            </Button>
            <Button
              color='info'
              role={'link'}
              href={getUrl('asset_research')}
              target='_blank'
            >
              Open in ART
            </Button>
            <Button
              color='info'
              role={'link'}
              href={getUrl('springboard')}
              target='_blank'
            >
              Open in Springboard
            </Button>
            <Button
              color='info'
              role={'link'}
              href={getUrl('datascreen')}
              target='_blank'
            >
              Open in Datascreen
            </Button>
            <Button
              color='info'
              role={'link'}
              href={getUrl('virtual_tester')}
              target='_blank'
            >
              Open in Virtual Tester
            </Button>
            <Button
              color='info'
              role={'link'}
              href={getUrl('rule_tester')}
              target='_blank'
            >
              Open in Rule Tester
            </Button>
            <Button
              color='inherit'
              role={'link'}
              href={prodUrl}
              target='_blank'
            >
              Open in Prod
            </Button>
            <Button
              onClick={() => console.log(JSON.stringify(layout, null, 2))}
            >
              Log Layout
            </Button>
          </ButtonGroup>
        </Stack>
      </Stack>
    );
  }

  return <></>;
};
