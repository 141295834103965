import { Paper } from '@mui/material';
import { Stack } from '@mui/system';
import { IDetailCellRendererParams } from 'ag-grid-community';

import { TimelineEventType } from 'common/features/timeline/types';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

import { EventTooltipBody } from 'loco-history/cards/Timeline/components/event-tooltip/EventTooltipBody';
import { AnyTimelineEvent } from 'loco-history/types';

/**
 *
 * @param {IDetailCellRendererParams<AnyTimelineEvent>} params ag-grid detail cell renderer parameters
 * @returns {JSX.Element} detail cell component for ag-grid (expand row -> see detail view)
 */
export const EventDetailCell = (
  params: IDetailCellRendererParams<AnyTimelineEvent>
) => {
  if (params.data) {
    return <BaseEventDetailCell event={params.data} type={params.data.type} />;
  }
  return <></>;
};

/**
 *
 * @param {any} props implicit destructured props
 * @param {AnyTimelineEvent} props.event event to render detail view
 * @param {TimelineEventType} props.type type of event to render detail view for
 * @returns {JSX.Element} base detail component - leverages the existing event tooltip component
 */
const BaseEventDetailCell = ({
  event,
  type,
}: {
  event: AnyTimelineEvent;
  type: TimelineEventType;
}) => {
  return (
    <Stack px={2} py={2}>
      <Paper
        sx={{
          backgroundColor: PowerToolDarkTheme.main,
          px: 4,
          py: 1,
        }}
      >
        <EventTooltipBody event={event} type={type} />
      </Paper>
    </Stack>
  );
};
