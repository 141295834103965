import { Stack, Divider, Typography } from '@mui/material';
import React from 'react';

import { StyledLink } from 'common/components/general/StyledLink';
import { useUserFormattedTime } from 'common/hooks';
import { RX } from 'common/types/types';
import { useStripHTML } from 'common/util/hooks';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

import { ViewRelatedDataComponent } from 'train-tool/cards/Analysis/components/ViewRelatedDataComponent';

/**
 *
 * @param {any} props implicit destructured props
 * @param {RX} props.rx active rx details
 * @returns {JSX.Element} expanded detail view for an active rx
 */
export const RXDetailView = ({ rx }: { rx: RX }) => {
  const strippedLineZero = useStripHTML(rx.lineZeroNotes);

  return (
    <React.Fragment>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems='center'
      >
        <Stack
          direction={'row'}
          alignItems='center'
          spacing={1}
          divider={<Divider orientation='vertical' flexItem />}
        >
          <Stack
            direction={'row'}
            alignItems='center'
            spacing={1}
            sx={{ ml: 2 }}
          >
            <Typography variant='button'>{'Case ID: '}</Typography>
            <Typography variant='body2'>{rx.rxCaseId}</Typography>
          </Stack>
          <Stack
            direction={'row'}
            alignItems='center'
            spacing={1}
            sx={{ color: PowerToolDarkTheme.neutralDark800 }}
          >
            <Typography variant='button'>{'Opened:'}</Typography>
            <Typography variant='body2' sx={{ ml: 1 }}>
              {useUserFormattedTime(rx.rxOpenDate)}
            </Typography>
          </Stack>
          <Typography variant='button'>
            <StyledLink target='_blank' href={rx.pdfLink}>
              View RX
            </StyledLink>
          </Typography>
        </Stack>

        <ViewRelatedDataComponent />
      </Stack>

      <Stack
        direction={'row'}
        alignItems='center'
        spacing={3}
        sx={{ ml: 1 }}
        divider={<Divider orientation='vertical' flexItem />}
      ></Stack>

      {strippedLineZero ? (
        <Stack
          direction={'row'}
          alignItems='center'
          spacing={3}
          sx={{ ml: 1 }}
          divider={<Divider orientation='vertical' flexItem />}
        >
          <Stack direction={'row'} alignItems='center' spacing={1}>
            <Typography variant='button'>{'Notes: '}</Typography>
            <Typography variant='body2'>{strippedLineZero}</Typography>
          </Stack>
        </Stack>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};
