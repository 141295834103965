import { useEffect, useRef, useState } from 'react';
import { batch } from 'react-redux';

import { defaultUser } from 'auth/config';

import { baseTimeSettings } from 'common/config/config';
import { useAppDispatch } from 'common/hooks';
import {
  changeUser,
  setCustomer,
  setTimeSettings,
} from 'common/stores/globalSlice';
import { User } from 'common/types/types';
import { getOMDHost } from 'common/util/utils';

/**
 *
 * @param {JSX.Element} children components to provide URL params to
 * @returns {JSX.Element[]} returns the children passed into the component
 *
 * This components only job is to handle
 * window.post message communcation
 */
export const PostMessageProvider = ({ children }) => {
  const dispatch = useAppDispatch();

  const host = getOMDHost();

  const [userTimer, setUserTimer] = useState<NodeJS.Timer>();
  const [userCountdown, setUserCountdown] = useState<number>();

  const receivedUser = useRef<boolean>(false);

  useEffect(() => {
    // establish listener for OMD user information
    const listener = (event) => {
      if (event.origin === host && event.data.type === 'powertool-userInfo') {
        // grab the user and cast it
        const user = event.data.user as User;

        // if defined, dispatch the user, customer, and time settings
        if (user) {
          batch(() => {
            receivedUser.current = true;
            dispatch(changeUser(user));
            dispatch(setCustomer(user.customerId ?? 'ALL'));
            dispatch(
              setTimeSettings({
                ...baseTimeSettings,

                // OMD sends us 'CST' which isnt a valid DB Name for an ISO TZ :(
                // https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
                zone:
                  user.prefTimeZoneDesc === 'CST'
                    ? 'US/Central'
                    : user.prefTimeZoneDesc,
              })
            );
          });
        }
      }
    };

    window.addEventListener('message', listener);
    let timer: NodeJS.Timer;

    // if we are opened as a child window from OMD
    if (window.opener && host) {
      // we start a timer
      // this is to guard against PT opening from OMD, and the
      // window post message failing to reach PT
      timer = setInterval(
        () => setUserCountdown((timer) => (timer ? timer + 1 : 1)),
        1000
      );
      setUserTimer(timer);

      // post a message telling them its OK to send user info now
      window.opener.postMessage({ type: 'powertool-fetchUserInfo' }, host);
    }

    // cleanup listeners when component unmounts
    // NOTE: the timer must be cleared by a *locally scoped*
    //       variable here. otherwise the timer will run forever
    //       if component is reloaded before timer finishes
    return () => {
      window.removeEventListener('message', listener);
      clearInterval(timer);
    };
  }, []);

  // if our countdown was triggered (opened from OMD)
  // and we have not gotten a window post message,
  // then we assume no message is coming and we reject the user settings
  // we also check to see if the user has already been set before dispatching the rejected state
  useEffect(() => {
    if (
      userCountdown &&
      userCountdown >= 3 &&
      userTimer &&
      !receivedUser.current
    ) {
      console.warn(
        'Failed to get window.post message from OMD. Falling back to anonymous user.'
      );

      dispatch(changeUser(defaultUser));
      clearInterval(userTimer);
    }
  }, [userCountdown, userTimer, dispatch]);

  return children;
};
