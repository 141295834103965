import { ClipboardLabel } from 'common/components/card/CardHeaderLabels';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

/**
 *
 * @param {any} props implicit destructured props
 * @param {string} props.value value to copy to clipboard
 * @returns {JSX.Element} clipboard label component for the event tooltip component
 */
export const EventTooltipClipboardLabel = ({ value }: { value: string }) => {
  return (
    <ClipboardLabel
      role='label'
      value={value}
      sxProps={{
        alignSelf: 'flex-end',
        fontSize: '14px',
        m: 0,
        backgroundColor: PowerToolDarkTheme.statusGray800,
        ':hover': {
          backgroundColor: PowerToolDarkTheme.statusGray800,
        },
      }}
    />
  );
};
