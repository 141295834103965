import { UserClaims, toRelativeUrl } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

// https://github.com/okta/okta-react/blob/master/samples/routing/react-router-dom-v6/src/App.tsx
/**
 * @returns {(_oktaAuth: unknown, originalUrl: string) => void} function to navigate back to URL the user originally navigated to
 */
export const useRestoreOriginalUrl = () => {
  const navigate = useNavigate();

  const restoreOriginalUri = useCallback(
    (_oktaAuth: unknown, originalUrl: string) => {
      navigate(toRelativeUrl(originalUrl || '/', window.location.origin));
    },
    [navigate]
  );

  return { restoreOriginalUri };
};

// https://developer.okta.com/docs/guides/sign-into-spa-redirect/react/main/#get-info-about-the-user
/**
 * @returns {UserClaims | undefined} okta user information if logged in, undefined otherwise
 */
export const useOktaUserInfo = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState<UserClaims | undefined>();

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      setUserInfo(undefined);
    } else {
      setUserInfo(authState.idToken?.claims);
    }
  }, [authState, oktaAuth]);

  return { userInfo };
};
