import { IHeaderParams } from 'ag-grid-community';
import { useCallback, useEffect, useRef, useState } from 'react';

import { toggleDispatchChart } from 'common/cards/Data/IngestedDataGrid';
import { ColumnHeaderTooltip } from 'common/cards/Data/components/header/HeaderTooltip';
import { useColumnHeaderRenderer } from 'common/cards/Data/components/header/helpers';
import {
  StyledActiveFilterIcon,
  StyledInactiveFilterIcon,
  StyledColumnTooltipIcon,
} from 'common/components/ag-grid/AgGridCustom.styled';
import { LazyTooltip } from 'common/components/general/LazyTooltip';
import { useAppDispatch, useAppSelector } from 'common/hooks';

import { toggleFocusNewUserPlot } from 'power-tool/cards/Plot/PlotCard';
import {
  getSelectedPlot,
  getChartModels,
  setChartModels,
} from 'power-tool/stores/plotSlice';

export type DataGridHeaderProps = {
  tooltip?: string;
} & IHeaderParams;

/**
 *
 * @param {DataGridHeaderProps} props display props for a custom column header
 */
export const DataGridHeader = (props: DataGridHeaderProps) => {
  const dispatch = useAppDispatch();
  const selectedChart = useAppSelector(getSelectedPlot);
  const models = useAppSelector(getChartModels);

  const refButton = useRef(null);
  const column = useRef(props.column);

  const { columnHeader } = useColumnHeaderRenderer(props);

  const [filtered, setFiltered] = useState<boolean>(false);

  let menu: JSX.Element | undefined = undefined;
  let infoTooltip: JSX.Element | undefined = undefined;
  // let displayName: JSX.Element | string = `${props.displayName} (${asset?.unitTimeZone})`;

  const onFilterChanged = useCallback((params?) => {
    setFiltered(column.current.isFilterActive());
  }, []);

  useEffect(() => {
    column.current.addEventListener('filterChanged', onFilterChanged);
    onFilterChanged();
  }, [onFilterChanged]);

  /**
   * function to capture event when a column menu is clicked (filter/settings menu)
   */
  const onMenuClicked = () => {
    // @ts-ignore
    props.showColumnMenu(refButton.current);
  };

  /**
   *
   * @param {React.MouseEvent} event mouse click event when a header label is clicked
   */
  const onHeaderClick = (event) => {
    // no interaction for clicking columns if they are not plottable
    if (props.column.getColDef().chartDataType === 'excluded') {
      return;
    }

    const currentRanges = props.api.getCellRanges();

    // if we dont have a range currently selected, add the entire column to the range
    if (currentRanges && currentRanges?.length === 0) {
      props.api.addCellRange({
        columns: [props.column.getColId()],
        rowStartIndex: 0,
        rowEndIndex: null,
      });
    }

    // otherwise, we currently already have an active range in the grid
    else {
      const currentRange = currentRanges![0]; // we null and length check this above
      props.api.clearRangeSelection();

      // if our current range already includes the column we are clicking
      // remove it from the range
      if (
        currentRange.columns
          .map((c) => c.getColId())
          .includes(props.column.getColId())
      ) {
        props.api.addCellRange({
          columns: currentRange.columns.filter(
            (c) => c.getColId() !== props.column.getColId()
          ),
          rowStartIndex: 0,
          rowEndIndex: null,
        });
      } else {
        // otherwise, adjust the current range to include the new column
        // we do not want to simply append a new range. this causes the grid to
        // have multiple range selections, and the integrated chart only plots the
        // first range that is selected
        props.api.addCellRange({
          columns: [...currentRange.columns, props.column.getColId()],
          rowStartIndex: 0,
          rowEndIndex: null,
        });
      }
    }

    // when we click a header, we want to add that column to the plot automatically
    if (selectedChart >= 0 && models.length > 0) {
      // get the chart that is currently focused
      const currentChart = models[selectedChart];

      // get the chart model itself
      const model = props.api
        .getChartModels()
        ?.find((model) => model.chartId === currentChart);

      if (model !== undefined) {
        // track our plots position so we can sort a small list later
        const positionMap: Record<string, number> = {};

        // unsorted columns to plot
        let parameters: string[] = [];

        // parameters we will plot
        let orderedParameters: string[];

        // if our plotted range already includes the column we clicked
        // remove it from the plotted range
        if (model.cellRange.columns?.includes(props.column.getColId())) {
          parameters = model.cellRange.columns
            .filter((column) => column.toString() !== props.column.getColId())
            .map((col) => col.toString());
        }

        // otherwise add it to the plotted range
        else {
          parameters = [
            ...(model.cellRange.columns ?? []),
            props.column.getColId(),
          ].map((col) => col.toString());
        }

        // parameters.forEach((mp) => (positionMap[mp] = columnPositionMap[mp]));

        // sort the parameters based on position
        // orderedParameters = Object.keys(positionMap).sort(
        //   (a, b) => positionMap[a] - positionMap[b]
        // );

        // assign the ordered parameters to our chart that we are restoring
        model.cellRange = {
          ...model.cellRange,
          columns: parameters,
        };

        // since we are replacing the plot instead of creating a new
        // we will take care of the dispatch here because we need to
        // replace instead of append to the list of created charts
        // we also do not want the plot card to focus the 'new' user plot
        // since we are just replacing the same plot
        toggleDispatchChart();
        toggleFocusNewUserPlot();

        // create the new chart and replace the current plot with the new one
        const newChart = props.api.restoreChart(model);
        if (newChart !== undefined) {
          // replace the current plot with the newly created plot
          // order matters here!
          dispatch(
            setChartModels(
              models.map((existingChart) =>
                existingChart !== currentChart
                  ? existingChart
                  : newChart.chartId
              )
            )
          );

          // and remove the old from ag-grid
          props.api.getChartRef(currentChart)?.destroyChart();
        }

        // restore normal dispatching in the onChartCreated event
        setTimeout(() => {
          toggleDispatchChart();
          toggleFocusNewUserPlot();
        }, 0);
      }
    }
  };

  if (props.enableMenu) {
    menu = (
      <div ref={refButton} onClick={() => onMenuClicked()}>
        {filtered ? <StyledActiveFilterIcon /> : <StyledInactiveFilterIcon />}
      </div>
    );
  }

  if (props.tooltip) {
    const group = props.column.getParent().getColGroupDef()?.headerName;
    infoTooltip = (
      <LazyTooltip
        title={<ColumnHeaderTooltip tooltip={props.tooltip} group={group} />}
      >
        <div>
          <StyledColumnTooltipIcon
            style={{
              fontSize: '13px',
            }}
          />
        </div>
      </LazyTooltip>
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        height: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          marginTop: '2px',
          justifyContent: 'space-between',
          marginBottom: '1px',
        }}
      >
        {menu}
        {infoTooltip}
      </div>
      <div
        className={
          props.column.getColDef().chartDataType !== 'excluded'
            ? 'clickableHeader'
            : undefined
        }
        style={{
          whiteSpace: 'normal',
          fontWeight: 500,
          fontSize: '12px',
        }}
        onClick={(event) => onHeaderClick(event)}
      >
        {columnHeader}
      </div>
    </div>
  );
};
