import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { useAppSelector } from 'common/hooks';
import {
  selectApplicationContext,
  selectUser,
} from 'common/stores/globalSlice';

import { getAllUserSettings } from './api';

export const useFetchSettings = () => {
  const application = useAppSelector(selectApplicationContext);
  const user = useAppSelector(selectUser);

  const enabled = useMemo(() => {
    return (
      user !== undefined &&
      user.userName !== 'anonymous' &&
      application !== undefined
    );
  }, [user, application]);

  const hook = useQuery(
    ['user_settings'],
    () => getAllUserSettings(user!.userName, application!),
    { enabled, refetchOnWindowFocus: false, refetchOnMount: false }
  );

  return {
    settings: hook.data,
    settingsStatus: hook.status,
    ...hook,
  };
};
