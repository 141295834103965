import { takeLatest, all } from 'redux-saga/effects';

import {
  caseAction, // getParameter,
  // getIngestedData,
  getFiredFaults,
  runAction,
  ruleSelectedAction,
  initializeAction,
  getAssetRuns,
  setToolOutputAction,
  setAssetAction,
} from './actionSlice';
import {
  changeAssetHandler,
  faultsForRuleHandler,
  getAsssetRunHandler,
  initializeActionHandler,
  ruleSelectedActionHandler,
  runActionHandler,
  setToolOutputHandler,
} from './handlers';

// we define a number of watchers to observe actions that are dispatched
// from redux. these actions are defined in actionSlice.ts
// this also acts a debouncing mechanism for our actions as takeLatest(...)
// ensures that we always take the most recent call to the handler.
// action handlers are defined in handlers.ts

function* watchFaultsForRule() {
  yield takeLatest(getFiredFaults.type, faultsForRuleHandler);
}

function* watchRunAction() {
  yield takeLatest(runAction.type, runActionHandler);
}

function* watchRuleAction() {
  yield takeLatest(ruleSelectedAction.type, ruleSelectedActionHandler);
}

function* watchInitializeAction() {
  yield takeLatest(initializeAction.type, initializeActionHandler);
}

function* watchsetAssetAction() {
  yield takeLatest(setAssetAction.type, changeAssetHandler);
}

function* watchAssetRunsAction() {
  yield takeLatest(getAssetRuns.type, getAsssetRunHandler);
}

function* watchSetToolOutputAction() {
  yield takeLatest(setToolOutputAction.type, setToolOutputHandler);
}

export default function* rootSaga() {
  yield all([
    watchFaultsForRule(),
    watchRunAction(),
    watchRuleAction(),
    watchInitializeAction(),
    watchsetAssetAction(),
    watchAssetRunsAction(),
    watchSetToolOutputAction(),
  ]);
}
