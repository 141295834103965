import { Chip } from '@mui/material';
import { MdDirectionsRailway } from 'react-icons/md';

import { useFetchTrain } from 'train-tool/hooks/apiHooks';

/**
 *
 */
export const TrainChip = () => {
  const { train, isPlaceholderData } = useFetchTrain();

  if (train && train.trainId && train.exists && !isPlaceholderData) {
    return (
      <Chip
        size='small'
        color='primary'
        icon={<MdDirectionsRailway />}
        label={train.trainId}
      />
    );
  }
  return <></>;
};
