import {
  CellRangeParams,
  ChartCreated,
  ColumnApi,
  ColumnVisibleEvent,
  FilterOpenedEvent,
  GetChartToolbarItemsParams,
  GridApi,
  GridOptions,
  RangeSelectionChangedEvent,
} from 'ag-grid-community';
import { AgGridReact as AgGridReactType } from 'ag-grid-react/lib/agGridReact';
import { useEffect, useMemo, useRef, useState } from 'react';

import useGridCallbacks from 'common/cards/Data/callbacks';
import { AgGridCustomdataScreen } from 'common/cards/Data/components/AgGridCustomDataScreen';
import { GridDateTimeFilter } from 'common/cards/Data/components/DateTimePickerFilter';
import {
  useClearFilterHandler,
  useColumnVisibilityHandler,
  useFaultMapHandler,
  useFiredDataHandler,
  useHealthCheckFilterHandler,
  useQuickFilterHandler,
  useQuickNavHandler,
  useShowNoFiredFaultsHandler,
} from 'common/cards/Data/effects';
import { AG_GRID_LOCALE_EN } from 'common/cards/Data/locale/locale.en';
import { useGridProps } from 'common/cards/Data/props';
import { setGridApi } from 'common/cards/Data/store';
import { LoadingSpinnerOverlayLoading } from 'common/components/LoadingComponents';
import { useFetchIngestedFiredFaultData } from 'common/features/ingested-data/hooks';
import {
  selectIngestedData,
  selectIngestedDataStatus,
} from 'common/features/ingested-data/store';
import { useAppDispatch, useAppSelector } from 'common/hooks';
import {
  selectApplicationContext,
  selectAssetDetails,
  selectDebugDataGrid,
  setDataGridDebug,
} from 'common/stores/globalSlice';
import {
  setRowCount,
  setShowFiredFaults,
} from 'common/stores/ingestedDataFooterSlice';
import { getFocusedFault, setFocusedFault } from 'common/stores/mapSlice';
import { downloadObjectToFile } from 'common/util/utils';

import { createChartContainer } from 'power-tool/cards/Plot/PlotCard';
import {
  addChartModel,
  changePlot,
  getChartModels,
  getPlottedCellRange,
  getSelectedPlot,
  setChartModels,
  setPlottedCellRange,
} from 'power-tool/stores/plotSlice';

import { useAreChartsEnabled, useGetIngestedDataGridColumns } from './hooks';
import { applyHCFilter } from './utils';

export var shouldDispatchChart: boolean = true;
export var shouldFireChartCreateEvent: boolean = true;

export const toggleDispatchChart = () =>
  (shouldDispatchChart = !shouldDispatchChart);

// we need to store the original range used to generate the plot
const plottedRangeMap: Record<string, CellRangeParams> = {};

// used to track the columns that were originally plotted for a given plot,
// but are now hidden
const hiddenColsMap: Record<string, string[]> = {};

export const IngestedDataGrid = () => {
  // redux store
  const dispatch = useAppDispatch();
  const application = useAppSelector(selectApplicationContext);
  const chartModels = useAppSelector(getChartModels);
  const asset = useAppSelector(selectAssetDetails);
  const plottedCellRange = useAppSelector(getPlottedCellRange);
  const selectedPlot = useAppSelector(getSelectedPlot);
  const rowIdToFocus = useAppSelector(getFocusedFault);
  const debugDataGrid = useAppSelector(selectDebugDataGrid);

  const chartsEnabled = useAreChartsEnabled();

  // ref hooks -> we need to use refs for values that are leveraged in callbacks to ag-grid and other modules
  // once callbacks are set, the original value used will always be the same. instead, we need to have the
  // callbacks and hooks use references to the original value. we need to ensure these refs are updated
  // via useEffect when the base value changes
  const gridRef = useRef<AgGridReactType>(null);
  const chartModelRef = useRef(chartModels);
  const plottedRangeRef = useRef(plottedCellRange);
  const selectedPlotRef = useRef(selectedPlot);
  // ============== Ref Hook Updates ==============

  useEffect(() => {
    chartModelRef.current = chartModels;
  }, [chartModels]);

  useEffect(() => {
    plottedRangeRef.current = plottedCellRange;
  }, [plottedCellRange]);

  useEffect(() => {
    selectedPlotRef.current = selectedPlot;
  }, [selectedPlot]);

  // ============== Use Callback Hooks ==============

  // ============== Use Effect Hook Updates ==============
  // remember: useEffect are for side effects only. attempt to use useMemo, useState, and/or
  // lifting state up prior to implementing new useEffect hooks.

  useEffect(() => {
    if (rowIdToFocus) {
      const id = rowIdToFocus.toString();
      const node = gridRef.current?.api.getRowNode(id);
      if (node?.data.OBJID === id) {
        //if the node is filtered out, we want to remove the filter
        if (node.rowIndex === null) {
          //remove filters
          gridRef.current?.api.setFilterModel(null);

          // if the node is a fired fault and filtered, toggle "Show Fired"
          if (!doesExternalFilterPass(node)) {
            dispatch(setShowFiredFaults(true));
          } else {
            dispatch(setShowFiredFaults(false));
          }
        }
        // move the row we want to see to the middle

        // we setTimeout of zero here because we need the current event loop to complete
        // this is because the ensureNodeVisible call includes a scroll event, and we need that
        // to be completed prior to selecting the row, otherwise the user will not see it
        // and the call to select it could fail
        setTimeout(
          () => gridRef.current?.api?.ensureNodeVisible(node, 'middle'),
          0
        );

        // select it
        node.setSelected(true);
      }

      // setting the data to undefined, allowing multiple clicks
      dispatch(setFocusedFault(undefined));
    }
  }, [rowIdToFocus, dispatch]);

  useEffect(() => {
    if (debugDataGrid === true) {
      const cols =
        'column,label,width,filter,type' +
        gridRef.current?.columnApi.getAllGridColumns().map((col) => {
          return `\n"${col.getColDef().field}","${
            col.getColDef().headerName
          }","${Math.round(col.getColDef().width ?? 0)}","${
            col.getColDef().filter
          }","${col.getColDef().chartDataType}"`;
        });

      downloadObjectToFile(
        cols,
        `${asset?.vehicleModel}-${asset?.controller}-columns.csv`,
        (object) => object
      );

      dispatch(setDataGridDebug(false));
    }
  }, [debugDataGrid]);

  // ======= AG GRID EVENTS =========

  const onColumnVisible = (params: ColumnVisibleEvent) => {
    // we need to maintain the set of columns that should be plotted ourselves
    // when columns are hidden, the default ag-grid behavior is to remove all linkages to that column
    // in our case, if a column is hidden and plotted, and then that column becomes visible again,
    // we want that column to appear on the plot once again
    // largely taken from support article: https://ag-grid.zendesk.com/hc/en-us/articles/4410767336209
    let columns: string[] | undefined;
    if (params.column) {
      columns = [params.column.getColId()];
    } else {
      columns = params.columns?.map((col) => col.getColId());
    }

    const currentChart = params.api
      .getChartModels()
      ?.find(
        (model) =>
          model.chartId === chartModelRef.current[selectedPlotRef.current]
      );

    // column(s) has become hidden
    if (!params.visible) {
      // for each plot that we currently have rendered,
      // check to see if the column(s) that we are hiding
      // were part of the original plotted columns when
      // the chart was first created
      Object.keys(plottedRangeMap).forEach((chartId) => {
        hiddenColsMap[chartId] = [
          ...(hiddenColsMap[chartId] ?? []),
          ...(columns?.filter((hiddenCol) =>
            plottedRangeMap[chartId].columns?.includes(hiddenCol)
          ) ?? []),
        ];
      });
    }

    // column(s) have become visisble
    else {
      let currentModels = chartModelRef.current;

      Object.keys(plottedRangeMap).forEach((chartId) => {
        if (
          plottedRangeMap[chartId].columns?.some((col) =>
            columns?.includes(col.toString())
          )
        ) {
          // columns for the plot that are still hidden after a visible event
          const stillHidden = hiddenColsMap[chartId].filter(
            (hiddenCol) => !columns?.includes(hiddenCol)
          );

          // columns that were once rendered, then hidden, and now are no longer hidden
          const columnsToReplot = plottedRangeMap[chartId].columns?.filter(
            // @ts-ignore
            (originalColumn) => !stillHidden.includes(originalColumn)
          );

          const chartModel = params.api
            .getChartModels()
            ?.find((model) => model.chartId === chartId);

          // tear down the chart.
          //  if we are currently focusing this plot, we need to remove it from the DOM (and its wrapper)
          //    and re-render the plot
          //  for all plots, destroy the chart via ag-grid and remove the old plot from our map
          params.api.getChartRef(chartId)?.destroyChart();
          const chartElement = document
            .querySelector('#plot-panel')
            ?.children.item(0);
          if (chartElement && currentChart?.chartId === chartId) {
            document.querySelector('#plot-panel')?.removeChild(chartElement);
          }

          // we tell our onChartCreated event to not do anything via this flag
          shouldFireChartCreateEvent = false;

          // now and go recreate the chart with the original columns minus the ones that are still hidden
          const recreatedChart = params.api.createRangeChart({
            cellRange: {
              ...plottedRangeMap[chartId],
              columns: columnsToReplot,
              rowStartIndex: 0,
              rowEndIndex: null,
            },
            chartThemeOverrides: {
              line: {
                title: chartModel?.chartOptions?.common?.title,
              },
            },
            chartType: chartModel?.chartType ?? 'line',
          });

          // replace the plot we just recreated in our chart model redux store
          if (recreatedChart !== undefined) {
            currentModels = currentModels.map((existingModel) =>
              existingModel !== chartId ? existingModel : recreatedChart.chartId
            );
          }

          // allow onChartCreated to fire again
          setTimeout(() => {
            shouldFireChartCreateEvent = true;
          }, 0);

          if (recreatedChart !== undefined) {
            // our hidden cols map should now only be tracking columns
            // that were hidden after the re-render of the plot
            hiddenColsMap[recreatedChart.chartId] = stillHidden;

            // our plotted range map should preserve the original plotted range for the chart
            plottedRangeMap[recreatedChart.chartId] = plottedRangeMap[chartId];

            // remove references to our old plot that we were tracking
            delete hiddenColsMap[chartId];
            delete plottedRangeMap[chartId];
          }
        }
      });

      // at the end of the processing, dispatch our updated model list to redux
      dispatch(setChartModels(currentModels));
    }
  };

  const onChartCreated = (params: ChartCreated) => {
    // we use this toggle to prevent this handler from firing under some circumstances
    if (!shouldFireChartCreateEvent) return;

    // regardless of if this is an API chart or a user created chart,
    // we want to store the original plotted range for that model
    const model = params.api
      .getChartModels()
      ?.find((model) => model.chartId === params.chartId);
    if (model?.chartId !== undefined && model.cellRange !== undefined)
      plottedRangeMap[model.chartId] = model.cellRange;

    // set our original plotted cell range for this plot
    dispatch(
      setPlottedCellRange(
        params.api
          ?.getChartModels()
          ?.find((model) => model.chartId === params.chartId)?.cellRange
      )
    );

    // sometimes we want to create a chart without dispatching the new chart
    if (!shouldDispatchChart) return;

    // whenever a chart is created, add the chart ID to our list of models in Redux and
    // change the selected plot to the one we just created,
    dispatch(addChartModel(params.chartId));
    dispatch(changePlot(chartModelRef.current.length));
  };

  const onFilterOpened = (params: FilterOpenedEvent) => {
    if (params.column.getId() === 'FAULT_DESC') {
      // to calcuate widths, we create a dummy DOM element and measure its width
      // create canvas element inside a document framgment
      const fragment: DocumentFragment = document.createDocumentFragment();
      const canvas: HTMLCanvasElement = document.createElement('canvas');
      fragment.appendChild(canvas);
      // the calculation is applied to the container of elements. this is extra padding to accommodate
      const extraPixels = 70;
      // get the canvas element, set the font that we use in the filter
      const context = canvas.getContext('2d') as CanvasRenderingContext2D;
      context.font =
        '13px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif';
      let minSize: number = 0;

      params.api.forEachNode((node) => {
        if (context.measureText(node.data.FAULT_DESC).width > minSize) {
          minSize = context.measureText(node.data.FAULT_DESC).width;
        }
      });
      params.eGui.setAttribute(
        'style',
        'width:' + (minSize + extraPixels) + 'px;'
      );
    } else if (
      params.column.getId() === 'MP_0102_N_0_0' ||
      params.column.getId() === 'NOTCH'
    ) {
      //unset the fixed height for the filter list. showing all items without scrolling
      params.eGui
        .querySelector('.ag-set-filter-list')
        ?.setAttribute('style', 'height: auto');
    }
  };

  // users like to use column selection as a place marker
  // as well as plotting. when rows are selected, the range is
  // updated to that cell. to prevent this, when ranges are changed,
  // we revert back to the last plotted range
  const onRangeSelectionChanged = (event: RangeSelectionChangedEvent) => {
    //if last event.
    if (event.finished) {
      //get the current plotted data.
      const currentChart = chartModelRef.current[selectedPlotRef.current];
      const model = event.api
        .getChartModels()
        ?.find((model) => model.chartId === currentChart);

      //get the current ranges
      const ranges = event.api.getCellRanges();

      if (model) {
        //if there is a model/plot
        model.cellRange.columns?.forEach((column) => {
          //check to see if the columns in the plot are in the current ranges
          if (
            column.toString() !== 'OCCUR_DATE' &&
            !ranges?.find((range) => {
              return (
                range.columns.find((col) => col.getColId() === column) !==
                undefined
              );
            })
          ) {
            //if not, add them
            event.api.addCellRange({
              columns: [column],
              rowStartIndex: 0,
              rowEndIndex: null,
            });
          }
        });
      }
    }
  };

  // ======= INGESTED DATA UTILITIES =========

  const handleKeyDown = (event) => {
    if (
      event.which === 13 &&
      event.target.className &&
      event.target.className.indexOf('ag-input-field-input') > -1
    ) {
      gridRef.current?.api?.hidePopupMenu();
    }
  };

  // ======= GRID RENDER =========

  const options: GridOptions = {
    // grid events
    onGridReady: (params) => {
      setApi(params.api);
      setColApi(params.columnApi);

      // this dispatch is really bad and shouldn't be done
      // when plotting is refactored, this should go away
      dispatch(setGridApi(params.api));
    },
    onComponentStateChanged: (params) =>
      dispatch(setRowCount(params.api.getDisplayedRowCount())),
    onColumnVisible: onColumnVisible,
    onChartCreated: onChartCreated,
    createChartContainer: createChartContainer,
    onFilterOpened: onFilterOpened,
    onRangeSelectionChanged: onRangeSelectionChanged,
    getChartToolbarItems: (params: GetChartToolbarItemsParams) => [
      'chartData',
      'chartSettings',
      'chartFormat',
      'chartLink',
      'chartDownload',
    ],
    chartToolPanelsDef: {
      panels: ['data', 'settings', 'format'],
      settingsPanel: {
        chartGroupsDef: {
          lineGroup: ['line'],
          scatterGroup: ['scatter'],
          areaGroup: ['area', 'stackedArea'],
          histogramGroup: ['histogram'],
          combinationGroup: ['columnLineCombo', 'customCombo'],
        },
      },
    },

    // look & feel settings
    // statusBar: statusBar,
    sideBar: {
      position: 'right',
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivotMode: true,
          },
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
      ],
    },
    autoSizePadding: 2,
    localeText: AG_GRID_LOCALE_EN,
    enableCellTextSelection: false,
    enableFillHandle: true,
    suppressCopySingleCellRanges: true,

    // cell settings
    suppressCellFocus: true,
    suppressNoRowsOverlay: false,

    // column settings
    suppressMovableColumns: false,
    suppressMenuHide: true,
    headerHeight: 103,
    groupHeaderHeight: 20,
    defaultColDef: {
      wrapText: true,
      resizable: true,
      filter: 'agTextColumnFilter',
      menuTabs: ['filterMenuTab', 'generalMenuTab'],
      filterParams: {
        buttons: ['clear'],
        closeOnApply: true,
        defaultToNothingSelected: true,
      },
    },
    postProcessPopup: function (params) {
      params.ePopup.addEventListener('keydown', handleKeyDown);
    },

    // row settings
    rowSelection: 'multiple',
    rowMultiSelectWithClick: true,
    suppressRowHoverHighlight: true,
    suppressScrollOnNewData: true,
    suppressMultiRangeSelection: false,

    // filter settings
    floatingFiltersHeight: 10,

    // plotting
    enableCharts: chartsEnabled,
    enableRangeSelection: true,
    popupParent: document.body,
    chartThemes: ['ag-default-dark'],
    enableChartToolPanelsButton: true,
    loadingOverlayComponent: LoadingSpinnerOverlayLoading,

    // excel
    // excelStyles: excelStyles,
    overlayNoRowsTemplate: 'No Data to display',
  };

  // =============================
  // ==   Begin Grid Refactor   ==
  // =============================
  // Grid was refactored Q1 2023. Leaving code 'separate'
  // for now. Plan is to retire above callbacks and effects once
  // the plotting card implements charts-js
  // the effects and callbacks below have been reimplemented.
  // *** All new grid logic should live below this comment ***

  // grid state
  const [api, setApi] = useState<GridApi>();
  const [colApi, setColApi] = useState<ColumnApi>();

  // get data, columns, and status
  const columns = useGetIngestedDataGridColumns(colApi);
  const faultData = useAppSelector(selectIngestedData);
  const status = useAppSelector(selectIngestedDataStatus);

  // fired faults
  const { firedFaultData } = useFetchIngestedFiredFaultData();

  // grid management hooks
  const {
    doesExternalFilterPass,

    getRowStyle,
    getContextMenuItems,
    getMainMenuItems,

    onCellClicked,
    onColumnEverythingChanged,
    onFilterChanged,
  } = useGridCallbacks();

  // grid prop values
  const {
    excelStyles,
    chartThemeOverrides,
    defaultCsvExportParams,
    defaultExcelExportParams,
  } = useGridProps();

  // when grid goes into fetching state, we reset and show loading
  useEffect(() => {
    switch (status) {
      // when we are fetching (waiting for backend)
      // we lock the grid, and show loading overlay
      case 'fetching':
        colApi?.resetColumnState();
        api?.showLoadingOverlay();
        break;

      // as soon as we start getting data back
      // from the backend, apply HC filter
      case 'streaming':
        if (application !== 'train_analysis') {
          applyHCFilter(api);
        }
        break;

      // overlay will hide automatically when data streams in
      // but in the case that there are no rows returned
      // we need to disable manually
      case 'complete':
        api?.hideOverlay();
        break;
    }
  }, [api, status, application]);

  // we want to leave grid data as undefined if there is no fault or fired fault data
  const data = useMemo(() => {
    // no asset selected, no data
    if (asset === undefined) {
      return [];
    }

    // we have both fired faults and data
    else if (faultData && firedFaultData) {
      // find the fired faults that are not already in the grid
      // and mark them as outside the viewable range
      const firedFaults = firedFaultData.faults
        .filter(
          (fired) =>
            faultData.find((fault) => fault.OBJID === fired.OBJID) === undefined
        )
        .map((fired) => ({ ...fired, inDayRange: false }));

      // then return fired faults (excluding ones we got with normal data)
      // combined with fault data
      return [...faultData, ...firedFaults];
    }

    // we only have fault data
    else if (faultData) {
      return faultData;
    }
  }, [faultData, firedFaultData, asset]);

  // these are strictly side effects
  // they generally depend on the current grid API
  // and some external action, like a button click from another card
  useFiredDataHandler(api);
  useQuickFilterHandler(api);
  useClearFilterHandler(api);
  useFaultMapHandler(data);
  useQuickNavHandler(api, colApi);
  useShowNoFiredFaultsHandler(api);
  useHealthCheckFilterHandler(api);
  useColumnVisibilityHandler(api, colApi);

  return (
    <AgGridCustomdataScreen
      ref={gridRef}
      rowData={data}
      columnDefs={columns}
      components={{
        agDateInput: GridDateTimeFilter,
      }}
      gridOptions={{
        ...options,

        excelStyles,
        chartThemeOverrides,

        suppressRowClickSelection: true,
        animateRows: true,

        isExternalFilterPresent: () => true,
        doesExternalFilterPass,

        getRowStyle,
        getContextMenuItems,
        getMainMenuItems,

        onCellClicked,
        onColumnEverythingChanged,
        onFilterChanged,

        defaultCsvExportParams,
        defaultExcelExportParams,
      }}
    />
  );
};
