import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { QuickFilter } from 'common/cards/Data/types';
import { ServiceDropdownChoice } from 'common/features/ingested-data/types';
import { RootState } from 'common/store';

export type IngestedDataFooterState = {
  rowCount: number;
  service?: ServiceDropdownChoice;
  showFiredFaults: boolean;
  showFiredParameters: boolean;
  clearAllFilters: boolean;
  selectedQuickNavigation?: string;
  filterActive: boolean;
  hardFetchNewData: boolean;
  healthCheckFilterActive: boolean;
  quickFilters: QuickFilter[];
};

const initialState: IngestedDataFooterState = {
  rowCount: 0,
  showFiredFaults: false,
  showFiredParameters: false,
  clearAllFilters: false,
  selectedQuickNavigation: '',
  filterActive: false,
  hardFetchNewData: false,
  healthCheckFilterActive: true,
  quickFilters: [],
};

export const ingestedDataFooterSlice = createSlice({
  name: 'ingestedDataFooter',
  initialState,
  reducers: {
    setRowCount: (state, action: PayloadAction<number>) => {
      state.rowCount = action.payload;
    },
    setService: (
      state,
      action: PayloadAction<ServiceDropdownChoice | undefined>
    ) => {
      state.service = action.payload;
    },
    setShowFiredFaults: (state, action: PayloadAction<boolean>) => {
      state.showFiredFaults = action.payload;
    },
    setShowFiredParameters: (state, action: PayloadAction<boolean>) => {
      state.showFiredParameters = action.payload;
    },
    setClearAllFilters: (state, action: PayloadAction<boolean>) => {
      state.clearAllFilters = action.payload;
    },
    setSelectedQuickNavigation: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.selectedQuickNavigation = action.payload;
    },
    setFilterActive: (state, action: PayloadAction<boolean>) => {
      state.filterActive = action.payload;
    },
    setHardFetchNewData: (state, action: PayloadAction<boolean>) => {
      state.hardFetchNewData = action.payload;
    },
    toggleHealthCheckFilter: (state, action: PayloadAction<void>) => {
      state.healthCheckFilterActive = !state.healthCheckFilterActive;
    },
    setHealthCheckFilter: (state, action: PayloadAction<boolean>) => {
      state.healthCheckFilterActive = action.payload;
    },
    setQuickFilters: (state, action: PayloadAction<QuickFilter[]>) => {
      state.quickFilters = action.payload;
    },
  },
});

export const {
  setRowCount,
  setService,
  setShowFiredFaults,
  setShowFiredParameters,
  setClearAllFilters,
  setSelectedQuickNavigation,
  setFilterActive,
  setHardFetchNewData,
  toggleHealthCheckFilter,
  setQuickFilters,
  setHealthCheckFilter,
} = ingestedDataFooterSlice.actions;

export const selectRowCount = (state: RootState) =>
  state.ingestedDataFooter.rowCount;
export const selectService = (state: RootState) =>
  state.ingestedDataFooter.service;
export const selectShowFiredFaults = (state: RootState) =>
  state.ingestedDataFooter.showFiredFaults;
export const selectShowFiredParameters = (state: RootState) =>
  state.ingestedDataFooter.showFiredParameters;
export const selectClearAllFilters = (state: RootState) =>
  state.ingestedDataFooter.clearAllFilters;
export const selectedQuickNavigation = (state: RootState) =>
  state.ingestedDataFooter.selectedQuickNavigation;
export const selectFilterActive = (state: RootState) =>
  state.ingestedDataFooter.filterActive;
export const selectHardFetchNewData = (state: RootState) =>
  state.ingestedDataFooter.hardFetchNewData;
export const selectHealthCheckFilterState = (state: RootState) =>
  state.ingestedDataFooter.healthCheckFilterActive;
export const selectQuickFilters = (state: RootState) =>
  state.ingestedDataFooter.quickFilters;
export default ingestedDataFooterSlice.reducer;
