import html2canvas from 'html2canvas';
import React from 'react';

import { MapMarkerData } from 'common/cards/Map/types';
import { PowerToolUrlParams, Asset } from 'common/types/types';

import { Run } from 'power-tool/types';

/**
 *
 * @param column
 */
export const getColumnSortOrder = (column?: string): number => {
  const columnSortMap: Record<string, number> = {
    OFFBOARD_LOAD_DATE: 11,
    CMU_TIME: 10,
  };

  return column && columnSortMap[column] ? columnSortMap[column] : -1;
};

// TO-DO: This should be a hook since we just pass
// in redux state variables to the function anyways
/**
 *
 * @param run
 */
export const isRunSelected = (run: Run | undefined): boolean => {
  if (run && run.objid && run.objid > 0) return true;
  return false;
};

// this is a pretty expensive class to use
// this is when we want to have a set of unique objects
// this can be done with vanilla JS by comparing the props of each
// object prior to adding
// https://stackoverflow.com/questions/41404607/why-does-javascript-set-not-do-unique-objects
export class DeepSet<T extends MapMarkerData> extends Set {
  add(value: T): this {
    for (const item of this) {
      if (this.deepCompare(item, value)) {
        return this;
      }
    }
    super.add.call(this, value);
    return this;
  }

  private deepCompare(current: T, other: T) {
    return current.lat === other.lat && current.long === other.long;
  }
}

// check to see if we are currently running in a 'deployed' env
// this is set when we do an npm build -- should be only true on deployed images
/**
 *
 * @param urlParams
 */
export const isAppDeployed = (urlParams?: PowerToolUrlParams) => {
  if (urlParams) {
    // urlParams is null-checked by the caller
    return (
      process.env.NODE_ENV === 'production' &&
      (urlParams.isDecryptedUrl === undefined ||
        urlParams.isDecryptedUrl === 'false')
    );
  }

  return process.env.NODE_ENV === 'production';
};

/**
 *
 */
export const getApplicationRuntime = (): 'local' | 'dev' | 'qa' | 'prod' => {
  const host = window.parent.origin;

  if (host.includes('localhost')) {
    return 'local';
  } else if (host.includes('dev')) {
    return 'dev';
  } else if (host.includes('qa')) {
    return 'qa';
  } else {
    return 'prod';
  }
};

// determine if the application is running internally or externally
/**
 *
 */
export const getAppHostLevel = (): 'internal' | 'external' => {
  const host = window.parent.origin;

  // if app is running locally or in dev, we are always internal
  if (
    getApplicationRuntime() === 'local' ||
    getApplicationRuntime() === 'dev'
  ) {
    return 'internal';
  }

  // if the app does not have '.corp' in the URL, we are running externally
  else if (!host.toLowerCase().includes('.corp')) {
    return 'external';
  }

  // finally default to internal
  return 'internal';
};

/**
 *
 */
export const getOMDHost = () => {
  const appLevel = getAppHostLevel();

  switch (getApplicationRuntime()) {
    case 'dev':
      return process.env.REACT_APP_OMD_INTERNAL_DEV_URL;

    case 'local':
    case 'qa':
      return appLevel === 'external'
        ? process.env.REACT_APP_OMD_EXTERNAL_QA_URL
        : process.env.REACT_APP_OMD_INTERNAL_QA_URL;

    case 'prod':
    default:
      return appLevel === 'external'
        ? process.env.REACT_APP_OMD_EXTERNAL_URL
        : process.env.REACT_APP_OMD_INTERNAL_URL;
  }
};

// https://stackoverflow.com/a/65586375/9744436
/**
 *
 * @param file
 */
export const file2Base64 = (file: File): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    /**
     *
     */
    reader.onload = () => resolve(reader.result?.toString() || '');
    /**
     *
     * @param error
     */
    reader.onerror = (error) => reject(error);
  });
};

export const downloadObjectToFile = (
  payload: Object,
  filename?: string,
  transform?: (payload: Object | any) => string
) => {
  const temp = document.createElement('a');
  temp.href = URL.createObjectURL(
    new Blob([transform ? transform(payload) : JSON.stringify(payload)])
  );
  temp.setAttribute('download', filename ?? 'payload.json');
  document.body.appendChild(temp);
  temp.click();
  document.body.removeChild(temp);
};

/**
 *
 * @param asset
 */
export const getRoadNumber = (asset: Asset) => {
  return `${asset.vehicleHdr}-${asset.roadNumber}`;
};

/**
 *
 * @param value
 * @param index
 * @param self
 */
export const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index;
};

// https://blog.logrocket.com/export-react-components-as-images-html2canvas/
// https://html2canvas.hertzen.com/
/**
 *
 * @param params
 * @param element
 * @param filename
 * @param minimumWidth
 * @param params.element
 * @param params.filename
 * @param params.minimumWidth
 * @param params.minimumHeight
 * @param params.fixedWidth
 * @param params.fixedHeight
 * @param params.backgroundColor
 * @param params.scale
 */
export const captureHTMLElement = (params: {
  element: HTMLElement | null;
  filename: string;
  minimumWidth?: number;
  minimumHeight?: number;
  fixedWidth?: number;
  fixedHeight?: number;
  backgroundColor?: string;
  scale?: number;
}) => {
  //some logic to allow the option for both minimum and fixed widths and heights
  let finalWidth, finalHeight;

  if (params.element) {
    if (params.element.clientWidth) {
      //if there's a fixedwidth, use that. Otherwise if the minimum width is defined AND it's more than the current width, set that as the width
      if (params.fixedWidth) {
        finalWidth = params.fixedWidth;
      } else if (
        params.minimumWidth &&
        params.element.clientWidth < params.minimumWidth
      ) {
        finalWidth = params.minimumWidth;
      }
    }

    if (params.element.clientHeight) {
      if (params.fixedHeight) {
        //if there's a fixedHeight, use that. Otherwise if the minimum height is defined AND it's more than the current height, set that as the height
        finalHeight = params.fixedHeight;
      } else if (
        params.minimumHeight &&
        params.element.clientHeight < params.minimumHeight
      ) {
        finalHeight = params.minimumHeight;
      }
    }
  }

  // https://github.com/niklasvh/html2canvas/issues/2008#issuecomment-990597427
  /**
   *
   * @param document
   * @param element
   */
  const onclone = (document: Document, element: HTMLElement) => {
    //if custom widths/heights are used, use those instead
    if (finalWidth) {
      element.style.width = `${finalWidth}px`;
    }
    if (finalHeight) {
      element.style.height = `${finalHeight}px`;
    }
    Array.from(document.querySelectorAll('textarea')).forEach((area) => {
      const div = document.createElement('div');
      if (area.value !== 'x') {
        div.innerText = area.value;
        area.style.display = 'none';
        area.parentElement?.append(div);
      }
    });
  };

  if (params.element !== undefined && params.element !== null) {
    html2canvas(params.element, {
      scale: params.scale ?? 2.5,
      onclone,
      windowWidth: finalWidth,
      windowHeight: finalHeight,
      backgroundColor: params.backgroundColor,
    }).then((canvas) => {
      const image = canvas.toDataURL('image/png', 1);
      const temp = document.createElement('a');
      temp.setAttribute('style', 'display:none;');
      temp.setAttribute('download', params.filename);
      temp.setAttribute('href', image);
      document.body.appendChild(temp);
      temp.click();
      document.body.removeChild(temp);
      temp.remove();
    });
  }
};

/**
 *
 * @param rxId
 * @param customerOrgId
 * @param roadNumber
 * @param hideCustomization
 */
export const openOMDRx = (
  rxId: number,
  customerOrgId?: string,
  roadNumber?: string,
  hideCustomization?: boolean
) => {
  const host = getOMDHost();
  // const taskType = isFlowchartOnly === 'Y' ? 'Flowchart' : 'List';
  const h = 1000;
  const w = 1500;
  const url = `${host}/RMDWeb/showRxAuthoring?solutionid=${rxId}&fromPage=toolOutput&customerID=${customerOrgId}&roadNo=${roadNumber}${
    hideCustomization ? '&hideCustomizeBtn=true' : ''
  }`;
  window.open(
    url,
    'powertoolRxpopupwindow',
    'toolbar=no ,location=0,status=no,titlebar=no,menubar=no,width=' +
      w +
      ',height=' +
      h
  );
};

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions
export const escapeRegExp = (string: string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
};

/**
 * takes a string with encoded html, decodedes and returns as a span
 *
 * @param rawHTML html string to decode
 * @returns {JSX.Element} the element rendered as a span
 */
export const stripEncodedHTML = (rawHTML: string) =>
  React.createElement('span', { dangerouslySetInnerHTML: { __html: rawHTML } });
