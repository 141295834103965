import axios, { Canceler } from 'axios';

import {
  ApplicableRule,
  CaseDetails,
  ConfigItems,
  HealthInfoData,
  Run,
  RunInfo,
  RxMetric,
  ToolOutputData,
  VehicleConfig,
} from './types';

let cancelAppRules: Canceler, cancelBOMConfig: Canceler;

// TODO: This should be part of the base asset query, and this service should go away
/**
 *
 * @param vehicleObjid
 */
export const getRunInfo = (
  vehicleObjid?: string | number
): Promise<RunInfo[]> => {
  return axios
    .post<RunInfo[]>('/query/vehicle_last_run', {
      parameters: {
        1: vehicleObjid,
      },
      cacheable: false,
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log('error calling query endpoint for vehicle tool run data');
      throw e;
    });
};

// TODO: This should be part of the base asset query, and this service should go away
/**
 *
 * @param vehicleObjid
 */
export const getHealth = (
  vehicleObjid?: string | number
): Promise<HealthInfoData[]> => {
  return axios
    .post<HealthInfoData[]>('/query/health_score_for_vehicle', {
      parameters: {
        1: vehicleObjid,
      },
      cacheable: false,
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log('error calling query endpoint for vehicle health data');
      throw e;
    });
};

// TODO: This should be part of the base asset query, and this service should go away
/**
 *
 * @param vehicleObjid
 */
export const getConfigOfInterest = (
  vehicleObjid?: string | number
): Promise<ConfigItems[]> => {
  return axios
    .post<ConfigItems[]>('/query/config_items_of_interest', {
      parameters: {
        1: vehicleObjid,
      },
      cacheable: false,
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(
        'error calling query endpoint for vehicle configs of interest data'
      );
      throw e;
    });
};

/**
 *
 * @param vehicleObjid
 */
export const getCases = (
  vehicleObjid: number | string
): Promise<CaseDetails[]> => {
  return axios
    .post<CaseDetails[]>('/query/cases_for_vehicle', {
      parameters: {
        1: vehicleObjid,
        2: 180,
      },
      cacheable: false,
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log('error calling query endpoint for cases for vehicle data');
      throw e;
    });
};

/**
 *
 * @param vehicleObjid
 */
export const getRunsForVehicle = (vehicleObjid: number): Promise<Run[]> => {
  return axios
    .post<Run[]>('/query/runs_for_vehicle', {
      parameters: {
        1: vehicleObjid,
      },
      cacheable: false,
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log('error calling query endpoint for runs from case');
      throw e;
    });
};

/**
 *
 * @param caseId
 */
export const getRunsFromCase = (caseId: string): Promise<Run[]> => {
  return axios
    .post<Run[]>('/query/runs_from_case', {
      parameters: {
        1: caseId,
      },
      cacheable: false,
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log('error calling query endpoint for runs from case');
      throw e;
    });
};

export const getRuns = (vehicleObjid?: string | number, caseId?: string) => {
  const url = caseId ? '/query/runs_from_case' : '/query/runs_for_vehicle';
  const parameter = caseId ?? vehicleObjid;

  return axios
    .post<Run[]>(url, {
      parameters: {
        1: parameter,
      },
      cacheable: false,
    })
    .then((response) => response.data);
};

/**
 *
 * @param caseId
 */
export const getToolOutputForCase = (
  caseId: string
): Promise<ToolOutputData[]> => {
  return axios
    .post<ToolOutputData[]>('/query/tool_output_for_case', {
      parameters: {
        1: caseId,
        2: caseId,
        3: caseId,
      },
      cacheable: false,
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log('error calling query endpoint for tooloutput data for case');
      throw e;
    });
};

/**
 *
 * @param runId
 */
export const getToolOutputFromRun = (
  runId: number
): Promise<ToolOutputData[]> => {
  return axios
    .post<ToolOutputData[]>('/query/tool_output_for_run', {
      parameters: {
        1: runId,
        2: runId,
        3: runId,
      },
      cacheable: false,
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log('error calling query endpoint for tooloutput data');
      throw e;
    });
};

/**
 *
 * @param caseId
 * @param runId
 */
export const getToolOutput = (
  caseId?: string,
  runId?: string | number
): Promise<ToolOutputData[]> => {
  const url = runId
    ? '/query/tool_output_for_run'
    : '/query/tool_output_for_case';
  const parameter = runId ?? caseId;

  return axios
    .post<ToolOutputData[]>(url, {
      parameters: {
        1: parameter,
        2: parameter,
        3: parameter,
      },
      cacheable: false,
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log('error calling query endpoint for tooloutput data for case');
      throw e;
    });
};

/**
 *
 * @param runId
 */
export const getApplicableRulesFromRun = (
  runId: number
): Promise<ApplicableRule[]> => {
  if (cancelAppRules) {
    cancelAppRules();
  }
  return axios
    .post<ApplicableRule[]>(
      '/query/app_rules_from_run',
      {
        parameters: {
          1: runId,
          2: runId,
        },
      },
      {
        cancelToken: new axios.CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancelAppRules = c;
        }),
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(
        'error calling query endpoint for applicable rules for run',
        e
      );
      throw e;
    });
};

/**
 *
 * @param faultCode
 * @param vehicleObjid
 */
export const getAppRulesForFault = (
  faultCode: string,
  vehicleObjid: number
): Promise<ApplicableRule[]> => {
  if (cancelAppRules) {
    cancelAppRules();
  }
  return axios
    .post<ApplicableRule[]>(
      '/query/apprules_for_faultcode',
      {
        parameters: {
          1: faultCode,
          2: vehicleObjid,
          3: faultCode,
          4: vehicleObjid,
        },
        cacheable: false,
      },
      {
        cancelToken: new axios.CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancelAppRules = c;
        }),
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(
        'error calling query endpoint for activerules_for_vehicle_and_fault'
      );
      throw e;
    });
};

/**
 *
 * @param ruleId
 */
export const getParametersFromRule = (ruleId: number): Promise<string[]> => {
  return axios
    .post<any[]>('/query/parameters_from_rule', {
      parameters: {
        1: ruleId,
      },
    })
    .then((response) => {
      return response.data.map((entry) => {
        return entry.parameterName;
      });
    })
    .catch((e) => {
      console.log('error calling query endpoint for parameters from rule');
      throw e;
    });
};

/**
 *
 * @param caseId
 * @param ruleId
 */
export const getFaultsFromCaseRule = (
  caseId: string,
  ruleId: number
): Promise<number[]> => {
  // console.log(caseId, ruleId)
  return axios
    .post<any[]>('/query/faults_from_case-rule', {
      parameters: {
        1: caseId,
        2: ruleId,
      },
    })
    .then((response) => {
      return response.data.map((entry) => {
        return entry.objid;
      });
    })
    .catch((e) => {
      console.log('error calling query endpoint for faults from rule');
      throw e;
    });
};

/**
 *
 * @param runId
 * @param ruleId
 */
export const getFaultsFromRunRule = (
  runId: string,
  ruleId: number
): Promise<number[]> => {
  return axios
    .post<any[]>('/query/faults_from_run-rule', {
      parameters: {
        1: runId,
        2: ruleId,
      },
    })
    .then((response) => {
      return response.data.map((entry) => {
        return entry.objid;
      });
    })
    .catch((e) => {
      console.log('error calling query endpoint for faults from rule');
      throw e;
    });
};

export const getFiredFaults = (
  ruleId?: string | number,
  caseId?: string,
  runId?: string | number
): Promise<string[]> => {
  const url = runId
    ? '/query/faults_from_run-rule'
    : '/query/faults_from_case-rule';
  const parameter = runId ?? caseId;

  return axios
    .post<{ objid: string }[]>(url, {
      parameters: {
        1: parameter,
        2: ruleId,
      },
    })
    .then((response) => response.data.map((item) => item?.objid));
};

/**
 *
 * @param vehicleObjid
 */
export const getVehicleBomConfig = (
  vehicleObjid: number
): Promise<VehicleConfig[]> => {
  if (cancelBOMConfig) {
    cancelBOMConfig();
  }
  return axios
    .post<VehicleConfig[]>(
      '/query/vehicle_bom_config',
      {
        parameters: {
          1: vehicleObjid,
        },
      },
      {
        cancelToken: new axios.CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancelBOMConfig = c;
        }),
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log('error calling query endpoint for BOM config');
      throw e;
    });
};

/**
 *
 * @param caseId
 * @param ruleId
 */
export const getMaxRunId = (
  caseId: string,
  ruleId: number
): Promise<number[]> => {
  return axios
    .post<number[]>('/query/max_run_for_tool_output', {
      parameters: {
        1: ruleId,
        2: caseId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(
        'error calling query endpoint for faults from max_run_for_tool_output'
      );
      throw e;
    });
};

/**
 *
 * @param runId
 * @param simruleId
 */
export const getFaultsForSimpleRule = (
  runId: number,
  simruleId: number
): Promise<number[]> => {
  return axios
    .post<any[]>('/query/faults_for_simple_rule', {
      parameters: {
        1: runId,
        2: simruleId,
      },
    })
    .then((response) => {
      return response.data.map((entry) => {
        return entry.objid;
      });
    })
    .catch((e) => {
      console.log('error calling query endpoint for faults from simple rule');
      throw e;
    });
};

/**
 *
 * @param runId
 * @param complexRuleId
 */
export const getFaultsForComplexRule = (
  runId: number,
  complexRuleId: number
): Promise<number[]> => {
  return axios
    .post<any[]>('/query/faults_for_complex_rule', {
      parameters: {
        1: runId,
        2: runId,
        3: complexRuleId,
      },
    })
    .then((response) => {
      return response.data.map((entry) => {
        return entry.objid;
      });
    })
    .catch((e) => {
      console.log('error calling query endpoint for faults from complex rule');
      throw e;
    });
};

/**
 *
 * @param rxIds
 */
export const getRxMetics = (rxIds: number[]): Promise<RxMetric[]> => {
  return axios
    .post<RxMetric[]>('/query/rx_metrics', {
      parameters: {
        1: rxIds,
      },
    })
    .then((response) => response.data);
};

export const getCase = (caseId: string) => {
  return axios
    .post<CaseDetails[]>('/query/case_by_id_number', {
      parameters: {
        1: caseId,
      },
    })
    .then((response) => response.data);
};
