import { AutoFixHigh } from '@mui/icons-material';
import { Typography, Divider, Tooltip } from '@mui/material';
import React, { CSSProperties, useMemo } from 'react';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

import { LocoStatusDetailDisplayProps } from 'train-tool/types';

import { useRowProps } from './hooks';

/**
 *
 * @param {any} props implicit component props
 * @param {LocoStatusDetailDisplayProps?} props.displayProps any additional display properties for the given locomotive
 * @param {boolean?} props.divider flag to render the bottom divider or not
 * @param {boolean?} props.showStatusIndicator flag to render the green/red status indicator inline
 * @param {CSSProperties?} props.textProps additional CSS properties to apply to the text
 * @param {string?} props.backgroundColor background color of the text item
 * @returns {JSX.Element} basic text component to be used in the train status table
 */
export const TextItem = (props: {
  displayProps?: LocoStatusDetailDisplayProps;
  divider?: boolean;
  showStatusIndicator?: boolean;
  textProps?: CSSProperties;
  backgroundColor?: string;
}) => {
  const value = props.displayProps?.value ?? '';

  const rowProps = useRowProps();

  const shadowHorizontalOffset = '5px';
  const shadowVerticalOffset = '0px';
  const shadowBlurRadius = '6px';
  const shadowSpreadRadius = '-2px';
  const color = PowerToolDarkTheme.statusGray500;

  const getShadow = useMemo(() => {
    let glowColor = '';

    switch (props.displayProps?.format) {
      case 'error':
        glowColor = PowerToolDarkTheme.statusRed500;
        break;

      case 'warning':
        glowColor = PowerToolDarkTheme.statusOrange500;
        break;

      case 'success':
        glowColor = PowerToolDarkTheme.statusGreen500;
        break;

      default:
        glowColor = '';
    }

    if (glowColor) {
      return {
        boxShadow: `
        2px 0px 2px -1px ${glowColor},
        -2px 0px 2px -1px ${glowColor}
        ,${shadowHorizontalOffset} ${shadowVerticalOffset} ${shadowBlurRadius} ${shadowSpreadRadius} ${color},
        -${shadowHorizontalOffset} ${shadowVerticalOffset} ${shadowBlurRadius} ${shadowSpreadRadius} ${color}
        `,
        clipPath: 'inset(0px -100px 0px -100px)',
      } as CSSProperties;
    }

    return undefined;
  }, [props.displayProps, color]);

  const getFormattedText = useMemo(() => {
    if (value === '' || value === undefined) {
      return <div style={{ color: PowerToolDarkTheme.neutralDark500 }}>—</div>;
    }

    const formattedValue = props.displayProps?.units
      ? `${value} ${props.displayProps.units}`
      : value;

    if (props.displayProps?.format === 'strikethrough') {
      return (
        <div
          style={{
            color: PowerToolDarkTheme.neutralDark500,
            textDecoration: 'line-through',
          }}
        >
          {formattedValue}
        </div>
      );
    }

    return formattedValue;
  }, [value, props.displayProps?.format, props.displayProps?.units]);

  const renderTextComponent = useMemo(() => {
    if (props.displayProps?.tooltip) {
      return (
        <React.Fragment>
          <Tooltip title={props.displayProps.tooltip} arrow placement='left'>
            <AutoFixHigh
              sx={{
                fontSize: '1rem',
                mr: 0.5,
                color: PowerToolDarkTheme.dataPink700,
              }}
            />
          </Tooltip>
          <Typography
            variant='body2'
            sx={{ fontSize: '11px', ...props.textProps }}
          >
            {getFormattedText}
          </Typography>
        </React.Fragment>
      );
    }

    return (
      <Typography variant='body2' sx={{ fontSize: '11px', ...props.textProps }}>
        {getFormattedText}
      </Typography>
    );
  }, [getFormattedText, props.textProps, props.displayProps?.tooltip]);

  // box-shadow: [horizontal offset] [vertical offset] [blur radius] [optional spread radius] [color];
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: props.backgroundColor,
        ...getShadow,
      }}
    >
      <div
        style={{
          ...rowProps,
          marginLeft: '6px',
          marginRight: '6px',
          whiteSpace: 'nowrap',
        }}
      >
        {renderTextComponent}
      </div>
      {props.divider === false ? <></> : <Divider />}
    </div>
  );
};
