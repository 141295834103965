import {
  CloseOutlined,
  ExpandMore,
  NoteAltOutlined,
} from '@mui/icons-material';
import {
  Box,
  AccordionSummary,
  IconButton,
  Chip,
  Typography,
  Accordion,
  ToggleButtonGroup,
  ToggleButton,
  Tooltip,
  Button,
} from '@mui/material';
import chroma from 'chroma-js';
import { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from 'common/hooks';
import { selectAssetDetails } from 'common/stores/globalSlice';
import { openOMDRx } from 'common/util/utils';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

import {
  removeSearchItem,
  selectToolOutputVerification,
  setOpenedRx,
  updateItem,
} from 'power-tool/stores/caseActionSlice';
import { selectToolOutput } from 'power-tool/stores/toolOutputSlice';
import {
  ToolOutputAccordionProps,
  CaseActionChoice,
  ToolOutputItemState,
  ToolOutputAccordionHeaderProps,
} from 'power-tool/tool-dispo/types';

import { AppendComponent } from './AppendComponent';
import { CloseComponent } from './CloseComponent';
import { DeliverComponent } from './DeliverComponent';
import { MergeComponent } from './MergeComponent';

export const ToolOutputAccordionHeader = (
  props: ToolOutputAccordionHeaderProps
) => {
  const dispatch = useAppDispatch();

  return (
    <Box
      sx={{
        py: 1,
        backgroundColor: PowerToolDarkTheme.neutralDark200,
        fontSize: '20px',
        mt: props.spacing,
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginLeft: '20px',
          marginRight: '20px',
        }}
      >
        {props.label}
        {props.SearchItem && (
          <Button
            sx={{
              gridColumnStart: 2,
              alignSelf: 'baseline',
              justifySelf: 'center',
              minWidth: 'unset',
              padding: '4px',
            }}
            onClick={() => dispatch(removeSearchItem())}
          >
            <CloseOutlined />
          </Button>
        )}
        {props.children}
      </div>
    </Box>
  );
};

export const ToolOutputAccordion = (props: ToolOutputAccordionProps) => {
  const allToolOutput = useAppSelector(selectToolOutput);
  const verification = useAppSelector(selectToolOutputVerification);
  const assetDetails = useAppSelector(selectAssetDetails);

  const amIDisabled = useCallback(
    (currentAction: CaseActionChoice, item: ToolOutputItemState): boolean => {
      // if our verification response has 'ALL' in the disable actions object,
      // we disable all actions regardless of all other logic
      if (verification?.disableAllActionsFor?.includes('ALL')) {
        return true;
      }

      // if our verification response has the current rx id in the
      // disable actions object, disable all actions
      if (verification?.disableAllActionsFor?.includes(item.rxId.toString())) {
        return true;
      }

      // if we are currently a CBR tool output, and the other
      //   JDPAD tool output(s) contains the current (CBR) rxId,
      //   we disable the current tool output
      // in other words, if a JDPAD and CBR tool output both point
      // to the same RX, the JDPAD output takes priority
      if (
        item.toolId === 'CBR' &&
        allToolOutput
          .filter((output) => output.toolId === 'JDPAD')
          .map((output) => output.rxId)
          .includes(item.rxId)
      ) {
        return true;
      }

      // action specific logic
      switch (currentAction) {
        // cannot deliver cases for fault tool output
        case 'deliver':
          if (item.toolId === 'Fault') {
            return true;
          } else if (
            verification?.disabledRxList?.includes(item.rxId.toString())
          ) {
            return true;
          }

          return false;

        // merge is only available for multiple jdpad tool outputs
        case 'merge':
          if (allToolOutput.length === 1 || item.toolId === 'Fault') {
            return true;
          }
          return false;

        // append and merge are always available
        case 'append':
        case 'close':
          return false;

        // if any of the above logic fails, disable the button
        default:
          return true;
      }
    },
    [verification, allToolOutput]
  );

  const getActionButtonStyle = (choice: CaseActionChoice) => {
    switch (choice) {
      case 'deliver':
        return {
          '&.Mui-selected': {
            backgroundColor: PowerToolDarkTheme.statusGreen500,
            color: 'white',
          },
          '&.Mui-selected:hover': {
            backgroundColor: chroma(PowerToolDarkTheme.statusGreen500)
              .brighten(0.5)
              .hex(),
          },
        };

      case 'close':
      case 'merge':
      case 'append':
        return {
          '&.Mui-selected': {
            backgroundColor: PowerToolDarkTheme.voltBlue600,
            color: 'white',
          },
          '&.Mui-selected:hover': {
            backgroundColor: chroma(PowerToolDarkTheme.voltBlue600)
              .brighten(0.5)
              .hex(),
          },
        };

      default:
        return undefined;
    }
  };

  const dispatch = useAppDispatch();

  return (
    <Accordion
      expanded={props.item.isExpanded}
      disableGutters={true}
      key={props.item.id}
    >
      <AccordionSummary
        expandIcon={
          <IconButton
            // disabled={!item.selectedAction || props.type === 'fault' && item.selectedAction === 'close'}
            disabled={props.expandDisabled}
            onClick={() => {
              dispatch(
                updateItem({
                  ...props.item,
                  isExpanded: !props.item.isExpanded,
                })
              );
            }}
          >
            <ExpandMore />
          </IconButton>
        }
        sx={{
          display: 'flex',
          flexDirection: 'row-reverse',
          justifyContent: 'space-between',
          width: '100%',
          m: 0,
          '&.MuiAccordionSummary-root:hover': {
            cursor: 'default',
          },
          '& .MuiAccordionSummary-expandIconWrapper': {
            transform: 'rotate(-90deg)',
          },
          '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(0deg)',
          },
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              marginLeft: '10px',
              display: 'flex',
              flexDirection: 'row',
              maxWidth: '800px',
            }}
          >
            {/* If we have a label, and it is CBR or JDPAD */}
            {props.label === undefined ? (
              <></>
            ) : props.label === 'CBR' || props.label === 'JDPAD' ? (
              // Then render a Tooltip'ed Chip component as label, with rule ID as tooltip value
              <Tooltip title={props.item.ruleId} arrow>
                <Chip
                  sx={{
                    mr: 1,
                    fontWeight: 'bold',
                    alignSelf: 'center',
                  }}
                  label={props.label}
                  size='small'
                  color='default'
                />
              </Tooltip>
            ) : (
              // Otherwise, for anything else just render a chip (really shouldn't get here)
              <Chip
                sx={{
                  mr: 1,
                  fontWeight: 'bold',
                  alignSelf: 'center',
                }}
                label={props.label}
                size='small'
                color='default'
              />
            )}

            {props.item.isCustomized === true ? (
              <Tooltip title={'Customized RX'} arrow>
                <Chip
                  sx={{
                    mr: 1,
                    fontWeight: 'bold',
                    alignSelf: 'center',
                    backgroundColor: PowerToolDarkTheme.dataPurple500,
                  }}
                  label={'Custom'}
                  size='small'
                />
              </Tooltip>
            ) : (
              <></>
            )}

            {props.item.itemType === 'advisory' ? (
              <Chip
                sx={{
                  mr: 1,
                  fontWeight: 'bold',
                  alignSelf: 'center',
                  backgroundColor: PowerToolDarkTheme.dataPink600,
                }}
                label={'Advisory'}
                size='small'
              />
            ) : (
              <></>
            )}

            <Typography
              noWrap
              variant='subtitle1'
              sx={{
                // flexBasis: 2,
                fontSize: '20px',
                fontWeight: 500,
                color: PowerToolDarkTheme.neutralDark900,
              }}
            >
              {props.item.rxTitle}
            </Typography>

            {!props.enableOMDLink ? (
              <></>
            ) : (
              <Tooltip arrow title='View RX OMD' enterDelay={200}>
                <span
                  onClick={() => {
                    dispatch(setOpenedRx(props.item));
                    openOMDRx(
                      props.item.rxId,
                      assetDetails?.customerOrgId,
                      assetDetails?.roadNumber,
                      props.item.itemType === 'advisory'
                    );
                  }}
                >
                  <NoteAltOutlined
                    style={{ fontSize: '24px' }}
                    sx={{
                      cursor: 'pointer',
                      ml: 1,
                      color: PowerToolDarkTheme.neutralDark600,
                    }}
                  />
                </span>
              </Tooltip>
            )}
          </div>

          <ToggleButtonGroup
            sx={{ justifySelf: 'self-end', mr: 2 }}
            color='primary'
            value={props.item.selectedAction}
            onChange={(event, value) =>
              props.onActionSelect(event, value, props.item)
            }
            exclusive
            size='small'
          >
            {props.actions.map((action) => (
              <ToggleButton
                key={action}
                value={action}
                disabled={amIDisabled(action, props.item)}
                sx={getActionButtonStyle(action)}
              >
                {action}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </div>
      </AccordionSummary>

      {props.actions.includes('deliver') ? (
        <DeliverComponent
          onUpdate={props.onUpdate}
          action={props.item.selectedAction ?? null}
          currentToolOutput={props.item}
          verification={verification}
          advisoryRX={props.item.itemType === 'advisory'}
        />
      ) : (
        <></>
      )}

      {props.actions.includes('merge') ? (
        <MergeComponent
          onUpdate={props.onUpdate}
          action={props.item.selectedAction ?? null}
          currentToolOutput={props.item}
        />
      ) : (
        <></>
      )}

      {props.actions.includes('append') ? (
        <AppendComponent
          onUpdate={props.onUpdate}
          action={props.item.selectedAction ?? null}
          currentToolOutput={props.item}
        />
      ) : (
        <></>
      )}

      {props.actions.includes('close') ? (
        <CloseComponent
          onUpdate={props.onUpdate}
          action={props.item.selectedAction ?? null}
          currentToolOutput={props.item}
        />
      ) : (
        <></>
      )}
    </Accordion>
  );
};
