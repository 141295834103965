import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'common/store';

import { TrainDataSource, TrainSearchAction } from 'train-tool/types';

export type TrainAnalysisState = {
  dataSource?: TrainDataSource;
  trainSearchItem?: TrainSearchAction;
};

const initialState: TrainAnalysisState = {};

export const trainSlice = createSlice({
  name: 'train',
  initialState,
  reducers: {
    /**
     *
     * @param {TrainAnalysisState} state current train analysis state
     * @param {TrainDataSource} action new datasource to fetch train information from
     */
    setDataSource: (state, action: PayloadAction<TrainDataSource>) => {
      state.dataSource = action.payload;
    },
    /**
     *
     * @param {TrainAnalysisState} state current train analysis state
     * @param {TrainSearchAction} action new searched item in the application; either a train or loco
     */
    setTrainSearchItem: (state, action: PayloadAction<TrainSearchAction>) => {
      state.trainSearchItem = action.payload;
    },
  },
});

export const { setDataSource, setTrainSearchItem } = trainSlice.actions;

/**
 *
 * @param {TrainAnalysisState} state current train analysis state
 * @returns {TrainDataSource} currently selected datasource
 */
export const selectDataSource = (state: RootState) =>
  state.trainAnalysis.dataSource;
/**
 *
 * @param {TrainAnalysisState} state current train analysis state
 * @returns {TrainSearchAction} the current searched item; either train or loco
 */
export const selectTrainSearchItem = (state: RootState) =>
  state.trainAnalysis.trainSearchItem;
export default trainSlice.reducer;
