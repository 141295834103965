import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import {
  getAsset,
  getAssets,
  getAssetsForCustomer,
  getAssetsForRuleFamily,
  getEOAUserLoginName,
  getModelsInFamilies,
} from 'common/api';
import { useAppSelector } from 'common/hooks';
import {
  selectApplicationContext,
  selectCustomer,
  selectRuleFamily,
} from 'common/stores/globalSlice';

import { selectCase } from 'power-tool/stores/casesSlice';

/**
 *
 */
export const useFetchEvoModels = () => {
  const hook = useQuery(['models_in_family'], () =>
    getModelsInFamilies(['ACCCA', 'DCCCA'])
  );

  return {
    evoModels: hook.data,
    evoModelStatus: hook.status,
    ...hook,
  };
};

/**
 *
 */
export const useFetchAssetsForCustomer = () => {
  const customer = useAppSelector(selectCustomer);

  const enabled = useMemo(() => {
    return customer !== undefined;
  }, [customer]);

  const hook = useQuery(
    ['assets_for_customer', { customer }],
    () => getAssetsForCustomer(customer!),
    { enabled }
  );

  return {
    assets: hook.data,
    assetsStatus: hook.status,
    ...hook,
  };
};

/**
 *
 */
export const useFetchAssets = () => {
  const application = useAppSelector(selectApplicationContext);
  const family = useAppSelector(selectRuleFamily);

  const enabled = useMemo(() => {
    if (
      application === 'asset_research' ||
      application === 'asset_view' ||
      application === 'datascreen' ||
      application === 'springboard'
    ) {
      return true;
    } else {
      return family !== undefined;
    }
  }, [family, application]);

  const hook = useQuery(
    ['assets', { family }],
    application === 'asset_research' ||
      application === 'asset_view' ||
      application === 'datascreen' ||
      application === 'springboard'
      ? getAssets
      : () => getAssetsForRuleFamily(family!),
    { enabled }
  );

  return {
    assets: hook.data,
    assetsStatus: hook.status,
    ...hook,
  };
};

export const useFetchAsset = (
  vehicleObjid?: string | number | null,
  caseId?: string | null
) => {
  const enabled = useMemo(() => {
    return vehicleObjid || caseId ? true : false;
  }, [vehicleObjid]);

  const hook = useQuery(
    ['asset', { vehicleObjid }],
    () => getAsset(vehicleObjid, caseId),
    { enabled }
  );

  return {
    asset: hook.data,
    assetStatus: hook.status,
    ...hook,
  };
};

// today this just returns the EOA user name
// eventually, we want this to return more like the default role
export const useFetchUserInfo = (userId?: string | null) => {
  const enabled = useMemo(() => {
    return userId !== undefined;
  }, [userId]);

  const hook = useQuery(
    ['user_info', { userId }],
    () => getEOAUserLoginName(userId),
    { enabled }
  );

  return {
    userName: hook.data,
    userNameStatus: hook.status,
    ...hook,
  };
};
