import { ParameterMap } from 'common/cards/Data/types';
import { defaultTimestampFormat } from 'common/constants';
import { UserDateTimeConfig } from 'common/types/types';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

import { CaseUrgencyChoice } from 'power-tool/types';

export const UrgencyColorMap: Record<CaseUrgencyChoice, string> = {
  R: PowerToolDarkTheme.statusRedFrosted,
  A: PowerToolDarkTheme.dataPink600,
  Y: PowerToolDarkTheme.statusYellowFrosted,
  O: PowerToolDarkTheme.statusOrangeFrosted,
  B: PowerToolDarkTheme.dataTealFrosted,
  C: PowerToolDarkTheme.dataPurpleFrosted,
  W: PowerToolDarkTheme.statusGrayFrosted,
  G: PowerToolDarkTheme.statusGreenFrosted,
};

// These parameters are not expected to come back from the /parameter_map endpoint
export const DefaultParameters: Record<string, ParameterMap> = {
  FAULT_CODE: {
    parameterName: 'FAULT_CODE',
    parameterLabel: 'Fault Code',
    parameterGroup: 'fixed',
  },
  TOOL_IND_TIP: {
    parameterName: 'TOOL_IND_TIP',
    parameterLabel: 'Tool Indicator Tooltip',
    parameterGroup: 'fixed',
  },
  OBJID: {
    parameterName: 'OBJID',
    parameterLabel: 'Objid',
    parameterGroup: 'fixed',
  },
  FAULT_DESC: {
    parameterName: 'FAULT_DESC',
    parameterLabel: 'Fault Description',
    parameterGroup: 'fixed',
    widthOverride: 280,
  },
  FAULT_CLASSIFICATION: {
    parameterName: 'FAULT_CLASSIFICATION',
    parameterLabel: 'Crit',
    parameterGroup: 'fixed',
  },
  SUB_ID: {
    parameterName: 'SUB_ID',
    parameterLabel: 'Sub ID',
    parameterGroup: 'fixed',
  },
  RECORD_TYPE: {
    parameterName: 'RECORD_TYPE',
    parameterLabel: 'Record Type',
    parameterGroup: 'fixed',
  },
  OCCUR_DATE: {
    parameterName: 'OCCUR_DATE',
    parameterLabel: 'Occur Date',
    parameterGroup: 'fixed',
  },
  FAULT_RESET_DATE: {
    parameterName: 'FAULT_RESET_DATE',
    parameterLabel: 'Fault Reset Date',
    parameterGroup: 'fixed',
  },
  ENGINE_SPEED: {
    parameterName: 'ENGINE_SPEED',
    parameterLabel: 'Engine Speed',
    parameterGroup: 'basic',
  },
  WATER_TEMP: {
    parameterName: 'WATER_TEMP',
    parameterLabel: 'Water Temp',
    parameterGroup: 'basic',
  },
  PRECIPIN: {
    parameterName: 'PRECIPIN',
    parameterLabel: 'Precip',
    parameterGroup: 'fixed',
    widthOverride: 23,
  },
  SNOWIN: {
    parameterName: 'SNOWIN',
    parameterLabel: 'Snow (inches)',
    parameterGroup: 'fixed',
  },
  ICON: {
    parameterName: 'ICON',
    parameterLabel: 'Weather',
    parameterGroup: 'fixed',
    widthOverride: 23,
  },
  CMU_TIME: {
    parameterName: 'CMU_TIME',
    parameterLabel: 'CMU Time (GMT)',
    parameterGroup: 'endfields',
  },
  OFFBOARD_LOAD_DATE: {
    parameterName: 'OFFBOARD_LOAD_DATE',
    parameterLabel: 'Offboard Load Date (EST)',
    parameterGroup: 'endfields',
  },
  DEVICE_NAME: {
    parameterName: 'DEVICE_NAME',
    parameterLabel: 'Device Info',
    parameterGroup: 'basic',
  },
  FAULT_INDEX: {
    parameterName: 'FAULT_INDEX',
    parameterLabel: 'Fault Index',
    parameterGroup: 'endfields',
  },
  ADDITIONAL_INFO: {
    parameterName: 'ADDITIONAL_INFO',
    parameterLabel: 'additional info',
    parameterGroup: 'endfields',
  },
  GPS_LONGITUDE: {
    parameterName: 'GPS_LONGITUDE',
    parameterLabel: 'GPS Longitude',
    parameterGroup: 'fixed',
  },
  GPS_LATITUDE: {
    parameterName: 'GPS_LATITUDE',
    parameterLabel: 'GPS Latitude',
    parameterGroup: 'fixed',
  },
  NATIVE_TIME: {
    parameterName: 'OCCUR_DATE',
    parameterLabel: 'Native Time',
    toolTip: 'True occur date time with timezone',
    parameterGroup: 'endfields',
    widthOverride: 135,
  },
};
//columns that are left of the weather columns
export const DefaultLeftMostParameters: Record<string, ParameterMap> = {
  TOOL_RUN_INDICATOR: {
    parameterName: 'TOOL_RUN_INDICATOR',
    parameterLabel: 'Tool Run',
    parameterGroup: 'fixed',
    widthOverride: 23,
  },
  LOCATION: {
    parameterName: 'LOCATION',
    parameterLabel: 'Location',
    parameterGroup: 'fixed',
    widthOverride: 23,
  },
};
//weather columns in the grid
export const DefaultWeatherParameters: Record<string, ParameterMap> = {
  WEATHER: {
    parameterName: 'WEATHER',
    parameterLabel: 'Weather Desc',
    parameterGroup: 'fixed',
    widthOverride: 23,
  },
  TEMPF: {
    parameterName: 'TEMPF',
    parameterLabel: 'Temp (°F)',
    parameterGroup: 'fixed',
    widthOverride: 23,
  },
  PRECIPIN: {
    parameterName: 'PRECIPIN',
    parameterLabel: 'Precip',
    parameterGroup: 'fixed',
    widthOverride: 23,
  },
  WINDSPEEDMPH: {
    parameterName: 'WINDSPEEDMPH',
    parameterLabel: 'Wind Speed (MPH)',
    parameterGroup: 'fixed',
    widthOverride: 23,
  },
};

export const baseTimeSettings: UserDateTimeConfig = {
  zone: 'US/Eastern',
  dateTimeFormat: defaultTimestampFormat,
  dateFormat: 'LL-dd-yy',
  dateTimeFormat12h: 'D t',
};
