import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ThemeTypes } from 'styled-components';

import { ParameterMap } from 'common/cards/Data/types';
import { baseTimeSettings } from 'common/config/config';
import { RootState } from 'common/store';
import {
  Asset,
  PowerToolApplicationContext,
  UserDateTimeConfig,
  User,
} from 'common/types/types';

import { VirtualState } from 'virtual-tester/types';

export type GlobalState = {
  // todo: move URL stuff into its own slice
  caseIdFromURL?: string;
  parameterMap: Record<string, ParameterMap>;
  ruleId: number;
  ruleCardTitle: string;
  assetDetails?: Asset;
  theme: ThemeTypes;
  isLastSelectedRuleFromAppRules: boolean;
  user?: User;
  deselectedType?: string;
  debugDataGrid?: boolean;
  debug?: boolean;
  ruleFamily?: string;

  // this should only be used in DEV/QA environments and
  // never with released versions of PT
  superUser: boolean;

  // determines which cards and settings should be loaded
  // set with URL parameter
  applicationContext?: PowerToolApplicationContext;

  // customer is used for external facing application contexts
  customer?: string;

  // sometimes we can't use a hook to set an error message
  // 95%+ of the time the custom useSnackbar() hook can be used
  // but in certain cases like redux saga handlers,
  // we need to dispatch an error message through redux
  // because we are not in a component
  errorMessage?: string;

  // user timezone settings
  time: UserDateTimeConfig;
};

export const initialGlobalState: GlobalState = {
  parameterMap: {},
  ruleId: 0,
  ruleCardTitle: '',
  theme: 'dark',
  isLastSelectedRuleFromAppRules: false,
  superUser: false,

  // default application timezone is GMT
  // default time format is 12-31-99 23:59:58
  time: baseTimeSettings,
};

export const globalSlice = createSlice({
  name: 'global',
  initialState: initialGlobalState,
  reducers: {
    /**
     *
     * @param state
     * @param action
     */
    setCaseIdFromURL: (state, action: PayloadAction<string>) => {
      state.caseIdFromURL = action.payload;
    },
    /**
     *
     * @param state
     * @param action
     */
    changeRule: (state, action: PayloadAction<number>) => {
      state.ruleId = action.payload;
    },
    /**
     *
     * @param state
     * @param action
     */
    changeRuleCardTitle: (state, action: PayloadAction<string>) => {
      state.ruleCardTitle = action.payload;
    },
    /**
     *
     * @param state
     * @param action
     */
    setParameterMap: (
      state,
      action: PayloadAction<Record<string, ParameterMap>>
    ) => {
      state.parameterMap = action.payload;
    },
    /**
     *
     * @param state
     * @param action
     */
    setAsset: (state, action: PayloadAction<Asset | undefined>) => {
      state.assetDetails = action.payload;
    },
    /**
     *
     * @param state
     * @param action
     */
    changeTheme: (state, action: PayloadAction<ThemeTypes>) => {
      state.theme = action.payload;
    },
    /**
     *
     * @param state
     * @param action
     */
    changeLastSelectedRuleFromAppRules: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isLastSelectedRuleFromAppRules = action.payload;
    },
    /**
     *
     * @param state
     * @param action
     */
    changeUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    /**
     *
     * @param state
     * @param action
     */
    setDeselectedType: (state, action: PayloadAction<string>) => {
      state.deselectedType = action.payload;
    },
    /**
     *
     * @param state
     * @param action
     */
    setSuperUser: (state, action: PayloadAction<boolean>) => {
      state.superUser = action.payload;
    },
    /**
     *
     * @param state
     * @param action
     */
    setApplicationContext: (
      state,
      action: PayloadAction<PowerToolApplicationContext>
    ) => {
      state.applicationContext = action.payload;
    },
    /**
     *
     * @param state
     * @param action
     */
    setCustomer: (state, action: PayloadAction<string>) => {
      state.customer = action.payload;
    },
    /**
     *
     * @param state
     * @param action
     */
    setErrorMessage: (state, action: PayloadAction<string | undefined>) => {
      state.errorMessage = action.payload;
    },
    /**
     *
     * @param state
     * @param action
     */
    setTimeSettings: (state, action: PayloadAction<UserDateTimeConfig>) => {
      state.time = {
        dateTimeFormat:
          action.payload.dateTimeFormat ?? state.time.dateTimeFormat,
        dateFormat: action.payload.dateFormat ?? state.time.dateFormat,
        zone: action.payload.zone ?? state.time.zone,
        dateTimeFormat12h:
          action.payload.dateTimeFormat12h ?? state.time.dateTimeFormat12h,
      };
    },
    setDataGridDebug: (state, action: PayloadAction<boolean>) => {
      state.debugDataGrid = action.payload;
    },
    setDebugMode: (state, action: PayloadAction<boolean | undefined>) => {
      state.debug = action.payload;
    },
    /**
     *
     * @param {VirtualState} state current virtual tester state
     * @param {PayloadAction<string>} action rule family for the current virtual to support asset search
     */
    setRuleFamily: (state, action: PayloadAction<string>) => {
      state.ruleFamily = action.payload;
    },
  },
});

export const {
  setCaseIdFromURL,
  setParameterMap,
  changeRule,
  changeRuleCardTitle,
  setAsset,
  changeTheme,
  changeLastSelectedRuleFromAppRules,
  changeUser,
  setDeselectedType,
  setSuperUser,
  setApplicationContext,
  setCustomer,
  setErrorMessage,
  setTimeSettings,
  setDataGridDebug,
  setDebugMode,
  setRuleFamily,
} = globalSlice.actions;

/**
 *
 * @param state
 */
export const selectCaseIdFromURL = (state: RootState) =>
  state.global.caseIdFromURL;
/**
 *
 * @param state
 */
export const selectParameterMap = (state: RootState) =>
  state.global.parameterMap;
/**
 *
 * @param state
 */
export const selectRule = (state: RootState) => state.global.ruleId;
/**
 *
 * @param state
 */
export const selectRuleCardTitle = (state: RootState) =>
  state.global.ruleCardTitle;
/**
 *
 * @param state
 */
export const selectAssetDetails = (state: RootState) =>
  state.global.assetDetails;
/**
 *
 * @param state
 */
export const selectTheme = (state: RootState) => state.global.theme;
/**
 *
 * @param state
 */
export const selectLastSelectedRuleFromAppRules = (state: RootState) =>
  state.global.isLastSelectedRuleFromAppRules;
/**
 *
 * @param state
 */
export const selectUser = (state: RootState) => state.global.user;
/**
 *
 * @param state
 */
export const selectDeselectedType = (state: RootState) =>
  state.global.deselectedType;
/**
 *
 * @param state
 */
export const selectSuperUser = (state: RootState) => state.global.superUser;
/**
 *
 * @param state
 */
export const selectApplicationContext = (state: RootState) =>
  state.global.applicationContext;
/**
 *
 * @param state
 */
export const selectCustomer = (state: RootState) => state.global.customer;
/**
 *
 * @param state
 */
export const selectErrorMessage = (state: RootState) =>
  state.global.errorMessage;
/**
 *
 * @param state
 */
export const selectTimeSettings = (state: RootState) => state.global.time;
export const selectDebugDataGrid = (state: RootState) =>
  state.global.debugDataGrid;
export const selectDebugMode = (state: RootState) => state.global.debug;
/**
 *
 * @param {VirtualState} state current virtual tester state
 * @returns {string?} rule family for the selected virtual
 */
export const selectRuleFamily = (state: RootState) => state.global.ruleFamily;
export default globalSlice.reducer;
