import axios from 'axios';

import {
  TATUserProperties,
  TrainDataSource,
  TrainResponse,
  TrainSearchItem,
} from './types';

/**
 *
 * @param {string} customer abbreviation to get trains for
 * @param {TrainDataSource} dataSource selected application datasource
 * @returns {Promise<TrainSearchItem>} promise containing all trains for a given customer
 */
export const getTrainsForCustomer = (
  customer: string,
  dataSource: TrainDataSource
): Promise<TrainSearchItem[]> => {
  switch (dataSource) {
    case 'TO':
      return axios
        .post<TrainSearchItem[]>('/query/get_trains_for_customer', {
          parameters: {
            1: customer,
          },
          dataSource: 'DWH',
        })
        .then((response) => {
          return response.data;
        })
        .catch((e) => {
          throw e;
        });

    case 'DART':
      return axios
        .get<string>(`/dart/trains?customer=${customer}`)
        .then((response) => {
          const trains: Set<string> = new Set();

          response.data.split('\n').forEach((line, index) => {
            const items = line.split(',');

            if (index !== 0) {
              trains.add(items[2]);
            }
          });

          return Array.from(trains)
            .filter((item) => item)
            .map((item) => ({ trainId: item }));
        })
        .catch((e) => {
          throw e;
        });

    case 'B2B':
      return axios
        .post<TrainSearchItem[]>('/query/get_trains_for_customer_b2b', {
          parameters: {
            1: customer,
          },
        })
        .then((response) => {
          return response.data;
        })
        .catch((e) => {
          throw e;
        });
  }
};

/**
 *
 * @param {object} props obj
 * @param {string} props.customer customer abbreviation to get trains for
 * @param {TrainDataSource} props.dataSource selected application datasource
 * @param {string} props.trainId present if we are getting train by trainId, otherwise undefined
 * @param {string} props.vehicleHeader present if we are getting train by header/road no, otherwise undefined
 * @param {string} props.roadNumber present if we are getting train by header/road no, otherwise undefined
 * @param {string} props.userId used for Trip_opt trains to save
 * @returns {Promise<TrainResponse>} promise containing processed train, otherwise error
 */
export const getTrain = (props: {
  customer: string;
  dataSource: TrainDataSource;
  trainId?: string;
  vehicleHeader?: string;
  roadNumber?: string;
  userId?: string;
}): Promise<TrainResponse> => {
  let url = '';

  switch (props.dataSource) {
    case 'TO':
      url = `/trip_opt/train`;
      break;

    case 'DART':
      url = `/dart/train`;
      break;

    case 'B2B':
      url = '/b2b/train';
  }

  url += `?customer=${props.customer}`;

  if (props.trainId !== undefined) {
    url += `&trainId=${props.trainId}`;
  } else if (
    props.vehicleHeader !== undefined &&
    props.roadNumber !== undefined
  ) {
    url += `&vehicleHeader=${props.vehicleHeader}&vehicleNo=${props.roadNumber}`;
  }

  if (
    props.userId !== undefined &&
    props.userId !== 'anonymous' &&
    props.dataSource === 'TO'
  ) {
    url += `&userId=${props.userId}`;
  }
  return axios
    .get<TrainResponse>(url)
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });
};

/**
 *
 * @param {string | undefined} userId okta user or employee number to get properties for
 * @returns {Promise<TATUserProperties[]>} API response, should be single record always but returns array
 */
export const getTATUserProperties = (
  userId?: string
): Promise<TATUserProperties[]> => {
  return axios
    .post<TATUserProperties[]>('/query/tat_user_properties', {
      cacheable: false,
      parameters: {
        1: userId,
        2: userId,
      },
    })
    .then((response) => response.data);
};

/**
 * sends train feedback to be saved in the closeout table
 *
 * @param {number} tat2historyobjid objid for the train history entry
 * @param {string} feedback feedback
 * @param {sentiment?} sentiment sentiment level
 * @returns {Promise<void>} updates database
 */
export const submitTrainCloseout = (
  tat2historyobjid: number,
  feedback: string,
  sentiment?: string | number | null
) => {
  return axios
    .post('/closeout', {
      sentiment: sentiment,
      feedback: feedback,
      tatHistoryObjid: tat2historyobjid,
    })
    .then();
};
