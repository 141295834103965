import DragHandleIcon from '@mui/icons-material/DragHandle';
import { useState } from 'react';
import styled from 'styled-components';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

const StyledDragIcon = styled(DragHandleIcon)`
  transition: all 0.5s ease-out !important;
  opacity: 0.2;
  :hover {
    transition: all 0.1s ease-in !important;
    color: ${PowerToolDarkTheme.voltBlue300} !important;
    opacity: 1;
  }
`;
export const DragComponent = () => {
  const [dragging, setDragging] = useState<boolean>(false);
  return (
    <StyledDragIcon
      style={{ cursor: dragging ? 'grabbing' : 'grab', fontSize: '18px' }}
      className={`draggableHandle`}
      onMouseDown={() => {
        setDragging(true);
      }}
      onMouseUp={() => {
        setDragging(false);
      }}
      onClick={(e) => e.stopPropagation()}
    />
  );
};
