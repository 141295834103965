import {
  Engineering,
  FormatListBulletedOutlined,
  NotificationsActiveOutlined,
  PsychologyOutlined,
  RailwayAlertOutlined,
} from '@mui/icons-material';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

import { DPAlarm, AnalysisCardAlertType, AlertTypeDisplayProps } from './types';

export const DPAlarmMap: Record<string, DPAlarm> = {
  TL14_Pinion_Slip: {
    label: 'TL14 Pinion Slip In',
    description: 'Suspected invalid DP message',
    value: 'TL14_Pinion_Slip',
  },
  Locked_Axle: {
    label: 'Locked Axle',
    description: 'Unit has locked axle fault',
    value: 'Locked_Axle',
  },
  AutoTM_CO: {
    label: 'Auto TM Cutout',
    description: 'Unit has TM auto-cutout fault',
    value: 'AutoTM_CO',
  },
  Engine_Shutdown: {
    label: 'Engine Shutdown',
    description: 'ECU has declared shutdown state',
    value: 'Engine_Shutdown',
  },
  BV_Out: {
    label: 'Brake Valve Out',
    description: 'DP is not controlling the Brake Pipe',
    value: 'BV_Out',
  },
  Unx_Airflow: {
    label: 'Unexpected Air Flow Increase',
    description: 'Unexpected air flow',
    value: 'Unx_Airflow',
  },
  Eng_Miscomp: {
    label: 'Engine Status Mis-compare',
    value: 'Eng_Miscomp',
  },
  NoCom_Greater_45: {
    label: 'Communication Interruption - More than 45 seconds',
    value: 'NoCom_Greater_45',
  },
  NoCom_Less_45: {
    label: 'Communication Interruption - Less than 45 seconds',
    value: 'NoCom_Less_45',
  },
  Alarm_Bel: {
    label: 'Alarm Bell Active',
    description: 'Lead unit alarm bell is ringing due to this unit',
    value: 'Alarm_Bel',
  },
  Fire_Mal: {
    label: 'Fire System Malfunction',
    description: 'Invalid DP message',
    value: 'Fire_Mal',
  },
  Fire_alrm: {
    label: 'Fire Alarm Active',
    description: 'Invalid DP message',
    value: 'Fire_alrm',
  },
  Pen_Brk: {
    label: 'Penalty Brake Application in Effect',
    value: 'Pen_Brk',
  },
  Sys_Mal: {
    label: 'Major System Malfunction',
    description: 'Critical DP fault active',
    value: 'Sys_Mal',
  },
  Brk_Warn: {
    label: 'Brake Warning',
    value: 'Brk_Warn',
  },
  Sand: {
    label: 'Sand',
    description: 'TL 23 is high on remote consist',
    value: 'Sand',
  },
  PCS_Open: {
    label: 'PCS Open',
    description: 'Power control switch is open',
    value: 'PCS_Open',
  },
  Wheel_Slip: {
    label: 'Wheel Slip Condition',
    description: 'TL 10 is high on remote consist',
    value: 'Wheel_Slip',
  },
};

export const AnalysisCardCategoryMap: Record<
  AnalysisCardAlertType,
  AlertTypeDisplayProps
> = {
  alarm: {
    color: PowerToolDarkTheme.statusOrange600,
    label: 'DP Alarm',
    priority: 1,
    icon: <NotificationsActiveOutlined />,
  },
  rx: {
    color: PowerToolDarkTheme.dataPurple500,
    label: 'RX',
    icon: <FormatListBulletedOutlined />,
    priority: 1,
  },
  sense: {
    color: PowerToolDarkTheme.statusYellow500,
    label: 'Sense',
    icon: <PsychologyOutlined />,
    priority: 1,
  },
  fault: {
    color: PowerToolDarkTheme.dataTeal600,
    label: 'Fault',
    icon: <RailwayAlertOutlined />,
    priority: 1,
  },
  incident: {
    color: PowerToolDarkTheme.dataGreen600,
    label: 'Defect',
    icon: <Engineering />,
    priority: 1,
  },
};
