import { KeyboardArrowDown } from '@mui/icons-material';
import { Button, MenuItem } from '@mui/material';
import React, { useState } from 'react';

import { PowerToolDropdownMenu } from 'common/components/DropdownMenu';
import { defaultTimestampFormat } from 'common/constants';
import { useAppDispatch, useAppSelector } from 'common/hooks';
import { selectTimeSettings, setTimeSettings } from 'common/stores/globalSlice';
import { UserDateTimeConfig } from 'common/types/types';

export const TimezoneList = () => {
  const dispatch = useAppDispatch();
  const selectedTimeZone = useAppSelector(selectTimeSettings);

  const [layoutAnchor, setLayoutAnchor] = useState<null | HTMLElement>(null);

  const open = Boolean(layoutAnchor);

  const onClose = (event: any) => {
    if (event) {
      dispatch(setTimeSettings(event));
    }

    setLayoutAnchor(null);
  };

  const onOpen = (event: React.MouseEvent<HTMLElement>) => {
    setLayoutAnchor(event.currentTarget);
  };

  return (
    <React.Fragment>
      <Button
        variant='contained'
        size='small'
        disableElevation
        onClick={onOpen}
        endIcon={<KeyboardArrowDown />}
      >
        {selectedTimeZone.zone}
      </Button>

      <PowerToolDropdownMenu
        anchorEl={layoutAnchor}
        open={open}
        onClose={onClose}
      >
        {['US/Eastern', 'US/Central', 'US/Pacific', 'UTC'].map((zone) => {
          return (
            <MenuItem
              key={zone}
              onClick={() =>
                onClose({
                  dateTimeFormat: defaultTimestampFormat,
                  zone,
                } as UserDateTimeConfig)
              }
            >
              {zone}
            </MenuItem>
          );
        })}
      </PowerToolDropdownMenu>
    </React.Fragment>
  );
};
