import { useEffect } from 'react';

import { ClearAllFilterComponent } from 'common/cards/Data/components/footer/ClearFiltersComponent';
import { QuickFilterComponent } from 'common/cards/Data/components/footer/QuickFilterComponent';
import { QuickNavComponent } from 'common/cards/Data/components/footer/QuickNavComponent';
import { RowCountComponent } from 'common/cards/Data/components/footer/RowCountComponent';
import { ServiceSelectionComponent } from 'common/cards/Data/components/footer/ServiceSelectionComponent';
import { ShowFiredComponent } from 'common/cards/Data/components/footer/ShowFiredComponent';
import { LookbackRangeComponent } from 'common/cards/Data/date-range/LookbackRangeComponent';
import { setDayRange } from 'common/cards/Data/date-range/dateRangeSlice';
import { useFetchIngestedData } from 'common/features/ingested-data/hooks';
import { useAppDispatch, useAppSelector } from 'common/hooks';
import { selectApplicationContext } from 'common/stores/globalSlice';

import { HealthCheckToggle } from 'train-tool/components/HealthCheckToggle';

import { IngestedDataGrid } from './IngestedDataGrid';

/**
 *
 */
export const IngestedDataCard = () => {
  const dispatch = useAppDispatch();
  const data = useFetchIngestedData();
  const application = useAppSelector(selectApplicationContext);

  useEffect(() => {
    if (application === 'train_analysis') {
      dispatch(setDayRange('2'));
    }
  }, [application]);

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <IngestedDataGrid />
      <div
        style={{
          marginTop: '2px',
          marginBottom: '2px',
          display: 'flex',
          flexFlow: 'row wrap',
          justifyContent: 'space-between',
          gap: '5px 0px',
        }}
      >
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <LookbackRangeComponent />
          <ServiceSelectionComponent />
          <ShowFiredComponent />
          <QuickFilterComponent />
          <ClearAllFilterComponent />
          <HealthCheckToggle />
        </div>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: 'auto',
          }}
        >
          <RowCountComponent />
          <QuickNavComponent />
        </div>
      </div>
    </div>
  );
};

export default IngestedDataCard;
