import { Stack } from '@mui/material';
import { lazy, Suspense } from 'react';

import { TokenInfo } from 'auth/features/TokenInfo';

import IngestedDataCard from 'common/cards/Data/IngestedDataCard';
import { DataFetchStatus } from 'common/cards/Data/components/DataFetchStatus';
import { DataGridDebugButton } from 'common/cards/Data/components/DatagridDebugButton';
import MapCard from 'common/cards/Map/MapCard';
import { TabbedCard, SingleCard } from 'common/components/card/Card';
import {
  TrainCardLabel,
  TrainToolDataCardLabel,
} from 'common/components/card/CardHeaderLabels';
import { DatapackDataGrid } from 'common/features/datapack/components/DatapackDataGrid';
import { DatapackHelpTooltip } from 'common/features/datapack/components/DatapackHelpTooltip';
import { DatapackPlot } from 'common/features/datapack/components/DatapackPlot';
import { RefreshButton } from 'common/features/refresh/RefreshButton';
import { SocketDemoCard } from 'common/providers/SocketProvider';
import { Card, ResizeHandle } from 'common/types/types';

import ApplicableRulesCard from 'power-tool/cards/ApplicableRules/ApplicableRulesCard';
import AssetCard from 'power-tool/cards/Asset/AssetCard';
import CasesCard from 'power-tool/cards/Cases/CasesCard';
import ConfigCard from 'power-tool/cards/Config/ConfigCard';
import PlotCard from 'power-tool/cards/Plot/PlotCard';
import RuleOutputCard, {
  RuleOutputTitle,
} from 'power-tool/cards/RuleOutput/RuleOutputCard';
import RunsCard from 'power-tool/cards/Runs/RunsCard';
import ToolOutputCard from 'power-tool/cards/ToolOutput/ToolOutputCard';

import AnalysisCard from 'train-tool/cards/Analysis/AnalysisCard';
import TrainStatusCard from 'train-tool/cards/Status/TrainStatusCard';
import TrainCard from 'train-tool/cards/Summary/TrainCard';
import { CurrentWeatherComponent } from 'train-tool/components/CurrentWeatherComponent';

import VirtualPreview from 'virtual-tester/cards/VirtualPreview';

import { HistoryCard } from 'loco-history/cards/Timeline/HistoryCard';

// const MapCard  = lazy(() => import("common/cards/Map/MapCard"));
// const AssetCard  = lazy(() => import("power-tool/cards/Asset/AssetCard"));
// const CasesCard  = lazy(() => import("power-tool/cards/Cases/CasesCard"));
// const ConfigCard  = lazy(() => import("power-tool/cards/Config/ConfigCard"));
// const PlotCard  = lazy(() => import("power-tool/cards/Plot/PlotCard"));
// const ToolOutputCard  = lazy(() => import("power-tool/cards/ToolOutput/ToolOutputCard"));
// const AnalysisCard  = lazy(() => import("train-tool/cards/Analysis/AnalysisCard"));
// const TrainStatusCard  = lazy(() => import("train-tool/cards/Status/TrainStatusCard"));
// const TrainCard  = lazy(() => import("train-tool/cards/Summary/TrainCard"));
// const VirtualPreview  = lazy(() => import("virtual-tester/cards/VirtualPreview"));
// const IngestedDataCard = lazy(() => import("common/cards/Data/IngestedDataCard"));
// const RunsCard = lazy(() => import('power-tool/cards/Runs/RunsCard'));
// const ApplicableRulesCard = lazy(() => import("power-tool/cards/ApplicableRules/ApplicableRulesCard"));
// const RuleOutputCard = lazy(() => import("power-tool/cards/RuleOutput/RuleOutputCard"));

export const layoutColumnScale = 10;
export const layoutRowHeight = 10;

const resizeHandles: ResizeHandle[] = ['se'];
export const defaultCards: Card[] = [
  // Row One
  {
    layout: {
      i: 'ingested-data',
      x: 0,
      y: 0,
      w: 12 * layoutColumnScale,
      h: 30,
      minW: 7 * layoutColumnScale,
      resizeHandles: resizeHandles,
    },
    component: (
      <TabbedCard
        rightHeaderComponent={
          <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'center'}
            spacing={1}
            mb={'3px'}
          >
            <DataGridDebugButton />
            <RefreshButton />
          </Stack>
        }
        centerHeaderComponent={<DataFetchStatus />}
        componentMap={[
          {
            title: 'Data',
            content: <IngestedDataCard />,
          },
        ]}
      />
    ),
  },

  {
    layout: {
      i: 'tools/rules',
      x: 0,
      y: 3 * layoutColumnScale,
      w: 4 * layoutColumnScale,
      h: 13,
      resizeHandles: resizeHandles,
    },
    component: (
      <TabbedCard
        componentMap={[
          {
            title: 'Tools',
            content: <ToolOutputCard />,
          },
          {
            title: 'Rules',
            content: <ApplicableRulesCard />,
          },
        ]}
      />
    ),
  },
  {
    layout: {
      i: 'cases/runs',
      x: 0,
      y: 4 * layoutColumnScale,
      w: 4 * layoutColumnScale,
      h: 17,
      resizeHandles: resizeHandles,
    },
    component: (
      <TabbedCard
        componentMap={[
          {
            title: 'Cases',
            content: <CasesCard />,
          },
          {
            title: 'Runs',
            content: <RunsCard />,
          },
        ]}
      />
    ),
  },

  // Row Two
  {
    layout: {
      i: 'plot',
      x: 8 * layoutColumnScale,
      y: 3 * layoutColumnScale,
      w: 4 * layoutColumnScale,
      h: 30,
    },
    component: (
      <TabbedCard
        componentMap={[
          {
            title: 'Plot',
            content: <PlotCard />,
          },
        ]}
      />
    ),
  },
  {
    layout: {
      i: 'asset/config',
      x: 9.5 * layoutColumnScale,
      y: 6 * layoutColumnScale,
      w: 2.5 * layoutColumnScale,
      h: 36,
      resizeHandles: resizeHandles,
    },
    component: (
      <TabbedCard
        componentMap={[
          {
            title: 'Asset',
            content: <AssetCard />,
          },
          {
            title: 'Config',
            content: <ConfigCard />,
          },
        ]}
      />
    ),
  },

  // Row Three
  {
    layout: {
      i: 'rule-output',
      x: 0,
      y: 6 * layoutColumnScale,
      w: 9.5 * layoutColumnScale,
      h: 36,
      resizeHandles: resizeHandles,
    },
    component: (
      <SingleCard
        title={<RuleOutputTitle />}
        content={
          <Suspense fallback={<></>}>
            <RuleOutputCard />
          </Suspense>
        }
      />
    ),
  },
  {
    layout: {
      i: 'maps',
      x: 4 * layoutColumnScale,
      y: 3 * layoutColumnScale,
      w: 4 * layoutColumnScale,
      h: 30,
      resizeHandles: resizeHandles,
    },
    component: (
      <TabbedCard
        componentMap={[
          {
            title: 'Map',
            content: <MapCard />,
            // content: <PowerToolMap />
          },
        ]}
      />
    ),
  },
];

export const defaultVirtualTesterCards: Card[] = [
  {
    layout: {
      i: 'virtual-preview',
      x: 0 * layoutColumnScale,
      y: 0 * layoutColumnScale,
      w: 5 * layoutColumnScale,
      h: 25,
    },
    component: (
      <TabbedCard
        componentMap={[
          {
            title: 'Virtual',
            content: <VirtualPreview />,
          },
        ]}
      />
    ),
  },
  {
    layout: {
      i: 'plot',
      x: 6 * layoutColumnScale,
      y: 0 * layoutColumnScale,
      w: 7 * layoutColumnScale,
      h: 25,
    },
    component: (
      <TabbedCard
        componentMap={[
          {
            title: 'Plot',
            content: <PlotCard />,
          },
        ]}
      />
    ),
  },
  //ROW 2
  {
    layout: {
      i: 'ingested-data',
      x: 0,
      y: 30,
      w: 12 * layoutColumnScale,
      h: 45,
      minW: 7 * layoutColumnScale,
      resizeHandles: resizeHandles,
    },
    component: (
      <TabbedCard
        rightHeaderComponent={<RefreshButton buttonProps={{ mb: '2px' }} />}
        componentMap={[
          {
            title: 'Data',
            content: <IngestedDataCard />,
          },
        ]}
      />
    ),
  },
];
export const defaultRuleTesterCards: Card[] = [
  {
    layout: {
      i: 'rule-tester',
      x: 0 * layoutColumnScale,
      y: 0 * layoutColumnScale,
      w: 5 * layoutColumnScale,
      h: 25,
    },
    component: (
      <TabbedCard
        componentMap={[
          {
            title: 'Rule',
            content: <div />,
          },
        ]}
      />
    ),
  },
  {
    layout: {
      i: 'plot',
      x: 6 * layoutColumnScale,
      y: 0 * layoutColumnScale,
      w: 7 * layoutColumnScale,
      h: 25,
    },
    component: (
      <TabbedCard
        componentMap={[
          {
            title: 'Plot',
            content: <PlotCard />,
          },
        ]}
      />
    ),
  },
  //ROW 2
  {
    layout: {
      i: 'ingested-data',
      x: 0,
      y: 30,
      w: 12 * layoutColumnScale,
      h: 45,
      minW: 7 * layoutColumnScale,
      resizeHandles: resizeHandles,
    },
    component: (
      <TabbedCard
        componentMap={[
          {
            title: 'Data',
            content: <IngestedDataCard />,
          },
        ]}
      />
    ),
  },
];

export const defaultTrainAnalysisCards: Card[] = [
  //Row One
  {
    layout: {
      i: 'train-summary-card',
      x: 0,
      y: 0,
      w: 4.5 * layoutColumnScale,
      h: 13,
      minW: 4.5 * layoutColumnScale,
      resizeHandles: resizeHandles,
    },
    component: (
      <TabbedCard
        centerHeaderComponent={<TrainCardLabel role='label' />}
        componentMap={[
          {
            title: 'Train',
            content: <TrainCard />,
          },
        ]}
      />
    ),
  },
  {
    layout: {
      i: 'train-status-card',
      x: 4.5 * layoutColumnScale,
      y: 0,
      w: 7.5 * layoutColumnScale,
      h: 47,
      minW: 3 * layoutColumnScale,
      resizeHandles: resizeHandles,
    },
    component: (
      <TabbedCard
        rightHeaderComponent={<RefreshButton buttonProps={{ mb: '2px' }} />}
        componentMap={[
          {
            title: 'Status',
            content: <TrainStatusCard />,
          },
        ]}
      />
    ),
  },
  // Row Two
  {
    layout: {
      i: 'analysis-card',
      x: 0,
      y: 13,
      w: 45,
      h: 42,
      resizeHandles: resizeHandles,
    },
    component: (
      <TabbedCard
        componentMap={[
          {
            title: 'Analysis',
            content: <AnalysisCard />,
          },
        ]}
      />
    ),
  },
  {
    layout: {
      i: 'ingested-data',
      x: 45,
      y: 47,
      w: 7.5 * layoutColumnScale,
      h: 45,
      resizeHandles: resizeHandles,
    },
    component: (
      <TabbedCard
        centerHeaderComponent={<TrainToolDataCardLabel role='label' />}
        componentMap={[
          {
            title: 'Data',
            content: <IngestedDataCard />,
          },
        ]}
      />
    ),
  },

  // Row Three
  {
    layout: {
      i: 'map',
      x: 0,
      y: 55,
      w: 4.5 * layoutColumnScale,
      h: 37,
      resizeHandles: resizeHandles,
    },
    component: (
      <TabbedCard
        centerHeaderComponent={<CurrentWeatherComponent />}
        componentMap={[
          {
            title: 'Map',
            content: <MapCard />,
          },
        ]}
      />
    ),
  },
];

export const defaultLocoHistoryCards: Card[] = [
  {
    layout: {
      i: 'history-card',
      x: 0,
      y: 0,
      w: 120,
      h: 80,
      minW: 4.5 * layoutColumnScale,
      resizeHandles: resizeHandles,
    },
    component: (
      <TabbedCard
        componentMap={[
          {
            title: 'History',
            content: <HistoryCard />,
          },
        ]}
      />
    ),
  },
];

export const datapackCards: Card[] = [
  // Row One
  {
    layout: {
      i: 'datapacks-data',
      x: 0,
      y: 0,
      w: 12 * layoutColumnScale,
      h: 31,
      minW: 7 * layoutColumnScale,
      resizeHandles: resizeHandles,
    },
    component: (
      <TabbedCard
        componentMap={[
          {
            title: 'Data',
            content: <DatapackDataGrid />,
          },
        ]}
        rightHeaderComponent={<DatapackHelpTooltip />}
      />
    ),
  },
  {
    layout: {
      i: 'plot',
      x: 8 * layoutColumnScale,
      y: 31,
      w: 12 * layoutColumnScale,
      h: 32,
    },
    component: (
      <TabbedCard
        componentMap={[
          {
            title: 'Plot',
            content: <DatapackPlot />,
          },
        ]}
      />
    ),
  },
];
export const standaloneDatascreenCards: Card[] = [
  // Row One
  {
    layout: {
      i: 'ingested-data',
      x: 0,
      y: 0,
      w: 12 * layoutColumnScale,
      h: 31,
      minW: 7 * layoutColumnScale,
      resizeHandles: resizeHandles,
    },
    component: (
      <TabbedCard
        componentMap={[
          {
            title: 'Data',
            content: <IngestedDataCard />,
          },
        ]}
      />
    ),
  },
  {
    layout: {
      i: 'plot',
      x: 8 * layoutColumnScale,
      y: 31,
      w: 12 * layoutColumnScale,
      h: 32,
    },
    component: (
      <TabbedCard
        componentMap={[
          {
            title: 'Plot',
            content: <PlotCard />,
          },
        ]}
      />
    ),
  },
];

export const defaultSpringboardCards: Card[] = [
  {
    layout: {
      i: 'history-card',
      x: 0,
      y: 0,
      w: 120,
      h: 80,
      minW: 4.5 * layoutColumnScale,
      resizeHandles: resizeHandles,
    },
    component: (
      <TabbedCard
        componentMap={[
          {
            title: 'History',
            content: <HistoryCard />,
          },
        ]}
      />
    ),
  },
];

export const defaultTokenizerCards: Card[] = [
  {
    layout: {
      i: 'token-card',
      x: 0,
      y: 0,
      w: 120,
      h: 80,
      minW: 4.5 * layoutColumnScale,
      resizeHandles: resizeHandles,
    },
    component: (
      <TabbedCard
        componentMap={[
          {
            title: 'Token',
            content: <TokenInfo />,
          },
        ]}
      />
    ),
  },
];

export const defaultSocketCards: Card[] = [
  {
    layout: {
      i: 'socket-card',
      x: 0,
      y: 0,
      w: 120,
      h: 80,
      minW: 4.5 * layoutColumnScale,
      resizeHandles: resizeHandles,
    },
    component: (
      <TabbedCard
        componentMap={[
          {
            title: 'Socket 🔌',
            content: <SocketDemoCard />,
          },
        ]}
      />
    ),
  },
];
