import { BsFilter } from 'react-icons/bs';
import { FaFilter } from 'react-icons/fa';
import { IoInformationCircleOutline } from 'react-icons/io5';
import styled from 'styled-components';

const StyledAgGridCustom = styled.div`
  height: 100%;

  .ag-theme-alpine {
    --ag-row-border-color: #181d1f;
  }

  .ag-root-wrapper {
    border-radius: 3px;
  }

  .ag-theme-alpine-dark {
    --ag-background-color: ${(props) => props.theme.main};
    --ag-odd-row-background-color: ${(props) => props.theme.main};
    /* --ag-odd-row-background-color: #1e2f41; */
    --ag-header-background-color: ${(props) => props.theme.main};
    --ag-row-border-color: #fff;
    --ag-foreground-color: #e8e8e8;
  }

  .ag-header-cell {
    // or write CSS selectors to make customisations beyond what the parameters support
    border-right: 1px solid ${(props) => props.theme.tertiary};
  }

  .ag-header-row {
    border-color: ${(props) => props.theme.tertiary};
  }

  .ag-header-cell-resize {
    border: 1px solid rgba(0, 0, 0, 0);
  }
  .ag-header-cell-resize::after {
    border: 1px solid rgba(255, 255, 0, 0);
  }

  .ag-header-cell-comp-wrapper {
    height: 100%;
  }

  .ag-row {
    font-size: 11px;
    font-family: 'Roboto';
    border-color: ${(props) => props.theme.tertiary};
  }

  .power-tool-grid .ag-row-selected {
    background-color: rgba(68, 118, 194, 0.85);
  }

  .checkbox-header {
    display: flex;
    align-items: flex-end;
    padding-bottom: 5px;
  }

  .ag-row.fired:not(.ag-row-selected),
  .ag-header .fired,
  .ag-row:not(.ag-row-selected) .ag-cell.fired:not(.outbound) {
    background-color: ${(props) => props.theme.grayHighlight} !important;
  }

  .ag-header .virtualTesterParam:not(.fired) {
    background-color: ${(props) => props.theme.voltBlue800};
  }
  .ag-header .virtualTesterResult:not(.fired) {
    background-color: ${(props) => props.theme.voltBlue700};
  }
  .ag-header .parameterGroup1:not(.fired) {
    background-color: ${(props) => props.theme.parameterGroup1};
  }
  .ag-header .parameterGroup2:not(.fired) {
    background-color: ${(props) => props.theme.parameterGroup2};
  }

  .ag-header .parameterGroup3:not(.fired) {
    background-color: ${(props) => props.theme.parameterGroup3};
  }

  .ag-header .parameterGroup4:not(.fired) {
    background-color: ${(props) => props.theme.parameterGroup4};
  }

  .ag-header .parameterGroup5:not(.fired) {
    background-color: ${(props) => props.theme.parameterGroup5};
  }

  .ag-header .parameterGroup6:not(.fired) {
    background-color: ${(props) => props.theme.parameterGroup6};
  }

  .ag-header .parameterGroup7:not(.fired) {
    background-color: ${(props) => props.theme.parameterGroup7};
  }

  .ag-header .parameterGroup8:not(.fired) {
    background-color: ${(props) => props.theme.parameterGroup8};
  }

  .ag-row:not(.ag-row-selected):not(.fired) .virtualTesterParam {
    background-color: ${(props) => props.theme.voltBlue800};
  }
  .ag-row:not(.ag-row-selected):not(.fired) .virtualTesterResult {
    background-color: ${(props) => props.theme.voltBlue700};
  }
  .ag-row:not(.ag-row-selected):not(.fired) .parameterGroup1 {
    background-color: ${(props) => props.theme.parameterGroup1};
  }

  .ag-row:not(.ag-row-selected):not(.fired) .parameterGroup2 {
    background-color: ${(props) => props.theme.parameterGroup2};
  }

  .ag-row:not(.ag-row-selected):not(.fired) .parameterGroup3 {
    background-color: ${(props) => props.theme.parameterGroup3};
  }

  .ag-row:not(.ag-row-selected):not(.fired) .parameterGroup4 {
    background-color: ${(props) => props.theme.parameterGroup4};
  }

  .ag-row:not(.ag-row-selected):not(.fired) .parameterGroup5 {
    background-color: ${(props) => props.theme.parameterGroup5};
  }

  .ag-row:not(.ag-row-selected):not(.fired) .parameterGroup6 {
    background-color: ${(props) => props.theme.parameterGroup6};
  }

  .ag-row:not(.ag-row-selected):not(.fired) .parameterGroup7 {
    background-color: ${(props) => props.theme.parameterGroup7};
  }

  .ag-row:not(.ag-row-selected):not(.fired) .parameterGroup8 {
    background-color: ${(props) => props.theme.parameterGroup8};
  }

  .ag-cell {
    padding-left: 1px;
    padding-right: 1px;
  }

  .ag-cell-value {
    white-space: nowrap;
  }

  .ag-header-cell {
    padding-left: 2px;
    padding-right: 1px;
    font-size: 12px;
    font-weight: 600;
  }

  .active-fault-column {
    color: ${(props) => props.theme.statusYellow300};
  }

  .critical-fault {
    font-weight: 1000;
  }

  /*Floating filters*/
  .ag-floating-filter-body {
    height: 50px;
  }

  .ag-floating-filter-body input {
    height: 49px;
  }

  .ag-floating-filter-button {
    margin-top: -49px;
  }

  .ag-floating-filter-button button {
    height: 49px;
  }

  .ag-floating-filter-body input {
    font-size: 15px;
    font-weight: bold;
  }

  .ag-react-container {
    height: 100%;
  }

  /* .ag-header-group-cell{
    font-weight: 700;
    font-size: 15;
  } */

  .ag-header-group-text {
    font-weight: 700;
    font-size: 14px;
  }

  .header-light {
    /* background-color: ${(props) => props.theme.voltBlue800}; */
  }

  .header-dark {
    color: ${(props) => props.theme.mainAccentText};
    background-color: ${(props) => props.theme.mainAccent};
  }

  .ag-header-group-cell.parameter-group-even {
    color: #b9bfc4;
    background-color: ${(props) => props.theme.parameterGroupEven};
  }

  .ag-header-group-cell.parameter-group-odd {
    color: #b9bfc4;
    background-color: ${(props) => props.theme.parameterGroupOdd};
  }

  .ag-header-group-cell {
    color: #f1f1f1;
  }

  .ag-header-group-cell.virtualtester {
    /* color: #FFF; */
    background-color: ${(props) => props.theme.voltBlue800};
  }

  .ag-header-group-cell.parametergroup1 {
    /* color: #FFF; */
    background-color: ${(props) => props.theme.parameterGroup1};
  }

  .ag-header-group-cell.parametergroup2 {
    /* color: #FFF; */
    background-color: ${(props) => props.theme.parameterGroup2};
  }

  .ag-header-group-cell.parametergroup3 {
    /* color: #FFF; */
    background-color: ${(props) => props.theme.parameterGroup3};
  }

  .ag-header-group-cell.parametergroup4 {
    /* color: #FFF; */
    background-color: ${(props) => props.theme.parameterGroup4};
  }

  .ag-header-group-cell.parametergroup5 {
    /* color: #FFF; */
    background-color: ${(props) => props.theme.parameterGroup5};
  }

  .ag-header-group-cell.parametergroup6 {
    /* color: #FFF; */
    background-color: ${(props) => props.theme.parameterGroup6};
  }

  .ag-header-group-cell.parametergroup7 {
    /* color: #FFF; */
    background-color: ${(props) => props.theme.parameterGroup7};
  }

  .ag-header-group-cell.parametergroup8 {
    /* color: #FFF; */
    background-color: ${(props) => props.theme.parameterGroup8};
  }

  .ag-overlay-wrapper .ag-react-container {
    height: unset !important;
  }

  .clickableHeader {
    :hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

export default StyledAgGridCustom;

export const StyledColumnTooltipIcon = styled(IoInformationCircleOutline)`
  color: ${(props) => props.theme.tertiary};
  :hover {
    color: ${(props) => props.theme.secondary};
  }
`;

export const StyledInactiveFilterIcon = styled(BsFilter).attrs((props) => ({
  className: props.className,
}))`
  font-size: 18px;
  font-weight: bold;
  color: ${(props) => props.theme.secondary};
  cursor: pointer;
  :hover {
    color: ${(props) => props.theme.tertiary};
  }

  &.active {
    font-size: 30px;
    color: blue;
  }
`;

export const StyledActiveFilterIcon = styled(FaFilter)`
  cursor: pointer;
  color: ${(props) => props.theme.statusYellow300};
  :hover {
    color: ${(props) => props.theme.statusYellow200};
  }
`;
