import { toRelativeUrl } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import { useEffect } from 'react';
import { Outlet } from 'react-router';

import { AuthSplash } from 'auth/components/AuthSplash';

// https://github.com/okta/okta-react/blob/master/samples/routing/react-router-dom-v6/src/components/SecureRoute.tsx
/**
 * @returns {JSX.Element} guarded authentication route - loading splash if navigating, otherwise allow users through to next route if authenticated
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SecureRoute = () => {
  const { oktaAuth, authState } = useOktaAuth();

  useEffect(() => {
    if (!authState) {
      return;
    }

    if (!authState?.isAuthenticated) {
      const originalUri = toRelativeUrl(
        window.location.href,
        window.location.origin
      );
      oktaAuth.setOriginalUri(originalUri);
      void oktaAuth.signInWithRedirect();
    }
  }, [oktaAuth, authState, authState?.isAuthenticated]);

  if (!authState || !authState?.isAuthenticated) {
    return <AuthSplash />;
  }

  return <Outlet />;
};

/**
 * @returns {JSX.Element} temporary route that bypasses PT Okta if opened from a child window
 */
export const AuthRequired = () => {
  // *** IMPORTANT ***
  // Okta authentication is currently bypassed if the
  // user is opening power tool from OMD
  // this needs to be removed when full separation from
  // OMD is completed
  const isCloudfront = process.env.REACT_APP_IS_CLOUDFRONT === 'true';

  if (isCloudfront) {
    return <SecureRoute />;
  } else {
    return <Outlet />;
  }
};
