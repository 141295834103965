import * as React from 'react';

/**
 *
 * @param {React.SVGProps<SVGSVGElement>} props additional SVG display props
 * @returns {SVGElement} icon for representing if a locomotive has steerable trucks
 */
export const SteerableIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width ?? 48}
      height={props.height ?? 24}
      id='Layer_2'
      data-name='Layer 2'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 163.18 76.56'
    >
      <defs>
        <style>
          {`
          .cls-1 {
            stroke-width: 3px;
          }
    
          .cls-1, .cls-2 {
            fill: none;
            stroke: ${props.fill ?? 'none'};
            stroke-miterlimit: 10;
          }
    
          .cls-2 {
            stroke-width: .5px;
          }
          }`}
        </style>
      </defs>
      <g id='Layer_7' data-name='Layer 7'>
        <polygon
          className='cls-1'
          points='161.68 55.15 161.68 69.63 1.5 69.63 1.5 55.26 4.16 53.31 17.48 43.55 35.99 43.55 49.3 53.31 59.1 53.31 72.42 43.55 90.92 43.55 104.24 53.31 114.03 53.31 127.35 43.55 145.86 43.55 159.17 53.31 161.68 55.15'
        />
        <circle
          className='cls-1'
          cx='81.59'
          cy='19.61'
          r='14.02'
          transform='translate(11.52 65.78) rotate(-46.9)'
        />
        <polyline className='cls-1' points='81.59 33.63 81.59 19.61 94.44 14' />
        <line className='cls-1' x1='68.74' y1='14' x2='81.59' y2='19.61' />
      </g>
      <g id='Layer_10' data-name='Layer 10'>
        <path
          className='cls-2'
          d='M92.98,32.85c1.25-1.07,2.34-2.31,3.23-3.68,.9-1.37,1.61-2.88,2.09-4.49'
        />
        <path
          className='cls-2'
          d='M92.06,35.89c.76-.49,1.48-1.03,2.16-1.62,1.37-1.18,2.58-2.55,3.58-4.08,1-1.52,1.79-3.19,2.32-4.97,.23-.75,.41-1.51,.54-2.29'
        />
        <path
          className='cls-2'
          d='M64.9,24.78c.48,1.57,1.19,3.07,2.09,4.43,.9,1.37,2,2.62,3.28,3.71'
        />
        <path
          className='cls-2'
          d='M62.48,22.72c.15,.89,.35,1.77,.62,2.63,.54,1.73,1.31,3.38,2.31,4.91,1,1.52,2.21,2.91,3.63,4.11,.6,.51,1.22,.98,1.88,1.41'
        />
        <path
          className='cls-2'
          d='M86.44,2.83c-1.58-.46-3.21-.69-4.85-.69-1.64,0-3.29,.23-4.9,.71'
        />
        <path
          className='cls-2'
          d='M89.49,1.93c-.83-.37-1.67-.67-2.54-.92-1.74-.5-3.55-.76-5.37-.76-1.82,0-3.65,.25-5.43,.78-.75,.22-1.49,.48-2.21,.8'
        />
      </g>
      <g id='Layer_8' data-name='Layer 8' stroke={props.fill}>
        <path
          fill={props.fill}
          d='M43.96,73.75c-1.29,8.34-8.51,14.74-17.21,14.74s-15.92-6.4-17.21-14.74H43.96Z'
        />
        <path
          fill={props.fill}
          d='M98.8,73.75c-1.29,8.34-8.51,14.74-17.21,14.74s-15.92-6.4-17.21-14.74h34.42Z'
        />
        <path
          fill={props.fill}
          d='M153.78,73.75c-1.29,8.34-8.51,14.74-17.21,14.74s-15.92-6.4-17.21-14.74h34.42Z'
        />
      </g>
    </svg>
  );
};
