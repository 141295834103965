import { ZoomOut } from '@mui/icons-material';
import { Fade, IconButton, Typography, Zoom } from '@mui/material';
import { Stack } from '@mui/system';
import {
  Chart as ChartJS,
  CategoryScale,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Decimation,
  ChartData,
  ChartOptions,
  ChartDataset,
  ScaleOptions,
  Colors,
  TooltipItem,
} from 'chart.js';
import type { DecimationOptions } from 'chart.js';
// eslint-disable-next-line import/no-unresolved
import 'chartjs-adapter-luxon';
import annotationPlugin, {
  AnnotationPluginOptions,
} from 'chartjs-plugin-annotation';
import zoomPlugin from 'chartjs-plugin-zoom';
import chroma from 'chroma-js';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';

import { ExportModal } from 'common/cards/Data/components/ExportModal';
import { PlotMenu } from 'common/features/plot/PlotMenu';
import {
  AxisConfiguration,
  BaseChartOptions,
  ExportOptions,
} from 'common/features/plot/types';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Colors,
  Title,
  Tooltip,
  Legend,
  Decimation,
  zoomPlugin,
  annotationPlugin
);

export type PlotPoint = {
  index: number;
  timestamp: string;
  value: number | string | undefined;
};

export const BasePlot = ({
  datasets,
  options,
  defaultContinuous,
  annotations,
}: {
  datasets: Record<string, PlotPoint[]>;
  options?: BaseChartOptions;
  defaultContinuous?: boolean;
  annotations?: AnnotationPluginOptions;
}) => {
  const ref = useRef<ChartJS<'line'>>(null);
  const exportRef = useRef<ChartJS<'line'>>(null);
  const exportedElementRef = useRef<HTMLDivElement | null>(null);

  const [resetZoomButton, setResetZoomButton] = useState<boolean>(false);
  const [exportResetZoomButton, setExportResetZoomButton] =
    useState<boolean>(false);
  const [continuous, setContinuous] = useState<boolean>(
    defaultContinuous ?? false
  );
  const [decimation, setDecimation] = React.useState<DecimationOptions>({
    enabled: true,
    algorithm: 'min-max',
  });
  const [axisConfigs, setAxisConfigs] = useState<AxisConfiguration[]>(
    Object.keys(datasets).map((parameter) => ({ parameter, axis: 'L' }))
  );

  const [showLines, setShowLines] = useState<boolean>(true);
  const [showAnnotations, setShowAnnotations] = useState<boolean>(true);
  const [showModal, setShowModal] = useState<boolean>(false);

  const colors = [
    PowerToolDarkTheme.dataYellow500,
    PowerToolDarkTheme.dataGreen500,
    PowerToolDarkTheme.dataPurple500,
    PowerToolDarkTheme.dataOrange500,
    PowerToolDarkTheme.dataPink500,
    PowerToolDarkTheme.dataTeal500,
    PowerToolDarkTheme.neutralDark400,
    PowerToolDarkTheme.dataYellow200,
    PowerToolDarkTheme.dataGreen200,
    PowerToolDarkTheme.dataPurple200,
    PowerToolDarkTheme.dataOrange200,
    PowerToolDarkTheme.dataPink200,
    PowerToolDarkTheme.dataTeal200,
    PowerToolDarkTheme.neutralDark800,
  ];

  const data = useMemo((): ChartData<'line'> => {
    if (datasets) {
      const right = axisConfigs
        .filter((config) => config.axis === 'R')
        .map((config) => config.parameter);

      return {
        // for each dataset given to the plot
        datasets: Object.entries(datasets).map(
          ([parameter, dataset], i): ChartDataset<'line'> => {
            const pointData: any[] = [];

            // for each point in the dataset
            dataset.forEach((point) => {
              // if the mode is continuous, use whatever the index is that was given for X axis
              if (continuous) {
                pointData.push({
                  x: point.index,
                  y: point.value,
                });
              }

              // otherwise use time for x axis
              else {
                pointData.push({
                  x: new Date(point.timestamp).getTime(),
                  y: point.value,
                });
              }
            });

            // return a single dataset
            return {
              yAxisID: right.includes(parameter) ? 'y1' : 'y',
              data: pointData,
              label: parameter,
              borderColor: colors[i % colors.length],
              backgroundColor: chroma(colors[i % colors.length])
                .alpha(showLines ? 0.5 : 1)
                .hex(),
              borderWidth: showLines ? 1.5 : 0,
            };
          }
        ),
      };
    }

    return {
      datasets: [],
    };
  }, [datasets, continuous, axisConfigs, showLines]);

  const baseDependentAxis: ScaleOptions<'linear'> = useMemo(() => {
    return {
      type: 'linear',
      grid: {
        color: PowerToolDarkTheme.statusGray900,
      },
      title: {
        display: true,
        color: 'white',
      },
      ticks: {
        color: 'white',
      },
    };
  }, []);

  const axisTitleConfig = useMemo(() => {
    return {
      display: true,
      color: PowerToolDarkTheme.neutralDark700,
      font: {
        weight: 'bold',
      },
    };
  }, []);

  const dependentLeft: ScaleOptions<'linear'> = useMemo(() => {
    return {
      ...baseDependentAxis,
      title: {
        ...axisTitleConfig,
        text: axisConfigs.filter((c) => c.axis === 'L').map((c) => c.parameter),
      },
    };
  }, [baseDependentAxis, axisConfigs]);

  const dependentRight: ScaleOptions<'linear'> = useMemo(() => {
    return {
      ...baseDependentAxis,
      display: axisConfigs.filter((c) => c.axis === 'R').length > 0,
      grid: {
        drawOnChartArea: false,
      },
      position: 'right',
      title: {
        ...axisTitleConfig,
        text: axisConfigs.filter((c) => c.axis === 'R').map((c) => c.parameter),
      },
    };
  }, [baseDependentAxis, axisConfigs]);

  const independentAxis: ScaleOptions<'linear' | 'time'> = useMemo(() => {
    return {
      type: continuous ? ('linear' as const) : ('time' as const),
      title: {
        ...axisTitleConfig,
        text: continuous ? 'Sample No.' : 'Time',
      },
      time: {
        displayFormats: {
          month: 'dd MMM',
        },
      },
      grid: {
        color: PowerToolDarkTheme.statusGray900,
      },
      ticks: {
        color: 'white',
      },
    };
  }, [continuous]);

  const chartOptions: ChartOptions<'line'> = React.useMemo(
    () => ({
      // Turn off animations and data parsing for performance
      // animation: false as const,
      // parsing: false as const,
      responsive: true as const,
      maintainAspectRatio: false,
      color: 'white',
      // tooltip & hover options
      interaction: {
        mode: 'point' as const,
        axis: 'x' as const,
        intersect: false,
      },

      // axis options
      scales: {
        y: dependentLeft,
        y1: dependentRight,
        x: independentAxis,
      },

      // additional plugin configurations
      plugins: {
        title: {
          display: true,
          text: 'Datapack Chart',
          color: 'white',
        },
        tooltip: {
          callbacks: {
            title: (context: TooltipItem<'line'>[]) => {
              return options?.tooltipTitle
                ? `${options.tooltipTitle}: ${context[0].label}`
                : context[0].label;
            },
            footer: (context: TooltipItem<'line'>[]) => {
              return options?.tooltipFooter;
            },
          },
        },
        legend: {
          onClick: (event, item, legend) => {
            // use the default handler
            // @ts-ignore
            ChartJS.defaults.plugins.legend.onClick(event, item, legend);
          },
        },
        decimation: decimation,
        // https://www.chartjs.org/chartjs-plugin-zoom/guide/options.html
        zoom: {
          pan: {
            enabled: true,
            mode: 'x' as const,
            modifierKey: 'shift' as const,
          },
          zoom: {
            mode: 'x' as const,
            drag: {
              enabled: true,
            },
            onZoomComplete: (chart) => {
              if (showModal) {
                setExportResetZoomButton(chart.chart.getZoomLevel() > 1);
              } else {
                setResetZoomButton(chart.chart.getZoomLevel() > 1);
              }
            },
          },
          limits: {
            y: {
              min: 1,
            },
          },
        },
        annotation: showAnnotations ? annotations : undefined,
      },
      ...options,
    }),
    [
      decimation,
      dependentLeft,
      dependentRight,
      independentAxis,
      showAnnotations,
      showModal,
      annotations,
      options,
    ]
  );

  useEffect(() => {
    const parameters = Object.keys(datasets);
    setAxisConfigs((current) => {
      // grab current axis configs that are still plotted
      const existingConfigs = current.filter((parameter) =>
        parameters.includes(parameter.parameter)
      );

      // new configs will be ones we don't already have
      const newConfigs: AxisConfiguration[] = parameters
        .filter(
          (parameter) =>
            !existingConfigs
              .map((config) => config.parameter)
              .includes(parameter)
        )
        .map((parameter) => ({ parameter, axis: 'L' }));

      return [...existingConfigs, ...newConfigs];
    });
  }, [datasets]);

  useEffect(() => {
    if (options?.onModeChange) {
      options.onModeChange(continuous ? 'continuous' : 'time');
    }
  }, [options?.onModeChange, continuous]);

  if (data.datasets.length === 0) {
    return (
      <Zoom in={data.datasets.length === 0}>
        <Stack height={'100%'} alignItems={'center'} justifyContent={'center'}>
          <Typography variant='h2' sx={{ fontSize: '28px', mb: 3 }}>
            Click on column headers to create a plot
            {/* <Timeline sx={{ml: 2}} fontSize='large'/> */}
          </Typography>
        </Stack>
      </Zoom>
    );
  }

  return (
    <Fade in={data.datasets.length > 0}>
      <Stack height={'100%'} position={'relative'}>
        <div
          style={{
            position: 'absolute',
            right: '5px',
            top: '3px',
          }}
        >
          <PlotMenu
            continuousOptions={{
              onContinuousClick: () => setContinuous((current) => !current),
              state: continuous,
            }}
            downsamplingOptions={{
              state: decimation.enabled,
              show: options?.decimation ?? true,
              onDownsamplingClick: () =>
                setDecimation((current) => ({
                  algorithm: 'min-max',
                  enabled: !current.enabled,
                })),
            }}
            axisConfigOptions={{
              state: axisConfigs,
              onAxisConfigUpdate: (configs) => setAxisConfigs(configs),
            }}
            showLinesOptions={{
              state: showLines,
              onShowLinesChange: () => setShowLines((current) => !current),
            }}
            showAnnotationsOptions={{
              state: showAnnotations,
              onShowAnnotationsChange: () =>
                setShowAnnotations((current) => !current),
              show: annotations !== undefined,
            }}
            exportOptions={{
              onExportClick: () => setShowModal(true),
              show: options?.export?.enabled === true,
            }}
          />
        </div>
        <div
          style={{
            position: 'absolute',
            right: '70px',
            top: '0px',
          }}
        >
          <Zoom in={resetZoomButton}>
            <IconButton
              onClick={() => {
                ref.current?.resetZoom();
              }}
              size='large'
            >
              <ZoomOut fontSize='large' />
            </IconButton>
          </Zoom>
        </div>
        <Line ref={ref} options={chartOptions} data={data} />

        {options?.export?.enabled === true ? (
          <ExportModal
            open={showModal}
            filename={options?.export?.filename}
            backgroundColor={PowerToolDarkTheme.main}
            onClose={() => setShowModal(false)}
            exportedElement={exportedElementRef}
          >
            <Zoom
              in={exportResetZoomButton}
              style={{
                position: 'absolute',
                right: '70px',
                top: '40px',
              }}
            >
              <IconButton
                onClick={() => {
                  exportRef.current?.resetZoom();
                }}
                size='large'
              >
                <ZoomOut fontSize='large' />
              </IconButton>
            </Zoom>
            <div ref={exportedElementRef} style={{ width: 1400, height: 600 }}>
              <Line ref={exportRef} options={chartOptions} data={data} />
            </div>
          </ExportModal>
        ) : (
          <></>
        )}
      </Stack>
    </Fade>
  );
};
