import {
  ColDef,
  FirstDataRenderedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  SelectionChangedEvent,
} from 'ag-grid-community';
import { useEffect, useCallback, useRef, useMemo, useState } from 'react';
import { batch } from 'react-redux';

import {
  setDateRange,
  setDateSource,
  setDayRange,
} from 'common/cards/Data/date-range/dateRangeSlice';
import { DayRangeChoice } from 'common/cards/Data/types';
import { AgGridCustom } from 'common/components/ag-grid/DensePowerToolGrid';
import { ZonedTimeCell } from 'common/components/ag-grid/cell-renderers/ZonedTimeCell';
import { useAppDispatch, useAppSelector } from 'common/hooks';
import { changeRule } from 'common/stores/globalSlice';

import { useFetchCases } from 'power-tool/api/hooks';
import { selectCase, setSelectedCase } from 'power-tool/stores/casesSlice';
import { setRun } from 'power-tool/stores/runSlice';
import { CaseDetails } from 'power-tool/types';

export const CasesCard = () => {
  const dispatch = useAppDispatch();

  const selectedCase = useAppSelector(selectCase);
  const selectedCaseRef = useRef<CaseDetails | undefined>(selectedCase);

  const { cases } = useFetchCases();

  const [api, setApi] = useState<GridApi>();

  useEffect(() => {
    selectedCaseRef.current = selectedCase;
    if (selectedCase === undefined) {
      api?.deselectAll();
    }
  }, [selectedCase]);

  const columns: ColDef<CaseDetails>[] = useMemo(
    () => [
      {
        field: 'caseId',
        headerName: 'Case ID',
        width: 70,
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
      },
      {
        field: 'caseTitle',
        headerName: 'Case Title',
        width: 275,
        filter: 'agSetColumnFilter',
      },
      {
        field: 'caseType',
        headerName: 'Case Type',
        width: 85,
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
      },
      {
        field: 'creationDate',
        headerName: 'Creation Date',
        width: 110,
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        cellRenderer: ZonedTimeCell,
      },
      {
        field: 'closeDate',
        headerName: 'Closed Date',
        width: 100,
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        cellRenderer: ZonedTimeCell,
      },
    ],
    []
  );

  const onFirstDataRendered = useCallback(
    (params: FirstDataRenderedEvent<CaseDetails>) => {
      params.api.sizeColumnsToFit();
      if (selectedCaseRef.current) {
        params.api
          .getRowNode(selectedCaseRef.current.caseId)
          ?.setSelected(true);
      }
    },
    [selectedCase?.caseId]
  );

  const onSelectionChanged = useCallback(
    (params: SelectionChangedEvent<CaseDetails>) => {
      const selectedCase = params.api.getSelectedRows().find((first) => first);

      let lookback: DayRangeChoice;
      switch (selectedCase?.caseType) {
        case 'ESTP Problem':
          lookback = '30';
          break;

        case 'Oil Anomaly':
          lookback = '90';
          break;

        case 'EOA Problem':
        case 'QNX Equipment':
        case 'Outbound Review':
        default:
          lookback = '7';
          break;
      }

      // if case changes and we don't have anything in redux
      // OR
      // the redux case id does not match our new selection
      // then dispatch change of case
      if (
        selectedCaseRef.current === undefined ||
        selectedCaseRef.current.caseId !== selectedCase?.caseId
      ) {
        batch(() => {
          // change to case
          // reset rule, run
          dispatch(setSelectedCase(selectedCase));
          dispatch(setRun(undefined));
          dispatch(changeRule(0));

          // change to case specific lookback
          // reset date range
          // reset to occur_date
          dispatch(setDayRange(lookback));
          dispatch(setDateSource('occur_date'));
          dispatch(setDateRange(undefined));
        });
      }
    },
    []
  );

  const onGridReady = useCallback((params: GridReadyEvent) => {
    setApi(params.api);
  }, []);

  const gridOptions: GridOptions = {
    onGridReady: onGridReady,
    onFirstDataRendered: onFirstDataRendered,
    onSelectionChanged: onSelectionChanged,
    getRowId: (data) => data.data.caseId,

    overlayNoRowsTemplate: 'No Cases for Vehicle',
    skipHeaderOnAutoSize: true,
    headerHeight: 24,
    rowSelection: 'single',
  };

  return (
    <AgGridCustom<CaseDetails>
      data={cases}
      columns={columns}
      options={gridOptions}
    />
  );
};

export default CasesCard;
