import {
  GridApi,
  ICellRendererParams,
  ProcessCellForExportParams,
  ValueFormatterParams,
} from 'ag-grid-community';

import { ToggleCustomFilterParams } from 'common/cards/Data/types';
import { AssetTimeZoneCell } from 'common/components/ag-grid/cell-renderers/AssetTimeZoneCell';
import { FaultCodeCell } from 'common/components/ag-grid/cell-renderers/FaultCodeCell';
import { FaultDescriptionCell } from 'common/components/ag-grid/cell-renderers/FaultDescriptionCell';
import {
  FixedTimeZoneCell,
  FixedTimeZoneCellWithTZ,
} from 'common/components/ag-grid/cell-renderers/FixedTimeZoneCell';
import { LocationCell } from 'common/components/ag-grid/cell-renderers/LocationCell';
import { ToolRunIndicatorCell } from 'common/components/ag-grid/cell-renderers/ToolRunIndicatorCell';
import { WeatherIconCell } from 'common/components/ag-grid/cell-renderers/WeatherIconCell';
import { WindSpeedCell } from 'common/components/ag-grid/cell-renderers/WindSpeedCell';
import { IngestedData } from 'common/features/ingested-data/types';
import { Asset } from 'common/types/types';

import {
  fixedTimeColumns,
  controllerZonedTimeColumns,
  zonedTimeColumns,
} from './const';

// https://www.ag-grid.com/react-data-grid/cell-content/
// https://www.ag-grid.com/react-data-grid/reactui/
/**
 *
 * @param {string | undefined} column column to get cell renderer for
 * @returns {(props: ICellRendererParams) => JSX.Element | undefined} either a cell renderer function or undefined
 */

export const getCellRenderer = (column?: string) => {
  if (column === undefined || column === '') return undefined;

  // time columns render an ISO timestamp either in a fixed time
  // or a zoned time depending on the customer and application

  // columns that we convert based on asset controller
  // application specific logic within this renderer as well
  if (controllerZonedTimeColumns.includes(column)) {
    return AssetTimeZoneCell;
  }

  // columns that we always display in the timezone they are sent in
  if (fixedTimeColumns.includes(column)) {
    return FixedTimeZoneCell;
  }

  // columns that we always display in the timezone they are sent in
  // plus we show the timezone with it
  if (zonedTimeColumns.includes(column)) {
    return FixedTimeZoneCellWithTZ;
  }

  // depending on the column, we may or may not have a custom renderer defined
  switch (column.toLowerCase()) {
    case 'weather':
      return WeatherIconCell;

    case 'proximity_label':
      return LocationCell;

    case 'windspeedmph':
      return WindSpeedCell;

    case 'fault_code':
      return FaultCodeCell;

    case 'fault_desc':
      return FaultDescriptionCell;

    case 'tool_run_indicator':
      return ToolRunIndicatorCell;

    // no renderer defined
    default:
      return undefined;
  }
};

/**
 * Generic function to create a simple internal filter
 *
 * @param column column to filter on
 * @param type https://www.ag-grid.com/react-data-grid/filter-provided-simple/#simple-filter-options
 * @param filterType 'number', 'text', or 'date'
 * @param value filter threshold value
 * @param api Grid API to apply the filter on
 */
export const applyFilter = (
  column: string,
  filterType: 'number' | 'text' | 'date',
  type: string,
  value: number,
  api?: GridApi
) => {
  api?.getFilterInstance(column)?.setModel({
    filterType: filterType,
    type: type,
    filter: value,
  });

  api?.onFilterChanged();
};

/**
 *
 * @param {string} column column to apply set filter to
 * @param {any[]} values values to 'check' on the set filter
 * @param {GridApi | undefined} api gridApi to apply the filter to
 */
export const applySetFilter = (
  column: string,
  values: any[],
  api?: GridApi
) => {
  api?.getFilterInstance(column)?.setModel({ values });
  api?.onFilterChanged();
};

/**
 * Generic function to destroy a simple internal filter
 *
 * @param column
 * @param api
 */
export const destroyFilter = (column: string, api?: GridApi) => {
  if (api?.getFilterInstance(column)?.getModel()) {
    api?.destroyFilter(column);
  }

  api?.onFilterChanged();
};

/**
 *
 * @param {ToggleCustomFilterParams} props set filter toggle props
 */
export const toggleCustomFilter = (props: ToggleCustomFilterParams) => {
  const value = props.quickFilter.keys().next().value;
  const isChecked = props.quickFilter.values().next().value;

  // independent of model
  if (value === 'Has Datapack') {
    if (isChecked) {
      props.datapacks.current = true;
      props.api?.onFilterChanged();
    } else {
      props.datapacks.current = false;
      props.api?.onFilterChanged();
    }
  }

  // dependent on model
  if (
    props.asset?.vehicleModel &&
    props.models?.includes(props.asset?.vehicleModel.toString())
  ) {
    if (value === 'Notch 8') {
      if (isChecked) {
        applySetFilter('MP_0102_N_0_0', [8.0], props.api);
      } else {
        destroyFilter('MP_0102_N_0_0', props.api);
      }
    }

    if (value === 'Full Load') {
      if (isChecked) {
        applyFilter(
          'MP_1000_N_0_0',
          'number',
          'greaterThanOrEqual',
          4000.0,
          props.api
        );
      } else {
        destroyFilter('MP_1000_N_0_0', props.api);
      }
    }

    if (value === 'Engine Running') {
      if (isChecked) {
        applyFilter(
          'MP_1005_N_0_0',
          'number',
          'greaterThanOrEqual',
          300.0,
          props.api
        );
      } else {
        destroyFilter('MP_1005_N_0_0', props.api);
      }
    }

    if (value === 'Steady State') {
      if (isChecked) {
        props.steadyState.current = true;
        props.api?.onFilterChanged();
      } else {
        props.steadyState.current = false;
        props.api?.onFilterChanged();
      }
    }
  } else {
    if (value === 'Notch 8') {
      if (isChecked) {
        applySetFilter('NOTCH', [8.0], props.api);
      } else {
        destroyFilter('NOTCH', props.api);
      }
    }

    if (value === 'Full Load') {
      if (isChecked) {
        applyFilter('HP', 'number', 'greaterThanOrEqual', 3900.0, props.api);
      } else {
        destroyFilter('HP', props.api);
      }
    }

    if (value === 'Engine Running') {
      if (isChecked) {
        applyFilter(
          'ENGINE_SPEED',
          'number',
          'greaterThanOrEqual',
          300.0,
          props.api
        );
      } else {
        destroyFilter('ENGINE_SPEED', props.api);
      }
    }
  }
};

/**
 * used to calculate header widths for grids.
 * filters down the columnName to the largest word, split on spaces. Then compares that with the dataExample, whichever is larger gets extrapixels added to it
 * and if that is larger than the minimumWidth we return it, otherwise we return minimumWidth
 *
 * @param {object} props object containing below props. using an object so that names appear in function calls for readability
 * @param {string} props.columnName column name
 * @param {number} props.extraPixels extra pixels to always add on top of the final calculation
 * @param {number} props.minimumWidth minimum possible width
 * @param {string | undefined} props.dataExample example of a value in the column, preferably the largest
 * @returns {number} the final width
 */
export const calculateHeaderWidth = (props: {
  columnName: string;
  // the calculation is a little too accurate. give a few pixels of room to the column
  extraPixels?: number;
  minimumWidth?: number;
  dataExample?: string;
}): number | undefined => {
  // to calcuate widths, we create a dummy DOM element and measure its width
  // create canvas element inside a document framgment
  const fragment: DocumentFragment = document.createDocumentFragment();
  const canvas: HTMLCanvasElement = document.createElement('canvas');
  fragment.appendChild(canvas);

  // get the canvas element, set the font that we use in the header
  const context = canvas.getContext('2d') as CanvasRenderingContext2D;

  // header font is 12px roboto
  context.font = '12px Roboto';
  const text = props.columnName.split(' ');
  const labelWidth = Math.max(...text.map((t) => context.measureText(t).width));

  // data font is 11px roboto
  context.font = '11px Roboto';
  const dataWidth = props.dataExample
    ? context.measureText(props.dataExample).width
    : 0;

  return Math.max(
    Math.max(labelWidth, dataWidth) + (props.extraPixels ?? 10),
    props.minimumWidth ?? 0
  );
};

export const getSelectedFaultsOnGrid = (
  api: GridApi<IngestedData>
): IngestedData[] => {
  const rows: IngestedData[] = [];

  // for each selected range
  api.getCellRanges()?.forEach((range) => {
    // mark the start and end rows (start can be greater than end)
    const i = range.startRow?.rowIndex;
    const j = range.endRow?.rowIndex;
    if (i !== undefined && j !== undefined) {
      // define start and end of loops
      const start = Math.min(i, j);
      const end = Math.max(i, j);

      // if the row is defined, add it to the return list
      for (let i = start; i <= end; i++) {
        const data = api.getDisplayedRowAtIndex(i)?.data;
        if (data) {
          rows.push(data);
        }
      }
    }
  });

  // then include selected rows if not already included
  const faults = rows.map((row) => row.OBJID);
  api.getSelectedRows().forEach((row) => {
    if (!faults.includes(row.OBJID)) {
      rows.push(row);
    }
  });

  return rows;
};

// prettier-ignore
export const applyHCFilter = (api?: GridApi<IngestedData>) => {
  // @ts-ignore
  const recordTypes = api?.getFilterInstance('RECORD_TYPE')?.valueModel?.allValues;

  api?.getFilterInstance('RECORD_TYPE')?.setModel({
    values: recordTypes.filter((type) => type !== 'HC'),
  });

  api?.onFilterChanged();
};
