import { CloseOutlined, Download } from '@mui/icons-material';
import {
  Button,
  Modal,
  List,
  Paper,
  ListSubheader,
  ThemeProvider,
  Dialog,
  Stack,
} from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';

import { captureHTMLElement } from 'common/util/utils';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

type exportModalProps = {
  open: boolean;
  filename?: string;
  backgroundColor: string;
  exportedElement: React.MutableRefObject<HTMLDivElement | null>;
  onClose: () => void;
  children: ReactNode;
};

export const ExportModal = (props: exportModalProps) => {
  return (
    <Dialog
      open={props.open}
      disableScrollLock={true}
      maxWidth={'xl'}
      fullWidth
    >
      <Paper
        sx={{
          p: 2,
          overflow: 'auto',
          backgroundColor: PowerToolDarkTheme.neutralDark200,
        }}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <Button
          sx={{
            minWidth: 'unset',
            padding: '4px',
            mr: 1,
            alignSelf: 'flex-end',
          }}
          onClick={props.onClose}
        >
          <CloseOutlined />
        </Button>

        <List sx={{ alignSelf: 'center' }} dense component='div' role='list'>
          <ListSubheader
            sx={{
              backgroundColor: PowerToolDarkTheme.neutralDark100,
              lineHeight: '36px',
            }}
          >
            {'Preview'}
          </ListSubheader>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              alignSelf: 'center',
            }}
          >
            <div
              style={{
                alignSelf: 'center',
              }}
            >
              <div
                style={{
                  backgroundColor: PowerToolDarkTheme.main,
                  display: 'flex',
                }}
              >
                {props.children}
              </div>
            </div>
          </div>
        </List>

        <Button
          variant='contained'
          color={'success'}
          sx={{
            mt: 1,
            alignSelf: 'flex-end',
          }}
          onClick={() =>
            captureHTMLElement({
              scale: 1,
              element: props.exportedElement.current,
              filename: props.filename ?? 'plot',
              backgroundColor: props.backgroundColor,
            })
          }
          endIcon={<Download />}
        >
          Download
        </Button>
      </Paper>
    </Dialog>
  );
};
