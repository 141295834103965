import { Paper, Tooltip } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';

import { useAppSelector, useUserFormattedTime } from 'common/hooks';
import { selectAssetDetails } from 'common/stores/globalSlice';
import { Asset } from 'common/types/types';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

import { useFetchAssetCardInfo } from 'power-tool/api/hooks';

import { AssetCardItem } from './AssetComponents';

export const AssetCard = () => {
  const asset = useAppSelector(selectAssetDetails);

  const { configItems, healthInfo, lastRun } = useFetchAssetCardInfo();

  const [assetInfo, setAssetInfo] = useState<Asset>();

  const [vehicleStatus, setVehicleStatus] = useState<
    'In Overhaul' | 'Shipped' | 'Not Shipped' | 'Unknown'
  >('Unknown');

  useEffect(() => {
    if (asset && configItems && healthInfo && lastRun) {
      setAssetInfo({
        ...asset,
        configItems,
        healthInfo: healthInfo.length > 0 ? healthInfo[0] : undefined,
        runs: lastRun.length > 0 ? lastRun[0] : undefined,
      });
    }
  }, [asset, configItems, healthInfo, lastRun]);

  const assetTitle = useMemo(
    () =>
      assetInfo?.customerOrgId && assetInfo?.roadNumber
        ? `${assetInfo.customerOrgId} ${assetInfo.roadNumber}`
        : 'N/A',
    [assetInfo?.customerOrgId, assetInfo?.roadNumber]
  );

  const model = useMemo(
    () =>
      assetInfo?.controller && assetInfo?.vehicleModel
        ? `${assetInfo?.vehicleModel}/${assetInfo?.controller}`
        : 'N/A',
    [assetInfo?.controller, assetInfo?.vehicleModel]
  );

  const lastToolRun = useMemo(
    () => assetInfo?.runs?.lastToolRun ?? 'N/A',
    [assetInfo?.runs?.lastToolRun]
  );

  useEffect(() => {
    // if the start date is defined but the complete date isn't
    // OR
    // both are defined and the start date is after the complete date
    // then the vehicle is in overhaul
    if (
      (assetInfo?.overhaulStartDate !== undefined &&
        assetInfo?.overhaulStartDate !== '' &&
        (assetInfo.overhaulCompleteDate === undefined ||
          assetInfo.overhaulCompleteDate === '')) ||
      (assetInfo?.overhaulStartDate !== undefined &&
        assetInfo?.overhaulStartDate !== '' &&
        assetInfo?.overhaulCompleteDate !== undefined &&
        assetInfo?.overhaulCompleteDate !== '' &&
        new Date(assetInfo.overhaulStartDate).getTime() >
          new Date(assetInfo.overhaulCompleteDate).getTime())
    ) {
      setVehicleStatus('In Overhaul');
    }

    // if the ship date is defined and before now, it's shipped
    else if (
      assetInfo?.shipDate !== undefined &&
      assetInfo?.shipDate !== '' &&
      new Date(assetInfo.shipDate).getTime() < new Date().getTime()
    ) {
      setVehicleStatus('Shipped');
    }

    // if overhaul and ship dates are all unknown
    else if (
      assetInfo?.overhaulStartDate === undefined &&
      assetInfo?.overhaulCompleteDate === undefined &&
      assetInfo?.shipDate === undefined
    ) {
      setVehicleStatus('Unknown');
    } else {
      setVehicleStatus('Not Shipped');
    }
  }, [assetInfo]);

  const configs = useMemo(() => {
    return assetInfo?.configItems?.map((config) => {
      if (config.item === 'Cooling Valve Type') {
        return (
          <AssetCardItem
            key={config.item}
            label={`${config.item.replaceAll('_', ' ')}:`}
            info={
              <Tooltip
                title={
                  <span style={{ whiteSpace: 'pre-line' }}>
                    {config?.desc ?? ''}
                  </span>
                }
              >
                <span>{config.version !== '' ? config.version : 'N/A'}</span>
              </Tooltip>
            }
          />
        );
      } else {
        return (
          <AssetCardItem
            key={config.item}
            label={`${config.item.replaceAll('_', ' ')}:`}
            info={config.version !== '' ? config.version : 'N/A'}
          />
        );
      }
    });
  }, [assetInfo?.configItems]);

  return (
    <Paper
      style={{
        top: 6,
        left: 6,
        position: 'relative',
        width: 'calc(100% - 12px)',
        display: 'flex',
        flexDirection: 'column',
        padding: 10,
        fontSize: 12,
        backgroundColor: PowerToolDarkTheme.main,
        rowGap: 4,
        overflow: 'auto',
        height: 'calc(100% - 12px)',
        lineHeight: '20px',
        whiteSpace: 'nowrap',
      }}
      elevation={6}
    >
      <AssetCardItem label='Asset:' info={assetTitle} />
      <AssetCardItem label={'Model/Controller:'} info={model} />

      {configs}
      <AssetCardItem label='Vehicle Status:' info={vehicleStatus} />
      <AssetCardItem
        label='Last Tool Run:'
        info={useUserFormattedTime(lastToolRun) ?? 'N/A'}
      />
    </Paper>
  );
};

export default AssetCard;
