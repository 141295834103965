import styled from 'styled-components';

// import { StyledTableWrapper } from "./Table.styled";

export const StyledToolOutput = styled.div`
  height: 100%;
  .recom-cell {
    /* display: flex; */
  }

  .recom-badge {
    margin-left: 15px;
    /* margin-right: -15px; */
    padding: 2px 10px;
    border-radius: 12px;
    line-height: 1.4;
  }

  .recom-badge.R {
    background-color: ${(props) => props.theme.statusRedFrosted};
  }

  .recom-badge.Y {
    background-color: ${(props) => props.theme.statusYellowFrosted};
  }

  .recom-badge.O {
    background-color: ${(props) => props.theme.statusOrangeFrosted};
  }

  .recom-badge.B {
    background-color: ${(props) => props.theme.dataTealFrosted};
  }

  .recom-badge.C {
    background-color: ${(props) => props.theme.dataPurpleFrosted};
  }

  .recom-badge.W {
    background-color: ${(props) => props.theme.statusGrayFrosted};
  }

  .recom-badge.G {
    background-color: ${(props) => props.theme.statusGreenFrosted};
  }
`;
