import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'common/store';

export type RuleTesterState = {
  isClearingLogic?: boolean;
  ruleInfo?: string;
};

const initialState: RuleTesterState = {};

export const ruleTesterSlice = createSlice({
  name: 'ruleTester',
  initialState,
  reducers: {
    /**
     *
     * @param {RuleTesterState} state current rule tester state
     * @param {PayloadAction<boolean | undefined>} action new rule tester isClearingLogic State
     */
    setIsClearingLogic: (state, action: PayloadAction<boolean | undefined>) => {
      state.isClearingLogic = action.payload;
    },
    /**
     *
     * @param {RuleTesterState} state current rule tester state
     * @param {PayloadAction<string | undefined>} action new rule tester ruleInfo State
     */
    setRuleInfo: (state, action: PayloadAction<string | undefined>) => {
      state.ruleInfo = action.payload;
    },
  },
});

export const { setIsClearingLogic, setRuleInfo } = ruleTesterSlice.actions;

/**
 *
 * @param {RuleTesterState} state current rule tester state
 * @returns {boolean?} isClearingLogic state
 */
export const selectIsClearingLogic = (state: RootState) =>
  state.ruleTester.isClearingLogic;
/**
 *
 * @param {RuleTesterState} state current rule tester state
 * @returns {string?} ruleInfo state
 */
export const selectRuleInfo = (state: RootState) => state.ruleTester.ruleInfo;
export default ruleTesterSlice.reducer;
