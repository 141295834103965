import { InfoOutlined } from '@mui/icons-material';
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
} from '@mui/material';
import { useMemo, useState } from 'react';

import { useFetchEvoModels } from 'common/api/hooks';
import { QuickFilter, QuickFilterChoice } from 'common/cards/Data/types';
import { useAppDispatch, useAppSelector } from 'common/hooks';
import {
  selectApplicationContext,
  selectAssetDetails,
} from 'common/stores/globalSlice';
import {
  selectQuickFilters,
  setQuickFilters,
} from 'common/stores/ingestedDataFooterSlice';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  disableScrollLock: true,
};

/**
 * @returns {JSX.Element} quick filter dropdown component
 */
export const QuickFilterComponent = () => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState<boolean>(false);

  // const [selectedFilter, setSelectedFilter] = useState<string[]>([]);

  const selectedFilters = useAppSelector(selectQuickFilters);
  const assetDetails = useAppSelector(selectAssetDetails);
  const application = useAppSelector(selectApplicationContext);

  const { evoModels } = useFetchEvoModels();

  const baseQuickFilters = useMemo((): QuickFilter[] => {
    return [
      {
        label: 'Engine Running',
        type: 'engine_running',
      },
      {
        label: 'Full Load',
        type: 'full_load',
        tooltip: 'Gross HP ≥ 4000',
      },
      {
        label: 'Notch 8',
        type: 'notch_8',
      },
      {
        label: 'Has Datapack',
        type: 'has_datapack',
      },
    ];
  }, []);

  const availableQuickFilters = useMemo((): QuickFilter[] | undefined => {
    if (assetDetails && evoModels) {
      if (evoModels.includes(assetDetails.vehicleModel ?? '')) {
        return [
          ...baseQuickFilters,
          {
            type: 'steady_state',
            label: 'Steady State',
            tooltip: 'abs(Engine Speed - Engine Speed Demand) < 5',
          },
        ];
      } else {
        return baseQuickFilters;
      }
    }
  }, [baseQuickFilters, evoModels, assetDetails]);

  /**
   *
   * @param {SelectChangeEvent<string[]>} event when the quick filter menu selections change, this event fires
   */
  const handleChange = (event: SelectChangeEvent<QuickFilterChoice[]>) => {
    if (availableQuickFilters) {
      const selection = event.target.value as QuickFilterChoice[];
      const filters: QuickFilter[] = [];
      selection.forEach((selectedFilter) => {
        const filter = availableQuickFilters.find(
          (filter) => filter.type === selectedFilter
        );
        if (filter) {
          filters.push(filter);
        }
      });
      console.log(filters);
      dispatch(setQuickFilters(filters));
    }
  };

  // No quick filters for TAT or rule_tester
  if (application === 'train_analysis' || application === 'rule_tester') {
    return <></>;
  }

  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      <FormControl size='small' sx={{ minWidth: 120 }}>
        <InputLabel
          sx={{ fontSize: '12px', marginBottom: '0px', marginTop: '-6px' }}
          id='quickfilter-multiple-checkbox-label'
        >
          Quick Filters
        </InputLabel>
        <Select
          sx={{ fontSize: '12px', marginLeft: '5px', height: '25px' }}
          labelId='quickfilter-multiple-checkbox-label'
          id='quickfilter-multiple-checkbox'
          multiple
          value={selectedFilters.map((filter) => filter.type)}
          onChange={handleChange}
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          renderValue={(selected) => {
            const joinedLabels = selected
              .map(
                (filter) =>
                  availableQuickFilters?.find((f) => f.type === filter)?.label
              )
              ?.join(', ');

            if (joinedLabels.length > 10) {
              return joinedLabels.substring(0, 10) + '...';
            }
            return joinedLabels;
          }}
          MenuProps={MenuProps}
        >
          {availableQuickFilters?.map((filter) => {
            return (
              <MenuItem key={filter.type} value={filter.type} sx={{ py: 0 }}>
                <Checkbox
                  size='small'
                  checked={
                    selectedFilters.find(
                      (selected) => selected.type === filter.type
                    ) !== undefined
                  }
                />
                <ListItemText
                  primary={filter.label}
                  primaryTypographyProps={{ fontSize: '12px' }}
                />
                {filter.tooltip ? (
                  <Tooltip title={filter.tooltip} placement='right'>
                    <InfoOutlined fontSize='small' />
                  </Tooltip>
                ) : (
                  <></>
                )}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};
