import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { useAppSelector } from 'common/hooks';
import { selectAssetDetails } from 'common/stores/globalSlice';

import { getRxPlots } from 'power-tool/cards/Plot/api';
import { selectRun } from 'power-tool/stores/runSlice';
import { selectToolOutput } from 'power-tool/stores/toolOutputSlice';

export const useFetchRxPlots = () => {
  const vehicleInfo = useAppSelector(selectAssetDetails);
  const toolOutputData = useAppSelector(selectToolOutput);
  const run = useAppSelector(selectRun);

  const rxIds = useMemo(() => {
    return toolOutputData
      .map((data) => data.rxId)
      .filter((id) => id.toString() !== '' && id * 1 > 0);
  }, [toolOutputData]);

  const enabled = useMemo(() => {
    return (
      vehicleInfo !== undefined &&
      toolOutputData.length > 0 &&
      (!run || run.objid <= 0) &&
      rxIds.length > 0 &&
      vehicleInfo.vehicleObjid !== 0
    );
  }, [vehicleInfo, toolOutputData, run, rxIds]);

  const hook = useQuery(
    ['rx_plots', { rxIds, vehicleInfo }],
    () => getRxPlots(vehicleInfo!.vehicleObjid!, rxIds!),
    { enabled }
  );

  return {
    rxPlots: hook.data,
    rxPlotStatus: hook.status,
    ...hook,
  };
};
