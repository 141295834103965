import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Stack, IconButton, Tooltip, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useMemo } from 'react';

import { useAppSelector } from 'common/hooks';
import { selectAssetDetails, selectUser } from 'common/stores/globalSlice';

import { LayoutWrapper } from 'loco-history/cards/Timeline/components/event-tooltip/EventTooltipContent';
import {
  BodyHeader,
  MainBody,
  BodyFooter,
} from 'loco-history/cards/Timeline/components/event-tooltip/EventTooltipContentComponents';
import { FaultEvent } from 'loco-history/types';

export type FaultEventProps = {
  event: FaultEvent;
  onContentClick: ((content: string) => void) | undefined;
};
/**
 *
 * @param {FaultEventProps} props event and onContentClick callback
 * @returns {JSX.Element} element
 */
export const FaultEventTooltipContent = (props: FaultEventProps) => {
  const assetDetails = useAppSelector(selectAssetDetails);
  const user = useAppSelector(selectUser);

  const faultDates = useMemo(() => {
    if (
      props.event &&
      props.event.type === 'fault' &&
      props.event['occurDate'] !== '' &&
      assetDetails
    ) {
      try {
        const occurDate = DateTime.fromISO(props.event.occurDate);
        const startDate = occurDate.minus({ days: 1 }).toUTC().toISO();
        const endDate = occurDate.plus({ days: 1 }).toUTC().toISO();
        return {
          startDate: startDate,
          endDate: endDate,
          vehicleObjid: assetDetails.vehicleObjid,
        };
      } catch {
        return undefined;
      }
    }
    return undefined;
  }, [assetDetails, props.event]);

  return (
    <LayoutWrapper
      header={
        <Stack direction='row' justifyContent={'space-between'}>
          <BodyHeader title={props.event.faultCode} />
          {faultDates !== undefined && (
            <Tooltip
              title={
                <Typography variant='body2'>
                  {'Open in Powertool Standalone'}
                </Typography>
              }
              placement='top'
            >
              <IconButton
                onClick={() => {
                  //opens a powertool window in a new tab for the given vehicle + the faultdate -1 day to +1 day
                  window.open(
                    `?application_context=datascreen&vehicleObjid=${faultDates.vehicleObjid}&from=${faultDates.startDate}&to=${faultDates.endDate}&role=${user?.currRole}&dayRange=range&isDecryptedUrl=true`,
                    '_blank',
                    'noopener'
                  );
                }}
              >
                <OpenInNewIcon />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
      }
      body={
        <MainBody
          onContentClick={props.onContentClick}
          left={[
            {
              label: 'fault code',
              value: props.event.faultCode,
            },
            {
              label: 'fault desc',
              value: props.event.faultDesc,
            },
          ]}
          right={[
            {
              label: 'sub id',
              value: props.event.subId,
            },
            {
              label: 'occur date',
              value: props.event.occurDate,
              options: { formatTime: true },
            },
            {
              label: 'reset date',
              value: '',
              options: { formatTime: true },
            },
          ]}
        />
      }
      footer={
        <BodyFooter
          items={[
            {
              label: 'objid',
              value: props.event.objid,
            },
          ]}
        />
      }
    />
  );
};
