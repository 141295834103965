import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, fork, all, select } from 'redux-saga/effects';

import { getAsset } from 'common/api';
import {
  setFiredFaults,
  setFiredFltAndParamForRuleLoaded,
} from 'common/cards/Data/store';
import {
  setCaseIdFromURL,
  selectParameterMap,
  selectRule,
  setAsset,
  setParameterMap,
} from 'common/stores/globalSlice';
import { Asset } from 'common/types/types';

import {
  getCases,
  getConfigOfInterest,
  getFaultsForComplexRule,
  getFaultsForSimpleRule,
  getFaultsFromCaseRule,
  getFaultsFromRunRule,
  getHealth,
  getParametersFromRule,
  getRunInfo,
  getRunsForVehicle,
} from 'power-tool/api';
import { selectCase } from 'power-tool/stores/casesSlice';
import { setRun, selectRun, setRuns } from 'power-tool/stores/runSlice';
import { setToolOutputData } from 'power-tool/stores/toolOutputSlice';
import { resetToolOutputForm } from 'power-tool/tool-dispo/saga/caseActionHandlers';
import { Run, ToolOutputData } from 'power-tool/types';

import { FiredFaultInput, RunActionInput } from './actionSlice';
import {
  getRunsForCase,
  getToolOutputForCase,
  getToolOutputForRun,
  getRunsForVehicle as getRunsForVehicleHelper,
  setCurrentCaseOwnerHelper,
} from './helpers';

export function* faultsForRuleHandler(action: PayloadAction<FiredFaultInput>) {
  try {
    const firedFaultInput = action.payload;
    if (firedFaultInput.ruleId > 0) {
      let methodCall: any;
      if (firedFaultInput.type === 'simrul') {
        methodCall = getFaultsForSimpleRule;
      } else if (firedFaultInput.type === 'cmprul') {
        methodCall = getFaultsForComplexRule;
      } else if (firedFaultInput.type === 'runrul') {
        methodCall = getFaultsFromRunRule;
      } else if (firedFaultInput.type === 'caserul') {
        methodCall = getFaultsFromCaseRule;
      }
      const firedFaults: string[] = yield call(
        methodCall,
        firedFaultInput.id,
        firedFaultInput.ruleId
      );
      yield put(setFiredFaults(firedFaults));
      yield put(setFiredFltAndParamForRuleLoaded(true));
    } else {
      yield put(setFiredFaults([]));
      yield put(setFiredFltAndParamForRuleLoaded(true));
    }
  } catch (err) {
    yield put(setFiredFaults([]));
    yield put(setFiredFltAndParamForRuleLoaded(true));
  }
}

export function* runActionHandler(action: PayloadAction<RunActionInput>) {
  const runActionInput: RunActionInput = action.payload;
  yield put(setRun(runActionInput.run));
  yield fork(getToolOutputForRun, runActionInput.run.objid);
  if (runActionInput.firedFaultInput) {
    yield fork(firedFaultsAndFiredParameter, runActionInput.firedFaultInput);
  }
}

export function* ruleSelectedActionHandler() {
  const ruleId = yield select(selectRule);
  const parameterMap = yield select(selectParameterMap);
  const selectedCase = yield select(selectCase);
  // const ingestedData = yield select(selectIngestedData); // do we need this check
  const runId = yield select(selectRun);
  // if (ruleId && parameterMap && selectedCase && ingestedData) {
  //   if (!(runId && runId > 0)) {
  //     const firedFaultInput: FiredFaultInput = {
  //       id: selectedCase.caseId,
  //       ruleId: ruleId,
  //       type: 'caserul',
  //     };
  //     yield call(firedFaultsAndFiredParameter, firedFaultInput);
  //   }
  // }
}

export function* firedFaultsAndFiredParameter(
  firedFaultInput: FiredFaultInput
) {
  const parameterMap = yield select(selectParameterMap);
  try {
    let getFiredFaults: any;
    if (firedFaultInput.ruleId > 0) {
      if (firedFaultInput.type === 'runrul') {
        getFiredFaults = getFaultsFromRunRule;
      } else if (firedFaultInput.type === 'caserul') {
        getFiredFaults = getFaultsFromCaseRule;
      }

      const [firedParameters, firedFaults] = yield all([
        call(getParametersFromRule, firedFaultInput.ruleId),
        call(getFiredFaults, firedFaultInput.id, firedFaultInput.ruleId),
      ]);

      // yield put(
      //   setFiredParameters(
      //     firedParameters.map((parameter) => {
      //       if (parameterMap[parameter]) {
      //         return parameterMap[parameter].parameterName;
      //       }
      //       return parameter;
      //     })
      //   )
      // );

      yield put(setFiredFaults(firedFaults));
      yield put(setFiredFltAndParamForRuleLoaded(true));
    } else {
      // yield put(setFiredParameters([]));
      yield put(setFiredFaults([]));
      yield put(setFiredFltAndParamForRuleLoaded(true));
    }
  } catch (err) {
    // yield put(setFiredParameters([]));
    yield put(setFiredFaults([]));
    yield put(setFiredFltAndParamForRuleLoaded(true));
  }
}

export function* initializeActionHandler(
  action: PayloadAction<{ caseId?: string; vehicleObjid?: string }>
) {
  try {
    const caseId = action.payload.caseId;
    const vehicleObjid = action.payload.vehicleObjid;

    const assetDetailResponse = yield call(getAsset, vehicleObjid, caseId);

    if (caseId) {
      yield call(setCurrentCaseOwnerHelper, caseId);
    }

    // TODO: This should be one query that populates one thing: Asset
    const [runInfo, healthInfo, configOfInterest] = yield all([
      call(getRunInfo, assetDetailResponse[0]['vehicleObjid']),
      call(getHealth, assetDetailResponse[0]['vehicleObjid']),
      call(getConfigOfInterest, assetDetailResponse[0]['vehicleObjid']),
    ]);

    if (caseId) {
      yield put(setCaseIdFromURL(caseId));
      yield fork(getRunsForCase, caseId);
      yield fork(getToolOutputForCase, caseId);
    }

    // if we get a successful response from the assetDetail API
    // dispatch it to redux
    if (assetDetailResponse.length > 0) {
      const assetDetail = {
        ...assetDetailResponse[0],
        healthInfo: healthInfo[0],
        configItems: configOfInterest,
        runs: runInfo[0],
      };
      yield put(setAsset(assetDetail));
      if (assetDetail.vehicleObjid > 0) {
        const [cases] = yield all([
          // call(getParameterMap, assetDetail.vehicleObjid),
          call(getCases, assetDetail.vehicleObjid),
        ]);

        yield all([
          // yield put(setParameterMap(parameterMap)),
          // yield put(setCases(cases)),
        ]);

        if (!caseId) {
          yield all([
            // put(setShouldFetchNewData(true)),
            fork(getRunsForVehicleHelper, parseInt(assetDetail.vehicleObjid)),
          ]);
        }
      }
    }
  } catch (e) {
    console.log('err seeting case type and asset info', e);
  }
}

export function* getAsssetRunHandler(action: PayloadAction<number>) {
  try {
    const runsFromVehicle: Run[] = yield call(
      getRunsForVehicle,
      action.payload
    );
    yield put(setRuns(runsFromVehicle));
  } catch (err) {
    yield put(setRuns([]));
  }
}

export function* setToolOutputHandler(action: PayloadAction<ToolOutputData[]>) {
  yield put(setToolOutputData(action.payload));

  // we reset the form whenever tooloutput is set
  yield resetToolOutputForm(action.payload);
}

export function* changeAssetHandler(action: PayloadAction<Asset | undefined>) {
  // if we de-select an asset,
  // we want to make sure the data is cleared
  if (action.payload === undefined) {
    // yield all([put(setAsset(undefined)), put(setIngestedData([]))]);
  }

  // otherwise, we go get a new parameter map
  // and change asset (triggering a data fetch)
  else if (action.payload.vehicleObjid) {
    // const parameterMap = yield call(
    //   // getParameterMap,
    //   action.payload.vehicleObjid
    // );

    yield all([
      // put(setParameterMap(parameterMap)),
      put(setAsset(action.payload)),
      // put(setShouldFetchNewData(true)),
    ]);
  }

  // if the asset objid is undefined
  // we still set the asset but don't call parameter map
  // and clear ingested data
  else {
    yield all([
      put(setAsset(action.payload)),
      put(setParameterMap({})),
      // put(setIngestedData([])),
    ]);
  }
}
