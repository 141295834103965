import { CSSProperties, useMemo } from 'react';

export const trackHeight = '20px';

export const useTimelineStyles = () => {
  const trackStyle = useMemo((): CSSProperties => {
    return {
      height: trackHeight,
      left: 0,
      zIndex: 1,
    };
  }, []);

  const thumbStyle = useMemo((): CSSProperties => {
    return {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '25px',
      height: '25px',
    };
  }, []);

  return {
    trackStyle,
    thumbStyle,
  };
};
