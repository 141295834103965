import {
  ColDef,
  ColGroupDef,
  ColumnApi,
  GetContextMenuItemsParams,
} from 'ag-grid-community';
import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { getOutboundResults, getWeatherForFaults } from 'common/cards/Data/api';
import useGridCallbacks from 'common/cards/Data/callbacks';
import { DataGridHeader } from 'common/cards/Data/components/header/DataGridHeader';
import { DataGridHeaderGroup } from 'common/cards/Data/components/header/DataGridHeaderGroup';
import { useGridProps } from 'common/cards/Data/props';
import { addOutboundResults } from 'common/cards/Data/store';
import {
  OutboundMacroResults,
  WeatherForFaultPayload,
} from 'common/cards/Data/types';
import {
  calculateHeaderWidth,
  getCellRenderer,
  getSelectedFaultsOnGrid,
} from 'common/cards/Data/utils';
import { MapMarkerData } from 'common/cards/Map/types';
import {
  selectIngestedDataColumns,
  selectIngestedDataStatus,
} from 'common/features/ingested-data/store';
import {
  IngestedData,
  IngestedDataColumn,
} from 'common/features/ingested-data/types';
import { useAppDispatch, useAppSelector } from 'common/hooks';
import { store } from 'common/store';
import {
  selectApplicationContext,
  selectAssetDetails,
} from 'common/stores/globalSlice';
import { addMarkerData } from 'common/stores/mapSlice';

import { useFetchFiredParameters } from 'power-tool/api/hooks';

import {
  selectVirtualJson,
  selectVirtualData,
} from 'virtual-tester/stores/virtualSlice';

export const useAreChartsEnabled = () => {
  const application = useAppSelector(selectApplicationContext);

  return useMemo(() => {
    if (application) {
      switch (application) {
        case 'power_tool':
        case 'virtual_tester':
          return true;

        case 'train_analysis':
          return false;

        default:
          return true;
      }
    }

    return false;
  }, [application]);
};

export const useGetIngestedDataGridColumns = (
  colApi?: ColumnApi
): (ColDef<IngestedData> | ColGroupDef<IngestedData>)[] | undefined => {
  const asset = useAppSelector(selectAssetDetails);
  const columns = useAppSelector(selectIngestedDataColumns);
  const application = useAppSelector(selectApplicationContext);
  const virtual = useAppSelector(selectVirtualData);

  const {
    cellStyles,
    cellRendererSelector,
    getValueGetter,
    getValueFormatter,
  } = useGridCallbacks();
  const { isVirtualParameter } = useIsVirtualParameter();
  const { headerClass, headerGroupClass } = useDetermineColumnClass();
  const { cellClassRules } = useGridProps();

  // generate grouped columns from flat list
  return useMemo(() => {
    // don't show columns if we don't
    //  have an asset selected
    if (asset === undefined) {
      return;
    }

    // first get list of unique group labels
    const groupLabels: string[] = Array.from(
      new Set(columns?.map((col) => col.group ?? 'unk'))
    );

    // then this 'wonderful' little block of code takes the unique array
    // and maps each value in the array to an object where
    // the key is the group and the value is a base ColGroupDef with
    // and empty array for its children
    const groups: Record<
      string,
      ColGroupDef<IngestedData>
    > = groupLabels.reduce(
      (a, group: string | undefined): Record<string, ColGroupDef> => {
        // bit of special logic to map the virtual tester params to the right class
        return {
          ...a,
          [group ?? 'unk']: {
            headerClass: headerGroupClass(group, groupLabels),
            headerGroupComponent: DataGridHeaderGroup,
            headerName: group,
            children: [],
          },
        };
      },
      {}
    );

    // special group for virtual tester results
    if (application === 'virtual_tester') {
      groups['virtual results'] = {
        headerGroupComponent: DataGridHeaderGroup,
        headerClass: 'virtualTesterParam',
        headerName: 'virtual results',

        // and as the initial state we add the
        // virtual tester result column to the group
        children: virtual
          ? [
              {
                headerComponent: DataGridHeader,
                cellStyle: cellStyles,

                field: 'VIRTUAL_RESULT',
                headerName: virtual.virtualName,
                pinned: true,
                filter: 'agNumberColumnFilter',
                width: calculateHeaderWidth({
                  columnName: virtual.virtualName,
                  extraPixels: 15,
                }),
                cellClassRules: cellClassRules,
                headerClass: 'virtualTesterResult',
              },
            ]
          : [],
      };
    }

    // then for each column in the API response
    let groupCounter = 0;
    let currentGroup: string | undefined = undefined;
    columns?.forEach((column) => {
      // we track the index of each column
      // within their respective groups
      if (currentGroup !== column.group) {
        groupCounter = 0;
        currentGroup = column.group;
      }
      // if column is already generated, get its def
      const existingDef = colApi?.getColumn(column.name);
      // common col def between all columns
      const baseColDef: ColDef | ColGroupDef = {
        headerComponent: DataGridHeader,
        cellRenderer: getCellRenderer(column.name),
        cellStyle: cellStyles,
        cellRendererSelector,
        valueGetter: getValueGetter(column.name),
        valueFormatter: getValueFormatter(column.name),

        field: column.name,
        filter: column.filterType,
        pinned: existingDef?.getPinned() ?? column.group === 'fixed',
        chartDataType: column.defaultChartType,
        headerName: column.headerName,
        cellClassRules: cellClassRules,
        minWidth: 0,
        columnGroupShow:
          (column.group === 'virtual' || column.group === 'FOCUSED_PARAMS') &&
          groupCounter > 2
            ? 'open'
            : undefined,
        width:
          column.width ??
          calculateHeaderWidth({ columnName: column.headerName }),
        headerComponentParams: {
          tooltip: column.toolTip,
        },
        headerClass: headerClass(column, groupLabels, groups),
        filterParams:
          column.filterType === 'agDateColumnFilter'
            ? {
                browserDatePicker: true,
                comparator: (
                  currentFilterValue: string,
                  currentCellValue: string
                ) => {
                  const filterValue = new Date(currentFilterValue).getTime();
                  const cellValue = new Date(currentCellValue).getTime();
                  if (filterValue === cellValue) {
                    return 0;
                  }

                  if (cellValue < filterValue) {
                    return -1;
                  }

                  if (cellValue > filterValue) {
                    return 1;
                  }
                },
              }
            : undefined,
      };

      // we sort by occur date
      if (column.name === 'OCCUR_DATE') {
        groups[column.group ?? 'unk'].children.push({
          ...baseColDef,
          sort: 'desc',
        });
      }

      // otherwise just base column and visible
      // note we exclude non-visible columns from the
      // col def list entirely. we don't want users to
      // be able to see these columns at all
      else if (column.isVisible) {
        // if virtual parameter, put it in its own special group
        // unshift just pushes to start of list
        // we want the virtual result column (appended at start)
        // to always be at the end
        if (
          isVirtualParameter(column.name) &&
          column.group !== 'FOCUSED_PARAMS'
        ) {
          groups['virtual results'].children.unshift({
            ...baseColDef,
            pinned: true,
          });
        }

        // otherwise use whatever the backend is telling us
        else {
          groups[column.group ?? 'unk'].children.push(baseColDef);
        }
      }

      // we track the position in each group
      groupCounter++;
    });

    // add checkbox selection column
    if (groups['fixed']) {
      groups['fixed'].children = [
        {
          checkboxSelection: true,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          pinned: true,
          minWidth: 0,
          suppressMenu: true,
          suppressMovable: true,
          suppressNavigable: true,
          width: 23,
          headerClass: 'checkbox-header',
        },
        ...groups['fixed'].children,
      ];
    }

    // and at the end the all the values of the object represent our col defs
    // we also get away with correct ordering since we iterate in order
    // over the columns object
    return Object.values(groups);
  }, [columns, headerClass]);
};

export const useGetGPSColumns = () => {
  const status = useAppSelector(selectIngestedDataStatus);
  const columns = useAppSelector(selectIngestedDataColumns);
  const gpsColumns = useMemo(() => {
    const [latitude, longitude] = [
      columns?.find(
        (column) => column.subGroup?.toUpperCase() === 'GPS LATITUDE'
      )?.name,
      columns?.find(
        (column) => column.subGroup?.toUpperCase() === 'GPS LONGITUDE'
      )?.name,
    ];

    // we don't want to have stale columns
    // in the state. this can lead to issues
    // when switching assets that are different models
    if (status !== 'complete') {
      return undefined;
    } else if (latitude && longitude) {
      return { latitude, longitude };
    }

    return undefined;
  }, [columns, status]);

  return { gpsColumns };
};

export const useIsValidGPSLocation = () => {
  const { gpsColumns } = useGetGPSColumns();

  // ag-grid's react support is pretty suspect so even in
  // proper hooks we need to store a ref so that if ag-grid
  // uses this hook it has the correct state value
  const gpsColumnsRef = useRef(gpsColumns);
  useEffect(() => {
    gpsColumnsRef.current = gpsColumns;
  }, [gpsColumns]);

  const isValidLocation = useCallback(
    (row: IngestedData): boolean => {
      const gps = gpsColumnsRef.current ?? gpsColumns;
      // if coordinates are defined
      if (gps === undefined) {
        return false;
      }

      const [latitude, longitude] = [row[gps.latitude], row[gps.longitude]];

      // if the values for the coordinates are defined
      if (latitude === undefined || longitude === undefined) {
        return false;
      }

      // and they are both numbers
      if (isNaN(latitude) || isNaN(longitude)) {
        return false;
      }

      // and both not zero (technically they are strings coming from backend, so we have to multiply by 1)
      else if (latitude * 1 === 0 && longitude * 1 === 0) {
        return false;
      }

      return true;
    },
    [gpsColumns]
  );

  return { isValidLocation };
};

export const useIsValidOutbound = () => {
  const isValidOutbound = useCallback((row: IngestedData): boolean => {
    if (row['MP_0102_N_0_0'] !== undefined && row['MP_0102_N_0_0'] === 8) {
      return true;
    } else {
      return false;
    }
  }, []);

  return { isValidOutbound };
};

export const useDropPinsAndFetchWeather = () => {
  const dispatch = useAppDispatch();

  const columns = useAppSelector(selectIngestedDataColumns);
  const columnsRef = useRef(columns);
  useEffect(() => {
    columnsRef.current = columns;
  }, [columns]);

  const { gpsColumns } = useGetGPSColumns();
  const gpsColumnsRef = useRef(gpsColumns);
  useEffect(() => {
    gpsColumnsRef.current = gpsColumns;
  }, [gpsColumns]);

  const { enqueueSnackbar } = useSnackbar();
  const { isValidLocation } = useIsValidGPSLocation();

  const dropPinsAndFetchWeather = useCallback(
    (params: GetContextMenuItemsParams<IngestedData>) => {
      if (gpsColumnsRef.current !== undefined) {
        let payloads: WeatherForFaultPayload[] = [];

        // get unique selected faults with valid locations
        // and add a request payload to a list
        getSelectedFaultsOnGrid(params.api)
          .filter((fault) => isValidLocation(fault))
          .forEach((fault) => {
            payloads.push({
              latitude: fault[gpsColumnsRef.current!.latitude],
              longitude: fault[gpsColumnsRef.current!.longitude],
              faultObjId: fault.OBJID,
              date: DateTime.fromISO(fault.OCCUR_DATE, {
                setZone: true,
              }).toFormat('MM/dd/yyyy HH:mm:ss'),
            });
          });

        const updatedRecords: IngestedData[] = [];
        const mapMarkers: MapMarkerData[] = [];

        // then for each payload get the weather
        // and merge it with the existing data
        getWeatherForFaults(payloads)
          .then((results) => {
            results.forEach((result) => {
              const data = params.api.getRowNode(
                result.faultObjid.toString()
              )?.data;
              if (data) {
                const tempF = Math.round(result.tempF);
                const windSpeedMPH = Math.round(result.windSpeedMPH);
                const precipIN = Math.round(result.precipIN);
                const elevFT = Math.round(result.elevFT);
                const windGustMPH = Math.round(result.windGustMPH);

                updatedRecords.push({
                  ...data,
                  TEMPF: tempF,
                  PRECIPIN: precipIN,
                  WINDSPEEDMPH: windSpeedMPH,
                  WINDGUSTMPH: windGustMPH,
                  ICON: result.icon,
                  WEATHER: result.weather,
                  WINDDIR: result.windDir,
                  windDirDeg: result.windDirDeg,
                  SNOWIN: result.snowIn,
                });

                mapMarkers.push({
                  long: data[gpsColumnsRef.current!.longitude],
                  lat: data[gpsColumnsRef.current!.latitude],
                  date: data.OCCUR_DATE,
                  id: result.faultObjid,
                  weather: result.weather,
                  name: result.name,
                  state: result.state,
                  tempF: tempF,
                  windSpeedMPH: windSpeedMPH,
                  precipIN: precipIN,
                  elevFT: elevFT,
                  type: 'manual',
                });
              }
            });

            if (columnsRef.current) {
              // show weather columns if they arent visible
              params.columnApi.setColumnsVisible(
                columnsRef.current
                  .filter((column) => column.subGroup === 'weather')
                  .map((column) => column.name),
                true
              );
            }

            // set the data, dispatch map markers
            params.api.applyTransaction({ update: updatedRecords });
            dispatch(addMarkerData(mapMarkers));
          })
          .catch((_error) => {
            enqueueSnackbar(
              'Whoops! Looks like we ran into an error fetching weather information',
              { variant: 'error', persist: false }
            );
          });
      }
    },
    [columns, gpsColumns, isValidLocation, enqueueSnackbar, dispatch]
  );

  return { dropPinsAndFetchWeather };
};

export const useProcessOutbound = () => {
  const dispatch = useAppDispatch();
  const asset = useAppSelector(selectAssetDetails);

  const { isValidOutbound } = useIsValidOutbound();

  const processOutbound = useCallback(
    (params: GetContextMenuItemsParams<IngestedData>) => {
      const asset = store.getState().global.assetDetails;

      const validFaults = getSelectedFaultsOnGrid(params.api).filter((fault) =>
        isValidOutbound(fault)
      );

      if (asset?.vehicleObjid) {
        getOutboundResults(asset?.vehicleObjid, validFaults).then(
          (response) => {
            const results: Record<string, OutboundMacroResults> = {};
            response.forEach((result) => {
              results[result.objid] = result.data;
            });
            dispatch(addOutboundResults(results));
            params.api.refreshCells({ force: true });
          }
        );
      }
    },
    [asset]
  );

  return { processOutbound };
};

export const useIsVirtualParameter = () => {
  const application = useAppSelector(selectApplicationContext);
  const virtualJSON = useAppSelector(selectVirtualJson);

  const isVirtualParameter = (columnName: string): boolean => {
    //test app context first so function is O(1) outside of virtual tester.
    if (application !== 'virtual_tester' || columnName === undefined) {
      return false;
    }
    //VIRTUAL_RESULT is a part of the ingested data result, so we check to see if it's that first
    if (columnName === 'VIRTUAL_RESULT') {
      return true;
    }
    //then check to see if the column is a parameter passed from OMD.
    return (
      virtualJSON.find(
        (row) => row.paramDBName === columnName || row.name === columnName
      ) !== undefined
    );
  };

  return { isVirtualParameter };
};

export const useDetermineColumnClass = () => {
  const { firedParameters } = useFetchFiredParameters();
  const { isVirtualParameter } = useIsVirtualParameter();

  // order matters!
  const headerClass = useCallback(
    (
      column: IngestedDataColumn,
      labels: string[],
      groups: Record<string, ColGroupDef<IngestedData>>
    ) => {
      if (firedParameters?.includes(column.headerName)) {
        return 'fired';
      }

      if (
        isVirtualParameter(column.name) ||
        column.group === 'FOCUSED_PARAMS'
      ) {
        return column.name === 'VIRTUAL_RESULT'
          ? 'virtualTesterResult'
          : 'virtualTesterParam';
      }

      labels = labels.filter((label) => label !== 'fixed');

      return `parameterGroup${labels.indexOf(
        groups[column.group ?? 'unk'].headerName ?? 'unk'
      )}`;
    },
    [firedParameters]
  );

  // order matters!
  const headerGroupClass = useCallback(
    (group: string | undefined, labels: string[]) => {
      if (group === 'FOCUSED_PARAMS') {
        return 'virtualTesterParam';
      }

      labels = labels.filter((label) => label !== 'fixed');

      return `parameterGroup${labels.indexOf(group ?? 'unk')}`;
    },
    []
  );

  return { headerClass, headerGroupClass };
};
