import {
  ChevronLeft,
  ChevronRight,
  Add as AddIcon,
  Download,
  Link,
} from '@mui/icons-material';
import { Button, ButtonGroup, IconButton, Switch } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { selectGridApi, selectColumnApi } from 'common/cards/Data/store';
import { CustomColDef } from 'common/components/ag-grid/types';
import { useAppSelector, useAppDispatch } from 'common/hooks';
import { selectAssetDetails } from 'common/stores/globalSlice';
import { selectScatterPlotView } from 'common/stores/userSettingSlice';

import {
  changePlot,
  getChartModels,
  getSelectedPlot,
} from 'power-tool/stores/plotSlice';

import { createChart } from './PlotList';

type PlotToolbarProps = {
  focusedView: boolean;
  selectedPlotTitle: string;
  userPlots: string[];
  selectedUserPlot: number;
  setSelectedUserPlot: React.Dispatch<React.SetStateAction<number>>;
  setFocusedView: React.Dispatch<React.SetStateAction<boolean>>;
};

export const PlotToolbarComponent = (props: PlotToolbarProps) => {
  const api = useAppSelector(selectGridApi);
  const columnApi = useAppSelector(selectColumnApi);
  const selectedChart = useAppSelector(getSelectedPlot);
  const models = useAppSelector(getChartModels);

  // whenever we change focused views, handle the event here
  useEffect(() => {
    // for each column in our selected range, get the column ID
    const plottedColumns: string[] = [];
    api
      ?.getCellRanges()
      ?.forEach((range) =>
        range.columns.forEach((col) => plottedColumns.push(col.getColId()))
      );

    // hide the columns that are not plotted
    if (props.focusedView) {
      const columnsToHide =
        columnApi
          ?.getColumns()
          ?.filter(
            (col) =>
              (col.getUserProvidedColDef() as CustomColDef).parameterGroup !==
                'fixed' && !plottedColumns.includes(col.getColId())
          ) ?? [];

      columnApi?.setColumnsVisible(columnsToHide, false);
    }

    // restore full view
    else {
      columnApi?.setColumnsVisible(columnApi?.getColumns() ?? [], true);
    }

    // we get a warning that we need to include api and columnApi
    // but we only want this to run when focusedView is changed
    // we don't want the side effects of this hook to run with the grid and/or column api are set
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.focusedView]);

  // otherwise, render plot options and navigation
  return (
    // main horizonal row for the toolbar
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        flexDirection: 'row',
      }}
    >
      {/* Focused view toggle -- collapse all un-plotted columns */}
      {props.selectedPlotTitle !== 'anomaly plot' && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div>
            <Switch
              size='small'
              checked={props.focusedView}
              onChange={(event) => props.setFocusedView(event.target.checked)}
            />
            <span style={{ fontSize: '12px', marginRight: '10px' }}>
              Focused View
            </span>
          </div>
        </div>
      )}

      {/* Plot navigation */}
      <PlotNavigationComponent
        userPlots={props.userPlots}
        selectedPlotTitle={props.selectedPlotTitle}
        selectedUserPlot={props.selectedUserPlot}
        setSelectedUserPlot={props.setSelectedUserPlot}
      />

      {/* Plot actions ... save, attach, delete, etc... */}
      <ButtonGroup size='small' variant='outlined'>
        <AddPlotComponent selectedPlotTitle={props.selectedPlotTitle} />
        {/* <Button
          onClick={() =>
            api?.downloadChart({
              chartId: models[selectedChart],
              dimensions: { height: 500, width: 1392 },
            })
          }
        >
          <Download fontSize='small' />
        </Button> */}
      </ButtonGroup>
    </div>
  );
};

type PlotNavProps = {
  userPlots: string[];
  selectedUserPlot: number;
  selectedPlotTitle: string;

  setSelectedUserPlot: React.Dispatch<React.SetStateAction<number>>;
};

const PlotNavigationComponent = (props: PlotNavProps) => {
  const dispatch = useAppDispatch();
  const chartModels = useAppSelector(getChartModels);

  const [userPlots, setUserPlots] = useState(props.userPlots);
  // const [selectedUserPlot, setSelectedUserPlot] = useState<number>(0);

  useEffect(() => {
    setUserPlots(props.userPlots);
  }, [props.userPlots]);

  // when the user changes the user plot selection,
  // find the plot in our list of chart models
  // and change to that plot
  useEffect(() => {
    const userPlotIndex = chartModels.indexOf(
      userPlots[props.selectedUserPlot]
    );
    if (userPlotIndex >= 0) {
      dispatch(changePlot(userPlotIndex));
    }

    // we get a warning that we need to include userPlots and chartModels
    // but the selectedUserPlot changing will imply that the userPlot and/or chartModels
    // object will also change, and we do not want this hook to run multiple times
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedUserPlot, dispatch]);

  const nextPlot = () => {
    if (props.selectedUserPlot < userPlots.length - 1) {
      props.setSelectedUserPlot(props.selectedUserPlot + 1);
    }
  };

  const prevPlot = () => {
    if (props.selectedUserPlot > 0) {
      props.setSelectedUserPlot(props.selectedUserPlot - 1);
    }
  };

  if (props.selectedPlotTitle === 'user plot') {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <IconButton size='small' sx={{ mr: 1 }} onClick={prevPlot}>
          <ChevronLeft />{' '}
        </IconButton>
        <div style={{ justifyContent: 'center', fontSize: '12px' }}>{`${
          props.selectedUserPlot + 1
        } of ${userPlots.length} plots`}</div>
        <IconButton size='small' sx={{ ml: 1 }} onClick={nextPlot}>
          <ChevronRight />{' '}
        </IconButton>
      </div>
    );
  } else {
    return <div />;
  }
};

type AddPlotProps = {
  selectedPlotTitle: string;
};
const AddPlotComponent = (props: AddPlotProps) => {
  const api = useAppSelector(selectGridApi);
  const scatterPlotView = useAppSelector(selectScatterPlotView);
  const vehicleInfo = useAppSelector(selectAssetDetails);
  if (props.selectedPlotTitle === 'user plot' && vehicleInfo) {
    return (
      // create an empty plot when users click the '+' button
      <Button
        onClick={(event) =>
          createChart('Custom Plot', [], api, scatterPlotView, vehicleInfo)
        }
      >
        <AddIcon fontSize='small' />
      </Button>
    );
  }

  return <div />;
};
