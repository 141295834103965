import { Button, ButtonGroup } from '@mui/material';
import chroma from 'chroma-js';
import { useMemo } from 'react';

import { selectIngestedDataColumns } from 'common/features/ingested-data/store';
import { useAppDispatch, useAppSelector } from 'common/hooks';
import { selectApplicationContext } from 'common/stores/globalSlice';
import { setSelectedQuickNavigation } from 'common/stores/ingestedDataFooterSlice';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

export const QuickNavComponent = () => {
  const dispatch = useAppDispatch();

  const application = useAppSelector(selectApplicationContext);
  const columns = useAppSelector(selectIngestedDataColumns);

  const groups = useMemo(() => {
    return Array.from(
      new Set(
        columns
          ?.map((columns) => columns.group)
          .filter((group) => group !== undefined)
      )
    );
  }, [columns]);

  // no quick nav buttons for TAT
  if (application === 'train_analysis' || application === 'rule_tester') {
    return <></>;
  }

  return (
    <ButtonGroup size='small'>
      {groups
        .filter((group) => group && group !== 'fixed')
        .map((group, i) => {
          const color =
            PowerToolDarkTheme[`parameterGroup${i + 1}`] ??
            PowerToolDarkTheme.main;
          const accent = chroma(color).brighten(0.5).hex();
          return (
            <Button
              key={group}
              size='small'
              onClick={() => dispatch(setSelectedQuickNavigation(group ?? ''))}
              sx={{
                color: 'white',
                backgroundColor: color,
                borderColor: accent,
                '&:hover': {
                  backgroundColor: accent,
                  borderColor: accent,
                },
                py: '0px',
                px: '7px',
                fontSize: '11px',
              }}
            >
              {group}
            </Button>
          );
        })}
    </ButtonGroup>
  );
};
