export const dayTimeSpan = (start: Date, end: Date): number => {
  return Math.floor((end.getTime() - start.getTime()) / (1000 * 3600 * 24));
};

export const hourTimeSpan = (start: Date, end: Date): number => {
  return Math.abs(end.getTime() - start.getTime()) / 3600000;
};

export const daysToMinutes = (days: number) => {
  return days * 24 * 60;
};
