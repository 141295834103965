import { ICellRendererParams } from 'ag-grid-community';

import { LazyTooltip } from 'common/components/general/LazyTooltip';

export const LocationCell = (params: ICellRendererParams) => {
  const data = params.data;
  const loc = data && data.PROXIMITY_LABEL ? data.PROXIMITY_LABEL : '';
  const desc = data && data.PROXIMITY_DESC ? data.PROXIMITY_DESC : '';
  return (
    <LazyTooltip placement='right' title={desc !== undefined ? desc : ''}>
      <div>{loc}</div>
    </LazyTooltip>
  );
};
