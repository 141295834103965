import { AccordionDetails, Grow } from '@mui/material';
import { useState, useEffect } from 'react';

import { useAppSelector } from 'common/hooks';

import { getCaseActionState } from 'power-tool/stores/caseActionSlice';
import {
  AdditionalActionProps,
  ListItemComponentProps,
  ToolOutputItemState,
} from 'power-tool/tool-dispo/types';

import { ListComponent } from './ListComponent';

export const MergeComponent = (props: AdditionalActionProps) => {
  const toolOutput = useAppSelector(getCaseActionState).items;

  const [item, setItem] = useState<ToolOutputItemState>(
    props.currentToolOutput
  );
  const [items, setItems] = useState<ListItemComponentProps[]>([]);

  useEffect(() => {
    setItem(props.currentToolOutput);
  }, [props.currentToolOutput]);

  useEffect(() => {
    // valid merge targets are other tool output that are not marked for merge and that are not itself
    const validMergeTargets =
      toolOutput
        ?.filter(
          (otherItem) =>
            otherItem.selectedAction &&
            otherItem.selectedAction !== 'merge' &&
            otherItem.toolId !== 'Fault'
        )
        .map((filteredItem) => ({
          description: `${filteredItem.toolId} - ${filteredItem.ruleId} - ${filteredItem.rxTitle}`,
          value: filteredItem.rxId.toString(),
          selected: item.mergeTarget === filteredItem.rxId.toString(),
          id: filteredItem.id,
        })) ?? [];

    // if we dont have any valid merge targets, we still run the risk
    // of having a stale state. so we need to clear out any existing selections in the state
    if (validMergeTargets.length <= 0) {
      toolOutput
        ?.filter(
          (item) => item.mergeTarget !== '' && item.mergeTarget !== undefined
        )
        .forEach((item) => {
          props.onUpdate({
            ...item,
            mergeTarget: undefined,
          });
        });
    }

    if (validMergeTargets.length > 0) {
      if (
        item.selectedAction !== 'merge' &&
        item.mergeTarget !== '' &&
        item.mergeTarget !== undefined
      ) {
        props.onUpdate({
          ...item,
          mergeTarget: undefined,
        });
      }
    }

    setItems(validMergeTargets);
  }, [item.mergeTarget, toolOutput]);

  const onSelect = (selectedItem: ListItemComponentProps) => {
    props.onUpdate({
      ...item,
      mergeTarget: selectedItem.value,
    });
  };

  if (props.action === 'merge') {
    return (
      <Grow in={props.action === 'merge'}>
        <AccordionDetails sx={{ ml: 2 }}>
          <ListComponent
            handleSelect={onSelect}
            label='Merge with...'
            items={
              items.length > 0
                ? items
                : [
                    {
                      description:
                        'Merge is only available for other output marked for delivery, append, or close',
                      value: 'disabled',
                    },
                  ]
            }
          />
        </AccordionDetails>
      </Grow>
    );
  }

  return <></>;
};
