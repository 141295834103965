import ReactSlider from 'react-slider';
import styled from 'styled-components';

import { trackHeight } from './hooks/style';

// transform: isDragged || isFocused ? 'scale(1.4)' : undefined,
// transition: 'all .1s ease-in-out',

export const FullWidthTimeline = styled(ReactSlider)`
  /* max-width: 100% */
  width: 100%;
  height: ${trackHeight};
  display: flex;
  align-items: center;
  overflow: hidden;
  transition: all;
  @keyframes fadeIn {
    0% {
      scale: 0.9;
      transform: translateX(-5px);
      transform: translateY(-15px);
      opacity: 0;
    }
    100% {
      opacity: 1;
      scale: 1;
    }
  }

  animation: fadeIn ease-in 0.2s;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
`;
