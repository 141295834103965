import { PlaylistAdd } from '@mui/icons-material';
import { SxProps, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useRef, useCallback, useMemo } from 'react';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

/**
 *
 * @param {any} props implicit destructured props
 * @param {string | number | JSX.Element | undefined} props.content content to send to story
 * @param {(content: string) => void | undefined} props.onContentClick callback on when content is clicked
 * @param {SxProps | undefined} props.textProps text display properties
 * @returns {JSX.Element} component that sends clicked text to the locomotive story component
 */
export const SendTextToStory = ({
  content,
  onContentClick,
  textProps,
}: {
  content?: string | number | JSX.Element;
  onContentClick?: (content: string) => void;
  textProps?: SxProps;
}) => {
  const contentRef = useRef<HTMLSpanElement | null>(null);

  const onClick = useCallback(() => {
    if (onContentClick && contentRef.current?.textContent) {
      onContentClick(contentRef.current.textContent);
    }
  }, [onContentClick]);

  const icon = useMemo(() => {
    if (onContentClick && content) {
      return (
        <PlaylistAdd
          onClick={onClick}
          fontSize='small'
          sx={{ color: PowerToolDarkTheme.statusGreen300, cursor: 'pointer' }}
        />
      );
    } else {
      return <></>;
    }
  }, [content, onContentClick, onClick]);

  return (
    <Stack
      direction={'row'}
      spacing={1}
      alignItems='center'
      justifyContent={'space-between'}
    >
      <Typography ref={contentRef} variant='body1' sx={textProps}>
        {content}
      </Typography>
      {icon}
    </Stack>
  );
};
