import { CheckCircle } from '@mui/icons-material';
import {
  Alert,
  CircularProgress,
  Slide,
  Stack,
  Typography,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';

import { selectIngestedDataStatus } from 'common/features/ingested-data/store';
import { useAppSelector } from 'common/hooks';

export const DataFetchStatus = () => {
  const [visible, setVisible] = useState<boolean>(true);

  const status = useAppSelector(selectIngestedDataStatus);

  useEffect(() => {
    switch (status) {
      case 'complete':
        setTimeout(() => setVisible(false), 3000);
        break;
      default:
        setVisible(true);
        break;
    }
  }, [status]);

  const indicator = useMemo(() => {
    switch (status) {
      case 'complete':
        return (
          <Stack
            direction={'row'}
            justifyContent={'center'}
            alignItems={'center'}
            spacing={1}
          >
            <Typography variant='body2'>Complete</Typography>
            <CheckCircle color='success' />
          </Stack>
        );

      case 'fetching':
        return (
          <Stack
            direction={'row'}
            justifyContent={'center'}
            alignItems={'center'}
            spacing={1}
          >
            <Typography variant='body2'>Fetching</Typography>
            <CircularProgress color='info' size={18} />
          </Stack>
        );

      case 'streaming':
        return (
          <Stack
            direction={'row'}
            justifyContent={'center'}
            alignItems={'center'}
            spacing={1}
          >
            <Typography variant='body2'>Streaming</Typography>
            <CircularProgress color='success' size={18} />
          </Stack>
        );

      default:
        return <></>;
    }
  }, [status]);

  return (
    <Slide in={visible}>
      <Stack mb={0.5} sx={{ color: 'white' }}>
        {indicator}
      </Stack>
    </Slide>
  );
};
