import { Add } from '@mui/icons-material';
import React from 'react';

import { useAppSelector } from 'common/hooks';
import {
  selectApplicationContext,
  selectCustomer,
} from 'common/stores/globalSlice';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

import BNSFLogo from 'train-tool/assets/icons/BNSF_Railway_Logo_White_RGB.png';
import { CNLogo } from 'train-tool/assets/icons/CNLogo';
import CSXLogo from 'train-tool/assets/icons/csxlogo.png';

/**
 * @returns {JSX.Element} customer logo depending on application's current customer. Currently supports CSX, CN, and BNSF
 */
export const CustomerLogo = () => {
  const customer = useAppSelector(selectCustomer);
  const application = useAppSelector(selectApplicationContext);

  if (application === 'train_analysis') {
    if (customer === 'CSX') {
      return (
        <React.Fragment>
          <Add
            fontSize='medium'
            sx={{ color: PowerToolDarkTheme.statusGray400, mr: 1 }}
          />
          <img
            src={CSXLogo}
            alt='Customer Logo'
            style={{ width: 56, marginRight: '15px' }}
          />
        </React.Fragment>
      );
    } else if (customer === 'CN') {
      return (
        <React.Fragment>
          <Add
            fontSize='medium'
            sx={{ color: PowerToolDarkTheme.statusGray400, ml: 1 }}
          />
          <CNLogo />
        </React.Fragment>
      );
    } else if (customer === 'BNSF') {
      return (
        <React.Fragment>
          <Add
            fontSize='medium'
            sx={{ color: PowerToolDarkTheme.statusGray400, ml: 0.5 }}
          />
          <img src={BNSFLogo} alt='Customer Logo' style={{ width: '110px' }} />
        </React.Fragment>
      );
    } else {
      return <></>;
    }
  }

  return <></>;
};
