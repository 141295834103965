import { GridApi } from 'ag-grid-community';

import { Asset, DateRange } from 'common/types/types';

export const DayRangeChoices = ['2', '7', '30', '90', 'range'] as const;
export type DayRangeChoice = (typeof DayRangeChoices)[number];

export const DateSourceChoices = ['occur_date', 'offboard_load_date'] as const;
export type DateSourceChoice = (typeof DateSourceChoices)[number];

export interface ParameterMap {
  parameterName: string;
  parameterLabel: string;
  parameterGroup: string;
  toolTip?: string;
  widthOverride?: number; // specify in pixels a width. this overrides all other width definitions
}

export type ExternalFilterTracker = {
  steadyState: boolean | undefined;
  showFired: boolean | undefined;
  inDataRange: boolean | undefined;
  healthCheck: boolean | undefined;
  datapacks: boolean | undefined;
};

export type OutboundMacroResult = {
  colors: string[];
  colorDomain: number[];
  value: number;
  tooltip: string;
};
export type OutboundMacroResults = Map<string, OutboundMacroResult>;
export type OutboundMacroResponse = {
  data: OutboundMacroResults;
  objid: string;
};

export type WeatherForFaultPayload = {
  latitude: number;
  longitude: number;
  faultObjId: string;
  date?: string;
};

export type OnDemandWeatherResponse = {
  faultObjid: string;
  name: string;
  state: string;
  elevFT: number;
  weather: string;
  precipIN: number;
  snowIn: number;
  windSpeedMPH: number;
  windGustMPH: number;
  windDirDeg: number;
  windDir: string;
  tempF: number;
  icon: string;
};

export type ColumnHeaderTooltipProps = {
  group?: string;
  tooltip: string;
};

export type ToggleCustomFilterParams = {
  quickFilter: Map<string, boolean>;
  steadyState: React.MutableRefObject<boolean>;
  datapacks: React.MutableRefObject<boolean>;
  asset?: Asset;
  models?: string[];
  api?: GridApi<any>;
};

export const QuickFilterChoices = [
  'engine_running',
  'full_load',
  'notch_8',
  'has_datapack',
  'steady_state',
] as const;
export type QuickFilterChoice = (typeof QuickFilterChoices)[number];

export type QuickFilter = {
  type: QuickFilterChoice;
  label: string;
  tooltip?: string;
};
