import { Divider, Slide, Typography } from '@mui/material';
import { DateTime, Duration } from 'luxon';
import React, { useMemo } from 'react';

import { useAppSelector } from 'common/hooks';
import { selectTimelineNow } from 'common/stores/timelineSlice';
import { daysToMinutes } from 'common/util/time';

import { Thumb } from './components/Thumb';
import { Tick, TimeTick } from './components/Tick';
import { Track } from './components/Track';
import { useTimelineStyles } from './hooks/style';
import { useConvertEventsToDurationMap } from './hooks/util';
import { FullWidthTimeline } from './styles';
import { TimelineEventType } from './types';

export const GenericTimeline = ({
  min,
  max,
  step,
  label,
  type,
  iconView,
  scrubbing,
}: {
  min: number;
  max: number;
  step: number;
  label: string;
  iconView: boolean;
  type?: TimelineEventType | 'time';
  scrubbing?: boolean;
}) => {
  const { trackStyle } = useTimelineStyles();

  return (
    <React.Fragment>
      <div
        style={{
          display: 'flex',
          height: trackStyle.height,
        }}
      >
        <Slide in={true} direction={'right'}>
          <Typography
            variant='button'
            style={{ flex: '0 0 100px', color: 'white' }}
          >
            {label}
          </Typography>
        </Slide>

        {type === 'time' ? (
          <ZoomableTimeTimeline
            min={min}
            max={max}
            step={step}
            iconView={iconView}
            scrubbing={scrubbing}
          />
        ) : (
          <SingleEventTimeline
            min={min}
            max={max}
            step={step}
            type={type}
            iconView={iconView}
            scrubbing={scrubbing}
          />
        )}

        {/* <SingleEventTimeline /> */}
      </div>
      <Divider flexItem orientation='horizontal' />
    </React.Fragment>
  );
};

const SingleEventTimeline = ({
  min,
  max,
  step,
  type,
  iconView,
  scrubbing,
}: {
  min: number;
  max: number;
  step: number;
  iconView: boolean;
  type?: TimelineEventType;
  scrubbing?: boolean;
}) => {
  const { eventMap } = useConvertEventsToDurationMap(type);

  return (
    <FullWidthTimeline
      min={min}
      max={max}
      step={step}
      marks={[...eventMap.keys()]}
      renderTrack={(props, state) => {
        return <Track props={props} />;
      }}
      renderThumb={
        scrubbing
          ? (props, state) => {
              return <Thumb value={state.valueNow} props={props} />;
            }
          : undefined
      }
      renderMark={(props) => {
        const event = eventMap.get(props.key as number);

        return <Tick event={event} props={props} icons={iconView} />;
      }}
      onChange={
        scrubbing
          ? (value: number | readonly number[], index) => {
              // to-do implement filtering events and such
            }
          : undefined
      }
    />
  );
};

const ZoomableTimeTimeline = ({
  min,
  max,
  step,
  iconView,
  scrubbing,
}: {
  min: number;
  max: number;
  step: number;
  iconView: boolean;
  scrubbing?: boolean;
}) => {
  const now = useAppSelector(selectTimelineNow);

  const range = useMemo(() => {
    return now
      .diff(now.plus({ minutes: min }), ['years', 'months', 'days', 'hours'])
      .toObject();

    // in minutes, what is the current range the user is looking at
    // console.log((max - min) / 1440)

    // return max - min;
  }, [now, min]);

  const hourMarks = useMemo(() => {
    const marks: number[] = [];
    const { years, months, days, hours } = range;

    /**
     *   Minutes to Duration Conversions
     *
     *     60      = 1 HOUR
     *     1440    = 1 DAY
     *
     */

    if (
      range &&
      years !== undefined &&
      months !== undefined &&
      days !== undefined &&
      hours !== undefined
    ) {
      // console.log(range)

      if (years > 0) {
        return [];
      }

      // our current span is greater than 2 months
      else if (months > 2) {
        return [];
      }

      // current span is more than a day
      else if (days > 0) {
        // we're looking at multiple months and multiple days
        if (days > 5 || months > 0) {
          for (let i = 0; i > min; i--) {
            // push a tick for each day
            if (i % 1440 === 0) {
              marks.push(i);
            }
          }
        }

        // otherwise we're looking at a span off less than a month
        else if (months === 0) {
          for (let i = 0; i > min; i--) {
            // push a tick for every hour
            if (i % 60 === 0) {
              marks.push(i);
            }
          }
        }
      }
    }

    // console.log(marks.length)
    return marks;
  }, [min, range]);

  return (
    <FullWidthTimeline
      min={min}
      max={max}
      step={step}
      marks={hourMarks}
      renderTrack={(props, state) => {
        return <Track props={props} />;
      }}
      renderThumb={
        scrubbing
          ? (props, state) => {
              return <Thumb value={state.valueNow} props={props} />;
            }
          : undefined
      }
      renderMark={(props) => {
        return <TimeTick props={props} />;
      }}
      onChange={
        scrubbing
          ? (value: number | readonly number[], index) => {
              // to-do implement filtering events and such
            }
          : undefined
      }
    />
  );
};
