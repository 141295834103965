import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UserSetting } from 'common/features/settings/types';
import { RootState } from 'common/store';

export type UserSettingState = {
  userSettings: UserSetting[] | undefined;
  omdSetting?: 'rejected'; //if user info is unable to be retrieved from OMD, this will be 'rejected'
  urlParamSetting?: 'rejected'; //if no userId param is set, this'll be 'rejected'
  currentLayout: any;
  currentLayoutSetting: UserSetting | undefined;
  saveUserLayout: boolean;
  satelliteView: boolean;
  scatterPlotView: boolean;
};

const initialState: UserSettingState = {
  userSettings: undefined,
  currentLayout: {}, // as the user changes their layout, we hold the current state here
  currentLayoutSetting: undefined, // a user at any given time will have a defined "original" layout before they start adjusting
  saveUserLayout: false,
  satelliteView: false,
  scatterPlotView: false,
};

export const userSettingSlice = createSlice({
  name: 'userSetting',
  initialState,
  reducers: {
    setCurrentLayout: (state, action: PayloadAction<any>) => {
      state.currentLayout = action.payload;
    },
    setCurrentLayoutSetting: (
      state,
      action: PayloadAction<UserSetting | undefined>
    ) => {
      state.currentLayoutSetting = action.payload;
    },
    setSaveUserLayout: (state, action: PayloadAction<boolean>) => {
      state.saveUserLayout = action.payload;
    },
    setSatelliteView: (state, action: PayloadAction<boolean>) => {
      state.satelliteView = action.payload;
    },
    setScatterPlotView: (state, action: PayloadAction<boolean>) => {
      state.scatterPlotView = action.payload;
    },
  },
});

export const {
  setCurrentLayout,
  setCurrentLayoutSetting,
  setSaveUserLayout,
  setSatelliteView,
  setScatterPlotView,
} = userSettingSlice.actions;

export const selectLayout = (state: RootState) =>
  state.userSetting.currentLayout;
export const selectActiveLayout = (state: RootState) =>
  state.userSetting.currentLayoutSetting;
export const selectSaveUserLayout = (state: RootState) =>
  state.userSetting.saveUserLayout;
export const selectSatelliteView = (state: RootState) =>
  state.userSetting.satelliteView;
export const selectScatterPlotView = (state: RootState) =>
  state.userSetting.scatterPlotView;
export default userSettingSlice.reducer;
