import { ColDef } from 'ag-grid-community';
import { useMemo } from 'react';

import { useFetchCallEvents } from 'loco-history/api/hooks';
import { createEventTimeColDef } from 'loco-history/cards/Timeline/components/events/utils';
import { CallEvent } from 'loco-history/types';

import { EventsGrid } from './EventsGrid';

/**
 * @returns {JSX.Element} ag-grid wrapper for locomotive call log events
 */
export const CallEvents = () => {
  const { callEvents } = useFetchCallEvents();

  const columns = useMemo(
    (): ColDef[] => [
      {
        field: 'callCustomer',
        headerName: 'Customer',
        cellRenderer: 'agGroupCellRenderer',
        headerCheckboxSelection: true,
        checkboxSelection: true,
        width: 150,
      },
      {
        field: 'issueType',
        headerName: 'Type',
        width: 200,
      },
      {
        field: 'caller',
        headerName: 'Caller',
        width: 125,
      },
      {
        field: 'callType',
        headerName: 'Call Type',
        width: 100,
      },
      {
        field: 'notesDesc',
        headerName: 'Notes',
        suppressSizeToFit: false,
      },
      {
        field: 'location',
        headerName: 'Location',
        width: 95,
      },
      {
        field: 'agentName',
        headerName: 'Agent Name',
        width: 120,
      },
      {
        field: 'subCodeInfo',
        headerName: 'Sub Code',
        width: 120,
      },
      {
        field: 'callDurationMinutes',
        headerName: 'Duration',
        width: 80,
      },
      createEventTimeColDef({
        field: 'creationDate',
        headerName: 'Creation Date',
        sort: true,
      }),
      createEventTimeColDef({
        field: 'callStartedOn',
        headerName: 'Started On',
      }),
      createEventTimeColDef({ field: 'callEndedOn', headerName: 'Ended On' }),
    ],
    []
  );

  return <EventsGrid<CallEvent> data={callEvents} columns={columns} />;
};
