import { GridOptions } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import { useEffect, useRef, useState } from 'react';

import { LoadingSpinnerOverlayLoading } from 'common/components/LoadingComponents';
import StyledAgGridCustom from 'common/components/ag-grid/AgGridCustom.styled';
import { useAppSelector } from 'common/hooks';
import { selectTheme } from 'common/stores/globalSlice';

import 'theme/css/ag-grid-custom.scss';

import { AgGridCustomProps, CustomColDef } from './types';

export const AgGridCustom = <T,>(props: AgGridCustomProps<T>) => {
  const theme = useAppSelector(selectTheme);

  // data items for grid
  const [data, setData] = useState<any[] | undefined>(props.data);
  const [columns, setColumns] = useState<CustomColDef[] | undefined>(
    props.columns
  );

  const gridRef = useRef<AgGridReact>(null);
  const handleKeyDown = (event) => {
    if (
      event.which === 13 &&
      event.target.className &&
      event.target.className.indexOf('ag-input-field-input') > -1
    ) {
      gridRef.current?.api?.hidePopupMenu();
    }
  };
  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  useEffect(() => {
    setColumns(props.columns);
  }, [props.columns]);

  const customGridOptions: GridOptions = {
    // column settings
    suppressMovableColumns: true,
    defaultColDef: {
      resizable: true,
      menuTabs: ['filterMenuTab'],
      filterParams: {
        buttons: ['clear'],
        closeOnApply: true,
        defaultToNothingSelected: true,
      },
    },
    postProcessPopup: function (params) {
      params.ePopup.addEventListener('keydown', handleKeyDown);
    },

    // row settings
    rowSelection: 'single',
    suppressRowHoverHighlight: true,

    // cell settings
    suppressCellFocus: true,
    enableRangeSelection: false,
    enableCellTextSelection: true,
    rowMultiSelectWithClick: true,

    frameworkComponents: {
      customLoadingOverlay: LoadingSpinnerOverlayLoading,
    },
    ...props.options,
  };

  return (
    <StyledAgGridCustom>
      {/* Grid Theme Wrapper */}
      <div
        className={
          theme === 'light' ? `ag-theme-alpine` : `ag-theme-alpine-dark`
        }
        style={{ height: props.height ?? '100%', width: '100%' }}
      >
        {/* Render Grid */}
        <AgGridReact
          {...props}
          ref={gridRef}
          rowData={data}
          columnDefs={columns}
          key={theme}
          gridOptions={customGridOptions}
          loadingOverlayComponent={'customLoadingOverlay'}
        ></AgGridReact>
      </div>
    </StyledAgGridCustom>
  );
};
