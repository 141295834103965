import { Responsive, WidthProvider } from 'react-grid-layout';
import styled from 'styled-components';

const ResponsiveGridLayout = WidthProvider(Responsive);

export const StyledReactGridLayout = styled(ResponsiveGridLayout)`
  background-color: ${(props) => props.theme.tertiary};

  .react-grid-item.react-grid-placeholder {
    border-radius: 2px;
    background-color: ${(props) => props.theme.secondary};
  }

  .dynamic-grid .card {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    border: 1px white;
  }

  .react-grid-item > .react-resizable-handle::after {
    border-radius: 1px;
    border-right: 2px solid ${(props) => props.theme.secondary} !important;
    border-bottom: 2px solid ${(props) => props.theme.secondary} !important;
  }
`;
