import axios from 'axios';

import { UserSetting } from './types';

export const createUserSettings = (
  userSettings: UserSetting[]
): Promise<UserSetting[]> => {
  return axios
    .post<UserSetting[]>('settings/saveUserSettings', userSettings)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log('error calling query endpoint for ingested data');
      throw e;
    });
};

export const updateUserSettings = (
  userSettings: UserSetting[]
): Promise<UserSetting> => {
  return axios
    .post<UserSetting>('settings/saveUserSettings', userSettings)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log('error calling query endpoint for ingested data');
      throw e;
    });
};

export const getAllUserSettings = (
  userId: string,
  appName: string
): Promise<UserSetting[]> => {
  return axios
    .get<UserSetting[]>(`settings/all?appName=${appName}&userId=${userId}`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log('error fetching getAllUserSettings');
      throw e;
    });
};

export const saveUserSettings = (
  userSettings: UserSetting[]
): Promise<UserSetting[]> => {
  return axios
    .post<UserSetting[]>('settings/saveUserSettings', userSettings)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log('error in saveUserSettings');
      throw e;
    });
};
