import { Chip } from '@mui/material';
import { MdDirectionsRailway } from 'react-icons/md';

import { useAppSelector } from 'common/hooks';
import { selectAssetDetails } from 'common/stores/globalSlice';

export const AssetChip = () => {
  const asset = useAppSelector(selectAssetDetails);

  if (asset) {
    return (
      <Chip
        size='small'
        color='primary'
        icon={<MdDirectionsRailway />}
        label={asset.vehicleHdr + ' ' + asset.roadNumber}
      />
    );
  }
  return <></>;
};
