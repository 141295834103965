import { BugReport } from '@mui/icons-material';
import { Grid, Stack, Typography, Divider, Tooltip } from '@mui/material';

import { DateTimeWrapper } from 'common/components/DateTimeWrapper';

import { EventTooltipClipboardLabel } from 'loco-history/cards/Timeline/components/event-tooltip/EventTooltipClipboardLabel';
import { SendTextToStory } from 'loco-history/cards/Timeline/components/event-tooltip/SendToStoryComponent';
import { LabelValuePair } from 'loco-history/cards/Timeline/types';

/**
 *
 * @param {any} props implicit destructured props
 * @param {string | undefined} props.title tooltip body title
 * @param {string | undefined} props.clipboardLabel clipboard content to add in
 * @returns {JSX.Element} generic tooltip body header component
 */
export const BodyHeader = ({
  title,
  clipboardLabel,
}: {
  title?: string;
  clipboardLabel?: string;
}) => {
  return (
    <Stack direction={'row'} spacing={1} justifyContent='space-between'>
      <Typography variant='h6' justifySelf={'center'}>
        {title}
      </Typography>
      {clipboardLabel ? (
        <EventTooltipClipboardLabel value={clipboardLabel} />
      ) : (
        <></>
      )}
    </Stack>
  );
};

/**
 *
 * @param {any} props implicit destructured props
 * @param {LabelValuePair} props.item item to display
 * @param {(content: string) => void} props.onContentClick callback function for when item is clicked
 * @returns {JSX.Element} small component representing a label and value for the tooltip body
 */
export const BodyItem = ({
  item,
  onContentClick,
}: {
  item: LabelValuePair;
  onContentClick?: (content: string) => void;
}) => {
  return (
    <Stack direction={'row'} spacing={1}>
      <Typography variant='button' sx={{ whiteSpace: 'nowrap' }}>
        {item.label}:
      </Typography>
      {item.options?.formatTime === true && item.value ? (
        <SendTextToStory
          content={<DateTimeWrapper date={item.value.toString()} />}
          onContentClick={onContentClick}
        />
      ) : (
        <SendTextToStory content={item.value} onContentClick={onContentClick} />
      )}
    </Stack>
  );
};

/**
 *
 * @param {any} props implicit destructured props
 * @param {LabelValuePair} props.item item to display
 * @param {(content: string) => void} props.onContentClick callback function for when item is clicked
 * @returns {JSX.Element} small component representing a label and large value for the tooltip body
 */
export const BodyLongText = ({
  item,
  onContentClick,
}: {
  item: LabelValuePair;
  onContentClick?: (content: string) => void;
}) => {
  if (item.value && item.value !== '') {
    return (
      <Stack key={item.label} direction={'column'} spacing={1}>
        <Divider textAlign='left'>
          <Typography variant='button'>{item.label}</Typography>
        </Divider>
        <SendTextToStory
          content={item.value}
          onContentClick={onContentClick}
          textProps={{ px: 2, whiteSpace: 'pre-line' }}
        />
      </Stack>
    );
  } else {
    return <></>;
  }
};

/**
 *
 * @param {any} props implicit destructured props
 * @param {LabelValuePair[]} props.left represents left column label/values to display
 * @param {LabelValuePair[]} props.right represents right column label/values to display
 * @param {(content: string) => void | undefined} props.onContentClick callback function for when item is clicked
 * @returns {JSX.Element} tooltip body layout wrapper - two columns
 */
export const MainBody = ({
  left,
  right,
  onContentClick,
}: {
  left: LabelValuePair[];
  right: LabelValuePair[];
  onContentClick?: (content: string) => void;
}) => {
  return (
    <Grid container>
      <Grid item xs={6}>
        <Stack direction={'column'} spacing={0.5} justifyContent={'flex-start'}>
          {left.map((item) => (
            <Stack
              key={item.label}
              direction={'row'}
              spacing={1}
              sx={{ wordBreak: 'break-word', pr: 1 }}
            >
              <BodyItem item={item} onContentClick={onContentClick} />
            </Stack>
          ))}
        </Stack>
      </Grid>

      <Grid item xs={6}>
        <Stack direction={'column'} spacing={1} justifyContent={'flex-start'}>
          {right.map((item) => (
            <Stack
              key={item.label}
              direction={'row'}
              spacing={1}
              sx={{ wordBreak: 'break-word', pr: 1 }}
            >
              <BodyItem item={item} onContentClick={onContentClick} />
            </Stack>
          ))}
        </Stack>
      </Grid>
    </Grid>
  );
};

/**
 *
 * @param {any} props implicit destructured props
 * @param {LabelValuePair[]} props.items items to display
 * @param {(content: string) => void} props.onContentClick callback function for when item is clicked
 * @returns {JSX.Element} small component representing a list of values in the tooltip footer
 */
export const BodyFooter = ({ items }: { items: LabelValuePair[] }) => {
  return (
    <Stack direction={'row'} spacing={1} justifyContent={'flex-end'}>
      <Tooltip
        arrow
        leaveDelay={50}
        placement='right-start'
        title={items.map((item) => (
          <Stack
            key={item.label}
            direction={'row'}
            alignItems='center'
            spacing={1}
          >
            <Typography variant='button'>{item.label}: </Typography>
            <EventTooltipClipboardLabel value={item.value?.toString() ?? ''} />
          </Stack>
        ))}
      >
        <BugReport />
      </Tooltip>
    </Stack>
  );
};
