import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'common/store';
import { PowerToolError } from 'common/types/error';
import { PowerToolEvent, User } from 'common/types/types';

import {
  CaseActionState,
  DeliveryAttachmentProps,
  OMDServicesStateChoice,
  ToolOutputDropdowns,
  ToolOutputItemState,
  ToolOutputVerification,
} from 'power-tool/tool-dispo/types';
import { ToolOutputData } from 'power-tool/types';

const initialState: CaseActionState = {
  showWarnings: true,
  caseActionDisabled: true,
  isToolDispoLoading: false,
  errorModalOpen: false,
  omdServicesState: 'init',
  processingState: 'initial',
  inWorkQueue: 'Y',
  isAddRxEnabled: true,
};

export const caseActionSlice = createSlice({
  name: 'caseActionSlice',
  initialState,
  reducers: {
    resetForm: (state, action: PayloadAction<ToolOutputItemState[]>) => {
      state.items = [...action.payload];
      state.showWarnings = true;
    },
    updateItem: (state, action: PayloadAction<ToolOutputItemState>) => {
      state.items = state.items?.map((item) =>
        item.id !== action.payload.id ? item : { ...action.payload }
      );
    },
    addItem: (state, action: PayloadAction<ToolOutputItemState>) => {
      if (
        state.items?.find((item) => item.rxTitle === action.payload.rxTitle) ===
        undefined
      ) {
        state.items = state.items
          ? state.items.concat(action.payload)
          : [action.payload];
      }
    },
    setSearchItem: (state, action: PayloadAction<ToolOutputItemState>) => {
      state.items = state.items
        ?.filter((i) => i.itemType === 'ToolOutput')
        .concat(action.payload);
    },
    removeSearchItem: (state) => {
      state.items = state.items?.filter((i) => i.itemType === 'ToolOutput');
    },
    setCaseActionDisabled: (state, action: PayloadAction<boolean>) => {
      state.caseActionDisabled = action.payload;
    },
    setToolOutputVerification: (
      state,
      action: PayloadAction<ToolOutputVerification>
    ) => {
      state.verification = action.payload;
    },
    setToolOutputDropdowns: (
      state,
      action: PayloadAction<ToolOutputDropdowns>
    ) => {
      state.dropdowns = action.payload;
    },
    setCaseActionDisableReason: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.disableReason = action.payload;
    },
    setIsToolDispoLoading: (state, action: PayloadAction<boolean>) => {
      state.isToolDispoLoading = action.payload;
    },
    setIsOMDServicesLoaded: (
      state,
      action: PayloadAction<OMDServicesStateChoice>
    ) => {
      state.omdServicesState = action.payload;
    },
    setCurrentCaseOwner: (state, action: PayloadAction<string | undefined>) => {
      state.currentCaseOwner = action.payload;
    },
    setErrorModalOpen: (state, action: PayloadAction<boolean>) => {
      state.errorModalOpen = action.payload;
    },
    setError: (state, action: PayloadAction<PowerToolError>) => {
      state.error = action.payload;
    },
    setVizxAttachments: (
      state,
      action: PayloadAction<DeliveryAttachmentProps[]>
    ) => {
      state.vizxAttachments = action.payload;
    },
    setToolDispoProcessingState: (
      state,
      action: PayloadAction<'ready' | 'processing' | 'error'>
    ) => {
      state.processingState = action.payload;
    },
    setInWorkQueue: (state, action: PayloadAction<'Y' | 'N'>) => {
      state.inWorkQueue = action.payload;
    },
    setIsAddRxEnabled: (state, action: PayloadAction<boolean>) => {
      state.isAddRxEnabled = action.payload;
    },
    setRxCustomized: (
      state,
      action: PayloadAction<PowerToolEvent | undefined>
    ) => {
      state.rxCustomized = action.payload;
    },
    setOpenedRx: (
      state,
      action: PayloadAction<ToolOutputItemState | undefined>
    ) => {
      state.openedRx = action.payload;
    },

    // saga actions
    setToolDispoErrorAction: (
      state,
      action: PayloadAction<PowerToolError>
    ) => {},
    refreshToolDispoAction: (state, action: PayloadAction<undefined>) => {},
    openToolDispoAction: (state, action: PayloadAction<User>) => {},
    addRxAction: (state, action: PayloadAction<ToolOutputData>) => {},
  },
});

export const {
  resetForm,
  updateItem,
  addItem,
  setSearchItem,
  setCaseActionDisabled,
  setToolOutputVerification,
  setCaseActionDisableReason,
  setIsToolDispoLoading,
  setToolOutputDropdowns,
  setCurrentCaseOwner,
  setErrorModalOpen,
  setError,
  setVizxAttachments,
  setIsOMDServicesLoaded,
  setToolDispoErrorAction,
  refreshToolDispoAction,
  openToolDispoAction,
  setToolDispoProcessingState,
  setInWorkQueue,
  removeSearchItem,
  addRxAction,
  setIsAddRxEnabled,
  setRxCustomized,
  setOpenedRx,
} = caseActionSlice.actions;

export const getCaseActionState = (state: RootState) => state.caseAction;
export const selectCaseActionDisabled = (state: RootState) =>
  state.caseAction.caseActionDisabled;
export const selectToolOutputVerification = (state: RootState) =>
  state.caseAction.verification;
export const selectCaseActionDisableReason = (state: RootState) =>
  state.caseAction.disableReason;
export const selectIsToolDispoLoading = (state: RootState) =>
  state.caseAction.isToolDispoLoading;
export const selectToolOutputDropdowns = (state: RootState) =>
  state.caseAction.dropdowns;
export const selectCurrentCaseOwner = (state: RootState) =>
  state.caseAction.currentCaseOwner;
export const selectErrorModalOpen = (state: RootState) =>
  state.caseAction.errorModalOpen;
export const selectErrorModalMessage = (state: RootState) =>
  state.caseAction.error;
export const selectVizxAttachments = (state: RootState) =>
  state.caseAction.vizxAttachments;
export const selectIsOMDServicesLoaded = (state: RootState) =>
  state.caseAction.omdServicesState;
export const selectToolDispoProcessingState = (state: RootState) =>
  state.caseAction.processingState;
export const selectInWorkQueue = (state: RootState) =>
  state.caseAction.inWorkQueue;
export const selectIsAddRxEnabled = (state: RootState) =>
  state.caseAction.isAddRxEnabled;
export const selectRxCustomized = (state: RootState) =>
  state.caseAction.rxCustomized;
export const selectOpenedRx = (state: RootState) => state.caseAction.openedRx;
export default caseActionSlice.reducer;
