import { ChartRef } from 'ag-grid-community';
import { useState, useEffect } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

import { useFetchEvoModels } from 'common/api/hooks';
import { selectGridApi } from 'common/cards/Data/store';
import { LoadingSpinner } from 'common/components/LoadingComponents';
import { selectIngestedDataStatus } from 'common/features/ingested-data/store';
import { useAppSelector } from 'common/hooks';
import { selectAssetDetails, selectRule } from 'common/stores/globalSlice';

import { selectCase } from 'power-tool/stores/casesSlice';
import { getChartModels, getSelectedPlot } from 'power-tool/stores/plotSlice';

import { AnomPlot } from './AnomPlot';
import { PlotListComponent } from './PlotList';
import { PlotToolbarComponent } from './PlotToolbar';
import { getSASPlot } from './api';
import { cannedPlots, basePlots } from './config';

const createdPlots = {};

const chartPanel = (
  <div
    className='chart-wrapper ag-theme-alpine-dark'
    style={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <div className='chart-wrapper-body' style={{ height: '100%' }}></div>
  </div>
);

let shouldFocusNewUserPlot = true;
export const toggleFocusNewUserPlot = () =>
  (shouldFocusNewUserPlot = !shouldFocusNewUserPlot);

export function PlotCard() {
  const api = useAppSelector(selectGridApi);
  const chartModels = useAppSelector(getChartModels);
  const selectedPlot = useAppSelector(getSelectedPlot);
  // const isDataCardLoading = useAppSelector(selectDataCardLoading);
  const dataStatus = useAppSelector(selectIngestedDataStatus);
  const assetInfo = useAppSelector(selectAssetDetails);
  const selectedRule = useAppSelector(selectRule);
  const selectedCase = useAppSelector(selectCase);

  const { evoModels } = useFetchEvoModels();
  const supportedLegacyModels = ['D8', 'D9', 'AC44CW'];

  const [focusedView, setFocusedView] = useState<boolean>(false);
  const [selectedPlotTitle, setSelectedPlotTitle] =
    useState<string>('user plot');
  const [userPlots, setUserPlots] = useState<string[]>([]);
  const [selectedUserPlot, setSelectedUserPlot] = useState<number>(0);
  const [anomalyPlot, setAnomalyPlot] = useState<string>();
  const [plots, setPlots] = useState<{}>(cannedPlots);

  // when the PlotCard re-renders (i.e. switching tabs) or when the selected plot changes
  // render the correct plot to the card
  // we need to select the wrapper element to ensure we get the menu associated with the plot
  useEffect(() => {
    // div element where our plots go
    const parentElement = document.querySelector('#plot-panel');
    const chartId = chartModels[selectedPlot];
    const chart = api?.getChartRef(chartId);
    if (
      chartModels.length > 0 &&
      parentElement &&
      selectedPlot >= 0 &&
      chart !== undefined
      // !isDataCardLoading
    ) {
      // replace our plot with the focused chart
      parentElement.innerHTML = '';
      const chartDOMElement = chart.chartElement.closest(`#${chartId}`);
      // if (chartDOMElement !== null)
      parentElement?.appendChild(chartDOMElement as HTMLElement);

      // i have no idea why the plots arent focused when changing.
      // also just dispatching an event after the plot is created doesnt't work
      // it also needs to be wrapped in a 0ms settimeout, ag-grid has examples
      // of this in their offical support topics
      // https://ag-grid.zendesk.com/hc/en-us/articles/4410767336209
      setTimeout(
        () => chart.chartElement.dispatchEvent(new Event('focusin')),
        0
      );
    }

    // we dont want this to happen again when the gridAPI is set
    // so we ignore it from the dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    chartModels,
    selectedPlot,
    // isDataCardLoading
  ]);

  // when our list of chart models change, look for user created plots (not RX plot, not canned plot)
  // and filter them into a new list
  // this is used for navigation purposes: we allow the user to cycle through multiple different
  // user generated plots
  useEffect(() => {
    // we see if any of the existing models do not match
    // the index of our createdPlots map (canned plot tracker)
    // if a chart is not in our canned list tracker, it is a user generated plot
    setUserPlots([
      ...new Set(
        chartModels.filter(
          (_, index) => !Object.values(createdPlots).includes(index)
        )
      ),
    ]);
  }, [chartModels]);

  // when we add a new user plot, set the selected plot
  // to the most recently created plot (last element in array)
  useEffect(() => {
    if (shouldFocusNewUserPlot) {
      setSelectedUserPlot(userPlots.length - 1);
    }
  }, [userPlots]);

  useEffect(() => {
    const parentElement = document.querySelector('#plot-panel');

    // when we get an anom plot, we need to clear the canvas from the current tab
    if (parentElement?.innerHTML && selectedPlotTitle === 'anomaly plot') {
      parentElement.innerHTML = '';
      setFocusedView(false);
    }
  }, [selectedPlotTitle]);

  // once we have the information to get a SAS plot, go get the sas plot
  useEffect(() => {
    //set selected plot to default. this makes auto switching to anomPlot smoother
    setSelectedPlotTitle('user plot');
    if (
      selectedRule > 0 &&
      selectedCase &&
      assetInfo &&
      assetInfo.vehicleObjid > 0
    ) {
      getSASPlot(selectedRule, selectedCase.caseId, assetInfo.vehicleObjid)
        .then((response) => {
          if (response !== '' && response !== undefined) {
            const changedPlot = {
              ...cannedPlots,
              // @ts-ignore
              'anomaly plot': {},
            };
            setPlots(changedPlot);
          } else {
            setPlots(cannedPlots);
          }
          setAnomalyPlot(response);
        })
        .catch((e) => {
          setPlots(cannedPlots);
        });
    } else {
      setPlots(cannedPlots);
    }
  }, [selectedRule, selectedCase, assetInfo, anomalyPlot]);

  const render = () => {
    if (dataStatus === 'fetching') {
      return (
        <div
          style={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <LoadingSpinner />
          <div
            id='plot-panel'
            style={{ height: '100%', overflow: 'auto', marginRight: '13px' }}
          />
        </div>
      );
    } else {
      return (
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
          }}
        >
          <PlotListComponent
            cannedPlots={
              evoModels?.includes(assetInfo?.vehicleModel ?? '') ||
              supportedLegacyModels?.includes(assetInfo?.vehicleModel ?? '')
                ? plots
                : basePlots
            }
            createdPlots={createdPlots}
            userPlots={userPlots}
            selectedPlotTitle={selectedPlotTitle}
            selectedUserPlot={selectedUserPlot}
            anomalyPlotData={anomalyPlot}
            setSelectedPlotTitle={setSelectedPlotTitle}
            setFocusedView={setFocusedView}
          />

          <PlotToolbarComponent
            focusedView={focusedView}
            selectedPlotTitle={selectedPlotTitle}
            userPlots={userPlots}
            selectedUserPlot={selectedUserPlot}
            setFocusedView={setFocusedView}
            setSelectedUserPlot={setSelectedUserPlot}
          />

          {/* this is what we select below to put the AG-Grid integrated chart into */}
          <div
            id='plot-panel'
            style={{
              height: selectedPlotTitle !== 'anomaly plot' ? '100%' : '0%',
              overflow: 'auto',
              marginRight: '13px',
            }}
          ></div>
          {selectedPlotTitle === 'anomaly plot' && (
            <AnomPlot
              src={`data:image/png;base64,${anomalyPlot}`}
              label={'Anom Plot'}
            />
          )}
        </div>
      );
    }
  };

  return render();
}

// https://www.ag-grid.com/react-data-grid/integrated-charts-container/#example-provided-container
export const createChartContainer = (chartRef: ChartRef) => {
  // get the chart element itself coming as a ref from AG-Grid
  const chartElement = chartRef.chartElement;

  // create a temp element to put everything into
  const tempElement = document.createElement('div');

  // then render the plot panel into the temp div
  tempElement.innerHTML = renderToStaticMarkup(chartPanel);
  const chartWrapper = tempElement.firstChild as HTMLDivElement;

  // get the chart's parent element
  const parentElement = document.querySelector('#plot-panel');

  if (!parentElement) {
    console.log('error setting plot - null parent element');
    // createChartContainer(chartRef);
    return;
  } else {
    // ID of the wrapper is the ID of the chart
    // we should be able to query select this from anywhere in the app
    // to get the direct HTML element
    chartWrapper.id = chartRef.chartId;

    // put the wrapper in the parent defined above in the component itself
    parentElement.appendChild(chartWrapper);

    // put the actual chart contents/events into the DOM
    chartWrapper
      .querySelector('.chart-wrapper-body')
      ?.appendChild(chartElement);
    return;
  }
};

export default PlotCard;
