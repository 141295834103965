/**
 *
 * @param {React.SVGProps<SVGSVGElement>} props additional SVG icon properties
 * @returns {SVGElement} icon that represents if a locomotive has univalve cooling
 */
export const UnivalveIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    // <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
    //   viewBox="0 0 503.609 503.609">
    <svg
      width={props.width ?? 48}
      height={props.height ?? 24}
      // fill="none"
      viewBox='0 0 503.609 503.609'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g>
        <g>
          <g>
            <path
              d='M495.216,0.001h-67.148c-4.642,0-8.393,3.752-8.393,8.393V193.99c0,8.738-7.109,15.847-15.847,15.847h-60.542
				c-3.811-8.251-8.662-15.922-14.453-22.797l-47.683,48.783c2.61,4.776,4.23,10.164,4.23,15.981c0,6.211-1.805,11.952-4.759,16.946
				l48.027,48.027c5.867-6.925,10.777-14.672,14.621-23.006h84.799c41.657,0,75.541-33.884,75.541-75.541V8.395
				C503.609,3.753,499.857,0.001,495.216,0.001z M486.822,218.231c0,32.39-26.364,58.754-58.754,58.754h-78.789
				c0.042-0.151,0.05-0.311,0.092-0.47c0.881-3.466,1.553-7.008,2.065-10.609c0.126-0.848,0.252-1.687,0.353-2.543
				c0.436-3.802,0.739-7.638,0.739-11.558s-0.302-7.764-0.739-11.558c-0.101-0.839-0.227-1.679-0.344-2.51
				c-0.52-3.668-1.2-7.277-2.107-10.811c-0.025-0.101-0.034-0.201-0.059-0.302h54.549c17.996,0,32.634-14.638,32.634-32.634V16.788
				h50.361V218.231z'
            />
            <path
              d='M235.019,251.805c0,4.625,1.88,8.813,4.91,11.852c0.008,0.008,0.008,0.008,0.017,0.008c0,0.008,0,0.008,0.008,0.017
				c3.038,3.03,7.227,4.91,11.852,4.91c4.625,0,8.813-1.88,11.851-4.91c0.008-0.008,0.008-0.008,0.008-0.017
				c0.008,0,0.008,0,0.017-0.008c3.03-3.038,4.91-7.227,4.91-11.852c0-9.258-7.529-16.787-16.787-16.787
				C242.548,235.018,235.019,242.547,235.019,251.805z'
            />
            <path
              d='M251.806,285.378c-6.211,0-11.952-1.805-16.946-4.759l-48.027,48.027c17.559,14.865,40.221,23.879,64.974,23.879
				s47.415-9.015,64.974-23.879l-48.027-48.027C263.758,283.574,258.017,285.378,251.806,285.378z'
            />
            <path
              d='M251.806,218.231c6.597,0,12.716,1.981,17.912,5.279l47.263-48.363c-17.584-14.974-40.322-24.064-65.175-24.064
				c-24.996,0-47.834,9.199-65.46,24.324l47.373,48.195C238.955,220.237,245.133,218.231,251.806,218.231z'
            />
            <path
              d='M218.23,251.805c0-5.75,1.586-11.071,4.138-15.805l-47.851-48.674c-5.682,6.799-10.458,14.37-14.21,22.511H75.541
				C33.884,209.837,0,243.722,0,285.378v209.836c0,4.642,3.76,8.393,8.393,8.393h67.148c4.642,0,8.393-3.752,8.393-8.393V309.619
				c0-8.738,7.109-15.847,15.847-15.847h60.559c3.853,8.335,8.763,16.082,14.621,23.006l48.027-48.027
				C220.042,263.757,218.23,258.016,218.23,251.805z M151.829,263.362c0.101,0.856,0.227,1.695,0.344,2.543
				c0.512,3.601,1.183,7.143,2.065,10.609c0.042,0.159,0.05,0.319,0.092,0.47H99.781c-17.996,0-32.634,14.638-32.634,32.634v177.202
				H16.787V285.378c0-32.39,26.364-58.754,58.754-58.754h78.789c0,0.017-0.008,0.025-0.008,0.042
				c-0.94,3.634-1.637,7.344-2.166,11.121c-0.117,0.814-0.235,1.628-0.327,2.451c-0.445,3.802-0.747,7.646-0.747,11.566
				S151.384,259.56,151.829,263.362z'
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
