import styled, { createGlobalStyle } from 'styled-components';

// import { Theme } from "./Themes";

// non-variable properties defined in global.css

export const GlobalStyles = createGlobalStyle`
  body {
    background: ${(props) => props.theme.background};
    color: ${(props) => props.theme.primary}
  }
  .app-bar{
    background: ${(props) => props.theme.background}
  }

  .SnackbarItem-variantSuccess {
    background: ${(props) => props.theme.statusGreen500} !important;
  }

  .SnackbarItem-variantInfo {
    background: ${(props) => props.theme.voltBlue500} !important;
  },

  .SnackbarItem-variantWarning {
    background: ${(props) => props.theme.statusOrange500} !important;
  },

  .SnackbarItem-variantError {
    background: ${(props) => props.theme.statusRed500} !important;
  },
`;

export const StyledH4 = styled.h4`
  color: ${(props) => props.theme.primary};
  font-size: 16px;
  font-weight: 500;
  margin: 0px 0px 0px 4px;
  /* line-height: 24; */
`;

export const StyledH6 = styled.h6`
  color: ${(props) => props.theme.voltBlue500};
  font-size: 16px;
  font-weight: bold;
  margin: 0px;
  white-space: 'nowrap';
`;
