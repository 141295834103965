import { Divider, Paper, Stack, Typography } from '@mui/material';
// import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { useTheme } from 'styled-components';
import { v4 as uuid } from 'uuid';

import { LoadingSpinner } from 'common/components/LoadingComponents';
import { AssetSearch } from 'common/features/search/AssetSearch';
import { useAppDispatch, useAppSelector } from 'common/hooks';
import { setRuleFamily } from 'common/stores/globalSlice';

// import { selectIngestedData } from 'common/cards/Data/store';
import { getVirtualDetails } from 'virtual-tester/api';
import {
  selectVirtualData,
  selectVirtualId,
  selectVirtualJson,
  setVirtualData,
  setVirtualEquation,
  setVirtualJson,
} from 'virtual-tester/stores/virtualSlice';
import { VirtualJson } from 'virtual-tester/types';

/**
 * @returns {JSX.Element} preview component for virtual tester containing the virtual title, equation, and asset search component
 */
export function VirtualPreview() {
  const theme = useTheme();

  const virtualData = useAppSelector(selectVirtualData);
  const virtualId = useAppSelector(selectVirtualId);
  const virtualJson = useAppSelector(selectVirtualJson);
  // const ingestedData = useAppSelector(selectIngestedData);
  const dispatch = useAppDispatch();

  const [virtualName, setVirtualName] = useState('');

  // const { enqueueSnackbar } = useSnackbar();

  const displayJson = useMemo(() => {
    return (
      <React.Fragment>
        {virtualJson.map((item) => {
          //parameters are always green
          if (item.type === 'parameter' || item.type === 'virtual') {
            return (
              <span style={{ color: theme.dataTeal300 }} key={uuid()}>
                {' '}
                {item.name}{' '}
              </span>
            );
          }
          // operators are labeled as functions, we want those to be white
          // so we omit them here for them to be catched in the else
          else if (
            item.type === 'function' &&
            item.name !== '-' &&
            item.name !== '/' &&
            item.name !== '*' &&
            item.name !== '+'
          ) {
            return (
              <span style={{ color: theme.dataGreen300 }} key={uuid()}>
                {' '}
                {item.name}{' '}
              </span>
            );
          } else {
            return <React.Fragment key={uuid()}>{item.name} </React.Fragment>;
          }
        })}
      </React.Fragment>
    );
  }, [virtualJson, theme]);

  // useEffect(() => {
  //   if (ingestedData.length > 0) {
  //     // if virtual tester is unable to process a virtual for any reason, it returns its results without
  //     // VIRTUAL_RESULT. we leverage that here to warn the user that something is going wrong.
  //     const result = ingestedData.find((data) => data['VIRTUAL_RESULT']);
  //     if (result === undefined) {
  //       enqueueSnackbar(
  //         "Virtual didn't calculate for this vehicle. Try a different one!",
  //         { variant: 'warning', autoHideDuration: 5000 }
  //       );
  //     }
  //   }
  // }, [enqueueSnackbar, ingestedData]);

  useEffect(() => {
    if (
      virtualData &&
      virtualJson &&
      virtualData.virtualEquation[0]?.equation
    ) {
      let newEquation: string = virtualData.virtualEquation[0].equation;

      virtualJson.forEach((js) => {
        //replacing IDs with virtual tester friendly params
        //“( $7512$ / #571# )” -> “( $MP_1151_N_0_0$ / #V_TRS# )”
        if (js.type === 'virtual') {
          newEquation = newEquation.replaceAll(`#${js.id}#`, `#${js.name}#`);
        } else if (js.type === 'parameter') {
          newEquation = newEquation.replaceAll(
            `$${js.id}$`,
            `$${js.paramDBName}$`
          );
        }
      });

      dispatch(setVirtualEquation(newEquation));
    }
  }, [dispatch, virtualData, virtualJson]);

  useEffect(() => {
    if (virtualData) {
      setVirtualName(virtualData.virtualName);

      dispatch(setRuleFamily(virtualData.family));

      if (virtualData.virtualEquation[0]?.equationJsonTxt !== undefined) {
        //converting the stored text json into an actual json
        try {
          const json: VirtualJson[] = JSON.parse(
            virtualData.virtualEquation[0].equationJsonTxt
          ) as VirtualJson[];
          dispatch(setVirtualJson(json));
        } catch {
          // console.log('Error parsing virtual equation');
        }
      }
    }
  }, [dispatch, virtualData]);

  // used for testing purposes. retrieves the virtual JSON in place of receiving it from OMD
  // when the virtualId is set in URLParams
  useEffect(() => {
    if (virtualId) {
      getVirtualDetails(virtualId)
        .then((response) => {
          dispatch(setVirtualData(response));
        })
        .catch(() => dispatch(setVirtualData(undefined)));
    }
  }, [dispatch, virtualId]);

  return (
    <Paper
      sx={{
        backgroundColor: '#172839',
        p: 5,
      }}
      style={{ padding: '10px', height: '100%' }}
    >
      {virtualData ? (
        <Stack data-testid='virtualPreview' divider={<Divider />} spacing={1}>
          <div
            style={{
              display: 'flex',
              gap: '5px',
              justifyContent: 'center',
            }}
          >
            <Typography
              variant='h6'
              data-testid='virtualName'
              style={{
                fontWeight: 'bold',
                marginLeft: '10px',
                marginTop: '10px',
              }}
            >
              {virtualName}
            </Typography>
          </div>
          <div
            style={{
              display: 'flex',
              gap: '5px',
              justifyContent: 'center',
            }}
          >
            <AssetSearch inputSx={{ minWidth: '300px' }} />
          </div>
          <div
            style={{
              display: 'flex',
              gap: '5px',
              justifyContent: 'center',
            }}
          >
            <Paper
              variant='outlined'
              sx={{ backgroundColor: '#172839', fontSize: '13px' }}
              style={{
                padding: '8px',
                display: 'flex',
                flexWrap: 'wrap',
                gap: '.1rem',
                marginBottom: '5px',
                fontFamily: 'monospace',
                width: 'fit-content',
                justifyContent: 'center',
              }}
            >
              {displayJson}
            </Paper>
          </div>
        </Stack>
      ) : (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <LoadingSpinner />
        </div>
      )}
    </Paper>
  );
}

export default VirtualPreview;
