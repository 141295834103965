/* eslint import/no-webpack-loader-syntax: off */
// eslint-disable-next-line import/no-unresolved
import mapboxgl from '!mapbox-gl';
import { bbox, lineString } from '@turf/turf';
import chroma from 'chroma-js';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useRef, useEffect, useState, useMemo, useCallback } from 'react';
import { createRoot } from 'react-dom/client';

import { useAppSelector, useAppDispatch } from 'common/hooks';
import { selectApplicationContext } from 'common/stores/globalSlice';
import {
  getMarkerData,
  getMapFlag,
  setFocusedFault,
  setMarkerData,
} from 'common/stores/mapSlice';
import { selectSatelliteView } from 'common/stores/userSettingSlice';
import { PowerToolApplicationContext } from 'common/types/types';
import { getApplicationRuntime } from 'common/util/utils';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

import { MapControlButtonGroup } from './components/MapControls';
import { MapMarkerComponent } from './components/MapIcon';
import { MapLegend } from './components/MapLegend';
import './style/map-style.css';
import { MapMarker, MapMarkerData } from './types';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN ?? '';

type DefaultZoomOptions = {
  center: mapboxgl.LngLatLike;
  zoom: number;
  pitch: number;
};

const defaultMap =
  getApplicationRuntime() === 'prod'
    ? process.env.REACT_APP_MAPBOX_PROD_URL
    : process.env.REACT_APP_MAPBOX_QA_URL;

export function MapCard() {
  const appcontext: PowerToolApplicationContext | undefined = useAppSelector(
    selectApplicationContext
  );
  const currentMapData = useAppSelector(getMarkerData);
  // const currentMapData: MapMarkerData[] = [{
  //   date: '',
  //   elevFT: 100,
  //   id: 1,
  //   lat: 40.899,
  //   long: -90.396,
  //   precipIN: 0,
  //   tempF: 70,
  //   type: 'auto',
  //   weather: 'Sunny',
  //   windSpeedMPH: '0',
  // }]
  const resizeFlag = useAppSelector(getMapFlag);
  const threeDPitch = 55;
  const satelliteView = useAppSelector(selectSatelliteView);
  const dispatch = useAppDispatch();

  const [is3D, set3D] = useState(
    appcontext === 'train_analysis' ? true : false
  );
  const [isSatView, setIsSatView] = useState(false);
  const [rails, setRails] = useState(true);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [fences, setFences] = useState<boolean>(true);

  const railRef = useRef<boolean>(false);
  const markerData = useRef<MapMarker[]>([]);
  const mapContainer = useRef<HTMLDivElement>(null);
  const map = useRef<mapboxgl.Map>();
  const onLoadMapViewChange = useRef<boolean>(false);

  //constants for clarity
  // const defaultMap = "mapbox://styles/wabtec/cl6pfyote009915qj502ih8uh";

  // our default view of the map, zoomed out
  const defaultZoomOptions: DefaultZoomOptions = useMemo(() => {
    return {
      center: [-0.0635, 28.61],
      zoom: 0.39,
      pitch: is3D ? threeDPitch : 0,
    };
  }, []);

  // our main data structure for placing markers onto the map
  // as the map data changes in our state, the markers
  // are updated to reflect the current map data
  const mapboxMarkers = useMemo(() => {
    const getMarker = (color: string, data: MapMarkerData) => {
      const marker = document.createElement('div');

      // we make sure user placed markers are always on top
      marker.style.zIndex = data.type === 'auto' ? '1' : '2';

      const markerRoot = createRoot(marker);

      markerRoot.render(
        <MapMarkerComponent
          iconProps={{
            strokeWidth: 0.5,
            stroke: 'darkgrey',
            fontSize: 'large',
            style: {
              color: color,
            },
          }}
          markerData={data}
        />
      );

      return marker;
    };

    const generateMapboxMarker = (
      color: string,
      data: MapMarkerData
    ): MapMarker => {
      const mapboxMarker = new mapboxgl.Marker({
        anchor: 'bottom',
        draggable: false,
        element: getMarker(color, data),
      }).setLngLat([data.long, data.lat]);

      // TAT filter views and this feature clash
      if (appcontext !== 'train_analysis') {
        mapboxMarker.getElement().addEventListener('click', () => {
          // this is used to trigger the data grid to move to and highlight
          // this markers node
          dispatch(setFocusedFault(data.id));
        });
      }

      return {
        id: data.id,
        marker: mapboxMarker,
        type: data.type,
      };
    };

    // we color map points light -> dark to represent past -> present
    const autoMarkerColorScale = chroma
      .scale([PowerToolDarkTheme.voltBlue800, PowerToolDarkTheme.voltBlue300])
      .domain([
        0,
        currentMapData.filter((marker) => marker.type === 'auto').length,
      ]);

    // generate automatically placed markers and set the styles
    // limits automatic markers to 50
    const autoMarkers: MapMarker[] = currentMapData
      .filter((marker) => marker.type === 'auto')
      .slice(0, 50)
      .map((marker, index) =>
        generateMapboxMarker(
          index > 0
            ? autoMarkerColorScale(index).hex()
            : PowerToolDarkTheme.statusGreen500,
          marker
        )
      );

    // generate all the user placed markers and set their style
    const userMarkers: MapMarker[] = currentMapData
      .filter((marker) => marker.type === 'manual')
      .map((marker) =>
        generateMapboxMarker(PowerToolDarkTheme.statusYellow500, marker)
      );
    // set all markers
    return [...autoMarkers, ...userMarkers];
  }, [currentMapData, dispatch]);

  // when called, fits the map view to the current value of the mapboxMarkers array
  const mapFlyOver = useCallback(() => {
    if (map.current) {
      const mostRecentManualMarker = mapboxMarkers.find(
        (marker) => marker.type === 'manual'
      );
      //if there is a manual marker, we fly to it
      if (mostRecentManualMarker !== undefined) {
        map.current.flyTo({
          center: [
            mostRecentManualMarker.marker.getLngLat().lng,
            mostRecentManualMarker.marker.getLngLat().lat,
          ],
          zoom: 12,
          duration: 1000,
        });
      }

      // if we have one point, then just zoom to that single point
      else if (mapboxMarkers.length === 1) {
        map.current.jumpTo({
          center: [
            mapboxMarkers[0].marker.getLngLat().lng,
            mapboxMarkers[0].marker.getLngLat().lat,
          ],
          zoom: 12,
        });
      }

      // if we have more than one point, we need to draw a 'box' around all the points,
      // then tell the map to zoom to that area (with some padding)
      else if (mapboxMarkers.length > 0) {
        // draw a line from all the points
        const line = lineString(
          mapboxMarkers.map((marker) => [
            marker.marker.getLngLat().lng,
            marker.marker.getLngLat().lat,
          ])
        );

        // get a bounding box from that line
        const bounds = bbox(line); //bbox returns [[sw corner][ne corner]], fitBounds uses [[ne corner][sw corner]].
        // and fit the bounds
        const mapHeight = mapContainer.current?.clientHeight ?? 250;
        const mapWidth = mapContainer.current?.clientWidth ?? 250;
        map.current.fitBounds([bounds[2], bounds[3], bounds[0], bounds[1]], {
          padding: {
            top: mapHeight / 5,
            bottom: mapHeight / 5,
            left: mapWidth / 5,
            right: mapWidth / 5,
          },
          maxZoom: 12,
          duration: 0,
        });
      }

      // we have no points, restore to default view
      else {
        map.current.jumpTo(defaultZoomOptions);
      }
    }
  }, [mapboxMarkers, defaultZoomOptions]);

  const drawMarkers = useCallback(() => {
    if (map.current !== undefined) {
      // remove our old data
      markerData.current.forEach((oldMarker) => oldMarker.marker.remove());

      // get our generated markers
      const newMarks = mapboxMarkers;

      if (newMarks.length > 0) {
        // place all but start marker (first marker)
        newMarks.slice(1).forEach(
          // safe to force unwrap here, we check map above
          (marker) => marker.marker.addTo(map.current!)
        );

        // place the first and last points so the always render on top 'layer'
        newMarks[0].marker.addTo(map.current);
      }

      // update our reference to the markers
      markerData.current = newMarks;

      // and fly to the markers
      mapFlyOver();
    }
  }, [mapboxMarkers, mapFlyOver]);

  // creates map on mount
  useEffect(() => {
    // initialize map only once
    if (map.current) return;

    // map maker
    if (mapContainer.current) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: defaultMap,
        // resizes the map when the browser window resizes.
        trackResize: true,
        ...defaultZoomOptions,
        //maxZoom: 16,//~1 meter per pixel
      });

      // we only use the compass control offered by mapbox
      // we override the other controls to conform to our look & feel
      map.current.addControl(
        new mapboxgl.NavigationControl({
          showCompass: true,
          visualizePitch: true,
          showZoom: false,
        })
      );
      map.current.on('idle', onMapIdle);
    }
  }, [defaultZoomOptions]);

  useEffect(() => {
    setIsSatView(!satelliteView);
    if (loaded) {
      onLoadMapViewChange.current = true;
      onMapChange();
    }
  }, [satelliteView, loaded]);

  useEffect(() => {
    if (loaded && !onLoadMapViewChange.current) {
      onMapChange();
    }
  }, [loaded]);

  useEffect(() => {
    railRef.current = rails;
  }, [rails]);
  // when the resize flag is changed, we update the map size
  // the resize flag is dispatched from the GridLayout component
  useEffect(() => {
    if (map.current) map.current.resize();
  }, [resizeFlag]);

  // when the load state changes or the marker data changes
  // refresh the markers on the map
  useEffect(() => {
    if (loaded) {
      drawMarkers();
    }
  }, [loaded, drawMarkers]);

  // if 2D is true we set the pitch to 75 otherwise its zero
  useEffect(() => {
    is3D ? map.current?.setPitch(threeDPitch) : map.current?.setPitch(0);
  }, [is3D]);

  // update our state when the map is done loading (idle state)
  const onMapIdle = (
    event: mapboxgl.MapboxEvent<undefined> & mapboxgl.EventData
  ) => {
    //when the map stops moving towards markers
    setLoaded(true);
  };

  const onZoomIn = () => {
    map.current?.zoomIn();
  };

  const onZoomOut = () => {
    map.current?.zoomOut();
  };

  const onPerspectiveChange = () => {
    set3D(!is3D);
  };

  // checks if it's safe to transition then sets the visibility of
  // satellite and dark mode related assets depending on the mapMode state
  const onMapChange = () => {
    if (map.current?.isStyleLoaded()) {
      //prevents unsafe transitions

      if (!isSatView) {
        map?.current.getStyle().layers?.forEach((layer) => {
          if (layer.id.includes('sat_mode')) {
            map?.current?.setLayoutProperty(layer.id, 'visibility', 'visible');
          } else if (layer.id.includes('dark_mode')) {
            map?.current?.setLayoutProperty(layer.id, 'visibility', 'none');
          }
        });
      } else {
        map?.current.getStyle().layers?.forEach((layer) => {
          if (layer.id.includes('sat_mode')) {
            map?.current?.setLayoutProperty(layer.id, 'visibility', 'none');
          } else if (layer.id.includes('dark_mode')) {
            map?.current?.setLayoutProperty(layer.id, 'visibility', 'visible');
          }
        });
      }
      setIsSatView(!isSatView);
    }
  };

  // checks if it's safe to transition then sets the visibility of
  // the rail data based on the rails state
  const onRailToggle = () => {
    if (map.current?.isStyleLoaded()) {
      //prevents unsafe transitions
      if (railRef.current) {
        map?.current.getStyle().layers?.forEach((layer) => {
          if (layer.id.includes('wabtec_rails')) {
            map?.current?.setLayoutProperty(layer.id, 'visibility', 'none');
          }
        });
      } else {
        map?.current.getStyle().layers?.forEach((layer) => {
          if (layer.id.includes('wabtec_rails')) {
            map?.current?.setLayoutProperty(layer.id, 'visibility', 'visible');
          }
        });
      }
      setRails(!railRef.current);
    }
  };
  // checks if it's safe to transition then sets the visibility of
  // the geofence data based on the fences state
  const onFenceToggle = () => {
    if (map.current?.isStyleLoaded()) {
      if (fences) {
        map?.current.getStyle().layers?.forEach((layer) => {
          if (layer.id.includes('wabtec_geofences')) {
            map?.current?.setLayoutProperty(layer.id, 'visibility', 'none');
          }
        });
      } else {
        map?.current.getStyle().layers?.forEach((layer) => {
          if (layer.id.includes('wabtec_geofences')) {
            map?.current?.setLayoutProperty(layer.id, 'visibility', 'visible');
          }
        });
      }
      setFences(!fences);
    }
  };

  const onMarkerClear = () => {
    dispatch(setMarkerData(currentMapData.filter((x) => x.type === 'auto')));
  };

  return (
    <div ref={mapContainer} className='map-container'>
      <MapControlButtonGroup
        onZoomIn={onZoomIn}
        onZoomOut={onZoomOut}
        onReset={drawMarkers}
        onPerspectiveChange={onPerspectiveChange}
        onMapChange={onMapChange}
        onRailToggle={onRailToggle}
        onFenceToggle={onFenceToggle}
        onMarkerClear={onMarkerClear}
        mapMode={isSatView}
        railState={rails}
        fenceState={fences}
        currentPerspective={is3D ? '2D' : '3D'}
      />

      <MapLegend />
    </div>
  );
}

export default MapCard;
