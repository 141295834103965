import React from 'react';

import { useUserFormattedTime } from 'common/hooks';
import { DateFormattingOptions } from 'common/types/types';

// sometimes we want to access a property and format the date while rendering
// for example: list.map(i => <span>{useUserFormattedTime(i)}</span>)
//     is invalid because hooks cannot be called during a callback (map)
//     so instead we can use the wrapper component to format at the top level
export const DateTimeWrapper = ({
  date,
  options,
}: {
  date: string;
  options?: DateFormattingOptions;
}) => {
  const formattedDate = useUserFormattedTime(date, options);

  return <React.Fragment>{formattedDate}</React.Fragment>;
};
