import { Typography } from '@mui/material';
import { useEffect } from 'react';
import { batch } from 'react-redux';

import { LoadingSpinner } from 'common/components/LoadingComponents';
import { useAppDispatch, useAppSelector } from 'common/hooks';
import { setAsset } from 'common/stores/globalSlice';
import { setMarkerData } from 'common/stores/mapSlice';
import { Asset } from 'common/types/types';

import { setAssetAction } from 'power-tool/saga/actionSlice';

import { useFetchTrain } from 'train-tool/hooks/apiHooks';
import { selectTrainSearchItem } from 'train-tool/stores/trainSlice';

import { TrainSummaryComponent } from './components/TrainSummaryComponent';

/**
 * @returns {JSX.Element} card content for the train summary. displays loading spinner while train information is loading
 */
export const TrainCard = () => {
  const dispatch = useAppDispatch();

  const { train, isPlaceholderData } = useFetchTrain();
  const searchItem = useAppSelector(selectTrainSearchItem);

  useEffect(() => {
    if (isPlaceholderData) {
      dispatch(setAssetAction(undefined));
      dispatch(setMarkerData([]));
    }

    // if we got a train back and we have some locomotives
    if (train && train.locomotives.length > 0) {
      // if our original search was an asset, select that asset
      if (searchItem?.type === 'asset') {
        const item = searchItem.item as Asset;
        dispatch(setAssetAction(item));
      }

      // otherwise, we dispatch the first non-forign loco
      // in the train
      else {
        const loco = train.locomotives.find(
          (loco) => !loco.foreign && loco.vehicleObjid > 0
        );
        dispatch(setAssetAction(loco));
      }
    }

    // otherwise we reset
    else {
      batch(() => {
        dispatch(setAssetAction(undefined));
        dispatch(setMarkerData([]));
        dispatch(setAsset(undefined));
      });
    }
  }, [train, isPlaceholderData, searchItem, dispatch]);

  // our loading state is when we don't have a train ID (null)
  // and the data in the hook is placeholder data
  if (train?.trainId === null && isPlaceholderData) {
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <LoadingSpinner />
      </div>
    );
  }

  // if we are looking at a single loco without a train
  if (train?.exists === false) {
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography variant='h6'>Train not found</Typography>
      </div>
    );
  }

  // display the summary component (regardless if dummy data or not)
  else {
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(100% - 10px)',
        }}
      >
        <TrainSummaryComponent />
      </div>
    );
  }
};

export default TrainCard;
