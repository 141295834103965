import { Paper } from '@mui/material';

import { useTimelineStyles } from 'common/features/timeline/hooks/style';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

export const Track = ({ props }: { props: any }) => {
  const { trackStyle } = useTimelineStyles();

  return (
    <div
      {...props}
      style={{
        ...props.style,
        ...trackStyle,
        left: 0,
        width: '100%',
      }}
    >
      <Paper
        elevation={1}
        style={{
          ...trackStyle,
          backgroundColor: PowerToolDarkTheme.neutralDark100,
        }}
      />
    </div>
  );
};
