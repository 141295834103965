import { Refresh } from '@mui/icons-material';
import { IconButton, SxProps, Tooltip, Typography } from '@mui/material';
import { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from 'common/hooks';
import { selectApplicationContext } from 'common/stores/globalSlice';
import {
  selectHardFetchNewData,
  setHardFetchNewData,
} from 'common/stores/ingestedDataFooterSlice';

import { useFetchTrain } from 'train-tool/hooks/apiHooks';

export const RefreshButton = ({ buttonProps }: { buttonProps?: SxProps }) => {
  const dispatch = useAppDispatch();

  const application = useAppSelector(selectApplicationContext);
  const hardFetchStatus = useAppSelector(selectHardFetchNewData);

  const { refetch: refetchTrain } = useFetchTrain();

  const onClick = useCallback(() => {
    switch (application) {
      case 'power_tool':
      case 'virtual_tester':
        dispatch(setHardFetchNewData(true));
        break;

      case 'train_analysis':
        dispatch(setHardFetchNewData(true));
        void refetchTrain();
        break;

      default:
        break;
    }
  }, [application, refetchTrain]);

  return (
    <Tooltip title={<Typography>{'Refresh'}</Typography>}>
      <IconButton
        disabled={hardFetchStatus}
        color='primary'
        size='small'
        onClick={onClick}
        sx={buttonProps}
      >
        <Refresh fontSize='small' />
      </IconButton>
    </Tooltip>
  );
};
