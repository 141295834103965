import { ContentCopy, Subway, Train } from '@mui/icons-material';
import { Paper, SxProps } from '@mui/material';
import chroma from 'chroma-js';
import { useSnackbar } from 'notistack';
import React from 'react';

import { useAppSelector } from 'common/hooks';
import { selectAssetDetails } from 'common/stores/globalSlice';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

import { useFetchTrain } from 'train-tool/hooks/apiHooks';

/**
 *
 * @param props
 * @param props.role
 * @param props.sxProps
 */
export const TrainToolDataCardLabel = (props: {
  role: 'button' | 'label';
  sxProps?: SxProps;
}) => {
  const asset = useAppSelector(selectAssetDetails);

  if (asset) {
    const label = `${asset.vehicleHdr}-${asset.roadNumber}`;

    return (
      <ClipboardLabel
        sxProps={props.sxProps}
        role={props.role}
        clipboardValue={label}
        value={
          <React.Fragment>
            <Train fontSize='small' />
            {label}
          </React.Fragment>
        }
      />
    );
  }

  return <></>;
};

/**
 *
 * @param props
 * @param props.role
 */
export const TrainCardLabel = (props: { role: 'button' | 'label' }) => {
  const { train, isPlaceholderData } = useFetchTrain();

  if (train?.trainId && train.exists && !isPlaceholderData) {
    return (
      <ClipboardLabel
        role={props.role}
        clipboardValue={train.trainId}
        value={
          <React.Fragment>
            <Subway fontSize='small' />
            {train.trainId}
          </React.Fragment>
        }
      />
    );
  }

  return <></>;
};

/**
 *
 * @param props
 * @param props.value
 * @param props.role
 * @param props.clipboardValue
 * @param props.sxProps
 * @param props.onClick
 */
export const ClipboardLabel = (props: {
  value?: JSX.Element | string | number;
  role: 'button' | 'label';
  clipboardValue?: string;
  sxProps?: SxProps;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}) => {
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Paper
      variant='outlined'
      onClick={props.onClick}
      onDrag={undefined}
      sx={{
        '&:hover': {
          backgroundColor:
            props.role === 'button'
              ? chroma(PowerToolDarkTheme.statusGray900).brighten(0.5).hex()
              : PowerToolDarkTheme.statusGray900,
          cursor: props.role === 'button' ? 'pointer' : 'default',
        },
        backgroundColor: PowerToolDarkTheme.statusGray900,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf: 'center',
        px: 1,
        py: 0.5,
        mb: 1,
        marginTop: '5px',
        fontWeight: '600',
        gap: '10px',
        fontSize: '12px',
        cursor: 'default',
        zIndex: 1,
        userSelect: 'none',
        ...props.sxProps,
      }}
    >
      {props.value}

      <ContentCopy
        onClick={() => {
          navigator.clipboard.writeText(
            props.clipboardValue ?? (props.value as string)
          );
          enqueueSnackbar('Copied to clipboard', {
            variant: 'info',
            anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
            autoHideDuration: 1000,
          });
        }}
        style={{
          fontSize: '12px',
          color: PowerToolDarkTheme.neutralDark800,
          cursor: 'pointer',
        }}
      />
    </Paper>
  );
};
