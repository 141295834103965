import { OktaAuth } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { PowerToolRoutes } from 'Routes';
import { LicenseManager } from 'ag-grid-enterprise';
import 'mapbox-gl/dist/mapbox-gl.css';

import config from 'auth/config';
import { useRestoreOriginalUrl } from 'auth/hooks';

const okta = new OktaAuth(config.oidc);

/**
 * @returns {JSX.Element} top level application. this is what react renders into the root of the DOM
 */
export const App = () => {
  const { restoreOriginalUri } = useRestoreOriginalUrl();

  return (
    <Security oktaAuth={okta} restoreOriginalUri={restoreOriginalUri}>
      <PowerToolRoutes />
    </Security>
  );
};

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE ?? '');
