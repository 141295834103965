import { ColDef } from 'ag-grid-community';
import { useMemo } from 'react';

import { useFetchRXEvents } from 'loco-history/api/hooks';
import { RxTitleUrgencyRenderer } from 'loco-history/cards/Timeline/components/events/grids/renderers/RxTitleUrgencyRenderer';
import { StrippedHTMLCellRenderer } from 'loco-history/cards/Timeline/components/events/grids/renderers/StrippedHTMLCellRenderer';
import { createEventTimeColDef } from 'loco-history/cards/Timeline/components/events/utils';
import { RXEvent } from 'loco-history/types';

import { EventsGrid } from './EventsGrid';

/**
 * @returns {JSX.Element} ag-grid wrapper for rx events
 */
export const RxEvents = () => {
  const { rxEvents } = useFetchRXEvents();

  const columns = useMemo(
    (): ColDef[] => [
      {
        field: 'rxCaseId',
        headerName: 'RX Case ID',
        cellRenderer: 'agGroupCellRenderer',
        headerCheckboxSelection: true,
        checkboxSelection: true,
        width: 190,
        cellRendererParams: {
          innerRenderer: RxTitleUrgencyRenderer,
        },
      },
      {
        field: 'title',
        headerName: 'Title',
        width: 500,
      },
      {
        field: 'lineZeroNotes',
        headerName: 'Line Zero Notes',
        suppressSizeToFit: false,
        cellRenderer: StrippedHTMLCellRenderer,
      },
      createEventTimeColDef({
        field: 'rxOpenDate',
        headerName: 'Opened On',
        sort: true,
      }),
      createEventTimeColDef({ field: 'rxCloseDate', headerName: 'Closed On' }),
    ],
    []
  );

  return <EventsGrid<RXEvent> columns={columns} data={rxEvents} />;
};
