import { useTheme } from 'styled-components';
import { v4 as uuid } from 'uuid';

import { ColumnHeaderTooltipProps } from 'common/cards/Data/types';

/**
 *
 * @param {any} props implicit destructured props
 * @param {string | undefined} props.group parameter group that the column belongs to
 * @param {string} props.tooltip tooltip text to display in column header
 */
export const ColumnHeaderTooltip = ({
  group,
  tooltip,
}: ColumnHeaderTooltipProps) => {
  const theme = useTheme();

  // we do special formatting and tokenization for virtual tooltips
  // essentially we display the equation used to calculate the virtual, but 'nicely' formatted
  if (group === 'virtual') {
    // split on tokens wrapped in '$' or '#'
    // we use a capturing group here to preserve our delimeter
    return (
      <span>
        {tooltip.split(/([$#][\s\S]*?[$#])/g).map((token) => {
          // parameters are wrapped in '$', i.e. $Gnd Prop Flt AC Ohm - 6268$
          if (token.includes('$')) {
            return (
              <span style={{ color: theme.dataTeal300 }} key={uuid()}>
                {token.replaceAll('$', '')}
              </span>
            );
          }

          // other virtuals are wrapped in '#', i.e. #V EMRP LTRP#
          else if (token.includes('#')) {
            return (
              <span style={{ color: theme.dataGreen300 }} key={uuid()}>
                {token.replaceAll('#', '')}
              </span>
            );
          }

          // otherwise, the token is part of the virtual calculation
          return (
            <span
              style={{ fontFamily: 'monospace', fontWeight: 'bold' }}
              key={uuid()}
            >{` ${token.trim()} `}</span>
          );
        })}{' '}
      </span>
    );
  }

  // otherwise, its just a normal tooltip, and return the text
  else {
    return <span style={{ whiteSpace: 'pre-line' }}>{tooltip}</span>;
  }
};
