import { ColDef } from 'ag-grid-community';
import { useMemo } from 'react';

import { useFetchDefectEvents } from 'loco-history/api/hooks';
import { createEventTimeColDef } from 'loco-history/cards/Timeline/components/events/utils';
import { DefectEvent } from 'loco-history/types';

import { EventsGrid } from './EventsGrid';

/**
 * @returns {JSX.Element} ag-grid wrapper for eservice defect events
 */
export const DefectEvents = () => {
  const { defectEvents } = useFetchDefectEvents();
  const columns = useMemo(
    (): ColDef[] => [
      {
        field: 'woNumber',
        headerName: 'Work Order Number',
        cellRenderer: 'agGroupCellRenderer',
        headerCheckboxSelection: true,
        checkboxSelection: true,
        width: 190,
      },
      {
        field: 'defectComments',
        headerName: 'Defect Comments',
        suppressSizeToFit: false,
      },
      {
        field: 'repairComments',
        headerName: 'Repair Comments',
        suppressSizeToFit: false,
      },
      createEventTimeColDef({
        field: 'creationDate',
        headerName: 'Creation Date',
        sort: true,
      }),
      createEventTimeColDef({
        field: 'lastUpdateDate',
        headerName: 'Last Updated',
      }),
    ],
    []
  );

  return <EventsGrid<DefectEvent> data={defectEvents} columns={columns} />;
};
