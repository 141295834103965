// example taken from
// https://mui.com/material-ui/react-transfer-list/
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
} from '@mui/icons-material';
import { ListSubheader } from '@mui/material';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import { Container, SxProps } from '@mui/system';
import * as React from 'react';
import { useEffect } from 'react';

import { AxisConfiguration } from 'common/features/plot/types';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

function not(a: readonly AxisConfiguration[], b: readonly AxisConfiguration[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(
  a: readonly AxisConfiguration[],
  b: readonly AxisConfiguration[]
) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

export const TransferList = ({
  sx,
  configs,
  onUpdate,
}: {
  sx?: SxProps;
  configs: AxisConfiguration[];
  onUpdate: (configs: AxisConfiguration[]) => void;
}) => {
  const [checked, setChecked] = React.useState<readonly AxisConfiguration[]>(
    []
  );

  const [left, setLeft] = React.useState<readonly AxisConfiguration[]>(
    configs.filter((c) => c.axis === 'L')
  );
  const [right, setRight] = React.useState<readonly AxisConfiguration[]>(
    configs.filter((c) => c.axis === 'R')
  );

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value: AxisConfiguration) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  useEffect(() => {
    const newConfigs: AxisConfiguration[] = [];

    left.forEach((config) => newConfigs.push({ ...config, axis: 'L' }));
    right.forEach((config) => newConfigs.push({ ...config, axis: 'R' }));

    onUpdate(newConfigs);
  }, [left, right]);

  const CustomList = ({
    items,
    label,
  }: {
    items: readonly AxisConfiguration[];
    label: string;
  }) => (
    <Paper
      sx={{
        width: 400,
        overflow: 'auto',
        height: '300px',
        backgroundColor: PowerToolDarkTheme.neutralDark200,
      }}
    >
      <List dense component='div' role='list' sx={{ p: 0 }}>
        <ListSubheader
          sx={{
            backgroundColor: PowerToolDarkTheme.neutralDark100,
            lineHeight: '36px',
          }}
        >
          {label}
        </ListSubheader>
        {items.map((value: AxisConfiguration, i: number) => {
          return (
            <ListItem
              key={i}
              role='listitem'
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  size='small'
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText id={i.toString()} primary={value.parameter} />
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );

  return (
    <Container sx={sx}>
      <Grid container spacing={2} justifyContent='center' alignItems='center'>
        <Grid item>{<CustomList label='Left Axis' items={left} />}</Grid>
        <Grid item>
          <Grid container direction='column' alignItems='center'>
            <Button
              sx={{ my: 0.5 }}
              variant='outlined'
              size='small'
              onClick={handleAllRight}
              disabled={left.length === 0}
            >
              <KeyboardDoubleArrowRight />
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant='outlined'
              size='small'
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
            >
              <KeyboardArrowRight />
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant='outlined'
              size='small'
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
            >
              <KeyboardArrowLeft />
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant='outlined'
              size='small'
              onClick={handleAllLeft}
              disabled={right.length === 0}
            >
              <KeyboardDoubleArrowLeft />
            </Button>
          </Grid>
        </Grid>
        <Grid item>{<CustomList label='Right Axis' items={right} />}</Grid>
      </Grid>
    </Container>
  );
};
