import { MoreVert } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Menu,
  Slider,
  Typography,
} from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';

import { DateTimeWrapper } from 'common/components/DateTimeWrapper';
import { GenericTimeline } from 'common/features/timeline/GenericTimeline';
import { useAppSelector } from 'common/hooks';
import {
  selectTimelineEvents,
  selectTimelineNow,
} from 'common/stores/timelineSlice';
import { daysToMinutes } from 'common/util/time';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

import { TimelineEventToPropsMap } from 'loco-history/config';

const MIN_TIME = 720; // -daysToMinutes(NUM_DAYS)
const MAX_TIME = 0;
const STEP = 1;

// https://zillow.github.io/react-slider/
// https://github.com/react-component/slider
/**
 * @returns {JSX.Element} slider component that represents time
 */
export const EventTimeline = () => {
  const now = useAppSelector(selectTimelineNow);
  const events = useAppSelector(selectTimelineEvents);

  // number of days our timeline represents
  const [min] = useState<number>(-daysToMinutes(MIN_TIME));
  const [max] = useState<number>(MAX_TIME);

  const [range, setRange] = useState<number[]>([-daysToMinutes(182), MAX_TIME]);

  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [combinedView, setCombinedView] = useState<boolean>(false);
  const [iconView, setIconView] = useState<boolean>(true);

  const menuOpen = Boolean(menuAnchor);

  const calculateDelta = useCallback(
    (newRange: number[]) => {
      const d0 = newRange[0] - range[0];
      const d1 = newRange[1] - range[1];
      return d0 === 0 ? d1 : d0;
    },
    [range]
  );

  const sliderMarks = useMemo(() => {
    return [
      {
        value: 0,
        label: 'Now',
      },
      {
        value: -daysToMinutes(90),
        label: '3 Mo.',
      },
      {
        value: -daysToMinutes(182),
        label: '6 Mo.',
      },
      {
        value: -daysToMinutes(365),
        label: '1 Year',
      },
      {
        value: -daysToMinutes(720),
        label: '2 Years',
      },
    ];
  }, []);

  const timelineCategories = useMemo(() => {
    return [
      ...new Set(
        events.filter((e) => e.type !== 'write-in').map((e) => e.type)
      ),
    ];
  }, [events]);

  return (
    <React.Fragment>
      {/* event updates and gathering performed in this headless component */}
      {/* <TimelineProvider /> */}

      <div
        style={{
          paddingLeft: '40px',
          paddingRight: '40px',
          paddingBottom: '10px',
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            alignContent: 'center',
            paddingBottom: '5px',
            marginBottom: '10px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexGrow: 3,
              justifyContent: 'center',
              flex: '1 0 0',
            }}
          ></Box>

          <Box sx={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
            <Typography
              variant='button'
              whiteSpace={'nowrap'}
              sx={{ minWidth: '150px' }}
            >
              <DateTimeWrapper
                date={now.plus({ minutes: range[0] }).toISO()}
                options={{ pretty: true }}
              />
            </Typography>

            <Slider
              disableSwap
              min={min}
              max={max}
              value={range}
              marks={sliderMarks}
              size='small'
              sx={{ minWidth: '150px' }}
              // https://github.com/mui/material-ui/issues/17228
              onChange={(event, value) => {
                const newValues = value as number[];

                const delta = calculateDelta(newValues);

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const { shiftKey } = event;
                if (shiftKey) {
                  setRange((old) => old.map((v) => v + delta));
                } else {
                  setRange(newValues);
                }
              }}
            />

            <Typography
              variant='button'
              whiteSpace={'nowrap'}
              display={'flex'}
              justifyContent={'flex-end'}
              sx={{ minWidth: '150px' }}
            >
              <DateTimeWrapper
                date={now.plus({ minutes: range[1] }).toISO()}
                options={{ pretty: true }}
              />
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', flexGrow: 3, flex: '1 0 0' }} />

          <div
            style={{
              position: 'absolute',
              right: '5px',
              top: '35px',
            }}
          >
            <IconButton
              aria-label='more'
              id='long-button'
              aria-haspopup='true'
              onClick={(event) => setMenuAnchor(event.currentTarget)}
            >
              <MoreVert />
            </IconButton>
            <Menu
              disableScrollLock
              anchorEl={menuAnchor}
              open={menuOpen}
              onClose={() => setMenuAnchor(null)}
              PaperProps={{
                elevation: 3,
                style: {
                  backgroundColor: PowerToolDarkTheme.main,
                },
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', pl: 2 }}>
                <FormGroup>
                  <FormControlLabel
                    label='Combined Timeline'
                    sx={{ whiteSpace: 'nowrap' }}
                    control={
                      <Checkbox
                        checked={combinedView}
                        onChange={(event) =>
                          setCombinedView(event.target.checked)
                        }
                        size='small'
                      />
                    }
                  />

                  <FormControlLabel
                    label='Show Icons'
                    control={
                      <Checkbox
                        checked={iconView}
                        onChange={(event) => setIconView(event.target.checked)}
                        size='small'
                      />
                    }
                  />
                </FormGroup>
              </Box>
            </Menu>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
          }}
        >
          {combinedView ? (
            <GenericTimeline
              label={'Combined'}
              min={range[0]}
              max={range[1]}
              step={STEP}
              iconView={iconView}
              key={'combined'}
            />
          ) : (
            timelineCategories.map((category) => (
              <GenericTimeline
                label={TimelineEventToPropsMap[category].label}
                min={range[0]}
                max={range[1]}
                step={STEP}
                iconView={iconView}
                type={category}
                key={category}
              />
            ))
          )}

          <GenericTimeline
            label={'Time'}
            type={'time'}
            min={range[0]}
            max={range[1]}
            step={STEP}
            iconView={false}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
