import { Chip, Divider, Tooltip } from '@mui/material';
import chroma from 'chroma-js';
import React from 'react';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

export const AssetCardItem = (props: {
  label: string;
  info: JSX.Element | string;
  style?: React.CSSProperties;
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <div
        style={{
          marginLeft: '4px',
          marginRight: '4px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <span style={{ fontWeight: 'bold', ...props.style }}>
          {props.label}
        </span>
        <span>{props.info}</span>
      </div>

      <Divider orientation='horizontal' flexItem />
    </div>
  );
};

export const HealthChip = (props: { risk: string; score: number }) => {
  const colorScale = chroma
    .scale([
      PowerToolDarkTheme.statusRed700,
      PowerToolDarkTheme.statusRed500,
      PowerToolDarkTheme.statusYellow600,
      PowerToolDarkTheme.statusGreen500,
    ])
    .domain([0, 30, 80, 90]);
  return (
    <Tooltip title={props.risk} placement='left'>
      <Chip
        sx={{ fontSize: 11, fontWeight: 'bold', mb: '2px' }}
        label={props.score}
        size='small'
        style={{ backgroundColor: colorScale(props.score).css('hsl') }}
      />
    </Tooltip>
  );
};
