import { CellTower, DoDisturb, Train } from '@mui/icons-material';
import { Badge, Tooltip } from '@mui/material';
import { useMemo } from 'react';

import { useAppSelector } from 'common/hooks';
import { selectCustomer } from 'common/stores/globalSlice';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

import { TrainAsset } from 'train-tool/types';

/**
 *
 * @param {any} props implicit destructured props
 * @param {JSX.Element?} props.value badge content value
 * @param {() => unknown?} props.onClick callback function for when icon is clicked
 * @param {boolean?} props.disabled flag to disable interaction with icon
 * @param {JSX.Element?} props.badgeTooltip tooltip component for hover on badge
 * @param {JSX.Element?} props.iconTooltip tooltip component for hover on icon
 * @param {string?} props.badgeColor color hex value for the badge
 * @param {string?} props.color icon color representing lead
 * @param {boolean?} props.leadRemote flag to style icon if loco is lead remote
 * @param {'small' | 'default'?} props.size general size of the component
 * @param {'dot' | 'standard'} props.variant variant of the badge; dot is used when we want to hide the health score value
 * @returns {JSX.Element} single wrapped loco icon containing the badge value and correct styling
 */
export const BadgedTrainIcon = (props: {
  value?: JSX.Element;
  onClick?: () => unknown;
  disabled?: boolean;
  badgeTooltip?: JSX.Element;
  iconTooltip?: JSX.Element;
  badgeColor?: string;
  color?: string;
  leadRemote?: boolean;
  size?: 'small' | 'default';
  variant: 'dot' | 'standard';
}) => {
  const customer = useAppSelector(selectCustomer);

  const icon = useMemo(() => {
    return (
      <div>
        {props.leadRemote ? (
          <Tooltip title={'Lead Remote'}>
            <CellTower
              sx={{
                position: 'absolute',
                left: 0,
                top: 0,
                fontSize: '16px',
                color: PowerToolDarkTheme.voltBlue300,
              }}
            />
          </Tooltip>
        ) : (
          <></>
        )}
        <Train
          onClick={props.disabled ? undefined : props.onClick}
          sx={{
            ml: props.leadRemote ? 2 : 0,
            cursor: props.disabled ? 'not-allowed' : 'pointer',
            opacity: props.disabled ? 0.4 : 1,
            color: props.color ?? 'white',
            fontSize: props?.size === 'small' ? '26px' : '32px',
          }}
        />
      </div>
    );
  }, [props]);

  const tooltippedIcon = useMemo(() => {
    if (props.iconTooltip) {
      return <Tooltip title={props.iconTooltip}>{icon}</Tooltip>;
    }

    return icon;
  }, [props.iconTooltip, icon]);

  /**
   * @returns {() => JSX.Element} function to create a custom disabled badge component with icon
   */
  const disabledBadge = () => <DoDisturb sx={{ fontSize: '15px' }} />;

  // CN does not get heath scores
  if (customer === 'CN') {
    return tooltippedIcon;
  }

  return (
    <Badge
      variant={props.variant}
      max={100}
      badgeContent={props.value}
      components={props.disabled ? { Badge: disabledBadge } : {}}
      sx={{
        '& .MuiBadge-badge': { backgroundColor: props.badgeColor ?? 'inherit' },
      }}
    >
      {tooltippedIcon}
    </Badge>
  );
};

/**
 *
 * @param {any} props implicit props
 * @param {TrainAsset} props.asset asset to display health score for
 * @returns {JSX.Element} tooltip wrapped health score badge content representing the health score of the locomotive
 */
export const HealthScoreBadgeValue = (props: { asset: TrainAsset }) => {
  const tooltipTitle = useMemo(() => {
    if (props.asset.health !== undefined && props.asset.health !== null) {
      return (
        <div>
          <div style={{ marginBottom: '3px' }}>
            <span style={{ fontWeight: 600 }}>Risk: </span>
            {props.asset.health.risk}
          </div>

          <div>
            <span style={{ fontWeight: 600 }}>Checks: </span>
            {props.asset.health.performanceChecks ?? 'None'}
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <span>No IPA information available</span>
        </div>
      );
    }
  }, [props.asset.health]);

  return (
    <Tooltip title={tooltipTitle}>
      {props.asset.health ? (
        <span style={{ fontSize: '10px' }}>{props.asset.health.health}</span>
      ) : (
        <span>N/A</span>
      )}
    </Tooltip>
  );
};
