import { ColDef } from 'ag-grid-community';
import { useMemo } from 'react';

import { useFetchProgramEvents } from 'loco-history/api/hooks';
import { createEventTimeColDef } from 'loco-history/cards/Timeline/components/events/utils';
import { ProgramEvent } from 'loco-history/types';

import { EventsGrid } from './EventsGrid';

/**
 * @returns {JSX.Element} ag-grid wrapper for locomotive call log events
 */
export const ProgramEvents = () => {
  const { programEvents } = useFetchProgramEvents();

  const columns = useMemo(
    (): ColDef[] => [
      {
        field: 'program',
        headerName: 'Program',
        cellRenderer: 'agGroupCellRenderer',
        headerCheckboxSelection: true,
        checkboxSelection: true,
        width: 250,
      },
      {
        field: 'profileStatus',
        headerName: 'Status',
        width: 90,
      },
      {
        field: 'title',
        headerName: 'Title',
        width: 400,
      },
      {
        field: 'workorder',
        headerName: 'Workorder',
        width: 140,
      },
      {
        field: 'description',
        headerName: 'Description',
        width: 500,
      },
      {
        field: 'org',
        headerName: 'Org',
        width: 100,
      },
      {
        field: 'softwareCode',
        headerName: 'SW Code',
        width: 95,
      },
      {
        field: 'profileName',
        headerName: 'Profile Name',
        width: 300,
      },
      createEventTimeColDef({
        field: 'completionDate',
        headerName: 'Completion Date',
        sort: true,
      }),
    ],
    []
  );

  return <EventsGrid<ProgramEvent> data={programEvents} columns={columns} />;
};
