import { useMemo, useState } from 'react';

import { useFetchFaultById } from 'common/features/datapack/hooks';
import { PlotPoint, BasePlot } from 'common/features/plot/BasePlot';
import { useAppSelector, useUserFormattedTime } from 'common/hooks';
import { selectPlottedDatapackParameters } from 'common/stores/datpacksSlice';
import { selectAssetDetails } from 'common/stores/globalSlice';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

export const DatapackPlot = () => {
  const { fault } = useFetchFaultById();

  const asset = useAppSelector(selectAssetDetails);
  const plottedData = useAppSelector(selectPlottedDatapackParameters);
  const formattedOccurDate = useUserFormattedTime(fault?.occurDate);

  const [mode, setMode] = useState<'time' | 'continuous'>('continuous');

  const datasets = useMemo((): Record<string, PlotPoint[]> => {
    let data: Record<string, PlotPoint[]> = {};

    // for each parameter that has been selected
    Object.entries(plottedData).forEach(([parameter, values]) => {
      // sort the values first before giving it to the plot
      const sortedValues = [...values].sort((a, b) =>
        a.timestamp < b.timestamp ? -1 : a.timestamp > b.timestamp ? 1 : 0
      );

      // then return point objects
      const dataset = sortedValues.map((sample) => {
        return {
          index: sample.sampleNo,
          timestamp: sample.timestamp,
          value: sample.value,
        };
      });

      data = {
        ...data,
        [parameter]: dataset,
      };
    });

    return data;
  }, [plottedData]);

  return (
    <BasePlot
      options={{
        decimation: true,
        tooltipTitle: mode === 'continuous' ? 'Sample No' : 'Occur Date',
        tooltipFooter: `Fault Occur Date: ${formattedOccurDate}`,
        onModeChange: (mode) => setMode(mode),
        export: {
          enabled: true,
          filename: asset
            ? `${asset.vehicleHdr}-${asset.roadNumber} Datapack Chart`
            : 'Datapack Chart',
        },
      }}
      datasets={datasets}
      defaultContinuous={true}
      annotations={{
        annotations: {
          datapack: {
            type: 'line',
            scaleID: 'x',
            value: mode === 'continuous' ? -0.5 : fault?.occurDate,
            borderWidth: 2,
            borderColor: PowerToolDarkTheme.neutralDark800,
            borderDash: [8, 8],
            label: {
              position: 'start',
              borderWidth: 1,
              xAdjust: -175,
              yAdjust: 35,
              display: true,
              content: [
                `${fault?.faultCode ?? ''}: ${fault?.faultDesc ?? ''}`,
                formattedOccurDate ?? '',
              ],
              callout: {
                borderColor: PowerToolDarkTheme.neutralDark900,
                display: true,
                borderWidth: 2,
                margin: 0,
              },
            },
          },
        },
      }}
    />
  );
};
