import { ColDef } from 'ag-grid-community';
import { useMemo } from 'react';

import { useFetchShopEvents } from 'loco-history/api/hooks';
import { ShopReasonCodeCellRenderer } from 'loco-history/cards/Timeline/components/events/grids/renderers/ShopReasonCodeCellRenderer';
import { createEventTimeColDef } from 'loco-history/cards/Timeline/components/events/utils';
import { ShopEvent } from 'loco-history/types';

import { EventsGrid } from './EventsGrid';

/**
 * @returns {JSX.Element} ag-grid wrapper for locomotive shopping events
 */
export const ShopEvents = () => {
  const { shopEvents } = useFetchShopEvents();

  const columns = useMemo(
    (): ColDef[] => [
      {
        field: 'workorder',
        headerName: 'Workorder Number',
        cellRenderer: 'agGroupCellRenderer',
        headerCheckboxSelection: true,
        checkboxSelection: true,
        width: 190,
      },
      {
        field: 'shop',
        headerName: 'Shop',
        width: 175,
      },
      {
        field: 'inshopReason',
        headerName: 'Reason',
        width: 80,
        cellRenderer: ShopReasonCodeCellRenderer,
      },
      createEventTimeColDef({
        field: 'inshopDate',
        headerName: 'Inshop',
        sort: true,
      }),
      {
        field: 'comments',
        headerName: 'Comments',
        suppressSizeToFit: false,
      },
    ],
    []
  );

  return <EventsGrid<ShopEvent> columns={columns} data={shopEvents} />;
};
