/**
 *
 * @param {React.SVGProps<SVGSVGElement>} props additional SVG display props
 * @returns {SVGElement} icon for representing if a locomotive has high adhesion trucks
 */
export const HighAdhesionIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width ?? 48}
      height={props.height ?? 24}
      id='Layer_2'
      data-name='Layer 2'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 163.18 76.56'
    >
      <defs>
        <style>
          {`.cls-1 {
            fill: none;
            stroke-width: 3px;
          }

          .cls-1, .cls-2 {
            stroke: ${props.fill ?? 'none'};
            stroke-miterlimit: 10;
          }

          .cls-2 {
            fill: ${props.fill ?? 'none'};
            stroke-width: 2px;
          }`}
        </style>
      </defs>
      <g id='Layer_10' data-name='Layer 10'>
        <line className='cls-2' x1='26.75' y1='23.37' x2='26.75' />
        <polyline
          className='cls-2'
          points='20.9 16.79 26.75 23.37 32.6 16.79'
        />
        <line className='cls-2' x1='136.57' y1='23.37' x2='136.57' />
        <polyline
          className='cls-2'
          points='130.71 16.79 136.57 23.37 142.42 16.79'
        />
        <line className='cls-2' x1='26.75' y1='23.37' x2='26.75' />
        <polyline
          className='cls-2'
          points='20.9 16.79 26.75 23.37 32.6 16.79'
        />
        <line className='cls-2' x1='26.75' y1='23.37' x2='26.75' />
        <polyline
          className='cls-2'
          points='20.9 16.79 26.75 23.37 32.6 16.79'
        />
        <line className='cls-2' x1='81.59' y1='23.37' x2='81.59' />
        <polyline
          className='cls-2'
          points='75.74 16.79 81.59 23.37 87.44 16.79'
        />
      </g>
      <g id='Layer_9' data-name='Layer 9' fill={props.fill}>
        <polygon
          className='cls-1'
          points='161.68 43.22 161.68 57.7 1.5 57.7 1.5 43.33 4.16 41.38 17.48 31.62 35.99 31.62 49.3 41.38 59.1 41.38 72.42 31.62 90.92 31.62 104.24 41.38 114.03 41.38 127.35 31.62 145.86 31.62 159.17 41.38 161.68 43.22'
        />
        <path d='M43.96,61.82c-1.29,8.34-8.51,14.74-17.21,14.74s-15.92-6.4-17.21-14.74H43.96Z' />
        <path d='M98.8,61.82c-1.29,8.34-8.51,14.74-17.21,14.74s-15.92-6.4-17.21-14.74h34.42Z' />
        <path d='M153.78,61.82c-1.29,8.34-8.51,14.74-17.21,14.74s-15.92-6.4-17.21-14.74h34.42Z' />
      </g>
    </svg>
  );
};
