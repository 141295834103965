/* eslint-disable @typescript-eslint/no-unsafe-member-access */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
// aeris SDK kinda stinks so we need to assume any types and assign them
import ApiResult from '@aerisweather/javascript-sdk/dist/network/api/ApiResult';
import { NearMe } from '@mui/icons-material';
import { Paper, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import {
  useGetGPSColumns,
  useIsValidGPSLocation,
} from 'common/cards/Data/hooks';
import {
  selectIngestedData,
  selectIngestedDataStatus,
} from 'common/features/ingested-data/store';
import { useAppDispatch, useAppSelector } from 'common/hooks';
import { aeris } from 'common/services';
import { selectApplicationContext } from 'common/stores/globalSlice';
import { setMarkerData } from 'common/stores/mapSlice';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

export type CurrentWeatherProps = {
  city: string;
  state: string;
  temp: number;
  weather: string;
  icon: string;
};
/**
 * @returns {JSX.Element} returns current weather and location component, selects most recent fault from ingested data
 */
export const CurrentWeatherComponent = () => {
  const dispatch = useAppDispatch();

  const data = useAppSelector(selectIngestedData);
  const status = useAppSelector(selectIngestedDataStatus);
  const application = useAppSelector(selectApplicationContext);

  const { gpsColumns } = useGetGPSColumns();
  const { isValidLocation } = useIsValidGPSLocation();

  const [conditions, setConditions] = useState<
    CurrentWeatherProps | undefined
  >();

  const fault = useMemo(() => {
    if (status === 'complete' && data && data.length > 0) {
      return [...data]
        .sort((a, b) =>
          a.OCCUR_DATE < b.OCCUR_DATE ? 1 : a.OCCUR_DATE > b.OCCUR_DATE ? -1 : 0
        )
        .find((row) => isValidLocation(row));
    }
    return undefined;
  }, [data, status, isValidLocation]);

  useEffect(() => {
    if (fault && gpsColumns) {
      void aeris
        .api()
        .endpoint('conditions')
        .place(`${fault[gpsColumns.latitude]},${fault[gpsColumns.longitude]}`)
        .from(fault.OCCUR_DATE)
        .get()
        .then((response: ApiResult) => {
          if (response.error || response.data.length < 1) {
            setConditions(undefined);
          } else {
            const weather = response.data[0];
            setConditions({
              city: weather.place.name,
              state: weather.place.state,
              temp: weather.periods[0].tempF,
              weather: weather.periods[0].weatherPrimary,
              icon: weather.periods[0].icon,
            });
            dispatch(
              setMarkerData([
                {
                  id: fault.OBJID,
                  lat: fault[gpsColumns.latitude],
                  long: fault[gpsColumns.longitude],
                  date: fault.OCCUR_DATE,
                  type: 'auto',
                  precipIN: weather.periods[0].precipIN,
                  state: weather.place.state,
                  name: weather.place.name,
                  elevFT: weather.profile.elevFT,
                  weather: weather.periods[0].weatherPrimary,
                  tempF: weather.periods[0].tempF,
                  windSpeedMPH: weather.periods[0].windSpeedMPH,
                },
              ])
            );
          }
        });
    } else {
      setConditions(undefined);
      dispatch(setMarkerData([]));
    }
  }, [fault, gpsColumns, dispatch]);

  if (application !== 'train_analysis') {
    return <></>;
  }

  if (conditions === undefined) {
    return <></>;
  }

  return (
    <Paper
      sx={{
        backgroundColor: PowerToolDarkTheme.neutralDark200,
        px: 1,
        py: 0.5,
        m: 0.5,
      }}
    >
      <Stack direction={'row'} spacing={2}>
        <Stack direction={'row'} alignItems='center' spacing={0.5}>
          <NearMe
            fontSize='small'
            sx={{ color: PowerToolDarkTheme.voltBlue300 }}
          />
          <Typography>
            {`${_.startCase(conditions?.city) ?? 'Unknown'}, ${
              conditions?.state.toUpperCase() ?? 'Unknown'
            }`}
          </Typography>
        </Stack>

        <Stack direction={'row'} alignItems='center' spacing={0.5}>
          {conditions.icon ? (
            <img
              src={require(`common/assets/weather/${conditions.icon}`)}
              alt={conditions?.icon.replaceAll('.png', '')}
              height={'28px'}
              width={'28px'}
            />
          ) : (
            <></>
          )}
          <Typography fontWeight={'bold'}>
            {`${
              conditions?.temp
                ? `${Math.round(conditions.temp)} ${String.fromCharCode(176)}F`
                : 'Unknown'
            }`}
          </Typography>
        </Stack>
      </Stack>
    </Paper>
  );
};
