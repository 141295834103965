import { ColDef, GetRowIdParams } from 'ag-grid-community';
import { useMemo } from 'react';

import { calculateHeaderWidth } from 'common/cards/Data/utils';
import { LoadingSpinnerOverlayLoading } from 'common/components/LoadingComponents';
import { AgGridCustom } from 'common/components/ag-grid/DensePowerToolGrid';
import { DatapackGridHeader } from 'common/features/datapack/components/DatapackGridHeader';
import { useFetchDatapack } from 'common/features/datapack/hooks';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

export const DatapackDataGrid = () => {
  const { datapack } = useFetchDatapack();

  const findLargestDatapackData = (parmLoadColumn: string) => {
    let largestData = '';
    datapack?.data.forEach((node) => {
      //type checking to ensure the value is a string, then checking the length
      if (
        typeof node[parmLoadColumn] === 'string' &&
        (node[parmLoadColumn] as string).length > largestData.length
      ) {
        //storing the value
        largestData = node[parmLoadColumn] as string;
      }
    });
    return largestData;
  };

  const columns: ColDef[] = useMemo(() => {
    if (datapack) {
      return [
        {
          field: 'INCIDENT_TIMESTAMP',
          headerName: 'Incident Timestamp',
          filter: 'agDateColumnFilter',
          sortable: true,
          menuTabs: ['filterMenuTab', 'generalMenuTab'],
          width: 120,
          resizable: true,
          wrapHeaderText: true,
          autoHeaderHeight: true,
          pinned: true,
        },
        {
          field: 'SAMPLE_NO',
          headerName: 'No.',
          width: 55,
          filter: 'agNumberColumnFilter',
          menuTabs: ['filterMenuTab', 'generalMenuTab'],
          sortable: true,
          resizable: true,
          wrapHeaderText: true,
          autoHeaderHeight: true,
          pinned: true,
        },
        ...datapack.columns.map(
          (col): ColDef => ({
            field: col.parmLoadColumn,
            headerName: col.ruleDescription,
            width: calculateHeaderWidth({
              columnName: col.ruleDescription,
              extraPixels: 32,
              minimumWidth: 0,
              dataExample: findLargestDatapackData(col.parmLoadColumn),
            }),
            filter: 'agNumberColumnFilter',
            menuTabs: ['filterMenuTab', 'generalMenuTab'],
            sortable: true,
            resizable: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
          })
        ),
      ];
    }
    return [];
  }, [datapack]);

  return (
    <AgGridCustom
      components={{
        agColumnHeader: DatapackGridHeader,
      }}
      enableRangeSelection={true}
      data={datapack?.data}
      onRowDataUpdated={(params) => {
        const nodeToCenter = params.api.getRowNode('-0.5');
        if (nodeToCenter) {
          params.api.ensureNodeVisible(nodeToCenter, 'middle');
        }
      }}
      columns={columns ? columns : []}
      headerHeight={96}
      sideBar={{
        defaultToolPanel: 'columns',
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressRowGroups: true,
              suppressValues: true,
              suppressPivotMode: true,
            },
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
      }}
      loadingOverlayComponent={LoadingSpinnerOverlayLoading}
      getRowStyle={(params) => {
        if (params.data['SAMPLE_NO'] === '-0.5') {
          return {
            fontWeight: 800,
            backgroundColor: PowerToolDarkTheme.purpleAccent,
          };
        }
      }}
      getRowId={(params: GetRowIdParams) => {
        return params.data['SAMPLE_NO'];
      }}
    />
  );
};
