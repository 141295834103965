import { Warning } from '@mui/icons-material';
import { Stack } from '@mui/system';
import { ICellRendererParams } from 'ag-grid-community';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

import { ShopEvent } from 'loco-history/types';

/**
 *
 * @param {ICellRendererParams<ShopEvent>} params ag-grid cell renderer params
 * @returns {JSX.Element} ag-grid cell renderer to add icon to shop in reason code
 */
export const ShopReasonCodeCellRenderer = (
  params: ICellRendererParams<ShopEvent>
) => {
  if (['FL'].includes(params.value as string)) {
    return (
      <Stack
        direction={'row'}
        spacing={1}
        display={'flex'}
        alignItems={'center'}
      >
        <div>{params.value}</div>
        <Warning
          sx={{ color: PowerToolDarkTheme.statusRed500, fontSize: '16px' }}
        />
      </Stack>
    );
  }

  return <span>{params.value}</span>;
};
