import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  DatapackPlotValue,
  FetchDatapackInputs,
} from 'common/features/datapack/types';
import { RootState } from 'common/store';

export type DataPackState = {
  datapackFaultObjid?: string;
  // datapackIngestedDataInputs?: FetchDatapackInputs;
  datapackTemplate?: string;

  // plotting state
  plotData: Record<string, DatapackPlotValue[]>;
  plottedRange?: string[];
};

const initialState: DataPackState = {
  plotData: {},
};

export const datapacksSlice = createSlice({
  name: 'datapacks',
  initialState,
  reducers: {
    // setDatapackInputs: (state, action: PayloadAction<FetchDatapackInputs>) => {
    //   state.datapackIngestedDataInputs = action.payload;
    // },
    setDatapackFaultObjid: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.datapackFaultObjid = action.payload;
    },
    setDatapackTemplate: (state, action: PayloadAction<string | undefined>) => {
      state.datapackTemplate = action.payload;
    },
    addPlotItem: (
      state,
      action: PayloadAction<{ column: string; data: DatapackPlotValue[] }>
    ) => {
      state.plotData = {
        ...state.plotData,
        [action.payload.column]: action.payload.data,
      };
    },
    removePlotItem: (state, action: PayloadAction<string>) => {
      state.plotData = Object.fromEntries(
        Object.entries(state.plotData).filter(([sampleNo]) => {
          return sampleNo !== action.payload;
        })
      );
    },
  },
});

export const {
  setDatapackFaultObjid,
  // setDatapackInputs,
  setDatapackTemplate,
  addPlotItem: plotDatapackParameter,
  removePlotItem: removeDatapackParameter,
} = datapacksSlice.actions;

export const selectDatapackFaultObjid = (state: RootState) =>
  state.datapacks.datapackFaultObjid;
// export const selectDatapackIngestedDataInputs = (state: RootState) =>
// state.datapacks.datapackIngestedDataInputs;
export const selectDatapackTemplate = (state: RootState) =>
  state.datapacks.datapackTemplate;
export const selectPlottedDatapackParameters = (state: RootState) =>
  state.datapacks.plotData;
export default datapacksSlice.reducer;
