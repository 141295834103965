import { useMemo, CSSProperties, useCallback } from 'react';

import { LocoStatusDetails, TrainAsset } from 'train-tool/types';

/**
 * @returns {CSSProperties} style for a row in the train details table
 */
export const useRowProps = () => {
  return useMemo(() => {
    return {
      height: '20px',
      display: 'flex',
      alignItems: 'center',
    } as CSSProperties;
  }, []);
};

/**
 * @returns {(loco: TrainAsset) => LocoStatusDetails | undefined} callback to get the latest record between a snapshot and health check record
 */
export const useGetLatestRecord = () => {
  const getLatestRecord = useCallback((loco: TrainAsset) => {
    return loco.snapshotData?.isLatest || !loco.healthCheckData
      ? loco.snapshotData
      : loco.healthCheckData;
  }, []);
  return { getLatestRecord };
};

/**
 * @returns {any} combined style object for use throughout the train details components
 */
export const useDisplayProps = () => {
  return {
    columnProps: useMemo((): CSSProperties => {
      return {
        flexBasis: '100%',
        maxWidth: '200px',
      };
    }, []),

    leadColumnProps: useMemo((): CSSProperties => {
      return {
        minWidth: '180px',
        marginRight: '10px',
      };
    }, []),

    labelColumnProps: useMemo((): CSSProperties => {
      return {
        backgroundColor: 'rgba(102, 117, 127, 0.50)',
        overflow: 'hidden',
      };
    }, []),

    evenColor: useMemo(() => 'rgba(57, 71, 85, 0.50)', []),
    oddColor: useMemo(() => 'rgba(45, 56, 68, 0.498)', []),
  };
};
