import { DateTime, DurationUnits } from 'luxon';
import { useMemo } from 'react';

import {
  TimelineEventType,
  TimelineEvent,
} from 'common/features/timeline/types';
import { useAppSelector } from 'common/hooks';
import {
  selectTimelineEvents,
  selectTimelineNow,
} from 'common/stores/timelineSlice';

export const useCalculateOffset = (value: number) => {
  const now = useAppSelector(selectTimelineNow);

  const offset = useMemo((): string => {
    return now.minus({ minutes: Math.abs(value) }).toISO();
  }, [now, value]);

  return { offset };
};

export const useConvertEventsToDurationMap = (
  type?: TimelineEventType,
  unit?: DurationUnits
) => {
  const now = useAppSelector(selectTimelineNow);
  const events = useAppSelector(selectTimelineEvents);

  const eventMap = useMemo((): Map<number, TimelineEvent> => {
    const tracker: Map<number, TimelineEvent> = new Map();

    // we filter by the passed in event type (if null, we match all)
    // then we calculate minutes since the timestamp, given the units (defaulting to minutes)
    events
      .filter((event) => event.type === (type ?? event.type))
      .forEach((event) =>
        tracker.set(
          Math.round(
            DateTime.fromISO(event.timestamp).diff(now, unit ?? 'minutes')
              .minutes
          ),
          event
        )
      );

    return tracker;
  }, [now, events, type, unit]);

  return { eventMap };
};
