import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab';
import {
  ClickAwayListener,
  Fade,
  InputBase,
  Paper,
  Popper,
  Slide,
  TextField,
  Typography,
} from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React, { useCallback, useMemo, useRef, useState } from 'react';

import { DateTimeWrapper } from 'common/components/DateTimeWrapper';
import { UnderlineTextOnHover } from 'common/components/general/UnderlineTextOnHover';
import { TimelineEvent } from 'common/features/timeline/types';
import { useAppDispatch, useAppSelector, usePrevious } from 'common/hooks';
import {
  selectTimelineEvents,
  selectTimelineMode,
  updateTimelineEvent,
} from 'common/stores/timelineSlice';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

import { EventTooltip } from 'loco-history/cards/Timeline/components/event-tooltip/EventTooltip';
import { EventTooltipBody } from 'loco-history/cards/Timeline/components/event-tooltip/EventTooltipBody';
import { TimelineEventToPropsMap } from 'loco-history/config';

export const LocomotiveStory = React.forwardRef<HTMLDivElement | null>(
  (_, ref) => {
    const events = useAppSelector(selectTimelineEvents);

    const sortedEvents = useMemo((): TimelineEvent[] => {
      return [...events].sort((a: TimelineEvent, b: TimelineEvent) =>
        a.timestamp < b.timestamp ? -1 : a.timestamp > b.timestamp ? 1 : 0
      );
    }, [events]);

    const prevEvents = usePrevious(sortedEvents)?.map((event) => event.id);

    const newEvent = useMemo(() => {
      const newEvents = sortedEvents.filter(
        (event) => !prevEvents?.includes(event.id)
      );
      return newEvents.length > 0 ? newEvents[0] : undefined;
    }, [sortedEvents, prevEvents]);

    if (events.length > 0) {
      return (
        <Fade in={true} timeout={1000}>
          <div
            className='story-wrapper'
            style={{
              backgroundColor: PowerToolDarkTheme.main,
              display: 'flex',
              flex: '1 0 0',
              overflow: 'auto',
              position: 'relative',
            }}
          >
            <Paper
              className='story-paper'
              variant='outlined'
              sx={{
                m: 2,
                backgroundColor: 'inherit',
                width: '100%',
                overflow: 'auto',
              }}
            >
              <div
                ref={ref}
                style={{ backgroundColor: PowerToolDarkTheme.main }}
              >
                <Timeline
                  sx={{
                    alignItems: 'flex-start',
                    [`& .MuiTimelineOppositeContent-root`]: {
                      flex: 0,
                      flexBasis: '175px',
                      paddingTop: 0,
                      marginTop: 0,
                    },
                    // [`& .MuiTimelineContent-root`]: {
                    //   paddingTop: '6px',
                    // },
                    [`& .MuiTimelineItem-root`]: {
                      width: '100%',
                    },
                  }}
                >
                  {sortedEvents.map((event) => {
                    if (newEvent && newEvent.id === event.id) {
                      return (
                        <Slide key={event.id} in={true} direction='down'>
                          <div>
                            <StoryItem event={event} />
                          </div>
                        </Slide>
                      );
                    } else {
                      return <StoryItem key={event.id} event={event} />;
                    }
                  })}
                </Timeline>
              </div>
            </Paper>
          </div>
        </Fade>
      );
    } else {
      return (
        <Fade in={true} timeout={1000}>
          <Typography
            display='flex'
            justifyContent={'center'}
            sx={{ pt: 5 }}
            variant='h5'
            fontSize={'16px'}
          >
            Add events to build a story
          </Typography>
        </Fade>
      );
    }
  }
);
LocomotiveStory.displayName = 'Locomotive Story';

/**
 *
 * @param {any} props implicit destructured props
 * @param {TimelineEvent} props.event event that the story will represent
 * @returns {JSX.Element} a single locomotive story component
 */
const StoryItem = ({ event }: { event: TimelineEvent }) => {
  const mode = useAppSelector(selectTimelineMode);
  const ref = useRef<HTMLInputElement | null>(null);
  const props = TimelineEventToPropsMap[event.type];

  const [textValue, setTextValue] = useState(event.storyProps?.text);

  const [detailsOpen, setDetailsOpen] = useState<boolean>(false);

  const onClickAway = useCallback(() => {
    setDetailsOpen(false);
  }, []);

  const onContentClick = useCallback((content: string) => {
    setTextValue((old) => `${old ? `${old}\n` : ''}${content}`);
  }, []);

  // https://github.com/niklasvh/html2canvas/issues/664#issuecomment-636374614
  // useEffect(() => {
  //   if (ref.current && textValue) {
  //     ref.current.innerHTML = textValue.replace(/\s/g, '<span> </span>');
  //   }
  // }, [textValue])

  // if (event.event === undefined) {
  //   return <></>;
  // }

  const timelineDot = useMemo(() => {
    return (
      <TimelineDot
        variant='outlined'
        sx={{
          borderColor: props.style.backgroundColor,
          cursor: event.event ? 'pointer' : 'default',
        }}
      >
        {props.icon}
      </TimelineDot>
    );
  }, [event.event, props.icon, props.style]);

  const tooltip = useMemo(() => {
    if (event.event) {
      return (
        <EventTooltip
          tooltipProps={{ placement: mode === 'build' ? 'bottom' : 'left' }}
          open={detailsOpen}
          onClickAway={onClickAway}
          title={
            <EventTooltipBody
              event={event.event}
              type={event.type}
              onContentClick={onContentClick}
            />
          }
          content={timelineDot}
        />
      );
    } else {
      return timelineDot;
    }
  }, [event, timelineDot, detailsOpen, mode, onClickAway, onContentClick]);

  return (
    <TimelineItem onClick={() => setDetailsOpen(true)}>
      <TimelineOppositeContent variant='body2' sx={{ marginTop: '10px' }}>
        <Typography variant='h6' fontSize={16}>
          {event.storyProps?.title}
        </Typography>
        <div
          style={{
            whiteSpace: 'nowrap',
            fontSize: 12,
            color: PowerToolDarkTheme.statusGray300,
          }}
        >
          <StoryDatePicker event={event} />
        </div>
      </TimelineOppositeContent>
      <TimelineSeparator>
        {tooltip}
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent fontSize={14}>
        <InputBase
          ref={ref}
          sx={{
            width: '100%',
            wordBreak: 'break-word',
            whiteSpace: 'normal',
            fontSize: 13,
          }}
          multiline
          value={textValue}
          onChange={(event) => setTextValue(event.target.value)}
        />
      </TimelineContent>
    </TimelineItem>
  );
};

/**
 *
 * @param {any} props implicit destructured props
 * @param {TimelineEvent} props.event timeline event to display timestamp for
 * @returns {JSX.Element} formatted timestamp component that allows changing the time on click
 */
export const StoryDatePicker = ({ event }: { event: TimelineEvent }) => {
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    },
    [anchorEl]
  );

  const onDateChange = useCallback(
    (value: Date | null) => {
      if (value) {
        dispatch(
          updateTimelineEvent({
            ...event,
            timestamp: value?.toISOString(),
          })
        );
      }
    },
    [event, dispatch]
  );

  const open = Boolean(anchorEl);

  return (
    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
      <div>
        <UnderlineTextOnHover onClick={handleClick}>
          <DateTimeWrapper date={event.timestamp} options={{ pretty: true }} />
        </UnderlineTextOnHover>
        <Popper open={open} anchorEl={anchorEl}>
          <Paper
            elevation={1}
            sx={{
              backgroundColor: PowerToolDarkTheme.main,
              p: 2,
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                minDate={new Date(2000, 1, 1)}
                maxDate={new Date()}
                label='Timestamp'
                value={event.timestamp}
                onChange={onDateChange}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Paper>
        </Popper>
      </div>
    </ClickAwayListener>
  );
};
