import { AccordionDetails, Grow } from '@mui/material';
import { useState, useEffect } from 'react';

import { useAppSelector } from 'common/hooks';

import { selectToolOutputDropdowns } from 'power-tool/stores/caseActionSlice';
import {
  AdditionalActionProps,
  ListItemComponentProps,
  ToolOutputItemState,
} from 'power-tool/tool-dispo/types';

import { CaseConditionComponent } from './CaseConditionChip';
import { ListComponent } from './ListComponent';

export const AppendComponent = (props: AdditionalActionProps) => {
  const dropdownValues = useAppSelector(selectToolOutputDropdowns);

  const [item, setItem] = useState<ToolOutputItemState>(
    props.currentToolOutput
  );
  const [items, setItems] = useState<ListItemComponentProps[]>([]);

  useEffect(() => {
    setItem(props.currentToolOutput);
  }, [props.currentToolOutput]);

  useEffect(() => {
    if (
      dropdownValues?.appendCaseList &&
      dropdownValues.appendCaseList.length > 0
    ) {
      setItems(
        dropdownValues.appendCaseList.map((c) => ({
          selected: item.appendTarget === c.caseId,
          value: c.caseId,
          description: `${c.caseId} - ${c.caseTitle}`,
          id: c.caseId,
          prefixComponent: <CaseConditionComponent condition={c.condition} />,
        }))
      );
    }
  }, [dropdownValues?.appendCaseList, item.appendTarget]);

  const onSelect = (selectedItem: ListItemComponentProps) => {
    props.onUpdate({
      ...item,
      appendTarget: selectedItem.value,
    });
  };

  if (props.action === 'append') {
    return (
      <Grow in={props.action === 'append'}>
        <AccordionDetails sx={{ ml: 2 }}>
          <ListComponent
            handleSelect={onSelect}
            label='Append to...'
            items={
              items.length > 0
                ? items
                : [
                    {
                      description: 'No valid Append targets',
                      value: 'disabled',
                    },
                  ]
            }
          />
        </AccordionDetails>
      </Grow>
    );
  }

  return <></>;
};
