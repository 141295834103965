import { IoEllipsisVertical, IoClose } from 'react-icons/io5';
import styled from 'styled-components';

export const StyledCard = styled.div`
  background-color: ${(props) => props.theme.main};
  box-shadow: 0px 0px 10px 1px ${(props) => props.theme.shadow};
`;

export const StyledCardHeader = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.tertiary};

  .handle-icon {
    margin-left: -30px;
    color: ${(props) => props.theme.tertiary};
  }
`;

export const StyledEllipsis = styled(IoEllipsisVertical)`
  &:hover {
    box-shadow: 0px 0px 10px 1px ${(props) => props.theme.shadow};
  }
`;

export const StyledClose = styled(IoClose)`
  &:hover {
    box-shadow: 0px 0px 10px 1px ${(props) => props.theme.shadow};
  }
`;
