import { LoginCallback } from '@okta/okta-react';
import { PowerTool } from 'PowerTool';
import { Navigate, Route, Routes } from 'react-router';

import { AuthSplash } from 'auth/components/AuthSplash';
import { AuthRequired } from 'auth/routes/SecureRoute';

/**
 * @returns {JSX.Element} router paths for the application
 */
// https://github.com/okta/okta-react/blob/master/samples/routing/react-router-dom-v6/src/components/Routes.tsx
export const PowerToolRoutes = () => {
  return (
    <Routes>
      {/* base of application, authentication is always required */}
      <Route path='/' element={<AuthRequired />}>
        <Route path='' element={<PowerTool />} />
      </Route>
      <Route path='/powertool' element={<AuthRequired />}>
        <Route path='' element={<PowerTool />} />
      </Route>

      {/* special okta route to handle login callbacks */}
      <Route
        path='login/callback'
        element={<LoginCallback loadingElement={<AuthSplash />} />}
      />

      {/* if a user tries to go somewhere not defined, redirect them into power tool */}
      <Route path='*' element={<Navigate to='/' replace />} />
    </Routes>
  );
};
