import { Chip } from '@mui/material';
import { Stack } from '@mui/system';
import { useMemo } from 'react';
import { TfiSignal } from 'react-icons/tfi';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

import { TrainAsset } from 'train-tool/types';

/**
 *
 * @param {any} props implicit destructured props
 * @param {TrainAsset} props.loco lead locomotive that contains EOT sensor data
 * @returns {JSX.Element} end of train component icon wrapper component
 */
export const EOTComponent = ({ loco }: { loco: TrainAsset }) => {
  const isValidBP = useMemo(() => {
    if (
      loco.healthCheckData?.eotBP?.value === undefined ||
      loco.healthCheckData?.eotBP?.value === null
    ) {
      return false;
    }

    return true;
  }, [loco.healthCheckData?.eotBP]);

  const color = useMemo(() => {
    if (!isValidBP) {
      return PowerToolDarkTheme.statusGray500;
    } else if (loco.healthCheckData?.eotBP?.format === 'warning') {
      return PowerToolDarkTheme.statusYellow500;
    } else {
      return PowerToolDarkTheme.statusGreen500;
    }
  }, [loco.healthCheckData?.eotBP, isValidBP]);

  const value = useMemo(() => {
    if (isValidBP) {
      if (loco.healthCheckData?.eotBP?.format === 'warning') {
        return loco.healthCheckData.eotBP.value;
      } else {
        return `${loco.healthCheckData?.eotBP?.value ?? ''} PSI`;
      }
    }

    return 'UNK';
  }, [isValidBP, loco.healthCheckData?.eotBP]);

  const label = useMemo(() => {
    return (
      <Chip
        // onClick={isDisabled ? undefined : selectLoco}
        sx={{
          // cursor: isDisabled ? 'not-allowed' : 'pointer',
          // opacity: isDisabled ? 0.4 : 1,
          mt: 1,
          fontSize: '11px',
          width: '95px',
        }}
        size='small'
        label={
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontWeight: 500,
              fontSize: '10px',
            }}
          >
            <Stack spacing={0.5} direction={'row'} alignItems='center'>
              <div>{'EOT BP:'}</div>
              <div style={{ color: color }}>{value}</div>
            </Stack>
          </div>
        }
      />
    );
  }, [value, color]);

  return (
    <Stack justifyContent={'center'} alignItems={'center'}>
      <TfiSignal
        style={{
          fontSize: '28px',
          color: color,
        }}
      />
      {label}
    </Stack>
  );
};
