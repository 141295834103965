import { SvgIcon, SvgIconProps, Tooltip } from '@mui/material';
import React, { LegacyRef } from 'react';
import { Provider } from 'react-redux';

import { MapMarkerData } from 'common/cards/Map/types';
import { store } from 'common/store';

import { MapMarkerTooltipComponent } from './MapMarkerTooltip';

export type MapMarkerComponentProps = {
  markerData: MapMarkerData;
  iconProps?: SvgIconProps;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

export const MapMarkerComponent = (props: MapMarkerComponentProps) => {
  if (props.markerData.disableTooltip === true) {
    return <MapMarkerIcon onClick={props.onClick} />;
  }

  // this sucks - we need to add the provider again because these elements are rendered
  // outside of our root
  return (
    <Provider store={store}>
      <Tooltip
        title={<MapMarkerTooltipComponent marker={props.markerData} />}
        placement='top'
        arrow
      >
        <MapMarkerIcon onClick={props.onClick} iconProps={props.iconProps} />
      </Tooltip>
    </Provider>
  );
};

export type MapIconProps = {
  iconProps?: SvgIconProps;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
} & React.HTMLAttributes<HTMLDivElement>;

export const MapMarkerIcon = React.forwardRef<HTMLDivElement, MapIconProps>(
  (props: MapIconProps, ref?: LegacyRef<HTMLDivElement>) => {
    const { iconProps, onClick, ...divProps } = props;

    return (
      <div
        style={{ cursor: 'pointer' }}
        ref={ref}
        {...divProps}
        onClick={onClick}
      >
        <SvgIcon {...iconProps}>
          <path
            strokeWidth={0.5}
            stroke={'darkgrey'}
            d='M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z'
            id='path130'
          />
          <path
            strokeWidth={0.5}
            stroke={'darkgrey'}
            fill='white'
            d='M 11.629172,11.46214 C 10.869481,11.315521 10.253851,10.901837 9.8827586,10.288605 9.6137095,9.8439996 9.5077642,9.42178 9.5305704,8.8850433 9.5527756,8.3624525 9.6705931,8.0150023 9.9759283,7.5716588 10.431519,6.910145 11.17389,6.5269917 12,6.5269917 c 1.379292,0 2.474149,1.0907644 2.476617,2.4673606 0.002,1.1154797 -0.732667,2.0869757 -1.814615,2.3995797 -0.212996,0.06154 -0.84956,0.103579 -1.03283,0.06821 z'
            id='path273'
          />
        </SvgIcon>
      </div>
    );
  }
);
