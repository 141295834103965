import { Tooltip, Typography, Zoom } from '@mui/material';
import { useCallback, useState } from 'react';

import { useTimelineStyles } from 'common/features/timeline/hooks/style';
import { useCalculateOffset } from 'common/features/timeline/hooks/util';
import { useUserFormattedTime } from 'common/hooks';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

export const ThumbTooltipTitle = ({ value }: { value: number }) => {
  const { offset } = useCalculateOffset(value);
  const formattedTime = useUserFormattedTime(offset);

  return (
    <div>
      <Typography variant='body2'>{formattedTime}</Typography>
    </div>
  );
};

export const Thumb = ({ value, props }: { value: number; props: any }) => {
  const { trackStyle, thumbStyle } = useTimelineStyles();

  const [isDragged, setDragged] = useState<boolean>(false);
  const [isFocused, setFocused] = useState<boolean>(false);

  const onMouseDown = useCallback((event) => {
    setDragged(true);
  }, []);

  const onMouseUp = useCallback((event) => {
    setDragged(false);
  }, []);

  const onMouseEnter = useCallback((event) => {
    setFocused(true);
  }, []);

  const onMouseLeave = useCallback((event) => {
    setFocused(false);
    setDragged(false);
  }, []);

  return (
    <Tooltip
      arrow
      placement='top'
      TransitionComponent={Zoom}
      title={<ThumbTooltipTitle value={value} />}
    >
      <div
        {...props}
        style={{
          ...props.style,
          ...thumbStyle,
          // @ts-ignore
          zIndex: trackStyle.zIndex + 2,
          outlineStyle: 'none',
        }}
        onMouseEnter={onMouseEnter}
        onMouseUp={onMouseUp}
        onMouseDown={onMouseDown}
        onMouseLeave={onMouseLeave}
      >
        <div
          style={{
            ...thumbStyle,
            border: '2px solid',
            borderColor: PowerToolDarkTheme.voltBlue500,
            borderRadius: '50%',
            // @ts-ignore
            zIndex: trackStyle.zIndex + 2,

            backgroundColor: PowerToolDarkTheme.voltBlue900,
            cursor: isDragged ? 'grabbing' : 'grab',
            opacity: isDragged || isFocused ? '50%' : '100%',
            transform: isDragged || isFocused ? 'scale(1.4)' : undefined,
            transition: 'all .1s ease-in-out',
          }}
        >
          <div
            style={{
              height: '8px',
              width: '2px',
              // borderRadius: '50%',
              borderRadius: '2px',
              backgroundColor: PowerToolDarkTheme.voltBlue300,
            }}
          />
        </div>
      </div>
    </Tooltip>
  );
};
