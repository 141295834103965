import {
  Launch,
  NoteAltOutlined,
  OpenInNew,
  Preview,
  Visibility,
} from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import { useMemo } from 'react';

import { GradientBadgeComponent } from 'common/components/GradientBadgeComponent';
import { useAppSelector } from 'common/hooks';
import { selectAssetDetails } from 'common/stores/globalSlice';
import { openOMDRx } from 'common/util/utils';

import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

/**
 *
 * @param params
 */
export const RecommendationCell = (params: ICellRendererParams) => {
  const assetDetails = useAppSelector(selectAssetDetails);

  const icon = useMemo(() => {
    if (params.data?.ruleId !== '') {
      return (
        <Tooltip arrow title='View RX OMD' enterDelay={200}>
          <span
            style={{ display: 'flex' }}
            onClickCapture={(e) => {
              e.stopPropagation();

              openOMDRx(
                params.data.rxId,
                assetDetails?.customerOrgId,
                assetDetails?.roadNumber,
                true
              );
            }}
          >
            <OpenInNew
              style={{ fontSize: '18px', marginRight: '2px' }}
              sx={{
                cursor: 'pointer',
                color: PowerToolDarkTheme.neutralDark600,
              }}
            />
          </span>
        </Tooltip>
      );
    }

    return <></>;
  }, [assetDetails]);

  return (
    <div
      className='recom-cell'
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      {icon}
      {params.value}
      <span className={`recom-badge ${params.data.rxUrgency ?? 'none'}`}>
        {params.data.rxUrgency}
      </span>
    </div>
  );
};

/**
 *
 * @param params
 */
export const ReissueCell = (params: ICellRendererParams) => {
  if (params.data?.toolId === 'Fault') {
    return <></>;
  }

  return <GradientBadgeComponent value={params.data?.reissuePct} />;
};

/**
 *
 * @param params
 */
export const NTFCell = (params: ICellRendererParams) => {
  if (params.data?.toolId === 'Fault') {
    return <></>;
  }

  return <GradientBadgeComponent value={params.data?.ntfPct} />;
};
