import '@aerisweather/javascript-sdk/dist/styles/styles.css';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo } from 'react';
import { AiOutlinePartition } from 'react-icons/ai';
import { MdDirectionsRun, MdError } from 'react-icons/md';

import { LogoutButton } from 'auth/features/Logout';

import { Logo } from 'common/assets/Logo';
import {
  selectFaultCodes, // selectIngestedData,
  setFiredFaults,
  setFiredFltAndParamForRuleLoaded, // setFiredParameters,
  setSelectedFaultCodes,
} from 'common/cards/Data/store';
import { getTemplateLabel } from 'common/features/datapack/utils';
import { AssetSearch } from 'common/features/search/AssetSearch';
import { SearchHistory } from 'common/features/search/SearchHistory';
import { UserSettings } from 'common/features/settings/UserSettings';
import {
  useAppDispatch,
  useAppSelector,
  useUserFormattedTime,
} from 'common/hooks';
import { selectDatapackTemplate } from 'common/stores/datpacksSlice';
import {
  changeRule,
  selectApplicationContext,
  selectAssetDetails,
  selectDeselectedType,
  selectParameterMap,
  selectRule,
  setDeselectedType,
  selectUser,
  selectErrorMessage,
} from 'common/stores/globalSlice';

import { StyledH6 } from 'theme/GlobalStyles';
import { PowerToolDarkTheme } from 'theme/PowerToolThemes';

import {
  caseAction,
  FiredFaultInput,
  getAssetRuns,
  getFiredFaultsAndFiredParameters,
} from 'power-tool/saga/actionSlice';
import { selectCase, setSelectedCase } from 'power-tool/stores/casesSlice';
import {
  selectRun,
  setRun,
  setRunRemovedFromFilter,
} from 'power-tool/stores/runSlice';
import { setToolOutputData } from 'power-tool/stores/toolOutputSlice';
import { Run } from 'power-tool/types';

import { DataSourceSelector } from 'train-tool/features/DataSourceSelector';
import { TrainAndAssetSearch } from 'train-tool/features/TrainAndAssetSearch';

import { AssetChip } from './components/AssetChip';
import { TrainChip } from './components/TrainChip';

/**
 *
 */
export const TopBar = () => {
  const dispatch = useAppDispatch();

  const assetDetails = useAppSelector(selectAssetDetails);
  const selectedCase = useAppSelector(selectCase);
  const runStart = useUserFormattedTime(useAppSelector(selectRun)?.startedOn);
  const ruleId = useAppSelector(selectRule);
  const selectedFaultCodes = useAppSelector(selectFaultCodes);
  const parameterMap = useAppSelector(selectParameterMap);
  // const ingestedData = useAppSelector(selectIngestedData);
  const deselectType = useAppSelector(selectDeselectedType);
  const user = useAppSelector(selectUser);
  const applicationContext = useAppSelector(selectApplicationContext);
  const appErrorMessage = useAppSelector(selectErrorMessage);
  const datapackTemplate = useAppSelector(selectDatapackTemplate);

  const { enqueueSnackbar } = useSnackbar();

  let ruleChip, runChip, caseChip, datapackTemplateChip;

  /**
   *
   */
  const resetParmRuleAndFiredFaults = () => {
    // dispatch(setFiredParameters([]));
    dispatch(setFiredFaults([]));
    dispatch(setFiredFltAndParamForRuleLoaded(true));
  };

  // sometimes we need to dispatch an error outside
  // of a component. so we handle it here, at the highest
  // level component we can
  useEffect(() => {
    if (appErrorMessage) {
      enqueueSnackbar(appErrorMessage, { variant: 'warning' });
    }
  }, [appErrorMessage, enqueueSnackbar]);

  useEffect(() => {
    if (deselectType) {
      handleFilterDelete(deselectType, 'deselect');
    }
  }, [deselectType]);

  const applicationTitle = useMemo(() => {
    switch (applicationContext) {
      case 'train_analysis':
        return 'Train Analysis Tool';
      case 'datascreen':
        return 'Datascreen';
      case 'asset_research':
        return (
          <Stack direction='column' ml={1}>
            <Stack spacing={2} direction='row'>
              <div>🚧</div>
              <div
                style={{
                  color: PowerToolDarkTheme.statusOrange500,
                }}
              >
                {'Asset Research Tool'}
              </div>
              <div>🚧</div>
            </Stack>
            <Typography
              variant='caption'
              color={PowerToolDarkTheme.statusYellow500}
            >
              {'Tool is under construction. Use at your own risk.'}
            </Typography>
          </Stack>
        );

      case 'virtual_tester':
        return 'Virtual Tester';

      case 'datapack_viewer':
        return 'Datapack';

      case 'springboard':
        return 'Loco Events';

      case 'tokenizer':
        return 'Okta Token Info  🔐';

      default:
        return 'Power Tool';
    }
  }, [applicationContext]);

  const searchComponent = useMemo(() => {
    switch (applicationContext) {
      case 'train_analysis':
        return <TrainAndAssetSearch />;

      case 'asset_research':
      case 'virtual_tester':
      case 'asset_view':
      case 'rule_tester':
      case 'datascreen':
        return <AssetSearch />;

      default:
        return <></>;
    }
  }, [applicationContext]);

  /**
   *
   * @param type
   * @param value
   */
  function handleFilterDelete(type: string, value: any) {
    // const emptyRun: Run = {} as Run;
    switch (type) {
      case 'rule':
        dispatch(changeRule(0));
        resetParmRuleAndFiredFaults();
        break;
      case 'case':
        // to-do this whole filter thing needs to be a saga
        dispatch(changeRule(0));
        dispatch(setRun(undefined));
        dispatch(setSelectedFaultCodes([]));
        dispatch(setSelectedCase(undefined));
        resetParmRuleAndFiredFaults();
        dispatch(setToolOutputData([]));
        dispatch(getAssetRuns(assetDetails?.vehicleObjid ?? 0));
        break;
      case 'run':
        // only clear the rule if we are clearing the run
        // with no case selected
        if (selectedCase === undefined) {
          dispatch(changeRule(0));
          resetParmRuleAndFiredFaults();
        }

        // then clear the run and clear the selected faults
        dispatch(setRun(undefined));
        dispatch(setSelectedFaultCodes([]));

        // if we have data and a rule & case selected, show the firings for that selection
        // if (ruleId && parameterMap && selectedCase && ingestedData) {
        //   const firedFaultInput: FiredFaultInput = {
        //     id: selectedCase.caseId,
        //     ruleId: ruleId,
        //     type: 'caserul',
        //   };

        //   dispatch(getFiredFaultsAndFiredParameters(firedFaultInput));
        // }
        dispatch(setRunRemovedFromFilter(true));

        // if we have a case selected
        if (selectedCase) {
          // && !noSelectCaseTypes.includes(selectedCase.caseId)){
          dispatch(caseAction(selectedCase.caseId));
        }

        // otherwise we are on one of the above cases so clear the tool output
        else {
          dispatch(setToolOutputData([]));
        }
        break;
      case 'faults':
        const faultCodes = [...selectedFaultCodes];
        const index = faultCodes.indexOf(value);
        faultCodes.splice(index, 1);
        dispatch(setSelectedFaultCodes(faultCodes));
        break;
      default:
        break;
    }

    if (value && value.toString() === 'deselect') {
      dispatch(setDeselectedType(''));
    }
  }

  if (ruleId > 0) {
    ruleChip = (
      <Chip
        size='small'
        color='primary'
        icon={<AiOutlinePartition />}
        label={ruleId}
        onDelete={(e) => {
          handleFilterDelete('rule', 0);
        }}
      />
    );
  }
  if (runStart) {
    runChip = (
      <Chip
        size='small'
        color='primary'
        icon={<MdDirectionsRun />}
        label={runStart}
        onDelete={(e) => {
          handleFilterDelete('run', 0);
        }}
      />
    );
  }
  if (selectedCase && selectedCase.caseId !== '') {
    caseChip = (
      <Chip
        size='small'
        color='primary'
        label={selectedCase.caseId}
        onDelete={(e) => {
          handleFilterDelete('case', '');
        }}
      />
    );
  }
  if (applicationContext === 'datapack_viewer') {
    let datapackTitle = getTemplateLabel(datapackTemplate);
    if (datapackTitle !== '') {
      datapackTemplateChip = (
        <Chip
          size='small'
          color='primary'
          icon={<PostAddIcon />}
          label={datapackTitle}
        />
      );
    }
  }

  return (
    <div
      style={{
        height: '48px',
        display: 'flex',
        justifyContent: 'space-between',
        position: 'sticky',
        background: PowerToolDarkTheme.background,
        top: 0,
        zIndex: 1,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Logo />
        <StyledH6>{applicationTitle}</StyledH6>
        <SearchHistory />
        <hr
          className='divider'
          style={{
            boxShadow: `0px -9px 10px 5px ${PowerToolDarkTheme.shadow}`,
            transform: 'rotate(102deg)',
            border: 'transparent',
            width: '46px',
            marginLeft: '20px',
            height: '2px',
            borderBottom: 'transparent',
            background: 'transparent',
            zIndex: -1,
          }}
        />
        <Stack direction='row' spacing={0.5}>
          <TrainChip />
          <AssetChip />
          {caseChip}
          {runChip}
          {ruleChip}
          {datapackTemplateChip}
          {selectedFaultCodes.map((data) => {
            return (
              <Chip
                size='small'
                icon={<MdError />}
                color='primary'
                key={data}
                label={data}
                onDelete={(e) => {
                  handleFilterDelete('faults', data);
                }}
              />
            );
          })}
        </Stack>
      </div>

      <div
        style={{
          display: 'flex',
          gap: '5px',
          alignItems: 'center',
        }}
      >
        <DataSourceSelector />

        {searchComponent}

        {user && user.userName !== 'anonymous' && applicationContext ? (
          <UserSettings />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
