import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'common/store';

import { RuleDetails } from 'power-tool/cards/RuleOutput/types';

export type RuleOutputState = {
  ruleCardTitle: string;
  ruleDetails?: RuleDetails;
};

const initialState: RuleOutputState = {
  ruleCardTitle: '',
};

export const ruleOutputSlice = createSlice({
  name: 'ruleOutput',
  initialState,
  reducers: {
    setRuleCardTitle: (state, action: PayloadAction<string>) => {
      state.ruleCardTitle = action.payload;
    },
    setRuleDetails: (state, action: PayloadAction<RuleDetails>) => {
      state.ruleDetails = action.payload;
    },
  },
});

export const { setRuleCardTitle, setRuleDetails } = ruleOutputSlice.actions;

export const selectRuleCardTitle = (state: RootState) =>
  state.ruleOutput.ruleCardTitle;
export const selectRuleDetails = (state: RootState) =>
  state.ruleOutput.ruleDetails;

export default ruleOutputSlice.reducer;
